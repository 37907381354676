import { Input } from "@chakra-ui/input";
import { Box, Text } from "@chakra-ui/layout";
import { BoxProps } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import { useRef, useState } from "react";
import Autosuggest, {
  GetSuggestionValue,
  InputProps,
  OnSuggestionSelected,
  RenderSuggestion,
  RenderSuggestionParams,
  SuggestionsFetchRequested,
  Theme,
} from "react-autosuggest";
import { cancellablePromise } from "../utils/promiseUtils";

const theme: Theme = {
  container: { position: "relative" },
  inputOpen: { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
  suggestionsContainer: { display: "none" },
  suggestionsContainerOpen: {
    display: "block",
    position: "absolute",
    top: 31,
    left: -1,
    right: -1,
    border: "2px solid #3182ce",
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    zIndex: 2,
    paddingBottom: 10,
    backgroundColor: "white",
    maxHeight: "200px",
    overflowY: "auto",
  },
  suggestionsList: { margin: 0, padding: 0, listStyleType: "none" },
  suggestionHighlighted: { backgroundColor: "#ddd" },
};

type TWithAddNew<T> = T & { isAddNew?: boolean };

interface Props<T> {
  options?: T[];
  exclude?: T[];
  fetchSuggestions?: (inputValue: string) => Promise<T[]>;
  handleSearchResultClick?: (
    selection: Autosuggest.SuggestionSelectedEventData<T>
  ) => void;
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  getSuggestionValue?: GetSuggestionValue<T>;
  focusInputOnSuggestionClick?: boolean;
  highlightFirstSuggestion?: boolean;
  alwaysRenderSuggestions?: boolean;
  inputProps?: Partial<InputProps<T>>;
  containerProps?: BoxProps;
  onNewSuggestion?: (value: string) => void;
  renderSuggestion?: RenderSuggestion<T>;
}

interface AutocompleteInputNonStringProps<T> extends Props<T> {
  getSuggestionValue: GetSuggestionValue<T>;
  handleSearchResultClick?: (
    selection: Autosuggest.SuggestionSelectedEventData<TWithAddNew<T>>
  ) => void;
  renderSuggestion?: RenderSuggestion<TWithAddNew<T>>;
}

type AutocompleteInputProps<T> = T extends string
  ? Props<T>
  : AutocompleteInputNonStringProps<T>;

export default function AutocompleteInput<T>({
  options = [],
  handleSearchResultClick,
  disabled,
  value,
  onChange,
  getSuggestionValue,
  exclude,
  focusInputOnSuggestionClick,
  highlightFirstSuggestion = true,
  alwaysRenderSuggestions = false,
  inputProps,
  containerProps = {},
  fetchSuggestions,
  onNewSuggestion,
  renderSuggestion = (
    suggestion: string | TWithAddNew<T>,
    { query, isHighlighted }: RenderSuggestionParams
  ) =>
    typeof suggestion !== "string" && suggestion?.isAddNew ? (
      <Text
        cursor="pointer"
        backgroundColor={isHighlighted ? "#ddd" : "white"}
        padding={2}
      >
        [+] Add new: <strong>{query}</strong>
      </Text>
    ) : (
      <Text
        cursor="pointer"
        backgroundColor={isHighlighted ? "#ddd" : "white"}
        padding={2}
      >
        {`${
          getSuggestionValue ? getSuggestionValue(suggestion as T) : suggestion
        }`}
      </Text>
    ),
}: AutocompleteInputProps<T>) {
  const [suggestions, setSuggestions] = useState<TWithAddNew<T>[]>(
    options || []
  );
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const cancelPromise = useRef<(() => void) | undefined>(undefined);
  const fetchTimeout = useRef<NodeJS.Timeout>();

  const loadSuggestions = async (value: string) => {
    if (!!cancelPromise.current) {
      cancelPromise.current();
    }
    setIsLoading(true);

    let newSuggestions: T[] = [];
    try {
      newSuggestions = await new Promise(async (resolve, reject) => {
        if (fetchTimeout.current) {
          clearTimeout(fetchTimeout.current);
        }
        fetchTimeout.current = setTimeout(async () => {
          const { promise, cancel } = cancellablePromise(
            fetchSuggestions!(value)
          );
          cancelPromise.current = cancel;
          resolve(await promise);
        }, 1000);
      });
    } catch (error) {}
    setIsLoading(false);
    return newSuggestions;
  };

  // Autosuggest will call this function every time you need to update suggestions.
  const onSuggestionsFetchRequested: SuggestionsFetchRequested = async ({
    value,
  }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let newSuggestions: T[] = [];
    if (fetchSuggestions) {
      newSuggestions = await loadSuggestions(inputValue);
    } else {
      newSuggestions =
        inputLength === 0
          ? options || []
          : options?.filter((option) =>
              `${getSuggestionValue ? getSuggestionValue(option) : option}`
                .toLowerCase()
                .includes(inputValue)
            ) || [];
    }
    const filteredSuggestions = exclude?.length
      ? newSuggestions?.filter(
          (option) =>
            !exclude.find(
              (exclusion) =>
                (getSuggestionValue
                  ? getSuggestionValue(exclusion)
                  : exclusion) ===
                (getSuggestionValue ? getSuggestionValue(option) : option)
            )
        )
      : newSuggestions;

    if (
      onNewSuggestion &&
      !filteredSuggestions.find(
        (option) =>
          `${
            getSuggestionValue ? getSuggestionValue(option) : option
          }`.toLowerCase() === inputValue
      )
    ) {
      setSuggestions([
        ...(filteredSuggestions || []),
        { isAddNew: true } as TWithAddNew<T>,
      ]);
      return;
    }
    setSuggestions(filteredSuggestions || []);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions(options || []);
  };

  const _inputProps: InputProps<T> = {
    value: value ?? searchText,
    ...(inputProps || {}),
    onChange: (event, params) => {
      inputProps?.onChange?.(event, params);
      if (onChange) {
        onChange((event.target as HTMLInputElement).value);
      }
      setSearchText((event.target as HTMLInputElement).value || "");
    },
    onBlur: (event) => {
      inputProps?.onBlur?.(event);
    },
  };

  const onSuggestionSelected: OnSuggestionSelected<TWithAddNew<T>> = (
    event,
    selection
  ) => {
    if (onNewSuggestion && selection.suggestion?.isAddNew) {
      onNewSuggestion(searchText);
      return;
    }
    if (handleSearchResultClick) {
      handleSearchResultClick(selection);
    }
  };
  return (
    <Box style={{ position: "relative" }} {...containerProps}>
      {isLoading && <Spinner pos="absolute" right={3} top={2} />}
      <Autosuggest
        suggestions={suggestions}
        highlightFirstSuggestion={highlightFirstSuggestion}
        focusInputOnSuggestionClick={focusInputOnSuggestionClick}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue || ((s) => `${s}`)}
        renderSuggestion={renderSuggestion}
        inputProps={_inputProps}
        shouldRenderSuggestions={() => true}
        alwaysRenderSuggestions={alwaysRenderSuggestions}
        containerProps={{ style: { position: "relative" } }}
        theme={theme}
        renderInputComponent={({ size, ...other }) => (
          <Input {...other} disabled={disabled} />
        )}
        onSuggestionSelected={onSuggestionSelected}
      />
    </Box>
  );
}
