import { Box, Flex, FormControl, FormLabel, VStack } from "@chakra-ui/react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import FacebookImageUpload from "../data-integrations/facebook/FacebookImageUpload";

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function StoreImageInput({
  url,
  onChange,
}: {
  url?: string;
  onChange?: Function;
}) {
  return (
    <VStack>
      <VStack rounded="md" shadow="lg" bg="white">
        <FacebookImageUpload
          image={url ? { fileUrl: url } : undefined}
          accept=".jpg, .png"
          width={16}
          height={16}
          cropType={undefined}
          onLoad={(fileType: string, file: File, result: any) => {
            onChange?.(result);
          }}
          swapButton={false}
          onDelete={() => onChange?.("")}
          onSwap={undefined}
          video={undefined}
          onApply={undefined}
          formControlProps={{ w: 16, mb: 0, mt: 4 }}
        />
      </VStack>
    </VStack>
  );
}

type Props = {
  storeImages?: string[];
  onChange: (newImages: string[]) => void;
};

export default function StoreImagesInput({
  storeImages = [],
  onChange,
}: Props) {
  const handleReorderServices = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      storeImages,
      result.source.index,
      result.destination.index
    );

    onChange(items);
  };
  return (
    <FormControl>
      <FormLabel>Additional Images</FormLabel>
      <DragDropContext onDragEnd={handleReorderServices}>
        <Droppable droppableId="additionalServicesList" direction="horizontal">
          {(provided) => (
            <Flex
              flexWrap="wrap"
              ref={provided.innerRef}
              flexDir="row"
              w="100%"
              {...provided.droppableProps}
            >
              {storeImages?.map((s, index) => (
                <Draggable
                  key={new Date().getTime() + index}
                  draggableId={`${s}`}
                  index={index}
                >
                  {(provided) => (
                    <Box
                      w="33.33%"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <StoreImageInput
                        url={s}
                        onChange={(newService: string) => {
                          const newImages = [...(storeImages || [])];
                          newImages.splice(index, 1, newService);
                          onChange(newImages.filter((i) => !!i));
                        }}
                      />
                    </Box>
                  )}
                </Draggable>
              ))}
              {storeImages && storeImages?.length < 12 && (
                <StoreImageInput
                  onChange={(newService: string) => {
                    onChange(
                      [...(storeImages || []), newService].filter((i) => !!i)
                    );
                  }}
                />
              )}
            </Flex>
          )}
        </Droppable>
      </DragDropContext>
    </FormControl>
  );
}
