import { checkboxAnatomy as parts } from "@chakra-ui/anatomy";
import type {
  PartsStyleFunction,
  SystemStyleFunction,
} from "@chakra-ui/theme-tools";
import { mode } from "@chakra-ui/theme-tools";

const baseStyleControl: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  return {
    _checked: {
      bg: mode(`${c}.400`, `${c}.200`)(props),
      borderColor: mode(`${c}.400`, `${c}.200`)(props),
      color: mode("white", "gray.900")(props),

      _hover: {
        bg: mode(`${c}.600`, `${c}.300`)(props),
        borderColor: mode(`${c}.600`, `${c}.300`)(props),
      },
    },

    _indeterminate: {
      bg: mode(`${c}.400`, `${c}.200`)(props),
      borderColor: mode(`${c}.400`, `${c}.200`)(props),
      color: mode("white", "gray.900")(props),
    },
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  control: baseStyleControl(props),
});

const checkboxTheme = {
  baseStyle,
};
export default checkboxTheme;
