import {
  Box,
  Button,
  IconButton,
  Img,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { DataType } from "ka-table/enums";
import { useRef, useState } from "react";
import { IoCloudUpload } from "react-icons/io5";
import { toast } from "react-toastify";
import XLSX from "xlsx";

import { WarningIcon } from "@chakra-ui/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import {
  selectAccessToken,
  selectMenus,
  selectOrgProperties,
  selectProducts,
  selectUserResources,
} from "../app/appSlice";
import { useAppDispatch, useAppSelector } from "../app/store";
import menuIconSales from "../assets/images/menu-icon-sales.png";
import { RESOURCES } from "../constants/user-constants";
import { fetchStores } from "../features/map-selection/mapSelectionSlice";
import { postSale } from "../services/api.service";
import { trackClick } from "../services/tracking.service";
import { EntityTable } from "./entity-table/EntityTable";
import FileUploadProxy from "./file-upload-proxy";
import { ResponsiveModal } from "./responsive-modal";

var pluralize = require("pluralize");

export const UploadSalesContent = () => {
  const shouldCancelProcess = useRef(false);
  const hiddenFileInput = useRef();
  const accessToken = useAppSelector(selectAccessToken);
  const products = useAppSelector(selectProducts);
  const userResources = useAppSelector(selectUserResources);
  const orgProperties = useAppSelector(selectOrgProperties);
  const [itemsWithProblems, setItemsWithProblems] = useState();
  const [fixingProblems, setFixingProblems] = useState(false);
  const [generatingTemplate, setGeneratingTemplate] = useState(false);
  const [file, setFile] = useState();
  const dispatch = useAppDispatch();
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";

  const WarningTooltip = ({ rowKeyValue }) => {
    const item = itemsWithProblems && itemsWithProblems[rowKeyValue];
    return (
      <Tooltip hasArrow label={item?.error} bg="red.600">
        {item?.error ? (
          <IconButton icon={<WarningIcon />} aria-label={"warning button"} />
        ) : (
          <Box></Box>
        )}
      </Tooltip>
    );
  };

  const generateTemplate = async () => {
    setGeneratingTemplate(true);
    let orgStores = [];

    try {
      const wrappedStores = await dispatch(fetchStores({ orgLayer: true }))();
      orgStores = unwrapResult(wrappedStores);
    } catch (error) {
      console.log(error);
    }

    const products = orgStores
      .flatMap((store) => store.products)
      .reduce(
        (acc, relation) =>
          !acc.find((prod) => prod["Product ID"] === relation.product_id)
            ? [
                ...acc,
                {
                  "Product ID": relation.product.id,
                  SKU: relation.product.sku,
                  "Product category": relation.product.category,
                  "Product flavor": relation.product.flavor,
                  "Product Name": relation.product.name,
                  "Product description": relation.product.description,
                },
              ]
            : acc,
        []
      );

    var wb = XLSX.utils.book_new();

    const productsSheet = XLSX.utils.json_to_sheet(
      products?.length
        ? products
        : [
            {
              "Product ID": "",
              SKU: "",
              "Product category": "",
              "Product flavor": "",
              "Product Name": "",
              "Product description": "",
            },
          ]
    );
    const storesSheet = XLSX.utils.json_to_sheet(
      orgStores?.length
        ? orgStores.map((store) => ({
            store_id: store.store_id,
            chain: store.chain,
            name: store.name || store.chain || store.address.split(",")[0],
            description: store.description,
            address: store.address,
            zipcode: store.zipcode,
            short_address: store.short_address,
            city: store.city,
            state: store.state,
            phone: store.phone,
            latitude: JSON.parse(store._geojson || "{}").properties?.latitude,
            longitude: JSON.parse(store._geojson || "{}").properties?.longitude,
          }))
        : [
            {
              store_id: "",
              chain: "",
              name: "",
              description: "",
              address: "",
              zipcode: "",
              short_address: "",
              city: "",
              state: "",
              phone: "",
              latitude: "",
              longitude: "",
            },
          ]
    );
    const salesSheet = XLSX.utils.json_to_sheet([
      {
        "Store ID": "",
        "Product ID": "",
        Units: "",
        "Start Date": "",
        "End Date": "",
        Period: "",
      },
    ]);
    XLSX.utils.book_append_sheet(wb, productsSheet, "Product");
    XLSX.utils.book_append_sheet(wb, storesSheet, "Stores");
    XLSX.utils.book_append_sheet(wb, salesSheet, "Sales");
    setGeneratingTemplate(false);
    XLSX.writeFile(wb, "StoreTemplate.xlsx");
  };

  const handleChange = (e) => {
    e.preventDefault();

    setFile(e.target.files[0]);

    e.target.value = null;
  };

  const handleProxyPass = async (
    selectedFields,
    data,
    dataCsv,
    dataWithProblems
  ) => {
    setItemsWithProblems(dataWithProblems);
    if (dataWithProblems?.length) {
      toast.error(`${dataWithProblems.length} items have errors`, {
        autoClose: false,
        closeButton: null,
        onClick: () => setFixingProblems(true),
      });
    }
    if (data?.length) {
      const salesToCreate = [...data];
      const goodSales = [];
      const badSales = [];
      toast(`Creating ${salesToCreate.length} items`, {
        autoClose: false,
        closeOnClick: false,
        onClose: () => {
          shouldCancelProcess.current = true;
        },
        toastId: "creatinggoodsales",
      });
      for (let i = 0; i < salesToCreate.length; i++) {
        if (shouldCancelProcess.current) {
          shouldCancelProcess.current = false;
          break;
        }
        const sale = salesToCreate[i];
        try {
          const newSale = await postSale(
            {
              description: sale.description,
              units: sale.units,
              period: sale.period,
              store_id: sale.store_id,
              start_date: DateTime.fromISO(sale.start_date).toJSDate(),
              end_date: DateTime.fromISO(sale.end_date).toJSDate(),
              product_id: sale.product_id,
            },
            accessToken
          );
          goodSales.push(newSale);
        } catch (error) {
          badSales.push({
            ...sale,
            error: `${error.message}: ${
              error.response?.data?.msg || error.response?.data?.message
            }`,
          });
        }
        toast.update("creatinggoodsales", {
          progress: (i + 1) / salesToCreate.length,
          render: `Creating ${salesToCreate.length - (i + 1)} items`,
        });
      }
      toast.clearWaitingQueue();
      toast.dismiss("creatinggoodsales");
      if (goodSales.length) toast.success(`${goodSales.length} items created`);
      if (badSales.length) {
        toast.error(`${badSales.length} items failed to create`, {
          autoClose: false,
          closeButton: null,
          onClick: () => setFixingProblems(true),
        });
        setItemsWithProblems((oldItems) => [...(oldItems || []), ...badSales]);
      }
    }
  };

  return (
    <VStack mb={3} alignItems="flex-start">
      <FileUploadProxy
        file={file}
        schemas={{
          Sale: [
            {
              name: "store_id",
              helperText: `${storeLabel} identifier`,
              isRequired: true,
            },
            {
              name: "product_id",
              helperText: "Product identifier",
              isRequired: true,
              build: (fileFieldName, item) => {
                if (isNaN(item[fileFieldName])) {
                  const product = products?.find((searchItem) =>
                    searchItem.name
                      .toLowerCase()
                      .includes(`${item[fileFieldName]}`.toLowerCase())
                  );
                  if (!product) {
                    return undefined;
                  }
                  return product.id;
                }
                return item[fileFieldName];
              },
            },
            {
              name: "start_date",
              helperText: "Start date of sales. (YYYY-MM-DD)",
              isRequired: true,
            },
            {
              name: "end_date",
              helperText: "End date of sales. (YYYY-MM-DD)",
              isRequired: true,
            },
            {
              name: "period",
              helperText: "Time period of sales",
              isRequired: true,
            },
            {
              name: "units",
              helperText: "Value of the number of sales for the product",
              isRequired: true,
            },
          ],
        }}
        onFinishProxy={handleProxyPass}
      />
      <ResponsiveModal
        isOpen={fixingProblems && itemsWithProblems?.length}
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <ModalHeader>Products</ModalHeader>
            <ModalCloseButton onClick={() => setFixingProblems(false)} />
            <EntityTable
              initialTableProps={{
                columns: [
                  {
                    title: `${storeLabel} ID`,
                    dataType: DataType.Number,
                    key: "store_id",
                    isRequired: true,
                  },
                  {
                    title: "Product ID",
                    dataType: DataType.Number,
                    key: "product_id",
                    isRequired: true,
                    filterOptions: products?.map((product) => ({
                      value: product.id,
                      label: product.name,
                    })),
                  },
                  {
                    title: "Units",
                    dataType: DataType.Number,
                    key: "units",
                    isRequired: true,
                  },
                  {
                    title: "Start Date",
                    dataType: DataType.Date,
                    key: "start_date",
                    isRequired: true,
                  },
                  {
                    title: "End Date",
                    dataType: DataType.Date,
                    key: "end_date",
                    isRequired: true,
                  },
                  {
                    title: "Period",
                    dataType: DataType.String,
                    key: "period",
                    isRequired: true,
                  },
                ],
              }}
              dataFromOutside={itemsWithProblems?.map((sale, index) => ({
                ...sale,
                id: index,
                start_date: DateTime.fromISO(sale.start_date).toJSDate(),
                end_date: DateTime.fromISO(sale.end_date).toJSDate(),
              }))}
              putItem={async (index, sale) => {
                try {
                  await postSale(sale, accessToken);
                  setItemsWithProblems((oldItems) => {
                    const updated = [...oldItems];
                    updated.splice(index, 1);
                    return updated;
                  });
                  toast.success(`item fixed and created`);
                } catch (error) {
                  setItemsWithProblems((oldItems) => {
                    const updated = [...oldItems];
                    updated[index] = {
                      ...sale,
                      error: `${error.message}: ${
                        error.response?.data?.msg ||
                        error.response?.data?.message
                      }`,
                    };
                    return updated;
                  });
                  toast.error(
                    `Could not create item: ${error.message}: ${
                      error.response?.data?.msg || error.response?.data?.message
                    }`
                  );
                }
              }}
              LeftButton={WarningTooltip}
            />
          </ModalBody>
        </ModalContent>
      </ResponsiveModal>
      <Text fontWeight="bold" fontSize="md">
        Current Sales Template
      </Text>
      <Text>
        This template gives you all of the {pluralize(storeLabel)}, products and
        sales currently in your organization. Add your new sales to this
        template then upload the updated file.
      </Text>
      {userResources?.includes(RESOURCES.SALES_UPLOAD) && (
        <Button
          variant="link"
          as="a"
          onClick={generateTemplate}
          isDisabled={generatingTemplate}
          isLoading={generatingTemplate}
        >
          Download template
        </Button>
      )}
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
        disabled={!userResources?.includes(RESOURCES.SALES_UPLOAD)}
      />
      <Button
        leftIcon={IoCloudUpload}
        onClick={() => hiddenFileInput.current.click()}
        isDisabled={!userResources?.includes(RESOURCES.SALES_UPLOAD)}
      >
        Upload Sales
      </Button>
    </VStack>
  );
};

export const SalesBtn = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const menus = useAppSelector(selectMenus);
  const userResources = useAppSelector(selectUserResources);
  const salesMenu = menus?.find((m) => m.id === "sales");
  return !location.pathname?.includes("storelocator") && !!salesMenu ? (
    <Box>
      <Button
        id="sales-btn"
        className="sales-btn"
        variant="link"
        as="div"
        color="blue.900"
        padding="12px 10px"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        leftIcon={
          <Img
            src={menuIconSales}
            height="1rem"
            width="1rem"
            id="sales-btn"
            className="sales-btn"
            filter={
              location.pathname.includes("sales")
                ? "invert(48%) sepia(45%) saturate(4075%) hue-rotate(177deg) brightness(97%) contrast(102%)"
                : undefined
            }
          />
        }
        isActive={location.pathname.includes("sales")}
        isDisabled={!userResources?.includes(RESOURCES.SALES_BTN)}
        iconSpacing={0}
        size="xs"
        _hover={{ textDecoration: "underline" }}
        onClick={() =>
          trackClick("sales-btn", "Sales", () => {
            if (!userResources?.includes(RESOURCES.SALES_BTN)) {
              toast.warn("You don't have access to this");
              return;
            }
            const child = salesMenu?.children?.[0];
            child?.embedUrl
              ? navigate(`${child.embedUrl ? "embed/" : ""}sales-${child.id}`, {
                  state: {
                    from: location,
                    url: child.embedUrl,
                  },
                })
              : child?.component
              ? navigate(`${child.component}/sales-${child.id}`)
              : navigate(`not_available/sales-${child.id}`);
          })
        }
      >
        Sales
      </Button>
    </Box>
  ) : (
    <></>
  );
};

function UploadSalesBtn() {
  const userResources = useAppSelector(selectUserResources);
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box>
      <Button
        onClick={(e) => {
          if (!userResources?.includes(RESOURCES.SALES_UPLOAD)) {
            e.preventDefault();
            toast.warn("You don't have access to this");
            return;
          }
          onOpen();
        }}
        isDisabled={!userResources?.includes(RESOURCES.SALES_UPLOAD)}
      >
        Upload Sales
      </Button>
      <ResponsiveModal isOpen={isOpen} isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <ModalHeader>Upload Sales</ModalHeader>
            <ModalCloseButton onClick={onClose} />
            <UploadSalesContent />
          </ModalBody>
        </ModalContent>
      </ResponsiveModal>
    </Box>
  );
}

export default UploadSalesBtn;
