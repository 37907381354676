import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import React, { useEffect, useState } from "react";
import { IoMegaphone } from "react-icons/io5";
import {
  setShouldOpen,
  toggleIntegrationsModal,
} from "../features/data-integrations/integrationSlice";
import { connect } from "react-redux";
import {
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/form-control";
import {
  setIsSelectingOnMap,
  setMapSelection,
} from "../features/map-selection/mapSelectionSlice";
import { ResponsiveModal } from "./responsive-modal";
import { ZipcodeTab } from "./LocationBtn";
import { RESOURCES } from "../constants/user-constants";
import { toast } from "react-toastify";
import { LoadedStoresTab } from "./LoadedStoresTab";
import { selectLoadedZipcodes } from "../features/map/keplerReducer";
import pluralize from "pluralize";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../app/store";
import { selectOrgProperties } from "../app/appSlice";

function _ManualSelection({
  onClose,
  isOpen,
  typeOfLastMenuSelection,
  mapSelection,
  setSelection,
  loadedDemographics,
  zipcodesDisclosure,
  noSelectionAlertDisclosure,
  orgConfig,
}) {
  const [manualSelection, setManualSelection] = useState();
  const zipcodeLabel = orgConfig?.zipcodeNameReplacement || "Zipcode";
  return (
    <AlertDialog
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      closeOnOverlayClick
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>
          Marketing By Target {typeOfLastMenuSelection}
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Text>
            Add any {pluralize(zipcodeLabel, 1)} you're interested on targeting
            below
          </Text>
          <FormControl>
            <FormLabel>{pluralize(zipcodeLabel, 2)}:</FormLabel>
            <Input
              value={manualSelection}
              onChange={(e) => setManualSelection(e.target.value)}
            />
            <FormHelperText>
              You can add more than one {pluralize(zipcodeLabel, 1)} separating
              by comma
            </FormHelperText>
          </FormControl>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            onClick={() => {
              onClose();
              const newZipcodes = (manualSelection || "")
                .split(",")
                .map((zip) => zip.trim())
                .filter(
                  (zip) =>
                    !mapSelection?.zipcodes?.find(
                      (item) => item.zipcode === zip
                    ) && zip
                );
              setSelection((oldSelection) => ({
                ...(oldSelection || {}),
                zipcodes: [
                  ...(oldSelection?.zipcodes || []),
                  ...(loadedDemographics || []).filter((demo) =>
                    (newZipcodes || []).includes(demo.zipcode)
                  ),
                ],
              }));
              if (
                [...(mapSelection?.zipcodes || []), ...(newZipcodes || [])]
                  .length
              ) {
                zipcodesDisclosure.onOpen();
              } else {
                noSelectionAlertDisclosure.onOpen();
              }
            }}
          >
            Continue
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

const ManualSelection = connect(
  (state) => ({
    mapSelection:
      state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
        ?.selection,
    loadedDemographics: selectLoadedZipcodes(state),
    orgConfig: state.app.orgProperties?.properties,
  }),
  (dispatch) => ({
    setSelection: (newSelection) => dispatch(setMapSelection(newSelection)),
  })
)(_ManualSelection);

export const EngageTabs = ({ onClose, type }) => {
  const [selectedType, setSelectedType] = useState(type || "stores");
  const orgProperties = useAppSelector(selectOrgProperties);
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";
  const zipcodeLabel =
    orgProperties?.properties?.zipcodeNameReplacement || "Zipcode";
  return (
    <Flex h="100%" flexDir="column">
      <Select
        value={selectedType}
        onChange={(e) => setSelectedType(e.currentTarget.value)}
      >
        <option value="stores">{storeLabel}</option>
        <option value="zipcodes">{zipcodeLabel}</option>
      </Select>
      <Tabs isFitted flex={1} display="flex" flexDir="column">
        <TabList>
          <Tab>Marketing By Target {selectedType}</Tab>
        </TabList>

        <TabPanels flex={1}>
          <TabPanel height="100%">
            {selectedType === "zipcodes" && <ZipcodeTab onClose={onClose} />}
            {selectedType === "stores" && <LoadedStoresTab onClose={onClose} />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

const MarketingBtn = ({
  loading,
  userResources,
  products,
  organizationId,
  isSelectingOnMap,
  mapSelection,
  fbIntegration,
  openIntegrationsModal,
  setShouldOpenDashboard,
  setIsSelecting,
  setSelection,
  loadedDemographics,
  orgConfig,
}) => {
  const zipcodesDisclosure = useDisclosure();
  const noIntegrationAlertDisclosure = useDisclosure();
  const noSelectionAlertDisclosure = useDisclosure();
  const manualSelectionDisclosure = useDisclosure();
  const [typeOfLastMenuSelection, setTypeOfLastMenuSelection] = useState();
  const [waitingForSelection, setWaitingForSelection] = useState();
  const location = useLocation();
  const zipcodeLabel = orgConfig?.zipcodeNameReplacement || "Zipcode";
  const storeLabel = orgConfig?.storeNameReplacement || "Store";

  const handleTargetClick = (type) => {
    setTypeOfLastMenuSelection(type);
    if (mapSelection?.[type]?.length) {
      zipcodesDisclosure.onOpen();
    } else {
      noSelectionAlertDisclosure.onOpen();
    }
  };

  useEffect(() => {
    if (
      waitingForSelection &&
      typeOfLastMenuSelection &&
      !isSelectingOnMap &&
      mapSelection?.[typeOfLastMenuSelection]?.length
    ) {
      zipcodesDisclosure.onOpen();
      setWaitingForSelection(false);
    }
  }, [
    isSelectingOnMap,
    mapSelection,
    typeOfLastMenuSelection,
    waitingForSelection,
    zipcodesDisclosure,
  ]);

  return !location.pathname?.includes("storelocator") ? (
    <Box>
      <AlertDialog
        onClose={noSelectionAlertDisclosure.onClose}
        isOpen={noSelectionAlertDisclosure.isOpen}
        isCentered
        closeOnOverlayClick
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            Marketing By Target {typeOfLastMenuSelection}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            You have not yet selected any {typeOfLastMenuSelection} for
            Marketing. Select option below to continue:
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              onClick={() => {
                noSelectionAlertDisclosure.onClose();
                setIsSelecting();
                setWaitingForSelection(true);
              }}
            >
              Select From Map
            </Button>
            <Button
              onClick={() => {
                noSelectionAlertDisclosure.onClose();
                manualSelectionDisclosure.onOpen();
              }}
            >
              Add Manually
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {manualSelectionDisclosure.isOpen && (
        <ManualSelection
          onClose={manualSelectionDisclosure.onClose}
          isOpen={manualSelectionDisclosure.isOpen}
          typeOfLastMenuSelection={typeOfLastMenuSelection}
          zipcodesDisclosure={zipcodesDisclosure}
          noSelectionAlertDisclosure={noSelectionAlertDisclosure}
        />
      )}
      <AlertDialog
        onClose={noIntegrationAlertDisclosure.onClose}
        isOpen={noIntegrationAlertDisclosure.isOpen}
        isCentered
        closeOnOverlayClick
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            Looks like you haven’t integrated a Social Media Account yet for Ads
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Go to integrations to set up your Ads Social Media Account
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              variant="solid"
              colorScheme="blue"
              onClick={() => {
                noIntegrationAlertDisclosure.onClose();
                openIntegrationsModal();
              }}
            >
              INTEGRATIONS
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Menu>
        <MenuButton
          as={"div"}
          _hover={{ textDecoration: "underline" }}
          disabled={!userResources?.includes(RESOURCES.MARKETING_BTN)}
          onClick={(e) => {
            if (!userResources?.includes(RESOURCES.MARKETING_BTN)) {
              e.preventDefault();
              toast.warn("You don't have access to this");
              return;
            }
            if (!fbIntegration?.connection) {
              e.preventDefault();
              noIntegrationAlertDisclosure.onOpen();
            }
          }}
        >
          <Button
            id="marketing-btn"
            className="marketing-btn"
            variant="link"
            as="div"
            padding="12px 10px"
            isActive={noIntegrationAlertDisclosure.isOpen}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            isDisabled={!userResources?.includes(RESOURCES.MARKETING_BTN)}
            leftIcon={
              <IoMegaphone
                id="marketing-btn"
                className="marketing-btn"
                size="1rem"
              />
            }
            iconSpacing={0}
            size="xs"
          >
            Marketing
          </Button>
        </MenuButton>
        <MenuList>
          <MenuGroup>
            <MenuItem onClick={() => handleTargetClick("stores")}>
              Target Selected {pluralize(storeLabel)}
            </MenuItem>
            <MenuItem onClick={() => handleTargetClick("zipcodes")}>
              Target Selected {pluralize(zipcodeLabel)}
            </MenuItem>
          </MenuGroup>
          <MenuGroup>
            <MenuItem
              onClick={() =>
                setShouldOpenDashboard((old) => ({
                  ...(old || {}),
                  facebook: true,
                }))
              }
            >
              Your Campaigns
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>

      <ResponsiveModal
        isOpen={zipcodesDisclosure.isOpen && !isSelectingOnMap}
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <ModalCloseButton onClick={zipcodesDisclosure.onClose} />
            <EngageTabs
              onClose={zipcodesDisclosure.onClose}
              type={typeOfLastMenuSelection}
            />
          </ModalBody>
        </ModalContent>
      </ResponsiveModal>
    </Box>
  ) : (
    <></>
  );
};

export default connect(
  (state) => ({
    fbIntegration: state.integrations.activeIntegrations?.facebook,
    loading:
      state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
        ?.loading,
    userResources: state.app.currentUser?.resources,
    isSelectingOnMap:
      state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
        ?.isSelecting,
    mapSelection:
      state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
        ?.selection,
    orgConfig: state.app.orgProperties?.properties,
  }),
  (dispatch) => ({
    openIntegrationsModal: () => dispatch(toggleIntegrationsModal(true)),
    setShouldOpenDashboard: (payload) => dispatch(setShouldOpen(payload)),
    setIsSelecting: () => dispatch(setIsSelectingOnMap(true)),
    setSelection: (newSelection) => dispatch(setMapSelection(newSelection)),
  })
)(MarketingBtn);
