import { delete as axiosDelete, get, post, put } from "axios";
import { getAuth } from "firebase/auth";
import TagManager from "react-gtm-module";
import { toast } from "react-toastify";
import {
  api_analytics_county,
  api_analytics_major_city,
  api_analytics_national_avg,
  api_analytics_state,
  api_annotation,
  api_delivery_entity,
  api_delivery_entity_indicators,
  api_delivery_inventory,
  api_delivery_product,
  api_delivery_store_locator_recommendations,
  api_delivery_store_locator_recommendations_fulfill,
  api_delivery_store_locator_recommendations_stores,
  api_delivery_store_locator_recommendations_summary,
  api_demographics,
  api_favorites,
  api_geolayer,
  api_organization,
  api_organization_properties,
  api_predict,
  api_predict_fb_sales,
  api_products,
  api_product_sales,
  api_product_store,
  api_product_stores_batch,
  api_properties,
  api_recommendations_backward_store_locator_recommendations,
  api_recommendations_backward_store_locator_recommendations_fulfill,
  api_recommendations_backward_store_locator_recommendations_stores,
  api_recommendations_backward_store_locator_recommendations_summary,
  api_role,
  api_search_chains,
  api_search_stores,
  api_shared_views,
  api_stores,
  api_stores_batch,
  api_stores_overview,
  api_store_import_history,
  api_store_locator_generate_storelocator,
  api_store_locator_inventory,
  api_store_locator_store_reviews,
  api_superset_guest_token,
  api_support_ticket,
  api_topic_modeling_scores,
  api_twitter_indicators,
  api_user_role,
  api_views,
  api_website_sales,
  api_zip3_indicators,
  api_zipcodes_in_radius,
  base_url,
  list_users,
} from "../constants/serviceConfig";
import { Product } from "../domain/Product";
import { Store } from "../domain/Store";
import { StoreReview } from "../domain/StoreReview";

export function handleErrorMessage(message, error, noToast, response) {
  const tagManagerArgs = {
    dataLayer: {
      event: "error",
      "gtm.errorMessage": `APIError: ${message}`,
      "gtm.errorLineNumber": error.config?.params?.toString(),
      "gtm.errorUrl": error.config?.url,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
  if (!noToast) {
    toast.error(
      `${message}: ${response?.data?.msg || response?.data?.message}`
    );
  }
  if (response) {
    switch (response.data.msg) {
      case "Token has expired":
        const auth = getAuth();
        auth.signOut().then();
        break;

      default:
        break;
    }
  }
}

const errorHandler = (error, noToast) => {
  const response = error.response;
  let message = error.message;
  const code = response?.status || 0;
  switch (code) {
    case 400:
      message = "Item already exists";
      break;
    case 401:
      message = "Not authorized";
      break;
    case 402:
      message = "No access";
      break;
    case 408:
      message = "Your request took too long";
      break;

    default:
      break;
  }
  handleErrorMessage(message, error, noToast, response);
};

export function getParams(parameters) {
  let params;
  if (Object.values(parameters || {}).filter((val) => !!val).length) {
    params = new URLSearchParams();
    Object.keys(parameters).forEach((key) => {
      if (typeof parameters[key] !== "string" && parameters[key]?.length) {
        parameters[key].forEach((param) => {
          params.append(key, param);
        });
      } else if (
        ["string", "number", "boolean"].includes(typeof parameters[key])
      ) {
        params.append(key, parameters[key]);
      }
    });
  }
  let url = base_url;
  return { params, url };
}

export async function getProperties(accessToken, endpoint, parameters) {
  try {
    let { params, url } = getParams(parameters);
    url = url + api_properties + (endpoint ? `/${endpoint}` : "");
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error, true);
    throw error;
  }
}

export async function getOrgProperties(accessToken, id) {
  try {
    let url = base_url;
    url = url + api_organization_properties + (id ? `/${id}` : "");
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error, true);
    throw error;
  }
}

export async function postOrgProperties(props, accessToken) {
  try {
    let url = base_url;
    url = url + api_organization_properties;
    const result = await post(url, props, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function putOrgProperties(newProperties, accessToken) {
  try {
    let url = base_url;
    url = url + api_organization_properties + `/${newProperties.id}`;
    const result = await put(url, newProperties, {
      headers: { Authorization: `Bearer ${accessToken}`, "If-Match": "*" },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getRoles(accessToken) {
  try {
    let params = new URLSearchParams();
    params.append("name", "*");
    let url = base_url;
    url = url + api_role;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function putRole(newRole, accessToken) {
  try {
    let url = base_url;
    url = url + api_role + `/${newRole.id}`;
    const result = await put(url, newRole, {
      headers: { Authorization: `Bearer ${accessToken}`, "If-Match": "*" },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postRole(role, accessToken) {
  try {
    let url = base_url;
    url = url + api_role;
    const result = await post(url, role, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postUserRole(userRole, accessToken) {
  try {
    let url = base_url;
    url = url + api_user_role;
    const result = await post(url, userRole, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function deleteRole(id, accessToken) {
  try {
    let url = base_url;
    url = url + api_role + `/${id}`;
    const result = await axiosDelete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getPrediction(
  audiences = [],
  stores = [],
  zipcodes = [],
  predictionType,
  rankingThreshold,
  accessToken,
  version = "v2"
) {
  try {
    let params = new URLSearchParams();
    let paramsObj = {
      stores,
      zipcodes,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key].forEach((param) => {
        params.append(key, param);
      });
    });
    if (audiences?.length) params.append("audience", audiences.join(","));
    if (predictionType) params.append("prediction_type", predictionType);
    if (rankingThreshold) params.append("filter", rankingThreshold);
    let url = base_url;
    url =
      url + (version === "v1" ? api_predict : [api_predict, version].join("_"));
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getPredictFBSales(store_id, accessToken) {
  try {
    let params = new URLSearchParams();
    params.append("store_id", store_id);
    let url = base_url;
    url = url + api_predict_fb_sales;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function listUsers(userId, accessToken) {
  try {
    let url = base_url;
    url = url + list_users + (userId ? `/${userId}` : "");
    const result = await get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    return undefined;
  }
}

export async function getUserViews(userId, accessToken) {
  try {
    let url = base_url;
    url = url + api_views;
    const result = await get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { user_id: userId },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function putUser(newUser, accessToken) {
  try {
    let url = base_url;
    url = url + list_users + `/${newUser.id}`;
    const result = await put(url, newUser, {
      headers: { Authorization: `Bearer ${accessToken}`, "If-Match": "*" },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getViews(viewId, accessToken) {
  try {
    let url = base_url;
    url = url + api_views + `/${viewId}`;
    const result = await get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    return viewId ? undefined : [];
  }
}

export async function getSharedViews(
  userViewId,
  accessToken,
  view_id,
  user_id
) {
  try {
    let url = base_url;
    url = url + api_shared_views + (userViewId ? `/${userViewId}` : "");
    let params = new URLSearchParams();
    view_id && params.append("view_id", view_id);
    user_id && params.append("user_id", user_id);
    const result = await get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    return userViewId ? undefined : [];
  }
}

export async function postSharedView(sharedView, accessToken) {
  try {
    let url = base_url;
    url = url + api_shared_views;
    const result = await post(url, sharedView, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postFavorite(favorite, accessToken) {
  try {
    let url = base_url;
    url = url + api_favorites;
    const result = await post(url, favorite, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function deleteFavorite(id, accessToken) {
  try {
    let url = base_url;
    url = url + api_favorites + `/${id}`;
    const result = await axiosDelete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postAnnotation(annotation, accessToken) {
  try {
    let url = base_url;
    url = url + api_annotation;
    const result = await post(url, annotation, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function putAnnotation(note, accessToken) {
  try {
    let url = base_url;
    url = url + api_annotation + `/${note.id}`;
    const result = await put(url, note, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function deleteAnnotation(id, accessToken) {
  try {
    let url = base_url;
    url = url + api_annotation + `/${id}`;
    const result = await axiosDelete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function putSharedView(userView, accessToken) {
  try {
    let url = base_url;
    url = url + api_shared_views + `/${userView.id}`;
    const result = await put(url, userView, {
      headers: { Authorization: `Bearer ${accessToken}`, "If-Match": "*" },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function deleteSharedView(id, accessToken) {
  try {
    let url = base_url;
    url = url + api_shared_views + `/${id}`;
    const result = await axiosDelete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postView(newView, accessToken) {
  try {
    let url = base_url;
    url = url + api_views;
    const result = await post(url, newView, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function putView(newView, accessToken) {
  try {
    let url = base_url;
    url = url + api_views + `/${newView.id}`;
    const result = await put(url, newView, {
      headers: { Authorization: `Bearer ${accessToken}`, "If-Match": "*" },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function deleteView(id, accessToken) {
  try {
    let url = base_url;
    url = url + api_views + `/${id}`;
    const result = await axiosDelete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getLayer(layerId, accessToken) {
  try {
    let url = base_url;
    url = url + api_geolayer + `/${layerId}`;
    const result = await get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    return layerId ? undefined : [];
  }
}

export async function postLayer(newLayer, accessToken) {
  try {
    let url = base_url;
    url = url + api_geolayer;
    const result = await post(url, newLayer, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function putLayer(newLayer, accessToken) {
  try {
    let url = base_url;
    url = url + api_geolayer + `/${newLayer.id}`;
    const result = await put(url, newLayer, {
      headers: { Authorization: `Bearer ${accessToken}`, "If-Match": "*" },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function deleteLayer(id, accessToken) {
  try {
    let url = base_url;
    url = url + api_geolayer + `/${id}`;
    const result = await axiosDelete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error, true);
    throw error;
  }
}

export async function getDeliveryProducts(
  accessToken,
  name,
  brand,
  country,
  limit
) {
  try {
    let url = base_url;
    url = url + api_delivery_product;

    const result = await get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: {
        name,
        brand,
        limit: limit === null ? null : limit ?? 100,
        country,
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getDeliveryEntities(
  accessToken,
  state,
  uid = [],
  country = "US",
  limit,
  chain = [],
  zipcode = [],
  source = [],
  store_capabilities = [],
  insights = [],
  product_uid = []
) {
  try {
    let url = base_url;
    url = url + api_delivery_entity;

    let params = new URLSearchParams();
    let paramsObj = {
      uid,
      chain,
      zipcode,
      source,
      store_capabilities,
      insights,
      product_uid,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key].forEach((param) => {
        params.append(key, param);
      });
    });
    params.append("country", country);
    if (state) params.append("state", state);
    if (limit) params.append("limit", limit);

    const result = await get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getDeliveryInventory(
  accessToken,
  delivery_product_uid = [],
  delivery_entity_uid = [],
  source = [],
  aisle_name = [],
  limit,
  country = "US",
  onlyWhereAvailable
) {
  try {
    let url = base_url;
    url = url + api_delivery_inventory;

    let params = new URLSearchParams();
    let paramsObj = {
      delivery_product_uid,
      delivery_entity_uid,
      source,
      aisle_name,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key].forEach((param) => {
        params.append(key, param);
      });
    });
    if (limit) params.append("limit", limit);
    if (typeof onlyWhereAvailable === "boolean")
      params.append("is_product_available", onlyWhereAvailable);
    const result = await get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getProducts(accessToken, id) {
  try {
    let url = base_url;
    url = url + api_products + (id ? `/${id}` : "");

    const result = await get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { org_only: true },
    });

    return result?.data?.map((d) => new Product(d));
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function deleteProduct(productId, accessToken) {
  try {
    let url = base_url;
    url = url + api_products + `/${productId}`;
    const result = await axiosDelete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postProduct(newProduct, accessToken) {
  try {
    let url = base_url;
    url = url + api_products;
    const result = await post(url, newProduct, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function putProduct(updatedProduct, accessToken) {
  try {
    let url = base_url;
    url = url + api_products + `/${updatedProduct.id}`;
    const result = await put(url, updatedProduct, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postGenStorelocator(accessToken, update = false) {
  try {
    let url = base_url;
    url = url + api_store_locator_generate_storelocator;
    const result = await post(url, undefined, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      params: { update },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postProductStore(newProductStore, accessToken) {
  try {
    let url = base_url;
    url = url + api_product_store;
    const result = await post(url, newProductStore, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function putProductStore(newProductStore, accessToken, id) {
  try {
    let url = base_url;
    url = url + api_product_store + "/" + id;
    const result = await put(url, newProductStore, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function deleteProductStore(id, accessToken) {
  try {
    let url = base_url;
    url = url + api_product_store + `/${id}`;
    const result = await axiosDelete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getProductStore(store_id, accessToken) {
  try {
    let url = base_url;
    url = url + api_product_store;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { store_id },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getSales(store_id, accessToken) {
  try {
    let url = base_url;
    url = url + api_product_sales;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { store_id },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postSale(newSale, accessToken) {
  try {
    let url = base_url;
    url = url + api_product_sales;
    const result = await post(url, newSale, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function putSale(updatedSale, accessToken) {
  try {
    let url = base_url;
    url = url + api_product_sales + `/${updatedSale.id}`;
    const result = await put(url, updatedSale, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function deleteSale(saleId, accessToken) {
  try {
    let url = base_url;
    url = url + api_product_sales + `/${saleId}`;
    const result = await axiosDelete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getStoresOverview(
  start_date = new Date(),
  end_date = new Date(),
  accessToken
) {
  try {
    let url = base_url;
    url = url + api_stores_overview;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { start_date, end_date },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getStores(
  states = [],
  chains = [],
  zipcodes = [],
  category = [],
  stores_id = [],
  search_query,
  orgOnly,
  accessToken,
  country,
  insights = [],
  ids = [],
  counties = [],
  cities = [],
  limit,
  type = [],
  q = []
) {
  try {
    let params = new URLSearchParams();
    let paramsObj = {
      chains,
      states,
      zipcodes,
      ids,
      category,
      stores_id,
      insights,
      counties,
      cities,
      type,
      q,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key].forEach((param) => {
        params.append(key, param);
      });
    });
    if (limit) params.append("limit", limit);
    if (search_query) params.append("search_query", search_query);
    if (typeof orgOnly === "boolean") params.append("org_only", orgOnly);
    if (country) params.append("country", country);
    let url = base_url;
    url = url + api_stores;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data?.map((d) => new Store(d));
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getStoreLocatorRecommendationStores(
  accessToken,
  include_previous_rejected = false
) {
  try {
    let url = base_url;
    url = url + api_delivery_store_locator_recommendations_stores;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { include_previous_rejected },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getStoreLocatorRecommendation(accessToken) {
  try {
    let url = base_url;
    url = url + api_delivery_store_locator_recommendations;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getStoreLocatorRecommendationsSummary(accessToken) {
  try {
    let url = base_url;
    url = url + api_delivery_store_locator_recommendations_summary;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getStoreLocatorBckwRecommendationsSummary(accessToken) {
  try {
    let url = base_url;
    url =
      url + api_recommendations_backward_store_locator_recommendations_summary;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getStoreLocatorBckwRecommendationsStores(accessToken) {
  try {
    let url = base_url;
    url =
      url + api_recommendations_backward_store_locator_recommendations_stores;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getStoreLocatorBckwRecommendations(accessToken) {
  try {
    let url = base_url;
    url = url + api_recommendations_backward_store_locator_recommendations;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postStoreLocatorBckwRecommendationsFulfill(accessToken) {
  try {
    let url = base_url;
    url =
      url + api_recommendations_backward_store_locator_recommendations_fulfill;
    const result = await post(url, undefined, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error, true);
    throw error;
  }
}

export async function putStoreLocatorBckwRecommendationsStores(
  is_approved,
  accessToken,
  store_ids = []
) {
  try {
    let url = base_url;
    url =
      url + api_recommendations_backward_store_locator_recommendations_stores;
    const result = await put(
      url,
      {
        is_approved,
        store_ids,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "If-Match": "*",
          "Content-Type": "application/json",
        },
      }
    );

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function putStoreLocatorBckwRecommendations(
  is_approved,
  accessToken,
  recommendation_ids = [],
  store_ids = []
) {
  try {
    let url = base_url;
    url = url + api_recommendations_backward_store_locator_recommendations;
    const result = await put(
      url,
      {
        is_approved,
        store_ids,
        recommendation_ids,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "If-Match": "*",
          "Content-Type": "application/json",
        },
      }
    );

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function putStoreLocatorRecommendationsStores(
  is_approved,
  accessToken,
  parent_ids = []
) {
  try {
    let url = base_url;
    url = url + api_delivery_store_locator_recommendations_stores;
    const result = await put(
      url,
      {
        is_approved,
        parent_ids,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "If-Match": "*",
          "Content-Type": "application/json",
        },
      }
    );

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function putStoreLocatorRecommendations(
  is_approved,
  accessToken,
  recommendation_ids = [],
  parent_ids = []
) {
  try {
    let url = base_url;
    url = url + api_delivery_store_locator_recommendations;
    const result = await put(
      url,
      {
        is_approved,
        parent_ids,
        recommendation_ids,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "If-Match": "*",
          "Content-Type": "application/json",
        },
      }
    );

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postStoreLocatorRecommendationsFulfill(accessToken) {
  try {
    let url = base_url;
    url = url + api_delivery_store_locator_recommendations_fulfill;
    const result = await post(url, undefined, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error, true);
    throw error;
  }
}

export async function getStoreReviews(store_id, accessToken) {
  try {
    let url = base_url;
    url = url + api_store_locator_store_reviews + "/" + store_id;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result?.data?.map((d) => new StoreReview(d));
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function putStoreReview(updatedStoreReview, accessToken) {
  try {
    let url = base_url;
    url = url + api_store_locator_store_reviews + `/${updatedStoreReview.uid}`;
    const result = await put(url, updatedStoreReview, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postStoreReview(store_id, newStoreReview, accessToken) {
  try {
    let url = base_url;
    url = url + api_store_locator_store_reviews + "/" + store_id;
    const result = await post(url, newStoreReview, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error, true);
    throw error;
  }
}

export async function getStoreLocatorInventory(
  entity_type = [],
  zipcode = [],
  delivery_product_uid = [],
  accessToken
) {
  try {
    let url = base_url;
    url = url + api_store_locator_inventory;
    let params = new URLSearchParams();
    let paramsObj = {
      entity_type,
      zipcode,
      delivery_product_uid,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key].forEach((param) => {
        params.append(key, param);
      });
    });
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postStore(newStore, accessToken) {
  try {
    let url = base_url;
    url = url + api_stores;
    const result = await post(url, newStore, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error, true);
    throw error;
  }
}

export async function getOrganizations(accessToken) {
  try {
    let url = base_url;
    url = url + api_organization;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postOrganization(name, accessToken) {
  try {
    let url = base_url;
    url = url + api_organization;
    const result = await post(
      url,
      { name },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return result?.data;
  } catch (error) {
    errorHandler(error, true);
    throw error;
  }
}

export async function putStore(updatedStore, accessToken) {
  try {
    let url = base_url;
    url = url + api_stores + `/${updatedStore.id}`;
    const result = await put(url, updatedStore, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getDemographics(
  term,
  nationalities = [],
  variables = [],
  states = [],
  counties = [],
  cities = [],
  zipcodes = [],
  otherFilters = {},
  accessToken,
  dynamic_filter = [],
  count_consumption = true,
  country,
  insights = []
) {
  try {
    let params = new URLSearchParams();
    let q = variables.concat(...nationalities);
    let paramsObj = {
      q,
      states,
      counties,
      cities,
      zipcodes,
      dynamic_filter,
      insights,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key]
        .filter((param) => !!param)
        .forEach((param) => {
          params.append(key, param);
        });
    });
    Object.keys(otherFilters).forEach((key) => {
      const parts = `${otherFilters[key]}`.split(":");
      if (
        !parts?.[0] ||
        parts?.[0] === "undefined" ||
        (parts.length > 1 &&
          (!parts[0] || !parts[1] || parts[1] === "undefined"))
      ) {
        return;
      }
      params.append(key, otherFilters[key]);
    });
    params.append("count_consumption", count_consumption);
    if (country) params.append("country", country);

    let url = base_url;
    url = url + api_demographics;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      params,
    });

    return result.data;
  } catch (error) {
    errorHandler(error);
    return error?.response?.data;
  }
}

export async function getDemographicsByCounty(
  nationalities = [],
  variables = [],
  location = [],
  accessToken
) {
  try {
    let params = new URLSearchParams();
    let q = variables.concat(...nationalities);
    let counties = location;
    let paramsObj = {
      q,
      counties,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key].forEach((param) => {
        params.append(key, param);
      });
    });

    let url = base_url;
    url = url + api_analytics_county;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      params,
    });

    return result.data;
  } catch (error) {
    errorHandler(error);
    return { data: { content: { data: [], type: "" } } };
  }
}

export async function getDemographicsByCity(
  nationalities = [],
  variables = [],
  location = [],
  states = [],
  accessToken
) {
  try {
    let params = new URLSearchParams();
    let q = variables.concat(...nationalities);
    let cities = location;
    let paramsObj = {
      q,
      cities,
      states,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key].forEach((param) => {
        params.append(key, param);
      });
    });

    let url = base_url;
    url = url + api_analytics_major_city;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      params,
    });

    return result.data;
  } catch (error) {
    errorHandler(error);
    return { data: { content: { data: [], type: "" } } };
  }
}

export async function getDemographicsByState(
  nationalities = [],
  variables = [],
  states = [],
  accessToken
) {
  try {
    let params = new URLSearchParams();
    let q = variables.concat(...nationalities);
    let paramsObj = {
      q,
      states,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key].forEach((param) => {
        params.append(key, param);
      });
    });

    let url = base_url;
    url = url + api_analytics_state;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      params,
    });

    return result.data;
  } catch (error) {
    errorHandler(error);
    return { data: { content: { data: [], type: "" } } };
  }
}

export async function getDemographicsNationalAvg(accessToken) {
  try {
    let url = base_url;
    url = url + api_analytics_national_avg;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return result.data;
  } catch (error) {
    errorHandler(error);
    return { data: { content: { data: [], type: "" } } };
  }
}

export async function getZipcodesInRadius(
  latitude,
  longitude,
  radius_mi,
  accessToken
) {
  try {
    let params = new URLSearchParams();
    params.append("latitude", latitude);
    params.append("longitude", longitude);
    params.append("radius_mi", radius_mi);
    let url = base_url;
    url = url + api_zipcodes_in_radius;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      params,
    });

    return result.data;
  } catch (error) {
    errorHandler(error);
    return { data: { content: { data: [], type: "" } } };
  }
}

export async function getTopics(zipcodes = [], accessToken) {
  try {
    let params = new URLSearchParams();
    zipcodes.forEach((param) => {
      params.append("zipcodes", param);
    });
    let url = base_url;
    url = url + api_topic_modeling_scores;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getTwitterIndicators(
  date,
  zipcodes = [],
  states = [],
  accessToken
) {
  try {
    let params = new URLSearchParams();
    if (date) params.append("date", date);
    zipcodes.forEach((param) => {
      params.append("zipcode", param);
    });
    states.forEach((param) => {
      params.append("state", param);
    });
    let url = base_url;
    url = url + api_twitter_indicators;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getDeliveryEntityIndicators(ids = [], accessToken) {
  try {
    let params = new URLSearchParams();
    ids.forEach((id) => {
      params.append("delivery_entity_uid", id);
    });
    let url = base_url;
    url = url + api_delivery_entity_indicators;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getZip3Indicators(zip3, accessToken) {
  try {
    let params = new URLSearchParams();
    if (zip3) params.append("zip3", zip3);
    let url = base_url;
    url = url + api_zip3_indicators;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getWebsiteSales(start_date, end_date, accessToken) {
  try {
    let params = new URLSearchParams();
    if (start_date) params.append("start_date", start_date);
    if (end_date) params.append("end_date", end_date);
    let url = base_url;
    url = url + api_website_sales;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postSearchStores(
  accessToken,
  stores,
  search_org,
  distance_threshold_meters,
  org_id
) {
  try {
    const body = { stores, search_org, distance_threshold_meters, org_id };
    let url = base_url;
    url = url + api_search_stores;
    const result = await post(url, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postSearchChains(accessToken, stores) {
  try {
    const body = { stores };
    let url = base_url;
    url = url + api_search_chains;
    const result = await post(url, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function putStoresBatch(accessToken, stores) {
  try {
    const body = { stores };
    let url = base_url;
    url = url + api_stores_batch;
    const result = await put(url, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postStoresBatch(accessToken, stores) {
  try {
    const body = { stores };
    let url = base_url;
    url = url + api_stores_batch;
    const result = await post(url, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function putProductStoresBatch(accessToken, product_stores) {
  try {
    const body = { product_stores };
    let url = base_url;
    url = url + api_product_stores_batch;
    const result = await put(url, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postProductStoresBatch(accessToken, product_stores) {
  try {
    const body = { product_stores };
    let url = base_url;
    url = url + api_product_stores_batch;
    const result = await post(url, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function fetchSupersetGuestToken(accessToken) {
  try {
    let url = base_url;
    url = url + api_superset_guest_token;
    const result = await post(url, undefined, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function deleteProductStoresBatch(
  accessToken,
  product_store_id = [],
  noToast = false
) {
  try {
    let params = new URLSearchParams();
    let paramsObj = {
      product_store_id,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key].forEach((param) => {
        params.append(key, param);
      });
    });
    let url = base_url;
    url = url + api_product_stores_batch;
    const result = await axiosDelete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error, noToast);
    throw error;
  }
}

export async function postStoreImportHistory(accessToken, importHistoryItem) {
  try {
    const body = importHistoryItem;
    let url = base_url;
    url = url + api_store_import_history;
    const result = await post(url, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getStoreImportHistory(accessToken) {
  try {
    let url = base_url;
    url = url + api_store_import_history;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function putStoreImportHistory(accessToken, importHistoryItem) {
  try {
    const body = importHistoryItem;
    let url = base_url;
    url = url + api_store_import_history + `/${importHistoryItem.id}`;
    const result = await put(url, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postSupportTicket(accessToken, supportTicket) {
  try {
    const body = supportTicket;
    let url = base_url;
    url = url + api_support_ticket;
    const result = await post(url, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error, true);
    throw error;
  }
}
