import { useState } from "react";
import { RecommendationProductStore } from "../../domain/RecommendationProductStore";
import { StoreLocatorRecommendationStore } from "../../domain/StoreLocatorRecommendationStore";
import {
  getStoreLocatorBckwRecommendations,
  getStoreLocatorBckwRecommendationsStores,
  getStoreLocatorRecommendation,
  getStoreLocatorRecommendationStores,
  postStoreLocatorBckwRecommendationsFulfill,
  postStoreLocatorRecommendationsFulfill,
  putStoreLocatorBckwRecommendations,
  putStoreLocatorBckwRecommendationsStores,
  putStoreLocatorRecommendations,
  putStoreLocatorRecommendationsStores,
} from "../../services/api.service";

type ReturnType = {
  getStores: (
    accessToken: string | undefined
  ) => Promise<StoreLocatorRecommendationStore[]>;
  fulfill: (accessToken: string | undefined) => Promise<void>;
  approveOrDeny: (
    is_approved: boolean,
    accessToken: string | undefined,
    store_ids?: number[]
  ) => Promise<void>;
  approveOrDenyRelations: (
    is_approved: boolean,
    accessToken: string | undefined,
    ids?: number[]
  ) => Promise<void>;
  storeAttrName: "parent_store" | "store";
  proxyName: "StoreRecommendationsProxy" | "StoreDiscontinuedProxy";
  approvedAction: "added" | "disabled";
};

export const useStoreRecommendations = (
  type: "new" | "discontinued"
): ReturnType => {
  const [productRecommendations, setProductRecommendations] = useState<
    RecommendationProductStore[]
  >([]);
  const [storeRecommendations, setStoreRecommendations] = useState<
    StoreLocatorRecommendationStore[]
  >([]);
  const storeAttrName = type === "new" ? "parent_store" : "store";
  const storeIdName = type === "new" ? "parent_id" : "store_id";
  const storePredicateName =
    type === "new" ? "is_new_store" : "is_store_discontinued";

  const doGetStores = async (accessToken: string | undefined) => {
    const getRecommendationStores =
      type === "new"
        ? getStoreLocatorRecommendationStores
        : getStoreLocatorBckwRecommendationsStores;
    const getRecommendations =
      type === "new"
        ? getStoreLocatorRecommendation
        : getStoreLocatorBckwRecommendations;
    const productRelations = (await getRecommendations(
      accessToken
    )) as RecommendationProductStore[];
    setProductRecommendations(productRelations);
    const recommendationStores = await getRecommendationStores(accessToken);

    const newStoreRecommendations = (recommendationStores || []).map(
      (s: any) => {
        const relations = productRelations?.filter(
          (pr) =>
            (type === "new" ? pr.store_id || pr.parent_id : pr.store_id) ===
            s[storeIdName]
        );
        const is_approved = !s.id
          ? relations?.every((r) => !!r.is_approved) || null
          : s.is_approved;
        return {
          ...s,
          is_approved,
          productRelations: relations,
        };
      }
    ) as StoreLocatorRecommendationStore[];
    setStoreRecommendations(newStoreRecommendations);
    return newStoreRecommendations;
  };

  const handleFulfill = async (accessToken: string | undefined) => {
    // Approve product recommendations outside new stores (existing stores)
    const productRecommendationsNewStores = (storeRecommendations || [])
      .flatMap((s) => s.productRelations || [])
      .map((s) => s.id);
    const relationsToApprove = productRecommendations
      .filter((pr) => !productRecommendationsNewStores.includes(pr.id))
      .map((s) => s.id);
    await (type === "new"
      ? putStoreLocatorRecommendations
      : putStoreLocatorBckwRecommendations)(
      true,
      accessToken,
      relationsToApprove
    );

    // Post fulfill for recommendations
    const postFulfill =
      type === "new"
        ? postStoreLocatorRecommendationsFulfill
        : postStoreLocatorBckwRecommendationsFulfill;
    await postFulfill(accessToken);
  };
  return {
    getStores: doGetStores,
    fulfill: handleFulfill,
    approveOrDeny:
      type === "new"
        ? putStoreLocatorRecommendationsStores
        : putStoreLocatorBckwRecommendationsStores,
    approveOrDenyRelations:
      type === "new"
        ? putStoreLocatorRecommendations
        : putStoreLocatorBckwRecommendations,
    storeAttrName,
    proxyName:
      type === "new" ? "StoreRecommendationsProxy" : "StoreDiscontinuedProxy",
    approvedAction: type === "new" ? "added" : "disabled",
  };
};
