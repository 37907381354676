import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { cloneDeep, merge } from "lodash";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import {
  OrgProperties,
  selectCurrentUser,
  selectOrgProperties,
  selectUserResources,
  updateOrgProperties,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import CheckboxGridInput from "../../components/CheckboxGridInput";
import PageLayout from "../../components/PageLayout";
import { PageSection } from "../../components/PageSection";
import { RESOURCES } from "../../constants/user-constants";

function NotificationSettingsScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useAppSelector(selectCurrentUser);
  const orgProperties = useAppSelector(selectOrgProperties);
  const userResources = useAppSelector(selectUserResources);
  const dispatch = useAppDispatch();

  const putOrg = async (
    changes: any | ((oldProps: OrgProperties | undefined) => any)
  ) => {
    const newOrgConfig = cloneDeep(orgProperties);
    merge(
      newOrgConfig,
      typeof changes === "function" ? changes(newOrgConfig) : changes
    );
    // @ts-ignore
    const wrapped = await dispatch(updateOrgProperties(newOrgConfig));
    // @ts-ignore
    return unwrapResult(wrapped);
  };

  return (
    <PageLayout title="Notification settings">
      <VStack w="100%" spacing={10} textAlign="start">
        <PageSection title="Contact info" contentDirection="column">
          <FormControl>
            <FormLabel fontWeight="bold">Your email</FormLabel>
            <FormHelperText mt={0}>
              This is the email associated with your user. You can change it
              going to{" "}
              <Button
                variant={"link"}
                onClick={() =>
                  navigate(
                    location.pathname.includes("storelocator")
                      ? `/storelocator/my_account`
                      : "/my_account"
                  )
                }
              >
                My Account
              </Button>
            </FormHelperText>
            <Input value={currentUser.username} isDisabled />
          </FormControl>
          <FormControl>
            <FormLabel fontWeight="bold">Notification email</FormLabel>
            <FormHelperText mt={0}>
              Add the email address where you want to get information about your
              Dathic store locator updates, reports and recommendations.
            </FormHelperText>
            <Input
              placeholder={currentUser.username}
              isDisabled={!userResources.includes(RESOURCES.MANAGE_ORG)}
              defaultValue={orgProperties?.store_locator?.notificationEmail}
              onBlur={(e) => {
                const newName = e.currentTarget.value;
                putOrg({ store_locator: { notificationEmail: newName } });
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel fontWeight="bold">Notification phone</FormLabel>
            <FormHelperText mt={0}>
              Would you like to receive notifications via SMS?
            </FormHelperText>
            <Input
              type={"tel"}
              placeholder={"+1 111 111 1111"}
              isDisabled={!userResources.includes(RESOURCES.MANAGE_ORG)}
              defaultValue={orgProperties?.store_locator?.notificationPhone}
              onBlur={(e) => {
                const newName = e.currentTarget.value;
                putOrg({ store_locator: { notificationPhone: newName } });
              }}
            />
          </FormControl>
        </PageSection>
        <PageSection title="Notifications preferences">
          <CheckboxGridInput
            isDisabled={!userResources.includes(RESOURCES.MANAGE_ORG)}
            refForCheckboxes={undefined}
            options={[
              {
                value: "Product tips and new releases",
                label: "Product tips and new releases",
              },
              {
                value: "Resources and events",
                label: "Resources and events",
              },
              {
                value: "Promos and refer",
                label: "Promos and refer",
              },
            ]}
            value={orgProperties?.store_locator?.notificationPrefs}
            onChange={(value) => {
              putOrg({ store_locator: { notificationPrefs: value } });
            }}
            name="Subscriptions"
          />
        </PageSection>
        {!userResources.includes(RESOURCES.MANAGE_ORG) && (
          <Text>Contact your admin to change these preferences.</Text>
        )}
      </VStack>
    </PageLayout>
  );
}
export default NotificationSettingsScreen;
