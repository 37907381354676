import { ChevronDownIcon, CopyIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  Code,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagLabel,
  Textarea,
} from "@chakra-ui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AutocompleteInput from "../../components/AutocompleteInput";
import { RESOURCES } from "../../constants/user-constants";
import {
  deleteSharedView,
  getSharedViews,
  listUsers,
  postSharedView,
  putSharedView,
} from "../../services/api.service";
import { createAnonymousToken } from "../../services/auth.service";
import { createView, removeView, setViews } from "./mapViewsSlice";

function _ShareAccordion({ accessToken, activeView, currentUserId }) {
  const [shareURL, setShareURL] = useState({ url: "", error: "" });
  const [users, setUsers] = useState([]);
  const [usersSharedTo, setUsersSharedTo] = useState([]);
  const [usersToRemove, setUsersToRemove] = useState([]);

  useEffect(() => {
    listUsers(undefined, accessToken).then((results) => {
      setUsers(results);
    });

    if (activeView) {
      getSharedViews(undefined, accessToken, activeView.id).then(
        async (userViews) => {
          let userViewsWithUsers = [];
          for (let i = 0; i < userViews.length; i++) {
            const userView = userViews[i];

            const user = await listUsers(userView.user_id, accessToken);
            userViewsWithUsers.push({ ...userView, user });
          }
          setUsersSharedTo(userViewsWithUsers);
        }
      );
    }
  }, [accessToken, activeView]);

  const createShareLink = async () => {
    let anonymousToken;
    try {
      anonymousToken = await createAnonymousToken(accessToken);
      if (anonymousToken?.token) {
        postSharedView(
          {
            user_id: anonymousToken.user,
            view_id: activeView?.id,
            access: "read",
          },
          accessToken
        );
        setShareURL({
          url: `https://growth.dathic.com?token=${anonymousToken.token}`,
        });
      }
    } catch (error) {
      setShareURL({ error: error.message });
    }
  };

  const handleSearchResultClick = (user) => {
    let newUserView = { user, access: "read" };
    const foundUserIndex = usersToRemove.findIndex(
      (item) => item.user.id === user.id
    );
    if (foundUserIndex !== -1) {
      const foundUser = usersToRemove[foundUserIndex];

      setUsersToRemove((oldUsers) => {
        const newUsers = [...oldUsers];
        newUsers.splice(foundUserIndex, 1);
        return newUsers;
      });
      newUserView = { ...foundUser };
    }
    setUsersSharedTo((oldUserViews) => [...oldUserViews, newUserView]);
  };

  const removeUser = (index) => {
    setUsersToRemove((oldUsers) => [...oldUsers, usersSharedTo[index]]);
    setUsersSharedTo((oldUsers) => {
      const newUsers = [...oldUsers];
      newUsers.splice(index, 1);
      return newUsers;
    });
  };

  const changeUserAccess = (index, newAccess) => {
    setUsersSharedTo((oldUserViews) => {
      const newUserViews = [...oldUserViews];
      newUserViews[index] = { ...oldUserViews[index], access: newAccess };
      return newUserViews;
    });
  };

  const handleShareSave = async () => {
    toast("Sharing view to users", {
      toastId: "sharingToUsers",
      autoClose: false,
    });
    let successfulShares = [];
    let removed = 0;
    for (let i = 0; i < usersSharedTo.length; i++) {
      const userView = usersSharedTo[i];

      try {
        if (userView.id) {
          const userViewToUpdate = { ...userView };
          delete userViewToUpdate.user;
          delete userViewToUpdate.view;
          await putSharedView(userViewToUpdate, accessToken);
        } else {
          await postSharedView(
            {
              user_id: userView.user.id,
              view_id: activeView?.id,
              access: userView.access || "read",
            },
            accessToken
          );
        }

        successfulShares.push(userView);
      } catch (error) {
        toast.error(
          `Could not share to ${
            userView.user.name || userView.user.email || userView.user.username
          }.  ${error.message}`
        );
      }
    }
    for (let i = 0; i < usersToRemove.length; i++) {
      const userView = usersToRemove[i];

      try {
        if (userView.id) {
          await deleteSharedView(userView, accessToken);
        }

        removed += 1;
      } catch (error) {
        toast.error(
          `Could not remove ${
            userView.user.name || userView.user.email || userView.user.username
          }.  ${error.message}`
        );
      }
    }
    removed && toast.success(`${removed} users removed from view`);
    toast.update("sharingToUsers", {
      autoClose: true,
      type: "success",
      render: `Shared view to ${
        successfulShares.length === 1
          ? successfulShares[0].name ||
            successfulShares[0].email ||
            successfulShares[0].username
          : `${successfulShares.length} users`
      }`,
    });
  };

  return (
    <Accordion>
      <AccordionItem>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            Share with users
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <Stack w="100%">
            <AutocompleteInput
              options={users}
              getSuggestionValue={(user) =>
                user.name || user.email || user.username
              }
              inputProps={{ placeholder: "Add people" }}
              handleSearchResultClick={(selection) => {
                handleSearchResultClick(selection.suggestion);
              }}
              exclude={users?.filter(
                (user) =>
                  user.id === currentUserId ||
                  user.name.toLowerCase().includes("anonymous")
              )}
            />
            {usersSharedTo.map((userView, index) => (
              <Tag paddingTop={1} paddingBottom={1} display="flex" dir="row">
                <Avatar
                  mr={1}
                  name={
                    userView.user.name ||
                    userView.user.email ||
                    userView.user.username
                  }
                  colorScheme="blue"
                />
                <TagLabel flex={1}>
                  {userView.user.name ||
                    userView.user.email ||
                    userView.user.username}
                </TagLabel>
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                    {userView.access || "read"}
                  </MenuButton>
                  <MenuList>
                    <MenuOptionGroup
                      defaultValue="read"
                      type="radio"
                      onChange={(newAccess) =>
                        changeUserAccess(index, newAccess)
                      }
                      value={userView.access}
                    >
                      <MenuItemOption value="read">Read Only</MenuItemOption>
                      <MenuItemOption value="write">Edit</MenuItemOption>
                      <MenuItemOption value="owner">Owner</MenuItemOption>
                    </MenuOptionGroup>
                    <MenuDivider />
                    <MenuGroup>
                      <MenuItem onClick={() => removeUser(index)}>
                        Remove
                      </MenuItem>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Tag>
            ))}
          </Stack>
          {!!usersSharedTo?.length && (
            <Button
              float="right"
              mr="auto"
              mt={3}
              mb={3}
              onClick={handleShareSave}
            >
              Save
            </Button>
          )}
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            Create share link
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          {shareURL?.url && (
            <Flex dir="row" w="100%">
              <Code maxH={9} maxW="70%" overflowX="auto">
                {shareURL?.url}
              </Code>
              <IconButton
                icon={<CopyIcon />}
                onClick={() => navigator.clipboard.writeText(shareURL?.url)}
              />
            </Flex>
          )}
          {!shareURL?.url && (
            <Button mt={3} mb={3} float="right" onClick={createShareLink}>
              Create share link
            </Button>
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

const ShareAccordionPanel = connect((state) => ({
  accessToken: state.app.accessToken,
  activeView: state.mapViews.views[`view${state.mapViews.activeView}`],
  viewAccess: state.mapViews.viewAccess[state.mapViews.activeView],
  currentUserId: state.app.currentUser?.id,
}))(_ShareAccordion);

function _ViewInfoPanel({
  isCreatingView,
  activeView,
  activeViewIndex,
  changeViewsList,
  createNewView,
  creatingViewStatus,
  onClose,
}) {
  const [viewName, setViewName] = useState(
    isCreatingView ? "" : activeView?.name
  );
  const [viewDescription, setViewDescription] = useState(
    isCreatingView ? "" : activeView?.description
  );

  const handleSaveView = () => {
    changeViewsList((oldViews) => {
      const newList = { ...oldViews };
      newList[`view${activeViewIndex}`] = {
        ...newList[`view${activeViewIndex}`],
        name: viewName,
        description: viewDescription,
      };
      return newList;
    });
  };

  function handleAddView() {
    const newView = { name: viewName, description: viewDescription };
    createNewView(newView)
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        if (originalPromiseResult) {
          onClose();
        }
      });
  }
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (isCreatingView) {
          handleAddView();
        } else {
          handleSaveView();
        }
      }}
    >
      <Stack spacing={5}>
        <FormControl isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            placeholder="Map view name"
            value={viewName}
            onChange={(event) => setViewName(event.target.value)}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Description</FormLabel>
          <Textarea
            placeholder="Map view description"
            value={viewDescription}
            onChange={(event) => setViewDescription(event.target.value)}
          />
        </FormControl>
        <FormControl>
          <Button
            float="right"
            type="submit"
            isLoading={isCreatingView && creatingViewStatus === "loading"}
          >
            Save
          </Button>
        </FormControl>
      </Stack>
    </form>
  );
}

const ViewInfoPanel = connect(
  (state) => ({
    activeView: state.mapViews.views[`view${state.mapViews.activeView}`],
    activeViewIndex: state.mapViews.activeView,
    creatingViewStatus: state.mapViews.creatingViewStatus,
  }),
  (dispatch) => ({
    changeViewsList: (newView) => dispatch(setViews(newView)),
    createNewView: (newView) => dispatch(createView(newView)),
  })
)(_ViewInfoPanel);

function ViewSettingsModal({
  onClose,
  isOpen,
  isCreatingView,
  deletingView,
  doDeleteView,
  userResources,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const handleDeleteBtn = async () => {
    doDeleteView()
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        if (originalPromiseResult) {
          onClose();
        }
      });
  };
  useEffect(() => {
    if (isCreatingView) {
      setActiveTab(0);
    }
  }, [isCreatingView]);
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      size="xl"
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>
          {isCreatingView ? "Create a map" : "Map settings"}
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Tabs
            display="flex"
            flexDirection="row"
            index={activeTab}
            onChange={setActiveTab}
          >
            <TabList
              display="flex"
              flexDirection="column"
              borderBottom={null}
              borderRight="1px solid lightgrey"
            >
              <Tab>Name & Description</Tab>
              {!isCreatingView && (
                <Tab
                  isDisabled={!userResources?.includes(RESOURCES.VIEW_SHARE)}
                >
                  Share
                </Tab>
              )}
              {!isCreatingView && (
                <Tab
                  color="#e82a37"
                  isDisabled={!userResources?.includes(RESOURCES.VIEW_DELETE)}
                >
                  Danger Zone
                </Tab>
              )}
            </TabList>
            <TabPanels>
              <TabPanel>
                <ViewInfoPanel
                  isCreatingView={isCreatingView}
                  onClose={onClose}
                />
              </TabPanel>
              {!isCreatingView && (
                <TabPanel>
                  <ShareAccordionPanel />
                </TabPanel>
              )}
              {!isCreatingView && (
                <TabPanel>
                  <FormControl>
                    <Button
                      onClick={handleDeleteBtn}
                      isLoading={deletingView}
                      background="#e82a37"
                      color="white"
                    >
                      Delete View
                    </Button>
                    <FormHelperText>
                      All data related to the view will be removed
                    </FormHelperText>
                  </FormControl>
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default connect(
  (state) => ({
    activeView: state.mapViews.views[`view${state.mapViews.activeView}`],
    activeViewIndex: state.mapViews.activeView,
    deletingView: state.mapViews.deletingView,
    userResources: state.app.currentUser.resources,
  }),
  (dispatch) => ({ doDeleteView: () => dispatch(removeView()) })
)(ViewSettingsModal);
