import { Button, Text } from "@chakra-ui/react";
import { BiBook, BiPhone } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import PageLayout from "../../components/PageLayout";
import { PageSection } from "../../components/PageSection";

function HelpSupportScreen() {
  return (
    <PageLayout title="Help & Support">
      <PageSection
        title="Help & Documentation"
        icon={<BiBook />}
        contentProps={{ justifyContent: "space-between" }}
      >
        <Text>
          In our help & documentation site you will find step by step guides and
          videos on how to use our platform and learn the many things you can
          do.
        </Text>
        <Button
          flexShrink={0}
          onClick={() =>
            window.open(
              "https://dathic.notion.site/Dathic-s-Product-Locator-help-center-9e264081c5554b6d98db97acf71c7b19?pvs=4"
            )
          }
        >
          Go to help center
        </Button>
      </PageSection>
      <PageSection
        title="Book call with support"
        icon={<BiPhone />}
        contentProps={{ justifyContent: "space-between" }}
      >
        <Text>
          If you have questions or need help using our platform you can book a
          call with support.
        </Text>
        <Button
          flexShrink={0}
          onClick={() =>
            window.open(
              "https://calendly.com/laura-rocha/30-mins-dathic--locator-support"
            )
          }
        >
          Book a call
        </Button>
      </PageSection>
      <PageSection
        title="Contact support"
        icon={<MdEmail />}
        contentProps={{ justifyContent: "space-between" }}
      >
        <Text>
          If you have a quick question or just want to chat, contact the support
          team. We'll respond quickly!
        </Text>
        <Button
          flexShrink={0}
          onClick={() => window.open("mailto:support@dathic.com")}
        >
          Contact support
        </Button>
      </PageSection>
    </PageLayout>
  );
}
export default HelpSupportScreen;
