import {
  Box,
  Button,
  Heading,
  HStack,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import ReactStars from "react-stars";
import { selectVisState } from "../../features/map/keplerReducer";
import { getProductStore } from "../../services/api.service";

function ProductContainer({ productItem }) {
  return (
    <Box bg="whitesmoke" p={3} minW={200}>
      <Text fontWeight="bold" fontSize={14}>
        {productItem.product.sku ? `${productItem.product.sku} - ` : ""}
        {productItem.product.name}
        {productItem.product.flavor ? ` - ${productItem.product.flavor}` : ""}
      </Text>
      {productItem.product.urls?.length === 1 && (
        <Button
          variant="link"
          onClick={() => window.open(productItem.product.urls[0])}
        >
          Go to product
        </Button>
      )}
      {productItem.product.urls?.length > 1 && (
        <HStack>
          {productItem.product.urls.map((url, index) => (
            <Button variant="link" onClick={() => window.open(url)}>
              Link {index + 1}
            </Button>
          ))}
        </HStack>
      )}
      <Text mt={3}>{productItem.product.description}</Text>
      <VStack mt={3} spacing={0} alignItems="flex-start">
        <Text fontWeight="bold" color={productItem.units < 3 ? "red" : "green"}>
          Stock: {productItem.units}
        </Text>
        <Text>
          {productItem.price_oz ? "Price per oz" : "Price"}:{" "}
          {productItem.price_oz || productItem.price}
        </Text>
        <Text>Score: {productItem.score_num}</Text>
      </VStack>
      <VStack>
        <ReactStars count={5} edit={false} value={productItem.review_num} />
      </VStack>
    </Box>
  );
}

function ProductSection({ products, title }) {
  return products?.length ? (
    <Box>
      <Heading as="h3">{title}</Heading>
      <HStack overflowX="auto">
        {products.map((item, i) => (
          <ProductContainer key={i} productItem={item} />
        ))}
      </HStack>
    </Box>
  ) : (
    <></>
  );
}

function MyProductsTab(props) {
  const { accessToken, clickedObject, onFinishLoading, orgConfig } = props;
  const [productItems, setProductItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const zipcodeLabel = orgConfig?.zipcodeNameReplacement || "Zipcode";
  const storeLabel = orgConfig?.storeNameReplacement || "Store";

  useEffect(() => {
    if (clickedObject?.id) {
      setIsLoading(true);
      getProductStore(clickedObject.id, accessToken)
        .then((items) => {
          setProductItems(items);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [accessToken, clickedObject]);

  useEffect(() => {
    if (onFinishLoading) onFinishLoading(!isLoading && productItems?.length);
  }, [isLoading, onFinishLoading, productItems]);

  const isZipcode = useMemo(
    () => !!(clickedObject?.zipcode && !clickedObject.address),
    [clickedObject]
  );
  return (
    <Box overflowY="auto">
      {isLoading && <Spinner />}
      {!isLoading && !productItems.length && (
        <Text>
          No products found for this {isZipcode ? zipcodeLabel : storeLabel}
        </Text>
      )}
      {productItems
        .reduce((categories, item) => {
          const newCategories = [...categories];
          const categoryIndex = categories.findIndex(
            (cat) => cat.title === item.product.category
          );
          if (categoryIndex >= 0) {
            newCategories[categoryIndex].items.push(item);
          } else {
            newCategories.push({ title: item.product.category, items: [item] });
          }
          return newCategories;
        }, [])
        .map((category) => (
          <ProductSection title={category.title} products={category.items} />
        ))}
    </Box>
  );
}

export default connect((state) => ({
  clickedObject: (
    selectVisState(state)?.clicked || selectVisState(state)?.customClicked
  )?.object?.properties,
  accessToken: state.app.accessToken,
  userResources: state.app.currentUser.resources,
  orgConfig: state.app.orgProperties?.properties,
}))(MyProductsTab);
