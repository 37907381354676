import React, { useEffect, useState } from "react";
import { ResponsiveModal } from "./responsive-modal";
import {
  Box,
  Divider,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import {
  DecorativeAxis,
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
} from "react-vis";
import { connect } from "react-redux";
import { setIsPredictionModalOpen } from "../app/appSlice";
import { getPredictionData } from "../services/data.service";
import { toast } from "react-toastify";
import pluralize from "pluralize";
import { DateTime } from "luxon";

const _PredictionModal = (props) => {
  const { predictionModalOpen, mapSelection, setIsOpen, orgConfig } = props;
  const [selectedZipcode, setSelectedZipcode] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [predictionData, setPredictionData] = useState();
  const zipcodeLabel = orgConfig?.zipcodeNameReplacement || "Zipcode";
  const storeLabel = orgConfig?.storeNameReplacement || "Store";

  useEffect(() => {
    const fetchPredictionData = async () => {
      try {
        const predictions = await getPredictionData(
          mapSelection?.zipcodes?.map((zipcode) => zipcode.zipcode)
        );
        setPredictionData(predictions?.zipcodes);
        setSelectedZipcode(predictions?.zipcodes?.[0]);
      } catch (e) {
        console.log(e);
      }
    };

    if (predictionModalOpen) {
      if (!mapSelection?.zipcodes || mapSelection.zipcodes.length === 0) {
        toast.warning(`Select at least one ${pluralize(zipcodeLabel, 1)}`);
        setIsOpen(false);
        return undefined;
      }
      fetchPredictionData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapSelection, predictionModalOpen, setIsOpen]);
  return (
    <ResponsiveModal
      isOpen={predictionModalOpen}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Similar {pluralize(zipcodeLabel, 2)} based on selection
        </ModalHeader>
        <ModalCloseButton onClick={() => setIsOpen(false)} />
        <ModalBody>
          <Select
            placeholder={`All ${pluralize(zipcodeLabel, 2)}`}
            value={JSON.stringify(selectedZipcode)}
            onChange={(event) =>
              setSelectedZipcode(JSON.parse(event.target.value))
            }
          >
            {predictionData?.map((zipcode) => (
              <option value={JSON.stringify(zipcode)}>{zipcode.id}</option>
            ))}
          </Select>
          <Flex w="100%" align="center" direction="column">
            <Box flex={0}>
              {selectedZipcode?.is_selected && (
                <Box fontSize="sm">
                  At this location, We see a good likelihood of sales of Pan
                  sobao based on the number of Puerto Ricans around the area
                  and/or historical sales of the product in similar locations.
                  Each {storeLabel} scores from 0-1000 based on the likelihood
                  of sales of Pan Sobao
                </Box>
              )}
              {selectedZipcode?.is_selected === false && (
                <Box fontSize="sm">
                  At this location, we see a lower probability of sales of Pan
                  sobao based on the number of puerto ricans, the size of the
                  area or historial sales in similar locations. Each{" "}
                  {storeLabel}
                  scores from 0-1000 based on the likelihood of sales of Pan
                  Sobao
                </Box>
              )}
            </Box>
            <Divider w="100%" />
            <StatGroup w="100%">
              <Stat>
                <StatLabel>Units / Week</StatLabel>
                <StatNumber>{selectedZipcode?.pred_units}</StatNumber>
              </Stat>

              <Stat>
                <StatLabel>Selected</StatLabel>
                <StatNumber>
                  {selectedZipcode?.is_selected ? "Yes" : "No"}
                </StatNumber>
              </Stat>

              <Stat>
                <StatLabel>Score</StatLabel>
                <StatNumber>
                  {((selectedZipcode?.score ?? 0) * 1000).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </StatNumber>
              </Stat>

              <Stat>
                <StatLabel>Probability</StatLabel>
                <StatNumber>
                  {selectedZipcode?.probability?.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })}
                </StatNumber>
              </Stat>
            </StatGroup>
            <Select
              placeholder="All products"
              value={selectedProduct}
              onChange={(event) => setSelectedProduct(event.target.value)}
            >
              {selectedZipcode?.pred_sales?.map((product) => (
                <option value={product.product_id}>{product.product_id}</option>
              ))}
            </Select>
            <XYPlot width={624} height={250} xType="time">
              <HorizontalGridLines />
              <VerticalGridLines />
              <XAxis tickLabelAngle={30} />
              <YAxis />
              <DecorativeAxis
                axisStart={{ x: new Date().getTime(), y: 0 }}
                axisEnd={{ x: new Date().getTime(), y: 1000000 }}
                axisDomain={[0, 1000]}
              />
              {!selectedZipcode?.pred_sales && (
                <LineSeries
                  className="first-series"
                  curve={"curveMonotoneX"}
                  data={[
                    {
                      x: new Date().getTime(),
                      y: selectedZipcode?.pred_units ?? 0,
                    },
                    {
                      x: DateTime.now().plus({ week: 1 }).toMillis(),
                      y: selectedZipcode?.pred_units ?? 0,
                    },
                  ]}
                />
              )}
              {selectedZipcode?.pred_sales &&
                selectedZipcode.pred_sales
                  .filter(
                    (productSales) =>
                      !selectedProduct ||
                      selectedProduct === `${productSales.product_id}`
                  )
                  .map((productSales) => (
                    <LineSeries
                      className="first-series"
                      curve={"curveMonotoneX"}
                      data={productSales.data.map((sale) => ({
                        x: DateTime.fromISO(sale.date).toMillis(),
                        y: sale.value,
                      }))}
                    />
                  ))}
            </XYPlot>
          </Flex>
        </ModalBody>
      </ModalContent>
    </ResponsiveModal>
  );
};

export const PredictionModal = connect(
  (state) => ({
    predictionModalOpen: state.app.predictionModalOpen,
    orgConfig: state.app.orgProperties?.properties,
    mapSelection:
      state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
        ?.selection,
  }),
  (dispatch) => ({
    setIsOpen: (value) => dispatch(setIsPredictionModalOpen(value)),
  })
)(_PredictionModal);
