import { Skeleton, Stat, StatLabel, StatNumber, Text } from "@chakra-ui/react";
import { StatHelpText } from "@chakra-ui/stat";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAccessToken, selectOrgProperties } from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import PageLayout from "../../components/PageLayout";
import { PageSection } from "../../components/PageSection";
import { getStoresOverview } from "../../services/api.service";

export const StoresOverview = () => {
  const orgProperties = useSelector(selectOrgProperties);
  const accessToken = useAppSelector(selectAccessToken);
  const [overview, setOverview] = useState<any | undefined>();
  const [overviewTotals, setOverviewTotals] = useState<any | undefined>();

  useEffect(() => {
    getStoresOverview(
      DateTime.now().startOf("quarter").toJSDate(),
      new Date(),
      accessToken
    )
      .then((overview) => {
        setOverview(overview?.filtered);
        setOverviewTotals(overview?.totals);
      })
      .catch(() => setOverview({}));
  }, [accessToken]);

  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";

  const getLastUpdateDate = DateTime.fromHTTP(
    overview?.last_update || ""
  ).toLocaleString();
  return (
    <PageLayout
      label={`This quarter: ${DateTime.now()
        .startOf("quarter")
        .toLocaleString()} - today`}
    >
      <PageSection
        title={`Recent growth locations - Last added ${storeLabel}: ${
          overview?.last_update
            ? getLastUpdateDate
            : `No ${pluralize(storeLabel)} added this quarter`
        }`}
        label={`New locations added to the organization in this quarter`}
      >
        {["state", "city", "zipcode"].map((comp) => (
          <Skeleton isLoaded={!!overview} flex={1}>
            <Stat bg="#f5f5f5ff" rounded="lg" p={3}>
              <StatLabel>New {pluralize(comp)}</StatLabel>
              <StatNumber>{overview?.[pluralize(comp)]}</StatNumber>
              <StatHelpText>
                Total: {overviewTotals?.[pluralize(comp)]}
              </StatHelpText>
            </Stat>
          </Skeleton>
        ))}
      </PageSection>
      <PageSection
        title={`Recent growth ${pluralize(storeLabel)}`}
        label={`New ${pluralize(
          storeLabel
        )} added to the organization in the last quarter`}
      >
        <Skeleton isLoaded={!!overview} flex={1}>
          <Stat bg="#f5f5f5ff" rounded="lg" p={3}>
            <StatLabel>New {pluralize(storeLabel)}</StatLabel>
            <StatNumber>{overview?.stores || 0}</StatNumber>
            <StatHelpText>Total: {overviewTotals?.stores || 0}</StatHelpText>
          </Stat>
        </Skeleton>
        <Skeleton isLoaded={!!overview} flex={1}>
          <Stat bg="#f5f5f5ff" rounded="lg" p={3}>
            <StatLabel>New retailers</StatLabel>
            <StatNumber>{overview?.store_retailers}</StatNumber>
            <StatHelpText>
              Total: {overviewTotals?.store_retailers}
            </StatHelpText>
          </Stat>
        </Skeleton>
        <Skeleton isLoaded={!!overview} flex={1}>
          <Stat bg="#f5f5f5ff" rounded="lg" p={3}>
            <StatLabel>Synced with dathic</StatLabel>
            <StatNumber>{overview?.auto_updated_stores}</StatNumber>
            <StatHelpText>
              Total: {overviewTotals?.auto_updated_stores}
            </StatHelpText>
          </Stat>
        </Skeleton>
        <Skeleton isLoaded={!!overview} flex={1}>
          <Stat bg="#f5f5f5ff" rounded="lg" p={3}>
            <StatLabel>New manually added</StatLabel>
            <StatNumber>{overview?.manually_updated_stores}</StatNumber>
            <StatHelpText>
              Total: {overviewTotals?.manually_updated_stores}
            </StatHelpText>
          </Stat>
        </Skeleton>
      </PageSection>
      <PageSection
        title="Recent growth online"
        label={`New online ${pluralize(
          storeLabel
        )} added to the organization in this quarter`}
      >
        <Text>
          No {pluralize(storeLabel)} added to the organization in this time
          frame.
        </Text>
      </PageSection>
      <PageSection
        title="Recent growth restaurants"
        label="New restaurants added to the organization in this quarter"
      >
        <Skeleton isLoaded={!!overview} flex={1}>
          <Stat bg="#f5f5f5ff" rounded="lg" p={3}>
            <StatLabel>New restaurants</StatLabel>
            <StatNumber>{overview?.restaurants || 0}</StatNumber>
            <StatHelpText>
              Total: {overviewTotals?.restaurants || 0}
            </StatHelpText>
          </Stat>
        </Skeleton>
        <Skeleton isLoaded={!!overview} flex={1}>
          <Stat bg="#f5f5f5ff" rounded="lg" p={3}>
            <StatLabel>New chains</StatLabel>
            <StatNumber>{overview?.chains || 0}</StatNumber>
            <StatHelpText>Total: {overviewTotals?.chains || 0}</StatHelpText>
          </Stat>
        </Skeleton>
        <Skeleton isLoaded={!!overview} flex={1}>
          <Stat bg="#f5f5f5ff" rounded="lg" p={3}>
            <StatLabel>New automatically updated</StatLabel>
            <StatNumber>{overview?.auto_updated_restaurants || 0}</StatNumber>
            <StatHelpText>
              Total: {overviewTotals?.auto_updated_restaurants || 0}
            </StatHelpText>
          </Stat>
        </Skeleton>
        <Skeleton isLoaded={!!overview} flex={1}>
          <Stat bg="#f5f5f5ff" rounded="lg" p={3}>
            <StatLabel>New manually added</StatLabel>
            <StatNumber>
              {overview?.manually_updated_restaurants || 0}
            </StatNumber>
            <StatHelpText>
              Total: {overviewTotals?.manually_updated_restaurants || 0}
            </StatHelpText>
          </Stat>
        </Skeleton>
      </PageSection>
      <PageSection
        title="Recent growth popup locations"
        label="New popup locations added to the organization in this quarter"
      >
        <Skeleton isLoaded={!!overview} flex={1}>
          <Stat bg="#f5f5f5ff" rounded="lg" p={3}>
            <StatLabel>New popup locations</StatLabel>
            <StatNumber>{overview?.popups || 0}</StatNumber>
            <StatHelpText>Total: {overviewTotals?.popups || 0}</StatHelpText>
          </Stat>
        </Skeleton>
        <Skeleton isLoaded={!!overview} flex={1}>
          <Stat bg="#f5f5f5ff" rounded="lg" p={3}>
            <StatLabel>New retailers</StatLabel>
            <StatNumber>{overview?.popup_retailers || 0}</StatNumber>
            <StatHelpText>
              Total: {overviewTotals?.popup_retailers || 0}
            </StatHelpText>
          </Stat>
        </Skeleton>
        <Skeleton isLoaded={!!overview} flex={1}>
          <Stat bg="#f5f5f5ff" rounded="lg" p={3}>
            <StatLabel>New automatically updated</StatLabel>
            <StatNumber>{overview?.auto_updated_popups || 0}</StatNumber>
            <StatHelpText>
              Total: {overviewTotals?.auto_updated_popups || 0}
            </StatHelpText>
          </Stat>
        </Skeleton>
        <Skeleton isLoaded={!!overview} flex={1}>
          <Stat bg="#f5f5f5ff" rounded="lg" p={3}>
            <StatLabel>New manually added</StatLabel>
            <StatNumber>{overview?.manually_updated_popups || 0}</StatNumber>
            <StatHelpText>
              Total: {overviewTotals?.manually_updated_popups || 0}
            </StatHelpText>
          </Stat>
        </Skeleton>
      </PageSection>
    </PageLayout>
  );
};
