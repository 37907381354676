import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Stack,
  Flex,
  Center,
  Text,
  Divider,
  Spinner,
  Collapse,
  Button,
  VStack,
  Box,
  HStack,
} from "@chakra-ui/react";
import { getTopics } from "../../../../../services/api.service";
import { camelCaseWords } from "../../../../../utils/stringUtils";
import { scaleLinear, scaleOrdinal } from "d3-scale";
import { EXTENDED_DISCRETE_COLOR_RANGE } from "react-vis/es/theme";
import { DiscreteColorLegend } from "react-vis";
import DemographicsRadialChart from "./DemographicsRadialChart";
import { MinusIcon, AddIcon } from "@chakra-ui/icons";
import { connect } from "react-redux";
import { primaryLight, secondaryDark } from "../../../../../theme/colors";

function SocialTab({
  setSelectedTab,
  selectedItems,
  twitterIndicators,
  loading,
  accessToken,
  orgProperties,
}) {
  const [treeData, setTreeData] = useState([]);
  const [openedItems, setOpenedItems] = useState([]);

  const zipSize = useMemo(() => orgProperties?.zipSize || 6, [orgProperties]);
  const zipcodes = useMemo(
    () => [
      ...new Set(
        [
          ...(selectedItems.demographic || []),
          ...(selectedItems.store || []),
        ].map((d) => d.zipcode.substring(0, zipSize))
      ),
    ],
    [selectedItems, zipSize]
  );

  const parseTopicData = (topicData) => {
    if (!topicData || topicData.length === 0) {
      setTreeData(null);
      return;
    }
    const filteredTopicData = topicData.map((data) => {
      const parsedData = Object.keys(data)
        .filter(
          (key) =>
            !key.includes("words_") &&
            !["date", "id", "spanish_tweets", "zipcode"].includes(key)
        )
        .map((topic) => {
          const score = data[topic];
          const words = data[`words_` + topic];
          let json = {
            children:
              (words &&
                words.map((word) => ({ name: camelCaseWords(word) }))) ||
              undefined,
            score,
          };
          return json ? { name: camelCaseWords(topic), ...json } : undefined;
        })
        .filter((data) => !!data)
        .sort((a, b) => (a.score > b.score ? -1 : a.score < b.score ? 1 : 0));
      return { zipcode: data.zipcode, data: parsedData };
    });
    setTreeData(filteredTopicData);
  };

  useEffect(() => {
    getTopics([zipcodes[0], zipcodes[1]], accessToken).then(parseTopicData);
  }, [accessToken, zipcodes]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getShade = useCallback(
    scaleLinear()
      .domain([
        Math.min(
          ...(treeData
            ?.flatMap((zip) => zip.data)
            ?.map((topic) => topic.score)
            ?.filter((score) => !!score) || [])
        ),
        Math.max(
          ...(treeData
            ?.flatMap((zip) => zip.data)
            ?.map((topic) => topic.score) || [])
        ),
      ])
      .range([primaryLight, secondaryDark]),
    [treeData]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSliceColor = useCallback(
    scaleOrdinal()
      .domain([
        ...new Set([
          ...(twitterIndicators
            ?.flatMap((ind) => ind.platforms_distribution)
            ?.map((d) => d.y) || []),
          ...(twitterIndicators
            ?.flatMap((ind) => ind.hispanic_platforms_distribution)
            ?.map((d) => d.y) || []),
        ]),
      ])
      .range(EXTENDED_DISCRETE_COLOR_RANGE),
    [twitterIndicators]
  );

  return (
    <Stack
      direction={["column", null, "row"]}
      height={["40%", null, "100%"]}
      background="white"
      rounded="md"
      width={["100%", null, 650]}
      textAlign="start"
      overflowY="auto"
    >
      <Flex flexDir="column" w="100%" h="100%">
        {!loading && treeData.length ? (
          <VStack>
            <Text
              m={2}
              textAlign={"center"}
              fontSize={"xl"}
              fontWeight={"bold"}
            >
              Number of tweets
            </Text>
            <HStack justifyContent={"space-evenly"} w="100%">
              <Text fontSize={"xl"}>
                {(twitterIndicators?.[0]?.tweets_count || 0).toLocaleString(
                  undefined
                )}
              </Text>
              <Text fontSize={"xl"}>
                {(twitterIndicators?.[1]?.tweets_count || 0).toLocaleString(
                  undefined
                )}
              </Text>
            </HStack>
            <Divider />
            <Text fontSize="xl" fontWeight="bold" align="center">
              Twitter Platform Distribution
            </Text>
            <Flex justifyContent="space-around" alignItems={"center"} w="100%">
              {twitterIndicators?.map((indicator, index) => {
                return (
                  <DemographicsRadialChart
                    key={index}
                    data={indicator.platforms_distribution.map((platform) => {
                      return {
                        total: scaleLinear()
                          .domain([
                            0,
                            indicator.platforms_distribution?.reduce(
                              (acc, p) => acc + p.x,
                              0
                            ),
                          ])
                          .range([0, 1])(platform.x),
                        label: platform.y,
                        color: getSliceColor(platform.y),
                      };
                    })}
                    formatHint={(d) =>
                      d.toLocaleString(undefined, { style: "percent" })
                    }
                  />
                );
              })}
            </Flex>
            <DiscreteColorLegend
              orientation="horizontal"
              items={(twitterIndicators?.[0]?.platforms_distribution || []).map(
                (val) => ({
                  title: val.y,
                  strokeWidth: 15,
                  color: getSliceColor(val.y),
                })
              )}
            />
            <Divider />
            <Text fontSize="xl" fontWeight="bold" align="center">
              Twitter Hispanic Platform Distribution
            </Text>
            <Flex justifyContent="space-around" alignItems={"center"} w="100%">
              {twitterIndicators?.map((indicator, index) => {
                return (
                  <DemographicsRadialChart
                    key={index}
                    data={indicator.hispanic_platforms_distribution.map(
                      (platform) => {
                        return {
                          total: scaleLinear()
                            .domain([
                              0,
                              indicator.hispanic_platforms_distribution?.reduce(
                                (acc, p) => acc + p.x,
                                0
                              ),
                            ])
                            .range([0, 1])(platform.x),
                          label: platform.y,
                          color: getSliceColor(platform.y),
                        };
                      }
                    )}
                    formatHint={(d) =>
                      d.toLocaleString(undefined, { style: "percent" })
                    }
                  />
                );
              })}
            </Flex>
            <DiscreteColorLegend
              orientation="horizontal"
              items={(
                twitterIndicators?.[0]?.hispanic_platforms_distribution || []
              ).map((val) => ({
                title: val.y,
                strokeWidth: 15,
                color: getSliceColor(val.y),
              }))}
            />
            <Divider />

            <Text fontSize="xl" fontWeight="bold" align="center">
              Trending Twitter Topics
            </Text>
            <HStack
              w="100%"
              divider={<Divider orientation="vertical" />}
              alignItems="flex-start"
            >
              <VStack w="100%">
                <Flex w="100%" px={5} justifyContent={"space-between"}>
                  <Text>Topic</Text>
                  <Text>Score</Text>
                </Flex>
                {treeData[0].data.map((topic, index) => {
                  return (
                    <ButtonCollapse
                      key={topic.name}
                      index={index}
                      topic={topic}
                      getShade={getShade}
                      isOpen={openedItems.includes(topic.name)}
                      setOpenedItems={setOpenedItems}
                    />
                  );
                })}
              </VStack>
              <VStack w="100%">
                <Flex w="100%" px={5} justifyContent={"space-between"}>
                  <Text>Topic</Text>
                  <Text>Score</Text>
                </Flex>
                {treeData[1].data.map((topic, index) => {
                  return (
                    <ButtonCollapse
                      key={topic.name}
                      index={index}
                      topic={topic}
                      getShade={getShade}
                      isOpen={openedItems.includes(topic.name)}
                      setOpenedItems={setOpenedItems}
                    />
                  );
                })}
              </VStack>
            </HStack>
          </VStack>
        ) : (
          <Center h="100%">
            <Spinner />
          </Center>
        )}
      </Flex>
    </Stack>
  );
}

const ButtonCollapse = ({ topic, getShade, isOpen, setOpenedItems }) => {
  const childShade = scaleLinear()
    .domain([0, topic.children?.length || 0])
    .range([secondaryDark, primaryLight]);
  return (
    <>
      <HStack w="100%" alignItems="flex-start">
        <Box flex={1} rounded="md" overflow="hidden">
          <Button
            rounded="md"
            bg={topic.score ? getShade(topic.score) : "gray.400"}
            color="white"
            justifyContent="flex-start"
            w="100%"
            leftIcon={isOpen ? <MinusIcon /> : <AddIcon />}
            onClick={() =>
              setOpenedItems((oldItems) => {
                const itemIndex = oldItems?.findIndex(
                  (item) => item === topic.name
                );
                const newItems = [...(oldItems || [])];
                if (itemIndex > -1) {
                  newItems.splice(itemIndex, 1);
                } else {
                  newItems.push(topic.name);
                }
                return newItems;
              })
            }
          >
            <Text>{topic.name}</Text>
          </Button>
          <Collapse in={isOpen} animateOpacity style={{ width: "100%" }}>
            <Flex justifyContent={"flex-start"} direction={"column"}>
              {topic.children.map((child, index) => {
                return (
                  <Button
                    key={child.name}
                    _hover={false}
                    borderRadius={0}
                    mt={index === 0 && "-1"}
                    bg={childShade(index)}
                    color="white"
                    justifyContent="center"
                  >
                    {child.name}
                  </Button>
                );
              })}
            </Flex>
          </Collapse>
        </Box>
        <Button
          _hover={false}
          rounded="md"
          bg={topic.score ? getShade(topic.score) : "gray.400"}
          color="white"
          justifyContent="center"
          w="25%"
        >
          {topic.score}
        </Button>
      </HStack>
    </>
  );
};

export default connect((state) => ({
  selectedItems:
    state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
      ?.comparisonSelection,
  orgProperties: state.app.orgProperties?.properties,
}))(SocialTab);
