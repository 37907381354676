import React from "react";
import { VStack, Flex, Checkbox, Text, IconButton } from "@chakra-ui/react";
import { IoTrash } from "react-icons/io5";
import { crudFavorite } from "../mapViewsSlice";
import { connect } from "react-redux";

function FavoriteDisplay({
  checked,
  setChecked,
  checkedZipcodes,
  type,
  groupedFavorites,
  clickObject,
  activeView,
  onClose,
  crudFavorite,
}) {
  const checkChange = (value) => {
    if (checked.indexOf(value) !== -1) {
      setChecked(checked.filter((checkBox) => checkBox !== value));
    } else {
      setChecked([...checked, value]);
    }
  };
  return (
    <>
      <VStack color={"black"}>
        {groupedFavorites[type]?.map((item, index) => (
          <Flex key={item.id} flexDir="row" alignItems="center">
            <Checkbox
              onChange={(e) => checkChange(item)}
              isChecked={checked.includes(item)}
              isDisabled={!checked.includes(item) && checkedZipcodes.length > 1}
              mr={3}
            ></Checkbox>
            <Flex
              w={250}
              alignItems="center"
              justifyContent="center"
              bg="gray.200"
              cursor="pointer"
              borderRadius={10}
              p={3}
              onClick={() =>
                clickObject(activeView, item.store || item.demographic)
              }
            >
              <VStack flex={1} alignItems="center" spacing={0}>
                <Flex w={"100%"} justifyContent={"space-between"}>
                  <Text fontWeight="medium" fontSize={16}>
                    {item.store?.address || item.demographic?.zipcode}
                  </Text>
                  <IconButton
                    variant="link"
                    icon={<IoTrash />}
                    colorScheme="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (checked.includes(item)) checkChange(item);
                      crudFavorite({
                        action: "delete",
                        data: {
                          id: item.id,
                        },
                      });
                    }}
                  />
                </Flex>
                <Text w={"100%"} textAlign={"left"}>
                  {[
                    item.store?.city || item.demographic?.major_city,
                    (item.store || item.demographic)?.county,
                    (item.store || item.demographic)?.state,
                  ]
                    .filter((part) => !!part)
                    .join(", ")}
                </Text>
              </VStack>
            </Flex>
          </Flex>
        ))}
      </VStack>
    </>
  );
}

export default connect(undefined, (dispatch) => ({
  crudFavorite: (payload) => dispatch(crudFavorite(payload)),
}))(FavoriteDisplay);
