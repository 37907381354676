import { selectAnatomy as parts } from "@chakra-ui/anatomy";
import { PartsStyleObject } from "@chakra-ui/theme-tools";

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  sm: {
    field: { borderRadius: 10 },
  },
};

const selectTheme = {
  parts: parts.keys,
  baseStyle: {
    field: {
      borderRadius: 10,
      _placeholder: {
        color: "gray.600",
      },
    },
  },
  variants: {
    outline: {
      field: {
        _invalid: {
          borderColor: "orange",
          boxShadow: `0px 1px 0px 0px orange`,
        },
      },
    },
  },
  sizes,
};
export default selectTheme;
