import React, { useEffect } from "react";
import { connect } from "react-redux";
import IntegrationsModal from "./integrations-modal";
import {
  toggleIntegrationsModal,
  setActiveIntegrations,
} from "./integrationSlice";
import { loadGoogle } from "./google/google-analytics-reporting-api";

function IntegrationsContainer({
  fbRespose,
  closeIntegrationsModal,
  integrationsModalIsOpen,
  activeIntegrations,
  setActiveIntegrations,
}) {
  useEffect(() => {
    loadGoogle((connectedToGoogle) => {
      setActiveIntegrations((active) => ({
        ...active,
        google: connectedToGoogle ? { connection: true } : undefined,
      }));
    });
  }, [setActiveIntegrations]);

  useEffect(() => {
    if (fbRespose?.accessToken) {
      setActiveIntegrations((active) => ({
        ...active,
        facebook: { connection: fbRespose },
      }));
    }
  }, [setActiveIntegrations, fbRespose]);

  useEffect(() => {
    setActiveIntegrations((active) => ({
      ...active,
      shopify: { connection: true },
    }));
  }, [setActiveIntegrations]);

  return (
    <>
      <IntegrationsModal
        isOpen={integrationsModalIsOpen}
        onClose={closeIntegrationsModal}
      />
    </>
  );
}

export default connect(
  (state) => ({
    integrationsModalIsOpen: state.integrations.modalIsOpen,
    activeIntegrations: state.integrations.activeIntegrations,
  }),
  (dispatch) => ({
    closeIntegrationsModal: () => dispatch(toggleIntegrationsModal(false)),
    setActiveIntegrations: (payload) =>
      dispatch(setActiveIntegrations(payload)),
  })
)(IntegrationsContainer);
