import { assign, keys, pick } from "lodash";
import type { Product } from "./Product";
import type { Store } from "./Store";

export class ProductStore {
  id!: number;
  store_id?: number;
  product_id?: number;
  distributor_id?: number;
  units?: number;
  store?: Store;
  product?: Product;
  price?: number;
  price_oz?: number;
  review_num?: number;
  score_num?: number;
  origin?: string;

  constructor(candidate: any) {
    assign(this, pick(candidate, keys(this)));
  }

  buildForPost() {
    const newReview = JSON.parse(JSON.stringify(this));
    delete newReview.id;
    delete newReview.store;
    delete newReview.product;
    newReview.origin = "Manual";
    return new ProductStore(newReview);
  }

  buildForPut() {
    const newReview = JSON.parse(JSON.stringify(this));
    delete newReview.store;
    delete newReview.product;
    return new ProductStore(newReview);
  }
}
