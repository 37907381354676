import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Code,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { selectIsShopifyApp, selectOrgProperties } from "../../app/appSlice";
import { useAppSelector } from "../../app/store";

export function EmbeddingStep() {
  const isShopifyApp = useAppSelector(selectIsShopifyApp);
  const orgConfig = useAppSelector(selectOrgProperties);
  const storeLabel = orgConfig?.properties?.storeNameReplacement || "Store";
  const storeLocatorUrl = orgConfig?.properties?.storeLocatorUrl;

  return storeLocatorUrl ? (
    <Box>
      <Text mb={3}>You can now embed your {storeLabel} Locator:</Text>
      <FormControl mb={5}>
        <FormLabel fontSize="18px" fontWeight="bold">
          iframe:
        </FormLabel>
        <FormHelperText>The iframe will fill your container</FormHelperText>
        <Flex dir="row" w="100%">
          <Code flex={1} overflowX="auto" p={3}>
            {`<iframe src="${storeLocatorUrl}" width="100%" height="100%" style="border: none;" allow="geolocation"></iframe>`}
          </Code>
          <IconButton
            icon={<CopyIcon />}
            onClick={() => {
              const text = `<iframe src="${storeLocatorUrl}" width="100%" height="100%" style="border: none;" allow="geolocation"></iframe>`;
              navigator.clipboard.writeText(text);
            }}
            aria-label={"copy store locator url"}
          />
        </Flex>
      </FormControl>
      {isShopifyApp && (
        <>
          <FormControl mb={5}>
            <FormLabel fontSize="18px" fontWeight="bold">
              Shopify App Block:
            </FormLabel>
            <FormHelperText>
              Add an app block to your shopify store theme.
            </FormHelperText>
            <HStack shadow="base" rounded="md" overflow="hidden">
              <video width="500" height="200" controls>
                <source
                  src="https://dathic-growth-files-public.s3.amazonaws.com/shopify_app_block_tutorial.mov"
                  type="video/mp4"
                />
              </video>
              <Text>
                Watch this tutorial on how to add a store locator app block to
                your Shopify store theme in a few simple steps.
              </Text>
            </HStack>
          </FormControl>
        </>
      )}
    </Box>
  ) : (
    <Text>
      Your {storeLabel} Locator is generating will soon be available for
      embedding. <Spinner />
    </Text>
  );
}
