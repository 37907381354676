import { Divider, Flex, Grid, Heading, Text, VStack } from "@chakra-ui/layout";
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { ResponsiveModal } from "../../components/responsive-modal";
import {
  handleSettingAction,
  selectActiveIntegrations,
  selectAvailableIntegrations,
  selectIntegration,
  selectSelectedIntegration,
} from "./integrationSlice";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/button";
import { primary } from "../../theme/colors";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { RESOURCES } from "../../constants/user-constants";
import { selectAccessToken, selectUserResources } from "../../app/appSlice";

export const IntegrationCard = ({
  availableIntegration,
  isSelected,
  setSelectedIntegration,
}) => {
  const [showingMessage, setShowingMessage] = useState();
  const activeIntegrations = useSelector(selectActiveIntegrations);
  return (
    <Flex
      id={`integration-card-${availableIntegration.id}`}
      className="integration-card-btn"
      as="button"
      p={5}
      flexDir="column"
      bg="#f5f5f5ff"
      border={isSelected ? `2px solid ${primary}` : undefined}
      borderRadius={10}
      _hover={{ bg: "#dcdcdcff" }}
      alignItems="center"
      onClick={() => {
        if (availableIntegration.connectAction?.action) {
          setSelectedIntegration(availableIntegration);
        } else {
          setShowingMessage(true);
          setTimeout(() => {
            setShowingMessage(false);
          }, 5000);
        }
      }}
    >
      {!showingMessage && (
        <>
          {availableIntegration.Icon && <availableIntegration.Icon size={50} />}
          <Text fontSize={12}>{availableIntegration.name}</Text>
        </>
      )}
      {showingMessage && (
        <Text>
          Sorry! We are currently working on the {availableIntegration.name}{" "}
          integration and can't offer it at this time.
        </Text>
      )}
      {activeIntegrations[availableIntegration.id]?.connection && (
        <Text textColor="limegreen">Connected</Text>
      )}
    </Flex>
  );
};

function IntegrationsModal({
  isOpen,
  onClose,
  handleSettingAction,
  integrationsState,
}) {
  const selectedIntegration = useSelector(selectSelectedIntegration);
  const activeIntegrations = useSelector(selectActiveIntegrations);
  const availableIntegrations = useSelector(selectAvailableIntegrations);
  const accessToken = useSelector(selectAccessToken);
  const userResources = useSelector(selectUserResources);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(selectIntegration());
    };
  }, [dispatch]);

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent pr={5} pl={5} pb={3} pt={3}>
        <ModalHeader>
          <Heading mb={3}>Integrations</Heading>
          <Divider />
        </ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          {!selectedIntegration && (
            <>
              <Text mb={5}>
                Stay tuned, there are many more integrations to come. If there
                is a specific integration not on this list that you'd like to
                see, please email us at{" "}
                <Button variant="link">info@dathic.com</Button>
              </Text>
              <Grid gap={2} autoFlow="column" columns={4}>
                {availableIntegrations.map((availableIntegration) => (
                  <IntegrationCard
                    availableIntegration={availableIntegration}
                    isSelected={
                      availableIntegration.id === selectedIntegration?.id
                    }
                    setSelectedIntegration={(v) =>
                      dispatch(selectIntegration(v))
                    }
                  />
                ))}
              </Grid>
            </>
          )}
          {selectedIntegration && (
            <Flex alignItems="flex-start">
              <VStack
                alignItems="flex-start"
                maxH="500px"
                pr={20}
                mr={3}
                overflowY="auto"
              >
                <Button
                  variant="link"
                  size="xs"
                  leftIcon={<ArrowBackIcon />}
                  onClick={() => dispatch(selectIntegration())}
                >
                  Back to integrations
                </Button>
              </VStack>
              <VStack>
                <Heading>{selectedIntegration.name}</Heading>
                <Text mb={3}>{selectedIntegration.description}</Text>
                <Divider />
                {activeIntegrations[selectedIntegration.id]?.connection && (
                  <>
                    <Text textColor="limegreen">Connected</Text>
                    {selectedIntegration.disconnectAction?.action && (
                      <Button
                        onClick={() =>
                          handleSettingAction({
                            action:
                              selectedIntegration.disconnectAction?.action,
                          })
                        }
                      >
                        Remove Integration
                      </Button>
                    )}
                  </>
                )}
                {!activeIntegrations[selectedIntegration.id]?.connection && (
                  <>
                    <FormControl>
                      <FormLabel>Connect</FormLabel>
                      <Button
                        onClick={() =>
                          handleSettingAction({
                            action: selectedIntegration.connectAction?.action,
                            params: [accessToken],
                          })
                        }
                        disabled={
                          !userResources?.includes(
                            RESOURCES[
                              `INTEGRATION_${selectedIntegration.id.toUpperCase()}_CONNECT`
                            ]
                          )
                        }
                      >
                        {selectedIntegration.connectAction?.label}
                      </Button>
                    </FormControl>
                  </>
                )}
                {activeIntegrations[selectedIntegration.id]?.connection &&
                  selectedIntegration.settings?.length &&
                  selectedIntegration.settings.map((setting) => (
                    <FormControl>
                      <FormLabel>{setting.label}</FormLabel>
                      {setting.Element && (
                        <setting.Element
                          selectedIntegration={selectedIntegration}
                          state={integrationsState}
                          dispatch={dispatch}
                        />
                      )}
                      {setting.action && (
                        <Button
                          onClick={() =>
                            handleSettingAction({
                              selectedIntegration,
                              action: setting.action?.action,
                            })
                          }
                          disabled={
                            !userResources?.includes(
                              RESOURCES[
                                `INTEGRATION_${selectedIntegration.id.toUpperCase()}_CONNECT`
                              ]
                            ) ||
                            (setting.label
                              .toLowerCase()
                              .includes("dashboard") &&
                              !userResources?.includes(
                                RESOURCES[
                                  `INTEGRATION_${selectedIntegration.id.toUpperCase()}_DASHBOARD`
                                ]
                              ))
                          }
                        >
                          {setting.action.label}
                        </Button>
                      )}
                    </FormControl>
                  ))}
              </VStack>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </ResponsiveModal>
  );
}

export default connect(
  (state) => ({
    integrationsState: state.integrations,
  }),
  (dispatch) => ({
    handleSettingAction: (payload) => dispatch(handleSettingAction(payload)),
  })
)(IntegrationsModal);
