import { FormControl } from "@chakra-ui/form-control";
import { Select } from "@chakra-ui/select";
import React from "react";
import { connect } from "react-redux";
import { setActiveIntegrations } from "../features/data-integrations/integrationSlice";

function InstagramAccountSelect({
  accounts,
  selectedAccount,
  setSelectedInstagramAccount,
}) {
  return (
    <FormControl>
      <Select
        isRequired
        value={
          (accounts?.findIndex((item) => item.id === selectedAccount?.id) ??
            -1) + 1
        }
        onChange={(event) => {
          setSelectedInstagramAccount(accounts[+event.target.value - 1]);
        }}
      >
        <option value={0} disabled selected>
          {" "}
          -- select a page --{" "}
        </option>
        {accounts?.map((page, index) => (
          <option key={page.id} value={index + 1}>
            {page.username}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
export default connect(
  (state) => ({
    accounts:
      state.integrations.activeIntegrations?.facebook?.instagramAccounts,
    selectedAccount:
      state.integrations.activeIntegrations?.facebook?.selectedInstagramAccount,
  }),
  (dispatch) => ({
    setSelectedInstagramAccount: (account) =>
      dispatch(
        setActiveIntegrations((otherIntegrations) => ({
          ...otherIntegrations,
          facebook: {
            ...(otherIntegrations.facebook || {}),
            selectedInstagramAccount: account,
          },
        }))
      ),
  })
)(InstagramAccountSelect);
