import { Input } from "@chakra-ui/input";
import { Box } from "@chakra-ui/layout";
import React, { useState } from "react";
import { HexColorPicker } from "react-colorful";

export default function ColorPicker({ color, setColor }) {
  const [text, setText] = useState(color || "");
  return (
    <Box>
      <HexColorPicker
        style={{ width: "100%" }}
        color={color}
        onChange={(v) => {
          setColor(v);
          setText(v);
        }}
      />

      <Input
        mt={3}
        borderLeft={`34px solid ${color} !important`}
        pl={3}
        w="100%"
        value={text}
        onChange={(e) => {
          setText(e.currentTarget.value);
          if (
            /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(e.currentTarget.value)
          ) {
            setColor(e.currentTarget.value);
          }
        }}
        pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
      />
    </Box>
  );
}
