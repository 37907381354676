import React, { useState } from "react";
import { RadialChart, Hint } from "react-vis";

export default function DemographicsRadialChart({ data, formatHint }) {
  const [value, setValue] = useState(false);
  return (
    <RadialChart
      innerRadius={0}
      radius={75}
      data={data}
      onValueMouseOver={(v) =>
        setValue({
          [v.label]: formatHint ? formatHint(v.total) : v.total,
        })
      }
      onSeriesMouseOut={() => setValue(false)}
      getAngle={(d) => d.total}
      colorType="literal"
      width={160}
      height={160}
      animation={"gentle"}
    >
      {value !== false && <Hint style={{ zIndex: "9999" }} value={value} />}
    </RadialChart>
  );
}
