import {
  Box,
  IconButton,
  List,
  ListItem,
  useDisclosure,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import { selectMenus } from "./appSlice";
import { useAppSelector } from "./store";

export function LateralSubmenu() {
  const { getButtonProps, getDisclosureProps, isOpen } = useDisclosure({
    defaultIsOpen: true,
  });
  const menus = useAppSelector(selectMenus);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const menuId = params.menuId || "";
  const selectedMenu = menus.find((m: any) => menuId?.split("-")?.[0] === m.id);

  return !!selectedMenu?.children?.length ? (
    <Box pos="relative">
      <motion.div
        {...getDisclosureProps()}
        hidden={false}
        initial={false}
        animate={{ width: isOpen ? "15rem" : 40 }}
        style={{
          width: "15rem",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          overflowY: "auto",
        }}
      >
        <List
          maxW={60}
          h="100%"
          paddingY="2.5rem"
          textAlign="start"
          color="blue.600"
          borderRightWidth={1}
        >
          {isOpen && selectedMenu?.label && (
            <ListItem
              color="blue.500"
              fontWeight="bold"
              fontSize="lg"
              paddingX="2rem"
            >
              {selectedMenu.label}
            </ListItem>
          )}
          {isOpen &&
            selectedMenu.children.map((item: any) => (
              <ListItem
                fontSize="md"
                color={
                  menuId.split("-").at(1) === item.id ? "blue.900" : undefined
                }
                fontWeight={
                  menuId.split("-").at(1) === item.id ? "bold" : undefined
                }
                _hover={
                  menuId.split("-").at(1) === item.id
                    ? undefined
                    : {
                        backgroundColor: "lightBlue.400",
                        cursor: "pointer",
                      }
                }
                padding="1rem 2rem"
                pos="relative"
                onClick={
                  menuId.split("-").at(1) === item.id
                    ? undefined
                    : () => {
                        item.embedUrl
                          ? navigate(
                              `${item.embedUrl ? "embed/" : ""}${menuId
                                .split("-")
                                .at(0)}-${item.id}`,
                              {
                                state: {
                                  from: location,
                                  url: item.embedUrl,
                                },
                              }
                            )
                          : item.component
                          ? navigate(
                              `${item.component}/${menuId.split("-").at(0)}-${
                                item.id
                              }`
                            )
                          : navigate(
                              `not_available/${menuId.split("-").at(0)}-${
                                item.id
                              }`
                            );
                      }
                }
              >
                {menuId.split("-").at(1) === item.id && (
                  <Box
                    h={3}
                    w={3}
                    rounded="sm"
                    backgroundColor="blue.400"
                    pos="absolute"
                    left={3}
                    top="calc(50% - 0.375rem)"
                  />
                )}
                {item.label}
              </ListItem>
            ))}
        </List>
      </motion.div>
      <IconButton
        {...getButtonProps()}
        icon={isOpen ? <FaCaretLeft /> : <FaCaretRight />}
        iconSpacing={0}
        pos="absolute"
        top={0}
        right={0}
        variant="link"
        p="5px 0"
      />
    </Box>
  ) : (
    <></>
  );
}
