import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { PartsStyleFunction } from "@chakra-ui/theme-tools";

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  body: { borderRadius: 10 },
});

const modalTheme = {
  parts: parts.keys,
  baseStyle,
};
export default modalTheme;
