import { Avatar } from "@chakra-ui/avatar";
import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Input } from "@chakra-ui/input";
import { HStack, VStack } from "@chakra-ui/layout";
import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import { Badge, Select, SelectProps, Text } from "@chakra-ui/react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { ICellEditorProps } from "ka-table/props";
import { useContext, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  selectAccessToken,
  selectCurrentUser,
  selectOrgProperties,
  selectUserResources,
  selectUserRoles,
} from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import { EntityTable } from "../../components/entity-table/EntityTable";
import OrgSelect from "../../components/OrgSelect";
import { RESOURCES } from "../../constants/user-constants";
import MessageModalContext from "../../contexts/MessageModalContext";
import {
  getOrganizations,
  getRoles,
  listUsers,
  postUserRole,
  putUser,
} from "../../services/api.service";
import { createUser } from "../../services/auth.service";
import { camelCaseWords } from "../../utils/stringUtils";
import { PasswordResetMessage } from "../login/Login";

type RoleSelectProps = {
  roles?: any[];
};

function RoleSelect({ roles, ...other }: RoleSelectProps & SelectProps) {
  const userResources = useAppSelector(selectUserResources);
  return (
    <Select
      disabled={!userResources?.includes(RESOURCES.MANAGE_USER)}
      w={40}
      {...other}
    >
      <option value={"undefined"} disabled selected>
        - Choose a role -
      </option>
      {roles?.map((role, index) => (
        <option key={role.id} value={`${role.id}`}>
          {camelCaseWords(role.name)}
        </option>
      ))}
    </Select>
  );
}

function UserOptions({
  user,
  setUsers,
}: {
  user: any;
  setUsers: React.Dispatch<React.SetStateAction<any[]>>;
}) {
  const userResources = useAppSelector(selectUserResources);
  const accessToken = useAppSelector(selectAccessToken);
  const messageModalContext = useContext(MessageModalContext);
  const sendResetLink = (user: any) => {
    const auth = getAuth();
    const catchError = (error: any) => {
      switch (error.code) {
        case "auth/invalid-email":
          toast.error("Please enter a valid email");
          break;
        case "auth/user-not-found":
          toast.error("A user with this email was not found");
          break;

        default:
          toast.error("Error sending password reset link");
          break;
      }
    };
    messageModalContext.showModal({
      title: "Password Reset",
      message: (
        <PasswordResetMessage
          email={user.username}
          disableInput
          setEmail={() => {}}
        />
      ),
      actions: [
        {
          label: "Cancel",
          isLeastDestructive: true,
        },
        {
          label: "Reset password",
          props: { colorScheme: "blue" },
          callback: () => {
            try {
              sendPasswordResetEmail(auth, user.username)
                .then(() => {
                  toast("Your password reset link has been sent to your email");
                })
                .catch(catchError);
            } catch (error) {
              catchError(error);
            }
          },
        },
      ],
    });
  };
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<HamburgerIcon />}
        disabled={!userResources?.includes(RESOURCES.MANAGE_USER)}
      ></MenuButton>
      <MenuList>
        <MenuGroup>
          <MenuItem onClick={() => sendResetLink(user)}>
            Reset password
          </MenuItem>
          <MenuItem
            onClick={() => {
              putUser(
                {
                  id: user.id,
                  access_token: user.access_token,
                  token: user.token,
                  username: user.username,
                  user_id: user.user_id,
                  active: user.active !== "true",
                },
                accessToken
              ).then((newUser) => {
                setUsers((oldUsers) => {
                  const newUsers = [...oldUsers];
                  const i = oldUsers.findIndex((item) => item.id === user.id);
                  newUsers[i] = newUser;
                  return newUsers;
                });
              });
            }}
            color={user.active === "true" ? "red" : "green"}
          >
            {user.active === "true" ? "Deactivate" : "Activate"} user
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}

function UserItem({ user }: { user: any }) {
  return (
    <HStack w={"100%"} overflow="hidden">
      <Avatar
        size={"sm"}
        mr={1}
        name={user.name || user.email || user.username}
        colorScheme="blue"
      />
      {user.name && <Text whiteSpace={"pre-wrap"}>{user.name}</Text>}
      {!user.name && (
        <Text whiteSpace={"pre-wrap"} color="gray">
          No name
        </Text>
      )}
    </HStack>
  );
}

function CreateUserForm({
  setUsers,
  roles,
  onFinish,
}: {
  setUsers: React.Dispatch<React.SetStateAction<any[]>>;
  roles: any[];
  onFinish: () => void;
}) {
  const { register, watch, setValue, reset, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      role: `${roles?.[0]?.id || ""}`,
    },
  });
  const userResources = useAppSelector(selectUserResources);
  const orgProperties = useAppSelector(selectOrgProperties);
  const accessToken = useAppSelector(selectAccessToken);
  const [loggingIn, setLoggingIn] = useState(false);

  const selectedRole = watch("role");

  const handleCreate: SubmitHandler<any> = async (formValues) => {
    setLoggingIn(true);
    try {
      const res = await createUser(
        formValues.email,
        formValues.password,
        formValues.name
      );
      if (res) {
        let newUser = await listUsers(res.user_id, accessToken);
        newUser = await putUser(
          {
            id: newUser.id,
            access_token: newUser.access_token,
            token: newUser.token,
            username: newUser.username,
            user_id: newUser.user_id,
            organization_id: orgProperties?.organization_id,
          },
          accessToken
        );
        await postUserRole(
          { user_id: newUser.id, role_id: +formValues.role },
          accessToken
        );
        setUsers((oldUsers) => [...oldUsers, newUser]);
        toast("The user has been created");
        reset();
      } else {
        throw new Error("Could not create user");
      }
    } catch (error) {
      console.log(error);
      toast.error("Could not create user");
    }
    onFinish();
    setLoggingIn(false);
  };
  return (
    <form onSubmit={handleSubmit(handleCreate)} style={{ width: "100%" }}>
      <VStack p={10} bgColor="white" borderRadius={10} spacing={5}>
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input {...register("name")} placeholder="Name" />
        </FormControl>
        <FormControl>
          <FormLabel>Email address</FormLabel>
          <Input
            {...register("email", { required: true })}
            type="email"
            placeholder="Email"
          />
        </FormControl>
        <FormControl>
          <FormLabel>Password</FormLabel>
          <Input
            {...register("password", { required: true })}
            type="password"
            placeholder="Password"
          />
        </FormControl>
        <FormControl>
          <FormLabel>Role</FormLabel>
          <HStack>
            <RoleSelect
              isRequired
              roles={roles}
              value={`${selectedRole}`}
              onChange={(event) => {
                setValue("role", event.target.value || "");
              }}
            />
            <Text>
              {roles?.find((r: any) => r.id === selectedRole)?.description}
            </Text>
          </HStack>
        </FormControl>
        <HStack>
          <Button onClick={() => onFinish()}>Cancel</Button>
          <Button
            isLoading={loggingIn}
            variant="solid"
            type="submit"
            isDisabled={!userResources?.includes(RESOURCES.USER_CREATE)}
            colorScheme="blue"
          >
            Sign up
          </Button>
        </HStack>
      </VStack>
    </form>
  );
}

export default function UserSettings() {
  const [users, setUsers] = useState<any[]>([]);
  const [creatingUser, setCreatingUser] = useState(false);
  const [, setLoadingRoles] = useState(false);
  const [roles, setRoles] = useState<any[]>([]);
  const [orgs, setOrgs] = useState<any[]>([]);
  const accessToken = useAppSelector(selectAccessToken);
  const userResources = useAppSelector(selectUserResources);
  const userRoles = useAppSelector(selectUserRoles);
  const currentUser = useAppSelector(selectCurrentUser);
  const userIsOnlyStoreLocator = useMemo(
    () =>
      userRoles.find((ur: any) => ur.role?.name.includes("store_locator")) &&
      !userRoles.find((ur: any) => !ur.role?.name.includes("store_locator")),
    [userRoles]
  );

  useEffect(() => {
    setLoadingRoles(true);
    getRoles(accessToken || "")
      .then((res) => {
        setRoles(
          res?.filter(
            (r: any) =>
              !userIsOnlyStoreLocator || r.name.includes("store_locator")
          )
        );
      })
      .finally(() => setLoadingRoles(false));
    getOrganizations(accessToken || "").then(setOrgs);
  }, [accessToken, userIsOnlyStoreLocator]);

  return (
    <VStack w="100%">
      {!creatingUser && (
        <Button
          isDisabled={!userResources?.includes(RESOURCES.USER_CREATE)}
          onClick={() => setCreatingUser(!creatingUser)}
        >
          Create new user
        </Button>
      )}
      {!creatingUser && (
        <EntityTable
          initialTableProps={{
            columns: [
              {
                title: "User",
                Render: (props) => <UserItem user={props.rowData} />,
                key: "name",
              },
              {
                title: "Email",
                key: "username",
              },
              {
                title: "Role",
                key: "role",
                filterOptions: roles.map((r) => ({
                  label: r.name,
                  value: `${r.id}`,
                })),
                Render: (props) => (
                  <RoleSelect
                    roles={roles}
                    w={"100%"}
                    value={props.rowData.roles?.[0]?.role_id}
                    onChange={(event) => {
                      const newRoleId = +event.target.value;
                      putUser(
                        {
                          id: props.rowData.id,
                          access_token: props.rowData.access_token,
                          token: props.rowData.token,
                          username: props.rowData.username,
                          user_id: props.rowData.user_id,
                          roles: [
                            {
                              id: props.rowData.roles?.[0]?.id,
                              role_id: newRoleId,
                              user_id: props.rowData.id,
                            },
                          ],
                        },
                        accessToken
                      ).then((newUser) => {
                        setUsers((oldUsers) => {
                          const newUsers = [...oldUsers];
                          const i = oldUsers.findIndex(
                            (item) => item.id === props.rowData.id
                          );
                          newUsers[i] = newUser;
                          return newUsers;
                        });
                      });
                    }}
                  />
                ),
              },
              ...(currentUser.is_super
                ? [
                    {
                      title: "Organization",
                      key: "organization_id",
                      filterOptions: orgs.map((r) => ({
                        label: r.name,
                        value: `${r.id}`,
                      })),
                      Render: (props: ICellEditorProps) => (
                        <OrgSelect
                          value={props.rowData.organization_id}
                          onChange={(event) => {
                            const newOrg = +event;
                            putUser(
                              {
                                id: props.rowData.id,
                                access_token: props.rowData.access_token,
                                token: props.rowData.token,
                                username: props.rowData.username,
                                user_id: props.rowData.user_id,
                                organization_id: newOrg,
                              },
                              accessToken
                            ).then((newUser) => {
                              setUsers((oldUsers) => {
                                const newUsers = [...oldUsers];
                                const i = oldUsers.findIndex(
                                  (item) => item.id === props.rowData.id
                                );
                                newUsers[i] = newUser;
                                return newUsers;
                              });
                            });
                          }}
                        />
                      ),
                    },
                  ]
                : []),
              {
                title: "Status",
                key: "active",
                filterOptions: [
                  { value: "false", label: "Active" },
                  { value: "true", label: "Inactive" },
                ],
                Render: (props) =>
                  props.rowData.active === "true" ? (
                    <Badge colorScheme="green">Active</Badge>
                  ) : (
                    <Badge>Inactive</Badge>
                  ),
              },
              {
                title: "Options",
                key: "options",
                Render: (props) => (
                  <UserOptions user={props.rowData} setUsers={setUsers} />
                ),
              },
            ],
          }}
          entityName="User"
          dataFromOutside={users.map((u) => ({
            ...u,
            active: u.active ? "true" : "false",
            role: u.roles?.[0]?.role_id,
          }))}
          fetch={async () => {
            const u = await listUsers(undefined, accessToken);
            console.log(u);
            return u || [];
          }}
          setOutsideData={(u) => setUsers(u || [])}
        />
      )}
      {!!creatingUser && (
        <CreateUserForm
          setUsers={setUsers}
          roles={roles}
          onFinish={() => setCreatingUser(false)}
        />
      )}
    </VStack>
  );
}
