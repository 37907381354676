import { assign, keys, pick } from "lodash";
import { ImportHistory } from "./ImportHistory";

export class StoreImportHistory extends ImportHistory {
  num_stores_added?: number;
  num_stores_removed?: number;
  num_products_added?: number;
  new_product_availability?: string;
  availability_file_key?: string;

  constructor(candidate: Partial<StoreImportHistory>) {
    super(candidate);
    assign(this, pick(candidate, keys(this)));
  }
}
