import React, { useState } from "react";
import {
  Flex,
  Input,
  FormControl,
  FormLabel,
  Text,
  Select,
  FormErrorMessage,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  IconButton,
  Button,
  VStack,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import { RequiredAsterisk } from "../../components/MultiSelectFormControl";
import CheckboxGridInput from "../../components/CheckboxGridInput";
import Slider from "../../components/Slider";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { camelCaseWords } from "../../utils/stringUtils";

export const _AudienceSelection = (props) => {
  const {
    orgConfig,
    educationalAttainmentOptions,
    travelTimeToWorkOptions,
    personalIncomeOptions,
    genderOptions,
    employmentOptions,
    selectedItems,
    changeSelectedItems,
    register,
    formState,
    audienceVariables,
  } = props;
  const { errors = {} } = formState || {};
  const [visibleDemographicFilters, setVisibleDemographicFilters] = useState(
    []
  );

  const validateJsonFilter = (variableName) => {
    if (
      (selectedItems?.otherFilters?.[`min_${variableName}`] || ":").split(
        ":"
      )[1] ||
      (selectedItems?.otherFilters?.[`max_${variableName}`] || ":").split(
        ":"
      )[1] ||
      (
        selectedItems?.otherFilters?.[`min_${variableName}`] ||
        selectedItems?.otherFilters?.[`max_${variableName}`] ||
        ""
      ).split(":")[0]
    ) {
      if (
        !(
          selectedItems?.otherFilters?.[`min_${variableName}`] ||
          selectedItems?.otherFilters?.[`max_${variableName}`] ||
          ""
        ).split(":")[0]
      ) {
        return "You must select an option";
      } else if (
        !(selectedItems?.otherFilters?.[`min_${variableName}`] || ":").split(
          ":"
        )[1] &&
        !(selectedItems?.otherFilters?.[`max_${variableName}`] || ":").split(
          ":"
        )[1]
      ) {
        return "You must set either min or max percentage of population";
      }
    }
    return true;
  };

  const filterVariables = [
    {
      type: "slider",
      label: "% Hispanics",
      defaultValue: [20, 60],
      step: 1,
      min: 0,
      max: 100,
      minValue: selectedItems.dynamicFilters?.perc_hispanics?.[0]?.value,
      maxValue: selectedItems.dynamicFilters?.perc_hispanics?.[1]?.value,
      onChange: (val) =>
        changeSelectedItems((oldValues) => ({
          ...oldValues,
          dynamicFilters: {
            ...(oldValues.dynamicFilters || {}),
            perc_hispanics: [
              {
                id: 1,
                params: "demographic.total,demographic.hispanic_or_latino",
                comparator: ">",
                value: val[0],
              },
              {
                id: 1,
                params: "demographic.total,demographic.hispanic_or_latino",
                comparator: "<",
                value: val[1],
              },
            ],
          },
        })),
      onClose: (val) =>
        changeSelectedItems((oldValues) => ({
          ...oldValues,
          dynamicFilters: {
            ...(oldValues.dynamicFilters || {}),
            perc_hispanics: undefined,
          },
        })),
    },
    {
      type: "slider",
      label: "Age (Years)",
      defaultValue: [20, 60],
      step: 1,
      min: 10,
      max: 80,
      minValue: selectedItems.otherFilters?.min_age,
      maxValue: selectedItems.otherFilters?.max_age,
      onChange: (val) =>
        changeSelectedItems((oldValues) => ({
          ...oldValues,
          otherFilters: {
            ...(oldValues.otherFilters || {}),
            min_age: val[0],
            max_age: val[1],
          },
        })),
      onClose: (val) =>
        changeSelectedItems((oldValues) => ({
          ...oldValues,
          otherFilters: {
            ...(oldValues.otherFilters || {}),
            min_age: undefined,
            max_age: undefined,
          },
        })),
    },
    {
      type: "slider",
      label: "Household Income",
      defaultValue: [150000, 200000],
      step: 1,
      min: 20000,
      max: 200000,
      minValue: selectedItems.otherFilters?.min_household_income,
      maxValue: selectedItems.otherFilters?.max_household_income,
      onChange: (val) =>
        changeSelectedItems((oldValues) => ({
          ...oldValues,
          otherFilters: {
            ...(oldValues.otherFilters || {}),
            min_household_income: val[0],
            max_household_income: val[1],
          },
        })),
      onClose: (val) =>
        changeSelectedItems((oldValues) => ({
          ...oldValues,
          otherFilters: {
            ...(oldValues.otherFilters || {}),
            min_household_income: undefined,
            max_household_income: undefined,
          },
        })),
    },
    {
      type: "slider",
      label: "Population Denisty (people/mi^2)",
      defaultValue: [600, 3000],
      min: 500,
      max: 5000,
      minValue: selectedItems.dynamicFilters?.pop_density?.[0]?.value,
      maxValue: selectedItems.dynamicFilters?.pop_density?.[1]?.value,
      onChange: (val) =>
        changeSelectedItems((oldValues) => ({
          ...oldValues,
          dynamicFilters: {
            ...(oldValues.dynamicFilters || {}),
            pop_density: [
              {
                id: 2,
                params: "demographic.total",
                comparator: ">",
                value: val[0],
              },
              {
                id: 2,
                params: "demographic.total",
                comparator: "<",
                value: val[1],
              },
            ],
          },
        })),
      onClose: (val) =>
        changeSelectedItems((oldValues) => ({
          ...oldValues,
          dynamicFilters: {
            ...(oldValues.dynamicFilters || {}),
            pop_density: undefined,
          },
        })),
    },
    {
      type: "slider",
      label: "Home Value",
      defaultValue: [1000000, 9000000],
      step: 1,
      min: 500000,
      max: 10000000,
      minValue: selectedItems.otherFilters?.min_home_value,
      maxValue: selectedItems.otherFilters?.max_home_value,
      onChange: (val) =>
        changeSelectedItems((oldValues) => ({
          ...oldValues,
          otherFilters: {
            ...(oldValues.otherFilters || {}),
            min_home_value: val[0],
            max_home_value: val[1],
          },
        })),
      onClose: (val) =>
        changeSelectedItems((oldValues) => ({
          ...oldValues,
          otherFilters: {
            ...(oldValues.otherFilters || {}),
            min_home_value: undefined,
            max_home_value: undefined,
          },
        })),
    },
    {
      type: "slider",
      label: "% people working full-time",
      defaultValue: [10, 90],
      step: 1,
      min: 0,
      max: 100,
      minValue: (
        selectedItems?.otherFilters?.min_employment_status || ":"
      ).split(":")[1],
      maxValue: (
        selectedItems?.otherFilters?.max_employment_status || ":"
      ).split(":")[1],
      onChange: (val) =>
        changeSelectedItems((oldValues) => ({
          ...oldValues,
          otherFilters: {
            ...(oldValues.otherFilters || {}),
            min_employment_status: `${employmentOptions.find((option) =>
              option.includes("full")
            )}:${val[0]}`,
            max_employment_status: `${employmentOptions.find((option) =>
              option.includes("full")
            )}:${val[1]}`,
          },
        })),
      onClose: (val) =>
        changeSelectedItems((oldValues) => ({
          ...oldValues,
          otherFilters: {
            ...(oldValues.otherFilters || {}),
            min_employment_status: undefined,
            max_employment_status: undefined,
          },
        })),
    },
    {
      type: "slider",
      label: "Weekly Digital Engagement",
      defaultValue: [5000, 800000],
      step: 50,
      min: 200,
      max: 1000000,
      minValue: selectedItems.otherFilters?.min_engagement,
      maxValue: selectedItems.otherFilters?.max_engagement,
      onChange: (val) =>
        changeSelectedItems((oldValues) => ({
          ...oldValues,
          otherFilters: {
            ...(oldValues.otherFilters || {}),
            min_engagement: val[0],
            max_engagement: val[1],
          },
        })),
      onClose: (val) =>
        changeSelectedItems((oldValues) => ({
          ...oldValues,
          otherFilters: {
            ...(oldValues.otherFilters || {}),
            min_engagement: undefined,
            max_engagement: undefined,
          },
        })),
    },
    {
      type: "json",
      label: "Highest degree of education",
      middleText: "% of the population have",
      variableName: "perc_education",
      selectOptions: educationalAttainmentOptions,
      min: 0,
      max: 100,
      onClose: (val) =>
        changeSelectedItems((oldValues) => ({
          ...oldValues,
          otherFilters: {
            ...(oldValues.otherFilters || {}),
            max_perc_education: undefined,
            min_perc_education: undefined,
          },
        })),
    },
    {
      type: "json",
      label: "Personal Income",
      middleText: "% of the population annually recieve",
      variableName: "personal_income",
      selectOptions: personalIncomeOptions,
      min: 0,
      max: 100,
      onClose: (val) =>
        changeSelectedItems((oldValues) => ({
          ...oldValues,
          otherFilters: {
            ...(oldValues.otherFilters || {}),
            max_personal_income: undefined,
            min_personal_income: undefined,
          },
        })),
    },
    {
      type: "json",
      label: "Gender",
      middleText: "% of the population are",
      variableName: "gender",
      selectOptions: genderOptions,
      min: 0,
      max: 100,
      onClose: (val) =>
        changeSelectedItems((oldValues) => ({
          ...oldValues,
          otherFilters: {
            ...(oldValues.otherFilters || {}),
            max_gender: undefined,
            min_gender: undefined,
          },
        })),
    },
    {
      type: "json",
      label: "Avg travel time to work",
      middleText: "% of the population takes minutes to work",
      variableName: "travel_time_to_work",
      selectOptions: travelTimeToWorkOptions,
      min: 0,
      max: 100,
      onClose: (val) =>
        changeSelectedItems((oldValues) => ({
          ...oldValues,
          otherFilters: {
            ...(oldValues.otherFilters || {}),
            max_travel_time_to_work: undefined,
            min_travel_time_to_work: undefined,
          },
        })),
    },
  ].filter(
    (variable) => !variable.selectOptions || !!variable.selectOptions.length
  );

  return (
    <>
      <AccordionItem>
        <AccordionButton fontSize={18} fontWeight="bold">
          Heritage to color by
          <RequiredAsterisk />
          <AccordionIcon ml="auto" />
        </AccordionButton>
        <AccordionPanel>
          <FormControl>
            <CheckboxGridInput
              options={
                orgConfig?.limitedAudiences?.map((variable) => ({
                  value: variable,
                  label: camelCaseWords(variable),
                })) ||
                audienceVariables.map((key) => ({
                  value: key,
                  label: camelCaseWords(key),
                }))
              }
              value={selectedItems?.nationality}
              onChange={(value) =>
                changeSelectedItems((oldValues) => ({
                  ...oldValues,
                  nationality: value || [],
                }))
              }
              name="heritage"
              refForCheckboxes={register("heritage", {
                required: false,
                validate: () => {
                  return (
                    Object.values(selectedItems.nationality || {}).find(
                      (val) => !!val
                    ) || "Please select at least one audience"
                  );
                },
              })}
            />
            <Text color="tomato">{errors?.heritage?.message}</Text>
          </FormControl>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionButton fontSize={18} fontWeight="bold">
          Demographic filters
          <AccordionIcon ml="auto" />
        </AccordionButton>
        <AccordionPanel>
          <VStack>
            {filterVariables
              .filter(
                (filterVariable) =>
                  !!visibleDemographicFilters.includes(filterVariable.label)
              )
              .map((filterVariable) => (
                <Box
                  key={filterVariable.label}
                  bg="#F5F5F5"
                  p={3}
                  position="relative"
                  w="100%"
                >
                  <IconButton
                    position="absolute"
                    zIndex={9}
                    top={3}
                    right={3}
                    icon={<CloseIcon />}
                    onClick={() => {
                      setVisibleDemographicFilters((oldVisible) => {
                        const newVisible = [...oldVisible];
                        newVisible.splice(
                          oldVisible.findIndex(
                            (item) => item.label === filterVariable.label
                          ),
                          1
                        );
                        return newVisible;
                      });
                      filterVariable.onClose?.();
                    }}
                  />

                  <FormControl
                    isInvalid={!!errors?.[filterVariable.variableName]?.message}
                  >
                    <FormLabel>
                      {filterVariable.label}
                      {filterVariable.type === "json" &&
                        typeof validateJsonFilter("perc_education") ===
                          "string" && <RequiredAsterisk />}
                    </FormLabel>
                    {filterVariable.type === "slider" && (
                      <Slider
                        sliderProps={{
                          defaultValue: filterVariable.defaultValue,
                          value: [
                            filterVariable.minValue,
                            filterVariable.maxValue,
                          ],
                          min: filterVariable.min,
                          max: filterVariable.max,
                          step: filterVariable.step,
                          onChange: filterVariable.onChange,
                        }}
                      />
                    )}
                    {filterVariable.type === "json" && (
                      <>
                        <Flex
                          flexDir="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Input
                            {...register(filterVariable.variableName, {
                              required: false,
                              validate: () =>
                                validateJsonFilter(filterVariable.variableName),
                            })}
                            type="number"
                            w={70}
                            min={filterVariable.min}
                            max={filterVariable.max}
                            placeholder="min %"
                            value={
                              (
                                selectedItems?.otherFilters?.[
                                  `min_${filterVariable.variableName}`
                                ] || ":"
                              ).split(":")[1]
                            }
                            onChange={(event) => {
                              return (
                                +event.target.value <= filterVariable.max &&
                                +event.target.value >= filterVariable.min &&
                                changeSelectedItems((oldValues) => ({
                                  ...oldValues,
                                  otherFilters: {
                                    ...(oldValues.otherFilters || {}),
                                    [`min_${filterVariable.variableName}`]:
                                      event.target.value
                                        ? `${
                                            (
                                              oldValues?.otherFilters?.[
                                                `min_${filterVariable.variableName}`
                                              ] || ""
                                            ).split(":")[0]
                                          }:${event.target.value}`
                                        : undefined,
                                  },
                                }))
                              );
                            }}
                          />
                          -
                          <Input
                            type="number"
                            w={70}
                            min={filterVariable.min}
                            max={filterVariable.max}
                            placeholder="max %"
                            value={
                              (
                                selectedItems?.otherFilters?.[
                                  `max_${filterVariable.variableName}`
                                ] || ":"
                              ).split(":")[1]
                            }
                            onChange={(event) =>
                              +event.target.value <= filterVariable.max &&
                              +event.target.value >= filterVariable.min &&
                              changeSelectedItems((oldValues) => ({
                                ...oldValues,
                                otherFilters: {
                                  ...(oldValues.otherFilters || {}),
                                  [`max_${filterVariable.variableName}`]: event
                                    .target.value
                                    ? `${
                                        (
                                          oldValues?.otherFilters?.[
                                            `max_${filterVariable.variableName}`
                                          ] || ""
                                        ).split(":")[0]
                                      }:${event.target.value}`
                                    : undefined,
                                },
                              }))
                            }
                          />
                          <Text>{filterVariable.middleText}</Text>
                          <Select
                            w={200}
                            value={
                              (
                                selectedItems?.otherFilters?.[
                                  `min_${filterVariable.variableName}`
                                ] ||
                                selectedItems?.otherFilters?.[
                                  `max_${filterVariable.variableName}`
                                ] ||
                                ""
                              ).split(":")[0]
                            }
                            onChange={(event) =>
                              changeSelectedItems((oldValues) => ({
                                ...oldValues,
                                otherFilters: {
                                  ...(oldValues.otherFilters || {}),
                                  [`min_${filterVariable.variableName}`]: event
                                    .target.value
                                    ? `${event.target.value}:${
                                        (
                                          oldValues?.otherFilters?.[
                                            `min_${filterVariable.variableName}`
                                          ] || ":"
                                        ).split(":")[1]
                                      }`
                                    : undefined,
                                  [`max_${filterVariable.variableName}`]: event
                                    .target.value
                                    ? `${event.target.value}:${
                                        (
                                          oldValues?.otherFilters?.[
                                            `max_${filterVariable.variableName}`
                                          ] || ":"
                                        ).split(":")[1]
                                      }`
                                    : undefined,
                                },
                              }))
                            }
                          >
                            <option value="" disabled>
                              Select{" "}
                              {camelCaseWords(filterVariable.variableName)}
                            </option>
                            {filterVariable.selectOptions?.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </Select>
                        </Flex>
                        <FormErrorMessage>
                          {errors?.[filterVariable.variableName]?.message}
                        </FormErrorMessage>
                      </>
                    )}
                  </FormControl>
                </Box>
              ))}
          </VStack>
          {!!filterVariables.filter(
            (filterVariable) =>
              !visibleDemographicFilters.includes(filterVariable.label)
          ).length && (
            <Flex mt={3} flexDir="row" flexWrap="wrap" alignItems="center">
              <Text mr={3} mb={3}>
                Add {!!visibleDemographicFilters?.length && "more "}filters:
              </Text>
              {filterVariables
                .filter(
                  (filterVariable) =>
                    !visibleDemographicFilters.includes(filterVariable.label)
                )
                .map((filterVariable) => (
                  <Button
                    key={filterVariable.label}
                    mr={3}
                    mb={3}
                    rightIcon={<AddIcon />}
                    onClick={() =>
                      setVisibleDemographicFilters((oldVisible) => [
                        ...oldVisible,
                        filterVariable.label,
                      ])
                    }
                  >
                    {filterVariable.label}
                  </Button>
                ))}
            </Flex>
          )}
        </AccordionPanel>
      </AccordionItem>
    </>
  );
};

export const AudienceSelection = connect((state) => {
  return {
    orgConfig: state.app.orgProperties?.properties,
    educationalAttainmentOptions: (
      state.app.properties?.find(
        (properties) => properties.name === "options_educational_attainment"
      )?.value || []
    ).filter(
      (option) =>
        ![
          "10th grade",
          "11th grade",
          "12th grade, no diploma",
          "5th and 6th grade",
          "7th and 8th grade",
          "9th grade",
          "Nursery to 4th grade",
          "Professional school degree",
          "Some college, 1 or more years, no degree",
          "Some college, less than 1 year",
        ].includes(option)
    ),
    travelTimeToWorkOptions:
      state.app.properties?.find(
        (properties) => properties.name === "options_travel_time_to_work"
      )?.value || [],
    personalIncomeOptions:
      state.app.properties?.find(
        (properties) => properties.name === "options_personal_income"
      )?.value || [],
    genderOptions:
      state.app.properties?.find(
        (properties) => properties.name === "options_gender"
      )?.value || [],
    employmentOptions:
      state.app.properties?.find(
        (properties) => properties.name === "options_employment_status"
      )?.value || [],
    audienceVariables:
      state.app.properties?.find(
        (properties) => properties.name === "audiences"
      )?.value || [],
  };
})(_AudienceSelection);
