import { PutObjectCommand } from "@aws-sdk/client-s3";
import { WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  IconButton,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { ICellTextProps } from "ka-table/props";
import { merge } from "lodash";
import pluralize from "pluralize";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  addOpenUploadProxy,
  addStatusMessage,
  removeStatusMessage,
  selectAccessToken,
  selectS3Client,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  CustomColumn,
  EntityTable,
} from "../../components/entity-table/EntityTable";
import MessageModalContext, {
  MessageModal,
} from "../../contexts/MessageModalContext";

export type S3Params = {
  folderName: string;
  file: File;
  getFileName: string | ((file: File) => string);
  isPublic?: boolean;
};
export type FinishMessage =
  | boolean
  | MessageModal
  | ((data: any) => MessageModal);

type UseUploadParams<T> = {
  postBatch?: Function;
  putBatch?: Function;
  deleteBatch?: Function;
  postItem?: Function;
  validator?: Function;
  entityName?: string;
  noModals?: boolean;
  schema?: CustomColumn<T>[];
};

type UseUploadSupportParams = {
  startSupportCondition?: boolean;
  startSupportTimeout?: number;
  useEffectDependencies?: any[];
  eventName: string;
  messageTriggerTicket?: string;
  createSupportTicket?: () => Promise<void>;
};

export const useUpload = <T,>(props?: UseUploadParams<T>) => {
  const {
    postBatch,
    postItem,
    validator,
    entityName,
    schema,
    putBatch,
    deleteBatch,
    noModals,
  } = props || {};
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(selectAccessToken);
  const s3client = useAppSelector(selectS3Client);
  const shouldCancelProcess = useRef(false);
  const messageModalContext = useContext(MessageModalContext);
  const [validatingMessage, setValidatingMessage] = useState<MessageModal>();
  const [updatingItems, setUpdatingItems] = useState(false);
  const [uploadingItems, setUploadingItems] = useState(false);

  const validateItem = useCallback(
    (item: any) => {
      const _schemas = schema || [];
      for (let i = 0; i < Object.keys(item).length; i++) {
        const key = Object.keys(item)[i];

        const schemaField = _schemas.find((item) => item.key === key);
        if (
          schemaField &&
          item[key] !== 0 &&
          !item[key] &&
          schemaField.isRequired
        ) {
          item.error = `${key} is required`;
          return false;
        }
        if (
          schemaField &&
          schemaField.dataType === "number" &&
          isNaN(Number(item[key]))
        ) {
          item.error = `${key} must be a number`;
          return false;
        }
      }
      item.error = undefined;
      return true;
    },
    [schema]
  );

  const WarningTooltip = ({ rowData }: ICellTextProps) => {
    return (
      <Tooltip hasArrow label={rowData?.error} bg="red.600">
        {rowData?.error ? (
          <IconButton
            icon={<WarningIcon />}
            aria-label={"Warning icon for upload item"}
          />
        ) : (
          <Box></Box>
        )}
      </Tooltip>
    );
  };

  const showDataWithProblems = useCallback(
    async (dataWithProblems: T[]): Promise<T> => {
      return new Promise((resolve, reject) => {
        messageModalContext.showModal({
          title: `Items with errors`,
          initialDialogState: { dataWithProblems },
          variant: "error",
          hideCloseButton: true,
          modalProps: { size: "6xl", closeOnEsc: false },
          message: (dialogState, setDialogState) => (
            <VStack>
              <Text>
                The following items have errors. Please review and correct them
                before uploading.
              </Text>
              <EntityTable
                initialTableProps={{
                  columns: schema || [],
                }}
                dataFromOutside={dialogState.dataWithProblems?.map(
                  (item: any, index: number) => ({
                    ...item,
                    id: index,
                  })
                )}
                putItem={async (index: any, item: any) => {
                  validateItem(item);
                  try {
                    if (postItem) {
                      await postItem(item, accessToken);
                      toast.success(`item fixed and created`);
                    }
                  } catch (error) {
                    const _error = error as { message: string; response: any };
                    item.error = `${_error.message}: ${
                      _error.response?.data?.msg ||
                      _error.response?.data?.message
                    }`;
                    toast.error(
                      `Could not create item: ${_error.message}: ${
                        _error.response?.data?.msg ||
                        _error.response?.data?.message
                      }`
                    );
                  }
                  setDialogState((oldState: any) => {
                    const oldItems = oldState.dataWithProblems;
                    const updated = [...(oldItems || [])];
                    updated[index] = {
                      ...item,
                    };
                    return { dataWithProblems: updated };
                  });
                }}
                LeftButton={WarningTooltip}
              />
            </VStack>
          ),
          actions: [
            {
              label: "Cancel",
              isLeastDestructive: true,
              callback() {
                reject();
              },
            },
            {
              label: "Continue",
              props: { colorScheme: "blue" },
              callback(index, dialogState, setDialogState) {
                resolve(
                  (dialogState.dataWithProblems || [])
                    .filter((item: any) => !item.error)
                    .map((item: any) => {
                      const { id, error, ...rest } = item;
                      return rest;
                    })
                );
              },
            },
          ],
        });
      });
    },
    [accessToken, postItem, schema, validateItem]
  );

  const uploadItems = useCallback(
    async (itemsToUpload: any[], finishMessage?: FinishMessage) => {
      let goodItems: { item: T; original: any }[] = [];
      const badItems: any[] = [];
      const existingItems: any[] = [];

      setUploadingItems(true);
      if (postBatch) {
        try {
          dispatch(
            addStatusMessage({
              id: "creatingitemsbatch",
              title: `Adding ${itemsToUpload.length} items`,
              loading: true,
            })
          );
          const ids = await postBatch(itemsToUpload, accessToken);
          goodItems = goodItems.concat(
            itemsToUpload.map((item, i) => ({
              item: { ...item, id: ids[i] },
              original: item,
            }))
          );
        } finally {
          dispatch(removeStatusMessage("creatingitemsbatch"));
        }
      } else {
        const promises = [];
        for (let i = 0; i < itemsToUpload.length; i++) {
          if (shouldCancelProcess.current) {
            shouldCancelProcess.current = false;
            break;
          }
          const item = itemsToUpload[i];
          Object.keys(item).forEach((key) => {
            if (item[key] === null) {
              delete item[key];
            }
          });
          promises.push(
            new Promise(async (resolve) => {
              try {
                if (postItem) {
                  const newItem = await postItem(item, accessToken);
                  goodItems.push({ item: newItem, original: item });
                }
              } catch (error) {
                const _error = error as {
                  response: { data: { message: string; msg: string } };
                  message: string;
                };
                if (_error.response.data.message === "IntegrityError") {
                  existingItems.push({
                    ...item,
                  });
                } else {
                  badItems.push({
                    ...item,
                    error: `${_error.message}: ${
                      _error.response?.data?.msg ||
                      _error.response?.data?.message
                    }`,
                  });
                }
              }
              resolve(false);
            })
          );
        }
        const modalIndex = messageModalContext.showModal({
          message: (
            <VStack>
              <Spinner />
              <Text>
                {validator ? "Validating" : "Creating"}{" "}
                {pluralize(entityName || "item", itemsToUpload.length, true)}
              </Text>
              <Text>This can take a moment.</Text>
            </VStack>
          ),
          actions: [{ label: "Continue in background" }],
        });
        await Promise.all(promises);
        messageModalContext.dismissModal(modalIndex);
      }
      setUploadingItems(false);
      const message =
        typeof finishMessage === "function"
          ? finishMessage(goodItems)
          : finishMessage;
      if (goodItems.length && message) {
        const defaultMessage = {
          title: `You have successfully added the ${pluralize(
            entityName || "item"
          )}`,
          variant: "success",
          actions: [
            {
              label: `Add more`,
            },
          ],
        };
        messageModalContext.showModal(
          merge(defaultMessage, typeof message !== "boolean" ? message : {})
        );
      }
      if (badItems.length) {
        showDataWithProblems(badItems);
        const defaultMessage = {
          title: `Some ${pluralize(entityName || "item")} could not be added`,
          variant: "error",
          actions: [
            {
              label: `Try again`,
            },
          ],
        };
        if (finishMessage) {
          messageModalContext.showModal(
            merge(defaultMessage, typeof message !== "boolean" ? message : {})
          );
        }
      }
      if (existingItems.length) {
        toast.info(`${existingItems.length} items already exist`);
      }
      return { goodItems, badItems, existingItems };
    },
    [
      accessToken,
      dispatch,
      entityName,
      postBatch,
      postItem,
      showDataWithProblems,
      validator,
    ]
  );

  const updateItems = useCallback(
    async (itemsToUpdate: any[], finishMessage?: FinishMessage) => {
      let goodItems: { item: T; original: any }[] = [];

      if (putBatch) {
        try {
          setUpdatingItems(true);
          dispatch(
            addStatusMessage({
              id: "updatingitemsbatch",
              title: `Adding ${itemsToUpdate.length} items`,
              loading: true,
            })
          );
          const result = await putBatch(itemsToUpdate, accessToken);
          goodItems =
            result === "ok"
              ? goodItems.concat(
                  itemsToUpdate.map((item, i) => ({
                    item,
                    original: item,
                  }))
                )
              : [];
        } finally {
          setUpdatingItems(false);
          dispatch(removeStatusMessage("updatingitemsbatch"));
        }
      }
      const message =
        typeof finishMessage === "function"
          ? finishMessage(goodItems)
          : finishMessage;
      if (goodItems.length && message) {
        const defaultMessage = {
          title: `You have successfully updated the ${pluralize(
            entityName || "item"
          )}`,
          variant: "success",
          actions: [
            {
              label: `Add more`,
            },
          ],
        };
        messageModalContext.showModal(
          merge(defaultMessage, typeof message !== "boolean" ? message : {})
        );
      }
      return { goodItems };
    },
    [accessToken, dispatch, entityName, putBatch]
  );

  const deleteItems = useCallback(
    async (itemsToUpdate: any[], finishMessage?: FinishMessage) => {
      let goodItems: { item: T; original: any }[] = [];

      if (deleteBatch) {
        try {
          dispatch(
            addStatusMessage({
              id: "deletingitemsbatch",
              title: `Deleting ${itemsToUpdate.length} items`,
              loading: true,
            })
          );
          const result = await deleteBatch(itemsToUpdate, accessToken);
          goodItems =
            result === "ok"
              ? goodItems.concat(
                  itemsToUpdate.map((item, i) => ({
                    item,
                    original: item,
                  }))
                )
              : [];
        } finally {
          dispatch(removeStatusMessage("deletingitemsbatch"));
        }
      }
      const message =
        typeof finishMessage === "function"
          ? finishMessage(goodItems)
          : finishMessage;
      if (goodItems.length && message) {
        const defaultMessage = {
          title: `You have successfully deleting the ${pluralize(
            entityName || "item"
          )}`,
          variant: "success",
          actions: [
            {
              label: `Add more`,
            },
          ],
        };
        messageModalContext.showModal(
          merge(defaultMessage, typeof message !== "boolean" ? message : {})
        );
      }
      return { goodItems };
    },
    [accessToken, deleteBatch, dispatch, entityName]
  );

  const onValidate = useCallback(
    async (
      data?: T[],
      proxyName?: string,
      dataWithProblems?: T[],
      onClose = () => {},
      proxyParams?: { [key: string]: any }
    ): Promise<{ selectedItems?: T[]; validatedItems?: T[] }> => {
      let _data = data || [];
      if (dataWithProblems?.length) {
        const fixedData = await showDataWithProblems(dataWithProblems);
        _data = _data.concat(fixedData);
      }
      if (_data?.length) {
        const itemsToUpload = [..._data];
        const _validatingMessage = {
          message: (
            <VStack>
              <Spinner />
              <Text>
                {validator ? "Validating" : "Creating"}{" "}
                {pluralize(entityName || "item", itemsToUpload.length, true)}
              </Text>
              <Text>This can take a moment.</Text>
            </VStack>
          ),
          actions: [{ label: "Continue in background" }],
        };
        setValidatingMessage(_validatingMessage);
        let modalIndex: number | undefined;
        if (!noModals) {
          modalIndex = messageModalContext.showModal(_validatingMessage);
        }
        dispatch(
          addStatusMessage({
            id: "validatingitemsbatch",
            title: `Validating ${pluralize(
              entityName || "item",
              itemsToUpload.length,
              true
            )}`,
            loading: true,
          })
        );
        return new Promise((resolve, reject) => {
          (validator || (async () => itemsToUpload))(itemsToUpload, accessToken)
            .then((validatedItems: any[]) => {
              if (proxyName) {
                const _onClose = () => {
                  onClose?.();
                  resolve({});
                };
                dispatch(
                  addOpenUploadProxy({
                    name: proxyName || "",
                    props: {
                      items: validatedItems,
                      ...(proxyParams || {}),
                      onFinish: (selectedItems: any[]) => {
                        if (selectedItems) {
                          resolve({ selectedItems, validatedItems });
                        }
                        _onClose();
                      },
                      onClose: _onClose,
                    },
                  })
                );
              } else {
                resolve({ validatedItems });
              }
            })
            .catch((e: any) => {
              toast.error(e.message);
              reject(e);
            })
            .finally(() => {
              if (typeof modalIndex === "number")
                messageModalContext.dismissModal(modalIndex);
              setValidatingMessage(undefined);
              dispatch(removeStatusMessage("validatingitemsbatch"));
            });
        });
      }
      return new Promise(() => []);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      accessToken,
      dispatch,
      entityName,
      noModals,
      showDataWithProblems,
      validator,
    ]
  );

  const uploadFileToS3 = useCallback(
    async (params: S3Params) => {
      const { folderName, file, getFileName, isPublic } = params;
      try {
        const folderKey = encodeURIComponent(folderName) + "/";
        const fileName =
          typeof getFileName === "function" ? getFileName(file) : getFileName;
        const Key = folderKey + fileName;
        const Bucket = isPublic
          ? process.env.REACT_APP_S3_PUBLIC_BUCKET
          : process.env.REACT_APP_S3_BUCKET;
        const uploadParams = {
          Bucket,
          Key,
          Body: file,
          ACL: isPublic ? "public-read" : undefined,
        };
        try {
          await s3client.send(new PutObjectCommand(uploadParams));
          return `https://${Bucket}.s3.amazonaws.com/${Key}`;
        } catch (err: any) {
          toast.error(`There was an error uploading your file: ${err.message}`);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [s3client]
  );

  return {
    uploadItems,
    showDataWithProblems,
    onValidate,
    uploadFileToS3,
    updateItems,
    deleteItems,
    validatingMessage,
    updatingItems,
    uploadingItems,
  };
};

export const useUploadSupport = (props: UseUploadSupportParams) => {
  const {
    startSupportCondition = true,
    startSupportTimeout = 2000,
    useEffectDependencies = [],
    eventName,
    messageTriggerTicket,
    createSupportTicket,
  } = props;
  const helpMessageTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const sendHelpEvent = () => window.tidioChatApi.track(eventName);

    function onUserRequestsHelp(e: Event) {
      if (e.data.message === messageTriggerTicket && createSupportTicket) {
        createSupportTicket()
          .then((supportTicket) => {
            window.tidioChatApi.messageFromOperator(
              `A support ticket has been created with id: ${supportTicket.identifier}.`
            );
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            window.tidioChatApi.messageFromOperator(
              `We will review the file you uploaded and let you know if we see any issues.`
            );
            window.tidioChatApi.messageFromOperator(
              `Please let us know the issue you are facing.`
            );
          });
      }
    }
    document.removeEventListener(
      "tidioChat-messageFromVisitor",
      onUserRequestsHelp
    );
    document.addEventListener(
      "tidioChat-messageFromVisitor",
      onUserRequestsHelp
    );
    if (startSupportCondition && !helpMessageTimeout.current) {
      helpMessageTimeout.current = setTimeout(
        sendHelpEvent,
        startSupportTimeout
      );
    }
    return () => {
      clearTimeout(helpMessageTimeout.current);
      document.removeEventListener(
        "tidioChat-messageFromVisitor",
        onUserRequestsHelp
      );
    };
  }, useEffectDependencies);

  return {
    startSupport: () => window.tidioChatApi.track(eventName),
  };
};
