import { SearchIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Alert,
  Box,
  Button,
  FormControl,
  HStack,
  Image,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Tag,
  TagCloseButton,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { ICellEditorProps, ICellTextProps } from "ka-table/props";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SuggestionSelectedEventData } from "react-autosuggest";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  selectAccessToken,
  selectOrgProperties,
  selectProducts,
  setProducts,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import AutocompleteInput from "../../components/AutocompleteInput";
import { EntityTable } from "../../components/entity-table/EntityTable";
import { ResponsiveModal } from "../../components/responsive-modal";
import { Product } from "../../domain/Product";
import { Store } from "../../domain/Store";
import { getStores } from "../../services/api.service";
import ProductForm from "../products/ProductForm";
import { refreshIframe } from "../store-locator/storeLocatorSlice";

export type State = {
  data?: any[];
  selectedFields: Object;
  detectedProducts?: any[];
  newProducts?: number[];
  newDetectedProducts?: {
    fileProduct?: string;
    productName?: string;
    productId?: number;
  }[];
  relationMethod?: string;
  selectedProductColumn?: string;
  storesRepeat?: string;
  separator?: string;
};

type Props = {
  state: State;
  setState: React.Dispatch<React.SetStateAction<State | undefined>>;
  onFinish?: (data: any[]) => void;
  noModals?: boolean;
  actionsFromOutside?: React.SetStateAction<any>;
};

function ProductMatchSelector({
  props,
  products,
  setDetectedProducts,
  setState,
  setIsCreatingNew,
  countStoresForProduct,
  setIgnoredProducts,
  ignoredProducts,
}: {
  props: ICellEditorProps & {
    isEditing?: boolean | undefined;
    data?: any[] | undefined;
  };
  products: Product[];
  setDetectedProducts: React.Dispatch<React.SetStateAction<any[]>>;
  setIgnoredProducts: React.Dispatch<React.SetStateAction<string[]>>;
  ignoredProducts: string[];
  setIsCreatingNew: React.Dispatch<React.SetStateAction<any | undefined>>;
  setState: any;
  countStoresForProduct: (productId: number) => number;
}) {
  const [searching, setSearching] = useState(false);
  const [ignored, setIgnored] = useState(
    ignoredProducts.includes(props.rowData.fileProduct)
  );
  return (
    <div>
      {!!searching && (
        <AutocompleteInput
          options={[...products, { isAddNew: true }] as Product[]}
          getSuggestionValue={(p: Product) => p.name}
          onNewSuggestion={() => setIsCreatingNew(props.rowData)}
          inputProps={{
            placeholder: "Search for product",
          }}
          handleSearchResultClick={(
            selection: SuggestionSelectedEventData<Product>
          ) => {
            setIgnoredProducts((old) =>
              old.filter((o) => o !== props.rowData.fileProduct)
            );
            setIgnored(false);
            setDetectedProducts((old) => {
              const productId = selection.suggestion.id;
              const product = products.find((p) => p.id === +productId);
              const newProducts = [...old];
              const index = newProducts.findIndex(
                (p) => p.fileProduct === props.rowData.fileProduct
              );
              if (index !== -1)
                newProducts.splice(index, 1, {
                  ...newProducts[index],
                  productId: product?.id,
                  productName: product?.name,
                  productDescription: product?.description,
                  productCategory: product?.category,
                  productImage: product?.url,
                  productStoreCount: product?.id
                    ? countStoresForProduct(product.id)
                    : 0,
                });
              setState((old: any) => ({
                ...old,
                detectedProducts: newProducts,
              }));
              return newProducts;
            });
            setSearching(false);
          }}
        />
      )}
      <HStack justifyContent={"space-between"}>
        {!!props.rowData.productName && !searching && (
          <Tag>
            {props.rowData.productName}
            <TagCloseButton
              onClick={() => {
                setDetectedProducts((old) => {
                  const newProducts = [...old];
                  const index = newProducts.findIndex(
                    (p) => p.fileProduct === props.rowData.fileProduct
                  );
                  if (index !== -1)
                    newProducts.splice(index, 1, {
                      ...newProducts[index],
                      productId: undefined,
                      productName: undefined,
                      productDescription: undefined,
                      productCategory: undefined,
                      productImage: undefined,
                      productStoreCount: 0,
                    });
                  setState((old: any) => ({
                    ...old,
                    detectedProducts: newProducts,
                  }));
                  return newProducts;
                });
              }}
            />
          </Tag>
        )}
        {!props.rowData.productName && !searching && (
          <VStack alignItems={"flex-start"}>
            <Text fontSize="sm" color="gray">
              {ignored ? (
                "Will be ignored"
              ) : (
                <>
                  <WarningIcon color={"orange"} /> Could not match with existing
                  product.
                </>
              )}
            </Text>
            <HStack>
              {!ignored && (
                <Button
                  variant="outline"
                  onClick={() => {
                    setIgnoredProducts((old) => {
                      return Array.from(
                        new Set([...old, props.rowData.fileProduct])
                      );
                    });
                    setIgnored(true);
                  }}
                >
                  Ignore product
                </Button>
              )}
              <Button
                variant="outline"
                onClick={() => setIsCreatingNew(props.rowData)}
              >
                Create product
              </Button>
              <Button
                aria-label="Search product icon"
                leftIcon={<SearchIcon />}
                onClick={() => setSearching((old) => !old)}
                width="6rem"
                minW={"unset"}
              >
                Search
              </Button>
            </HStack>
          </VStack>
        )}
        {!!props.rowData.productName && !searching && (
          <Button
            aria-label="Search product icon"
            leftIcon={<SearchIcon />}
            onClick={() => setSearching((old) => !old)}
            width="6rem"
            minW={"unset"}
          >
            Search
          </Button>
        )}
      </HStack>
    </div>
  );
}

const ProductImageField = ({ rowData, column }: ICellTextProps) => {
  return rowData.productId ? (
    <VStack>
      {rowData[column.key] ? (
        <Image src={rowData[column.key]} w={50} h={50} objectFit="contain" />
      ) : (
        <Box
          textAlign="center"
          p={3}
          rounded="lg"
          bg="gray.100"
          border="1px solid"
          lineHeight="1em"
          color="tomato"
          borderColor="tomato"
        >
          No Image
        </Box>
      )}
    </VStack>
  ) : (
    <></>
  );
};

function getDefault(input: string, state: any, products: Product[]) {
  const data = (state.data || []) as any[];
  const selectedFields = (state.selectedFields || {}) as any;

  const defaultSelectedProductColumn =
    data.flatMap((d) =>
      Object.keys(d).find((key) => key.toLowerCase().includes("product"))
    )?.[0] || "";
  switch (input) {
    case "relationMethod":
      return data.some((d) =>
        Object.keys(d).some((key) =>
          products.map((p) => `${p.id} - ${p.name}`).includes(key)
        )
      )
        ? "columns"
        : data.some((d) =>
            Object.keys(d).some((key) => key.toLowerCase().includes("product"))
          )
        ? "single_column"
        : "";

    case "selectedProductColumn":
      return defaultSelectedProductColumn;
    case "storesRepeat":
      return data.some((d1, i1) => {
        return data.some(
          (d2, i2) =>
            i1 !== i2 &&
            JSON.stringify(_.pick(d1, Object.keys(selectedFields))) ===
              JSON.stringify(_.pick(d2, Object.keys(selectedFields)))
        );
      })
        ? "stores_repeat"
        : "";
    case "separator":
      return data.some((d) => d[defaultSelectedProductColumn]?.includes(","))
        ? ","
        : data.some((d) => d[defaultSelectedProductColumn]?.includes("|"))
        ? "|"
        : data.some((d) => d[defaultSelectedProductColumn]?.includes("-"))
        ? "-"
        : "";
    default:
      break;
  }
  return "";
}

export default function ProductRelationProxy({
  state,
  setState,
  onFinish,
  noModals,
  actionsFromOutside,
}: Props) {
  const orgProperties = useAppSelector(selectOrgProperties);
  const accessToken = useAppSelector(selectAccessToken);
  const products = useAppSelector(selectProducts);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [currentStores, setCurrentStores] = useState<Store[]>();
  const [isLoadingStores, setIsLoadingStores] = useState(false);
  const [isCreatingNew, setIsCreatingNew] = useState<any>();
  const [isUpdatingProduct, setIsUpdatingProduct] = useState<any>();
  const borderRadius = useMemo(
    () => theme.components.Input.baseStyle.field.borderRadius,
    [theme]
  );
  const relationMethodDefault = useMemo(
    () => getDefault("relationMethod", state, products) || "",
    [state, products]
  );
  const selectedProductColumnDefault = useMemo(
    () => getDefault("selectedProductColumn", state, products) || "",
    [state, products]
  );
  const separatorDefault = useMemo(
    () => getDefault("separator", state, products) || "",
    [state, products]
  );
  const [relationMethod, setRelationMethod] = useState<string>(
    relationMethodDefault
  );
  const [selectedProductColumn, setSelectedProductColumn] = useState<string>(
    selectedProductColumnDefault
  );
  const [storesRepeat, setStoresRepeat] = useState<string>("stores_repeat");
  const [separator, setSeparator] = useState<string>(separatorDefault);
  const [detectedProducts, setDetectedProducts] = useState<any[]>([]);
  const [ignoredProducts, setIgnoredProducts] = useState<string[]>([]);

  const countStoresForProduct = useCallback(
    (productId: number) => {
      return (currentStores || [])?.filter((s) =>
        s.products?.map((p) => p.product_id).includes(productId)
      ).length;
    },
    [currentStores]
  );

  useEffect(() => {
    const findProductForName = (name: string) => {
      return products.find(
        (p) =>
          p.name.toLowerCase().includes(name.toLowerCase()) ||
          name.toLowerCase().includes(p.name.toLowerCase())
      );
    };

    let newProducts: string[] = [];
    if (relationMethod === "single_column") {
      newProducts = Array.from(
        new Set(
          state.data
            ?.map((row: any) => {
              if (storesRepeat) {
                return row[selectedProductColumn]
                  ? [`${row[selectedProductColumn]}`]
                  : [];
              } else {
                return separator
                  ? `${row[selectedProductColumn] || ""}`
                      .split(separator)
                      .map((p) => p.trim())
                  : `${row[selectedProductColumn] || ""}`.trim();
              }
            })
            .flat()
        )
      ).filter((p) => !!p) as string[];
    } else {
      newProducts = Array.from(
        new Set(
          Object.keys(state.data?.[0]).filter((key) =>
            products.map((p) => `${p.id} - ${p.name}`).includes(key)
          )
        )
      );
    }
    const newDetectedProducts = newProducts.map((p: string) => {
      const product = findProductForName(p);
      return {
        fileProduct: p,
        productName: product?.name,
        productId: product?.id,
        productDescription: product?.description,
        productCategory: product?.category,
        productImage: product?.url,
        productStoreCount: product?.id ? countStoresForProduct(product.id) : 0,
      };
    });
    setState(
      (old) =>
        ({
          ...(old || {}),
          detectedProducts: newDetectedProducts,
          relationMethod,
          selectedProductColumn,
          storesRepeat,
          separator,
        } as State)
    );
    setDetectedProducts(newDetectedProducts);
    setIgnoredProducts((old) =>
      old.filter(
        (o) =>
          !newDetectedProducts
            .filter((n) => !!n.productId)
            .map((n) => n.fileProduct)
            .includes(o)
      )
    );
  }, [
    countStoresForProduct,
    products,
    relationMethod,
    selectedProductColumn,
    separator,
    setState,
    state.data,
    storesRepeat,
  ]);

  useEffect(() => {
    if (!currentStores && !isLoadingStores) {
      setIsLoadingStores(true);
      getStores(
        [],
        [],
        [],
        [],
        [],
        undefined,
        true,
        accessToken,
        orgProperties?.properties?.country,
        [],
        [],
        [],
        [],
        undefined,
        [],
        [
          "id",
          "store_id",
          "parent_id",
          "name",
          "address",
          "chain",
          "state",
          "county",
          "city",
          "zipcode",
          "latitude",
          "longitude",
          "status",
          "created_at",
          "category",
          "products",
          "email",
          "type",
          "phone",
          "description",
          "image_url",
          "featured_content",
          "secondary_images",
          "start_date",
          "end_date",
          "recurrence_rule",
          "origin",
        ]
      )
        .then(setCurrentStores)
        .catch(() => setCurrentStores([]))
        .finally(() => setIsLoadingStores(false));
    }
  }, [accessToken, currentStores, isLoadingStores, orgProperties]);

  const convertProductRelations = ({
    data,
    detectedProducts,
    relationMethod,
    selectedProductColumn,
    separator,
    storesRepeat,
    selectedFields,
  }: any) => {
    return data.reduce((dacc: any[], d: any) => {
      const repeatedIndex = dacc.findIndex(
        (d2) =>
          JSON.stringify(
            _.pick(d, Object.keys(selectedFields))
          ).toLowerCase() ===
          JSON.stringify(_.pick(d2, Object.keys(selectedFields))).toLowerCase()
      );
      let store = repeatedIndex !== -1 ? { ...dacc[repeatedIndex] } : { ...d };
      if (relationMethod === "columns") {
        store = _.merge(
          { ...store },
          detectedProducts.reduce((acc: any, p: any) => {
            const product = `${p.productId} - ${p.productName}`;
            const units = d?.[product];
            return { ...acc, [product]: units };
          }, {})
        );
      } else {
        store = _.merge(
          { ...store },
          detectedProducts.reduce((acc: any, p: any) => {
            const product = `${p.productId} - ${p.productName}`;
            const productField = d?.[selectedProductColumn] as string;
            const dHasProduct =
              productField &&
              (!storesRepeat && separator
                ? productField
                    .split(separator)
                    .find((sep: string) => sep.trim() === p.fileProduct)
                : productField.trim() === p.fileProduct.trim());
            return { ...acc, [product]: dHasProduct ? 1 : undefined };
          }, {})
        );
      }
      return repeatedIndex !== -1
        ? dacc.map((d2, i) => (i === repeatedIndex ? store : d2))
        : [...dacc, store];
    }, []);
  };

  const doFinish = useCallback(async () => {
    if (state.relationMethod && onFinish) {
      onFinish(convertProductRelations(state));
      setState(undefined);
    }
  }, [onFinish, setState, state]);

  useEffect(() => {
    if (actionsFromOutside) {
      actionsFromOutside({
        doFinish,
        allowContinue:
          (!relationMethod || (!isCreatingNew && !isUpdatingProduct)) &&
          detectedProducts
            .filter((d) => !d.productId)
            .every((d) => ignoredProducts.includes(d.fileProduct)),
      });
    }
  }, [
    actionsFromOutside,
    doFinish,
    isCreatingNew,
    relationMethod,
    isUpdatingProduct,
    detectedProducts,
    ignoredProducts,
  ]);

  const getBody = useCallback(
    () => (
      <VStack w={"100%"} alignItems="flex-start">
        {!relationMethodDefault && (
          <Alert variant="subtle" as={HStack} spacing={3}>
            <Text>Do you know what products are sold at each store?</Text>
            <Spacer />
            <Button
              colorScheme={
                relationMethod === "not_in_file" ? "blue" : undefined
              }
              onClick={() => {
                setRelationMethod("not_in_file");
              }}
              variant={relationMethod === "not_in_file" ? "solid" : "outline"}
            >
              Yes, but not in file
            </Button>
            <Button
              colorScheme={
                relationMethod === "single_column" ? "blue" : undefined
              }
              onClick={() => {
                setRelationMethod("single_column");
              }}
              variant={relationMethod === "single_column" ? "solid" : "outline"}
            >
              Yes, they're on a column
            </Button>
            <Button
              colorScheme={!relationMethod ? "blue" : undefined}
              onClick={() => {
                setRelationMethod("");
              }}
              variant={!relationMethod ? "solid" : "outline"}
            >
              No
            </Button>
          </Alert>
        )}
        <HStack>
          {relationMethod === "single_column" &&
            (!selectedProductColumn || !selectedProductColumnDefault) && (
              <FormControl>
                <Select
                  value={selectedProductColumn}
                  onChange={(e) => {
                    const value = e.target.value;
                    setState((old: any) => ({
                      ...old,
                      selectedProductColumn: value,
                    }));
                    setSelectedProductColumn(value);
                  }}
                >
                  <option value="" disabled>
                    -- Choose column --
                  </option>
                  {Object.keys(state.data?.[0]?.original || {})
                    .filter(
                      (f) => !Object.keys(state.selectedFields).includes(f)
                    )
                    .map((f) => (
                      <option value={f}>{f}</option>
                    ))}
                </Select>
              </FormControl>
            )}
          {relationMethod === "single_column" && (
            <FormControl>
              <Select
                value={storesRepeat}
                onChange={(e) => {
                  const value = e.target.value;
                  setStoresRepeat(value);
                }}
              >
                <option value="">Multiple products per cell</option>
                <option value="stores_repeat">One product per cell</option>
              </Select>
            </FormControl>
          )}
          {relationMethod === "single_column" && !storesRepeat && (
            <FormControl>
              <Input
                placeholder="Type your product separator (e.g. ,)"
                value={separator}
                onChange={(e) => {
                  const value = e.target.value;
                  setState((old: any) => ({ ...old, separator: value }));
                  setSeparator(value);
                }}
              />
            </FormControl>
          )}
        </HStack>
        {!!relationMethod &&
          relationMethod !== "not_in_file" &&
          !isCreatingNew &&
          !isUpdatingProduct && (
            <>
              <EntityTable
                initialTableProps={{
                  columns: [
                    { key: "fileProduct", title: "Product from file" },
                    {
                      key: "matchedProduct",
                      title: "Product in your locator",
                      Render: (props) => (
                        <ProductMatchSelector
                          key={props.rowData.fileProduct}
                          props={props}
                          products={products}
                          setDetectedProducts={setDetectedProducts}
                          setIgnoredProducts={setIgnoredProducts}
                          ignoredProducts={ignoredProducts}
                          setState={setState}
                          setIsCreatingNew={setIsCreatingNew}
                          countStoresForProduct={countStoresForProduct}
                        />
                      ),
                    },
                    {
                      title: "Description",
                      key: "productDescription",
                    },
                    {
                      title: "Category",
                      key: "productCategory",
                      style: { width: 100 },
                    },
                    {
                      title: "Image",
                      info: `Product images are needed to view the product in Locator`,
                      key: "productImage",
                      Render: (props) =>
                        props.value || !props.rowData.productId ? (
                          <ProductImageField {...props} />
                        ) : (
                          <Button
                            onClick={() =>
                              setIsUpdatingProduct({
                                ...props.rowData,
                                product: products.find(
                                  (p) => p.id === props.rowData.productId
                                ),
                              })
                            }
                            variant="link"
                            colorScheme={"orange"}
                            rightIcon={<FaPlus />}
                          >
                            Add image
                          </Button>
                        ),
                      style: { width: 100 },
                    },
                  ],
                }}
                dataFromOutside={detectedProducts}
              />
            </>
          )}
        {relationMethod !== "not_in_file" &&
          (!relationMethod || !detectedProducts.length) && (
            <>
              <Text fontSize="sm" fontWeight="normal">
                If you don't have products in your file, no problem! Your stores
                are uploaded and will display the message "Contact store for
                product availability".
              </Text>
              <Text fontSize="sm" fontWeight="normal">
                We will include these stores in the next automatic update
                analysis and do our best to identify the products sold there.
              </Text>
            </>
          )}
        {relationMethod === "not_in_file" && (
          <>
            <Text fontSize="sm" fontWeight="normal">
              If you don't have products in your file, no problem! Your stores
              are uploaded and will display the message "Contact store for
              product availability".
            </Text>
            <Text fontSize="sm" fontWeight="normal">
              You will have the option to manage your newly added stores to
              include the products you have in mind. Just click "Next" to
              review, finish and manage your stores.
            </Text>
          </>
        )}
        {!!relationMethod && !!isCreatingNew && (
          <>
            <ProductForm
              skipSuccessMessage
              product={new Product({ name: isCreatingNew.fileProduct })}
              onFinish={(result) => {
                if (result) {
                  toast.success(`Added "${result.name}" to your locator`);
                  const _newProducts = [...products];
                  _newProducts.push(result);
                  dispatch(setProducts(_newProducts));
                  dispatch(refreshIframe());
                  setIgnoredProducts((old) =>
                    old.filter((o) => o !== isCreatingNew.fileProduct)
                  );
                  setDetectedProducts((old) => {
                    const productId = result.id;
                    const product = _newProducts.find(
                      (p) => p.id === +productId
                    );
                    const newProducts = [...old];
                    const index = newProducts.findIndex(
                      (p) => p.fileProduct === isCreatingNew.fileProduct
                    );
                    if (index !== -1)
                      newProducts.splice(index, 1, {
                        ...newProducts[index],
                        productId: product?.id,
                        productName: product?.name,
                        productDescription: product?.description,
                        productCategory: product?.category,
                        productImage: product?.url,
                        productStoreCount: product?.id
                          ? countStoresForProduct(product.id)
                          : 0,
                      });
                    setState((old: any) => ({
                      ...old,
                      detectedProducts: newProducts,
                      newProducts: Array.from(
                        new Set([...(old.newProducts || []), result.id])
                      ),
                    }));
                    return newProducts;
                  });
                }
                setIsCreatingNew(undefined);
              }}
            />
          </>
        )}
        {!!relationMethod && !!isUpdatingProduct && (
          <>
            <ProductForm
              skipSuccessMessage
              product={new Product(isUpdatingProduct.product)}
              onFinish={(result) => {
                if (result) {
                  toast.success(`Updated "${result.name}"`);
                  let _newProducts = [...products];
                  _newProducts.splice(
                    _newProducts.findIndex(
                      (p) => p.id === isUpdatingProduct.product.id
                    ),
                    1,
                    result
                  );
                  dispatch(setProducts(_newProducts));
                  dispatch(refreshIframe());
                  setDetectedProducts((old) => {
                    const newProducts = [...old];
                    const index = newProducts.findIndex(
                      (p) => p.fileProduct === isUpdatingProduct.fileProduct
                    );
                    if (index !== -1)
                      newProducts.splice(index, 1, {
                        ...newProducts[index],
                        productId: result?.id,
                        productName: result?.name,
                        productDescription: result?.description,
                        productCategory: result?.category,
                        productImage: result?.url,
                        productStoreCount: result?.id
                          ? countStoresForProduct(result.id)
                          : 0,
                      });
                    setState((old: any) => ({
                      ...old,
                      detectedProducts: newProducts,
                    }));
                    return newProducts;
                  });
                }
                setIsUpdatingProduct(undefined);
              }}
            />
          </>
        )}
      </VStack>
    ),
    [
      borderRadius,
      countStoresForProduct,
      detectedProducts,
      dispatch,
      isCreatingNew,
      isUpdatingProduct,
      products,
      relationMethod,
      selectedProductColumn,
      separator,
      setState,
      state,
      storesRepeat,
      theme.colors.blue,
      ignoredProducts,
    ]
  );

  return !noModals ? (
    <ResponsiveModal isOpen isCentered scrollBehavior="inside" size="full">
      <ModalOverlay />
      <ModalContent h="100%">
        <ModalCloseButton
          onClick={() => {
            setState(undefined);
          }}
        />

        <ModalHeader textAlign="center">Products in file</ModalHeader>

        <ModalBody>{getBody()}</ModalBody>
        {(!relationMethod || !isCreatingNew) && (
          <ModalFooter as={HStack} justifyContent={"flex-end"}>
            <Button>Cancel</Button>
            <Button colorScheme="blue" onClick={doFinish}>
              Continue
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </ResponsiveModal>
  ) : (
    getBody()
  );
}
