import { configureStore } from "@reduxjs/toolkit";
// @ts-ignore
import { taskMiddleware } from "react-palm/tasks";
import appReducer from "./appSlice";
import keplerReducer from "../features/map/keplerReducer";
import mapViewsReducer from "../features/map-views/mapViewsSlice";
import layersReducer from "../features/layers/layersSlice";
import integrationSlice from "../features/data-integrations/integrationSlice";
import storeLocatorSlice from "../features/store-locator/storeLocatorSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const store = configureStore({
  reducer: {
    keplerGl: keplerReducer,
    app: appReducer,
    integrations: integrationSlice,
    mapViews: mapViewsReducer,
    layers: layersReducer,
    storeLocator: storeLocatorSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(taskMiddleware),
  devTools: {
    stateSanitizer: (state) => {
      const newState = { ...state };
      return newState;
    },
    // @ts-ignore
    actionSanitizer: (action) => {
      return action.type.toLowerCase().includes("kepler")
        ? { type: action.type }
        : action;
    },
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
