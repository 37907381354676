import PageLayout from "../../components/PageLayout";
import QRCampaigns from "./QRCampaigns";

function ShareLocatorScreen() {
  return (
    <PageLayout title="Share locator">
      <QRCampaigns />
    </PageLayout>
  );
}
export default ShareLocatorScreen;
