import { ArrowDownIcon } from "@chakra-ui/icons";
import { Heading } from "@chakra-ui/layout";
import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Img,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { DataType } from "ka-table";
import pluralize from "pluralize";
import React, { useState } from "react";
import { BsFileEarmarkSpreadsheetFill } from "react-icons/all";
import { FaShopify, FaVideo } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  selectAccessToken,
  selectIsShopifyConnected,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import menuIconProducts from "../../assets/images/menu-icon-products.png";
import { EntityTable } from "../../components/entity-table/EntityTable";
import { syncProducts } from "../../services/shopify.service";
import { trackClick } from "../../services/tracking.service";
import { primary, primaryLight } from "../../theme/colors";
import {
  selectAvailableIntegrations,
  selectIntegration,
  toggleIntegrationsModal,
} from "../data-integrations/integrationSlice";
import { ImportProductsSteps } from "./ImportProductsSteps";
import ProductForm from "./ProductForm";

export default function UploadProductsTab() {
  const accessToken = useAppSelector(selectAccessToken);
  const shopifyConnected = useAppSelector(selectIsShopifyConnected);
  const availableIntegrations = useAppSelector(selectAvailableIntegrations);
  const [tabActive, setTabActive] = useState(0);
  const [syncingFromShopify, setSyncingFromShopify] = useState(false);
  const dispatch = useAppDispatch();

  const handleShopifySync: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (accessToken) {
      setSyncingFromShopify(true);
      syncProducts(accessToken)
        .then((response) => {
          const messages = [];
          if (response.new_products?.length) {
            messages.push(
              `${pluralize(
                "Product",
                response.new_products.length,
                true
              )} were created`
            );
          }
          if (response.updated_products?.length) {
            messages.push(
              `${pluralize(
                "Product",
                response.updated_products.length,
                true
              )} were updated`
            );
          }
          if (messages.length) {
            toast.success(messages.join(" and "));
          } else {
            toast.info("Nothing to sync");
          }
        })
        .finally(() => setSyncingFromShopify(false));
    }
  };

  return (
    <VStack h="100%" textAlign="start" spacing={5}>
      {!tabActive && (
        <>
          <HStack w="100%" justifyContent="space-between">
            <Text w="100%" textAlign="start">
              All Products uploaded or created will be added to your
              organization
            </Text>
            <Button variant="outline" leftIcon={<FaVideo />} isDisabled>
              Questions? Watch video
            </Button>
          </HStack>
          <Divider />
          <SimpleGrid w="100%" columns={3} textAlign="center" spacing={5}>
            <VStack
              rounded="lg"
              p={10}
              border={`1px solid ${primaryLight}`}
              flex={1}
              bg="white"
              justifyContent="space-around"
            >
              <Box pos="relative">
                <Icon as={FaShopify} boxSize="4em" />
                <ArrowDownIcon
                  pos="absolute"
                  bottom={0}
                  right={0}
                  bg="white"
                  rounded="lg"
                  boxSize="1em"
                  color={primary}
                />
              </Box>
              {shopifyConnected && (
                <Button
                  colorScheme="blue"
                  onClick={handleShopifySync}
                  isLoading={syncingFromShopify}
                >
                  Import products from shopify
                </Button>
              )}
              {!shopifyConnected && (
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    const shopifyIntegration = availableIntegrations.find(
                      (i) => i.id === "shopify"
                    );
                    dispatch(selectIntegration(shopifyIntegration));
                    dispatch(toggleIntegrationsModal(true));
                  }}
                  isLoading={syncingFromShopify}
                >
                  Integrate with Shopify
                </Button>
              )}
              <Text fontSize={12}>
                This is the best option if you set your locator with Shopify and
                your product info is updated on Shopify.
              </Text>
              <Button variant="link" isDisabled>
                Learn about import from Shopify
              </Button>
            </VStack>
            <VStack
              rounded="lg"
              p={10}
              border={`1px solid ${primaryLight}`}
              flex={1}
              bg="white"
              justifyContent="space-around"
            >
              <Box pos="relative">
                <Button
                  variant="link"
                  as="div"
                  padding="12px 10px"
                  isActive
                  _hover={{ textDecoration: "none" }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  leftIcon={
                    <Img
                      src={menuIconProducts}
                      height="1rem"
                      width="1rem"
                      filter="invert(48%) sepia(45%) saturate(4075%) hue-rotate(177deg) brightness(97%) contrast(102%)"
                    />
                  }
                  iconSpacing={0}
                >
                  Products
                </Button>
                <ArrowDownIcon
                  pos="absolute"
                  bottom={0}
                  right={0}
                  bg="white"
                  rounded="lg"
                  boxSize="1em"
                  color={primary}
                />
              </Box>
              <Button
                colorScheme="blue"
                onClick={() =>
                  trackClick("product-add-manually", "product", () =>
                    setTabActive(3)
                  )
                }
              >
                Create a product by filling out a form
              </Button>
              <Text fontSize={12}>
                This is the best option to create one or a few products.
              </Text>
              <Button variant="link" isDisabled>
                Learn about using the product form
              </Button>
            </VStack>
            <VStack
              rounded="lg"
              p={10}
              border={`1px solid ${primaryLight}`}
              flex={1}
              bg="white"
              justifyContent="space-around"
            >
              <Box pos="relative">
                <Icon as={BsFileEarmarkSpreadsheetFill} boxSize="4em" />
                <ArrowDownIcon
                  pos="absolute"
                  bottom={0}
                  right={0}
                  bg="white"
                  rounded="lg"
                  boxSize="1em"
                  color={primary}
                />
              </Box>
              <Button
                colorScheme="blue"
                onClick={() =>
                  trackClick("product-from-spreadsheet", "product", () =>
                    setTabActive(2)
                  )
                }
                display="flex"
                flexDir="column"
              >
                Import Products with a spreadsheet
              </Button>
              <Text fontSize={12}>
                This is the best option to add 10 or more products. You’ll need
                to upload the images of your products manually
              </Text>
              <Button variant="link" isDisabled>
                Learn about import from Spreadsheet
              </Button>
            </VStack>
          </SimpleGrid>
          <Divider />
          <Heading as="h3" size="md" alignSelf="flex-start">
            Import history
          </Heading>
          <EntityTable
            initialTableProps={{
              columns: [
                { key: "date", dataType: DataType.Date, title: "Date" },
                { key: "filename", title: "Filename" },
                { key: "rows", title: "Rows", dataType: DataType.Number },
                {
                  key: "download",
                  title: "Download",
                  Render: () => <Button>Download</Button>,
                },
              ],
            }}
          />
        </>
      )}
      {tabActive === 2 && (
        <ImportProductsSteps onFinish={() => setTabActive(0)} />
      )}
      {tabActive === 3 && <ProductForm onFinish={() => setTabActive(0)} />}
    </VStack>
  );
}
