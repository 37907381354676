import React, { useMemo } from "react";
import {
  Text,
  Box,
  Flex,
  Button,
  useMediaQuery,
  SkeletonText,
  SkeletonCircle,
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import { connect } from "react-redux";
var pluralize = require("pluralize");

function PopularStores({
  zipStores,
  isLoading,
  setSelectedViewStore,
  orgConfig,
}) {
  const [isMobile] = useMediaQuery("(max-width: 765px)");
  const storeLabel = orgConfig?.storeNameReplacement || "Store";

  const sortedZipStores = useMemo(() => {
    const chains = zipStores.reduce(
      (acc, store) => ({ ...acc, [store.chain]: (acc[store.chain] ?? 0) + 1 }),
      {}
    );
    return zipStores
      .sort((a, b) => b.getReviewCount() - a.getReviewCount())
      .sort((a, b) => {
        return !a.getReviewCount() && !b.getReviewCount()
          ? chains[b.chain] - chains[a.chain]
          : 0;
      })
      .slice(0, 3);
  }, [zipStores]);

  const getStar = (rating) => {
    let star = [];
    const roundedRating = Math.floor(rating);
    for (let i = 0; i < 5; i++) {
      star.push(
        <StarIcon
          key={i}
          color={i < roundedRating ? "yellow.300" : "gray.500"}
        />
      );
    }
    return star;
  };

  return (
    <Box mt="2" bg="gray.100" w="85%">
      <Text textAlign="center" fontWeight="medium" fontSize="xl">
        Popular {pluralize(storeLabel)}
      </Text>
      {isLoading ? (
        [...Array(3)].map((item, index) => (
          <Box
            bg="white"
            border="1px"
            borderColor="blackAlpha.400"
            mx="5"
            my="2"
            shadow="md"
          >
            <Flex
              flexWrap="wrap"
              direction={isMobile ? "column" : "row"}
              m="2"
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex w={"100%"}>
                <SkeletonText noOfLines={1} w="30%" m={2} />
              </Flex>
              <Text>Rating:</Text>
              <Box>
                <StarIcon color={"gray.500"} />
                <StarIcon color={"gray.500"} />
                <StarIcon color={"gray.500"} />
                <StarIcon color={"gray.500"} />
                <StarIcon color={"gray.500"} />
              </Box>
              <SkeletonText noOfLines={1} w="10%" />
              <Text>Reviews:</Text>
              <SkeletonText noOfLines={1} w="10%" />
              <SkeletonCircle w={85} />
            </Flex>
          </Box>
        ))
      ) : sortedZipStores.length ? (
        sortedZipStores.map((store, i) => (
          <Box
            key={i}
            bg="white"
            border="1px"
            borderColor="blackAlpha.400"
            mx="5"
            my="2"
            shadow="md"
          >
            <Flex
              flexWrap="wrap"
              direction={isMobile ? "column" : "row"}
              m="2"
              key={store.id}
              justifyContent="space-between"
              alignItems="center"
            >
              <Text w="100%" fontWeight="medium" textAlign="left">
                {i + 1}. {store.name.replace(/'/g, "").trim()}
              </Text>
              <Flex textAlign="center">
                <Text>Reviews:</Text>
                <Flex alignItems={"center"} fontSize={"large"}>
                  {getStar(store.getAvgRating())}
                </Flex>
              </Flex>
              <Flex textAlign="center">
                <Text>Reviews:</Text>
                <Text>{store.getReviewCount()}</Text>
              </Flex>
              <Button
                variant="outline"
                colorScheme="blue"
                onClick={() => setSelectedViewStore(store)}
              >
                View {storeLabel}
              </Button>
            </Flex>
          </Box>
        ))
      ) : (
        <Text textAlign="center" fontSize="xl">
          No {pluralize(storeLabel)} found
        </Text>
      )}
    </Box>
  );
}

export default connect((state) => ({
  orgConfig: state.app.orgProperties?.properties,
}))(PopularStores);
