import {
  Box,
  Button,
  Center,
  Heading,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { embedDashboard } from "zorp-bi-embedded-sdk";
import {
  selectAccessToken,
  selectOrgProperties,
  selectStoreLocatorLookerParams,
} from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import { selectCurrentView } from "../../features/map-views/mapViewsSlice";
import { selectSelectedAudiences } from "../../features/map/keplerReducer";
import { fetchSupersetGuestToken } from "../../services/api.service";
import "./styles.css";

type Props = {
  url?: string;
};

const EmbeddedReport: FunctionComponent<Props> = ({ url }) => {
  const location = useLocation();
  const { menuId } = useParams();
  const container = useRef<HTMLDivElement>(null);
  const [allowedDashboards, setAllowedDashboards] = useState([]);
  const [loadingGuestToken, setLoadingGuestToken] = useState(false);
  const selectedAudiences = useAppSelector(selectSelectedAudiences);
  const currentView = useAppSelector(selectCurrentView);
  const accessToken = useAppSelector(selectAccessToken);
  const orgProperties = useAppSelector(selectOrgProperties);
  const storeLocatorLookerParams = useAppSelector(
    selectStoreLocatorLookerParams
  );
  const organizationId = orgProperties?.organization_id;

  const urlToRender = useMemo(
    () => url || location.state?.url || menuId,
    [location.state, menuId, url]
  );
  const dashId = useMemo(() => urlToRender?.match(/dashboard\/(\d+)\//)[1], []);
  const isSupersetDashboard = useMemo(
    () => urlToRender?.includes("superset/dashboard"),
    [urlToRender]
  );
  const urlParams = useMemo(() => {
    const searchParams = new URLSearchParams();
    selectedAudiences?.forEach((audience) => {
      searchParams.append("audience", audience);
    });
    searchParams.append("view_id", `${currentView?.id}`);
    searchParams.append("org_id", `${organizationId}`);

    // Looker Studio parameters
    if (urlToRender?.includes("google")) {
      var encodedParams = encodeURIComponent(
        JSON.stringify(storeLocatorLookerParams)
      );
      searchParams.append("params", encodedParams);
    }
    return searchParams;
  }, [
    currentView,
    organizationId,
    selectedAudiences,
    storeLocatorLookerParams,
    urlToRender,
  ]);
  const addParamsToUrl = (urlParams: URLSearchParams) => {
    if (urlToRender) {
      const newUrl = new URL(urlToRender);
      newUrl.search = urlParams.toString();
      return newUrl.toString();
    }
    return urlToRender;
  };
  useEffect(() => {
    const fetchGuestToken = async () => {
      const res = await fetchSupersetGuestToken(accessToken);
      return res.guest_token;
    };
    if (isSupersetDashboard && urlToRender && accessToken) {
      setLoadingGuestToken(true);
      fetchSupersetGuestToken(accessToken)
        .then((guestResponse) => {
          setLoadingGuestToken(false);
          if (guestResponse && container.current) {
            setAllowedDashboards(guestResponse.allowed_dashboards);
            const dashUUID = guestResponse.allowed_dashboards.find(
              (dash: any) => dash.id === dashId
            )?.uuid;
            if (dashUUID) {
              embedDashboard({
                id: dashUUID, // given by the Superset embedding UI
                supersetDomain: "https://reports.dathic.com:8088",
                mountPoint: container.current, // any html element that can contain an iframe
                fetchGuestToken: () => fetchGuestToken(),
                dashboardUiConfig: {
                  hideTitle: true,
                  filters: {
                    expanded: true,
                  },
                  urlParams,
                },
              });
            }
          }
        })
        .finally(() => setLoadingGuestToken(false));
    }
  }, [accessToken, isSupersetDashboard, urlToRender]);
  return (
    <Box
      key={urlToRender}
      w="100%"
      h="100%"
      ref={container}
      id="embedded-report-container"
    >
      {!!isSupersetDashboard && loadingGuestToken && (
        <Center h="100%">
          <Spinner />
        </Center>
      )}
      {!!isSupersetDashboard &&
        !allowedDashboards.find((d: any) => d.id === dashId) &&
        !loadingGuestToken && (
          <Center h="100%">
            <VStack>
              <Heading>This dashboard is currently unavailable</Heading>
              <Text>
                Please contact{" "}
                <Button
                  variant="link"
                  onClick={() => window.open("mailto:info@dathic.com")}
                >
                  Dathic Support
                </Button>
              </Text>
            </VStack>
          </Center>
        )}
      {!!urlToRender && !isSupersetDashboard && (
        <iframe
          src={addParamsToUrl(urlToRender)}
          style={{
            border: 0,
            position: "relative",
            width: "100%",
            height: "100%",
          }}
          allowFullScreen
          title="store-locator-report"
        ></iframe>
      )}
    </Box>
  );
};

export default EmbeddedReport;
