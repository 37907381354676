import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import App from "./app/App";
import store from "./app/store";
import { initFacebookSdk } from "./features/data-integrations/facebook/facebook-api";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

if (process.env.NODE_ENV === "production") {
  TagManager.initialize({
    gtmId: "GTM-PFDWW7G",
  });
}

initFacebookSdk()
  .then((fbRespose) => startApp(fbRespose))
  .catch(() => startApp());

function startApp(fbRespose) {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App fbRespose={fbRespose} />
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
