import {
  Button,
  Divider,
  HStack,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import pluralize from "pluralize";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { FaVideo } from "react-icons/fa";
import { useLocation, useParams } from "react-router-dom";
import { selectOrgProperties, selectUserResources } from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import { RESOURCES } from "../../constants/user-constants";
import AdditionalSourcesForm from "../store-locator/AdditionalSourcesForm";
import BuyOnlineOptions from "../store-locator/BuyOnlineOptions";
import DisableStoresTab from "./DisableStoresTab";
import MyStoresTable from "./MyStoresTable";
import { StoresOverview } from "./StoresOverview";
import UploadStoresTab from "./UploadStoresTab";

type Props = {};

const StoresTabs: FunctionComponent<Props> = () => {
  const location = useLocation();
  const userResources = useAppSelector(selectUserResources);
  const orgProperties = useAppSelector(selectOrgProperties);
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";
  const [tabIndex, setTabIndex] = useState(+(location.state?.tabIndex || 0));
  const { storeId } = useParams();
  const parsedStoreId = useMemo(
    () => (!isNaN(Number(storeId)) ? Number(storeId) : undefined),
    [storeId]
  );

  useEffect(() => {
    if (!isNaN(Number(location.state?.tabIndex))) {
      setTabIndex(+location.state?.tabIndex);
      return;
    }
    if (!isNaN(Number(storeId))) {
      setTabIndex(1);
    }
  }, [location.state, storeId]);

  return (
    <Tabs
      isFitted
      index={tabIndex}
      onChange={setTabIndex}
      isLazy
      lazyBehavior="unmount"
      height="100%"
      display="flex"
      flexDir="column"
      overflowY="auto"
      data-tour="stores"
    >
      <TabList bg="white">
        <Tab>Overview</Tab>
        <Tab>Manage My Locations</Tab>
        <Tab isDisabled={!userResources?.includes(RESOURCES.STORES_CREATE)}>
          Add New Locations
        </Tab>
        <Tab isDisabled={!userResources?.includes(RESOURCES.STORES_UPDATE)}>
          Disable Locations
        </Tab>
      </TabList>

      <TabPanels flex={1}>
        <TabPanel height="100%">
          <VStack w="100%" flex={1} alignItems="flex-start" h="100%">
            <StoresOverview />
          </VStack>
        </TabPanel>
        <TabPanel height="100%">
          <VStack
            w="100%"
            flex={1}
            alignItems="flex-start"
            h="100%"
            spacing={5}
          >
            <HStack w="100%">
              <Text textAlign="start">
                Keep your stores update to help shoppers find you on shelf.
                Sort, filter or select to edit stores.
              </Text>
              <Spacer />
              <Button
                variant="outline"
                leftIcon={<FaVideo />}
                onClick={() =>
                  window.open(
                    "https://dathic.notion.site/Manage-your-locations-b322863a9a864fe5a96cae7ccc99410e?pvs=4"
                  )
                }
              >
                Questions? Watch video
              </Button>
            </HStack>
            <Divider />
            <Tabs w="100%" display="flex" flex={1} flexDir="column" isLazy>
              <TabList>
                <Tab>{pluralize(storeLabel)}</Tab>
                <Tab>Online</Tab>
                <Tab>Restaurants</Tab>
                <Tab>Pop-up {pluralize(storeLabel)}</Tab>
              </TabList>
              <TabPanels flex={1}>
                <TabPanel height="100%">
                  <MyStoresTable
                    key="stores"
                    storeTypes={["store"]}
                    storeId={parsedStoreId}
                  />
                </TabPanel>
                <TabPanel height="100%">
                  <BuyOnlineOptions />
                </TabPanel>
                <TabPanel height="100%">
                  <MyStoresTable
                    key="restaurant"
                    storeTypes={["restaurant"]}
                    storeId={parsedStoreId}
                  />
                </TabPanel>
                <TabPanel height="100%">
                  <MyStoresTable
                    key="popup"
                    storeTypes={["popup_store"]}
                    storeId={parsedStoreId}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </TabPanel>
        <TabPanel height="100%">
          <VStack
            w="100%"
            flex={1}
            alignItems="flex-start"
            h="100%"
            spacing={5}
          >
            <HStack w="100%">
              <Text textAlign="start">
                Make it easy for shoppers to find you by keeping your stores
                updated.
              </Text>
              <Spacer />
              <Button
                variant="outline"
                leftIcon={<FaVideo />}
                onClick={() =>
                  window.open(
                    "https://dathic.notion.site/Add-new-Stores-or-restaurants-e5a758bccb1e4b34a97cf8f28af282c9?pvs=4"
                  )
                }
              >
                Questions? Watch video
              </Button>
            </HStack>
            <Divider />
            <Tabs w="100%" display="flex" flex={1} flexDir="column" isLazy>
              <TabList>
                <Tab>{pluralize(storeLabel)}</Tab>
                <Tab>Online</Tab>
                <Tab>Restaurants</Tab>
                <Tab>Pop-up {pluralize(storeLabel)}</Tab>
              </TabList>
              <TabPanels flex={1}>
                <TabPanel height="100%">
                  <UploadStoresTab storeTypes={["store"]} />
                </TabPanel>
                <TabPanel height="100%">
                  <AdditionalSourcesForm />
                </TabPanel>
                <TabPanel height="100%">
                  <UploadStoresTab storeTypes={["restaurant"]} />
                </TabPanel>
                <TabPanel height="100%">
                  <UploadStoresTab storeTypes={["popup_store"]} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </TabPanel>
        <TabPanel height="100%">
          <VStack
            w="100%"
            flex={1}
            alignItems="flex-start"
            h="100%"
            spacing={5}
          >
            <HStack w="100%">
              <Text textAlign="start">
                Make it easy for shoppers to find you by keeping your stores
                updated.
              </Text>
              <Spacer />
              <Button
                variant="outline"
                leftIcon={<FaVideo />}
                onClick={() =>
                  window.open(
                    "https://dathic.notion.site/Disable-locations-in-batch-6e58b6e533ba41c1aceb114bda90baec?pvs=4"
                  )
                }
              >
                Questions? Watch video
              </Button>
            </HStack>
            <Divider />
            <Tabs w="100%" display="flex" flex={1} flexDir="column" isLazy>
              <DisableStoresTab />
            </Tabs>
          </VStack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default StoresTabs;
