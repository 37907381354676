import {
  Box,
  Flex,
  GridItem,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import GaugeChart from "react-gauge-chart";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  getDeliveryEntityIndicators,
  getZip3Indicators,
} from "../../services/api.service";
import { camelCaseWords } from "../../utils/stringUtils";
import { primary, primaryLight, secondaryDark } from "../../theme/colors";
import InfoPopover from "../InfoPopover";
import { selectVisState } from "../../features/map/keplerReducer";
import BarChart from "../../components/charts/bar-chart";
import pluralize from "pluralize";
import { LabelSeries } from "react-vis";

function DeliveryIndicatorsTab({
  accessToken,
  clickedObject,
  orgConfig,
  tabIsActive,
  onFinishLoading,
}) {
  const [indicators, setIndicators] = useState();
  const [loading, setLoading] = useState(false);
  const zipcodeLabel = orgConfig?.zipcodeNameReplacement || "Zipcode";
  const storeLabel = orgConfig?.storeNameReplacement || "Store";

  const isDeliveryEntity = useMemo(
    () => !!(clickedObject.address && clickedObject.uid),
    [clickedObject]
  );
  const isStoreWithDeliveryEntites = useMemo(
    () => !!(clickedObject.address && clickedObject.delivery_entities?.length),
    [clickedObject]
  );
  const isZipcode = useMemo(
    () => !!(clickedObject?.zipcode && !clickedObject.address),
    [clickedObject]
  );

  useEffect(() => {
    async function getIndicators() {
      let _indicators;
      setLoading(true);

      try {
        if (isDeliveryEntity) {
          _indicators = await getDeliveryEntityIndicators(
            [clickedObject.uid],
            accessToken
          );
        } else if (isStoreWithDeliveryEntites) {
          _indicators = await getDeliveryEntityIndicators(
            clickedObject.delivery_entities.map((e) => e.delivery_entity_uid),
            accessToken
          );
        } else if (isZipcode) {
          _indicators = await getZip3Indicators(
            clickedObject?.zipcode,
            accessToken
          );
        }
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
      setIndicators(_indicators);
      if (onFinishLoading)
        onFinishLoading(
          !!_indicators?.find(
            (ind) => !!Object.values(ind).filter((val) => !!val).length
          )
        );
    }

    getIndicators();
  }, [
    accessToken,
    clickedObject,
    isDeliveryEntity,
    isStoreWithDeliveryEntites,
    isZipcode,
    onFinishLoading,
  ]);

  const getBetterKey = (valueKey, indicator) => {
    const value = indicator[valueKey];

    const keys = Object.keys(indicator)
      .filter((key) => key.includes("normal_score"))
      .map((key) =>
        valueKey.includes("normal") ? key : key.replace("_normal", "")
      );
    return keys.find((key) => indicator[key] > value);
  };

  const barChartData = (indicator) =>
    Object.keys(indicator)
      .filter((key) => key.includes("normal_score"))
      .map((key) => ({
        x: camelCaseWords(key.split("_normal_score")[0]),
        y: indicator[key],
        color: !!getBetterKey(key, indicator) ? secondaryDark : primary,
      }));

  return (
    <VStack spacing={10}>
      {loading && <Spinner />}
      {tabIsActive &&
        indicators?.map((indicator) => (
          <VStack>
            <VStack
              key={indicator.id}
              w="100%"
              spacing={5}
              bg="whitesmoke"
              p={3}
              rounded="lg"
            >
              {indicator.source && <Heading mb={3}>{indicator.source}</Heading>}
              <SimpleGrid columns={4} textAlign="center" spacing={3}>
                <GridItem rowStart={2} rowSpan={3}>
                  {indicator.most_important_brand && (
                    <VStack rounded="lg" bg="white" shadow="md" p={3}>
                      <Text fontWeight="bold" fontSize={16}>
                        Most Important Brand
                      </Text>
                      <Text fontSize={28}>
                        {indicator.most_important_brand}
                      </Text>
                    </VStack>
                  )}
                </GridItem>
                <GridItem colStart={2} colSpan={3}>
                  <Flex h="100%" justifyContent="center" alignItems="center">
                    <Text fontWeight="bold" fontSize={16}>
                      Categories Compared to Other{" "}
                      {isZipcode
                        ? pluralize(zipcodeLabel)
                        : pluralize(storeLabel)}
                    </Text>
                    <InfoPopover
                      info={`These scores are compared to other ${
                        isZipcode
                          ? pluralize(zipcodeLabel)
                          : pluralize(storeLabel)
                      }`}
                      popoverProps={{ placement: "right" }}
                    />
                  </Flex>
                </GridItem>
                {Object.keys(indicator)
                  .filter((key) => key.includes("normal_score"))
                  .map((key) => (
                    <Flex
                      key={key}
                      justify="space-between"
                      alignItems="center"
                      flexDir="column"
                      bg="white"
                      rounded="lg"
                      shadow="md"
                      border={
                        getBetterKey(key.replace("_normal", ""), indicator)
                          ? "none"
                          : `3px solid ${primary}`
                      }
                    >
                      <Text>
                        {camelCaseWords(key.split("_normal_score")[0])}
                      </Text>
                      <GaugeChart
                        id={key}
                        nrOfLevels={20}
                        percent={indicator[key.replace("_normal", "")] / 100}
                        colors={[primaryLight, secondaryDark]}
                        arcWidth={0.35}
                        textColor={secondaryDark}
                        needleColor={primary}
                        formatTextValue={(val) => val}
                      />
                    </Flex>
                  ))}
              </SimpleGrid>
              <VStack w="100%">
                <Flex justifyContent="center" alignItems="center">
                  <Text fontWeight="bold" fontSize={16}>
                    Distribution In{" "}
                    {isZipcode
                      ? pluralize(zipcodeLabel, 1)
                      : pluralize(storeLabel)}
                  </Text>
                  <InfoPopover
                    info={`The distribution of each category in the ${
                      isZipcode
                        ? pluralize(zipcodeLabel, 1)
                        : pluralize(storeLabel)
                    }`}
                    popoverProps={{ placement: "right" }}
                  />
                </Flex>
                <Box bg="white" rounded="lg" shadow="md" w="100%">
                  <BarChart
                    height={130}
                    containerProps={{ width: "100%" }}
                    chartContainerProps={{
                      mb: 3,
                      ml: 2,
                      mr: 2,
                      mt: 3,
                      border: null,
                      bg: null,
                    }}
                    hideLegend
                    xAxisTickAngle={0}
                    xyPlotProps={{
                      margin: { left: 0, top: 10, right: 0, bottom: 30 },
                    }}
                    xAxis=""
                    yAxis=""
                    xDomain={barChartData(indicator).map((d) => d.x)}
                    yDomain={[
                      0,
                      Math.max(...barChartData(indicator).map((d) => d.y)),
                    ]}
                    data={{
                      key: {
                        data: barChartData(indicator),
                        props: { colorType: "literal" },
                      },
                    }}
                  >
                    <LabelSeries
                      data={barChartData(indicator)}
                      getLabel={(d) =>
                        d.y.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }
                    />
                  </BarChart>
                </Box>
              </VStack>
            </VStack>
          </VStack>
        ))}
      {!loading && !indicators?.length && (
        <Text>
          No product indicators for this {isZipcode ? zipcodeLabel : storeLabel}
        </Text>
      )}
    </VStack>
  );
}
export default connect((state) => ({
  accessToken: state.app.accessToken,
  orgConfig: state.app.orgProperties?.properties,
  clickedObject: (
    selectVisState(state)?.clicked || selectVisState(state)?.customClicked
  )?.object?.properties,
}))(DeliveryIndicatorsTab);
