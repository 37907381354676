import {
  Box,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger as OrigPopoverTrigger,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  VStack,
} from "@chakra-ui/react";
import { lowerCase, startCase } from "lodash";
import pluralize from "pluralize";
import React from "react";
import { useSelector } from "react-redux";
import { selectOrgProperties, selectProducts } from "../../app/appSlice";
import LimitationsMessage from "../../components/LimitationsMessage";
import { selectMyStores } from "../map/keplerReducer";

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
  OrigPopoverTrigger;

export default function OrgOverview() {
  const orgProperties = useSelector(selectOrgProperties);
  const stores = useSelector(selectMyStores);
  const products = useSelector(selectProducts);

  const statesLabel =
    orgProperties?.properties?.statesNameReplacement || "States";
  const zipcodeLabel =
    orgProperties?.properties?.zipcodeNameReplacement || "Zipcode";
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";
  const productLabel =
    orgProperties?.properties?.productNameReplacement || "Product";

  const locationLimits = {
    [statesLabel]: orgProperties?.properties?.limitedStates,
    county: orgProperties?.properties?.limitedCounties,
    city: orgProperties?.properties?.limitedCities,
    [zipcodeLabel]: orgProperties?.properties?.limitedZipcodes,
  };
  return (
    <VStack w="100%" spacing={10}>
      <VStack w="100%">
        <Flex alignItems="center" w="100%">
          <Text>Locations</Text>
          <Box flex={1} ml={3} h="2px" bg="gray.500" />
        </Flex>
        <HStack
          p={3}
          bg="white"
          rounded="lg"
          w="100%"
          sx={{ div: { height: "6rem" } }}
        >
          <Stat bg="#f5f5f5ff" rounded="lg" flex={0} p={3} minW={24}>
            <StatLabel>Country</StatLabel>
            <StatNumber>
              {orgProperties?.properties?.country || "US"}
            </StatNumber>
          </Stat>
          {Object.entries(locationLimits).map(([level, limits]) => (
            <Popover trigger="hover" isOpen={!limits ? false : undefined}>
              <PopoverTrigger>
                <Stat bg="#f5f5f5ff" rounded="lg" flex={0} p={3} minW={24}>
                  <StatLabel>
                    {startCase(pluralize(level, (limits || []).length))}
                  </StatLabel>
                  {limits && <StatNumber>{limits.length}</StatNumber>}
                  {limits && (
                    <StatHelpText color="tomato">Upgrade</StatHelpText>
                  )}
                  {!limits && <StatHelpText>No limit</StatHelpText>}
                </Stat>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverHeader>
                  {!limits?.length
                    ? `You don't have any ${lowerCase(pluralize(level))}`
                    : [limits.slice(0, -1).join(", "), limits.slice(-1)[0]]
                        .filter((i) => !!i)
                        .join(" and ")}
                </PopoverHeader>
                <PopoverBody>
                  <LimitationsMessage includedInMessage={[level]} />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ))}
        </HStack>
      </VStack>
      <VStack w="100%">
        <Flex alignItems="center" w="100%">
          <Text>{storeLabel}</Text>
          <Box flex={1} ml={3} h="2px" bg="gray.500" />
        </Flex>
        <HStack
          p={3}
          bg="white"
          rounded="lg"
          w="100%"
          sx={{ div: { height: "6rem" } }}
        >
          <Stat bg="#f5f5f5ff" rounded="lg" flex={0} p={3} minW={24}>
            <StatLabel>All {pluralize(storeLabel, stores?.length)}</StatLabel>
            <StatNumber>{stores?.length || 0}</StatNumber>
          </Stat>
          <Stat bg="#f5f5f5ff" rounded="lg" flex={0} p={3} minW={24}>
            <StatLabel>Retailers</StatLabel>
            <StatNumber>
              {
                Array.from(new Set(stores?.map((s: any) => s.chain) || []))
                  .length
              }
            </StatNumber>
          </Stat>
        </HStack>
      </VStack>
      <VStack w="100%">
        <Flex alignItems="center" w="100%">
          <Text>{productLabel}</Text>
          <Box flex={1} ml={3} h="2px" bg="gray.500" />
        </Flex>
        <HStack
          p={3}
          bg="white"
          rounded="lg"
          w="100%"
          sx={{ div: { height: "6rem" } }}
        >
          <Stat bg="#f5f5f5ff" rounded="lg" flex={0} p={3} minW={24}>
            <StatLabel>
              All {pluralize(productLabel, products?.length)}
            </StatLabel>
            <StatNumber>{products?.length || 0}</StatNumber>
          </Stat>
          <Stat bg="#f5f5f5ff" rounded="lg" flex={0} p={3} minW={24}>
            <StatLabel>Brands</StatLabel>
            <StatNumber>
              {Array.from(
                new Set(
                  products
                    ?.flatMap((p) => p.delivery_products || [])
                    .map((pdp) => pdp.delivery_product?.product_brand)
                    ?.filter((b) => !!b) || []
                )
              ).length || 0}
            </StatNumber>
          </Stat>
          <Stat bg="#f5f5f5ff" rounded="lg" flex={0} p={3} minW={32}>
            <StatLabel>Major categories</StatLabel>
            <StatNumber>
              {Array.from(
                new Set(
                  products
                    ?.flatMap((p) => p.delivery_products || [])
                    .map((pdp) =>
                      (pdp.delivery_product?.product_category || []).at(0)
                    )
                    ?.filter((b) => !!b) || []
                )
              ).length || 0}
            </StatNumber>
          </Stat>
        </HStack>
      </VStack>
    </VStack>
  );
}
