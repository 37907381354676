import { Box, Button, Img } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { selectMenus, selectUserResources } from "../app/appSlice";
import { useAppSelector } from "../app/store";
import menuIconStore from "../assets/images/menu-icon-store.png";
import { RESOURCES } from "../constants/user-constants";
import { trackClick } from "../services/tracking.service";

const StoresBtn = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userResources = useAppSelector(selectUserResources);
  const menus = useAppSelector(selectMenus);
  const growthMenu = menus?.find((m: any) => m.id === "stores");
  return (
    <Box>
      <Button
        className="stores-btn"
        id="stores-btn"
        variant="link"
        as="div"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        padding="12px 10px"
        isActive={location.pathname.includes("stores")}
        leftIcon={
          <Img
            src={menuIconStore}
            height="1rem"
            width="1rem"
            id="stores-btn"
            className="stores-btn"
            filter={
              location.pathname.includes("stores")
                ? "invert(48%) sepia(45%) saturate(4075%) hue-rotate(177deg) brightness(97%) contrast(102%)"
                : undefined
            }
          />
        }
        iconSpacing={0}
        size="xs"
        isDisabled={!userResources?.includes(RESOURCES.STORES_BTN)}
        onClick={() =>
          trackClick("stores-btn", "Locations", () => {
            if (!userResources?.includes(RESOURCES.STORES_BTN)) {
              toast.warn("You don't have access to this");
              return;
            }
            if (location.pathname.includes("storelocator")) {
              navigate(`storelocator/stores`);
              return;
            }
            const child = growthMenu?.children?.[0];
            child?.embedUrl
              ? navigate(
                  `${child.embedUrl ? "embed/" : ""}stores-${child.id}`,
                  {
                    state: {
                      from: location,
                      url: child.embedUrl,
                    },
                  }
                )
              : child?.component
              ? navigate(`${child.component}/stores-${child.id}`)
              : navigate(`not_available/stores-${child.id}`);
          })
        }
      >
        Locations
      </Button>
    </Box>
  );
};

export default StoresBtn;
