import { Box, Button, Img } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { selectMenus, selectUserResources } from "../app/appSlice";
import { useAppSelector } from "../app/store";
import menuIconGrowth from "../assets/images/menu-icon-engage.png";
import { RESOURCES } from "../constants/user-constants";
import { trackClick } from "../services/tracking.service";

export default function EngageBtn() {
  const location = useLocation();
  const navigate = useNavigate();
  const menus = useAppSelector(selectMenus);
  const userResources = useAppSelector(selectUserResources);
  const engageMenu = menus?.find((m: any) => m.id === "engage");
  return !location.pathname?.includes("storelocator") && !!engageMenu ? (
    <Box>
      <Button
        id="engage-btn"
        className="engage-btn"
        variant="link"
        as="div"
        color="blue.900"
        padding="12px 10px"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        leftIcon={
          <Img
            src={menuIconGrowth}
            height="1rem"
            width="1rem"
            id="engage-btn"
            className="engage-btn"
            filter={
              location.pathname.includes("engage")
                ? "invert(48%) sepia(45%) saturate(4075%) hue-rotate(177deg) brightness(97%) contrast(102%)"
                : undefined
            }
          />
        }
        isActive={location.pathname.includes("engage")}
        isDisabled={!userResources?.includes(RESOURCES.MARKETING_BTN)}
        iconSpacing={0}
        size="xs"
        _hover={{ textDecoration: "underline" }}
        onClick={() =>
          trackClick("engage-btn", "Engage", () => {
            if (!userResources?.includes(RESOURCES.MARKETING_BTN)) {
              toast.warn("You don't have access to this");
              return;
            }
            const child = engageMenu?.children?.[0];
            child?.embedUrl
              ? navigate(
                  `${child.embedUrl ? "embed/" : ""}engage-${child.id}`,
                  {
                    state: {
                      from: location,
                      url: child.embedUrl,
                    },
                  }
                )
              : child?.component
              ? navigate(`${child.component}/engage-${child.id}`)
              : navigate(`not_available/engage-${child.id}`);
          })
        }
      >
        Engage
      </Button>
    </Box>
  ) : (
    <></>
  );
}
