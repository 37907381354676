import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Text, VStack } from "@chakra-ui/react";
import {
  wrapTo,
  addFilter,
  enlargeFilter,
  removeFilter,
  setFilter,
  toggleFilterAnimation,
  toggleFilterFeature,
} from "kepler.gl/actions";
import { themeLT } from "kepler.gl/styles";
import { ThemeProvider } from "styled-components";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import { appInjector, FilterPanelFactory } from "kepler.gl/components";
import { IntlProvider } from "react-intl";
import { messages } from "kepler.gl/localization";
import { selectVisState } from "../../features/map/keplerReducer";
import { RESOURCES } from "../../constants/user-constants";

const FilterPanel = appInjector.get(FilterPanelFactory);

function KeplerFilterList({
  viewId,
  datasets,
  filters,
  layers,
  removeFilter,
  enlargeFilter,
  toggleAnimation,
  toggleFilterFeature,
  newFilter,
  userResources,
  setFilter,
  ...other
}) {
  const isAnyFilterAnimating = filters.some((f) => f.isAnimating);
  const reversedIndex = useMemo(() => {
    return new Array(filters.length)
      .fill(0)
      .map((d, i) => i)
      .reverse();
  }, [filters.length]);
  return (
    <VStack {...other}>
      {!!Object.keys(datasets || {}).length && (
        <Button
          leftIcon={<AddIcon />}
          onClick={() => {
            newFilter(viewId, Object.values(datasets)[0].id);
          }}
          mb={3}
          colorScheme="blue"
          isDisabled={!userResources?.includes(RESOURCES.LAYER_EDIT_CONFIG)}
        >
          Add Filter
        </Button>
      )}
      {!Object.keys(datasets || {}).length && (
        <Text>Add data to the map to add a filter</Text>
      )}
      {reversedIndex.map((idx) => (
        <IntlProvider locale={"en"} messages={messages["en"]}>
          <Box w="100%">
            <ThemeProvider theme={themeLT}>
              <FilterPanel
                key={`${filters[idx].id}-${idx}`}
                idx={idx}
                filters={filters}
                filter={filters[idx]}
                datasets={datasets}
                layers={layers}
                isAnyFilterAnimating={isAnyFilterAnimating}
                removeFilter={() => removeFilter(viewId, idx)}
                enlargeFilter={() => enlargeFilter(viewId, idx)}
                toggleAnimation={() => toggleAnimation(viewId, idx)}
                toggleFilterFeature={() => toggleFilterFeature(viewId, idx)}
                setFilter={(...params) => {
                  setFilter(viewId, ...params);
                }}
              />
            </ThemeProvider>
          </Box>
        </IntlProvider>
      ))}
    </VStack>
  );
}

export default connect(
  (state) => ({
    viewId: state?.mapViews?.views?.[`view${state?.mapViews?.activeView}`]?.id,
    datasets: selectVisState(state)?.datasets || {},
    filters: selectVisState(state)?.filters || [],
    layers: selectVisState(state)?.layers || [],
    userResources: state.app.currentUser.resources,
  }),
  (dispatch) => ({
    newFilter: (viewId, dataId) =>
      dispatch(wrapTo(`view${viewId}`, addFilter(dataId))),
    removeFilter: (viewId, idx) =>
      dispatch(wrapTo(`view${viewId}`, removeFilter(idx))),
    enlargeFilter: (viewId, idx) =>
      dispatch(wrapTo(`view${viewId}`, enlargeFilter(idx))),
    toggleAnimation: (viewId, idx) =>
      dispatch(wrapTo(`view${viewId}`, toggleFilterAnimation(idx))),
    toggleFilterFeature: (viewId, idx) =>
      dispatch(wrapTo(`view${viewId}`, toggleFilterFeature(idx))),
    setFilter: (viewId, ...params) =>
      dispatch(wrapTo(`view${viewId}`, setFilter(...params))),
  })
)(KeplerFilterList);
