import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  HStack,
  Image,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  VStack,
} from "@chakra-ui/react";
import pluralize from "pluralize";
import { useEffect, useRef, useState } from "react";
import { MdCheckCircle } from "react-icons/md";
import {
  selectAccessToken,
  selectCurrentUser,
  selectIsShopifyApp,
  selectOrgProperties,
  selectStripeCustomerID,
} from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import icono_bolsas from "../../assets/images/DATHIC_icono-bolsas.webp";
import icono_fuego from "../../assets/images/DATHIC_icono-fuego.webp";
import icono_rocket from "../../assets/images/DATHIC_icono-rocket.webp";
import { postShopifySubscriptionRequest } from "../../services/shopify.service";
import {
  getCustomerSession,
  getProducts,
  postCheckoutSession,
} from "../../services/stripe.service";
import { secondaryDark } from "../../theme/colors";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

type Plan = {
  storeLimit: number;
  interval: string;
  price: number;
  bestFor: string;
  features: string[];
};

const storelocatorPlans: Plan[] = [
  {
    storeLimit: 2000,
    interval: "Annual",
    price: 650,
    bestFor: "Starter plan",
    features: ["For brands available in fewer than 2k locations."],
  },
  {
    storeLimit: 10000,
    interval: "Annual",
    price: 2000,
    bestFor: "Grow plan",
    features: ["For brands available in 2k to 10k locations."],
  },
];

function PlanCardContent({
  storeLabel,
  plan,
  index,
  setSelectedPlan,
}: {
  storeLabel: string;
  plan: Plan;
  index: number;
  setSelectedPlan: (plan: Plan) => void;
}) {
  return (
    <VStack p={5}>
      <Image
        src={
          index === 0 ? icono_bolsas : index === 1 ? icono_fuego : icono_rocket
        }
        alt="map_preview"
        w={53}
        h={53}
      />
      <Text fontWeight="bold">
        {plan.storeLimit ? `UP TO ${plan.storeLimit}` : "UNLIMITED"}{" "}
        {pluralize(storeLabel).toUpperCase()}
      </Text>
      <Text fontWeight="bold">
        <span style={{ fontSize: 48, fontWeight: "bolder" }}>
          ${" "}
          {plan.interval === "Annual"
            ? Math.floor(plan.price / 12)
            : plan.price}
        </span>{" "}
        per month
      </Text>
      {plan.interval === "Annual" && (
        <Text fontSize={12} color="blue.400">
          billed at ${plan.price} once per year
        </Text>
      )}
      <Button
        variant="solid"
        colorScheme="blue"
        size="lg"
        rounded="full"
        onClick={() => setSelectedPlan(plan)}
      >
        START NOW
      </Button>
      <Text alignSelf="flex-start" fontWeight="bold">
        {plan.bestFor}
      </Text>
      <List spacing={3}>
        {plan.features.map((f) => (
          <ListItem>
            <ListIcon as={CheckIcon} color="green.500" />
            {f}
          </ListItem>
        ))}
      </List>
    </VStack>
  );
}

export function CheckoutStep() {
  const currentUser = useAppSelector(selectCurrentUser);
  const [numStores, setNumStores] = useState(1500);
  const [isLoadingStripe, setIsLoadingStripe] = useState(true);
  const [stripeProducts, setStripeProducts] = useState<any[] | undefined>(
    undefined
  );
  const accessToken = useAppSelector(selectAccessToken);
  const orgConfig = useAppSelector(selectOrgProperties);
  const isShopifyApp = useAppSelector(selectIsShopifyApp);
  const stripeCustomerID = useAppSelector(selectStripeCustomerID);
  const [customerSession, setCustomerSession] = useState<any>(null);
  const [isLoadingStripeCustomer, setIsLoadingStripeCustomer] = useState(true);
  const submitButton = useRef<HTMLButtonElement>(null);
  const config = orgConfig?.store_locator;
  const storeLocatorConfigured = ["payed", "free_trial"].includes(
    orgConfig?.store_locator?.status || ""
  );
  const storeLabel = orgConfig?.properties?.storeNameReplacement || "Store";

  useEffect(() => {
    if (!stripeProducts) {
      setIsLoadingStripe(true);
      getProducts(accessToken)
        .then((products) => {
          setStripeProducts(products?.data || []);
        })
        .finally(() => setIsLoadingStripe(false));
    }
  }, [accessToken, stripeCustomerID, stripeProducts]);

  const createCheckoutSession = (product: any) => {
    let params = new URLSearchParams(window.location.search);
    params.set("paymentsuccess", "true");
    params.delete("token");
    const successUrl = `${window.location.origin.toString()}/storelocator?${params.toString()}`;

    let failParams = new URLSearchParams(window.location.search);
    failParams.set("paymentcanceled", "true");
    failParams.delete("token");
    const cancelUrl = `${window.location.origin.toString()}/storelocator?${failParams.toString()}`;
    postCheckoutSession(
      {
        line_items: [
          {
            price: product.default_price.id,
            quantity: 1,
          },
        ],
        subscription_data: {
          trial_settings: {
            end_behavior: { missing_payment_method: "create_invoice" },
          },
          trial_period_days: 15,
        },
        payment_method_collection: "if_required",
        customer_email: currentUser?.username,
        mode: "subscription",
        success_url: successUrl,
        cancel_url: cancelUrl,
        client_reference_id: `${currentUser?.organization_id}`,
      },
      accessToken
    ).then((checkoutUrl) => {
      window.open(checkoutUrl);
    });
  };

  const getProductForNumStores = (numStores: number) => {
    return stripeProducts
      ?.sort(
        (a, b) =>
          (Number(a.metadata.storeLimit) || Number.POSITIVE_INFINITY) -
          (Number(b.metadata.storeLimit) || Number.POSITIVE_INFINITY)
      )
      .find((product) => Number(product.metadata.storeLimit) >= numStores);
  };

  const product =
    getProductForNumStores(numStores) ||
    stripeProducts?.find((p) => p.id === "prod_PeB0Fe8JmWY1Hw");

  useEffect(() => {
    if (!!stripeCustomerID) {
      setIsLoadingStripeCustomer(true);
      getCustomerSession(accessToken)
        .then((session) => {
          setCustomerSession(session);
        })
        .finally(() => setIsLoadingStripeCustomer(false));
    } else {
      setIsLoadingStripeCustomer(false);
    }
  }, [accessToken, stripeCustomerID]);

  return isShopifyApp ? (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        let successParams = new URLSearchParams(window.location.search);
        successParams.set("paymentsuccess", "true");
        successParams.delete("token");
        const successUrl = `${window.location.origin.toString()}/storelocator?${successParams.toString()}`;

        let failParams = new URLSearchParams(window.location.search);
        failParams.set("paymentcanceled", "true");

        if (isShopifyApp) {
          // @ts-ignore
          const planData = JSON.parse(e.target.elements.hidden_submit.value);
          postShopifySubscriptionRequest(
            {
              name: `${planData.interval} ${
                planData.storeLimit || "unlimited"
              } store locator`,
              returnUrl: successUrl,
              price: planData.price,
              interval:
                planData.interval === "Annual" ? "ANNUAL" : "EVERY_30_DAYS",
              trialDays: 15,
            },
            accessToken || ""
          ).then((res) => {
            if (res.confirmationUrl) {
              window.open(res.confirmationUrl, "_blank");
            }
          });
        }
      }}
    >
      <Box>
        <Box>
          <VStack bg="blue.900" color="white" p={10} spacing={5}>
            <Heading>Pricing Product Locator</Heading>
            <Heading rounded="full" bg="blue.400" p="1rem 2rem" as="h4">
              1 MONTH FREE TRIAL. CANCEL ANYTIME.
            </Heading>
            {orgConfig?.store_locator?.shopify_app_charges?.length &&
              storeLocatorConfigured && (
                <Text fontSize={12}>
                  Your current plan:{" "}
                  {orgConfig.store_locator.shopify_app_charges.at(-1).name}
                </Text>
              )}
            <SimpleGrid columns={2} gap={5}>
              {storelocatorPlans
                .filter((p) => p.interval === "Annual")
                .map((p, i) => (
                  <GridItem
                    bg="white"
                    color="blue.900"
                    shadow="outline"
                    rounded="lg"
                  >
                    <PlanCardContent
                      plan={p}
                      storeLabel={storeLabel}
                      index={i}
                      setSelectedPlan={(plan) => {
                        if (submitButton.current) {
                          submitButton.current.value = JSON.stringify(plan);
                          submitButton.current.click();
                        }
                      }}
                    />
                  </GridItem>
                ))}
            </SimpleGrid>
            <Button
              hidden
              ref={submitButton}
              type="submit"
              name="hidden_submit"
            ></Button>
          </VStack>
        </Box>
        {config?.status === "pendingpayment" && (
          <VStack>
            <Text>
              Your {storeLabel} Locator payment is still processing. Please
              email us at <a href="mailto: info@dathic.com">info@dathic.com</a>{" "}
              if you have any questions.
            </Text>
          </VStack>
        )}
      </Box>
    </form>
  ) : (
    <>
      {!isLoadingStripeCustomer && (
        <VStack
          p={10}
          minW={600}
          maxW={800}
          alignSelf="center"
          spacing={8}
          bg={secondaryDark}
          alignItems={"flex-start"}
        >
          <HStack w="100%" color={"white"}>
            <FormControl>
              <FormLabel>How many locations are you in?</FormLabel>
              <Slider
                min={1}
                max={11500}
                step={3000}
                defaultValue={1500}
                value={numStores}
                onChange={(value) => {
                  setNumStores(value);
                }}
              >
                {[
                  {
                    value: 1500,
                    label: "< 1.5k",
                  },
                  {
                    value: 4000,
                    label: "< 4k",
                  },
                  {
                    value: 7000,
                    label: "< 7k",
                  },
                  {
                    value: 10000,
                    label: "< 10k",
                  },
                  {
                    value: 11500,
                    label: "+",
                  },
                ].map((mark) => (
                  <SliderMark value={mark.value} mt="1" ml="-2.5" fontSize="sm">
                    {mark.label}
                  </SliderMark>
                ))}
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </FormControl>
          </HStack>
          <HStack w="100%" color={"white"}>
            <VStack textAlign={"start"} alignItems={"flex-start"}>
              <Skeleton isLoaded={!isLoadingStripe}>
                <Heading>
                  {product?.name || "Dathic Product Locator Plus"}
                </Heading>
              </Skeleton>
              <SkeletonText noOfLines={1} isLoaded={!isLoadingStripe}>
                <Text>
                  {product?.description || "Dathic Product Locator Plus"}
                </Text>
              </SkeletonText>
            </VStack>
            <Skeleton
              isLoaded={!isLoadingStripe}
              height={100}
              width={100}
            ></Skeleton>
            {!!product?.images?.[0] && (
              <Image
                src={product.images[0]}
                alt={product.name}
                borderRadius={10}
                height={100}
              />
            )}
          </HStack>
          {!!isLoadingStripe && (
            <HStack color={"white"} w="100%">
              <Skeleton>
                <Heading fontWeight={"extrabold"}>
                  {Number(2000).toLocaleString(undefined, {
                    currency: "usd",
                    style: "currency",
                    maximumFractionDigits: 0,
                  })}
                </Heading>
              </Skeleton>
              <SkeletonText noOfLines={1}>
                <Text>Per year</Text>
              </SkeletonText>
            </HStack>
          )}
          {!!product?.default_price?.unit_amount && (
            <HStack color={"white"} w="100%">
              <Heading fontWeight={"extrabold"}>
                {Number(product.default_price.unit_amount / 100).toLocaleString(
                  undefined,
                  {
                    currency: "usd",
                    style: "currency",
                    maximumFractionDigits: 0,
                  }
                )}
              </Heading>
              <Text>
                {product.default_price.recurring?.interval_count > 1
                  ? "every"
                  : "per"}{" "}
                {product.default_price.recurring?.interval_count > 1
                  ? `${product.default_price.recurring?.interval_count} `
                  : ""}
                {product.default_price.recurring?.interval}
              </Text>
            </HStack>
          )}
          {product?.id === "prod_PeB0Fe8JmWY1Hw" ? (
            <Button
              isFullWidth
              colorScheme={"blue"}
              onClick={() =>
                window.open(
                  "https://calendly.com/laura-rocha/30_mins_dathic_product_locator_intro",
                  "_blank"
                )
              }
            >
              Contact us
            </Button>
          ) : (
            <Button
              isFullWidth
              colorScheme={"blue"}
              onClick={() => createCheckoutSession(product)}
              isDisabled={isLoadingStripe || !product}
            >
              Start trial
            </Button>
          )}
          <List
            spacing={3}
            color={"white"}
            alignItems="flex-start"
            textAlign={"start"}
          >
            <ListItem>This includes</ListItem>
            {!!isLoadingStripe && (
              <SkeletonText noOfLines={10} w={500} spacing={5} lineHeight={10}>
                <ListItem marginLeft={5}>
                  <ListIcon as={SkeletonCircle} />
                  Dathic Product Locator Plus
                </ListItem>
              </SkeletonText>
            )}
            {product?.features?.map((feature: { name: string }) => (
              <ListItem marginLeft={5}>
                <ListIcon as={MdCheckCircle} />
                {feature.name}
              </ListItem>
            ))}
          </List>
        </VStack>
      )}
    </>
  );
}
