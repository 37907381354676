import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { IconButton } from "@chakra-ui/react";
import React from "react";
import { IoInformationCircleOutline } from "react-icons/io5";

export default function InfoPopover({
  info,
  iconProps,
  popoverProps,
  triggerContent,
}) {
  return (
    <Popover trigger="hover" {...(popoverProps || {})}>
      <PopoverTrigger>
        <div>
          {!triggerContent && (
            <IconButton
              size="md"
              variant="link"
              icon={<IoInformationCircleOutline />}
              {...(iconProps || {})}
            />
          )}
          {!!triggerContent && triggerContent}
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>{info}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
