import React, { useCallback, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { IoEye } from "react-icons/io5";
import { connect } from "react-redux";
import StepWizard from "react-step-wizard";
import { toast } from "react-toastify";
import {
  getAdVideo,
  getCreativePreview,
  postAdImage,
  postAdVideo,
  postFullAdCampaign,
  postImageCreative,
  postVideoCreative,
} from "../../../services/facebook_api.service";
import { replacePlaceholders } from "../../../utils/stringUtils";
import fbAdExample from "../../../assets/images/fb_ad_example.png";
import "./campaign-creation-styles.css";
import {
  billingEvents,
  callToActionTypes,
  currencyOffsets,
  objectives,
} from "./constants";
import "./campaign-creation.css";
import FacebookPageSelect from "../../../components/FacebookPageSelect";
import InstagramAccountSelect from "../../../components/InstagramAccountSelect";
import FacebookImageUpload from "./FacebookImageUpload";
import { Box, Flex, Grid, HStack, Text, VStack } from "@chakra-ui/layout";
import {
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/form-control";
import { Radio, RadioGroup } from "@chakra-ui/radio";
import { Button } from "@chakra-ui/button";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { Image } from "@chakra-ui/image";
import { Input } from "@chakra-ui/input";
import { Select } from "@chakra-ui/select";
import { Tag, TagLabel } from "@chakra-ui/tag";
import { NumberInput, NumberInputField } from "@chakra-ui/number-input";
import { Checkbox, CheckboxGroup } from "@chakra-ui/checkbox";
import DynamicInput from "../../../components/DynamicInput";
import pluralize from "pluralize";
import { DateTime, Interval } from "luxon";

const Nav = (props) => {
  const dots = [];
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i;
    dots.push(
      <span
        key={`step-${i}`}
        className={`dot ${isActive ? "active" : ""}`}
        onClick={() => i < props.currentStep && props.goToStep(i)}
      >
        &bull;
      </span>
    );
  }

  return <div className="nav">{dots}</div>;
};

function CampaignStep({
  campaignValues = {},
  setCampaignValues,
  nextStep,
  fbIntegration,
  accessToken,
}) {
  return (
    <Box p={5}>
      {(!fbIntegration?.adCampaginDefaults?.campaign?.objective ||
        !objectives[fbIntegration.adCampaginDefaults.campaign.objective]) && (
        <FormControl isRequired mb={5}>
          <FormLabel>Objective</FormLabel>
          <RadioGroup
            value={campaignValues.objective}
            onChange={(v) =>
              setCampaignValues((oldValues) => ({
                ...oldValues,
                objective: v,
              }))
            }
          >
            <VStack spacing={3}>
              {Object.keys(objectives).map((objective) => (
                <Radio value={objective}>
                  <span style={{ fontWeight: "bold" }}>
                    {objectives[objective].name}
                  </span>
                  : {objectives[objective].description}
                </Radio>
              ))}
            </VStack>
          </RadioGroup>
        </FormControl>
      )}
      <Flex
        bg="white"
        flexDir="row"
        justifyContent="space-between"
        position="fixed"
        bottom={10}
        right={10}
        zIndex={1}
      >
        <Button onClick={nextStep} isDisabled={!campaignValues.objective}>
          Next
        </Button>
      </Flex>
    </Box>
  );
}

function AdStep({
  adValues,
  setAdValues,
  nextStep,
  setCreative,
  previousStep,
  selectedPlatforms,
  fbIntegration,
  isVideoAd,
  setIsVideoAd,
  isCarouselAd,
  setIsCarouselAd,
  useStores,
  zipcodesFromSelection,
  storesFromSelection,
  storeLocatorUrl,
  storeLabel,
}) {
  const [videoSrc, setVideoSrc] = useState({});
  const [notAllowedCTAs, setNotAllowedCTAs] = useState([]);
  const videoRef = useRef();
  const stepLoaded = useRef();
  const dataForDynamicInput = Object.keys(
    (useStores ? storesFromSelection : zipcodesFromSelection)?.[0] || {}
  ).reduce(
    (acc, key) =>
      [
        "county",
        "major_city",
        "state",
        "zipcode",
        "address",
        "chain",
        "city",
        "name",
        "phone",
        "short_address",
      ].includes(key)
        ? { ...acc, [key]: `{${key}}` }
        : acc,
    {}
  );

  useEffect(() => {
    if (stepLoaded.current && adValues) {
      setCreative();
    } else {
      stepLoaded.current = true;
    }
  }, [adValues, setCreative]);

  const onloadedmetadata = (metadata) => {
    const secs = 10;
    const duration = metadata.target.duration;
    videoRef.current.currentTime = Math.min(
      Math.max(0, (secs < 0 ? duration : 0) + secs),
      duration
    );
  };
  const onseeked = (e) => {
    var canvas = document.createElement("canvas");
    canvas.height = videoRef.current.videoHeight;
    canvas.width = videoRef.current.videoWidth;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      setAdValues((oldValues) => ({
        ...oldValues,
        video: {
          ...oldValues.video,
          image: new File([blob], videoSrc.filename),
        },
      }));
    });
  };

  const onFileLoad = (fileType, file, fileUrl, index) => {
    if (fileType.includes("video")) {
      setAdValues((oldValues) => ({
        ...oldValues,
        video: { file },
      }));
      setVideoSrc({
        url: window.URL.createObjectURL(file),
        filename: `${file.name.split(".")[0]}.png`,
        loading: true,
      });
    } else {
      setAdValues((oldValues) => {
        let newImages = [...(oldValues.images || []), { fileUrl, file }];
        if (typeof index === "number") {
          newImages = [...(oldValues.images || [])];
          newImages[index] = { fileUrl, file };
        }
        const newAdValues = {
          ...oldValues,
          images: newImages,
        };
        if (newImages.length > 1 && !isVideoAd && !isCarouselAd) {
          setNotAllowedCTAs((oldValue) => [...oldValue, "BUY_NOW"]);
          newAdValues.call_to_action = callToActionTypes.filter(
            (key) => key !== "BUY_NOW"
          )[0];
        } else {
          setNotAllowedCTAs([]);
        }
        return newAdValues;
      });
    }
  };

  return (
    <Box p={5}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (adValues.images?.length || adValues.video) {
            nextStep();
          } else {
            toast.warn("You must select an image or video for your ads");
          }
        }}
      >
        <Popover trigger="hover">
          <PopoverTrigger>
            <Button alignSelf="flex-end" leftIcon={<IoEye />}>
              Example
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <Box w="600px" h="300px">
                <Image
                  width="100%"
                  height="100%"
                  sizes="lg"
                  src={fbAdExample}
                />
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <FormControl isRequired mb={5}>
          <FormLabel>Headline</FormLabel>
          <DynamicInput
            data={dataForDynamicInput}
            isTextArea
            info={
              <Box>
                <strong>Personalizing Ads:</strong>
                <p>{`To personalize text to include your target user's location, please choose the respective variable.`}</p>
                <Text
                  fontSize={12}
                >{`(ie: "A new ${storeLabel} has arrived to {state}" would appear as "A new ${storeLabel} has a arrived to Florida" for all clients in Florida)`}</Text>
              </Box>
            }
            value={adValues.headline}
            onChange={(newValue) =>
              setAdValues((oldValues) => ({
                ...oldValues,
                headline: newValue,
              }))
            }
          />
        </FormControl>
        <FormControl isRequired mb={5}>
          <FormLabel>Message</FormLabel>
          <DynamicInput
            data={dataForDynamicInput}
            info={
              <Box>
                <strong>Personalizing Ads:</strong>
                <p>{`To personalize text to include your target user's location, please choose the respective variable.`}</p>
                <Text
                  fontSize={12}
                >{`(ie: "A new ${storeLabel} has arrived to {state}" would appear as "A new ${storeLabel} has a arrived to Florida" for all clients in Florida)`}</Text>
              </Box>
            }
            value={adValues.message}
            onChange={(newValue) =>
              setAdValues((oldValues) => ({
                ...oldValues,
                message: newValue,
              }))
            }
          />
        </FormControl>
        <FormControl isRequired mb={5}>
          <FormLabel>Caption URL</FormLabel>
          <Input
            rounded="md"
            type="url"
            value={adValues.caption}
            onChange={(event) =>
              setAdValues((oldValues) => ({
                ...oldValues,
                caption: event.target.value,
              }))
            }
          />
          {adValues.caption && !/^https?:\/\//.test(adValues.caption) && (
            <FormControl color="tomato">
              URLs must begin with "http://" or "https://"
            </FormControl>
          )}
        </FormControl>
        <HStack>
          <FormControl isRequired mb={5}>
            <FormLabel>Call to action</FormLabel>
            <Select
              isRequired
              rounded="md"
              value={adValues.call_to_action}
              onChange={(event) =>
                setAdValues((oldValues) => ({
                  ...oldValues,
                  call_to_action: event.target.value,
                }))
              }
            >
              {callToActionTypes
                .filter((key) => !notAllowedCTAs.includes(key))
                .map((key) => (
                  <option key={key} value={key}>
                    {key.replaceAll("_", " ")}
                  </option>
                ))}
            </Select>
          </FormControl>
          <FormControl isRequired mb={5}>
            <FormLabel>Call to action link</FormLabel>
            <Input
              rounded="md"
              value={adValues.call_to_action_link}
              type="url"
              onChange={(event) =>
                setAdValues((oldValues) => ({
                  ...oldValues,
                  call_to_action_link: event.target.value,
                  link: event.target.value,
                }))
              }
            />
            {adValues.call_to_action_link &&
              !/^https?/.test(adValues.call_to_action_link) && (
                <FormHelperText color="tomato">
                  URLs must begin with "http" or "https"
                </FormHelperText>
              )}
            {useStores && storeLocatorUrl && (
              <FormHelperText>
                <Button
                  onClick={() => {
                    setAdValues((oldValues) => ({
                      ...oldValues,
                      call_to_action_link: storeLocatorUrl + `?storeId={id}`,
                      link: storeLocatorUrl + `?storeId={id}`,
                    }));
                  }}
                >
                  Use store locator url
                </Button>
              </FormHelperText>
            )}
          </FormControl>
        </HStack>

        <FormControl mb={5}>
          <FormLabel>Ad type</FormLabel>

          <Select
            rounded="md"
            value={isVideoAd ? 2 : isCarouselAd ? 1 : 0}
            onChange={(event) => {
              setIsCarouselAd(+event.target.value === 1);
              setIsVideoAd(+event.target.value === 2);
              setAdValues((oldValues) => ({
                ...oldValues,
                images: oldValues.images?.[0] ? [oldValues.images[0]] : [],
                video: undefined,
              }));
              setVideoSrc({});
            }}
          >
            <option value={0}>Single Image and Story</option>
            <option value={1}>Carousel Image Only</option>
            <option value={2}>Video Only</option>
          </Select>
        </FormControl>
        {!isVideoAd && (
          <HStack alignItems="flex-start" justifyContent="space-between">
            {isCarouselAd && (
              <Grid
                flex={1}
                gridTemplateColumns="repeat(5,1fr)"
                autoRows="1fr"
                gap={3}
              >
                {adValues.images.map((image, index) => (
                  <FacebookImageUpload
                    image={image}
                    accept=".jpg, .png"
                    cropType="feed"
                    onLoad={onFileLoad}
                    onDelete={() =>
                      setAdValues((oldValues) => {
                        const newImages = [...oldValues.images];
                        newImages.splice(index, 1);
                        return { ...oldValues, images: newImages };
                      })
                    }
                  />
                ))}
                <FacebookImageUpload
                  accept={!isVideoAd ? ".jpg, .png" : ".mp4, .mov, .gif"}
                  cropType="feed"
                  onLoad={onFileLoad}
                />
              </Grid>
            )}
            {!isCarouselAd && (
              <>
                <FormControl isRequired>
                  <FormLabel>Feed Placement</FormLabel>
                  <FacebookImageUpload
                    image={adValues.images?.[0]}
                    accept=".jpg, .png"
                    width={150}
                    height={150}
                    cropType="feed"
                    onLoad={(...params) => onFileLoad(...params, 0)}
                    swapButton
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Story Placement</FormLabel>
                  <FacebookImageUpload
                    image={adValues.images?.[1]}
                    accept=".jpg, .png"
                    width={150}
                    height={267}
                    cropType="story"
                    onLoad={(...params) => onFileLoad(...params, 1)}
                    swapButton
                  />
                </FormControl>
              </>
            )}
          </HStack>
        )}

        {isVideoAd && (
          <HStack alignItems="flex-start">
            <FacebookImageUpload
              accept=".mp4, .mov, .gif"
              onLoad={onFileLoad}
              variant="button"
            />
            {videoSrc.url && <Text>Selected video: {videoSrc.filename}</Text>}
            {videoSrc.url && (
              <video
                ref={videoRef}
                playsInline
                onLoadedMetadata={onloadedmetadata}
                onSeeked={onseeked}
                width={150}
              >
                <source type="video/mp4" id="videopreview" src={videoSrc.url} />
              </video>
            )}
          </HStack>
        )}
        <Text>Recommended image dimensions: 1080 x 1080</Text>
        <Text>Minimum width: 600px</Text>
        <Text>Max file size: {!isVideoAd ? "30mb" : "4gb"}</Text>
        <Text>
          {!isVideoAd
            ? "File types: .jpg, .png"
            : "File types: .mp4, .mov, .gif"}
        </Text>
        <Flex
          bg="white"
          flexDir="row"
          justifyContent="space-between"
          position="fixed"
          bottom={10}
          right={10}
          zIndex={1}
        >
          <Button onClick={previousStep} mr={5}>
            Back
          </Button>
          <Button
            type="submit"
            isDisabled={!adValues.images?.length && !adValues.video}
          >
            Preview
          </Button>
        </Flex>
      </form>
    </Box>
  );
}

function PreviewStep({
  campaignValues,
  adsetValues,
  adValues,
  fbIntegration,
  zipcodesFromSelection,
  isActive,
  accessToken,
  creative,
  setCreative,
  publishing,
  setPublishing,
  creativePreview,
  setCreativePreview,
  setInstagramCreativePreview,
  instagramCreativePreview,
  previousStep,
  zipcodes,
  useStores,
  storesFromSelection,
  onClose,
  selectedPlatforms,
  isVideoAd,
  isCarouselAd,
  zipcodeLabel,
  storeLabel,
}) {
  const [previewPlacement, setPreviewPlacement] = useState("feed");
  const [videoProgress, setVideoProgress] = useState();
  const [imagesResult, setImagesResult] = useState();
  const [videoResult, setVideoResult] = useState();
  const items = useStores ? storesFromSelection : zipcodes;
  const itemEntityName = useStores ? storeLabel : zipcodeLabel;

  const checkVideoProgress = useCallback(
    async (videoId) => {
      return new Promise(async (resolve, reject) => {
        const adVideo = await getAdVideo(videoId, accessToken);
        if (adVideo.status?.video_status === "processing") {
          setVideoProgress(adVideo.status.processing_progress);
          const innerAdVideo = await checkVideoProgress(videoId);
          resolve(innerAdVideo);
        } else if (adVideo.status?.video_status === "ready") {
          setVideoProgress();
          resolve(adVideo);
        } else {
          reject(adVideo);
        }
      });
    },
    [accessToken]
  );

  const createCreative = useCallback(
    async (_imagesResult, _videoResult, data) => {
      const accountId = fbIntegration?.selectedAdAccount?.account_id;
      const page_id = fbIntegration?.selectedPage?.id;

      let imageCreative;
      let videoCreative;
      const _adValues = data
        ? {
            ...adValues,
            headline: replacePlaceholders(adValues.headline, data),
            message: replacePlaceholders(adValues.message, data),
            call_to_action_link: replacePlaceholders(
              adValues.call_to_action_link,
              data
            ),
            link: replacePlaceholders(adValues.link, data),
          }
        : adValues;
      if (_adValues.video) {
        const creativeBody = {
          ..._adValues,
          description: _adValues.message,
          image_hash: _imagesResult[0].hash,
          video_id: _videoResult.id,
          page_id,
        };
        if (campaignValues.objective === "LEAD_GENERATION") {
          creativeBody.lead_gen_form_id =
            fbIntegration?.adCampaginDefaults?.creative?.lead_gen_form_id;
        }
        delete creativeBody.images;
        delete creativeBody.video;
        delete creativeBody.caption;
        delete creativeBody.description;

        videoCreative = await postVideoCreative(
          creativeBody,
          accountId,
          accessToken
        );
      } else if (_adValues.images?.length) {
        const creativeBody = {
          ..._adValues,
          description: _adValues.message,
          instagram_actor_id: fbIntegration?.selectedInstagramAccount?.id,
          page_id,
          [_imagesResult.length > 1 ? "child_attachments" : "image_hash"]:
            _imagesResult.length > 1
              ? _imagesResult.map((image) => ({ image_hash: image.hash }))
              : _imagesResult[0].hash,
        };
        if (campaignValues.objective === "LEAD_GENERATION") {
          creativeBody.lead_gen_form_id =
            fbIntegration?.adCampaginDefaults?.creative?.lead_gen_form_id;
        }
        delete creativeBody.images;

        if (!isVideoAd && !isCarouselAd && _imagesResult?.length > 1) {
          creativeBody.asset_feed_spec = {
            images: [
              {
                adlabels: [
                  {
                    name: "feed_image",
                  },
                ],
                hash: _imagesResult[0].hash,
              },
              {
                adlabels: [
                  {
                    name: "story_image",
                  },
                ],
                hash: _imagesResult[1].hash,
              },
            ],
            ad_formats: ["SINGLE_IMAGE"],
            link_urls: [
              {
                website_url: _adValues.link,
                display_url: _adValues.link,
              },
            ],
            call_to_action_types: [_adValues.call_to_action],
            call_to_actions: [
              {
                type: _adValues.call_to_action,
                value:
                  campaignValues.objective === "LEAD_GENERATION"
                    ? {
                        link: _adValues.call_to_action_link,
                        lead_gen_form_id:
                          fbIntegration?.adCampaginDefaults?.creative
                            ?.lead_gen_form_id,
                      }
                    : { link: _adValues.call_to_action_link },
              },
            ],
            titles: [{ text: _adValues.headline }],
            bodies: [{ text: _adValues.message }],
            asset_customization_rules: [
              {
                customization_spec: {
                  publisher_platforms: selectedPlatforms,
                },
                image_label: {
                  name: "feed_image",
                },
              },
              {
                customization_spec: {
                  publisher_platforms: selectedPlatforms,
                },
                image_label: {
                  name: "story_image",
                },
              },
            ],
          };
          if (selectedPlatforms.includes("facebook")) {
            creativeBody.asset_feed_spec.asset_customization_rules[0].customization_spec.facebook_positions =
              ["feed"];
            creativeBody.asset_feed_spec.asset_customization_rules[1].customization_spec.facebook_positions =
              ["story"];
          }
          if (selectedPlatforms.includes("instagram")) {
            creativeBody.asset_feed_spec.asset_customization_rules[0].customization_spec.instagram_positions =
              ["stream"];
            creativeBody.asset_feed_spec.asset_customization_rules[1].customization_spec.instagram_positions =
              ["story"];
          }
        }
        imageCreative = await postImageCreative(
          creativeBody,
          accountId,
          accessToken
        );
      }

      return imageCreative || videoCreative;
    },
    [
      accessToken,
      adValues,
      campaignValues.objective,
      fbIntegration,
      isCarouselAd,
      isVideoAd,
      selectedPlatforms,
    ]
  );
  const replacePlaceholdersInName = (original, zipcode, storeId) =>
    (original || "")
      .replaceAll("{country}", "US")
      .replaceAll("{zipcode}", zipcode)
      .replaceAll("{store_id}", storeId)
      .replaceAll(
        "{start_date}",
        DateTime.fromJSDate(adsetValues.start_time).toLocaleString(
          DateTime.DATE_SHORT
        )
      )
      .replaceAll(
        "{end_date}",
        DateTime.fromJSDate(adsetValues.end_time).toLocaleString(
          DateTime.DATE_SHORT
        )
      );

  const handlePublish = async () => {
    setPublishing(true);
    if (creative) {
      const accountId = fbIntegration?.selectedAdAccount?.account_id;

      const promises = [];

      (useStores ? storesFromSelection : zipcodes)?.forEach((item) => {
        const zipcode = useStores ? item.zipcode : item;
        const zipFromSelection = zipcodesFromSelection.find(
          (searchItem) => searchItem.zipcode === zipcode
        );
        const adset = {
          ...adsetValues,
          zipcode,
          optimization_goal: "LINK_CLICKS",
          name: replacePlaceholdersInName(
            `${adsetValues.name}${useStores ? "_{store_id}" : ""}`,
            zipcode,
            useStores && item.id
          ),
          lifetime_budget: Math.round(
            (+adsetValues.lifetime_budget *
              currencyOffsets[fbIntegration?.selectedAdAccount?.currency]) /
              zipcodes.length
          ),
        };
        adset.publisher_platforms = selectedPlatforms;
        if (campaignValues.objective === "LEAD_GENERATION") {
          adset.promoted_object = { page_id: fbIntegration?.selectedPage?.id };
        }
        const hasPlaceholders =
          adValues.headline.search(/{[^{}]*}/) >= 0 ||
          adValues.message.search(/{[^{}]*}/) >= 0;
        promises.push(
          new Promise(async (resolve) => {
            let _creative = creative;
            if (hasPlaceholders) {
              _creative = await createCreative(
                imagesResult,
                videoResult,
                useStores ? item : zipFromSelection
              );
            }
            const result = await postFullAdCampaign(
              {
                status: "ACTIVE",
                campaign: {
                  ...campaignValues,
                  name: replacePlaceholdersInName(
                    `${campaignValues.name}${useStores ? "_{store_id}" : ""}`,
                    zipcode,
                    useStores && item.id
                  ),
                },
                adset: adset,
                ad: {
                  name: replacePlaceholdersInName(
                    `${adValues.name}${useStores ? "_{store_id}" : ""}`,
                    zipcode,
                    useStores && item.id
                  ),
                  creative_id: _creative.id,
                },
              },
              accountId,
              accessToken
            );
            resolve(result);
          })
        );
      });

      try {
        Promise.all(promises).then((ok) => {
          const success = ok.filter((v) => !!v).length;
          if (success) {
            toast.success(`Created ${success} ads`);
            onClose();
          }
          if (ok.length > success) {
            toast.warn(`Failed to create ${ok.length - success} ads`);
          }
        });
      } catch (error) {
        toast.error(error);
      }
    } else {
      toast.warn("please wait until ad creative is finished");
    }
    setPublishing(false);
  };

  useEffect(() => {
    const preparePreview = async () => {
      let _imagesResult;
      let _videoResult;
      const accountId = fbIntegration?.selectedAdAccount?.account_id;
      const imagePromises = [];
      (adValues.images?.length
        ? adValues.images
        : adValues.video?.image
        ? [adValues.video.image]
        : []
      ).forEach((image) => {
        imagePromises.push(postAdImage(image.file, accountId, accessToken));
      });
      _imagesResult = await Promise.all(imagePromises);
      if (adValues.video) {
        _videoResult = await postAdVideo(
          adValues.video.file,
          accountId,
          accessToken
        );

        _videoResult = await checkVideoProgress(_videoResult.id, accessToken);
      }
      setImagesResult(_imagesResult);
      setVideoResult(_videoResult);
      let exampleDataForPreview;
      const hasPlaceholders =
        adValues.headline.search(/{[^{}]*}/) >= 0 ||
        adValues.message.search(/{[^{}]*}/) >= 0;
      if (hasPlaceholders) {
        exampleDataForPreview = useStores
          ? storesFromSelection[0]
          : zipcodesFromSelection[0];
      }
      const _creative = await createCreative(
        _imagesResult,
        _videoResult,
        exampleDataForPreview
      );
      setCreative(_creative);
      if (selectedPlatforms?.includes("facebook")) {
        const previewResult = await getCreativePreview(
          previewPlacement === "feed"
            ? "DESKTOP_FEED_STANDARD"
            : "FACEBOOK_STORY_MOBILE",
          _creative.id,
          accessToken
        );
        setCreativePreview(previewResult?.iframe_html);
      }
      if (selectedPlatforms?.includes("instagram")) {
        const previewResult = await getCreativePreview(
          previewPlacement === "feed"
            ? "INSTAGRAM_STANDARD"
            : "INSTAGRAM_STORY",
          _creative.id,
          accessToken
        );
        setInstagramCreativePreview(previewResult?.iframe_html);
      }
    };
    if (isActive) {
      preparePreview();
    } else {
      setCreativePreview();
      setInstagramCreativePreview();
    }
  }, [
    accessToken,
    adValues,
    checkVideoProgress,
    fbIntegration,
    createCreative,
    isActive,
    previewPlacement,
    selectedPlatforms,
    setCreative,
    setCreativePreview,
    setInstagramCreativePreview,
    useStores,
    storesFromSelection,
    zipcodesFromSelection,
  ]);

  return (
    <React.Fragment>
      <Flex flexDir="column" flex={1} overflowY="auto">
        {(adValues.images?.length > 1 || !!adValues.video) && (
          <FormControl>
            <FormLabel>Preview placement</FormLabel>
            <Select
              isRequired
              rounded="md"
              value={previewPlacement}
              onChange={(event) => {
                setPreviewPlacement(event.target.value);
                setCreativePreview();
                setInstagramCreativePreview();
              }}
            >
              <option value="feed">Feed</option>
              <option value="story">Story</option>
            </Select>
          </FormControl>
        )}
        {!creativePreview && !instagramCreativePreview && (
          <Button isDisabled>Generating Ad preview...</Button>
        )}
        {typeof videoProgress === "number" && (
          <Text>
            Facebook is processing the video. This might take a few minutes.
          </Text>
        )}
        <HStack w="100%" alignItems="flex-start">
          {creativePreview && (
            <FormControl flex={1}>
              <FormLabel>Facebook preview</FormLabel>
              <Flex
                flex={1}
                justifyContent="center"
                dangerouslySetInnerHTML={{ __html: creativePreview || "" }}
              ></Flex>
            </FormControl>
          )}
          {instagramCreativePreview && (
            <FormControl flex={1}>
              <FormLabel>Instagram preview</FormLabel>
              <Flex
                flex={1}
                justifyContent="center"
                dangerouslySetInnerHTML={{
                  __html: instagramCreativePreview || "",
                }}
              ></Flex>
            </FormControl>
          )}
        </HStack>
        <Flex
          flexDir="row"
          bg="whitesmoke"
          borderRadius={10}
          p={3}
          justifyContent="space-between"
          mb={3}
        >
          <Text fontWeight="bold">Campaign name</Text>
          <Text>{campaignValues.name}</Text>
        </Flex>
        <Flex
          flexDir="row"
          bg="whitesmoke"
          borderRadius={10}
          p={3}
          justifyContent="space-between"
          mb={3}
        >
          <Text fontWeight="bold">Objective</Text>
          <Text>{objectives[campaignValues.objective]?.name}</Text>
        </Flex>
        <Flex
          flexDir="row"
          bg="whitesmoke"
          borderRadius={10}
          p={3}
          justifyContent="space-between"
          mb={3}
        >
          <Text fontWeight="bold">Adset name</Text>
          <Text>{adsetValues.name}</Text>
        </Flex>
        <Flex
          flexDir="row"
          bg="whitesmoke"
          borderRadius={10}
          p={3}
          justifyContent="space-between"
          mb={3}
        >
          <Text fontWeight="bold">Date range</Text>
          <Text>{`${DateTime.fromJSDate(adsetValues.start_time).toLocaleString(
            DateTime.DATETIME_SHORT
          )} - ${
            !adsetValues.end_time
              ? "Indefinite"
              : DateTime.fromJSDate(adsetValues.end_time).toLocaleString(
                  DateTime.DATETIME_SHORT
                )
          }`}</Text>
        </Flex>
        <Flex
          flexDir="row"
          bg="whitesmoke"
          borderRadius={10}
          p={3}
          justifyContent="space-between"
          mb={3}
        >
          <Text fontWeight="bold">
            {adsetValues.daily_budget ? "Daily" : "Lifetime"} budget
          </Text>
          <Flex flexDir="column">
            <Text>
              {adsetValues.daily_budget || adsetValues.lifetime_budget}{" "}
              {fbIntegration?.selectedAdAccount?.currency}
            </Text>
            <Text>
              (
              {Math.round(
                (adsetValues.daily_budget || adsetValues.lifetime_budget || 0) /
                  items?.length
              )}{" "}
              per {pluralize(itemEntityName, 1)})
            </Text>
          </Flex>
        </Flex>
        <Flex
          flexDir="row"
          bg="whitesmoke"
          borderRadius={10}
          p={3}
          justifyContent="space-between"
          mb={3}
        >
          <Text fontWeight="bold">Geotargeting</Text>
          <Flex flexDir="column">
            <Text>Country</Text>
            <Text>{adsetValues.country}</Text>
          </Flex>
          <Flex flexDir="column">
            <Text>{pluralize(itemEntityName, 2)}</Text>
            {items?.map((item, index) => (
              <Tag key={index} borderRadius="full" mr={1} mb={2}>
                <TagLabel>{useStores ? item.id : item}</TagLabel>
              </Tag>
            ))}
          </Flex>
        </Flex>
        <Flex
          flexDir="row"
          bg="whitesmoke"
          borderRadius={10}
          p={3}
          justifyContent="space-between"
          mb={3}
        >
          <Text fontWeight="bold">Billing event</Text>
          <Text>{billingEvents[adsetValues.billing_event]}</Text>
        </Flex>
        <Flex
          flexDir="row"
          bg="whitesmoke"
          borderRadius={10}
          p={3}
          justifyContent="space-between"
          mb={3}
        >
          <Text fontWeight="bold">Ad name</Text>
          <Text>{adValues.name}</Text>
        </Flex>
        <Flex
          flexDir="row"
          bg="whitesmoke"
          borderRadius={10}
          p={3}
          justifyContent="space-between"
          mb={3}
        >
          <Text fontWeight="bold">Description</Text>
          <Text>{adValues.description}</Text>
        </Flex>
      </Flex>
      <Flex
        bg="white"
        flexDir="row"
        justifyContent="space-between"
        position="fixed"
        bottom={10}
        right={10}
        zIndex={1}
      >
        <Button onClick={previousStep} mr={5}>
          Back
        </Button>
        <Button
          onClick={handlePublish}
          isLoading={publishing}
          isDisabled={!creativePreview && !instagramCreativePreview}
          colorScheme="blue"
        >
          Publish
        </Button>
      </Flex>
    </React.Fragment>
  );
}

function FacebookCampaignCreation(props) {
  const {
    zipcodesFromSelection,
    onClose,
    fbIntegration,
    defaultValues = {},
    accessToken,
    zipcodes = zipcodesFromSelection.map((zip) => zip.zipcode),
    useStores,
    storesFromSelection,
    storeLocatorUrl,
    orgConfig,
  } = props;

  const zipcodeLabel = orgConfig?.zipcodeNameReplacement || "Zipcode";
  const storeLabel = orgConfig?.storeNameReplacement || "Store";
  const items = useStores ? storesFromSelection : zipcodes;
  const itemEntityName = useStores ? storeLabel : zipcodeLabel;
  const [isCarouselAd, setIsCarouselAd] = useState(false);
  const [isVideoAd, setIsVideoAd] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [creativePreview, setCreativePreview] = useState();
  const [instagramCreativePreview, setInstagramCreativePreview] = useState();
  const [campaignValues, setCampaignValues] = useState(
    fbIntegration?.adCampaginDefaults?.campaign || {}
  );
  const [adsetValues, setAdsetValues] = useState({
    ...(fbIntegration?.adCampaginDefaults?.adset || {
      country: "US",
      billing_event: "IMPRESSIONS",
    }),
    start_time: DateTime.now()
      .plus({ day: 1 })
      .set({ hour: 8, minute: 0 })
      .toJSDate(),
    end_time: DateTime.now()
      .plus({ day: 1 })
      .set({ hour: 8, minute: 0 })
      .plus({ days: fbIntegration?.adCampaginDefaults?.number_days || 1 })
      .toJSDate(),
  });
  const [selectedPlatforms, setSelectedPlatforms] = useState(["facebook"]);
  const [adValues, setAdValues] = useState({
    ...(defaultValues.adValues || { call_to_action: "BUY_NOW" }),
    ...(fbIntegration?.adCampaginDefaults?.ad || {
      name: `dathic_{country}_{zipcode}_{start_date}_{end_date}`,
    }),
    call_to_action:
      fbIntegration?.adCampaginDefaults?.creative?.call_to_action ||
      fbIntegration?.creatives?.[0]?.call_to_action_type,
    description:
      fbIntegration?.creatives?.[0]?.object_story_spec?.link_data?.description,
    message: (
      fbIntegration?.creatives?.[0]?.object_story_spec?.link_data ||
      fbIntegration?.creatives?.[0]?.object_story_spec?.video_data
    )?.message,
    headline: fbIntegration?.creatives?.[0]?.object_story_spec?.link_data?.name,
    caption:
      fbIntegration?.creatives?.[0]?.object_story_spec?.link_data?.caption,
    link:
      fbIntegration?.creatives?.[0]?.object_story_spec?.link_data?.link ||
      fbIntegration?.creatives?.[0]?.object_story_spec?.video_data?.image_url,
    call_to_action_link: (
      fbIntegration?.creatives?.[0]?.object_story_spec?.link_data ||
      fbIntegration?.creatives?.[0]?.object_story_spec?.video_data
    )?.call_to_action.value.link,
  });

  const [creative, setCreative] = useState(fbIntegration?.creatives?.[0]);

  const minimumBudget = () =>
    10 *
    (fbIntegration?.selectedAdAccount?.min_daily_budget /
      currencyOffsets[fbIntegration?.selectedAdAccount?.currency]) *
    (items?.length || 0) *
    Math.ceil(
      Interval.fromDateTimes(
        adsetValues.start_time,
        adsetValues.end_time
      ).length("weeks")
    );

  const DateInput = ({ value, onClick }) => {
    return (
      <Button onClick={onClick} variant="outline" bg="white">
        {`${DateTime.fromJSDate(adsetValues.start_time).toLocaleString(
          DateTime.DATE_SHORT
        )} - ${DateTime.fromJSDate(adsetValues.end_time).toLocaleString(
          DateTime.DATE_SHORT
        )}`}
      </Button>
    );
  };

  const isBudgetValid = (budget) => budget > minimumBudget();

  const getInitialWizardStep = () => {
    if (!fbIntegration?.adCampaginDefaults) {
      return 0;
    }
    const campaignStepValid = campaignValues && campaignValues.objective;
    const adStepValid =
      adValues &&
      adValues.headline &&
      adValues.message &&
      adValues.caption &&
      adValues.link &&
      adValues.call_to_action &&
      adValues.call_to_action_link &&
      (adValues.video || adValues.images?.length);
    if (adStepValid && campaignStepValid) {
      return 2;
    }
    if (campaignStepValid) {
      return 1;
    }
    return 0;
  };
  return (
    <Flex flexDir="column" h="100%" position="relative">
      <Text fontWeight="bold" fontSize={32}>
        Campaign Creation
      </Text>
      {fbIntegration?.adCampaginDefaults?.campaign?.objective &&
        objectives[fbIntegration.adCampaginDefaults.campaign.objective] && (
          <Text fontSize={14}>
            Optimized for:{" "}
            {
              objectives[fbIntegration.adCampaginDefaults.campaign.objective]
                ?.name
            }
          </Text>
        )}
      <HStack mb={3}>
        <FacebookPageSelect />
      </HStack>
      <form>
        <Flex flexDir="column" borderRadius={10} bg="#f1f3f6" p={5}>
          <Flex flexDir="row" justifyContent="space-between">
            <FormControl isRequired w={800} mr={10}>
              <FormLabel>Total budget</FormLabel>
              <FormHelperText mb={3}>
                Maximum amount you'll spend on this campaigns
              </FormHelperText>
              <NumberInput
                value={adsetValues.lifetime_budget}
                pattern="-?[0-9]+(.[0-9]+)?"
                onChange={(newValue) => {
                  setAdsetValues((oldValues) => ({
                    ...oldValues,
                    lifetime_budget: newValue,
                    end_time:
                      oldValues.end_time ||
                      DateTime.fromJSDate(oldValues.start_time || new Date())
                        .plus({ day: 1 })
                        .toJSDate(),
                  }));
                }}
              >
                <Box position="relative">
                  <NumberInputField bg="white" />
                  <Tag
                    borderRadius={10}
                    position="absolute"
                    right={1}
                    top={1}
                    bottom={1}
                    zIndex={99}
                  >
                    <TagLabel>
                      {fbIntegration?.selectedAdAccount?.currency}
                    </TagLabel>
                  </Tag>
                </Box>
              </NumberInput>
              <FormHelperText>
                (
                {Math.round((adsetValues.lifetime_budget || 0) / items?.length)}{" "}
                {fbIntegration?.selectedAdAccount?.currency} per{" "}
                {pluralize(itemEntityName, 1)})
              </FormHelperText>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Dates</FormLabel>
              <FormHelperText mb={3}>
                Start and end date for the campaign
              </FormHelperText>
              <DatePicker
                selected={adsetValues.start_time}
                onChange={(dates) => {
                  const [start, end] = dates;
                  setAdsetValues((oldValues) => ({
                    ...oldValues,
                    start_time: start,
                    end_time: end,
                  }));
                }}
                startDate={adsetValues.start_time}
                endDate={adsetValues.end_time}
                selectsRange
                customInput={<DateInput />}
                shouldCloseOnSelect={false}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                minDate={new Date()}
                popperModifiers={{
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: "viewport",
                  },
                  applyStyles: { enabled: true },
                }}
              />
            </FormControl>
          </Flex>
          <FormControl isRequired mt={5}>
            <FormLabel>Platforms</FormLabel>
            <FormHelperText mb={3}>
              {fbIntegration?.instagramAccounts?.length
                ? "Select the platform(s) through which you'd like to send ads"
                : "Your selected facebook page is not associated with any instagram account. Please associate a facebook page to your instagram account to enable Instagram Ads. Feel free to contact us if you have any issues"}
            </FormHelperText>
            <CheckboxGroup
              colorScheme="green"
              value={selectedPlatforms}
              onChange={setSelectedPlatforms}
            >
              <HStack>
                <Checkbox value="facebook">Facebook</Checkbox>
                {fbIntegration?.instagramAccounts?.length && (
                  <Checkbox value="instagram">Instagram</Checkbox>
                )}
                {fbIntegration?.instagramAccounts?.length &&
                  selectedPlatforms.includes("instagram") && (
                    <InstagramAccountSelect />
                  )}
              </HStack>
            </CheckboxGroup>
          </FormControl>
        </Flex>
      </form>
      {!(
        adsetValues?.start_time &&
        adsetValues?.end_time &&
        adsetValues?.lifetime_budget
      ) && (
        <Text p={10} border="1px solid #245" alignSelf="center" mt={5}>
          Please choose total budget and dates to continue
        </Text>
      )}
      {adsetValues?.lifetime_budget &&
        !isBudgetValid(adsetValues?.lifetime_budget) && (
          <Box
            p={10}
            border="1px solid tomato"
            alignSelf="center"
            color="tomato"
            mt={5}
            textAlign="center"
          >
            <Text fontWeight="bold">
              Budget must be more than $
              {minimumBudget().toLocaleString(undefined, {
                currency: fbIntegration?.selectedAdAccount?.currency,
              })}{" "}
              for a total of {items?.length || 0} {itemEntityName}
            </Text>
            <Text>
              Minimum of $
              {(
                10 *
                (fbIntegration?.selectedAdAccount?.min_daily_budget /
                  currencyOffsets[fbIntegration?.selectedAdAccount?.currency])
              ).toLocaleString(undefined, {
                currency: fbIntegration?.selectedAdAccount?.currency,
              })}{" "}
              per {pluralize(zipcodeLabel, 1)} per week.
            </Text>
          </Box>
        )}
      {adsetValues?.start_time &&
        adsetValues?.end_time &&
        adsetValues?.lifetime_budget &&
        isBudgetValid(adsetValues?.lifetime_budget) && (
          <StepWizard initialStep={getInitialWizardStep()} nav={<Nav />}>
            <CampaignStep
              campaignValues={campaignValues}
              setCampaignValues={setCampaignValues}
              fbIntegration={fbIntegration}
              accessToken={accessToken}
            />
            <AdStep
              adValues={adValues}
              setAdValues={setAdValues}
              setCreative={setCreative}
              selectedPlatforms={selectedPlatforms}
              fbIntegration={fbIntegration}
              isVideoAd={isVideoAd}
              setIsVideoAd={setIsVideoAd}
              isCarouselAd={isCarouselAd}
              setIsCarouselAd={setIsCarouselAd}
              useStores={useStores}
              storesFromSelection={storesFromSelection}
              zipcodesFromSelection={zipcodesFromSelection}
              storeLocatorUrl={storeLocatorUrl}
              storeLabel={storeLabel}
            />
            <PreviewStep
              campaignValues={campaignValues}
              adsetValues={adsetValues}
              adValues={adValues}
              fbIntegration={fbIntegration}
              onClose={onClose}
              accessToken={accessToken}
              creative={creative}
              setCreative={setCreative}
              publishing={publishing}
              setPublishing={setPublishing}
              setCreativePreview={setCreativePreview}
              setInstagramCreativePreview={setInstagramCreativePreview}
              creativePreview={creativePreview}
              instagramCreativePreview={instagramCreativePreview}
              zipcodes={zipcodes}
              selectedPlatforms={selectedPlatforms}
              isVideoAd={isVideoAd}
              isCarouselAd={isCarouselAd}
              useStores={useStores}
              storesFromSelection={storesFromSelection}
              zipcodesFromSelection={zipcodesFromSelection}
              zipcodeLabel={zipcodeLabel}
              storeLabel={storeLabel}
            />
          </StepWizard>
        )}
    </Flex>
  );
}

export default connect((state) => ({
  accessToken: state.app.accessToken,
  fbIntegration: state.integrations.activeIntegrations?.facebook,
  zipcodesFromSelection:
    state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
      ?.selection?.zipcodes || [],
  storesFromSelection:
    state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
      ?.selection?.stores,
  storeLocatorUrl: state.app.orgProperties?.properties?.storeLocatorUrl,
  orgConfig: state.app.orgProperties?.properties,
}))(FacebookCampaignCreation);
