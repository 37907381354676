import { CloseIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Collapse,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Spinner,
  Switch,
  Tag,
  TagLabel,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import pluralize from "pluralize";
import { useContext, useEffect, useMemo, useState } from "react";
import { GiGlassShot, GiHazardSign, GiThermometerHot } from "react-icons/gi";
import { connect } from "react-redux";
import AutocompleteInput from "../../components/AutocompleteInput";
import CheckboxGridInput from "../../components/CheckboxGridInput";
import InfoPopover from "../../components/InfoPopover";
import { RESOURCES } from "../../constants/user-constants";
import MessageModalContext from "../../contexts/MessageModalContext";
import {
  getDeliveryEntities,
  getDeliveryProducts,
} from "../../services/api.service";
import { getInsights } from "../../services/insights.service";
import { camelCaseWords } from "../../utils/stringUtils";
import { showDeliveryEntites } from "../delivery/deliverySlice";
import InsightsSelector from "../insights/InsightsSelector";
import { setIsSelectingOnMap } from "../map-selection/mapSelectionSlice";
import { selectLoadedZipcodes } from "../map/keplerReducer";

function SelectionGroup({
  groupKey,
  group,
  accessToken,
  orgProperties,
  setGroups,
}) {
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [searchFormValues, setSearchFormValues] = useState();

  useEffect(() => {
    if (group.items[0]?.type === "brand") {
      setGroups((oldValue) => {
        const newGroups = [...oldValue];
        newGroups[groupKey].items = [];
        return newGroups;
      });
      setLoadingProducts(true);
      getDeliveryProducts(
        accessToken,
        undefined,
        group.name,
        orgProperties?.properties?.country,
        null
      )
        .then((brandProducts) => {
          setGroups((oldValue) => {
            const newGroups = [...oldValue];
            newGroups[groupKey].items = brandProducts;
            return newGroups;
          });
        })
        .finally(() => {
          setLoadingProducts(false);
        });
    }
  }, [accessToken, group, groupKey, orgProperties, setGroups]);

  useEffect(() => {
    const selectedItems = Object.values(searchFormValues || {})
      .flat(1)
      .filter((val) => !!val);
    if (selectedItems?.length) {
      setGroups((oldValue) => {
        const newGroups = [...oldValue];
        newGroups[groupKey].items = selectedItems;
        return newGroups;
      });
      setSearchFormValues({ search: undefined });
    }
  }, [groupKey, searchFormValues, setGroups]);

  const removeProduct = (prod) => {
    setGroups((oldValue) => {
      const newItems = [...(oldValue?.[groupKey]?.items || [])];
      newItems.splice(
        newItems.findIndex((item) => item.uid === prod.uid),
        1
      );
      const newGroups = [...oldValue];
      newGroups[groupKey].items = newItems;
      return newGroups;
    });
  };

  const changeGroupName = (name) => {
    setGroups((oldValue) => {
      const newGroups = [...oldValue];
      newGroups[groupKey] = { ...newGroups[groupKey], name };
      return newGroups;
    });
  };

  return (
    <VStack
      bg="whitesmoke"
      p={3}
      rounded="lg"
      shadow="base"
      pos="relative"
      flex={1}
    >
      <IconButton
        pos="absolute"
        top={3}
        right={0}
        variant="link"
        icon={<CloseIcon />}
        onClick={() => {
          setGroups((oldValue) => {
            const newGroups = [...oldValue];
            newGroups.splice(groupKey, 1);
            return newGroups;
          });
        }}
      />
      <Editable defaultValue={group.name} onSubmit={changeGroupName}>
        <EditableInput />
        <EditablePreview fontWeight="bold" />
      </Editable>
      <AutocompleteInput
        containerProps={{ width: "100%", marginTop: 0, marginBottom: 3 }}
        inputProps={{ placeholder: "Add products" }}
        getSuggestionValue={(suggestion) =>
          suggestion.product_name || suggestion.product_brand
        }
        fetchSuggestions={async (searchText) => {
          const nameResults = await getDeliveryProducts(
            accessToken,
            searchText,
            undefined,
            orgProperties?.properties?.country
          );
          const suggestedProducts = nameResults || [];
          return suggestedProducts.reduce((acc, res) => {
            return [
              ...acc,
              ...(acc.find((v) => v.uid === res.uid) ? [] : [res]),
            ];
          }, []);
        }}
        renderSuggestion={(suggestion, { isHighlighted }) => (
          <Box
            cursor="pointer"
            backgroundColor={isHighlighted ? "#ddd" : "white"}
            padding={2}
          >
            <Text>
              {suggestion.type === "brand"
                ? suggestion.product_brand
                : suggestion.product_name}
            </Text>
            {suggestion.type && (
              <Text fontSize={12}>{camelCaseWords(suggestion.type)}</Text>
            )}
          </Box>
        )}
        handleSearchResultClick={(selection) => {
          setGroups((oldValue) => {
            const newItems = [
              selection.suggestion,
              ...(oldValue?.[groupKey]?.items || []),
            ];
            const newGroups = [...oldValue];
            newGroups[groupKey].items = newItems;
            return newGroups;
          });
        }}
      />
      {loadingProducts && <Spinner />}
      {!loadingProducts && group.type === "brand" && (
        <Box p={5} w="100%" shadow="base" bg="white">
          <Text fontSize={12}>
            {pluralize("product", group?.items?.length, true)} selected
          </Text>
        </Box>
      )}
      {!loadingProducts &&
        group.type !== "brand" &&
        group?.items?.map((prod) => (
          <Box
            key={prod.uid}
            p={5}
            w="100%"
            shadow="base"
            bg="white"
            position="relative"
          >
            {group.items.length > 1 && group.type !== "brand" && (
              <IconButton
                icon={<CloseIcon />}
                position="absolute"
                top={0}
                right={0}
                onClick={() => removeProduct(prod)}
              />
            )}
            <Flex flexDir="row" justify="space-between">
              <Box>
                <Text fontSize={12}>
                  {prod.type !== "brand" ? prod.product_brand : "Brand"}
                </Text>
                <Text fontWeight="bold">
                  {prod.product_name || prod.product_brand}
                </Text>
                {!prod.type && <Text>{prod.product_category?.join(", ")}</Text>}
              </Box>
            </Flex>
            {!prod.type && <ProductInfo prod={prod} />}
          </Box>
        ))}
    </VStack>
  );
}

function ProductInfo({ prod }) {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      {!isOpen && (
        <Button float="right" onClick={onToggle}>
          More info
        </Button>
      )}
      <Collapse in={isOpen} animateOpacity>
        <Box
          p={3}
          mt={3}
          rounded="md"
          bg="whitesmoke"
          shadow="md"
          pos="relative"
        >
          <IconButton
            onClick={onToggle}
            pos="absolute"
            right={3}
            top={3}
            icon={<CloseIcon />}
          />
          <HStack>
            {prod.alcohol_flag && (
              <InfoPopover
                popoverProps={{ placement: "right" }}
                triggerContent={<GiGlassShot size={32} />}
                info="Contains alcohol"
              />
            )}
            {prod.hazmat_flag && (
              <InfoPopover
                popoverProps={{ placement: "right" }}
                triggerContent={<GiHazardSign size={32} />}
                info="Hazardous"
              />
            )}
            {prod.heat_sensitive && (
              <InfoPopover
                popoverProps={{ placement: "right" }}
                triggerContent={<GiThermometerHot size={32} />}
                info="Heat sensitive"
              />
            )}
          </HStack>
          <Text mt={5}>{prod.product_features?.join(", ")}</Text>
          <Text mt={5}>{prod.product_description}</Text>
          <Box rounded="md" shadow="sm">
            <Text mt={5} fontWeight="bold">
              Nutrition
            </Text>
            <HStack>
              <Tag colorScheme="blue">
                <TagLabel>Rating: {prod.nutritional_rating}</TagLabel>
              </Tag>
              {Object.keys(prod)
                .filter(
                  (key) => key.includes("nutrition") && prod[key] === true
                )
                .map((key) => (
                  <Tag>
                    <TagLabel>
                      {camelCaseWords(key.split("nutrition_")[1])}
                    </TagLabel>
                  </Tag>
                ))}
            </HStack>
            <Text>Allergens: {prod.nutrition_allergens}</Text>
          </Box>
        </Box>
      </Collapse>
    </>
  );
}

const _ProductSearch = (props) => {
  const {
    userResources,
    accessToken,
    orgProperties,
    showDeliveryEntites,
    loadingStores,
    onClose,
    loadedDemographics,
    mapSelection,
    setIsSelecting,
  } = props;
  const [shouldCreateLayer, setShouldCreateLayer] = useState(false);
  const [formValues, setFormValues] = useState();
  const [advancedFormValues, setAdvancedFormValues] = useState();
  const [insightsToColorBy, setInsightsToColorBy] = useState();
  const [groups, setGroups] = useState();
  const [deliveryEntities, setDeliveryEntities] = useState([]);
  const [loadingEntities, setLoadingEntities] = useState(false);
  const [useMapSelection, setUseMapSelection] = useState(false);
  const [useProductAvailable, setUseProductAvailable] = useState(false);
  const [onlyWhereAvailable, setOnlyWhereAvailable] = useState(true);
  const [entityInsightOptions, setEntityInsightOptions] = useState();
  const [productInsightOptions, setProductInsightOptions] = useState();
  const modalsContext = useContext(MessageModalContext);
  const zipcodeLabel =
    orgProperties?.properties?.zipcodeNameReplacement || "Zipcode";
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";
  const country = useMemo(
    () => orgProperties?.properties?.country,
    [orgProperties]
  );

  useEffect(() => {
    const products = (groups || [])
      .flatMap((val) => val.items)
      .filter((val) => !!val?.uid);
    if (loadedDemographics?.length && products?.length) {
      const insightParams = entityInsightOptions
        ?.filter((op) =>
          Object.keys(advancedFormValues?.insights || {})
            .filter((key) => !!advancedFormValues.insights[key])
            .includes(`${op.id}`)
        )
        ?.reduce((acc, op) => {
          const queryParams = op.endpoint
            ?.split("?")?.[1]
            ?.split("&")
            .filter((param) => param.startsWith("q"))
            .map((param) => param.split("=")[1]);
          return [...new Set([...acc, ...(queryParams || [])])];
        }, []);
      setLoadingEntities(true);
      getDeliveryEntities(
        accessToken,
        undefined,
        undefined,
        country,
        undefined,
        undefined,
        loadedDemographics?.map((demo) => demo.zipcode),
        undefined,
        undefined,
        insightParams,
        products.map((p) => p.uid)
      )
        .then(setDeliveryEntities)
        .finally(() => setLoadingEntities(false));
    }
  }, [
    accessToken,
    advancedFormValues,
    entityInsightOptions,
    loadedDemographics,
    country,
    groups,
  ]);

  useEffect(() => {
    getInsights(accessToken, { level: "delivery_entity" }, "spec").then(
      setEntityInsightOptions
    );
    getInsights(accessToken, { level: "delivery_product" }, "spec").then(
      setProductInsightOptions
    );
  }, [accessToken]);

  useEffect(() => {
    const selectedItems = Object.values(formValues || {})
      .flat(1)
      .filter((val) => !!val);
    if (selectedItems?.length) {
      setGroups((oldValue) => {
        const groupName =
          selectedItems[0].product_name || selectedItems[0].product_brand;
        return [
          ...(oldValue || []),
          {
            type: selectedItems[0].type || "product",
            items: [...selectedItems],
            name: groupName,
          },
        ];
      });
      setFormValues({ search: undefined });
    }
  }, [formValues]);

  useEffect(() => {
    const groupsStr = localStorage.getItem("savedProductGroups");
    if (groupsStr) {
      try {
        const _groups = JSON.parse(groupsStr);
        setGroups(_groups || []);
      } catch (e) {}
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("savedProductGroups", JSON.stringify(groups || []));
  }, [groups]);

  return (
    <VStack spacing={5}>
      <Text>Select any products you'd like to get insights on.</Text>
      <HStack divider={<Text m={3}>vs</Text>} w="100%" alignItems="flex-start">
        {(groups || []).map((group, index) => (
          <SelectionGroup
            key={index}
            groupKey={index}
            group={group}
            setGroups={setGroups}
            accessToken={accessToken}
            orgProperties={orgProperties}
          />
        ))}
        {(!groups || groups?.length < 2) && (
          <FormControl flex={1}>
            {!groups?.length && (
              <FormLabel>Search products or brands</FormLabel>
            )}
            <AutocompleteInput
              disabled={groups?.length > 1}
              inputProps={{ placeholder: "Products or brands" }}
              getSuggestionValue={(suggestion) =>
                suggestion.product_name || suggestion.product_brand
              }
              fetchSuggestions={async (searchText) => {
                const brandResults = await getDeliveryProducts(
                  accessToken,
                  undefined,
                  searchText,
                  orgProperties?.properties?.country
                );
                const nameResults = await getDeliveryProducts(
                  accessToken,
                  searchText,
                  undefined,
                  orgProperties?.properties?.country
                );
                const suggestedProducts = [
                  ...(nameResults || []),
                  ...(brandResults || []),
                ];
                return suggestedProducts.reduce((acc, res) => {
                  const foundBrand =
                    !res.product_brand ||
                    acc.find(
                      (v) =>
                        v.type === "brand" &&
                        v.product_brand === res.product_brand
                    );
                  return [
                    ...(foundBrand
                      ? []
                      : [{ type: "brand", product_brand: res.product_brand }]),
                    ...acc,
                    ...(acc.find((v) => v.uid === res.uid) ? [] : [res]),
                  ];
                }, []);
              }}
              renderSuggestion={(suggestion, { isHighlighted }) => (
                <Box
                  cursor="pointer"
                  backgroundColor={isHighlighted ? "#ddd" : "white"}
                  padding={2}
                >
                  <Text>
                    {suggestion.type === "brand"
                      ? suggestion.product_brand
                      : suggestion.product_name}
                  </Text>
                  {suggestion.type && (
                    <Text fontSize={12}>{camelCaseWords(suggestion.type)}</Text>
                  )}
                </Box>
              )}
              handleSearchResultClick={(selection) => {
                setFormValues((oldValues) => ({
                  ...oldValues,
                  search: [...(oldValues?.search || []), selection.suggestion],
                }));
              }}
            />
          </FormControl>
        )}
      </HStack>
      <Accordion w="100%" allowToggle defaultIndex={0}>
        <AccordionItem>
          <AccordionButton fontSize={18} fontWeight="bold">
            Advanced options
            <AccordionIcon ml="auto" />
          </AccordionButton>
          <AccordionPanel>
            <VStack spacing={5}>
              <Flex justify="space-between" alignItems="center" w="100%">
                <Box>
                  <Switch
                    isChecked={!!useMapSelection}
                    onChange={() => setUseMapSelection((oldValue) => !oldValue)}
                  >
                    Use {pluralize(zipcodeLabel, 1)} map selection
                  </Switch>
                  <Text fontSize={12} color="darkgray">
                    {useMapSelection
                      ? `${pluralize(
                          storeLabel
                        )} will be searched in the ${pluralize(
                          zipcodeLabel,
                          2
                        )} you selected on the map`
                      : `${pluralize(
                          storeLabel
                        )} will be searched in all of the ${pluralize(
                          zipcodeLabel,
                          2
                        )} you have on the map`}
                  </Text>
                </Box>
                {!mapSelection?.zipcodes?.length && useMapSelection && (
                  <Button onClick={() => setIsSelecting()}>
                    Select on map
                  </Button>
                )}
              </Flex>
              <Flex justify="space-between" alignItems="center" w="100%">
                <Box>
                  <Switch
                    isChecked={!!useProductAvailable}
                    onChange={() =>
                      setUseProductAvailable((oldValue) => !oldValue)
                    }
                  >
                    Use product availability
                  </Switch>
                  <Text fontSize={12} color="darkgray">
                    {useProductAvailable
                      ? `Search will consider the availability of the product`
                      : `Product availability will not be considered`}
                  </Text>
                </Box>
                {useProductAvailable && (
                  <Box>
                    <Switch
                      isChecked={!!onlyWhereAvailable}
                      onChange={() =>
                        setOnlyWhereAvailable((oldValue) => !oldValue)
                      }
                    >
                      Only where products are available
                    </Switch>
                    <Text fontSize={12} color="darkgray">
                      {onlyWhereAvailable
                        ? `${pluralize(
                            storeLabel
                          )} will be searched where products are available`
                        : `${pluralize(
                            storeLabel
                          )} will be searched where products are not available`}
                    </Text>
                  </Box>
                )}
              </Flex>
              <FormControl>
                <FormLabel>Add {storeLabel} insights</FormLabel>
                <InsightsSelector
                  insightOptions={entityInsightOptions}
                  value={advancedFormValues?.insights}
                  onChange={(value) =>
                    setAdvancedFormValues((oldValues) => ({
                      ...oldValues,
                      insights: value,
                    }))
                  }
                />
              </FormControl>
              <FormControl isDisabled>
                <FormLabel>Add product insights</FormLabel>
                <InsightsSelector
                  insightOptions={productInsightOptions}
                  value={advancedFormValues?.insights}
                  onChange={(value) =>
                    setAdvancedFormValues((oldValues) => ({
                      ...oldValues,
                      insights: value,
                    }))
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Color by</FormLabel>
                <CheckboxGridInput
                  value={insightsToColorBy}
                  name={"insightsColorBy"}
                  isSingleSelection
                  options={Object.keys(
                    deliveryEntities?.find((ent) => !!ent.insights?.length)
                      ?.insights?.[0] || {}
                  ).map((key) => ({
                    label: camelCaseWords(key),
                    value: key,
                    options: Object.keys(
                      deliveryEntities.find((ent) => !!ent.insights?.length)
                        .insights[0][key]
                    ).map((innerkey) => ({
                      key: innerkey,
                      label: camelCaseWords(innerkey),
                    })),
                  }))}
                  onChange={setInsightsToColorBy}
                />
              </FormControl>
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Flex
        direction="row"
        justifyContent="space-between"
        position="absolute"
        right={0}
        bottom={0}
        bg="white"
        p={5}
        w="100%"
        borderTop="1px solid gainsboro"
        zIndex={9}
      >
        <Box>
          <Switch
            isChecked={!!shouldCreateLayer}
            onChange={() => setShouldCreateLayer((oldValue) => !oldValue)}
          >
            Create new layer
          </Switch>
          <Text fontSize={12} color="darkgray">
            {shouldCreateLayer
              ? "Your current product search layers will stay the same"
              : "Your current product search layers will be replaced with this search"}
          </Text>
        </Box>
        <Flex flexDir="column" alignItems="flex-end">
          <Button
            colorScheme="blue"
            isLoading={loadingStores}
            disabled={
              loadingEntities ||
              loadingStores ||
              !(groups || []).flatMap((val) => val.items).filter((val) => !!val)
                .length ||
              !userResources?.includes(RESOURCES.PRODUCTS_DISCOVER)
            }
            ml={3}
            onClick={async () => {
              const wrapped = await showDeliveryEntites({
                deliveryEntities,
                shouldCreateLayer,
                groups,
                onlyWhereAvailable: useProductAvailable
                  ? onlyWhereAvailable
                  : undefined,
                colorBy: Object.keys(insightsToColorBy || {})
                  .filter((key) => !!insightsToColorBy[key])
                  .map((key) =>
                    typeof insightsToColorBy[key] === "string"
                      ? insightsToColorBy[key]
                      : key
                  )?.[0],
              });
              const unwrapped = unwrapResult(wrapped);

              if (!unwrapped?.length) {
                modalsContext.showModal({
                  title: `No ${pluralize(storeLabel)} found`,
                  message: `There are no ${pluralize(
                    storeLabel
                  )} with these products in your selected location. If you would like to suggest a location, you can contact us`,
                  actions: [
                    {
                      label: `Suggest ${storeLabel}`,
                      callback: () => {
                        window.open(
                          "https://calendly.com/laura-rocha/30-mins-dathic--locator-support"
                        );
                      },
                      props: { colorScheme: "blue", mt: 5 },
                    },
                  ],
                });
                return;
              }
              if (onClose) onClose();
            }}
          >
            Locate on map
          </Button>
          {loadingStores && (
            <Text fontSize={12} color="darkgray">
              This search may take some time
            </Text>
          )}
        </Flex>
      </Flex>
    </VStack>
  );
};

export default connect(
  (state) => {
    return {
      accessToken: state.app.accessToken,
      orgProperties: state.app.orgProperties,
      loadingStores:
        !!state.mapViews.views[`view${state.mapViews.activeView}`]?.delivery
          ?.loading?.stores,
      userResources: state.app.currentUser.resources,
      loadedDemographics: selectLoadedZipcodes(state),
      mapSelection:
        state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
          ?.selection,
    };
  },
  (dispatch) => ({
    showDeliveryEntites: (payload) => dispatch(showDeliveryEntites(payload)),
    setIsSelecting: () => dispatch(setIsSelectingOnMap(true)),
  })
)(_ProductSearch);
