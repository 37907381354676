import React from "react";
import { Button, Image, Menu, MenuButton, MenuList } from "@chakra-ui/react";

import logo from "../assets/images/logo_negativo_vertical.png";

export const LogoComponent = ({ hasUserMenu = true }) => (
  <Menu>
    <MenuButton
      as={"div"}
      style={{ alignSelf: "center" }}
      id="dathic-logo"
      className="header-btn"
    >
      <Image src={logo} alt="dathic" h={30} />
    </MenuButton>
    {hasUserMenu && (
      <MenuList>
        <Button
          variant="link"
          size="xs"
          onClick={() => {
            window.location.href = "https://dathic.com/";
          }}
        >
          dathic.com
        </Button>
      </MenuList>
    )}
  </Menu>
);
