import axios from "axios";

export const generateShortUrl = async (url, accessToken, user_id) => {
  try {
    const response = await axios.post(
      `https://api-1.dathic.com/r/shorten_url/`,
      { url, user_id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};
