import React, { useState } from "react";
import {
  Button,
  Flex,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import "react-vis/dist/style.css";
import { connect } from "react-redux";
import { selectVisState } from "../../../features/map/keplerReducer";
import { crudAnnotation } from "../../../features/map-views/mapViewsSlice";
import { RESOURCES } from "../../../constants/user-constants";

const _AddNoteModal = (props) => {
  const { clickedObject, addNote, notes, userResources } = props;
  const [note, setNote] = useState("");
  const toast = useToast();
  const initRef = React.useRef();

  const handleAddNote = async (event) => {
    event.preventDefault();

    const annotationJson = {
      description: note,
      data: clickedObject,
    };
    if (clickedObject?.id) {
      annotationJson[clickedObject.address ? "store_id" : "demographic_id"] =
        clickedObject.id;
    }
    await addNote(annotationJson);
    setNote("");
  };

  return (
    <Popover placement="bottom-start" initialFocusRef={initRef}>
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <IconButton
              mr="0"
              disabled={!userResources?.includes(RESOURCES.VIEW_MAP_MODAL)}
              color="black"
              variant="link"
              icon={<EditIcon />}
            />
          </PopoverTrigger>
          <PopoverContent p="2">
            <PopoverArrow />
            <PopoverBody>
              <Input
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
                placeholder="Add a note"
              />
              <Flex mt={2} mb="-2" justify="flex-end">
                <Button
                  variant="outline"
                  onClick={onClose}
                  colorScheme="red"
                  ref={initRef}
                >
                  Cancel
                </Button>
                <Button
                  ml={2}
                  colorScheme="green"
                  isDisabled={
                    !userResources.includes(RESOURCES.ANNOTATION_CREATE)
                  }
                  onClick={(e) => {
                    note.length
                      ? handleAddNote(e).then(() => {
                          onClose();
                          toast({
                            title: "Note added",
                            description: "Note added successfully",
                            status: "success",
                            duration: 5000,
                            isClosable: true,
                          });
                        })
                      : toast({
                          title: "Please enter a note",
                          description: "",
                          status: "error",
                          duration: 5000,
                          isClosable: true,
                        });
                  }}
                >
                  Save
                </Button>
              </Flex>
              {!!notes?.length && (
                <TableContainer mt="5">
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Note</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {notes.map((note, i) => (
                        <Tr key={i}>
                          <Td>{note.description}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export const AddNoteModal = connect(
  (state) => {
    const clicked =
      selectVisState(state)?.clicked || selectVisState(state)?.customClicked;
    return {
      notes:
        state.mapViews.views[
          `view${state.mapViews.activeView}`
        ]?.annotations?.filter(
          (note) =>
            note[
              clicked?.object?.properties?.address
                ? "store_id"
                : "demographic_id"
            ] === clicked?.object?.properties?.id
        ) || [],
      clickedObject: clicked?.object?.properties,
    };
  },
  (dispatch) => ({
    addNote: (data) => {
      dispatch(crudAnnotation({ action: "create", data }));
    },
    editNote: (index, data) => {
      dispatch(crudAnnotation({ action: "update", index, data }));
    },
    removeNote: (index) => {
      dispatch(crudAnnotation({ action: "delete", index }));
    },
  })
)(_AddNoteModal);
