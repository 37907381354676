import {
  Box,
  Heading,
  StackProps,
  Text,
  TextProps,
  VStack,
} from "@chakra-ui/react";

type Props = {
  label?: TextProps["children"];
  title?: string;
  contentProps?: StackProps;
};

function PageLayout({
  label,
  title,
  children,
  contentProps = {},
}: React.PropsWithChildren<Props>) {
  return (
    <VStack h="100%" pt={10} bg="#F7F7F7" px={8} w="100%">
      <Box w="100%" textAlign="start">
        {title && <Heading>{title}</Heading>}
        {label && <Text fontSize="sm">{label}</Text>}
      </Box>
      <VStack w="100%" spacing={10} align="start" {...contentProps}>
        {children}
      </VStack>
    </VStack>
  );
}
export default PageLayout;
