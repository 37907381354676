import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  FormControl,
  HStack,
  ListItem,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  UnorderedList,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { parse, unparse } from "papaparse";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import XLSX from "xlsx";
import { EntityTable } from "./entity-table/EntityTable";
import { ResponsiveModal } from "./responsive-modal";

export default function FileUploadProxy({
  onFinishProxy,
  file,
  schemas,
  defaultSheet,
  isModal = true,
  actionsFromOutside = undefined,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFields, setSelectedFields] = useState({});
  const [selectedSchema, setSelectedSchema] = useState();
  const [fileFields, setFileFields] = useState([]);
  const [selectedSheetName, setSelectedSheetName] = useState(defaultSheet);
  const [tempSelectedSheet, setTempSelectedSheet] = useState();
  const [workbook, setWorkbook] = useState();
  const [data, setData] = useState();
  const [_schemas] = useState(schemas);

  const FieldSelector = (props) => {
    const { rowKeyValue } = props;
    const value = Object.keys(selectedFields).find(
      (k) => selectedFields[k] === rowKeyValue
    );
    return (
      <FormControl key={rowKeyValue}>
        <Select
          placeholder={`Ignore field`}
          value={value}
          onChange={(e) =>
            setSelectedFields((oldValue) => {
              const newSelection = {
                ...oldValue,
              };
              Object.keys(newSelection).forEach((k) => {
                if (newSelection[k] === rowKeyValue) {
                  delete newSelection[k];
                }
              });
              newSelection[e.target.value] = rowKeyValue;
              return newSelection;
            })
          }
        >
          {_schemas[selectedSchema]
            .filter((schemaField) => !schemaField.isHidden)
            .map((schemaField) => (
              <option value={schemaField.name}>{schemaField.helperText}</option>
            ))}
        </Select>
      </FormControl>
    );
  };

  const parseFields = useCallback(
    (_fileFields) => {
      setFileFields(_fileFields);
      let nameOfBestSchema = Object.keys(_schemas)[0];
      let fieldsThatMatched = {};

      const matchFunction = (schemaField, fileField) =>
        fileField?.toLowerCase().includes(schemaField.name?.toLowerCase()) ||
        schemaField?.matchFunction?.(schemaField, fileField);

      for (let i = 0; i < Object.keys(_schemas).length; i++) {
        const schemaName = Object.keys(_schemas)[i];

        let fieldsThatMatch = _schemas[schemaName].filter(
          (schemaField) =>
            !!_fileFields.find((fileField) =>
              matchFunction(schemaField, fileField)
            )
        );
        if (fieldsThatMatch.length > Object.keys(fieldsThatMatched).length) {
          nameOfBestSchema = schemaName;
          fieldsThatMatched = fieldsThatMatch.reduce((acc, schemaField) => {
            const fileField = _fileFields.find((fileField) =>
              matchFunction(schemaField, fileField)
            );
            const nextValue = { ...acc };
            nextValue[schemaField.name] = fileField;
            return nextValue;
          }, {});
          break;
        }
      }
      setSelectedSchema(nameOfBestSchema);
      setSelectedFields(fieldsThatMatched);
    },
    [_schemas]
  );

  const parseJson = useCallback(
    (fileText) => {
      const json = JSON.parse(fileText);
      setData(json);
      const _fields =
        json.length > 0 ? Object.keys(json[0]) : Object.keys(json);
      parseFields(_fields);
    },
    [parseFields]
  );

  useEffect(() => {
    const parseCsv = (fileText) => {
      const parsedFile = parse(fileText, { header: true });

      parseFields(parsedFile.meta.fields);
      Object.keys(parsedFile.data).forEach((key) => {
        if (parsedFile.data[key] === null) {
          delete parsedFile.data[key];
        }
      });
      setData(parsedFile.data);
    };

    const parseWorkbook = (_workbook) => {
      if (_workbook.SheetNames.length > 0) {
        setWorkbook(_workbook);
        if (_workbook.SheetNames.length === 1) {
          setSelectedSheetName(_workbook.SheetNames[0]);
        } else if (!_workbook.SheetNames.includes(defaultSheet || "")) {
          setSelectedSheetName();
        }
      }
    };

    const parseFile = async (file) => {
      const fileText = await file.text();
      switch (file.type) {
        case "text/csv":
          parseCsv(fileText);
          break;
        case "application/json":
          parseJson(fileText);
          break;
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
          var reader = new FileReader();
          reader.onload = function (e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });

            parseWorkbook(workbook);
          };
          reader.readAsArrayBuffer(file);
          break;

        default:
          break;
      }
    };

    if (file) {
      onOpen();
      parseFile(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, onOpen, parseJson]);

  useEffect(() => {
    if (selectedSheetName && workbook) {
      parseJson(
        JSON.stringify(
          XLSX.utils.sheet_to_json(workbook.Sheets[selectedSheetName], {
            defval: null,
            dateNF: "yyyy-mm-dd",
          })
        )
      );
    }
  }, [parseJson, selectedSheetName, workbook]);

  const validateItem = useCallback(
    (item) => {
      for (let i = 0; i < Object.keys(item).length; i++) {
        const key = Object.keys(item)[i];

        const schemaField = _schemas[selectedSchema].find(
          (item) => item.name === key
        );
        if (
          schemaField &&
          item[key] !== 0 &&
          !item[key] &&
          schemaField.isRequired
        ) {
          item.error = `${key} is required`;
          return false;
        }
        if (
          schemaField &&
          schemaField.dataType === "number" &&
          isNaN(Number(item[key]))
        ) {
          item.error = `${key} must be a number`;
          return false;
        }
        const validationError = schemaField?.validate?.(item[key]);
        if (validationError) {
          item.error = `${key} ${validationError}`;
          return false;
        }
      }
      return true;
    },
    [_schemas, selectedSchema]
  );

  const handleContinueUpload = useCallback(() => {
    if (onFinishProxy) {
      let goodData = [];
      let badData = [];
      let newDataCsv;
      if (data.length) {
        data
          .filter((d) => !!Object.values(d || {}).filter((v) => !!v).length)
          .map((dataItem, index) => {
            let newItem = { ...dataItem };
            newItem = _schemas[selectedSchema]
              .filter((schemaField) => !schemaField.build)
              .reduce((acc, schemaField) => {
                const newValue = schemaField.format
                  ? schemaField.format(
                      dataItem[selectedFields[schemaField.name]]
                    )
                  : dataItem[selectedFields[schemaField.name]];
                delete acc[selectedFields[schemaField.name]];
                return {
                  ...acc,
                  [schemaField.name]: newValue,
                };
              }, newItem);
            newItem = _schemas[selectedSchema]
              .filter((schemaField) => !!schemaField.build)
              .reduce((acc, schemaField) => {
                const builtField = schemaField.build(
                  selectedFields[schemaField.name],
                  newItem,
                  index
                );
                delete acc[selectedFields[schemaField.name]];
                return {
                  ...acc,
                  [schemaField.name]: builtField,
                };
              }, newItem);

            Object.keys(newItem).forEach((key) => {
              const schemaField = _schemas[selectedSchema].find(
                (item) => item.name === key
              );
              if (schemaField?.shouldDelete) {
                delete newItem[key];
                delete newItem[selectedFields[key]];
              }
            });
            if (validateItem(newItem)) {
              goodData.push(newItem);
            } else {
              badData.push(newItem);
            }
            return newItem;
          });
        newDataCsv = unparse(goodData);
      }
      onFinishProxy(selectedFields, goodData, newDataCsv, badData, workbook);
      setSelectedSheetName();
      setTempSelectedSheet();
    }
    onClose();
  }, [
    _schemas,
    data,
    onClose,
    onFinishProxy,
    selectedFields,
    selectedSchema,
    validateItem,
    workbook,
  ]);

  useEffect(() => {
    actionsFromOutside?.({
      onClose,
      handleContinueUpload,
      allowSubmit: !_schemas[selectedSchema]?.filter(
        (schemaField) =>
          !schemaField.isHidden &&
          schemaField.isRequired &&
          !selectedFields[schemaField.name]
      ).length,
    });
  }, [
    _schemas,
    actionsFromOutside,
    handleContinueUpload,
    onClose,
    selectedFields,
    selectedSchema,
  ]);

  const dataForTable = useMemo(
    () =>
      fileFields
        .filter((f) => defaultSheet !== "Stores" || !/^\d+\s-\s\w/.test(f))
        .map((f) => {
          return {
            field: f,
            selectedField: Object.keys(selectedFields).find(
              (k) => selectedFields[k] === f
            ),
            sample: data?.[0]?.[f],
          };
        }),
    [data, defaultSheet, fileFields, selectedFields]
  );

  const getBody = () => {
    return (
      <HStack>
        {workbook && !selectedSheetName && (
          <React.Fragment>
            <Select
              placeholder="Select sheet"
              value={tempSelectedSheet}
              onChange={(event) => setTempSelectedSheet(event.target.value)}
              isRequired
            >
              {workbook.SheetNames.map((sheet) => (
                <option value={sheet}>{sheet}</option>
              ))}
            </Select>
            <Button onClick={() => setSelectedSheetName(tempSelectedSheet)}>
              Select sheet
            </Button>
          </React.Fragment>
        )}
        {_schemas &&
          Object.keys(_schemas).length > 1 &&
          (!workbook || selectedSheetName) && (
            <Select
              placeholder="Select layer type"
              width={40}
              value={selectedSchema}
              onChange={(event) => setSelectedSchema(event.target.value)}
              isRequired
            >
              {Object.keys(_schemas).map((schema) => (
                <option value={schema}>{schema}</option>
              ))}
            </Select>
          )}
        {selectedSchema && (!workbook || selectedSheetName) && (
          <EntityTable
            initialTableProps={{
              rowKeyField: "field",
              filteringMode: "none",
              columns: [
                {
                  key: "field",
                  title: "Field in file",
                },
                {
                  key: "selectedField",
                  title: "Field in dathic",
                  Render: FieldSelector,
                },
                {
                  key: "sample",
                  title: "Sample value",
                },
              ],
            }}
            dataFromOutside={dataForTable}
          />
        )}
      </HStack>
    );
  };

  return isModal ? (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      scrollBehavior="inside"
      size="full"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleContinueUpload();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Choose fields
            {!!file && (
              <Text fontSize="sm" fontWeight="normal">
                Uploading "{file?.name}"
              </Text>
            )}
          </ModalHeader>
          <ModalCloseButton onClick={onClose} />

          <ModalBody>{getBody()}</ModalBody>
          <ModalFooter as={HStack}>
            {!!_schemas[selectedSchema]?.filter(
              (schemaField) =>
                !schemaField.isHidden &&
                schemaField.isRequired &&
                !selectedFields[schemaField.name]
            ).length && (
              <Alert status="error" variant="left-accent">
                <AlertIcon />
                <Flex flexDir="column">
                  <AlertTitle>Required fields</AlertTitle>
                  <AlertDescription>
                    <UnorderedList>
                      {_schemas[selectedSchema]
                        ?.filter(
                          (schemaField) =>
                            !schemaField.isHidden &&
                            schemaField.isRequired &&
                            !selectedFields[schemaField.name]
                        )
                        .map((schemaField) => (
                          <ListItem>{schemaField.helperText}</ListItem>
                        ))}
                    </UnorderedList>
                  </AlertDescription>
                </Flex>
              </Alert>
            )}
            {!actionsFromOutside && (
              <>
                <Button onClick={() => onClose()}>Cancel</Button>
                {!_schemas[selectedSchema]?.filter(
                  (schemaField) =>
                    !schemaField.isHidden &&
                    schemaField.isRequired &&
                    !selectedFields[schemaField.name]
                ).length && (
                  <Button type="submit" colorScheme="blue">
                    Continue upload
                  </Button>
                )}
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </form>
    </ResponsiveModal>
  ) : (
    <VStack w="100%">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleContinueUpload();
        }}
        style={{ width: "100%" }}
      >
        {getBody()}
        <HStack>
          {!!_schemas[selectedSchema]?.filter(
            (schemaField) =>
              !schemaField.isHidden &&
              schemaField.isRequired &&
              !selectedFields[schemaField.name]
          ).length && (
            <Alert status="error" variant="left-accent">
              <AlertIcon />
              <Flex flexDir="column">
                <AlertTitle>Required fields</AlertTitle>
                <AlertDescription>
                  <UnorderedList>
                    {_schemas[selectedSchema]
                      ?.filter(
                        (schemaField) =>
                          !schemaField.isHidden &&
                          schemaField.isRequired &&
                          !selectedFields[schemaField.name]
                      )
                      .map((schemaField) => (
                        <ListItem>{schemaField.helperText}</ListItem>
                      ))}
                  </UnorderedList>
                </AlertDescription>
              </Flex>
            </Alert>
          )}
          {!actionsFromOutside && (
            <>
              <Button onClick={() => onClose()}>Cancel</Button>
              {!_schemas[selectedSchema]?.filter(
                (schemaField) =>
                  !schemaField.isHidden &&
                  schemaField.isRequired &&
                  !selectedFields[schemaField.name]
              ).length && (
                <Button type="submit" colorScheme="blue">
                  Continue upload
                </Button>
              )}
            </>
          )}
        </HStack>
      </form>
    </VStack>
  );
}
