import { startCase } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import DemographicsRadialChart from "./DemographicsRadialChart";
import { EXTENDED_DISCRETE_COLOR_RANGE } from "react-vis/es/theme";
import { scaleLinear, scaleOrdinal } from "d3-scale";
import { DiscreteColorLegend } from "react-vis";
import { connect } from "react-redux";
import BarChart from "../../../../../components/charts/bar-chart";
import {
  EDUCATION_LEVELS,
  TRAVEL_TIME_MINUTES,
} from "../../../../../constants/data-constants";
import { getDemographics } from "../../../../../services/api.service";
import { useAppSelector } from "../../../../../app/store";
import { selectSelectedAudiences } from "../../../../map/keplerReducer";

function DemographicsTab({
  setSelectedTab,
  selectedItems,
  orgProperties,
  accessToken,
  audienceVariables,
}) {
  const selectedAudiences = useAppSelector(selectSelectedAudiences);
  const [demographics, setDemographics] = useState();
  const country = orgProperties?.country || "US";

  const zipSize = useMemo(() => orgProperties?.zipSize || 6, [orgProperties]);

  const states = useMemo(
    () => [
      ...new Set(
        [
          ...(selectedItems.demographic || []),
          ...(selectedItems.store || []),
        ]?.map((d) => d.state)
      ),
    ],
    [selectedItems]
  );
  const zipcodes = useMemo(
    () => [
      ...new Set(
        [
          ...(selectedItems.demographic || []),
          ...(selectedItems.store || []),
        ].map((d) => d.zipcode.substring(0, zipSize))
      ),
    ],
    [selectedItems, zipSize]
  );

  useEffect(() => {
    if (selectedItems.store?.length) {
      getDemographics(
        "zcta",
        audienceVariables,
        [
          "means_of_transportation_to_work_for_workers_16_and_over",
          "educational_attainment_for_population_25_and_over",
          "travel_time_to_work_in_minutes",
          "not_hispanic_or_latino",
        ],
        states,
        [],
        [],
        zipcodes,
        {},
        accessToken,
        undefined,
        false,
        country
      )
        .then((result) => {
          setDemographics(result);
        })
        .catch(() => {
          setDemographics([]);
        });
    } else {
      setDemographics(selectedItems.demographic);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, audienceVariables]);

  const objectData = (attrName, obj) => {
    const values = obj[attrName][0].values;
    const colorScale = scaleOrdinal()
      .domain([...new Set([...values.map((d) => d.x)])])
      .range(EXTENDED_DISCRETE_COLOR_RANGE);
    const percentScale = scaleLinear()
      .domain([0, values?.reduce((acc, p) => acc + p.y, 0)])
      .range([0, 1]);
    return values.map((val) => ({
      label: val.x,
      total: percentScale(val.y),
      value: val.y,
      color: colorScale(val.x),
    }));
  };
  const labelData = (attrName) => {
    const values = demographics[0][attrName][0].values;
    const scale = scaleOrdinal()
      .domain([...new Set([...values.map((d) => d.x)])])
      .range(EXTENDED_DISCRETE_COLOR_RANGE);
    return values.map((val) => ({
      title: val.x,
      strokeWidth: 15,
      color: scale(val.x),
    }));
  };

  return demographics ? (
    !demographics.msg ? (
      <Stack
        direction="column"
        background="white"
        width={[null, null, 650]}
        height="100%"
        textAlign="start"
        overflowY="auto"
      >
        {country === "US" && (
          <>
            <Text
              m={2}
              textAlign={"center"}
              fontSize={"xl"}
              fontWeight={"bold"}
            >
              Median Household Income
            </Text>
            <HStack justifyContent={"space-evenly"}>
              <Text fontSize={"xl"}>
                {demographics[0].median_household_income?.toLocaleString() ||
                  "No data found"}
              </Text>
              <Text fontSize={"xl"}>
                {demographics[1].median_household_income?.toLocaleString() ||
                  "No data found"}
              </Text>
            </HStack>
            <Divider />
            <Text fontSize="xl" fontWeight="bold" align="center">
              Hispanic/Latino Presence
            </Text>
            <Flex justify="space-around" alignItems={"center"}>
              {demographics.map((item, index) => {
                return (
                  <DemographicsRadialChart
                    key={index}
                    data={[
                      {
                        label: "Hispanic or Latino",
                        total: scaleLinear()
                          .domain([
                            0,
                            item.hispanic_or_latino +
                              item.not_hispanic_or_latino,
                          ])
                          .range([0, 1])(item.hispanic_or_latino),
                        color: EXTENDED_DISCRETE_COLOR_RANGE[0],
                      },
                      {
                        label: "Not Hispanic or Latino",
                        total: scaleLinear()
                          .domain([
                            0,
                            item.hispanic_or_latino +
                              item.not_hispanic_or_latino,
                          ])
                          .range([0, 1])(item.not_hispanic_or_latino),
                        color: EXTENDED_DISCRETE_COLOR_RANGE[1],
                      },
                    ]}
                    formatHint={(d) =>
                      d.toLocaleString(undefined, { style: "percent" })
                    }
                  />
                );
              })}
            </Flex>
            <DiscreteColorLegend
              orientation="horizontal"
              items={[
                {
                  title: "Hispanic or Latino",
                  color: EXTENDED_DISCRETE_COLOR_RANGE[0],
                  strokeWidth: 15,
                },
                {
                  title: "Not Hispanic or Latino",
                  color: EXTENDED_DISCRETE_COLOR_RANGE[1],
                  strokeWidth: 15,
                },
              ]}
            />
            <Divider />
          </>
        )}
        <Text fontSize="xl" fontWeight="bold" align="center">
          Selected Audience Presence
        </Text>
        <Flex justify="space-around" alignItems={"center"}>
          {demographics.map((item, index) => {
            return (
              <DemographicsRadialChart
                key={index}
                data={[
                  ...(selectedAudiences?.map((nationality, i) => ({
                    label: startCase(nationality),
                    total: scaleLinear().domain([0, item.total]).range([0, 1])(
                      item[nationality]
                    ),
                    color: EXTENDED_DISCRETE_COLOR_RANGE[i],
                  })) || []),
                  {
                    label: "Other audiences",
                    total: scaleLinear().domain([0, item.total]).range([0, 1])(
                      item.total -
                        (selectedAudiences?.reduce(
                          (acc, nat) => acc + item[nat],
                          0
                        ) || 0)
                    ),
                    color:
                      EXTENDED_DISCRETE_COLOR_RANGE[
                        selectedAudiences?.length || 0
                      ],
                  },
                ]}
                formatHint={(d) =>
                  d.toLocaleString(undefined, { style: "percent" })
                }
              />
            );
          })}
        </Flex>
        <DiscreteColorLegend
          orientation="horizontal"
          items={[
            ...(selectedAudiences?.map((nationality, i) => ({
              title: startCase(nationality),
              color: EXTENDED_DISCRETE_COLOR_RANGE[i],
              strokeWidth: 15,
            })) || []),
            {
              title: "Other audiences",
              color:
                EXTENDED_DISCRETE_COLOR_RANGE[selectedAudiences?.length || 0],
              strokeWidth: 15,
            },
          ]}
        />

        <Divider />
        <Text fontSize="xl" fontWeight="bold" align="center">
          Means of transportation to work
        </Text>
        <Flex justify="space-around" alignItems={"center"}>
          {demographics.map((item, index) => {
            return (
              <DemographicsRadialChart
                key={index}
                data={objectData(
                  "means_of_transportation_to_work_for_workers_16_and_over",
                  item
                )}
                formatHint={(d) =>
                  d.toLocaleString(undefined, { style: "percent" })
                }
              />
            );
          })}
        </Flex>
        <DiscreteColorLegend
          orientation="horizontal"
          items={labelData(
            "means_of_transportation_to_work_for_workers_16_and_over"
          )}
        />
        <Divider />
        <Text fontSize="xl" fontWeight="bold" align="center">
          Highest level of education
        </Text>
        <Flex justify="space-around" alignItems={"center"}>
          {demographics.map((item, index) => {
            return (
              <Box flex={1} key={index}>
                <BarChart
                  height={200}
                  containerProps={{ width: "100%" }}
                  chartContainerProps={{
                    mb: 3,
                    ml: 2,
                    mr: 2,
                    mt: 3,
                    border: null,
                    bg: null,
                  }}
                  hideLegend
                  xyPlotProps={{
                    margin: { left: 50, top: 10, right: 0, bottom: 3 },
                  }}
                  xAxis=""
                  yAxis="percent"
                  xDomain={EDUCATION_LEVELS[country]}
                  yDomain={[0, 100]}
                  data={{
                    key: {
                      data: objectData(
                        "educational_attainment_for_population_25_and_over",
                        item
                      ).map((d) => ({
                        x: d.label,
                        y:
                          (d.value * 100) /
                          objectData(
                            "educational_attainment_for_population_25_and_over",
                            item
                          ).reduce((acc, d) => acc + (d.value || 0), 0),
                        color: d.color,
                      })),
                      props: { colorType: "literal" },
                    },
                  }}
                ></BarChart>
              </Box>
            );
          })}
        </Flex>
        <DiscreteColorLegend
          orientation="horizontal"
          items={labelData("educational_attainment_for_population_25_and_over")}
        />
        <Divider />
        <Text fontSize="xl" fontWeight="bold" align="center">
          Travel time to work(min)
        </Text>
        <Flex justify="space-around" alignItems={"center"}>
          {demographics.map((item, index) => {
            return (
              <Box flex={1} key={index}>
                <BarChart
                  height={200}
                  containerProps={{ width: "100%" }}
                  chartContainerProps={{
                    mb: 3,
                    ml: 2,
                    mr: 2,
                    mt: 3,
                    border: null,
                    bg: null,
                  }}
                  hideLegend
                  xyPlotProps={{
                    margin: { left: 50, top: 10, right: 0, bottom: 3 },
                  }}
                  xAxis=""
                  yAxis="percent"
                  xDomain={TRAVEL_TIME_MINUTES[country]}
                  yDomain={[0, 100]}
                  data={{
                    key: {
                      data: objectData(
                        "travel_time_to_work_in_minutes",
                        item
                      ).map((d) => ({
                        x: d.label,
                        y:
                          (d.value * 100) /
                          objectData(
                            "travel_time_to_work_in_minutes",
                            item
                          ).reduce((acc, d) => acc + (d.value || 0), 0),
                        color: d.color,
                      })),
                      props: { colorType: "literal" },
                    },
                  }}
                ></BarChart>
              </Box>
            );
          })}
        </Flex>
        <DiscreteColorLegend
          orientation="horizontal"
          items={labelData("travel_time_to_work_in_minutes")}
        />
      </Stack>
    ) : (
      <Center h="100%">
        <Text>No demographics found</Text>
      </Center>
    )
  ) : (
    <Center h="100%">
      <Spinner />
    </Center>
  );
}

export default connect((state) => ({
  selectedItems:
    state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
      ?.comparisonSelection,
  accessToken: state.app.accessToken,
  orgProperties: state.app.orgProperties?.properties,
  audienceVariables:
    state.app.properties?.find((properties) => properties.name === "audiences")
      ?.value || [],
}))(DemographicsTab);
