import { assign, keys, pick } from "lodash";
import type { DeliveryProduct } from "./DeliveryProduct";

export class ProductDeliveryProduct {
  id!: number;
  product_id?: number;
  delivery_product_uid?: string;
  delivery_product?: DeliveryProduct;

  constructor(candidate: any) {
    assign(this, pick(candidate, keys(this)));
  }

  buildForPost() {
    const newReview = JSON.parse(JSON.stringify(this));
    delete newReview.id;
    delete newReview.delivery_product;
    return new ProductDeliveryProduct(newReview);
  }
}
