import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import { RESOURCES } from "../../constants/user-constants";
import {
  getDemographicsByCounty,
  getDemographicsByState,
} from "../../services/api.service";
import { camelCaseWords } from "../../utils/stringUtils";
import {
  selectSearchedStores,
  selectSelectedAudiences,
  selectVisState,
} from "../../features/map/keplerReducer";
import { PieForTable } from "../PieForTable";
import SelectMoreInsights from "../../features/insights/SelectMoreInsights";
import { useAppSelector } from "../../app/store";
import { InfoOutlineIcon } from "@chakra-ui/icons";

const variablesForFetch = [
  "population_by_age",
  "population_by_race",
  "weekly_tweets",
  "housing_units",
  "monthly_rent_including_utilities_studio_apt",
  "monthly_rent_including_utilities_1_b",
  "monthly_rent_including_utilities_2_b",
  "monthly_rent_including_utilities_3plus_b",
  "educational_attainment_for_population_25_and_over",
  "number_of_schools_by_levels",
  "schools_by_virtual_modalities",
  "num_students_with_school_lunch_support",
  "total_school_students_by_grades",
  "school_students_by_ethnicities",
  "total_schools_by_type",
  "total_school_students",
  "number_of_schools",
  "occupied_housing_units",
  "means_of_transportation_to_work_for_workers_16_and_over",
  "travel_time_to_work_in_minutes",
];

const _InfoTab = (props) => {
  const {
    accessToken,
    clickedObject,
    clickedLayerId,
    userResources,
    extraData,
    audienceVariables,
    loadedStores,
    orgConfig,
  } = props;
  const [insightOptions, setInsightOptions] = useState();
  const [countiesData, setCountiesData] = useState({});
  const [statesData, setStatesData] = useState({});
  const selectedAudiences = useAppSelector(selectSelectedAudiences);
  const country = orgConfig?.country || "US";
  const stateLabel = orgConfig?.statesNameReplacement || "State";

  const mapObjectForTable = useCallback(
    (obj) => {
      const demographic = Object.entries(obj || clickedObject).reduce(
        (acc, [key, value]) => {
          if (value?.constructor === Object) {
            return {
              ...acc,
              [key]: !Object.values(value).find(
                (val) => val?.constructor === Object
              )
                ? [
                    {
                      key: "Data",
                      values: Object.entries(value).map(([vkey, val]) => ({
                        x: vkey,
                        y: val,
                      })),
                    },
                  ]
                : Object.entries(value || {}).map(([vkey, val]) => ({
                    key: vkey,
                    values: Object.entries(val || {}).map(([svkey, vval]) => ({
                      x: svkey,
                      y: vval,
                    })),
                  })),
            };
          }
          return { ...acc, [key]: value };
        },
        {}
      );
      let population_by_age;
      let population_by_race;
      let educational_attainment;
      let transportation;
      let travelTime;
      try {
        population_by_age = demographic.population_by_age;
        population_by_race = demographic.population_by_race;
        educational_attainment =
          demographic.educational_attainment_for_population_25_and_over;
        transportation =
          demographic.means_of_transportation_to_work_for_workers_16_and_over;
        travelTime = demographic.travel_time_to_work_in_minutes;
      } catch (error) {
        console.log(error);
      }
      let ageGroups =
        (population_by_age || [])
          .find((item) => item.key === "Total")
          ?.values?.filter(
            (group) =>
              !group.x.includes("0 to 14") &&
              !group.x.includes("15 to 64") &&
              !group.x.includes("65 years") &&
              !group.x.includes("85 years")
          ) || [];
      let latinoRaceGroups = Object.keys(extraData)
        .filter(
          (data) =>
            audienceVariables.includes(data) &&
            !["total", "hispanic_or_latino"].includes(data)
        )
        .map((key) => ({ x: camelCaseWords(key), y: extraData[key] }));
      let raceGroups = (population_by_race || [])[0]?.values || [];
      let educationGroups = (educational_attainment || [])[0]?.values || [];
      let transportationGroups = (transportation || [])[0]?.values || [];
      let travelTimeGroups = (travelTime || [])[0]?.values || [];
      let number_of_schools_by_levels =
        demographic?.number_of_schools_by_levels?.[0]?.values || [];
      let schools_by_virtual_modalities =
        demographic?.schools_by_virtual_modalities?.[0]?.values || [];
      let num_students_with_school_lunch_support =
        demographic?.num_students_with_school_lunch_support?.[0]?.values || [];
      let total_school_students_by_grades =
        demographic?.total_school_students_by_grades?.[0]?.values || [];
      let school_students_by_ethnicities =
        demographic?.school_students_by_ethnicities?.[0]?.values || [];
      let total_schools_by_type =
        demographic?.total_schools_by_type?.[0]?.values || [];
      let total_school_students =
        demographic?.total_school_students?.[0]?.values || [];
      let number_of_schools = demographic?.number_of_schools?.[0]?.values || [];

      const engagementAudience = selectedAudiences?.reduce(
        (nationalities, nationality) => {
          const prob = demographic[`prob_${nationality}`];
          const nationalityTweets =
            (demographic.weekly_tweets ?? 0) * (prob ?? 0);
          return {
            ...nationalities,
            [nationality]: nationalityTweets || 0,
          };
        },
        {}
      );
      return {
        age: ageGroups || [],
        hispanic_heritage: latinoRaceGroups || [],
        race: raceGroups || [],
        income: demographic.median_household_income,
        engagement: [
          {
            y:
              (demographic.weekly_tweets || 0) -
              ((engagementAudience &&
                Object.keys(engagementAudience).reduce(
                  (otherEngagement, key) =>
                    otherEngagement + engagementAudience[key],
                  0
                )) ||
                0),
            x: "Other",
          },
          ...(engagementAudience
            ? Object.keys(engagementAudience).map((key) => ({
                y: engagementAudience[key],
                x: key,
              }))
            : []),
        ],
        housing_units: [
          {
            x: "Occupied",
            y: demographic.occupied_housing_units,
          },
          {
            x: "Not Occupied",
            y:
              (demographic.housing_units || 0) -
              (demographic.occupied_housing_units || 0),
          },
        ],
        avg_rent: Object.keys(demographic).reduce((demoRent, key) => {
          if (key.startsWith("monthly_rent")) {
            let monthly_rent;
            try {
              monthly_rent = demographic[key];
              let rentGroups = (monthly_rent || [])[0]?.values || [];
              return rentGroups.map((rentGroup) => {
                const group = demoRent.find((v) => v.x === rentGroup.x);
                return {
                  x: rentGroup.x,
                  y: rentGroup.y + (group?.y ?? 0),
                };
              });
            } catch (error) {}
          }
          return demoRent;
        }, []),
        education: educationGroups || [],
        num_stores: loadedStores?.reduce((numStores, store) => {
          if (store.zipcode === demographic.zipcode) return numStores + 1;
          return numStores;
        }, 0),
        transportation: transportationGroups || [],
        travel_time: travelTimeGroups || [],
        number_of_schools_by_levels,
        schools_by_virtual_modalities,
        num_students_with_school_lunch_support,
        total_school_students_by_grades,
        school_students_by_ethnicities,
        total_schools_by_type,
        total_school_students,
        number_of_schools,
        num_audience: selectedAudiences?.reduce(
          (num_audience, nationality) =>
            demographic[nationality] + num_audience,
          0
        ),
        ...(selectedAudiences?.reduce(
          (audiences, nationality) => ({
            ...audiences,
            [nationality]:
              demographic[nationality] + (audiences[nationality] ?? 0),
          }),
          {}
        ) || {}),
        zipcode: demographic.zipcode,
      };
    },
    [
      audienceVariables,
      clickedObject,
      extraData,
      loadedStores,
      selectedAudiences,
    ]
  );
  useEffect(() => {
    const parseDataForCountyTable = async () => {
      const countiesDemographics = await getDemographicsByCounty(
        selectedAudiences,
        ["county", ...variablesForFetch],
        [clickedObject?.county],
        accessToken
      );
      const data = mapObjectForTable(countiesDemographics?.[0]);
      setCountiesData(data);
    };
    const parseDataForStateTable = async () => {
      const stateDemographics = await getDemographicsByState(
        selectedAudiences,
        ["state", ...variablesForFetch],
        [clickedObject?.state],
        accessToken
      );
      const data = mapObjectForTable(stateDemographics?.[0]);
      setStatesData(data);
    };
    parseDataForCountyTable();
    parseDataForStateTable();
  }, [accessToken, clickedObject, mapObjectForTable, selectedAudiences]);

  let objectForTable;
  if (clickedObject) {
    objectForTable = mapObjectForTable();
  }
  return (
    <VStack align="flex-start">
      <SimpleGrid columns={3} w="100%" spacing={3}>
        {(country === "US"
          ? [
              "age",
              "race",
              "hispanic_heritage",
              ...(objectForTable?.engagement
                ?.filter((pair) => pair.x !== "Other")
                ?.reduce((acc, current) => {
                  return acc + current.y;
                }, 0)
                ? ["engagement"]
                : []),
              "housing_units",
              "avg_rent",
              "education",
              "transportation",
              "travel_time",
              "number_of_schools_by_levels",
              "schools_by_virtual_modalities",
              "num_students_with_school_lunch_support",
              "total_school_students_by_grades",
              "school_students_by_ethnicities",
              "total_schools_by_type",
              "total_school_students",
              "number_of_schools",
            ]
          : [
              "age",
              "race",
              ...(objectForTable?.engagement
                ?.filter((pair) => pair.x !== "Other")
                ?.reduce((acc, current) => {
                  return acc + current.y;
                }, 0)
                ? ["engagement"]
                : []),
              "education",
              "transportation",
              "travel_time",
            ]
        ).map((key) => (
          <VStack key={key} shadow="base" rounded="md" p={3}>
            <Text fontWeight="bold">{camelCaseWords(key)}</Text>
            <PieForTable
              key={key}
              height={150}
              width={150}
              value={objectForTable?.[key]}
            />
          </VStack>
        ))}
      </SimpleGrid>

      <Accordion w="100%" allowToggle>
        {!!Object.keys(countiesData || {}).length && (
          <AccordionItem>
            <AccordionButton fontSize={18} fontWeight="bold">
              County
              <AccordionIcon ml="auto" />
            </AccordionButton>
            <AccordionPanel>
              <SimpleGrid columns={3} w="100%" spacing={3}>
                {(country === "US"
                  ? [
                      "age",
                      "race",
                      "hispanic_heritage",
                      ...(countiesData?.engagement
                        ?.filter((pair) => pair.x !== "Other")
                        ?.reduce((acc, current) => {
                          return acc + current.y;
                        }, 0)
                        ? ["engagement"]
                        : []),
                      "housing_units",
                      "avg_rent",
                      "education",
                      "transportation",
                      "travel_time",
                      "number_of_schools_by_levels",
                      "schools_by_virtual_modalities",
                      "num_students_with_school_lunch_support",
                      "total_school_students_by_grades",
                      "school_students_by_ethnicities",
                      "total_schools_by_type",
                      "total_school_students",
                      "number_of_schools",
                    ]
                  : [
                      "age",
                      "race",
                      ...(countiesData?.engagement
                        ?.filter((pair) => pair.x !== "Other")
                        ?.reduce((acc, current) => {
                          return acc + current.y;
                        }, 0)
                        ? ["engagement"]
                        : []),
                      "education",
                      "transportation",
                      "travel_time",
                    ]
                ).map((key) => (
                  <VStack key={key} shadow="base" rounded="md" p={3}>
                    <Text fontWeight="bold">{camelCaseWords(key)}</Text>
                    <PieForTable
                      key={key}
                      height={150}
                      width={150}
                      value={countiesData?.[key]}
                    />
                  </VStack>
                ))}
              </SimpleGrid>
            </AccordionPanel>
          </AccordionItem>
        )}
        {!!Object.keys(statesData || {}).length && (
          <AccordionItem>
            <AccordionButton fontSize={18} fontWeight="bold">
              {stateLabel}
              <AccordionIcon ml="auto" />
            </AccordionButton>
            <AccordionPanel>
              <SimpleGrid columns={3} w="100%" spacing={3}>
                {(country === "US"
                  ? [
                      "age",
                      "race",
                      "hispanic_heritage",
                      ...(statesData?.engagement
                        ?.filter((pair) => pair.x !== "Other")
                        ?.reduce((acc, current) => {
                          return acc + current.y;
                        }, 0)
                        ? ["engagement"]
                        : []),
                      "housing_units",
                      "avg_rent",
                      "education",
                      "transportation",
                      "travel_time",
                      "number_of_schools_by_levels",
                      "schools_by_virtual_modalities",
                      "num_students_with_school_lunch_support",
                      "total_school_students_by_grades",
                      "school_students_by_ethnicities",
                      "total_schools_by_type",
                      "total_school_students",
                      "number_of_schools",
                    ]
                  : [
                      "age",
                      "race",
                      ...(statesData?.engagement
                        ?.filter((pair) => pair.x !== "Other")
                        ?.reduce((acc, current) => {
                          return acc + current.y;
                        }, 0)
                        ? ["engagement"]
                        : []),
                      "education",
                      "transportation",
                      "travel_time",
                    ]
                ).map((key) => (
                  <VStack key={key} shadow="base" rounded="md" p={3}>
                    <Text fontWeight="bold">{camelCaseWords(key)}</Text>
                    <PieForTable
                      key={key}
                      height={150}
                      width={150}
                      value={statesData?.[key]}
                    />
                  </VStack>
                ))}
              </SimpleGrid>
            </AccordionPanel>
          </AccordionItem>
        )}
        <AccordionItem>
          <AccordionButton fontSize={18} fontWeight="bold">
            Insights
            <AccordionIcon ml="auto" />
          </AccordionButton>
          <AccordionPanel>
            {!!clickedObject.insights?.length && (
              <SimpleGrid columns={3} w="100%" spacing={3}>
                {Object.keys(clickedObject.insights[0]).map((key) => (
                  <VStack
                    key={key}
                    shadow="base"
                    rounded="md"
                    padding={3}
                    pos="relative"
                  >
                    {insightOptions?.find((o) => o.endpoint?.includes(key))
                      ?.description && (
                      <Tooltip
                        hasArrow
                        placement="top"
                        label={
                          insightOptions?.find((o) => o.endpoint?.includes(key))
                            ?.description
                        }
                      >
                        <InfoOutlineIcon
                          pos="absolute"
                          top={3}
                          right={3}
                          ml={3}
                        />
                      </Tooltip>
                    )}
                    <Text fontWeight="bold">{camelCaseWords(key)}</Text>
                    {clickedObject.insights[0][key] &&
                      typeof clickedObject.insights[0][key] === "object" && (
                        <PieForTable
                          key={key}
                          height={150}
                          width={150}
                          value={Object.keys(
                            clickedObject.insights[0][key]
                          ).map((innerkey) => ({
                            x: innerkey,
                            y: clickedObject.insights[0][key][innerkey],
                          }))}
                        />
                      )}
                    {typeof clickedObject.insights[0][key] !== "object" && (
                      <Text fontSize={60}>
                        {clickedObject.insights[0][key] === true
                          ? "Yes"
                          : clickedObject.insights[0][key] === false
                          ? "No"
                          : `${clickedObject.insights[0][key]}`}
                      </Text>
                    )}
                  </VStack>
                ))}
              </SimpleGrid>
            )}
            <SelectMoreInsights
              layerId={clickedLayerId}
              onOptionsFetch={setInsightOptions}
            />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          isDisabled={!userResources?.includes(RESOURCES.DATA_MORE_INFO)}
        >
          <AccordionButton fontSize={18} fontWeight="bold">
            More info
            <AccordionIcon ml="auto" />
          </AccordionButton>
          <AccordionPanel>
            <Flex
              flexDir="column"
              flexWrap="wrap"
              maxH={200}
              overflowX="auto"
              w="100%"
            >
              {clickedObject &&
                Object.keys(clickedObject)
                  .filter((attribute) => {
                    let parsedJson;
                    if (typeof clickedObject[attribute] === "string") {
                      try {
                        parsedJson = JSON.parse(clickedObject[attribute]);
                      } catch (e) {}
                    }
                    const attributeNotObject =
                      typeof clickedObject[attribute] !== "object";
                    return attributeNotObject && !parsedJson;
                  })
                  .map((attribute) => (
                    <Stat
                      mb={2}
                      mr={5}
                      key={attribute}
                      shadow="base"
                      rounded="md"
                      p={3}
                    >
                      <StatLabel>{camelCaseWords(attribute)}</StatLabel>
                      <StatNumber fontSize={14}>
                        {clickedObject[attribute]}
                      </StatNumber>
                    </Stat>
                  ))}
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </VStack>
  );
};

export default connect((state) => {
  const clicked =
    selectVisState(state)?.clicked || selectVisState(state)?.customClicked;
  return {
    clickedObject: clicked?.object?.properties,
    clickedLayerId: clicked?.layer?.id,
    accessToken: state.app.accessToken,
    userResources: state.app.currentUser.resources,
    audienceVariables:
      state.app.properties?.find(
        (properties) => properties.name === "audiences"
      )?.value || [],
    loadedStores: selectSearchedStores(state),
    orgConfig: state.app.orgProperties?.properties,
  };
})(_InfoTab);
