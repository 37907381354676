import { Box, Button } from "@chakra-ui/react";
import { FaHouseUser } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { selectMenus } from "../app/appSlice";
import { useAppSelector } from "../app/store";
import { trackClick } from "../services/tracking.service";

export default function HomeBtn() {
  const location = useLocation();
  const navigate = useNavigate();
  const menus = useAppSelector(selectMenus);
  const overviewMenu = menus?.find((m: any) => m.id === "overview");
  return !location.pathname?.includes("storelocator") && !!overviewMenu ? (
    <Box>
      <Button
        id="home-btn"
        className="home-btn"
        variant="link"
        as="div"
        color="blue.900"
        padding="12px 10px"
        borderRadius={20}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        leftIcon={
          <FaHouseUser size="1rem" id="home-btn" className="home-btn" />
        }
        isActive={location.pathname.includes("/overview")}
        iconSpacing={0}
        size="xs"
        _hover={{ textDecoration: "underline" }}
        onClick={() =>
          trackClick("home-btn", "Overview", () => {
            if (overviewMenu.embedUrl) {
              navigate("/embed/overview", {
                state: {
                  from: location,
                  url: overviewMenu.embedUrl,
                },
              });
            } else {
              navigate("/overview", {
                state: {
                  from: location,
                },
              });
            }
          })
        }
      >
        Overview
      </Button>
    </Box>
  ) : (
    <></>
  );
}
