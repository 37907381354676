import { useEffect } from "react";
import { useNavigate } from "react-router";

export default function useLocatorPreviewCommunicator() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleMessage = (event: MessageEvent<any>) => {
      if (event.data?.action === "edit-store") {
        navigate(`/storelocator/stores/${event.data.data?.id}`);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
}
