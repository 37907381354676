import { Button, IconButton } from "@chakra-ui/button";
import {
  ArrowLeftIcon,
  DeleteIcon,
  EditIcon,
  LinkIcon,
} from "@chakra-ui/icons";
import { Image } from "@chakra-ui/image";
import { Box, HStack, SimpleGrid, Text, VStack } from "@chakra-ui/layout";
import {
  Divider,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FormControl,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger as OrigPopoverTrigger,
  Portal,
  Spacer,
  Tag,
  TagLabel,
  Tooltip,
  useEditableControls,
} from "@chakra-ui/react";
import { Select } from "@chakra-ui/select";
import { AnyAction, unwrapResult } from "@reduxjs/toolkit";
import { updateCellValue } from "ka-table/actionCreators";
import { DataType, SortDirection } from "ka-table/enums";
import { ICellEditorProps, ICellTextProps } from "ka-table/props";
import pluralize from "pluralize";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FaEdit, FaShopify, FaTrash, FaVideo } from "react-icons/fa";
import { MdMoreVert, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { toast } from "react-toastify";
import {
  fetchProducts,
  selectAccessToken,
  selectIsShopifyConnected,
  selectOrgProperties,
  selectProducts,
  selectUserResources,
  setProducts,
} from "../../app/appSlice";
import { AppDispatch, useAppDispatch, useAppSelector } from "../../app/store";
import PieChart from "../../components/charts/pie-chart";
import { EntityTable } from "../../components/entity-table/EntityTable";
import { RESOURCES } from "../../constants/user-constants";
import MessageModalContext from "../../contexts/MessageModalContext";
import { Product } from "../../domain/Product";
import { deleteProduct, putProduct } from "../../services/api.service";
import { syncProducts } from "../../services/shopify.service";
import { camelCaseWords } from "../../utils/stringUtils";
import { refreshIframe } from "../store-locator/storeLocatorSlice";
import ProductForm from "./ProductForm";

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
  OrigPopoverTrigger;

function StatusSelect(props: ICellTextProps & { appDispatch: AppDispatch }) {
  const { rowData, dispatch, appDispatch } = props;
  const accessToken = useAppSelector(selectAccessToken);
  return (
    <Select
      defaultValue={rowData.status}
      onChange={(event) => {
        const p = new Product(rowData);
        p.status =
          (event.currentTarget.value as Product["status"]) || undefined;
        putProduct(p.buildForUpdate(), accessToken).then(() => {
          appDispatch(refreshIframe());
          toast.success(`Status updated`);
          dispatch(updateCellValue(p.id, "status", p.status));
        });
      }}
    >
      <option value="" />
      {["selling", "not_selling"].map((option) => (
        <option key={option} value={option}>
          {option === "selling" ? "Enabled" : "Disabled"}
        </option>
      ))}
    </Select>
  );
}

type ViewingCorrelation = { product: Product; correlations: any[] };

function ProductCorrelation({
  obj,
  setViewingCorrelation,
}: {
  obj?: ViewingCorrelation;
  setViewingCorrelation: React.Dispatch<
    React.SetStateAction<ViewingCorrelation | undefined>
  >;
}) {
  const { product, correlations } = obj || {};
  let positive_correlations = JSON.parse(
    correlations?.[0]?.positive_correlations?.[0]?.replaceAll("'", '"')
  );
  positive_correlations = Object.keys(positive_correlations).reduce(
    (acc, joinedkey) => {
      const [key, group] = joinedkey.split("-");
      return {
        ...acc,
        [key]: [
          ...(acc[key as keyof {}] || []),
          { group, value: positive_correlations[joinedkey] },
        ],
      };
    },
    {}
  );
  const negative_correlations = JSON.parse(
    correlations?.[0]?.negative_correlations?.[0]?.replaceAll("'", '"')
  );
  const PieForCorrelation = ({ correlation }: { correlation: number }) => {
    const [pieData, setPieData] = useState();
    const [shouldRefresh, setShouldRefresh] = useState(true);
    const value = -1 * correlation + 1;
    return (
      <PieChart
        height={50}
        width={50}
        createData={() => ({
          children: [
            {
              name: "",
              hex: "#F68457",
              value: value,
            },
            {
              name: "none",
              hex: "#fff",
              value: 1 - value,
            },
          ],
        })}
        data={pieData}
        onDataChange={(d) => {
          setPieData(d);
          return null;
        }}
        shouldRefresh={shouldRefresh}
        onRefresh={() => setShouldRefresh(false)}
        showFooter={false}
        showLabel={false}
      />
    );
  };
  return (
    <Box position="relative">
      <Button
        leftIcon={<ArrowLeftIcon />}
        variant="link"
        onClick={() => setViewingCorrelation(undefined)}
      >
        Go back to products list
      </Button>

      <VStack
        bg="whitesmoke"
        p={5}
        borderRadius={10}
        spacing={10}
        maxH={500}
        overflowY="auto"
      >
        <Box w="100%">
          <Heading mb={3}>{product?.name}</Heading>
          <Text mb={3}>
            {correlations?.[0]?.description ||
              "There is no info available for this brand or product"}
          </Text>
          <VStack bg="white" borderRadius={10} divider={<Divider />}>
            {!!Object.keys(positive_correlations).length && (
              <Flex w="100%" alignItems="center" borderRadius={10} p={3}>
                <VStack flex={1} alignItems="start" spacing={3}>
                  <Text fontWeight="bold" fontSize={18}>
                    Higher demand
                  </Text>
                  {Object.keys(positive_correlations).map((key) => (
                    <SimpleGrid
                      templateColumns="repeat(auto-fill, 140px)"
                      width="100%"
                      alignItems="center"
                    >
                      <Text>{key}:</Text>
                      {positive_correlations[key].map((corr: any) => (
                        <Tag mr={3} mb={3}>
                          <TagLabel mr="auto">
                            {corr.group.split("_").join(" to ")}
                          </TagLabel>
                          <PieForCorrelation correlation={corr.value} />
                        </Tag>
                      ))}
                    </SimpleGrid>
                  ))}
                </VStack>
              </Flex>
            )}
            {!!Object.keys(negative_correlations).length && (
              <Flex w="100%" alignItems="center" borderRadius={10} p={3}>
                <VStack flex={1} alignItems="start" spacing={3}>
                  <Text fontWeight="bold" fontSize={18}>
                    Lower demand
                  </Text>
                  <Flex flexWrap="wrap">
                    {Object.keys(negative_correlations).map((key) => (
                      <Tag mr={3} mb={3}>
                        <TagLabel mr="auto">{key}</TagLabel>
                        <PieForCorrelation
                          correlation={negative_correlations[key]}
                        />
                      </Tag>
                    ))}
                  </Flex>
                </VStack>
              </Flex>
            )}
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
}

function ProductsTable({ productId }: { productId?: number }) {
  const [viewingCorrelation, setViewingCorrelation] =
    useState<ViewingCorrelation>();
  const [editingProduct, setEditingProduct] = useState<Product>();
  const [syncingFromShopify, setSyncingFromShopify] = useState(false);

  const dispatch = useAppDispatch();

  const accessToken = useAppSelector(selectAccessToken);
  const products = useAppSelector(selectProducts);
  const orgProperties = useAppSelector(selectOrgProperties);
  const userResources = useAppSelector(selectUserResources);
  const isShopifyConnected = useAppSelector(selectIsShopifyConnected);
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";

  useEffect(() => {
    if (productId) {
      const product = products.find((p) => p.id === productId);
      if (product) {
        setEditingProduct(product);
      }
    }
  }, [productId, products]);

  const ProductImageField = ({ rowData, column }: ICellTextProps) => {
    return (
      <VStack>
        {rowData[column.key] ? (
          <Image src={rowData[column.key]} w={50} h={50} objectFit="contain" />
        ) : (
          <Box
            textAlign="center"
            p={3}
            rounded="lg"
            bg="gray.100"
            border="1px solid"
            lineHeight="1em"
            color="tomato"
            borderColor="tomato"
          >
            No Image
          </Box>
        )}
      </VStack>
    );
  };

  const ProductUrlsField = ({
    rowData,
    dispatch,
    rowKeyValue,
    column,
  }: ICellTextProps) => {
    const [value, setValue] = useState("");
    const [prodUrls, setProdUrls] = useState<string[]>(
      JSON.parse(rowData.urls || "[]")
    );
    const originalUrls = useMemo(
      () => JSON.parse(rowData.urls || "[]"),
      [rowData.urls]
    );

    const editUrl = (newValue: string) => {
      const p = new Product({ ...rowData, urls: newValue });
      putProduct(p.buildForUpdate(), accessToken).then(() => {
        toast.success(`Urls updated`);
        dispatch(updateCellValue(rowKeyValue, column.key, newValue));
        dispatch(refreshIframe());
      });
    };

    const changeUrl = (edited: string, index: number) => {
      if (/^https?:\/\//.test(edited)) {
        setProdUrls(() => {
          const newList = [...(prodUrls || [])];
          newList[index] = edited;
          return newList;
        });
      } else {
        toast.error('URLs must begin with "http://" or "https://"');
      }
    };

    const deleteUrl = (index: number) => {
      setProdUrls((oldValue) => {
        const newList = [...(oldValue || [])];
        newList.splice(index, 1);
        return newList;
      });
    };

    const addUrl: React.FormEventHandler<HTMLFormElement> = (e) => {
      e.preventDefault();
      if (value?.length) {
        if (/^https?:\/\//.test(value)) {
          setProdUrls((oldValue) => [value, ...(oldValue || [])]);
          setValue("");
        }
      } else {
        toast.error("Please enter a url");
      }
    };

    const EditableIcon = () => {
      const { isEditing, getEditButtonProps } = useEditableControls();
      return isEditing ? (
        <></>
      ) : (
        <IconButton
          icon={<EditIcon />}
          variant="link"
          {...getEditButtonProps()}
          aria-label="Edit product button"
        />
      );
    };
    return (
      <Box>
        <Popover
          placement="bottom-start"
          onClose={() => setProdUrls(originalUrls)}
        >
          {({ onClose }) => (
            <>
              <PopoverTrigger>
                <Button leftIcon={<LinkIcon />}>
                  {prodUrls?.length || originalUrls?.length || 0}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverBody>
                  <VStack maxH={200} overflowY="auto">
                    <Flex w="100%">
                      <form onSubmit={addUrl}>
                        <Input
                          flex={1}
                          value={value}
                          onChange={(e) => {
                            setValue(e.target.value);
                          }}
                          placeholder="Add a url"
                          rounded="md"
                        />
                      </form>
                    </Flex>
                    {value && !/^https?:\/\//.test(value) && (
                      <FormControl color="tomato">
                        URLs must begin with "http://" or "https://"
                      </FormControl>
                    )}
                    {prodUrls.map((url, index) => (
                      <Flex
                        flexDir="row"
                        justify="space-between"
                        w="100%"
                        shadow="base"
                        rounded="md"
                        p={2}
                      >
                        <Editable
                          key={url}
                          defaultValue={url}
                          onSubmit={(newValue) => changeUrl(newValue, index)}
                        >
                          <EditablePreview />
                          <EditableInput />
                          <EditableIcon />
                        </Editable>
                        <IconButton
                          icon={<DeleteIcon />}
                          onClick={() => deleteUrl(index)}
                          aria-label={"Delete product button"}
                        />
                      </Flex>
                    ))}
                    <Flex mt={2} mb="-2" alignSelf="flex-end">
                      <Button
                        variant="outline"
                        onClick={onClose}
                        colorScheme="red"
                      >
                        Cancel
                      </Button>
                      <Button
                        ml={2}
                        colorScheme="green"
                        disabled={!!(value && !/^https?:\/\//.test(value))}
                        onClick={() => {
                          const urls = [
                            ...(value ? [value] : []),
                            ...(prodUrls || []),
                          ];
                          editUrl(JSON.stringify(urls));
                        }}
                      >
                        Save
                      </Button>
                    </Flex>
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
      </Box>
    );
  };
  const OptionsButton = ({ rowData }: ICellEditorProps) => {
    return (
      <Box>
        <Menu>
          <MenuButton disabled={!rowData.id}>
            <IconButton
              variant="link"
              icon={<MdMoreVert />}
              colorScheme="blue.900"
              aria-label={"More product options"}
            />
          </MenuButton>
          <Portal>
            <MenuList maxHeight={200} overflowY="auto" zIndex={999}>
              <MenuItem
                icon={<FaEdit />}
                isDisabled={!userResources?.includes(RESOURCES.PRODUCTS_UPDATE)}
                onClick={() => setEditingProduct(rowData)}
              >
                Edit Product
              </MenuItem>
              <MenuItem
                icon={<FaTrash />}
                isDisabled={!userResources?.includes(RESOURCES.PRODUCTS_DELETE)}
                onClick={async () => {
                  try {
                    await deleteProduct(rowData.id, accessToken);
                    const newProducts = [...products];
                    newProducts.splice(
                      products.findIndex((item) => item.id === rowData.id),
                      1
                    );
                    dispatch(setProducts(newProducts));
                    dispatch(refreshIframe());
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                Delete
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Box>
    );
  };

  const handleShopifySync: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (accessToken) {
      setSyncingFromShopify(true);
      syncProducts(accessToken)
        .then((response) => {
          const messages = [];
          if (response.new_products?.length) {
            messages.push(
              `${pluralize(
                "Product",
                response.new_products.length,
                true
              )} were created`
            );
          }
          if (response.updated_products?.length) {
            messages.push(
              `${pluralize(
                "Product",
                response.updated_products.length,
                true
              )} were updated`
            );
          }
          if (messages.length) {
            toast.success(messages.join(" and "));
          } else {
            toast.info("Nothing to sync");
          }
        })
        .finally(() => setSyncingFromShopify(false));
    }
  };

  const ToggleProductsVisibility = ({
    selectedRows,
  }: {
    selectedRows: Product[];
  }) => {
    const [isLoading, setIsLoading] = useState(false);
    const messageModalContext = useContext(MessageModalContext);
    const toEnable = selectedRows.filter((s) => s.status === "not_selling");
    const toDisable = selectedRows.filter(
      (s) => !s.status || s.status === "selling"
    );

    const batchUpdateProducts = (
      productsToUpdate: Product[],
      newStatus: "selling" | "not_selling"
    ) => {
      messageModalContext.showModal({
        title: "Confirm " + (newStatus === "selling" ? "enable" : "disable"),
        message: `You will ${
          newStatus === "selling" ? "enable" : "disable"
        } ${pluralize("Product", productsToUpdate.length, true)} and will ${
          newStatus === "selling" ? "" : "not"
        } be visible`,
        actions: [
          {
            label: "Cancel",
            isLeastDestructive: true,
          },
          {
            label: "Confirm",
            props: { colorScheme: "blue" },
            callback: async () => {
              try {
                setIsLoading(true);
                const res = await Promise.all(
                  productsToUpdate.map((s) => {
                    const toUpdate = new Product(s);
                    toUpdate.status = newStatus;
                    return putProduct(toUpdate.buildForUpdate(), accessToken);
                  })
                );
                setIsLoading(false);
                toast.success("Updated items");

                const newProducts = [...products];
                productsToUpdate.forEach((product, i) => {
                  const updated = res.find((r) => r.id === product.id);
                  if (updated) {
                    newProducts.splice(i, 1, updated);
                  }
                });
                dispatch(
                  setProducts(
                    Array.from(new Set(newProducts.map((s) => s.id)))
                      .map((id) => newProducts.find((s) => s.id === id))
                      .filter((s) => !!s) || []
                  )
                );
                dispatch(refreshIframe());
              } catch (error) {
                console.log(error);
              }
            },
          },
        ],
      });
    };
    return !toEnable.length || !toDisable.length ? (
      <Button
        leftIcon={
          !toEnable.length && !!toDisable.length ? (
            <MdVisibilityOff size="1rem" />
          ) : (
            <MdVisibility size="1rem" />
          )
        }
        isLoading={isLoading}
        variant="link"
        isDisabled={
          !(toEnable.length + toDisable.length) ||
          !userResources?.includes(RESOURCES.PRODUCTS_UPDATE)
        }
        size="md"
        aria-label={"Batch product disable or enable button"}
        onClick={async () => {
          const products = toEnable.length ? toEnable : toDisable;
          batchUpdateProducts(
            products,
            toEnable.length ? "selling" : "not_selling"
          );
        }}
      >
        {toDisable.length ? "Disable" : "Enable"}
      </Button>
    ) : (
      <Menu>
        <MenuButton
          as={Button}
          leftIcon={<MdVisibility size="1rem" />}
          isLoading={isLoading}
          variant="link"
          isDisabled={!userResources?.includes(RESOURCES.PRODUCTS_UPDATE)}
          aria-label={"Batch product disable or enable button"}
        >
          Enable or disable
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<MdVisibility size="1rem" />}
            onClick={() => batchUpdateProducts(toEnable, "selling")}
          >
            Enable {pluralize("Product", toEnable.length, true)}
          </MenuItem>
          <MenuItem
            icon={<MdVisibilityOff size="1rem" />}
            onClick={() => batchUpdateProducts(toDisable, "not_selling")}
          >
            Disable {pluralize("Product", toDisable.length, true)}
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  return (
    <VStack
      alignItems="flex-start"
      pos="relative"
      zIndex={1}
      height="100%"
      spacing={5}
    >
      {!!editingProduct && (
        <Box
          pos="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          backgroundColor="white"
          zIndex={99}
        >
          <ProductForm
            product={editingProduct}
            onFinish={(result) => {
              if (result) {
                const newProducts = [...products];
                newProducts[
                  products.findIndex((item) => item.id === result.id)
                ] = result;
                dispatch(setProducts(newProducts));
                dispatch(refreshIframe());
              }
              setEditingProduct(undefined);
            }}
          />
        </Box>
      )}
      {!viewingCorrelation && (
        <>
          <HStack w="100%" alignItems="flex-start">
            <Text textAlign="start">
              Keep your products updated to help your shoppers find you on
              shelve.
            </Text>
            <Spacer />
            {isShopifyConnected && (
              <Button
                leftIcon={<FaShopify />}
                onClick={handleShopifySync}
                isLoading={syncingFromShopify}
                variant="outline"
              >
                Sync Shopify Products
              </Button>
            )}
            <Button variant="outline" leftIcon={<FaVideo />} isDisabled>
              Questions? Watch video
            </Button>
          </HStack>
          <Divider />
        </>
      )}
      {!viewingCorrelation && (
        <Box w="100%" flex={1}>
          <EntityTable
            entityName="Product"
            containerProps={{ height: "100%" }}
            initialTableProps={{
              columns: [
                {
                  title: "Status",
                  dataType: DataType.String,
                  key: "status",
                  info: `Products that have the status (Enabled) will appear in your ${storeLabel} Locator`,
                  filterOptions: ["selling", "not_selling"].map((status) => ({
                    value: status,
                    label: status === "selling" ? "Enabled" : "Disabled",
                  })),
                  Render: (p) => <StatusSelect {...p} appDispatch={dispatch} />,
                  format: (value) => camelCaseWords(value),
                  sortDirection: SortDirection.Descend,
                  default: "None",
                },
                {
                  title: "Name",
                  dataType: DataType.String,
                  key: "name",
                  isRequired: true,
                },
                {
                  title: "Description",
                  dataType: DataType.String,
                  key: "description",
                },
                {
                  title: "Category",
                  dataType: DataType.String,
                  key: "category",
                  style: { width: 100 },
                },
                {
                  title: "Image",
                  info: `Product images are needed to view the product in ${storeLabel} Locator`,
                  key: "url",
                  Render: ProductImageField,
                  style: { width: 100 },
                },
                {
                  title: "Urls",
                  info: "Add product URLs to analyze the content of the url, such as comments and reviews.",
                  key: "urls",
                  Render: ProductUrlsField,
                  style: { width: 100 },
                  dataType: DataType.String,
                },
                {
                  title: "SKU",
                  dataType: DataType.String,
                  key: "sku",
                  style: { width: 80 },
                },
                {
                  title: "Origin",
                  dataType: DataType.String,
                  key: "origin",
                  Render: (props) => <Text>{props.rowData.origin}</Text>,
                  style: { width: 80 },
                  hideFilter: true,
                },
              ],
            }}
            LeftButton={OptionsButton}
            dataFromOutside={products?.map((prod) => ({
              ...prod,
              urls: JSON.stringify(prod.urls || []),
            }))}
            fetch={async () => {
              try {
                const wrappedProducts = await dispatch(
                  fetchProducts() as unknown as AnyAction
                );
                const unwrappedProducts = await unwrapResult(wrappedProducts);

                return unwrappedProducts.map((prod: Product) => ({
                  ...prod,
                  urls: JSON.stringify(prod.urls || []),
                }));
              } catch (e) {
                console.log(e);
              }
            }}
            allowBatchActions
            additionalBatchActions={[
              {
                Render: ToggleProductsVisibility,
              },
              {
                Render: ({ selectedRows }) =>
                  selectedRows.length === 1 ? (
                    <Tooltip label={`Edit product`}>
                      <Button
                        leftIcon={<FaEdit />}
                        variant="link"
                        isDisabled={
                          !userResources?.includes(RESOURCES.PRODUCTS_UPDATE)
                        }
                        aria-label={"Product edit button"}
                        onClick={() => setEditingProduct(selectedRows[0])}
                      >
                        Edit
                      </Button>
                    </Tooltip>
                  ) : (
                    <></>
                  ),
              },
            ]}
            deleteItem={
              userResources?.includes(RESOURCES.PRODUCTS_DELETE) &&
              ((pId) =>
                deleteProduct(pId, accessToken).then(() =>
                  dispatch(refreshIframe())
                ))
            }
          />
        </Box>
      )}
      {viewingCorrelation && (
        <ProductCorrelation
          obj={viewingCorrelation}
          setViewingCorrelation={setViewingCorrelation}
        />
      )}
    </VStack>
  );
}
export default ProductsTable;
