import {
  Box,
  BoxProps,
  FormControl,
  FormLabel,
  Switch,
  VStack,
} from "@chakra-ui/react";
import mapboxgl from "mapbox-gl";
import { useState } from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import { AUTH_TOKENS } from "../constants/default-settings";

mapboxgl.accessToken = AUTH_TOKENS.MAPBOX_TOKEN;

const DEFAULT_MAP_STYLE = "mapbox://styles/mapbox/light-v11";
const DARK_MAP_STYLE = "mapbox://styles/mapbox/dark-v11";

type Props = {
  coordinates?: { latitude?: number; longitude?: number };
  onChange?: (newCoordinates: { latitude: number; longitude: number }) => void;
  onChangeTheme?: (newTheme: "light" | "dark") => void;
  w?: number;
  h?: number;
  zoom?: number;
  draggable?: boolean;
  scrollZoom?:
    | boolean
    | {
        speed?: number | undefined;
        smooth?: boolean | undefined;
      };
  markerPin?: any;
  containerProps?: BoxProps;
  theme?: "light" | "dark";
};

export default function DraggableMapPin({
  coordinates: { latitude = 0, longitude = 0 } = { latitude: 0, longitude: 0 },
  w = 40,
  h = 40,
  zoom,
  onChange,
  draggable = true,
  scrollZoom = true,
  markerPin,
  containerProps = {},
  theme = "light",
  onChangeTheme,
}: Props) {
  const [mapZoom, setMapZoom] = useState(zoom || 15);
  const viewport = {
    width: w,
    height: h,
    latitude: isNaN(latitude) ? 0 : latitude,
    longitude: isNaN(longitude) ? 0 : longitude,
    zoom: zoom || mapZoom,
    bearing: 0,
    pitch: 0,
  };
  return (
    <VStack>
      <Box
        rounded="lg"
        shadow="md"
        w={w}
        h={h}
        overflow="hidden"
        {...containerProps}
      >
        <ReactMapGL
          mapboxApiAccessToken={AUTH_TOKENS.MAPBOX_TOKEN}
          {...viewport}
          width="100%"
          height="100%"
          onViewportChange={(vp: typeof viewport) => {
            setMapZoom(vp.zoom);
          }}
          mapStyle={theme === "dark" ? DARK_MAP_STYLE : DEFAULT_MAP_STYLE}
          attributionControl={false}
          scrollZoom={scrollZoom}
        >
          <Marker
            longitude={isNaN(longitude) ? 0 : longitude}
            latitude={isNaN(latitude) ? 0 : latitude}
            draggable={draggable}
            onDragEnd={(e: any) => {
              if (onChange && e)
                onChange({
                  latitude: e.lngLat["1"],
                  longitude: e.lngLat["0"],
                });
            }}
          >
            {markerPin}
          </Marker>
        </ReactMapGL>
      </Box>
      {onChangeTheme && (
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="theme" mb="0">
            Dark theme
          </FormLabel>
          <Switch
            id="theme"
            isChecked={theme === "dark"}
            onChange={(e) => {
              onChangeTheme(e.target.checked ? "dark" : "light");
            }}
          />
        </FormControl>
      )}
    </VStack>
  );
}
