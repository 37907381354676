import { unwrapResult } from "@reduxjs/toolkit";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { useCallback, useEffect, useRef } from "react";
import TagManager from "react-gtm-module";
import {
  selectAccessToken,
  setAccessToken,
  setCurrentUser,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { getUserViews, listUsers } from "../../services/api.service";
import { loginWithToken } from "../../services/auth.service";
import {
  changeActiveView,
  createView,
  setViews,
} from "../map-views/mapViewsSlice";

export function useAuth() {
  const params = new URLSearchParams(window.location.search);
  const urlToken = params.get("token") || "";
  const loaded = useRef(false);
  const accessToken = useAppSelector(selectAccessToken);
  const dispatch = useAppDispatch();
  const auth = getAuth();

  useEffect(() => {
    const signInAnonymousUser = async () => {
      loginWithToken(urlToken).then((dathicCredential) => {
        localStorage.clear();
        setTimeout(() => {
          localStorage.setItem(
            "dathicCredential",
            JSON.stringify(dathicCredential)
          );
          signInWithCustomToken(auth, urlToken);
        }, 1000);
      });
    };
    if (urlToken) {
      signInAnonymousUser();
    }
  }, [urlToken]);

  const handleUser = useCallback(
    async (user: any, dathicCredential: any) => {
      const { access_token } = dathicCredential;
      dispatch(setAccessToken(access_token));
      let userToHandle = user;
      if (user && !Object.keys(user.views || []).length) {
        const newView = {
          name: "Initial View",
          description: "This is the default initial view",
        };
        try {
          // @ts-ignore
          const wrappedResult = await dispatch(createView(newView));
          // @ts-ignore
          const unwrappedResult = await unwrapResult(wrappedResult);
          if (unwrappedResult) {
            userToHandle = { ...user, views: [`/${unwrappedResult.id}`] };
          }
        } catch (error) {
          console.log(error);
        }
      }
      const tagManagerArgs = {
        dataLayer: {
          event: "user_login",
          organization_id: userToHandle.organization_id,
          user_id: userToHandle.id,
        },
      };
      TagManager.dataLayer(tagManagerArgs);
      dispatch(setCurrentUser(userToHandle));
      const views = await getUserViews(user.id, access_token);
      const newViews = [...(views || [])].reduce((acc, view, index) => {
        return {
          ...acc,
          [`view${index}`]: {
            ...view,
            type: view.user_id !== user.id && "shared",
          },
        };
      }, {});
      if (Object.keys(newViews).length) {
        const firstNotClosedViewIndex = Object.values(newViews).findIndex(
          (view: any) => !view.config?.closed
        );
        dispatch(setViews(newViews));
        dispatch(changeActiveView(firstNotClosedViewIndex));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!accessToken) {
      auth.onAuthStateChanged(async (newUser) => {
        if (!newUser) {
          if (loaded.current) {
            localStorage.clear();
            window.location.reload();
          } else {
            dispatch(setCurrentUser(null));
          }
        } else {
          const dathicCredential = JSON.parse(
            localStorage.getItem("dathicCredential") || "{}"
          );
          if (dathicCredential?.access_token?.length) {
            const { user_id, access_token } = dathicCredential;
            const userFromDB = await listUsers(user_id, access_token);
            if (userFromDB && !userFromDB.length) {
              if (userFromDB.active === false) {
                auth.signOut();
              }
              const resources = userFromDB.roles?.reduce(
                (resources: any[], roleItem: any) =>
                  Array.from(
                    new Set([
                      ...resources,
                      ...(roleItem.role.resources?.map(
                        (resource: any) => resource.resource_id
                      ) || []),
                    ])
                  ),
                []
              );
              const { email, displayName, photoURL } = newUser;
              let user = {
                ...userFromDB,
                email,
                displayName,
                photoURL,
                resources,
              };
              handleUser(user, dathicCredential);
            } else {
              auth.signOut();
            }
          } else {
            auth.signOut();
          }
        }
        loaded.current = true;
      });
    }
  }, [accessToken, dispatch, handleUser]);
  return [];
}
