import { GetObjectCommand } from "@aws-sdk/client-s3";
import { Center, Progress, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { NotionRenderer } from "react-notion";
import "react-notion/src/styles.css";
import { selectS3Client } from "./appSlice";
import { useAppSelector } from "./store";

type Props = { tutorialFileName: string };

const getTutorialJson = async (tutorialFileName: string, s3client: any) => {
  const folderKey = encodeURIComponent("notion-tutorials") + "/";
  const fileName = `${tutorialFileName}.json`;
  const Key = folderKey + fileName;
  const Bucket = process.env.REACT_APP_S3_BUCKET;
  try {
    const objectData = await s3client.send(
      new GetObjectCommand({
        Bucket,
        Key,
      })
    );
    const streamToString = async (stream: ReadableStream) => {
      const reader = stream.getReader();
      const decoder = new TextDecoder("utf-8");
      let result = "";
      let done = false;

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        result += decoder.decode(value, { stream: !done });
      }

      return result;
    };

    const jsonString = await streamToString(objectData.Body);
    return JSON.parse(jsonString);
  } catch (err) {
    console.log(err);
    return null;
  }
};

export default function NotionTutorial({ tutorialFileName }: Props) {
  const s3Client = useAppSelector(selectS3Client);
  const [tutorialPage, setTutorialPage] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    if (tutorialFileName) {
      setIsLoading(true);
      getTutorialJson(tutorialFileName, s3Client)
        .then(setTutorialPage)
        .finally(() => setIsLoading(false));
    }
  }, [s3Client, tutorialFileName]);

  return isLoading ? (
    <Center>
      <Progress />
    </Center>
  ) : tutorialPage ? (
    <NotionRenderer blockMap={tutorialPage} />
  ) : (
    <Center>
      <Text>No page found</Text>
    </Center>
  );
}
