import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Text,
  VStack,
} from "@chakra-ui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { cloneDeep, merge } from "lodash";
import { DateTime } from "luxon";
import { SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import {
  selectOrgProperties,
  selectStates,
  updateOrgProperties,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import Form, { SchemaItem } from "../../components/Form";
import { urlPattern } from "../../utils/compareUtils";
import { OnlineOptionCard } from "./BuyOnlineOptions";

export type AdditionalOnlineSource = {
  buyUrl: string;
  imgUrl: string;
  source: string;
  origin: "user" | "dathic";
  isHidden: boolean | undefined;
  order?: number;
  deliveryArea?: {
    isNationwide: boolean;
    states: string[];
  };
};

type Props = {
  selectedSource?: number;
  onFinish?: (option: AdditionalOnlineSource | undefined) => void;
};

function AdditionalSourcesForm({ selectedSource, onFinish }: Props) {
  const orgProperties = useAppSelector(selectOrgProperties);
  const states = useAppSelector(selectStates);

  const storeLocatorConfig = orgProperties?.store_locator;
  const sources = storeLocatorConfig?.additionalSources || [];
  const dispatch = useAppDispatch();

  const formSchema: SchemaItem[] = [
    {
      key: "source",
      label: "Name",
      type: "string",
      isRequired: true,
      placeholder: `Enter Name`,
    },
    {
      key: "buyUrl",
      label: "Buy link",
      type: "string",
      registerOptions: {
        pattern: { value: urlPattern, message: "Invalid URL format" },
      },
      isRequired: true,
      placeholder: `Enter URL of the product's online store`,
    },
    {
      key: "imgUrl",
      label: "Option Image",
      type: "file",
      fileDropProps: {
        shouldUpload: true,
        s3Params: {
          folderName: "additional_source_images",
          getFileName: `${orgProperties?.organization?.id}_`.concat(
            DateTime.now().toFormat("yyyy-mm-dd_hh-mm-ss")
          ),
          isPublic: true,
        },
        maxImageWidth: 600,
        accept: "image/*",
      },
    },
    {
      key: "preview",
      label: "Preview",
      renderFormControl: (values) => (
        <FormControl>
          <FormLabel>Preview</FormLabel>
          <OnlineOptionCard option={values as AdditionalOnlineSource} />
        </FormControl>
      ),
    },
    {
      key: "deliveryArea",
      label: "Delivery area",
      type: "string",
      options: ["Nationwide", "Some states"],
    },
    {
      key: "deliveryStates",
      label: "Set delivery states",
      type: "list",
      isRequired: true,
      getOptionValue: (item: any) => item.label,
      options: states?.map((state) => ({
        key: state.abbr,
        label: state.name,
      })),
      isHidden: (values) => values?.deliveryArea !== "Some states",
    },
    {
      key: "order",
      label: "Order priority",
      type: "number",
      registerOptions: { valueAsNumber: true, min: 1 },
      placeholder: `Enter the priority. The lower the number the higher the order.`,
    },
  ];

  const saveChanges = async (newSources: AdditionalOnlineSource[]) => {
    const newOrgConfig = cloneDeep(orgProperties || {});
    merge(newOrgConfig, {
      store_locator: {
        additionalSources: [],
      },
    });
    // @ts-ignore
    newOrgConfig.store_locator = {
      // @ts-ignore
      ...newOrgConfig.store_locator,
      additionalSources: newSources.sort(
        (a, b) => (a?.order ?? 0) - (b?.order ?? 0)
      ),
    };
    const wrapped = await dispatch(updateOrgProperties(newOrgConfig));
    return unwrapResult(wrapped);
  };

  const onSubmit: SubmitHandler<AdditionalOnlineSource> = (values) => {
    const newSource: AdditionalOnlineSource = values;

    const newSources = [...sources];
    if (typeof selectedSource === "number") {
      newSources[+selectedSource] = newSource;
    } else {
      newSources.push(newSource);
    }
    saveChanges(newSources);
    if (onFinish) onFinish(newSource);
  };
  return (
    <VStack w="100%" h="100%" bg="white" p={3} rounded="lg">
      {onFinish && (
        <Button
          variant="link"
          alignSelf="flex-start"
          leftIcon={<ChevronLeftIcon />}
          onClick={() => {
            if (onFinish) onFinish(undefined);
          }}
        >
          Back
        </Button>
      )}
      <Text alignSelf="flex-start">
        Input information to add a new online option to your product locator
      </Text>
      <Box w="100%" flex={1}>
        <Form
          schema={formSchema}
          useFormProps={{
            defaultValues:
              typeof selectedSource === "number"
                ? sources[+selectedSource]
                : undefined,
          }}
          onSubmit={(values: any) => {
            if (!values.source) {
              toast.warn("Option name is required");
              return;
            }
            if (!values.buyUrl) {
              toast.warn("Option buy URL is required");
              return;
            }
            onSubmit(values);
          }}
        />
      </Box>
    </VStack>
  );
}
export default AdditionalSourcesForm;
