import {
  Box,
  Button,
  HStack,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { connect } from "react-redux";
import pluralize from "pluralize";
import { lowerCase } from "lodash";
import MessageModalContext from "../contexts/MessageModalContext";

const _UpgradePlanMessage = ({ orgConfig }) => {
  const statesLabel = orgConfig?.statesNameReplacement || "States";
  const zipcodeLabel = orgConfig?.zipcodeNameReplacement || "Zipcode";
  const limitations = [
    ...(orgConfig?.limitedStates?.map(() => ({
      entity: statesLabel,
      list: orgConfig.limitedStates,
    })) || []),
    ...(orgConfig?.limitedCounties?.map(() => ({
      entity: "county",
      list: orgConfig.limitedCounties,
    })) || []),
    ...(orgConfig?.limitedCities?.map(() => ({
      entity: "city",
      list: orgConfig.limitedCities,
    })) || []),
    ...(orgConfig?.limitedZipcodes?.map(() => ({
      entity: zipcodeLabel,
      list: orgConfig.limitedZipcodes,
    })) || []),
  ].reduce((acc, limit) => {
    const index = acc.findIndex((item) => item.entity === limit.entity);
    if (index > -1) {
      acc[index].count += 1;
    } else {
      acc.push({ ...limit, count: 1 });
    }
    return acc;
  }, []);
  return (
    <VStack alignItems="start">
      <Box bg="whitesmoke" p={5} w="100%">
        <Text>Your current plan includes:</Text>

        <UnorderedList>
          {limitations.map((limit) => {
            return (
              <ListItem>
                <HStack>
                  <Text>{pluralize(limit.entity, limit.count, true)}:</Text>
                  <Text>
                    {limit.list.join(", ").replace(/,([^,]*)$/, " and$1")}
                  </Text>
                </HStack>
              </ListItem>
            );
          })}
          {!limitations.length && (
            <ListItem>All available features for your country</ListItem>
          )}
        </UnorderedList>
      </Box>
      <Text>
        To upgrade your dathic growth plan, please{" "}
        <Button
          variant="link"
          onClick={() => window.open("mailto:info@dathic.com")}
        >
          email us at info@dathic.com
        </Button>
      </Text>
    </VStack>
  );
};
export const UpgradePlanMessage = connect()(_UpgradePlanMessage);

function LimitationsMessage({ orgConfig, includedInMessage }) {
  const modalsContext = useContext(MessageModalContext);
  const statesLabel = orgConfig?.statesNameReplacement || "States";
  const zipcodeLabel = orgConfig?.zipcodeNameReplacement || "Zipcode";
  const limitations = [
    ...(orgConfig?.limitedStates?.map(() => statesLabel) || []),
    ...(orgConfig?.limitedCounties?.map(() => "county") || []),
    ...(orgConfig?.limitedCities?.map(() => "city") || []),
    ...(orgConfig?.limitedZipcodes?.map(() => zipcodeLabel) || []),
  ]
    .filter(
      (entity) => !includedInMessage || !!includedInMessage.includes(entity)
    )
    .reduce((acc, entity) => {
      const index = acc.findIndex((item) => item.entity === entity);
      if (index > -1) {
        acc[index].count += 1;
      } else {
        acc.push({ entity, count: 1 });
      }
      return acc;
    }, []);
  const emptyLimits = Object.entries({
    [statesLabel]: orgConfig?.limitedStates?.length === 0,
    county: orgConfig?.limitedCounties?.length === 0,
    city: orgConfig?.limitedCities?.length === 0,
    [zipcodeLabel]: orgConfig?.limitedZipcodes?.length === 0,
  }).filter(
    ([entity]) => !includedInMessage || !!includedInMessage.includes(entity)
  );
  return (
    <div>
      {!!limitations.length && (
        <Text color="tomato">
          Your plan only includes{" "}
          {limitations
            .map((limit) => pluralize(limit.entity, limit.count, true))
            .join(", ")
            .replace(/,([^,]*)$/, " and$1")}
          .{" "}
        </Text>
      )}
      {emptyLimits
        .filter(([level, isEmpty]) => !!isEmpty)
        .map(([level, isEmpty]) => (
          <Text color="tomato">No {lowerCase(pluralize(level))}</Text>
        ))}
      {(!!limitations.length ||
        !!emptyLimits.filter(([level, isEmpty]) => !!isEmpty).length) && (
        <Button
          variant="link"
          color="tomato"
          onClick={() =>
            modalsContext.showModal({
              title: "Upgrade your plan",
              message: <UpgradePlanMessage orgConfig={orgConfig} />,
            })
          }
        >
          Upgrade for more
        </Button>
      )}
    </div>
  );
}

export default connect((state) => ({
  orgConfig: state.app.orgProperties?.properties,
}))(LimitationsMessage);
