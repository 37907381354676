import { Button } from "@chakra-ui/button";
import {
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/form-control";
import { Text } from "@chakra-ui/layout";
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from "@chakra-ui/modal";
import { Textarea } from "@chakra-ui/textarea";
import React, { useEffect, useRef, useState } from "react";
import { transform } from "../utils/cssTransform";
import { ResponsiveModal } from "./responsive-modal";

export default function StylesInputModal({
  isOpen,
  onClose,
  onOutputChange,
  initialInput,
}) {
  const [value, setValue] = useState(initialInput || "");
  const [error, setError] = useState();
  const inputCss = useRef();

  function inputTextUpdate(e) {
    setValue(e.target.value);
  }

  useEffect(() => {
    if (value === "") {
      setError(null);
      return;
    }
  }, [value]);

  const applyStyles = () => {
    if (!value) {
      if (onOutputChange) onOutputChange();
      setError(null);
      onClose();
      return;
    }

    try {
      var transformed = transform(value);

      var result = JSON.stringify(transformed);
      if (onOutputChange) onOutputChange(result);
      setError(null);
      onClose();
    } catch (ex) {
      setError(ex);
    }
  };
  return (
    <ResponsiveModal isOpen={isOpen} isCentered size="md">
      <ModalContent>
        <ModalBody>
          <ModalHeader>Styles</ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <FormControl isInvalid={!!error}>
            <FormLabel>Enter css styles for your component</FormLabel>
            <Textarea
              ref={inputCss}
              value={value}
              onChange={inputTextUpdate}
              placeholder="Type or paste CSS here..."
              isInvalid={!!error}
            />
            <FormHelperText>
              {error && <Text color="lightcoral">{`${error}`}</Text>}
            </FormHelperText>
          </FormControl>
          <Button onClick={applyStyles} float="right" colorScheme="blue">
            Apply
          </Button>
        </ModalBody>
      </ModalContent>
    </ResponsiveModal>
  );
}
