import React, { useState } from "react";
import { startCase, toLower } from "lodash";
import {
  selectChains,
  selectOrgProperties,
  selectProducts,
  selectStoreTypes,
} from "../../app/appSlice";
import LocationSearchInput from "../../components/LocationSearchInput";
import { Box, HStack, Select, VStack } from "@chakra-ui/react";
import { useAppSelector } from "../../app/store";

export default function SearchBlock() {
  const [filters, setFilters] = useState({ chain: "", type: "", product: "" });

  const types: string[] | undefined = useAppSelector(selectStoreTypes);
  const products = useAppSelector(selectProducts);
  const chains: string[] | undefined = useAppSelector(selectChains);
  const orgProperties = useAppSelector(selectOrgProperties);
  const zipcodeLabel =
    orgProperties?.properties?.zipcodeNameReplacement || "Zipcode";

  const storeTypeLabel =
    orgProperties?.properties?.storeTypeNameReplacement || "Type";
  const productLabel =
    orgProperties?.properties?.productNameReplacement || "Product";

  const searchWithTerm = async () => {};

  const getFilterList = {
    Product: [...(products || [])]
      .sort((a, b) => toLower(a.name).localeCompare(toLower(b.name)))
      .reduce((acc, p) => acc.set(p.id, startCase(toLower(p.name))), new Map()),
    Chain: [...(chains || [])]
      .sort((a, b) => toLower(a).localeCompare(toLower(b)))
      .reduce((acc, p) => acc.set(p, startCase(toLower(p))), new Map()),
    Type: [...(types || [])]
      .sort((a, b) => toLower(a).localeCompare(toLower(b)))
      .reduce((acc, p) => acc.set(p, startCase(toLower(p))), new Map()),
  };

  const getFilterTypeName = (filterType: string) => {
    switch ((filterType || "").toLowerCase()) {
      case "type":
        return storeTypeLabel;
      case "product":
        return productLabel;

      default:
        return filterType;
    }
  };

  return (
    <Box>
      <VStack spacing="10px" height="100%">
        <LocationSearchInput
          inputProps={{
            placeholder: `Search by Address or ${zipcodeLabel}`,
            rounded: "lg",
          }}
          onGeocode={searchWithTerm}
          style={undefined}
          onChange={undefined}
        />
        <HStack spacing="10px" width="100%">
          {orgProperties?.store_locator?.searchFilters?.map((filterType) => (
            <Select
              value={
                filters[
                  filterType.toLowerCase() as "chain" | "type" | "product"
                ]
              }
              onChange={(e) => {
                setFilters({
                  ...filters,
                  [filterType.toLowerCase()]: e.target.value,
                });
              }}
              placeholder={getFilterTypeName(filterType)}
            >
              <option value={""}>All</option>
              {Array.from(
                (
                  getFilterList[filterType as "Chain" | "Type" | "Product"] ||
                  new Map()
                ).entries()
              ).map((pair) => (
                <option value={pair[0]}>{startCase(toLower(pair[1]))}</option>
              ))}
            </Select>
          ))}
        </HStack>
      </VStack>
    </Box>
  );
}
