import { VStack } from "@chakra-ui/react";
import { startCase } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { PageSection } from "../../components/PageSection";
import { IntegrationCard } from "./integrations-modal";
import {
  Integration,
  selectAvailableIntegrations,
  selectIntegration,
  selectSelectedIntegration,
  toggleIntegrationsModal,
} from "./integrationSlice";

export default function OrgIntegrations() {
  const availableIntegrations = useSelector(selectAvailableIntegrations);
  const selectedIntegration = useSelector(selectSelectedIntegration);
  const dispatch = useDispatch();
  return (
    <VStack w="100%" spacing={10}>
      {Object.entries(
        availableIntegrations.reduce(
          (acc: { [category: string]: Integration[] }, integration) => {
            const { category } = integration;
            const integrations = acc?.[category] || [];
            return {
              ...acc,
              [category]: integrations.find((i) => i.category === category)
                ? integrations
                : [...integrations, integration],
            };
          },
          {}
        )
      ).map(([category, integrations]) => (
        <PageSection title={startCase(category)} key={category}>
          {integrations.map((i) => (
            <IntegrationCard
              availableIntegration={i}
              isSelected={i.id === selectedIntegration?.id}
              setSelectedIntegration={(v: Integration) => {
                dispatch(selectIntegration(v));
                dispatch(toggleIntegrationsModal(true));
              }}
            />
          ))}
        </PageSection>
      ))}
    </VStack>
  );
}
