import { get, post, put } from "axios";
import { getAuth } from "firebase/auth";
import TagManager from "react-gtm-module";
import { toast } from "react-toastify";
import {
  api_facebook_adaccounts,
  api_facebook_advertisement,
  api_facebook_auth,
  api_facebook_pages,
  base_url,
} from "../constants/serviceConfig";

const errorHandler = (error) => {
  const response = error.response;
  let message =
    response?.data?.error?.error_user_title ||
    response?.statusText ||
    error.message;
  const code = response?.status || 0;
  switch (code) {
    case 408:
      message = "Your request took too long";
      break;

    default:
      break;
  }
  const tagManagerArgs = {
    dataLayer: {
      event: "error",
      "gtm.errorMessage": `FBAPIError: ${message}`,
      "gtm.errorLineNumber": error.config?.params?.toString(),
      "gtm.errorUrl": error.config?.url,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
  toast.error(
    `Facebook API - ${message}: ${
      response?.data?.message ||
      response?.data?.error?.error_user_msg ||
      response?.data?.error?.message ||
      ""
    }`
  );
  if (response) {
    switch (response.data.msg) {
      case "Token has expired":
        const auth = getAuth();
        auth.signOut();
        break;

      default:
        break;
    }
  }
};

export async function facebookAuth(fbAccessToken, accessToken) {
  try {
    let params = new URLSearchParams();
    params.append("access_token", fbAccessToken);
    let url = base_url;
    url = url + api_facebook_auth;
    const result = await put(url, undefined, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
        "Content-Type": "application/json",
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function fetchAdAccounts(accessToken) {
  try {
    let url = base_url;
    url = url + api_facebook_adaccounts;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result?.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      console.log(error.response.data);
    }
    if (
      error?.response?.data?.error?.code !== 190 &&
      error.response?.status !== 401
    ) {
      errorHandler(error);
    }
    throw error;
  }
}

export async function fetchPages(accessToken) {
  try {
    let params = new URLSearchParams();
    params.append("access_token", accessToken);
    let url = base_url;
    url = url + api_facebook_pages;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getInstagramAccounts(
  accessToken,
  page_id,
  page_access_token
) {
  try {
    let params = new URLSearchParams();
    params.append("page_id", page_id);
    params.append("page_access_token", page_access_token);
    params.append("access_token", accessToken);
    let url = base_url;
    url = url + "api/facebook/" + page_id + "/instagram_accounts";
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getZipcodeAdvertisments(zipcodes = [], accessToken) {
  try {
    let params = new URLSearchParams();
    zipcodes.forEach((zipcode) => {
      params.append("zipcodes", zipcode);
    });
    let url = base_url;
    url = url + api_facebook_advertisement;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getCampaign(accountId, accessToken) {
  try {
    let params = new URLSearchParams();
    params.append("account_id", accountId);
    let url = base_url;
    url = url + "api/facebook/act_" + accountId + "/campaign";
    const result = await get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getAdsets(campaignId, accessToken) {
  try {
    let url = base_url;
    url = url + "api/facebook/" + campaignId + "/adsets";
    const result = await get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postAdImage(body, accountId, accessToken) {
  try {
    let url = base_url;
    url = url + "api/facebook/act_" + accountId + "/adimages";

    let data = new FormData();
    data.append("file", body);
    const result = await post(url, data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postAdVideo(body, accountId, accessToken) {
  try {
    let url = base_url;
    url = url + "api/facebook/act_" + accountId + "/advideo";

    let data = new FormData();
    data.append("file", body);
    const result = await post(url, data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getAdVideo(videoId, accessToken) {
  try {
    let url = base_url;
    url = url + "api/facebook/advideo/" + videoId;

    const result = await get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postImageCreative(body, accountId, accessToken) {
  try {
    let url = base_url;
    url = url + "api/facebook/act_" + accountId + "/creatives/image";
    const result = await post(url, body, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postVideoCreative(body, accountId, accessToken) {
  try {
    let url = base_url;
    url = url + "api/facebook/act_" + accountId + "/creatives/video";
    const result = await post(url, body, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getCreativePreview(placement, creativeId, accessToken) {
  try {
    let params = new URLSearchParams();
    params.append("ad_format", placement);
    params.append("access_token", accessToken);
    let url = base_url;
    url = url + "api/facebook/" + creativeId + "/previews";
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getCreatives(accountId, accessToken) {
  try {
    let url = base_url;
    url = url + "api/facebook/act_" + accountId + "/creatives";
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getAds(adsetId, accessToken) {
  try {
    let url = base_url;
    url = url + "api/facebook/" + adsetId + "/ads";
    const result = await get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postFullAdCampaign(body, accountId, accessToken) {
  try {
    let url = base_url;
    url = url + "api/facebook/act_" + accountId + "/full_ad_campaign";
    const result = await post(url, body, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getAdCampaginDefaults(accountId, accessToken) {
  try {
    let url = base_url;
    url = url + "api/facebook/act_" + accountId + "/ad_campaign_defaults";
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getAdsetInsights(
  accountId,
  accessToken,
  since,
  until,
  fields = [],
  adsetIds = []
) {
  try {
    let params = new URLSearchParams();
    params.append("since", since);
    params.append("until", until);
    if (adsetIds.length) {
      params.append(
        "filters",
        encodeURI(
          JSON.stringify({ field: "adset.id", operator: "IN", value: adsetIds })
        )
      );
    }
    let paramsObj = {
      fields,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key].forEach((param) => {
        params.append(key, param);
      });
    });
    let url = base_url;
    url = url + "api/facebook/act_" + accountId + "/insights/adset";
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getLeads(accessToken) {
  try {
    let url = base_url;
    url = url + "api/facebook/leads";
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function putLead(leadId, body, accessToken) {
  try {
    let url = base_url;
    url = url + "api/facebook/leads/" + leadId;
    const result = await put(url, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "If-Match": "*",
        "Content-Type": "application/json",
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}
