import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  HStack,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  useDisclosure,
  Text,
  Button,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { IoFilter, IoLayers } from "react-icons/io5";
import KeplerLayerList from "./KeplerLayerList";
import KeplerFilterList from "./KeplerFilterList";

export default function KeplerToolbar({
  width = 326,
  height = "77vh",
  containerProps,
  panelProps,
}) {
  const { getButtonProps, getDisclosureProps, isOpen } = useDisclosure();
  const [hidden, setHidden] = useState(!isOpen);
  return (
    <HStack alignItems="flex-start" {...containerProps}>
      <motion.div
        {...getDisclosureProps()}
        hidden={hidden}
        initial={false}
        onAnimationStart={() => setHidden(false)}
        onAnimationComplete={() => setHidden(!isOpen)}
        animate={{ width: isOpen ? width : 0, opacity: isOpen ? 1 : -1 }}
        style={{
          background: "white",
          width: width,
          height: height,
          textAlign: "start",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          overflowY: "auto",
          ...panelProps,
        }}
      >
        <Text fontWeight="bold" fontSize={18} pl={4} pt={6}>
          Map Customization
        </Text>
        <Tabs variant="line" display="flex" flexDir="column" flex={1}>
          <TabList>
            <Tab>
              <IoLayers style={{ marginRight: 5 }} />
              Layers
            </Tab>
            <Tab>
              <IoFilter style={{ marginRight: 5 }} />
              Filters
            </Tab>
          </TabList>
          <TabPanels flex={1}>
            <TabPanel>
              <KeplerLayerList />
            </TabPanel>
            <TabPanel>
              <KeplerFilterList />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </motion.div>
      <Button
        {...getButtonProps()}
        rightIcon={isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        iconSpacing={0}
      >
        {isOpen ? "" : "Customize Map"}
      </Button>
    </HStack>
  );
}
