import {
  Alert,
  Button,
  HStack,
  Spacer,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import StepWizard, { StepWizardChildProps } from "react-step-wizard";
import { selectStatusMessages } from "../app/appSlice";
import { useAppSelector } from "../app/store";
import { trackClick } from "../services/tracking.service";

type NavProps = {
  hoveringAlert: boolean;
};

const Nav = ({
  totalSteps,
  goToStep,
  currentStep,
  previousStep,
  nextStep,
  hoveringAlert,
}: StepWizardChildProps & NavProps) => {
  const count = useRef<number>(null);
  const timer = useRef<NodeJS.Timer>(null);
  useEffect(() => {
    if (totalSteps) {
      // @ts-ignore
      count.current = 1;
      if (timer.current) {
        clearInterval(timer.current);
      }
      if (!hoveringAlert) {
        // @ts-ignore
        timer.current = setInterval(() => {
          const nextStep = ((count.current || 1) % totalSteps) + 1;
          // @ts-ignore
          count.current = nextStep;
          goToStep(nextStep);
        }, 6000);
      }
    }

    return () => {
      if (timer.current) clearInterval(timer.current);
    };
  }, [totalSteps, hoveringAlert]);

  const dots = [];
  for (let i = 1; i <= totalSteps; i += 1) {
    const isActive = currentStep === i;
    dots.push(
      <span
        key={`step-${i}`}
        style={{
          color: "black",
          cursor: "pointer",
          fontSize: "30px",
          lineHeight: 1,
          opacity: 0.4,
          transition: "opacity 1s ease",
          willChange: "opacity, text-shadow",
          ...(isActive
            ? {
                color: "var(--chakra-colors-blue-400)",
                opacity: 1,
                textShadow: "0 0px 8px",
              }
            : {}),
        }}
        onClick={() => goToStep(i)}
      >
        &bull;
      </span>
    );
  }

  return dots.length > 1 ? (
    <HStack color="black" justifyContent="center" alignItems="center">
      <span
        style={{
          cursor: "pointer",
          fontSize: "24px",
          opacity: 0.4,
          marginRight: "auto",
          width: "30px",
        }}
        onClick={() => previousStep()}
      >
        {currentStep > 1 ? "<" : ""}
      </span>
      {dots}
      <span
        key={`next-arrow`}
        style={{
          cursor: "pointer",
          fontSize: "24px",
          opacity: 0.4,
          marginLeft: "auto",
          width: "30px",
        }}
        onClick={() => nextStep()}
      >
        {currentStep < totalSteps ? ">" : ""}
      </span>
    </HStack>
  ) : (
    <></>
  );
};
export const AlertsStepWizard = () => {
  const [hoveringAlert, setHoveringAlert] = useState(false);
  const statusMessages = useAppSelector(selectStatusMessages);

  useEffect(() => {}, []);
  return (
    <HStack flex={2} justifyContent="center">
      {/*// @ts-ignore*/}
      <StepWizard nav={<Nav hoveringAlert={hoveringAlert} />}>
        {statusMessages.map((message) => (
          <Alert
            bg="blue.400"
            rounded="xl"
            color="white"
            onMouseEnter={() => setHoveringAlert(true)}
            onMouseLeave={() => setHoveringAlert(false)}
          >
            {!!message.loading && <Spinner size="sm" />}
            <VStack>
              <Text>{message.title}</Text>
              {!!message.message && <Text>{message.message}</Text>}
            </VStack>
            <Spacer />
            {message.action && (
              <Button
                key={message.action.label}
                color="black"
                bg="white"
                minW={14}
                ml={3}
                onClick={() =>
                  trackClick(
                    `${message.id}-alert`,
                    typeof message.title === "string" ? message.title : "",
                    message.action?.callback &&
                      (() => message.action?.callback?.(0))
                  )
                }
                {...(message.action.props || {})}
              >
                {message.action.label}
              </Button>
            )}
          </Alert>
        ))}
      </StepWizard>
    </HStack>
  );
};
