import { Box, Flex, Heading, Text, VStack } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/stat";
import React, { useEffect, useState } from "react";
import { primary, secondaryDark } from "../../theme/colors";
import { EntityTable } from "../../components/entity-table/EntityTable";
import PieChart from "../../components/charts/pie-chart";
import { PieForTable } from "../../components/PieForTable";
import "./styles.css";
import { FilteringMode, SortDirection } from "ka-table/enums";
import { Button } from "@chakra-ui/button";
import { useNavigate } from "react-router";
import {
  getDemographicsByCity,
  getDemographicsByState,
} from "../../services/api.service";
import { connect } from "react-redux";
import { Divider, Spinner } from "@chakra-ui/react";

var pluralize = require("pluralize");

function createPieJSON(clickedObject, selectedAudience) {
  if (clickedObject) {
    const percentageNot =
      (clickedObject.not_hispanic_or_latino * 100) / clickedObject.total;
    const percentageAudience =
      (clickedObject[selectedAudience.id] * 100) / clickedObject.total;
    const percentageOther =
      ((clickedObject.hispanic_or_latino - clickedObject[selectedAudience.id]) *
        100) /
      clickedObject.total;
    return {
      children: [
        {
          name: "Not Latinos",
          hex: "#F68457",
          value: Math.round(percentageNot),
        },
        {
          name: "Latinos",
          children: [
            {
              name: selectedAudience.title,
              value: Math.round(percentageAudience),
              hex: "#0F7C4D",
            },
            {
              name: "Other Latinos",
              hex: "#436aa2",
              value: Math.round(percentageOther),
            },
          ],
        },
      ],
    };
  }
  return null;
}

function DemoReportModal({
  report,
  isOpen,
  onClose,
  accessToken,
  states,
  orgConfig,
}) {
  let navigate = useNavigate();
  const [predictedSales, setPredictedSales] = useState(0);
  const [citiesData, setCityData] = useState([]);
  const [zipcodesData, setZipcodesData] = useState([]);
  const [stateAvg, setStateAvg] = useState();
  const [bestCity, setBestCity] = useState();
  const [bestStore, setBestStore] = useState();
  const [pieData, setPieData] = useState();
  const [shouldRefreshPie, setShouldRefreshPie] = useState(false);
  const storeLabel = orgConfig?.storeNameReplacement || "Store";

  useEffect(() => {
    if (report.stores && isOpen) {
      const data = report.stores.reduce(
        (acc, store) => acc + Math.floor(+store.predicted_avg_units ?? 0),
        0
      );
      setPredictedSales(data);
    }
  }, [report, isOpen]);

  useEffect(() => {
    const parseData = (variable, groups) => {
      const incomeLimits = {};
      return Object.keys(groups)
        .map((groupKey) => {
          const demographics = groups[groupKey];
          const {
            age,
            engagement,
            housing_units,
            avg_rent,
            education,
            num_stores,
            transportation,
            travel_time,
            num_audience,
            audiences,
            total,
          } = demographics.reduce((variables, demographic) => {
            incomeLimits[groupKey] = {
              min: Math.min(
                incomeLimits[groupKey]?.min ?? Infinity,
                demographic.median_household_income
              ),
              max: Math.max(
                incomeLimits[groupKey]?.max ?? -Infinity,
                demographic.median_household_income
              ),
            };
            let population_by_age;
            let educational_attainment;
            let transportation;
            let travelTime;
            try {
              population_by_age = demographic.population_by_age;
              educational_attainment =
                demographic.educational_attainment_for_population_25_and_over;
              transportation =
                demographic.means_of_transportation_to_work_for_workers_16_and_over;
              travelTime = demographic.travel_time_to_work_in_minutes;
            } catch (error) {
              console.log(error);
            }
            let ageGroups =
              (population_by_age || []).find((item) => item.key === "Total")
                ?.values || [];
            let educationGroups =
              (educational_attainment || [])[0]?.values || [];
            let transportationGroups = (transportation || [])[0]?.values || [];
            let travelTimeGroups = (travelTime || [])[0]?.values || [];

            const prob = demographic[`prob_${report.selectedAudience.id}`];
            const nationalityTweets =
              (demographic.weekly_tweets ?? 0) * (prob ?? 0);
            const engagementAudience = {
              [report.selectedAudience.id]:
                (variables.engagement?.[report.selectedAudience.id] || 0) +
                (nationalityTweets || 0),
            };
            return {
              age: (ageGroups || []).map((group) => {
                const currentGroup = (variables.age || []).find(
                  (g) => g.x === group.x
                );
                return currentGroup
                  ? {
                      x: group.x,
                      y: group.y + (currentGroup?.y ?? 0),
                    }
                  : group;
              }),
              engagement: {
                total:
                  (variables.engagement?.total || 0) +
                  (demographic.weekly_tweets || 0),
                ...engagementAudience,
              },
              housing_units: [
                {
                  x: "Occupied",
                  y:
                    (variables.housing_units?.[0].y ?? 0) +
                    demographic.occupied_housing_units,
                },
                {
                  x: "Not Occupied",
                  y:
                    (variables.housing_units?.[1].y ?? 0) +
                    ((demographic.housing_units || 0) -
                      (demographic.occupied_housing_units || 0)),
                },
              ],
              avg_rent: Object.keys(demographic).reduce((demoRent, key) => {
                if (key.startsWith("monthly_rent")) {
                  let monthly_rent;
                  try {
                    monthly_rent = demographic[key];
                    let rentGroups = (monthly_rent || [])[0]?.values || [];
                    return rentGroups.map((rentGroup) => {
                      const group = demoRent.find((v) => v.x === rentGroup.x);
                      return {
                        x: rentGroup.x,
                        y: rentGroup.y + (group?.y ?? 0),
                      };
                    });
                  } catch (error) {}
                }
                return demoRent;
              }, variables.avg_rent || []),
              education: (educationGroups || []).map((group) => {
                const currentGroup = (variables.education || []).find(
                  (g) => g.x === group.x
                );
                return currentGroup
                  ? {
                      x: group.x,
                      y: group.y + (currentGroup?.y ?? 0),
                    }
                  : group;
              }),
              num_stores: report.stores?.reduce((numStores, store) => {
                if (store[variable] === groupKey) return numStores + 1;
                return numStores;
              }, 0),
              transportation: (transportationGroups || []).map((group) => {
                const currentGroup = (variables.transportation || []).find(
                  (g) => g.x === group.x
                );
                return currentGroup
                  ? {
                      x: group.x,
                      y: group.y + (currentGroup?.y ?? 0),
                    }
                  : group;
              }),
              travel_time: (travelTimeGroups || []).map((group) => {
                const currentGroupIndex = (
                  variables.travel_time_to_work || []
                ).findIndex((g) => g.x === group.x);
                if (currentGroupIndex >= 0) {
                  return {
                    x: group.x,
                    y: group.y + (travelTimeGroups[currentGroupIndex]?.y ?? 0),
                  };
                }
                return group;
              }),
              num_audience:
                demographic[report.selectedAudience.id] +
                  variables.num_audience ?? 0,
              audiences: {
                [report.selectedAudience.id]:
                  demographic[report.selectedAudience.id] +
                  ((variables.audiences ?? {})[report.selectedAudience.id] ??
                    0),
              },
              total: demographic.total + (variables.total || 0),
            };
          }, {});

          return {
            id: groupKey,
            num_audience,
            ...(audiences || {}),
            [variable]: groupKey,
            income:
              (incomeLimits[groupKey].max - incomeLimits[groupKey].min) / 2,
            age,
            engagement: [
              {
                y: engagement
                  ? engagement.total -
                    Object.keys(engagement).reduce(
                      (otherEngagement, key) =>
                        key !== "total"
                          ? otherEngagement + engagement[key]
                          : otherEngagement,
                      0
                    )
                  : 0,
                x: "Other",
              },
              ...(engagement
                ? Object.keys(engagement)
                    .filter((key) => key !== "total")
                    .map((key) => ({ y: engagement[key], x: key }))
                : []),
            ],
            housing_units,
            avg_rent,
            education,
            num_stores,
            transportation,
            travel_time,
            total,
          };
        })
        .sort((a, b) =>
          a.num_audience > b.num_audience
            ? -1
            : a.num_audience < b.num_audience
            ? 1
            : 0
        );
    };
    if (report && isOpen) {
      const cityGroups = report.demographics.reduce(
        (cities, demographic) => ({
          ...cities,
          [demographic.major_city]: [
            ...(cities[demographic.major_city] || []),
            demographic,
          ],
        }),
        {}
      );
      const data = parseData("city", cityGroups);
      setCityData(data);
      const zipcodeGroups = report.demographics.reduce(
        (zipcodes, demographic) => ({
          ...zipcodes,
          [demographic.zipcode]: [
            ...(zipcodes[demographic.zipcode] || []),
            demographic,
          ],
        }),
        {}
      );
      const zipData = parseData("zipcode", zipcodeGroups);
      setZipcodesData(zipData);
      setBestStore(
        zipData.reduce((_bestStore, demographic) => {
          const store = report.stores?.find(
            (store) => store.zipcode === demographic.zipcode
          );
          return _bestStore || store;
        }, undefined)
      );
      getDemographicsByState(
        [report.selectedAudience.id],
        [
          "state",
          "occupied_housing_units",
          "housing_units",
          "means_of_transportation_to_work_for_workers_16_and_over",
          "travel_time_to_work_in_minutes",
          "median_household_income",
          "weekly_tweets",
          "hispanic_or_latino",
          "not_hispanic_or_latino",
          "total",
        ],
        [states?.find((state) => state.name === report.selectedState)?.abbr],
        accessToken
      ).then((result) => {
        setStateAvg(result?.[0]);
      });
      getDemographicsByCity(
        [report.selectedAudience.id],
        [
          "major_city",
          "occupied_housing_units",
          "housing_units",
          "means_of_transportation_to_work_for_workers_16_and_over",
          "travel_time_to_work_in_minutes",
          "median_household_income",
          "weekly_tweets",
          "hispanic_or_latino",
          "not_hispanic_or_latino",
          "total",
        ],
        undefined,
        [states?.find((state) => state.name === report.selectedState)?.abbr],
        accessToken
      ).then((result) => {
        setBestCity(
          result?.length &&
            result?.reduce(
              (max, current) =>
                current[report.selectedAudience.id] >
                max[report.selectedAudience.id]
                  ? current
                  : max,
              result[0]
            )
        );
      });
    }
  }, [accessToken, isOpen, report, states]);

  useEffect(() => {
    if (report) {
      setShouldRefreshPie(true);
    }
  }, [report]);

  const RankNumber = ({ rowKeyValue, data }) => {
    const index = data.findIndex((item) => item.id === rowKeyValue);
    return (
      <Text
        h={46}
        w={46}
        borderRadius="full"
        bg={primary}
        fontWeight="Bold"
        fontSize={30}
        color="white"
        textAlign="center"
        pt={2}
      >
        {index + 1}
      </Text>
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <VStack
            p={8}
            alignItems="flex-start"
            bg={secondaryDark}
            color="white"
            flexDir="column"
            spacing={3}
          >
            <Text color={primary} fontWeight="Bold" fontSize={40}>
              MARKET ANALYSIS:{report.selectedState.toUpperCase()}
            </Text>
            <Text>Audience:{report.selectedAudience.title}</Text>
          </VStack>
          <StatGroup
            pt={10}
            pb={30}
            pr={16}
            pl={16}
            bg={primary}
            borderBottom="solid 1px #d3d3d3"
            mb={8}
            color="white"
          >
            <Stat>
              <StatLabel>Avg Income in state</StatLabel>
              <StatNumber>
                {stateAvg?.median_household_income?.toLocaleString(undefined, {
                  style: "currency",
                  currency: "USD",
                  currencyDisplay: "symbol",
                })}
              </StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Travel time to work</StatLabel>
              <StatNumber>
                {Object.keys(
                  stateAvg?.travel_time_to_work_in_minutes || {}
                ).reduce(
                  (max, current) =>
                    stateAvg?.travel_time_to_work_in_minutes[current] >
                    stateAvg?.travel_time_to_work_in_minutes[max]
                      ? current
                      : max,
                  Object.keys(stateAvg?.travel_time_to_work_in_minutes || {})[0]
                )}{" "}
                Minutes
              </StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Processed Digital Comments</StatLabel>
              <StatNumber>
                {stateAvg?.weekly_tweets?.toLocaleString()}
              </StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Visible {pluralize(storeLabel)}</StatLabel>
              <StatNumber fontSize={40} fontWeight="Bold">
                {(report.stores?.length || 0).toLocaleString()}
              </StatNumber>
              <StatHelpText>
                {`${
                  report.stores?.filter((store) => !!store.is_selected)
                    .length || 0
                } ${pluralize(storeLabel)} selected`}
              </StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Predicted Sales</StatLabel>
              {!isNaN(predictedSales) && predictedSales && (
                <StatNumber>{`${predictedSales.toLocaleString()} units`}</StatNumber>
              )}
              <StatHelpText>
                {predictedSales && !isNaN(predictedSales)
                  ? "Prediction for next period"
                  : `Rank ${pluralize(storeLabel)} to view prediction`}
              </StatHelpText>
            </Stat>
          </StatGroup>
          <Divider mb={5} />
          <Flex flexDir="row">
            <VStack>
              <Text fontWeight="Bold">
                {report.selectedAudience?.title}s in {report.selectedState}
              </Text>
              {stateAvg && (
                <PieChart
                  createData={() =>
                    createPieJSON(stateAvg, report.selectedAudience)
                  }
                  data={pieData}
                  initialFinalValue={{
                    finalPath: `${
                      states?.find(
                        (state) => state.name === report.selectedState
                      )?.abbr
                    } Population`,
                    value: stateAvg?.total?.toLocaleString(),
                  }}
                  onDataChange={setPieData}
                  shouldRefresh={shouldRefreshPie}
                  onRefresh={() => setShouldRefreshPie(false)}
                  formatValue={(v) => (v ? `${v} %` : "")}
                />
              )}
            </VStack>
            <Box>
              <Text fontWeight="Bold">{`${storeLabel} Opportunities`}</Text>
              <EntityTable
                initialTableProps={{
                  columns: [
                    {
                      title: "Score",
                      key: "score",
                      sortDirection: SortDirection.Descend,
                      filterRowOperator: ">",
                      format: (value) =>
                        ((value ?? 0) * 1000).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }),
                    },
                    {
                      title: "Name",
                      key: "name",
                    },
                  ],
                  filteringMode: FilteringMode.None,
                }}
                dataFromOutside={report.stores
                  ?.filter((store) => !store.in_store)
                  .slice(0, 7)}
              />
            </Box>
          </Flex>
          <Divider mb={5} mt={5} />
          <Flex flexDir="row">
            <VStack spacing={3} mr={5} flex={1} textAlign="center">
              <Text fontWeight="bold">AI Powered Insights</Text>
              <Flex
                borderRadius={10}
                boxShadow="0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
                flexDir="column"
                p={5}
              >
                <Text>{`Top city for ${
                  report.selectedAudience?.title || "audience"
                } in ${report.selectedState}`}</Text>
                {!bestCity && <Spinner />}
                {bestCity && (
                  <>
                    <Text fontWeight="Bold">{bestCity?.major_city}</Text>
                    <Text fontSize={14} mt={3}>
                      This is the best city for your audience because{" "}
                      <b>
                        {(
                          (bestCity?.[report.selectedAudience?.id] || 0) /
                          (bestCity?.total || 1)
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          style: "percent",
                        })}{" "}
                        of the population is {report.selectedAudience?.title}
                      </b>
                      , the median{" "}
                      <b>
                        income is $
                        {bestCity?.median_household_income?.toLocaleString()}
                      </b>
                      , the average{" "}
                      <b>
                        travel time is{" "}
                        {Object.keys(
                          bestCity?.travel_time_to_work_in_minutes || {}
                        ).reduce(
                          (max, current) =>
                            bestCity?.travel_time_to_work_in_minutes[current] >
                            bestCity?.travel_time_to_work_in_minutes[max]
                              ? current
                              : max,
                          Object.keys(
                            bestCity?.travel_time_to_work_in_minutes || {}
                          )[0]
                        )}{" "}
                        minutes
                      </b>{" "}
                      and generates more than{" "}
                      <b>
                        {bestCity?.weekly_tweets?.toLocaleString()} digital
                        interactions
                      </b>{" "}
                      per week.
                    </Text>
                  </>
                )}
              </Flex>
              <Flex
                borderRadius={10}
                boxShadow="0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
                flexDir="column"
                p={5}
              >
                <Text>{`Top ${pluralize(storeLabel)} for ${
                  report.selectedAudience?.title || "audience"
                } in ${report.selectedState}`}</Text>
                {(!bestStore || !(zipcodesData || [undefined])[0]) && (
                  <Spinner />
                )}
                {bestStore && (zipcodesData || [undefined])[0] && (
                  <>
                    <Text fontWeight="Bold">{bestStore?.address}</Text>
                    <Text fontSize={14} mt={3}>
                      This is the best {storeLabel} for your audience because
                      around the {storeLabel},{" "}
                      <b>
                        {(
                          ((zipcodesData || [undefined])[0]?.[
                            report.selectedAudience?.id
                          ] || 0) /
                          ((zipcodesData || [undefined])[0]?.total || 1)
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          style: "percent",
                        })}{" "}
                        of the population is {report.selectedAudience?.title}
                      </b>
                      , the median{" "}
                      <b>
                        income is $
                        {(zipcodesData || [
                          undefined,
                        ])[0]?.median_household_income?.toLocaleString()}
                      </b>
                      , the average{" "}
                      <b>
                        travel time is{" "}
                        {Object.keys(
                          (zipcodesData || [undefined])[0]
                            ?.travel_time_to_work_in_minutes || {}
                        ).reduce(
                          (max, current) =>
                            (zipcodesData || [undefined])[0]
                              ?.travel_time_to_work_in_minutes[current] >
                            (zipcodesData || [undefined])[0]
                              ?.travel_time_to_work_in_minutes[max]
                              ? current
                              : max,
                          Object.keys(
                            (zipcodesData || [undefined])[0]
                              ?.travel_time_to_work_in_minutes || {}
                          )[0]
                        )}{" "}
                        minutes
                      </b>{" "}
                      and generates more than{" "}
                      <b>
                        {(zipcodesData || [
                          undefined,
                        ])[0]?.weekly_tweets?.toLocaleString()}{" "}
                        digital interactions
                      </b>{" "}
                      per week.
                    </Text>
                  </>
                )}
              </Flex>
            </VStack>
            <VStack spacing={3} flex={1}>
              <Text fontWeight="bold">Top Zipcodes</Text>
              <Text>
                These are the best zipcodes for you because they have the most
                concentration of your audience and have the most digital
                interaction.
              </Text>
              <Flex
                borderRadius={10}
                boxShadow="0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
                flexDir="column"
                p={2}
              >
                {report.selectedAudience && (
                  <EntityTable
                    initialTableProps={{
                      columns: [
                        {
                          title: "Zipcode",
                          key: "zipcode",
                        },
                        {
                          title: report.selectedAudience.title,
                          key: report.selectedAudience.id,
                        },
                        {
                          title: "Digital Comments",
                          key: "weekly_tweets",
                        },
                        {
                          title: "City",
                          key: "major_city",
                        },
                      ],
                    }}
                    dataFromOutside={report.demographics
                      ?.sort((a, b) =>
                        a[report.selectedAudience?.id] >
                        b[report.selectedAudience?.id]
                          ? -1
                          : a[report.selectedAudience?.id] <
                            b[report.selectedAudience?.id]
                          ? 1
                          : 0
                      )
                      .filter((d, i) => i < 10)
                      .sort((a, b) =>
                        a.weekly_tweets > b.weekly_tweets
                          ? -1
                          : a.weekly_tweets < b.weekly_tweets
                          ? 1
                          : 0
                      )
                      .slice(0, 7)}
                  />
                )}
              </Flex>
            </VStack>
          </Flex>
          <Divider mb={5} mt={5} />

          <Box pr={16} pl={16} pos="relative">
            <Heading>Best cities for your audience</Heading>
            <EntityTable
              initialTableProps={{
                columns: [
                  {
                    title: "City",
                    key: "city",
                  },
                  {
                    title: "Age",
                    key: "age",
                    Render: PieForTable,
                  },
                  {
                    title: "Engagement",
                    key: "engagement",
                    Render: PieForTable,
                  },
                  {
                    title: "Housing",
                    key: "housing_units",
                    Render: PieForTable,
                  },
                  {
                    title: "Avg Rent",
                    key: "avg_rent",
                    Render: PieForTable,
                  },
                  {
                    title: "Education",
                    key: "education",
                    Render: PieForTable,
                  },
                  {
                    title: `Num ${pluralize(storeLabel)}`,
                    key: "num_stores",
                  },
                  {
                    title: "Transportation",
                    key: "transportation",
                    Render: PieForTable,
                  },
                  {
                    title: "Travel Time",
                    key: "travel_time",
                    Render: PieForTable,
                  },
                  {
                    title: "Recent News",
                    key: "recent_news",
                  },
                ],
                filteringMode: FilteringMode.None,
              }}
              LeftButton={({ rowKeyValue }) => (
                <RankNumber data={citiesData} rowKeyValue={rowKeyValue} />
              )}
              dataFromOutside={citiesData.slice(0, 7)}
            />
          </Box>

          <Box pr={16} pl={16} pos="relative">
            <Heading>Best zipcodes for your audience</Heading>
            <EntityTable
              initialTableProps={{
                columns: [
                  {
                    title: "Zipcode",
                    key: "zipcode",
                  },
                  {
                    title: "Age",
                    key: "age",
                    Render: PieForTable,
                  },
                  {
                    title: "Engagement",
                    key: "engagement",
                    Render: PieForTable,
                  },
                  {
                    title: "Housing",
                    key: "housing_units",
                    Render: PieForTable,
                  },
                  {
                    title: "Avg Rent",
                    key: "avg_rent",
                    Render: PieForTable,
                  },
                  {
                    title: "Education",
                    key: "education",
                    Render: PieForTable,
                  },
                  {
                    title: `Num ${pluralize(storeLabel)}`,
                    key: "num_stores",
                  },
                  {
                    title: "Transportation",
                    key: "transportation",
                    Render: PieForTable,
                  },
                  {
                    title: "Travel Time",
                    key: "travel_time",
                    Render: PieForTable,
                  },
                  {
                    title: "Recent News",
                    key: "recent_news",
                  },
                ],
                filteringMode: FilteringMode.None,
              }}
              LeftButton={({ rowKeyValue }) => (
                <RankNumber data={zipcodesData} rowKeyValue={rowKeyValue} />
              )}
              dataFromOutside={zipcodesData?.slice(0, 7)}
            />
          </Box>
          <VStack
            spacing={5}
            w={500}
            mt={5}
            ml="auto"
            mr="auto"
            borderRadius={10}
            p={5}
            boxShadow="0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
          >
            <Text>Full access is required to view complete report</Text>
            <Button
              variant="solid"
              colorScheme="blue"
              onClick={() =>
                navigate("/login", {
                  state: {
                    search: "?isRegistering=true",
                  },
                })
              }
            >
              Try for free
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default connect((state) => ({
  accessToken: state.app.accessToken,
  orgConfig: state.app.orgProperties?.properties,
  states: (
    state.app.properties?.find((properties) => properties.name === "states")
      ?.value || []
  )
    .map((val) => {
      try {
        return JSON.parse(val.replaceAll("'", '"'));
      } catch (error) {
        return undefined;
      }
    })
    .filter((val) => !!val),
}))(DemoReportModal);
