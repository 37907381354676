import { Button } from "@chakra-ui/button";
import { HStack, Text, VStack } from "@chakra-ui/layout";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {
  addOpenTour,
  selectAccessToken,
  selectCurrentUser,
  selectIsShopifyApp,
  selectOrgProperties,
  selectShopifyDomain,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { postBillingPortalSession } from "../../services/stripe.service";
import { secondaryDark } from "../../theme/colors";
import useLocatorPreviewCommunicator from "./LocatorPreviewCommunicator";
import { CheckoutStep } from "./StoreLocatorCheckout";
import { selectIframeKey } from "./storeLocatorSlice";

export const StoreLocatorPreview = () => {
  const navigate = useNavigate();
  const [iframeFailed, setIframeFailed] = useState(false);
  const [iframeSuccess, setIframeSuccess] = useState(false);

  const iframeKey = useAppSelector(selectIframeKey);
  const shopifyDomain = useAppSelector(selectShopifyDomain);
  const isShopifyApp = useAppSelector(selectIsShopifyApp);
  const orgProperties = useAppSelector(selectOrgProperties);
  const currentUser = useAppSelector(selectCurrentUser);
  const accessToken = useAppSelector(selectAccessToken);

  useLocatorPreviewCommunicator();

  const dispatch = useAppDispatch();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";

  const hasStripeBilling = Object.values(orgProperties?.properties || {}).find(
    (p: any) => !!p?.customer_id
  );

  useEffect(() => {
    if (
      orgProperties?.properties?.storeLocatorUrl &&
      !iframeSuccess &&
      !iframeFailed
    ) {
      var httpRequest = new XMLHttpRequest();
      httpRequest.onreadystatechange = function () {
        if (httpRequest.readyState === 4) {
          if (iframeRef.current && httpRequest.status === 200) {
            // success
            iframeRef.current.innerHTML = httpRequest.responseText;
            setIframeSuccess(true);
          } else {
            setIframeFailed(true);
          }
        }
      };
      httpRequest.open("GET", orgProperties?.properties?.storeLocatorUrl);
      httpRequest.send();
    }
  }, [iframeFailed, iframeSuccess, orgProperties]);

  const handleBillingPortal: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    postBillingPortalSession(accessToken).then((portalUrl) => {
      if (portalUrl) {
        window.open(portalUrl);
      }
    });
  };

  const storeLocatorUrl = useMemo(() => {
    if (!orgProperties?.properties?.storeLocatorUrl) return "";
    const url = new URL(orgProperties?.properties?.storeLocatorUrl || "");
    url.searchParams.set("adminToken", accessToken || "");
    url.searchParams.set("adminUserId", currentUser?.id || "");
    return url.toString();
  }, [accessToken, currentUser, orgProperties]);

  return (
    <>
      {orgProperties?.properties?.storeLocatorUrl && !iframeFailed && (
        <iframe
          key={iframeKey}
          ref={iframeRef}
          src={storeLocatorUrl}
          frameBorder="0"
          style={{
            border: 0,
            width: "100%",
            height: "100%",
          }}
          allowFullScreen
          title="store-locator"
        ></iframe>
      )}
      {orgProperties?.id && !orgProperties?.store_locator && (
        <VStack pb={20} w="100%" h="100%" display={"flex"}>
          <VStack w={"100%"} flex={1} bg={secondaryDark}>
            <CheckoutStep />
          </VStack>
          <Text fontWeight="bold">
            Looks like you haven't set up Dathic {storeLabel} Locator. Do you
            want to setup a {storeLabel} Locator?
          </Text>
          <Text>
            For information on the product{" "}
            <Button
              variant="link"
              onClick={() =>
                window.open("https://www.dathic.com/product-locator-plus")
              }
            >
              click here
            </Button>
          </Text>
          <Button
            colorScheme="blue"
            onClick={() => navigate("/storelocator/config")}
          >
            Set up
          </Button>
          <Button
            onClick={() => dispatch(addOpenTour("storelocatorTour"))}
            variant="link"
          >
            View tutorial
          </Button>
        </VStack>
      )}
      {orgProperties?.store_locator &&
        (!orgProperties?.properties?.storeLocatorUrl || iframeFailed) && (
          <VStack alignSelf="center" position="absolute" bottom={20} w="100%">
            <Text fontWeight="bold">
              In the meantime you can configure, manage and embed your
              {storeLabel} Locator.
            </Text>
            <HStack>
              <Button onClick={() => navigate("/storelocator/config")}>
                Manage
              </Button>
            </HStack>
          </VStack>
        )}
      {orgProperties?.store_locator?.status === "pendingpayment" &&
        !orgProperties?.properties?.storeLocatorUrl && (
          <VStack p={10} bg="white" borderRadius={10}>
            <Text fontWeight="bold" fontSize={18}>
              Your {storeLabel} Locator payment is still processing
            </Text>
            <Text>
              Please email us at{" "}
              <a href="mailto: info@dathic.com">info@dathic.com</a> if you have
              any questions.
            </Text>
          </VStack>
        )}
      {orgProperties?.store_locator?.status === "failedpayment" &&
        orgProperties?.properties?.storeLocatorUrl && (
          <VStack p={10} bg="white" borderRadius={10}>
            <>
              <Text fontWeight="bold" fontSize={18}>
                Your {storeLabel} Locator subscription failed to renew
              </Text>
              {hasStripeBilling && (
                <Button onClick={handleBillingPortal}>
                  Go to Stripe billing portal
                </Button>
              )}
              {isShopifyApp && (
                <Button
                  onClick={() =>
                    window.open(
                      `${window.location.protocol}//${shopifyDomain}/admin/settings/billing`
                    )
                  }
                >
                  Go to Shopify billing portal
                </Button>
              )}
              <Text>
                Please email us at{" "}
                <a href="mailto: info@dathic.com">info@dathic.com</a> if you
                have any questions.
              </Text>
            </>
          </VStack>
        )}
    </>
  );
};
