import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay
} from "@chakra-ui/modal";
import { Button, ButtonProps, HStack, Icon, ModalBodyProps, ModalHeaderProps, ModalProps } from "@chakra-ui/react";
import React, { PropsWithChildren, useCallback, useRef, useState } from "react";
import { IoMdCheckmarkCircleOutline, IoMdCloseCircleOutline, IoMdWarning } from "react-icons/all";

export type MessageAction = {
  label: string;
  callback?: (
    index: number,
    dialogState: any,
    setDialogState: React.Dispatch<React.SetStateAction<any>>
  ) => void;
  props?: ((
    index: number,
    dialogState: any,
    setDialogState: React.Dispatch<React.SetStateAction<any>>
  ) => ButtonProps) | ButtonProps;
  isLeastDestructive?: boolean;
  preventDismiss?: boolean;
  Render?: (renderProps: any) => React.ReactElement;
};

export type MessageModal<DialogState = {}> = {
  onClose?: MessageAction["callback"];
  modalProps?: Partial<ModalProps>;
  title?: ModalHeaderProps["children"];
  bodyProps?: ModalBodyProps
  message?:
    | string
    | React.ReactElement
    | ((
        dialogState: any,
        setDialogState: React.Dispatch<React.SetStateAction<any>>
      ) => React.ReactElement);
  variant?: "success" | "error" | "warning" | undefined;
  actions?: MessageAction[];
  hideCloseButton?: boolean;
  initialDialogState?: DialogState;
};

type ContextValue<DialogState = {}> = {
  visibleModals: MessageModal<DialogState>[];
  showModal: (item: MessageModal<DialogState>) => number;
  dismissModal: (index: number) => void;
};

const MessageModalContext = React.createContext<ContextValue>({
  visibleModals: [],
  showModal: () => {
    return 0;
  },
  dismissModal: () => {},
});

export const MessageModalsProvider = <DialogState = {},>(
  props: PropsWithChildren<unknown>
) => {
  const [visibleModals, setVisibleModals] = useState<
    MessageModal<DialogState>[]
  >([]);

  const showModal = useCallback<(item: MessageModal<DialogState>) => number>(
    (item) => {
      const newIndex = visibleModals.length;
      setVisibleModals((oldItems) => [...(oldItems || []), item]);
      return newIndex;
    },
    [visibleModals]
  );

  const dismissModal = useCallback<(index: number) => void>((index) => {
    setVisibleModals((oldItems) => {
      let newItems = [...oldItems];
      newItems.splice(index, 1);
      return newItems;
    });
  }, []);

  const { children } = props;

  function Dialog<DialogState>({
    index,
    item,
  }: {
    index: number;
    item: MessageModal<DialogState>;
  }) {
    const leastDestructiveButton = useRef(null);
    const [dialogState, setDialogState] = useState(item.initialDialogState);
    return (
      <AlertDialog
        key={index}
        isCentered
        scrollBehavior="inside"
        size="lg"
        {...(item.modalProps || {})}
        onClose={() => {
          dismissModal(index);
          if (item.onClose) item.onClose(index, dialogState, setDialogState);
        }}
        isOpen
        leastDestructiveRef={leastDestructiveButton}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay />

        <AlertDialogContent textAlign="start" alignItems="flex-start">
          {item.variant && (
            <Icon
              as={
                item.variant === "success"
                  ? IoMdCheckmarkCircleOutline
                  : item.variant === "warning" ? IoMdWarning : IoMdCloseCircleOutline
              }
              bg={item.variant === "success" ? "green.100" : item.variant === "warning" ? "orange" : "red.100"}
              rounded="full"
              boxSize="4em"
              color="gray.50"
              p="0.5em"
              mt={4}
              alignSelf="center"
            />
          )}
          {item.title && <AlertDialogHeader>{item.title}</AlertDialogHeader>}
          {!item.hideCloseButton && <AlertDialogCloseButton />}
          <AlertDialogBody w="100%" {...(item.bodyProps||{})}>
            <>
              {typeof item.message === "function"
                ? item.message(dialogState, setDialogState)
                : item.message}
            </>
          </AlertDialogBody>
          <AlertDialogFooter
            justifyContent="flex-end"
            w="100%"
            borderTop="1px solid gainsboro"
            as={HStack}
            spacing={5}
          >
            {item.actions?.length &&
              item.actions.map((action, i) =>
                action.Render ? (
                  <action.Render {...(dialogState || {})} />
                ) : (
                  <Button
                    key={action.label}
                    ref={
                      action.isLeastDestructive &&
                      !leastDestructiveButton.current
                        ? leastDestructiveButton
                        : undefined
                    }
                    onClick={() => {
                      action.callback?.(index, dialogState, setDialogState);
                      if (!action.preventDismiss) dismissModal(index);
                    }}
                    // @ts-ignore
                    isLoading={!!dialogState?.isLoading}
                    colorScheme={item.actions?.length === (i + 1) ? "blue" : undefined}
                    variant={(item.actions?.length ?? 0) > (i + 1) ? "link" : undefined}
                    {...((typeof action.props === "function" ? action.props(index, dialogState, setDialogState) : action.props) || {})}
                  >
                    {action.label}
                  </Button>
                )
              )}
            {!item.actions?.length && (
              <Button
                ref={leastDestructiveButton}
                onClick={() => dismissModal(index)}
                // @ts-ignore
                isLoading={!!dialogState?.isLoading}
                colorScheme="blue"
              >
                OK
              </Button>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  }

  return (
    <MessageModalContext.Provider
      value={{
        visibleModals,
  // @ts-ignore
        showModal,
        dismissModal,
      }}
    >
      {visibleModals?.map((item, index) => (
        <Dialog item={item} index={index} />
      ))}

      {children}
    </MessageModalContext.Provider>
  );
};

export default MessageModalContext;
