export function resizeImage(file, maxWidth, maxHeight, callback) {
  var img = new Image();
  img.src = URL.createObjectURL(file);

  img.onload = function () {
    var width = img.width;
    var height = img.height;

    // Calculate the new dimensions while maintaining the aspect ratio
    if (maxWidth && width > maxWidth) {
      height *= maxWidth / width;
      width = maxWidth;
    }

    if (maxHeight && height > maxHeight) {
      width *= maxHeight / height;
      height = maxHeight;
    }

    // Create a canvas element to draw the resized image
    var canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);

    // Convert the canvas to a blob and create a new File
    canvas.toBlob(function (blob) {
      var resizedFile = new File([blob], file.name, {
        type: file.type,
        lastModified: file.lastModified,
      });

      callback(resizedFile);
    }, file.type);
  };
}

export function checkAcceptAttribute(accept, file) {
  if (accept) {
    const acceptedTypes = accept
      .split(",")
      .map((type) => type.trim().toLowerCase());
    const fileExtension = file.name.split(".").pop(); // Get the file extension

    // Check if the file extension or MIME type matches any of the accepted types
    if (
      acceptedTypes.includes(`.${fileExtension.toLowerCase()}`) ||
      acceptedTypes.includes(file.type) ||
      acceptedTypes.find(
        (type) =>
          type.split("/")[0].toLowerCase() ===
          file.type.split("/")[0].toLowerCase()
      )
    ) {
      return true;
    }
  }

  return false;
}
