import { Button } from "@chakra-ui/button";
import { CheckIcon, EditIcon, TimeIcon, WarningIcon } from "@chakra-ui/icons";
import { Box, Code, Text, VStack } from "@chakra-ui/layout";
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Badge,
  Divider,
  FormControl,
  FormHelperText,
  HStack,
  IconButton,
  Input,
  ModalHeader,
  Select,
  SimpleGrid,
  Tag,
  TagLabel,
  TagRightIcon,
  Tooltip,
} from "@chakra-ui/react";
import {
  closeEditor,
  updateCellValue,
  updateEditorValue,
} from "ka-table/actionCreators";
import { ICellEditorProps, ICellTextProps } from "ka-table/props";
import { pick } from "lodash";
import pluralize from "pluralize";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FaCheck } from "react-icons/all";
import {
  removeOpenUploadProxy,
  selectAccessToken,
  selectOrgProperties,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  CustomColumn,
  EntityTable,
} from "../../components/entity-table/EntityTable";
import { ResponsiveModal } from "../../components/responsive-modal";
import MessageModalContext from "../../contexts/MessageModalContext";
import { Store } from "../../domain/Store";
import { postStoresBatch } from "../../services/api.service";
import { trackClick } from "../../services/tracking.service";
import { StoreCardPreview } from "../store-locator/StoreCardPreview";
import { StoreLocatorConfiguration } from "../store-locator/StoreLocatorConfig";
import { storeUploadValidation } from "./store-validators";
import { useUpload } from "./UploadHooks";

const CellEditorInput = ({
  props,
}: {
  props: ICellEditorProps & {
    isEditing?: boolean | undefined;
    data?: Store[] | undefined;
  };
}) => (
  <Input
    autoFocus
    onChange={(e) =>
      props.dispatch(
        updateEditorValue(props.rowKeyValue, props.column.key, e.target.value)
      )
    }
    onFocus={() => {
      props.dispatch(
        updateEditorValue(props.rowKeyValue, props.column.key, props.value)
      );
    }}
    onBlur={() => {
      props.dispatch(
        updateCellValue(props.rowKeyValue, props.column.key, props.editorValue)
      );
      props.dispatch(closeEditor(props.rowKeyValue, props.column.key));
    }}
    value={props.editorValue}
  />
);

const CompareStores = ({ storeToCompare }: { storeToCompare: Store }) => {
  const previewConfig = {
    storeDetails: {
      nameFormat: "{chain}",
      contactInfo: true,
      showAddress: true,
      showDescription: true,
      showStoreImage: false,
      showType: true,
    },
  } as StoreLocatorConfiguration;
  return (
    <HStack flex={1} justifyContent="center">
      <SimpleGrid columns={2} gap={3}>
        <Text fontSize={14}>Original</Text>
        <Text fontSize={14}>Found by Dathic</Text>
        <StoreCardPreview
          store={storeToCompare.original}
          config={previewConfig}
        />
        <StoreCardPreview
          store={storeToCompare.searched}
          config={previewConfig}
        />
      </SimpleGrid>
    </HStack>
  );
};

const ConfirmationMessage = ({
  stores,
  uploadedStores,
  selectedStores,
  existingStores,
  possibleDuplicates,
  storeLabel,
}: {
  stores: Store[];
  uploadedStores: Store[];
  selectedStores: number[];
  existingStores: number[];
  possibleDuplicates: number[];
  storeLabel: string;
}) => {
  const possibleDuplicate = stores.filter(
    (s) => possibleDuplicates.includes(s.id) && !s.issueSolution
  );
  const notFound = stores.filter(
    (item) =>
      item.searched?.store_validation !== "found_in_org" &&
      item.searched?.store_validation !== "duplicated"
  );
  return (
    <VStack divider={<Divider />} textAlign={"start"} spacing={5}>
      <VStack>
        <Text fontSize={18} fontWeight="bold" color="blue.400">
          {(stores.length || 0) + (uploadedStores.length || 0)} on file{" "}
          <TimeIcon />
        </Text>
      </VStack>
      {!!uploadedStores.length && (
        <VStack>
          <Text fontSize={18} fontWeight="bold" color="green">
            {uploadedStores.length || 0} disabled <CheckIcon />
          </Text>
        </VStack>
      )}
      {!!possibleDuplicate.length && (
        <VStack w="100%">
          <Text fontSize={18} fontWeight="bold" color="orange">
            {possibleDuplicate.length + notFound.length || 0} for review{" "}
            <WarningIcon />
          </Text>
          <Text w="100%">Check issues.</Text>
          <VStack
            pl={5}
            spacing={2}
            alignItems={"flex-start"}
            alignSelf="flex-start"
          >
            {!!possibleDuplicate.length && (
              <Text>
                <Code colorScheme="orange">{possibleDuplicate.length}</Code>{" "}
                duplicate on file
              </Text>
            )}
            {!!notFound.length && (
              <Text>
                <Code colorScheme="orange">{notFound.length}</Code> not found in
                your locator
              </Text>
            )}
          </VStack>
        </VStack>
      )}
      {!!selectedStores.length && (
        <VStack w="100%">
          <Text fontSize={18} fontWeight="bold" color="blue.400">
            {selectedStores.length || 0} ready to disable <CheckIcon />
          </Text>
          <Text w="100%">
            Confirm selection to disable from your list of locations.
          </Text>
          <VStack
            pl={5}
            spacing={2}
            alignItems={"flex-start"}
            alignSelf="flex-start"
          ></VStack>
        </VStack>
      )}
    </VStack>
  );
};

type Props = {
  items: Store[];
  onFinish: Function;
  onClose: Function;
  storeTypes?: (string | undefined)[];
};

function DisableStoresProxy({ items, onFinish, onClose, storeTypes }: Props) {
  const dispatch = useAppDispatch();
  const orgProperties = useAppSelector(selectOrgProperties);
  const accessToken = useAppSelector(selectAccessToken);
  const [uploadedStores, setUploadedStores] = useState<Store[]>([]);
  const { uploadItems } = useUpload({
    postBatch: async (items: any[], accessToken: string) => {
      const { store_id } = await postStoresBatch(
        accessToken,
        items.map((i) =>
          new Store({
            ...i,
            type: storeTypes?.includes("restaurant") ? "restaurant" : i.type,
            chain: i.chain,
          }).buildForPost()
        )
      );
      return store_id;
    },
  });
  const orgConfig = orgProperties?.properties;
  const [stores, setStores] = useState<any[]>([]);
  const [uploadingItems, setUploadingItems] = useState(false);
  const [possibleDuplicates, setPossibleDuplicates] = useState<number[]>([]);
  const [selectedStores, setSelectedStores] = useState<number[]>([]);
  const [existingStores, setExistingStores] = useState<number[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>("exists");
  const messageModalContext = useContext(MessageModalContext);
  const storeLabel = storeTypes?.includes("restaurant")
    ? "Restaurant"
    : orgConfig?.storeNameReplacement || "Store";

  useEffect(() => {
    setUploadedStores(
      items
        .filter(
          (item) =>
            item.searched?.store_validation === "found_in_org" &&
            item.status === "exists"
        )
        .map((s, id) => new Store({ ...s, id: id }))
    );
    const _items = items
      .filter(
        (item) =>
          !item.searched?.store_validation ||
          !item.status ||
          item.searched?.store_validation !== "found_in_org" ||
          item.status !== "exists"
      )
      .map((s) => ({ ...s, id: s.searched.id }));
    setStores(_items);
    if (_items) {
      const duplicates = _items.filter(
        (item) => item.searched?.store_validation === "duplicated"
      );
      const existingItems = _items.filter(
        (item) => item.searched?.store_validation === "found_in_org"
      );
      const newItems = _items.filter(
        (item) =>
          item.searched?.store_validation !== "found_in_org" &&
          item.searched?.store_validation !== "duplicated"
      );
      setPossibleDuplicates(duplicates.map((item) => item.searched.id));
      setSelectedStores((existingItems || []).map((item) => item.searched.id));
      setExistingStores((existingItems || []).map((item) => item.searched.id));
      messageModalContext.showModal({
        title: `${storeLabel} validation result`,
        message: (
          <ConfirmationMessage
            stores={_items.map((s) => ({ ...s, id: s.searched.id } as Store))}
            uploadedStores={items
              .filter(
                (item) =>
                  item.searched?.store_validation === "found_in_org" &&
                  item.status === "exists"
              )
              .map((s, id) => new Store({ ...s, id: id }))}
            selectedStores={(existingItems || []).map(
              (item) => item.searched.id
            )}
            existingStores={(existingItems || []).map(
              (item) => item.searched.id
            )}
            possibleDuplicates={duplicates.map((item) => item.searched.id)}
            storeLabel={storeLabel}
          />
        ),
        actions: [{ label: "Review" }],
      });
    }
  }, [items, storeLabel]);

  const duplicatePairs = useMemo(
    () => stores.map((s) => s.searched?.duplicate_pair).filter((d) => !!d),
    [stores]
  );

  const extendedFilter = (items: Store[]) => {
    return items?.filter((item: Store) => {
      return (
        (!selectedFilter && item.issueSolution !== "ignore") ||
        (selectedFilter === "duplicate" && duplicatePairs.includes(item.id)) ||
        selectedFilter === item.issue
      );
    });
  };

  function issueOnChange(rowData: Store, newValue: string) {
    let orgStore: any;
    setStores((oldSelection) => {
      const index = oldSelection.findIndex((s) => s.id === rowData.id);
      const newSelection = [...oldSelection];
      if (index > -1) {
        newSelection.splice(index, 1, {
          ...oldSelection[index],
          issueSolution: newValue,
        } as unknown as Store);
        orgStore = oldSelection[index].searched?.orgStore;
      }
      return newSelection;
    });
    setSelectedStores((old) => {
      const selectValue = newValue;
      return Array.from(
        new Set([
          ...old.filter((o) => selectValue !== "ignore" || o !== rowData.id),
          ...(["good", "original"].includes(selectValue) ? [rowData.id] : []),
        ])
      );
    });
  }

  const getIssueOnChange = (rowData: Store) => {
    return ((e) => {
      const newValue = e.currentTarget.value;
      issueOnChange(rowData, newValue);
    }) as React.ChangeEventHandler<HTMLSelectElement>;
  };

  const SelectDuplicate = (props: ICellTextProps) => {
    const { rowData } = props;
    return (
      <FormControl>
        {!rowData.issueSolution && (
          <FormHelperText>Possible duplicate</FormHelperText>
        )}
        <Select
          isInvalid={!rowData.issueSolution}
          value={rowData.issueSolution}
          onChange={getIssueOnChange(rowData)}
        >
          <option value={undefined} disabled selected>
            - Resolve issue -
          </option>
          <option value={"ignore"}>Ignore duplicate</option>
          <option value={"good"}>It's not a duplicate</option>
        </Select>
      </FormControl>
    );
  };

  const WarningButton = (props: ICellTextProps) => {
    const { rowData } = props;
    const store = stores.find((s) => s.id === rowData.id);
    return !rowData.issueSolution ? (
      <FormControl>
        <FormHelperText>Please review matched {storeLabel}</FormHelperText>
        <Button
          colorScheme="orange"
          onClick={() => {
            messageModalContext.showModal({
              title: `Are these ${pluralize(storeLabel)} the same?`,
              message: <CompareStores storeToCompare={store} />,
              actions: [
                {
                  label: `Ignore`,
                  callback: () => issueOnChange(rowData, "ignore"),
                },
                {
                  label: "Use original",
                  callback: () => {
                    issueOnChange(
                      {
                        ...store.original,
                        searched: store.searched,
                        original: store.original,
                        id: store.searched.id,
                      },
                      "original"
                    );
                  },
                },
                {
                  label: "Use verified",
                  isLeastDestructive: true,
                  props: { colorScheme: "blue" },
                  callback: () => {
                    const { id, order, store_id, store_validation, ...rest } =
                      store.searched || {};
                    issueOnChange({ ...rowData, ...rest }, "good");
                  },
                },
              ],
            });
          }}
        >
          Review
        </Button>
      </FormControl>
    ) : (
      <></>
    );
  };

  const FoundIndicator = ({ value, rowData }: any) => {
    const found = value === "found";
    const store = stores.find((s) => s.id === rowData.id);
    return (
      <Tooltip
        hasArrow
        label={
          found
            ? `We have found the location in our records. Click over it to validate.`
            : `We have no record of the location`
        }
      >
        <HStack w="100%">
          {found ? (
            <Tag colorScheme="green">
              <TagLabel>Verified</TagLabel>
              <TagRightIcon as={FaCheck} />
            </Tag>
          ) : (
            <Tag>
              <TagLabel>Not verified</TagLabel>
            </Tag>
          )}
          {rowData.found === "found" && (
            <IconButton
              aria-label={"edit match"}
              icon={<EditIcon />}
              variant="link"
              onClick={() => {
                messageModalContext.showModal({
                  title: `Are these ${pluralize(storeLabel)} the same?`,
                  message: <CompareStores storeToCompare={store} />,
                  actions: [
                    {
                      label: "Use original",
                      callback: () => {
                        const { id } = store.searched || {};
                        issueOnChange(
                          {
                            ...store.original,
                            searched: store.searched,
                            original: store.original,
                            id,
                          },
                          "good"
                        );
                      },
                    },
                    {
                      label: "Use verified",
                      isLeastDestructive: true,
                      props: { colorScheme: "blue" },
                      callback: () => {
                        const {
                          id,
                          order,
                          store_id,
                          store_validation,
                          ...rest
                        } = store.searched || {};
                        issueOnChange(
                          {
                            ...rowData,
                            ...rest,
                          },
                          "good"
                        );
                      },
                    },
                  ],
                });
              }}
            />
          )}
        </HStack>
      </Tooltip>
    );
  };

  const _onClose = () => {
    dispatch(removeOpenUploadProxy("DisableStoresProxy"));
    onClose?.();
  };

  const TextCellContent = (
    props: ICellEditorProps & {
      isEditing?: boolean | undefined;
      data?: Store[] | undefined;
    }
  ) => {
    if (!props.isEditing) {
      const store = stores.find((s) => s.id === props.rowData.id);
      return (
        <Text
          color={
            props.value === store?.searched?.[props.field] &&
            store.original?.[props.field] !== store?.searched?.[props.field]
              ? "green"
              : undefined
          }
        >
          {props.value}
        </Text>
      );
    } else {
      return <CellEditorInput props={props} />;
    }
  };

  const columns: CustomColumn<Store>[] = [
    {
      title: "Status",
      key: "issue",
      filterOptions: ["duplicate", "exists", "no_issue"].map((value) => ({
        value,
        label:
          value === "duplicate"
            ? "Possible duplicate"
            : value === "exists"
            ? "Ready to disable"
            : "Not found",
      })),
      width: 200,
      Render: (props) =>
        props.rowData.issue === "duplicate" ? (
          <SelectDuplicate {...props} />
        ) : props.rowData.issue === "exists" ? (
          <Text>Ready to disable</Text>
        ) : props.rowData.issue === "warning" ? (
          <WarningButton {...props} />
        ) : (
          <Text>Not found</Text>
        ),
    },
    {
      title: "Name",
      key: "name",
      Render: TextCellContent,
    },
    {
      title: "Chain",
      key: "chain",
      Render: TextCellContent,
    },
    {
      title: "Address",
      key: "address",
      style: { width: 200 },
      Render: TextCellContent,
    },
    {
      title: "State",
      key: "state",
      Render: TextCellContent,
    },
    {
      title: "City",
      key: "city",
      Render: TextCellContent,
    },
    {
      title: "Zipcode",
      key: "zipcode",
      Render: TextCellContent,
    },
  ];

  const storesForTable = useMemo(
    () =>
      (
        stores
          .map(
            (store: Store) =>
              ({
                ...store,
                issue:
                  store.searched?.store_validation === "duplicated"
                    ? "duplicate"
                    : store.searched?.store_validation === "found_in_org"
                    ? "exists"
                    : store.searched?.store_validation === "found_warning"
                    ? "warning"
                    : store.searched?.store_validation === "uploaded"
                    ? "uploaded"
                    : "no_issue",
                found: store.parent_id ? "found" : "not_found",
              } as unknown as Store)
          )
          .sort((a, b) =>
            a.issue === b.issue
              ? 0
              : !!a.issue && b.issue === "no_issue"
              ? -1
              : 1
          )
          .reduce((previousValue, currentValue, _, array) => {
            if (currentValue.searched?.duplicate_pair) {
              const duplicatePair: Store | undefined = array.find(
                (s) => s.searched?.id === currentValue.searched?.duplicate_pair
              );
              return [
                ...previousValue.filter(
                  (s: Store) =>
                    s.searched?.id !== currentValue.searched?.duplicate_pair
                ),
                currentValue,
                ...(duplicatePair ? [duplicatePair] : []),
              ] as Store[];
            }
            return [...previousValue, currentValue] as Store[];
          }, [] as Store[])
          .reduce(
            (acc: any[], store: { id: any }) =>
              acc.find((s) => {
                return s.id === store.id;
              })
                ? acc
                : [...acc, store],
            []
          ) as Store[]
      ).map((s) =>
        pick(s, ["id", "issueSolution", ...columns.map((c) => c.key)])
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stores]
  );

  async function putItem(item: Store) {
    const validatedStores = await storeUploadValidation(
      [new Store(item).buildForPost()],
      accessToken || "",
      [],
      orgProperties?.properties?.country,
      storeLabel,
      orgProperties?.organization_id
    );
    const validatedStore = (validatedStores?.[0] || item) as Store;
    const updatedStore = {
      ...validatedStore,
      id: validatedStore.searched?.id || item.id,
    };
    setStores((old) => {
      const n = [...old];
      n.splice(
        n.findIndex((s) => s.id === item.id),
        1,
        updatedStore
      );
      return n;
    });
    setPossibleDuplicates((old) => old.filter((o) => o !== item.id));
    setExistingStores((old) => old.filter((o) => o !== item.id));
    setSelectedStores((old) => old.filter((o) => o !== item.id));
    const listChange: React.SetStateAction<number[]> = (old) => [
      ...old,
      validatedStore.searched?.id || item.id,
    ];
    switch (validatedStore?.searched?.store_validation || "") {
      case "duplicated":
        setPossibleDuplicates(listChange);
        break;
      case "found_in_org":
        setExistingStores(listChange);
        break;

      default:
        setSelectedStores(listChange);
        break;
    }
    return updatedStore;
  }

  return (
    <ResponsiveModal
      isOpen={!!items}
      onClose={_onClose}
      isCentered
      scrollBehavior="inside"
      size="full"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          trackClick("disable-locations-submit", storeTypes?.[0] || "", () =>
            messageModalContext.showModal({
              title: `Confirm ${storeLabel} disable`,
              message: (
                <ConfirmationMessage
                  stores={stores}
                  uploadedStores={uploadedStores}
                  selectedStores={selectedStores}
                  existingStores={existingStores}
                  possibleDuplicates={possibleDuplicates}
                  storeLabel={storeLabel}
                />
              ),
              actions: [
                { label: "Cancel", isLeastDestructive: true },
                {
                  label: "Confirm",
                  callback: () => {
                    trackClick(
                      "disable-locations-confirm",
                      storeTypes?.[0] || ""
                    );
                    if (onFinish)
                      onFinish(
                        stores.filter((s) => selectedStores.includes(s.id))
                      );
                    _onClose();
                  },
                  props: { colorScheme: "blue", mt: 5 },
                },
              ],
            })
          );
        }}
      >
        <ModalOverlay />
        <ModalContent h="100%">
          <ModalCloseButton
            onClick={() => {
              onFinish();
              _onClose();
            }}
          />

          <ModalHeader textAlign="center">Disabling locations</ModalHeader>

          <ModalBody>
            <VStack spacing={5} h="100%">
              <VStack spacing={0} textAlign="center">
                <Text>
                  Check the checkbox to select a location. Only selected
                  locations will be disabled. To confirm your selections, click{" "}
                  <Tag variant="subtle" size="lg">
                    <TagLabel fontSize={18}>Disable</TagLabel>
                  </Tag>{" "}
                  👇
                </Text>
                <Text fontSize={14} fontStyle="italic">
                  Make sure the data is correct before disabling. To modify a
                  field, click on it.
                </Text>
              </VStack>
              <HStack spacing={5} w="100%">
                {!!stores.filter((s) => existingStores.includes(s.id))
                  .length && (
                  <Tooltip
                    label={`Confirm selection to disable from your list of locations.`}
                  >
                    <Tag
                      variant="subtle"
                      cursor={"pointer"}
                      colorScheme={
                        selectedFilter === "exists" ? "blue" : undefined
                      }
                      size="lg"
                      onClick={() => setSelectedFilter("exists")}
                      border={selectedFilter === "exists" ? "2px" : undefined}
                    >
                      <TagLabel>
                        <Badge fontSize="18">
                          {existingStores.length || 0}
                        </Badge>{" "}
                        ready to disable
                      </TagLabel>
                      <TagRightIcon as={CheckIcon} />
                    </Tag>
                  </Tooltip>
                )}
                {!!stores.filter(
                  (item) =>
                    item.searched?.store_validation !== "found_in_org" &&
                    item.searched?.store_validation !== "duplicated"
                ).length && (
                  <Tooltip
                    label={`Locations that were not found in your locator`}
                  >
                    <Tag
                      variant="subtle"
                      cursor={"pointer"}
                      onClick={() => setSelectedFilter("no_issue")}
                      colorScheme={
                        selectedFilter === "no_issue" ? "blue" : undefined
                      }
                      size="lg"
                      border={selectedFilter === "no_issue" ? "2px" : "none"}
                    >
                      <TagLabel>
                        <Badge fontSize="18">
                          {stores.filter(
                            (item) =>
                              item.searched?.store_validation !==
                                "found_in_org" &&
                              item.searched?.store_validation !== "duplicated"
                          ).length || 0}
                        </Badge>{" "}
                        not found
                      </TagLabel>
                      <TagRightIcon as={WarningIcon} color="orange" />
                    </Tag>
                  </Tooltip>
                )}
                {!!stores.filter((s) => possibleDuplicates.includes(s.id))
                  .length && (
                  <Tooltip label={`Check issues.`}>
                    <Tag
                      variant="subtle"
                      cursor={"pointer"}
                      colorScheme={
                        selectedFilter === "duplicate" ? "blue" : undefined
                      }
                      size="lg"
                      onClick={() => setSelectedFilter("duplicate")}
                      border={
                        selectedFilter === "duplicate" ? "2px" : undefined
                      }
                    >
                      <TagLabel>
                        <Badge fontSize="18">
                          {stores.filter(
                            (s) =>
                              possibleDuplicates.includes(s.id) &&
                              !s.issueSolution
                          ).length || 0}
                        </Badge>{" "}
                        duplicated on file
                      </TagLabel>
                      <TagRightIcon as={WarningIcon} color="orange" />
                    </Tag>
                  </Tooltip>
                )}
                {!!uploadedStores.length && (
                  <Tooltip label={`Items you have disabled from this file.`}>
                    <Tag
                      variant="subtle"
                      cursor={"pointer"}
                      onClick={() => setSelectedFilter("uploaded")}
                      colorScheme={
                        selectedFilter === "uploaded" ? "blue" : undefined
                      }
                      size="lg"
                      border={selectedFilter === "uploaded" ? "2px" : undefined}
                    >
                      <TagLabel>
                        <Badge fontSize="18">
                          {uploadedStores.length || 0}
                        </Badge>{" "}
                        disabled
                      </TagLabel>
                      <TagRightIcon as={CheckIcon} color="green" />
                    </Tag>
                  </Tooltip>
                )}
                <Tooltip label={`The total of items for you to review`}>
                  <Tag
                    variant="subtle"
                    cursor={"pointer"}
                    onClick={() => setSelectedFilter("")}
                    colorScheme={!selectedFilter ? "blue" : undefined}
                    size="lg"
                    border={!selectedFilter ? "2px" : undefined}
                  >
                    <Text fontSize={18} fontWeight="bold" mr={2}>
                      {stores?.filter((s) => s.issueSolution !== "ignore")
                        .length || 0}
                    </Text>
                    <TagLabel>awaiting disable</TagLabel>
                    <TagRightIcon as={TimeIcon} />
                  </Tag>
                </Tooltip>
              </HStack>
              <Box flex={1} w="100%">
                {selectedFilter === "uploaded" ? (
                  <EntityTable
                    containerProps={{ height: "100%" }}
                    initialTableProps={{
                      columns: [
                        {
                          title: "Status",
                          key: "issue",
                          width: 200,
                          Render: (props) => (
                            <Tag colorScheme="green">
                              <TagLabel>Disabled</TagLabel>
                              <TagRightIcon as={FaCheck} />
                            </Tag>
                          ),
                        },
                        {
                          title: "Name",
                          key: "name",
                        },
                        {
                          title: "Chain",
                          key: "chain",
                        },
                        {
                          title: "Address",
                          key: "address",
                          style: { width: 200 },
                        },
                        {
                          title: "State",
                          key: "state",
                        },
                        {
                          title: "City",
                          key: "city",
                        },
                        {
                          title: "Zipcode",
                          key: "zipcode",
                        },
                      ],
                    }}
                    dataFromOutside={uploadedStores.map((s) =>
                      pick(s, [
                        "id",
                        "name",
                        "chain",
                        "address",
                        "state",
                        "city",
                        "zipcode",
                        "issue",
                      ])
                    )}
                  />
                ) : (
                  <EntityTable
                    containerProps={{ height: "100%" }}
                    disableRowSelection={selectedFilter === "no_issue"}
                    extendedFilter={extendedFilter}
                    putItem={async (id, item, field) => {
                      await putItem(item);
                    }}
                    selectedItems={selectedStores || []}
                    onSelectedChange={(newSelection) => {
                      setSelectedStores(newSelection?.map((s) => s.id) || []);
                      setStores((old) =>
                        old.map((o) =>
                          newSelection?.map((s) => s.id).includes(o.id)
                            ? ({
                                ...o,
                                issueSolution: "good",
                              } as unknown as Store)
                            : o
                        )
                      );
                    }}
                    initialTableProps={{
                      columns,
                    }}
                    allowBatchActions
                    additionalBatchActions={[
                      ...(stores.find(
                        (s) =>
                          possibleDuplicates.includes(s.id) &&
                          s.issueSolution !== "ignore"
                      )
                        ? [
                            {
                              label: "Ignore all duplicates",
                              onSubmit: () => {
                                setStores((old) =>
                                  old.map((o) => {
                                    const isDup = possibleDuplicates.includes(
                                      o.id
                                    );
                                    return isDup
                                      ? ({
                                          ...o,
                                          issueSolution: "ignore",
                                        } as unknown as Store)
                                      : o;
                                  })
                                );
                                setSelectedStores((old) => {
                                  return [
                                    ...old.filter(
                                      (o) =>
                                        !possibleDuplicates.find(
                                          (dup) => dup === o
                                        )
                                    ),
                                  ];
                                });
                              },
                            },
                          ]
                        : []),
                    ]}
                    // @ts-ignore
                    dataFromOutside={storesForTable}
                  />
                )}
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter as={HStack} justifyContent={"space-between"}>
            <Box></Box>
            <Button
              type="submit"
              colorScheme="blue"
              isDisabled={!selectedStores?.length}
              isLoading={uploadingItems}
            >
              Disable {pluralize("Location", selectedStores?.length, true)}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </ResponsiveModal>
  );
}

export default DisableStoresProxy;
