import { Product } from "../../domain/Product";
import { ProductStore } from "../../domain/ProductStore";
import { Store } from "../../domain/Store";
import { getStores, postSearchStores } from "../../services/api.service";

var pluralize = require("pluralize");

export const validateStoreAddress = (address: string) => {
  if (!address) {
    return `does not have address`;
  }
  if (
    !/\d\s\w/.test(
      address.replaceAll("  ", " ").replaceAll(/[^a-zA-Z0-9\s]/g, "")
    )
  ) {
    return `bad format`;
  }
  return undefined;
};

const validateStore = async (store: Store, index: number) => {
  const row = index + 2;
  const addressError = validateStoreAddress(store.address);
  if (!!addressError) {
    throw new Error(`Row ${row} ${addressError}`);
  }

  return store;
};

export const storeUploadValidation = async (
  storesToValidate: Store[],
  accessToken: string,
  states: { abbr: string; name: string }[],
  country: string,
  storeLabel: string,
  orgId: number | undefined
) => {
  if (!storesToValidate?.length) {
    throw new Error(`No ${pluralize(storeLabel)} to upload`);
  }

  const validatedItems = await Promise.all(
    storesToValidate.map((v, i) => validateStore(v, i))
  );
  const searchedStores = await postSearchStores(
    accessToken,
    (validatedItems || []).map((s) => {
      const { id, store_id, status, ...rest } = s;
      return new Store(rest).buildForSearch();
    }),
    true,
    undefined,
    orgId
  );
  let foundStores = await getFoundStores(searchedStores, accessToken);
  return validatedItems
    ?.map((store, i) => {
      const searchedStore =
        searchedStores?.find((s: any) => s.order === i) || store.searched;
      const storeState = searchedStore?.state || store.state;
      const storeCountry = searchedStore?.country || store.country;
      const abbreviatedState =
        (storeState?.length === 2 ? storeState : undefined) ||
        states?.find(
          (s) =>
            s.name.toLowerCase().includes(storeState?.toLowerCase()) ||
            s.abbr.toLowerCase().includes(storeState?.toLowerCase())
        )?.abbr ||
        storeState;
      const abbreviatedCountry = storeCountry || country;
      const { id, order, store_id, store_validation, ...rest } =
        searchedStore || {};
      return {
        original: store,
        ...store,
        ...(rest || {}),
        country: abbreviatedCountry,
        state: abbreviatedState,
        searched: {
          ...(searchedStore || {}),
          store:
            foundStores?.find((s) => s.id === searchedStore?.parent_id) ||
            (searchedStore || {}).store,
          orgStore:
            foundStores?.find((s) => s.id === searchedStore?.org_store_id) ||
            (searchedStore || {}).store,
        },
      };
    })
    .filter((s) => !!s);
};

export const validateStoreProductRelationWithUploaded = (
  uploadedStores?: Store[],
  relations?: ProductStore[],
  products?: Product[],
  storeLabel = "Store"
) => {
  if (!relations || !relations.length) {
    throw new Error("No relations to validate");
  }
  if (!uploadedStores || !uploadedStores.length) {
    throw new Error(`No ${pluralize(storeLabel)} uploaded`);
  }
  if (!products || !products.length) {
    throw new Error("No products to validate relations");
  }
  return relations.filter((relation) => {
    const foundStore = !!uploadedStores.find(
      (store) => store.id === relation.store_id
    );

    const foundProduct = !!products.find(
      (product) => product.id === relation.product_id
    );
    if (!foundProduct) {
      throw new Error(
        `Did not find product for product<>${pluralize(storeLabel)} relation`
      );
    }
    return foundStore && foundProduct;
  });
};

export async function getFoundStores(
  searchedStores: any[],
  accessToken?: string
) {
  const foundIds = Array.from(
    new Set(
      (searchedStores || [])
        .map((s: any) => s.parent_id)
        .filter((s: string) => !!s)
    )
  );
  const foundOrgIds = Array.from(
    new Set(
      (searchedStores || [])
        .map((s: any) => s.org_store_id)
        .filter((s: string) => !!s)
    )
  );
  let foundStores: Store[] = [];
  if (foundOrgIds.length && accessToken) {
    foundStores = await getStores(
      [],
      [],
      [],
      [],
      [],
      undefined,
      true,
      accessToken,
      undefined,
      [],
      foundOrgIds
    );
    return foundStores;
  }
  if (foundIds.length && accessToken) {
    foundStores = await getStores(
      [],
      [],
      [],
      [],
      [],
      undefined,
      false,
      accessToken,
      undefined,
      [],
      foundIds
    );
  }
  return foundStores;
}
