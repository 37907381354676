import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import { ComponentStyleConfig } from "@chakra-ui/react";
import type { PartsStyleObject } from "@chakra-ui/theme-tools";

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  sm: {
    field: { borderRadius: 10 },
  },
};

const inputTheme: ComponentStyleConfig = {
  parts: parts.keys,
  baseStyle: {
    field: {
      borderRadius: 10,
      _placeholder: {
        color: "gray.600",
      },
    },
    addon: { borderRadius: 10, backgroundColor: "green" },
  },
  sizes,
};

export default inputTheme;
