import { LockIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  HStack,
  IconButton,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { updateEditorValue } from "ka-table/actionCreators";
import { DataType } from "ka-table/enums";
import { ICellEditorProps } from "ka-table/props";
import React, { useEffect, useState } from "react";
import { FaBoxes } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  selectAccessToken,
  selectCurrentUser,
  selectResources,
  selectUserResources,
  selectUserRoles,
} from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import { EntityTable } from "../../components/entity-table/EntityTable";
import { RESOURCES } from "../../constants/user-constants";
import {
  deleteRole,
  getRoles,
  postRole,
  putRole,
} from "../../services/api.service";
import { camelCaseWords } from "../../utils/stringUtils";

type ResourceCheckProps = {
  setRoles: React.Dispatch<React.SetStateAction<any[]>>;
  selectedRole: any;
  setSelectedRole: React.Dispatch<React.SetStateAction<any>>;
  rowData: any;
  userCanUpdate?: boolean;
};

function ResourceCheckbox(props: ResourceCheckProps) {
  const { rowData, selectedRole, setSelectedRole, setRoles, userCanUpdate } =
    props;
  const currentUser = useAppSelector(selectCurrentUser);
  const [loading, setLoading] = useState(false);
  return !loading ? (
    <Checkbox
      isDisabled={
        !userCanUpdate ||
        selectedRole.organization_id !== currentUser.organization_id
      }
      isChecked={selectedRole.resources?.find(
        (roleResource: any) => roleResource.resource_id === rowData.id
      )}
      onChange={async () => {
        const newResources = [...(selectedRole.resources || [])];
        const index = newResources.findIndex(
          (res) => res.resource_id === rowData.id
        );
        if (index > -1) {
          newResources.splice(index, 1);
        } else {
          newResources.push({
            role_id: selectedRole.id,
            resource_id: rowData.id,
          });
        }
        let newRole = { ...(selectedRole || {}), resources: newResources };
        if (selectedRole?.id) {
          setLoading(true);
          newRole = await putRole({
            id: selectedRole.id,
            resources: newResources,
          });
          setRoles((oldRoles) => {
            const newRoles = [...(oldRoles || [])];
            const index = oldRoles.findIndex(
              (role) => role.id === selectedRole.id
            );
            newRoles[index] = newRole;
            return newRoles;
          });
          setLoading(false);
        } else if (selectedRole?.updateResources) {
          selectedRole.updateResources(newResources);
        }
        setSelectedRole(newRole);
      }}
    />
  ) : (
    <Spinner />
  );
}

function RolesSettings() {
  const accessToken = useAppSelector(selectAccessToken);
  const userResources = useAppSelector(selectUserResources);
  const resources = useAppSelector(selectResources);
  const currentUser = useAppSelector(selectCurrentUser);
  const userRoles = useAppSelector(selectUserRoles);
  const [selectedRole, setSelectedRole] = useState<any>();
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [roles, setRoles] = useState<any[]>([]);

  useEffect(() => {
    setLoadingRoles(true);
    getRoles(accessToken || "")
      .then(setRoles)
      .finally(() => setLoadingRoles(false));
  }, [accessToken]);

  const ResourcesButton = (
    props: ICellEditorProps & { isEditing?: boolean; data?: any[] }
  ) => {
    const { rowData, dispatch, rowKeyValue, column, isEditing } = props;
    return (
      <IconButton
        className="table-row-btn"
        id="role-resources-btn"
        variant="link"
        icon={
          <FaBoxes
            size="2em"
            className="table-row-btn"
            id="role-resources-btn"
          />
        }
        onClick={() => {
          setSelectedRole({
            ...rowData,
            updateResources: isEditing
              ? (newResources: any) =>
                  dispatch(
                    updateEditorValue(rowKeyValue, column.key, newResources)
                  )
              : undefined,
          });
        }}
        aria-label={"table-row-btn"}
      />
    );
  };
  const userIsOnlyStoreLocator =
    userRoles.find((ur: any) => ur.role?.name.includes("store_locator")) &&
    !userRoles.find((ur: any) => !ur.role?.name.includes("store_locator"));
  return (
    <Box>
      <HStack>
        <Box flex={1}></Box>
        <Box flex={1}>
          {!!selectedRole &&
            selectedRole.organization_id === currentUser.organization_id && (
              <Button
                variant="link"
                onClick={async () => {
                  const newResources =
                    selectedRole.resources?.length === resources?.length
                      ? []
                      : resources.map((resource) => ({
                          role_id: selectedRole.id,
                          resource_id: resource?.id,
                        }));
                  let newRole = {
                    ...(selectedRole || {}),
                    resources: newResources,
                  };
                  if (selectedRole?.id) {
                    newRole = await putRole({
                      id: selectedRole.id,
                      resources: newResources,
                    });
                    setRoles((oldRoles) => {
                      const newRoles = [...(oldRoles || [])];
                      const index = oldRoles.findIndex(
                        (role) => role.id === selectedRole.id
                      );
                      newRoles[index] = newRole;
                      return newRoles;
                    });
                  } else if (selectedRole?.updateResources) {
                    selectedRole.updateResources(newResources);
                  }
                  setSelectedRole(newRole);
                }}
              >
                {selectedRole.resources?.length === resources?.length
                  ? "Deselect all"
                  : "Select all"}
              </Button>
            )}
          {!!selectedRole &&
            selectedRole.organization_id !== currentUser.organization_id && (
              <Text>
                You can't edit this role because it is a predefined role
              </Text>
            )}
        </Box>
      </HStack>
      <HStack>
        <EntityTable
          initialTableProps={{
            columns: [
              {
                title: "",
                info: "Pre-defined roles cannot be edited",
                key: "canEdit",
                Render: ({ rowData: { canEdit } }) =>
                  canEdit ? <></> : <LockIcon size="2em" />,
                style: { width: 10, textAlign: "center" },
                hideFilter: true,
              },
              {
                title: "Name",
                dataType: DataType.String,
                key: "name",
                isRequired: true,
                style: { width: 100 },
              },
              {
                title: "Description",
                dataType: DataType.String,
                key: "description",
              },
              {
                title: "",
                info: "Manage the resources the role has access to.",
                key: "resources",
                Render: ResourcesButton,
                style: { width: 60, textAlign: "center" },
                hideFilter: true,
              },
            ],
          }}
          deleteItem={
            userResources?.includes(RESOURCES.ROLE_DELETE) &&
            (async (productId, item) => {
              if (item.canEdit) {
                deleteRole(productId, accessToken);
                setSelectedRole(undefined);
              } else {
                toast(
                  "You can't edit this role because it is a predefined role"
                );
              }
            })
          }
          postItem={
            userResources?.includes(RESOURCES.ROLE_CREATE) &&
            (async (newSale: any) => {
              const newRole = await postRole(newSale, accessToken);
              setRoles((oldRoles) => [newRole, ...(oldRoles || [])]);
            })
          }
          putItem={
            userResources?.includes(RESOURCES.ROLE_UPDATE) &&
            (async (productId, updatedSale) => {
              if (updatedSale.canEdit) {
                putRole(
                  {
                    id: productId,
                    ...updatedSale,
                  },
                  accessToken
                );
              } else {
                toast(
                  "You can't edit this role because it is a predefined role"
                );
              }
            })
          }
          dataFromOutside={roles
            ?.filter(
              (r) => !userIsOnlyStoreLocator || r.name.includes("store_locator")
            )
            ?.map((role) => ({
              ...role,
              canEdit: role.organization_id === currentUser.organization_id,
            }))
            .sort((a, b) =>
              a.canEdit && !b.canEdit ? 1 : a.canEdit && b.canEdit ? 0 : -1
            )}
        />
        {selectedRole && (
          <EntityTable
            initialTableProps={{
              columns: [
                {
                  title: "Name",
                  dataType: DataType.String,
                  key: "name",
                  format: camelCaseWords,
                },
              ],
            }}
            LeftButton={({ rowData }) => (
              <ResourceCheckbox
                rowData={rowData}
                selectedRole={selectedRole}
                setSelectedRole={setSelectedRole}
                setRoles={setRoles}
                userCanUpdate={!!userResources?.includes(RESOURCES.ROLE_UPDATE)}
              />
            )}
            dataFromOutside={resources}
          />
        )}
      </HStack>
      {loadingRoles && <Spinner />}
    </Box>
  );
}

export default RolesSettings;
