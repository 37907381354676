import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { IoCamera, IoClose, IoSwapHorizontal } from "react-icons/io5";
import { Button, IconButton } from "@chakra-ui/button";
import { Box, Text } from "@chakra-ui/layout";
import ReactCrop from "react-image-crop";
import { FormControl } from "@chakra-ui/form-control";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import { Image } from "@chakra-ui/image";
import { AddIcon } from "@chakra-ui/icons";

const feedAspectRange = [9 / 16, 5 / 4];
const storyAspectRange = [9 / 16, 16 / 9];

export default function FacebookImageUpload({
  onLoad,
  onDelete,
  onSwap,
  image,
  video,
  accept,
  swapButton,
  width = 100,
  height = 100,
  cropType,
  onApply,
  variant = "card",
  formControlProps = {},
}) {
  const previewCanvasRef = useRef(null);
  const hiddenFileInput = useRef();
  const imgRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [cropSrc, setCropSrc] = useState();
  const [fileName, setFileName] = useState();
  const [crop, setCrop] = useState({});
  const applyRef = useRef();

  const clearState = () => {
    previewCanvasRef.current = undefined;
    imgRef.current = undefined;
    setCropSrc();
    setFileName();
    setLoading(false);
  };

  function generateDownload(canvas) {
    setLoading(true);
    if (!crop || !canvas) {
      setLoading(false);
      return;
    }

    canvas.toBlob(
      (blob) => {
        var reader = new FileReader();
        reader.onload = function (e) {
          const file = new File([blob], fileName);
          const previewUrl = e.target.result;
          onLoad("image", file, previewUrl);
          clearState();
        };
        reader.readAsDataURL(blob);
      },
      "image/png",
      1
    );
  }
  const onCompleted = (completedCrop) => {
    if (!completedCrop || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = document.createElement("canvas");
    previewCanvasRef.current = canvas;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  };
  return (
    <FormControl isRequired mb={5} {...formControlProps}>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={(e) => {
          e.preventDefault();
          const file = e.target.files[0];

          const fileType = file.type;
          const maxFileSize = fileType.includes("video") ? "4e+9" : "3e+7";
          if (file.size > Number(maxFileSize)) {
            toast.error(
              "Max file size: " + (fileType.includes("video") ? "4gb" : "30mb")
            );
            return;
          }

          var reader = new FileReader();

          reader.onload = function (e) {
            if (fileType.includes("image") && cropType) {
              setFileName(file.name);
              setCropSrc(e.target.result);
              return;
            }
            onLoad(fileType, file, e.target.result);
          };

          reader.readAsDataURL(file);

          e.target.value = null;
        }}
        accept={accept}
        style={{ display: "none" }}
      />
      {variant === "card" && (
        <>
          {image && (
            <Box
              position="relative"
              w={width}
              h={height}
              boxShadow="0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
              rounded="lg"
              overflow="hidden"
            >
              <Image src={image.fileUrl} w="100%" h="100%" fit="cover" />
              <IconButton
                position="absolute"
                top={0}
                right={0}
                size="xs"
                icon={swapButton ? <IoSwapHorizontal /> : <IoClose />}
                onClick={
                  swapButton ? () => hiddenFileInput.current.click() : onDelete
                }
              />
            </Box>
          )}
          {!image && (
            <IconButton
              w={width}
              h={height}
              boxShadow="0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
              icon={<AddIcon />}
              onClick={() => hiddenFileInput.current.click()}
            />
          )}
        </>
      )}
      {variant === "button" && (
        <Button
          leftIcon={<IoCamera size="2em" />}
          onClick={() => hiddenFileInput.current.click()}
        >
          {image ? "Add more images" : video ? "Replace video" : "Choose video"}
        </Button>
      )}
      <AlertDialog
        isOpen={cropSrc && cropType}
        leastDestructiveRef={applyRef}
        onClose={() => setCropSrc()}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontWeight="bold">
              Crop image for {cropType}
            </AlertDialogHeader>

            <AlertDialogBody>
              {(!crop || !crop.width || !crop.height) && (
                <Text>Click inside photo to crop</Text>
              )}
              <ReactCrop
                src={cropSrc}
                onImageLoaded={(img) => {
                  if (img.naturalWidth < 600) {
                    toast.error("Min image width: 600px");
                    clearState();
                    return;
                  }
                  imgRef.current = img;
                }}
                onComplete={onCompleted}
                onChange={(newCrop) => {
                  const _range =
                    cropType === "feed" ? feedAspectRange : storyAspectRange;
                  const _aspect = Math.max(
                    _range[0],
                    Math.min(_range[1], newCrop.width / newCrop.height)
                  );
                  const scaleX =
                    imgRef.current.naturalWidth / imgRef.current.width;
                  const width = Math.max(600 / scaleX, newCrop.width);
                  setCrop({
                    ...newCrop,
                    width,
                    height: width / _aspect,
                  });
                }}
                crop={crop}
              />
            </AlertDialogBody>

            <AlertDialogFooter d="flex" justifyContent="flex-end">
              <Button onClick={() => setCropSrc()}>Cancel</Button>
              <Button
                ref={applyRef}
                colorScheme="green"
                ml={3}
                onClick={() => {
                  generateDownload(previewCanvasRef.current);
                }}
                disabled={loading || !crop || !crop.width || !crop.height}
                isLoading={loading}
              >
                Apply
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </FormControl>
  );
}
