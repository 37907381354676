import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React from "react";
import { camelCaseWords } from "../utils/stringUtils";
import InfoPopover from "./InfoPopover";

type Props = {
  data?: { [x: string | number | symbol]: unknown };
  value: string;
  onChange?: (newValue: string) => void;
  // Keys can be strings, numbers, or symbols.
  // If you know it to be strings only, you can also restrict it to that.
  // For the value you can use any or unknown,
  // with unknown being the more defensive approach.
  [x: string | number | symbol]: unknown;
};

export default function DynamicInput({
  data,
  value,
  onChange,
  info,
  ...other
}: Props) {
  const appendAttributeToValue = (toAppend: string) => {
    onChange?.((value || "") + toAppend);
  };
  const validKeys = Object.keys(data || {}).filter(
    (key) =>
      data?.[key] &&
      !["undefined", "object", "function"].includes(typeof data[key])
  );
  return (
    <Box position="relative">
      {!!info && (
        <InfoPopover
          iconProps={{
            position: "absolute",
            right: 0,
            top: -5,
          }}
          info={info}
          popoverProps={undefined}
          triggerContent={undefined}
        />
      )}
      <Input
        bg="white"
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        rounded="md"
        paddingRight={120}
        {...other}
      />
      {!!validKeys.length && (
        <Menu>
          <MenuButton
            as="div"
            style={{
              position: "absolute",
              top: 2,
              right: 3,
              bottom: 2,
              zIndex: 2,
            }}
          >
            <Button height="100%" rounded="md" rightIcon={<ChevronDownIcon />}>
              Variables
            </Button>
          </MenuButton>
          <MenuList maxHeight={200} overflowY="auto" zIndex={999}>
            {validKeys.map((option, index) => (
              <MenuItem
                key={index}
                onClick={() =>
                  appendAttributeToValue(
                    `${data?.[option as keyof Props["data"]]}`
                  )
                }
              >
                {camelCaseWords(option)}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}
    </Box>
  );
}
