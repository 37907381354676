import { Button } from "@chakra-ui/button";
import { Box, HStack } from "@chakra-ui/layout";
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { connect } from "react-redux";
import StepWizard from "react-step-wizard";
import {
  selectIsShopifyApp,
  selectOrgProperties,
  selectProducts,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { ResponsiveModal } from "../../components/responsive-modal";
import { selectMyStores } from "../map/keplerReducer";
import ProductsTable from "../products/ProductsTable";
import UploadProductsTab from "../products/UploadProductsTab";
import MyStoresTable from "../stores/MyStoresTable";
import UploadStoresTab from "../stores/UploadStoresTab";
import { EmbeddingStep } from "./StoreLocatorEmbedding";
import {
  selectConfigCompletedIsOpen,
  toggleConfigCompletedModal,
} from "./storeLocatorSlice";

var pluralize = require("pluralize");

const _Nav = (props) => {
  const {
    totalSteps,
    currentStep,
    config,
    goToStep,
    products,
    myStores,
    orgConfig,
  } = props;
  const storeLabel = orgConfig?.storeNameReplacement || "Store";
  const dots = [];
  const stepNames = [
    "Embedding Options",
    ...(products?.length ? [`Manage Products`] : []),
    `Add Products`,
    ...(myStores?.length ? [`Manage ${pluralize(storeLabel)}`] : []),
    `Add ${pluralize(storeLabel)}`,
  ];

  for (let i = 1; i <= totalSteps; i += 1) {
    const isActive = currentStep === i;
    dots.push(
      <Button
        flex={1}
        colorScheme={isActive ? "blue" : undefined}
        disabled={i >= currentStep && !config}
        onClick={() => {
          return goToStep(i);
        }}
      >
        {stepNames[i - 1]}
      </Button>
    );
  }

  return (
    <HStack w="100%" mb={5}>
      {dots}
    </HStack>
  );
};

const Nav = connect((state) => {
  return {
    products: state.app.products,
    myStores: selectMyStores(state),
  };
})(_Nav);

function ManageProductsStep() {
  return (
    <Box>
      <ProductsTable />
    </Box>
  );
}

function ManageStoresStep() {
  return (
    <Box>
      <MyStoresTable />
    </Box>
  );
}
function AddStoresStep() {
  return (
    <Box>
      <UploadStoresTab />
    </Box>
  );
}

function AddProductsStep() {
  return (
    <Box>
      <UploadProductsTab />
    </Box>
  );
}

function StoreLocatorCompletedModal() {
  const isShopifyApp = useAppSelector(selectIsShopifyApp);
  const orgProperties = useAppSelector(selectOrgProperties);
  const myStores = useAppSelector(selectMyStores);
  const myProducts = useAppSelector(selectProducts);
  const isOpen = useAppSelector(selectConfigCompletedIsOpen);

  const dispatch = useAppDispatch();

  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";

  const onClose = dispatch(toggleConfigCompletedModal(false));

  return (
    <>
      <ResponsiveModal isOpen={isOpen} isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalBody position="relative">
            <ModalHeader>{storeLabel} Locator setup completed!</ModalHeader>
            <ModalCloseButton onClick={onClose} />
            <StepWizard nav={<Nav config={orgProperties?.store_locator} />}>
              <EmbeddingStep />
              {!!myProducts?.length && <ManageProductsStep />}
              <AddProductsStep />
              {!!myStores?.length && <ManageStoresStep />}
              <AddStoresStep />
            </StepWizard>
          </ModalBody>
        </ModalContent>
      </ResponsiveModal>
    </>
  );
}
export default StoreLocatorCompletedModal;
