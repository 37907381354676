import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  selectAccessToken,
  selectOrgProperties,
  selectUserResources,
} from "../../../app/appSlice";
import { useAppSelector } from "../../../app/store";
import { RESOURCES } from "../../../constants/user-constants";
import {
  getBulkQueryOrders,
  getShopifyConnectShopify,
} from "../../../services/shopify.service";

type ShopifyConnect = {
  shopUrl: string;
  apiKey: string;
  access_token: string;
};

export default function ConnectForm() {
  const orgProps = useAppSelector(selectOrgProperties);
  const accessToken = useAppSelector(selectAccessToken);
  const userResources = useAppSelector(selectUserResources);
  const [dateRange, setDateRange] = useState<{
    start: Date | null;
    end: Date | null;
  }>({ start: DateTime.now().minus({ month: 1 }).toJSDate(), end: new Date() });
  const [showToken, setShowToken] = useState(false);
  const customAppApiKey = orgProps?.properties?.shopifyData?.api_key;
  const admin_api_access_token =
    orgProps?.properties?.shopifyData?.access_token;
  const myshopifyDomain =
    orgProps?.properties?.shopifyData?.shop?.myshopify_domain;
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<ShopifyConnect>({
    defaultValues: {
      shopUrl: myshopifyDomain,
      apiKey: customAppApiKey,
      access_token: admin_api_access_token,
    } || {
      shopUrl: "",
      apiKey: "",
      access_token: "",
    },
  });

  const onSubmit: SubmitHandler<ShopifyConnect> = (values) => {
    getShopifyConnectShopify(
      values.shopUrl,
      values.apiKey,
      values.access_token,
      accessToken || ""
    ).then((res) => {
      const webhookSubId =
        res?.data?.webhookSubscriptionCreate?.webhookSubscription?.id;
      const errorMessage =
        res?.data?.webhookSubscriptionCreate?.userErrors?.[0]?.message;
      if (webhookSubId || errorMessage?.includes("taken")) {
        toast.success("Updated shopify integration");
      } else {
        toast.warn(`Could not update: ${errorMessage || ""}`);
      }
    });
  };

  const DateInput = ({
    onClick,
  }: {
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  }) => (
    <Button onClick={onClick} variant="outline">
      {`${
        dateRange.start
          ? DateTime.fromJSDate(dateRange.start).toISODate()
          : "start"
      }  to  ${
        dateRange.end ? DateTime.fromJSDate(dateRange.end).toISODate() : "end"
      }`}
    </Button>
  );

  const runOrdersQuery = () => {
    if (dateRange.start && dateRange.end && accessToken) {
      getBulkQueryOrders(
        DateTime.fromJSDate(dateRange.start).toISODate(),
        DateTime.fromJSDate(dateRange.end).toISODate(),
        accessToken
      ).then((res) => {
        const webhookSubId =
          res?.data?.webhookSubscriptionCreate?.webhookSubscription?.id;
        const errorMessage =
          res?.data?.webhookSubscriptionCreate?.userErrors?.[0]?.message;
        if (webhookSubId || errorMessage?.includes("taken")) {
          toast.success("We are now syncing your orders");
        }
      });
    }
  };
  return userResources.includes(RESOURCES.INTEGRATION_SHOPIFY_CONNECT) ? (
    <HStack
      alignItems="start"
      divider={<Divider orientation="vertical" />}
      h="80"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack>
          <FormControl isInvalid={errors.shopUrl && dirtyFields.shopUrl}>
            <FormLabel>Shopify Store Url</FormLabel>
            <Input
              {...register("shopUrl", {
                required: true,
                pattern: /\w+\.myshopify.com/,
              })}
              placeholder="mystore.myshopify.com"
            />
          </FormControl>
          <FormControl isInvalid={errors.apiKey && dirtyFields.apiKey}>
            <FormLabel>API Key</FormLabel>
            <Input
              {...register("apiKey", { required: true })}
              placeholder="40c9a7dd906e4a3e8a17a8fb9148f0c3"
            />
          </FormControl>
          <FormControl
            isInvalid={errors.access_token && dirtyFields.access_token}
          >
            <FormLabel>Access Token for Admin API</FormLabel>
            <InputGroup>
              <Input
                {...register("access_token", { required: true })}
                type={showToken ? "text" : "password"}
                placeholder="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"
                pr="4.5rem"
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  onClick={() => setShowToken((oldValue) => !oldValue)}
                >
                  {showToken ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Button type="submit">Update</Button>
        </VStack>
      </form>
      <VStack>
        <FormControl>
          <FormLabel>Sync orders cronologically</FormLabel>
          <InputGroup>
            <Select
              placeholder="Sync orders every..."
              disabled={
                !customAppApiKey || !admin_api_access_token || !myshopifyDomain
              }
            >
              <option value="option1">day</option>
              <option value="option2">week</option>
              <option value="option3">month</option>
            </Select>
          </InputGroup>
        </FormControl>
        <FormControl>
          <FormLabel>Sync orders manually</FormLabel>
          <HStack>
            <DatePicker
              selected={dateRange.start}
              onChange={async (dates) => {
                const [start, end] = dates;
                setDateRange({ start, end });
              }}
              startDate={dateRange.start}
              endDate={dateRange.end}
              selectsRange
              customInput={<DateInput onClick={undefined} />}
              shouldCloseOnSelect={false}
            />
            <Button
              onClick={runOrdersQuery}
              disabled={
                !customAppApiKey || !admin_api_access_token || !myshopifyDomain
              }
            >
              Sync Orders
            </Button>
          </HStack>
        </FormControl>
      </VStack>
    </HStack>
  ) : (
    <VStack>
      <Text>
        You don't have permission to modify this integration. Please contact
        support for help.
      </Text>
      <Button
        variant={"link"}
        onClick={() =>
          window.open(
            "https://calendly.com/laura-rocha/30-mins-dathic--locator-support"
          )
        }
      >
        Schedule call
      </Button>
    </VStack>
  );
}
