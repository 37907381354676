import { tagAnatomy as parts } from "@chakra-ui/anatomy";
import { PartsStyleFunction } from "@chakra-ui/theme-tools";

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  container: { borderRadius: 10 },
});

const tagTheme = {
  parts: parts.keys,
  baseStyle,
};
export default tagTheme;
