import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router";
import { selectUserResources } from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import { RESOURCES } from "../../constants/user-constants";
import ProductsTable from "./ProductsTable";
import UploadProductsTab from "./UploadProductsTab";

type Props = {};

const ProductsTabs: FunctionComponent<Props> = (props) => {
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(+(location.state?.tabIndex || 0));
  const userResources = useAppSelector(selectUserResources);
  const { productId } = useParams();
  const parsedProductId = useMemo(
    () => (!isNaN(Number(productId)) ? Number(productId) : undefined),
    [productId]
  );

  useEffect(() => {
    if (!isNaN(Number(location.state?.tabIndex))) {
      setTabIndex(+location.state?.tabIndex);
      return;
    }
    if (!isNaN(Number(productId))) {
      setTabIndex(0);
    }
  }, [location.state, productId]);

  return (
    <Tabs
      isFitted
      defaultIndex={0}
      isLazy
      lazyBehavior="unmount"
      height="100%"
      display="flex"
      flexDir="column"
      data-tour="products"
      index={tabIndex}
      onChange={setTabIndex}
    >
      <TabList bg="white">
        <Tab>Manage My Products</Tab>
        <Tab isDisabled={!userResources?.includes(RESOURCES.PRODUCTS_CREATE)}>
          Add New Products
        </Tab>
      </TabList>

      <TabPanels flex={1}>
        <TabPanel height="100%">
          <ProductsTable productId={parsedProductId} />
        </TabPanel>
        <TabPanel height="100%">
          <UploadProductsTab />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ProductsTabs;
