import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Heading } from "@chakra-ui/layout";
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Text,
  VStack,
} from "@chakra-ui/react";
import pluralize from "pluralize";
import { useState } from "react";
import { FaDownload, FaUpload, FaVideo } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import XLSX from "xlsx";
import { selectOrgProperties, selectUserResources } from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { DathicFileDrop } from "../../components/DathicFileDrop";
import UploadBtn from "../../components/uploadBtn";
import { RESOURCES } from "../../constants/user-constants";
import { Store } from "../../domain/Store";
import { putStoresBatch } from "../../services/api.service";
import { PopoverTrigger } from "../products/ProductsTable";
import { refreshIframe } from "../store-locator/storeLocatorSlice";
import { storeUploadValidation } from "./store-validators";

type Props = {
  onFinish?: () => void;
  storeTypes?: (string | undefined)[];
};
export const DisableStoresSteps = ({ onFinish, storeTypes }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userResources = useAppSelector(selectUserResources);
  const orgProperties = useAppSelector(selectOrgProperties);
  const dispatch = useAppDispatch();
  const [generatingTemplate, setGeneratingTemplate] = useState(false);
  const [file, setFile] = useState<File>();
  const [fileForMapping, setFileForMapping] = useState<File>();

  const storeLabel = storeTypes?.includes("restaurant")
    ? "Restaurant"
    : orgProperties?.properties?.storeNameReplacement || "Store";
  const statesLabel =
    orgProperties?.properties?.statesNameReplacement || "State";
  const zipcodeLabel =
    orgProperties?.properties?.zipcodeNameReplacement || "Zipcode";

  const storeSchema = Store.getSchema(statesLabel, zipcodeLabel, storeTypes);

  const generateTemplate = async (isCsv: boolean) => {
    setGeneratingTemplate(true);

    const dataToSave = [
      {
        store_id: "",
        chain: "",
        name: "",
        description: "",
        address: "",
        zipcode: "",
        city: "",
        state: "",
        phone: "",
        ...(storeTypes?.includes("popup_store")
          ? { start_date: "", end_date: "", recurrence_rule: "" }
          : {}),
      },
    ];
    if (!isCsv) {
      const wb = XLSX.utils.book_new();

      const storesSheet = XLSX.utils.json_to_sheet(dataToSave);
      XLSX.utils.book_append_sheet(wb, storesSheet, "Stores");
      XLSX.writeFile(wb, "StoreTemplate.xlsx");
    } else {
      const header = [
        "store_id",
        "chain",
        "name",
        "description",
        "address",
        "zipcode",
        "city",
        "state",
        "phone",
        ...(storeTypes?.includes("popup_store")
          ? ["start_date", "end_date", "recurrence_rule"]
          : []),
      ];
      const rows = [header].concat(
        dataToSave.map((row) => [
          ...header.map((h) => row[h as keyof typeof row] || ""),
        ])
      );

      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const element = document.createElement("a");
      element.setAttribute("href", encodedUri);
      element.setAttribute("download", "StoreTemplate");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
    setGeneratingTemplate(false);
  };

  const handleUploadFinish = (goodData: { item: Store; original: any }[]) => {
    dispatch(refreshIframe());
    setFile(undefined);
    setFileForMapping(undefined);
  };

  return (
    <VStack
      w="100%"
      textAlign="start"
      spacing={5}
      bg="white"
      p={3}
      rounded="lg"
    >
      {onFinish && (
        <Button
          variant="link"
          alignSelf="flex-start"
          leftIcon={<ChevronLeftIcon />}
          onClick={() => {
            if (onFinish) onFinish();
          }}
        >
          Back
        </Button>
      )}
      <HStack w="100%" justifyContent="space-between">
        <Heading size="lg">Disable locations with a spreadsheet</Heading>
        <Button variant="outline" leftIcon={<FaVideo />} isDisabled>
          Questions? Watch video
        </Button>
      </HStack>
      <Divider />
      <HStack w="100%">
        <VStack flex={1} alignItems="flex-start">
          <Heading size="md">Step 1: Download Template</Heading>
          <Text fontSize="sm" color="gray.800">
            This file shows exactly how Dathic organizes {storeLabel} data.
          </Text>
        </VStack>
        <HStack w="40%" justifyContent="center">
          <Button
            whiteSpace="normal"
            leftIcon={<FaDownload />}
            onClick={() => generateTemplate(true)}
            isDisabled={generatingTemplate}
            isLoading={generatingTemplate}
            id="product_csv_template"
          >
            CSV
          </Button>
          <Button
            whiteSpace="normal"
            leftIcon={<FaDownload />}
            onClick={() => generateTemplate(false)}
            isDisabled={generatingTemplate}
            isLoading={generatingTemplate}
            id="product_excel_template"
          >
            Excel
          </Button>
        </HStack>
      </HStack>
      <Divider />
      <HStack w="100%">
        <VStack flex={1} alignItems="flex-start">
          <Heading size="md">Step 2: Fill out template</Heading>
          <Text fontSize="sm" color="gray.800">
            Add locations you want to disable to the spreadsheet. Certain fields
            need to be entered in order for the process to be successful. Make
            sure they are complete before you start the process.
          </Text>
        </VStack>
        <HStack w="40%" justifyContent="center">
          <Popover trigger="hover">
            <PopoverTrigger>
              <Button>View mandatory fields</Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>
                Mandatory fields for {pluralize(storeLabel)}
              </PopoverHeader>
              <PopoverBody>
                <List spacing={3}>
                  {storeSchema
                    .filter((field) => !!field.isRequired)
                    .map((field) => (
                      <ListItem>
                        <FormControl isRequired>
                          <FormLabel>{field.label}</FormLabel>
                        </FormControl>
                      </ListItem>
                    ))}
                </List>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>
      </HStack>
      <Divider />
      <HStack w="100%">
        <VStack flex={1} alignItems="flex-start">
          <Heading size="md">Step 3: Import spreadsheet</Heading>
          <Text fontSize="sm" color="gray.800">
            Upload your file. Only Excel (.xls and .xlsx) and .csv file types
            are supported.
          </Text>
        </VStack>
        <HStack w="40%" justifyContent="center">
          <DathicFileDrop
            fileFromOutside={file}
            onChange={setFile}
            accept=".xls, .xlsx, .csv"
            message="Only Excel (.xls and .xlsx) and .csv file types are supported."
            shouldUpload={false}
          />
        </HStack>
      </HStack>
      <Divider />
      <HStack w="100%">
        <VStack flex={1} alignItems="flex-start">
          <Heading size="md">Step 4: Mapping</Heading>
          <Text fontSize="sm" color="gray.800">
            Map each column in your spreadsheet to the corresponding label in
            Dathic. Unmapped columns will not be imported into Dathic.
          </Text>
        </VStack>
        <HStack w="40%" justifyContent="center">
          <UploadBtn
            entityName={pluralize(storeLabel)}
            CustomButton={() => (
              <Button
                onClick={() => {
                  setFileForMapping(undefined);
                  setFileForMapping(file);
                }}
                isDisabled={!file}
              >
                Map fields
              </Button>
            )}
            fileFromOutside={fileForMapping}
            postBatch={async (items: any[], accessToken: string) => {
              await putStoresBatch(
                accessToken,
                items.map((i) =>
                  new Store({
                    id: i.org_store_id,
                    address: i.searched?.orgStore?.address,
                    secondary_images: i.searched?.orgStore?.secondary_images,
                    status: "not_selling",
                  }).buildForUpdate()
                )
              );
              return items.map((i) => i.id);
            }}
            validator={(itemsToUpload: Store[], accessToken: string) =>
              storeUploadValidation(
                itemsToUpload,
                accessToken,
                [],
                orgProperties?.properties?.country,
                storeLabel,
                orgProperties?.organization_id
              )
            }
            Icon={FaUpload}
            isDisabled={!userResources?.includes(RESOURCES.STORES_UPDATE)}
            schema={Store.getSchemaForTable(
              statesLabel,
              zipcodeLabel,
              storeTypes
            )}
            onFinish={handleUploadFinish}
            defaultSheet="Stores"
            proxyName="DisableStoresProxy"
            finishMessage={(data) => ({
              title: `You have successfully disabled the locations`,
              message: `To view your updated locations, go to Manage My Locations`,
              actions: [
                {
                  label: `Manage My Locations`,
                  callback: () => {
                    if (location.pathname.includes("storelocator")) {
                      navigate(`/storelocator/stores`, {
                        state: {
                          tabIndex: 1,
                        },
                      });
                      return;
                    }
                    navigate(`/StoresTabs/stores-manage`, {
                      state: {
                        tabIndex: 1,
                      },
                    });
                  },
                },
              ],
            })}
            proxyParams={{ storeTypes }}
          />
        </HStack>
      </HStack>
      <Divider />
      <HStack w="100%">
        <VStack flex={1} alignItems="flex-start">
          <Heading size="md">Step 5: Confirm</Heading>
          <Text fontSize="sm" color="gray.800">
            Confirm the import. Dathic will check the data to detect duplicates
            and will review the locations.
          </Text>
        </VStack>
      </HStack>
    </VStack>
  );
};
