import { EditIcon } from "@chakra-ui/icons";
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Stack,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { UseFormRegister } from "react-hook-form";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import Form from "../../components/Form";
import { RecurringEventForm } from "../../components/RecurringEventForm";
import { ResponsiveModal } from "../../components/responsive-modal";
import { StoreCardPreview } from "./StoreCardPreview";

export type FeaturedContent = {
  id: string;
  name: string;
  image?: string;
  imageHeight?: number;
  imageFit?: string;
  title?: string;
  message?: string;
  link?: string;
  callToAction?: string;
  type: "discount" | "advertisement" | "other";
  discountValue?: number;
  discountUnit?: "%" | "$";
};

type FormProps = {
  content?: FeaturedContent;
  setContent?: (content?: FeaturedContent) => void;
  onClose?: () => void;
  shouldSubmit: boolean;
};
type Props = {
  content?: FeaturedContent[];
  setContent?: React.Dispatch<React.SetStateAction<FeaturedContent[]>>;
  direction?: "row" | "column";
  w?: number | string;
  viewOnly?: boolean;
};

type CardProps = {
  content: FeaturedContent;
  onClose?: () => void;
  onEdit?: (editedContent: FeaturedContent) => void;
  viewOnly?: boolean;
};

export const FeaturedContentCard: FunctionComponent<CardProps> = ({
  content,
  onClose,
  onEdit,
  viewOnly,
}) => {
  return (
    <Flex bg="lightBlue.400" rounded="lg" flexDir="column" w="300px">
      {!viewOnly && (
        <Flex justifyContent="space-between">
          {!!onEdit && (
            <IconButton
              aria-label={"Edit featured content"}
              icon={<EditIcon />}
              size="sm"
              variant="link"
              m={1}
              onClick={() => onEdit(content)}
            />
          )}
          {!!onClose && (
            <IconButton
              aria-label={"Remove featured content"}
              icon={<FaTrash />}
              size="xs"
              variant="link"
              m={1}
              onClick={onClose}
            />
          )}
        </Flex>
      )}
      <Flex
        shadow="md"
        rounded="lg"
        w="300px"
        bg="white"
        flexDir="column"
        onClick={content.link ? () => window.open(content.link) : undefined}
        cursor={content.link ? "pointer" : undefined}
        minW="300px"
        overflow="hidden"
        flex={1}
      >
        {!!content.image && (
          <Box w="100%" h={`${content.imageHeight}px` || "200px"}>
            <Image
              h="100%"
              w="100%"
              src={content.image}
              objectFit={content.imageFit || "cover"}
            />
          </Box>
        )}
        {!![content.title, content.message, content.callToAction].filter(
          (c) => !!c
        ).length && (
          <VStack alignItems="flex-start" p={3}>
            {!!content.title && (
              <Text
                fontWeight="bold"
                fontSize={18}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                w="100%"
              >
                {content.title}
              </Text>
            )}
            {!!content.message && (
              <Text
                fontSize={14}
                noOfLines={3}
                overflow="hidden"
                textOverflow="ellipsis"
                w="100%"
                maxH="4.5em"
              >
                {content.message}
              </Text>
            )}
            {!!content.callToAction && (
              <Button maxW="100%" overflow="hidden">
                {content.callToAction}
              </Button>
            )}
          </VStack>
        )}
      </Flex>
    </Flex>
  );
};

const FeaturedContentForm: FunctionComponent<FormProps> = ({
  content,
  setContent = () => {},
  shouldSubmit = false,
}) => {
  const validate = (values: any) => {
    if (!values.name) {
      return "Name is required";
    }
    if (values.type === "discount" && !values.discountValue) {
      return "Discount value is required";
    }
    if (values.link && !/^https?:\/\/.*/.test(values.link)) {
      return "Invalid URL";
    }
    return "";
  };

  const onSubmit = (values: any) => {
    const message = validate(values);
    if (message) {
      toast.warn(message);
      return;
    }
    setContent(values as FeaturedContent);
  };

  const RenderScheduleFields = ({
    values,
    onChange,
  }: {
    values: { [key: string]: any };
    onChange: (attr: string, value: any) => void;
    register: UseFormRegister<any>;
  }) => {
    const onRecurringRuleChange = useCallback(
      (start: Date, end: Date, rule?: string) => {
        onChange("startDate", DateTime.fromJSDate(start).toSQLDate());
        onChange("endDate", DateTime.fromJSDate(end).toSQLDate());
        onChange("recurringRule", rule);
      },
      [onChange]
    );

    return (
      <GridItem gridColumn={2} colSpan={2}>
        <FormControl>
          <FormLabel>
            <Switch
              isChecked={values.startDate && values.endDate}
              onChange={(e) => {
                const shouldEnable = !(values.startDate && values.endDate);
                onChange(
                  "startDate",
                  shouldEnable
                    ? DateTime.fromJSDate(new Date()).toSQLDate()
                    : undefined
                );
                onChange(
                  "endDate",
                  shouldEnable
                    ? DateTime.fromJSDate(new Date())
                        .plus({ month: 1 })
                        .toSQLDate()
                    : undefined
                );
                onChange("recurringRule", undefined);
              }}
            >
              Schedule
            </Switch>
          </FormLabel>
          {!!(values.startDate && values.endDate) && (
            <RecurringEventForm
              rrule={values.recurringRule}
              startDateProp={
                typeof values.startDate === "string"
                  ? DateTime.fromSQL(values.startDate).toJSDate()
                  : values.startDate
              }
              endDateProp={
                typeof values.endDate === "string"
                  ? DateTime.fromSQL(values.endDate).toJSDate()
                  : values.endDate
              }
              onChange={onRecurringRuleChange}
            />
          )}
        </FormControl>
      </GridItem>
    );
  };

  return (
    <Form
      formName="featured-content-form"
      gridProps={{ columns: 3 }}
      schema={[
        {
          type: "string",
          key: "name",
          label: "Name ID",
          isRequired: true,
          placeholder: "Name identify the content",
        },
        {
          type: "string",
          key: "title",
          label: "Title",
          gridItemProps: { gridColumn: 2 },
          placeholder: "Title for the content",
        },
        {
          key: "preview",
          label: "Preview",
          renderFormControl: (values) => (
            <GridItem gridColumn={3} rowStart={1} rowEnd={20}>
              <StoreCardPreview featuredContent={[values as FeaturedContent]} />
            </GridItem>
          ),
        },
        {
          type: "string",
          options: ["discount", "advertisement", "other"],
          key: "type",
          label: "Type",
          isRequired: true,
          gridItemProps: { gridColumn: 1 },
          placeholder: "Choose the type of content",
        },
        {
          type: "string",
          key: "message",
          label: "Message",
          placeholder: "Message for the content",
          gridItemProps: { gridColumn: 2 },
        },
        {
          key: "discountValue",
          label: "Discount value",
          isHidden: (values) => values.type !== "discount",
          renderFormControl: (values, onChange, register) => (
            <GridItem gridColumn={1}>
              <FormControl isRequired>
                <FormLabel>Discount value</FormLabel>
                <InputGroup size="sm">
                  <Input
                    {...register("discountValue", {
                      required: true,
                      valueAsNumber: true,
                    })}
                    type={"number"}
                  />
                  <InputRightAddon>
                    <Select {...register("discountUnit", { required: true })}>
                      <option value={"%"}>Percentage</option>
                      <option value={"$"}>Money</option>
                    </Select>
                  </InputRightAddon>
                </InputGroup>
              </FormControl>
            </GridItem>
          ),
        },
        {
          key: "callToAction",
          label: "Call to action",
          renderFormControl: (values, onChange, register) => (
            <GridItem gridColumn={2}>
              <FormControl>
                <FormLabel>Call to action</FormLabel>
                <HStack>
                  <Input {...register("callToAction")} placeholder={"Label"} />
                  <Input
                    {...register("link", {
                      validate: (value) => {
                        if (!value) {
                          return undefined;
                        }

                        if (!/^https?:\/\/.*/.test(value)) {
                          return "Invalid URL";
                        }

                        return undefined;
                      },
                    })}
                    type="url"
                    placeholder={"URL for click"}
                  />
                </HStack>
              </FormControl>
            </GridItem>
          ),
        },
        {
          key: "schedule",
          label: "Schedule",
          renderFormControl: (values, onChange, register) => (
            <RenderScheduleFields
              values={values}
              onChange={onChange}
              register={register}
            />
          ),
        },
        {
          type: "file",
          key: "image",
          label: "Image",
          helperText: "Insert Image URL",
          gridItemProps: { gridColumn: 2 },
          disabled: (values) => !values.name,
          fileAccept: "image/*",
          fileDropProps: (values) => ({
            shouldUpload: true,
            s3Params: {
              folderName: "featured_content_images",
              getFileName: [values.name, new Date().getTime()]
                .filter((c) => !!c)
                .join("_"),
              isPublic: true,
            },
          }),
        },
        {
          key: "imageSettings",
          label: "Image settings",
          isHidden: (values) => !values.image,
          renderFormControl: (values, onChange, register) => (
            <GridItem gridColumn={2}>
              <HStack w="100%">
                <FormControl>
                  <FormLabel>Image height</FormLabel>
                  <Slider
                    min={20}
                    max={400}
                    step={10}
                    value={values.imageHeight}
                    onChange={(val) => onChange("imageHeight", val)}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </FormControl>
                <FormControl>
                  <FormLabel>Image fit</FormLabel>
                  <Select {...register("imageFit")}>
                    <option value="contain">Contain</option>
                    <option value="cover">Cover</option>
                    <option value="fill">Fill</option>
                  </Select>
                </FormControl>
              </HStack>
            </GridItem>
          ),
        },
      ]}
      onSubmit={onSubmit}
      useFormProps={{
        defaultValues: content || {
          type: "advertisement",
          discountUnit: "%",
          imageHeight: 200,
          imageFit: "cover",
        },
      }}
      submitFromOutside={{ shouldSubmit }}
    />
  );
};

const FeaturedContentList: FunctionComponent<Props> = ({
  content = [],
  setContent,
  direction = "row",
  w = 500,
  viewOnly = false,
}) => {
  const [creatingNew, setCreatingNew] = useState(false);
  const [selectedContent, setSelectedContent] = useState<FeaturedContent>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const closeForm = () => {
    setCreatingNew(false);
    setSelectedContent(undefined);
    setSelectedIndex(undefined);
  };

  useEffect(() => {
    if (shouldSubmit) setShouldSubmit(false);
  }, [shouldSubmit]);

  const onFormFinish = (formContent?: FeaturedContent) => {
    if (!formContent) {
      closeForm();
      return;
    }
    const newList = [...content];
    if (typeof selectedIndex === "number" && selectedIndex > -1) {
      newList.splice(selectedIndex, 1, formContent);
    } else {
      const found = content.find((c) => c.name === formContent.name);
      if (found) {
        toast.warn("You already have an item with this name.");
        return;
      }
      newList.push(formContent);
    }
    setContent?.(newList);
    closeForm();
  };

  return (
    <VStack w={w} alignItems="flex-start">
      <ResponsiveModal
        isOpen={!!selectedContent || creatingNew}
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {typeof selectedIndex === "number" ? "Edit" : "Create"} Featured
            Content
          </ModalHeader>
          <ModalCloseButton
            onClick={() => {
              closeForm();
            }}
          />
          <ModalBody>
            <FeaturedContentForm
              content={selectedContent}
              setContent={onFormFinish}
              shouldSubmit={shouldSubmit}
            />
          </ModalBody>
          <ModalFooter>
            <HStack
              alignSelf="flex-end"
              p={5}
              w="100%"
              borderTop="1px solid gainsboro"
            >
              <Spacer />
              <Button onClick={() => onFormFinish(undefined)}>Cancel</Button>
              <Button
                colorScheme="blue"
                onClick={() => {
                  setShouldSubmit(true);
                }}
              >
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </ResponsiveModal>
      {!viewOnly && <Button onClick={() => setCreatingNew(true)}>New</Button>}
      <Stack
        w="100%"
        p={5}
        overflow="auto"
        direction={direction}
        alignItems={direction === "row" ? "flex-start" : "center"}
      >
        {content.map((featuredContent, i) => (
          <FeaturedContentCard
            content={featuredContent}
            onClose={() =>
              setContent?.((oldContent) => {
                const newContent = [...oldContent];
                newContent.splice(i, 1);
                return newContent;
              })
            }
            onEdit={(toEdit) => {
              setSelectedContent(toEdit);
              setSelectedIndex(i);
            }}
            viewOnly={viewOnly}
          />
        ))}
      </Stack>
    </VStack>
  );
};

export default FeaturedContentList;
