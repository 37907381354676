import { ArrowDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Icon,
  Img,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { BsFileEarmarkSpreadsheetFill } from "react-icons/all";
import { useLocation, useNavigate } from "react-router";
import searchIcon from "../../assets/images/search_icon.png";
import { trackClick } from "../../services/tracking.service";
import { primary, primaryLight } from "../../theme/colors";
import { DisableStoresSteps } from "./DisableStoresSteps";

export default function DisableStoresTab() {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabActive, setTabActive] = useState(0);

  return (
    <VStack w="100%" h="100%" textAlign="start" spacing={5}>
      {!tabActive && (
        <SimpleGrid w="100%" columns={2} textAlign="center" spacing={5}>
          <VStack
            rounded="lg"
            p={10}
            border={`1px solid ${primaryLight}`}
            flex={1}
            bg="white"
          >
            <Box pos="relative" boxSize="4em">
              <Button
                variant="link"
                as="div"
                padding="12px 10px"
                isActive
                _hover={{ textDecoration: "none" }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                leftIcon={
                  <Img
                    src={searchIcon}
                    height="1rem"
                    width="1rem"
                    filter="invert(48%) sepia(45%) saturate(4075%) hue-rotate(177deg) brightness(97%) contrast(102%)"
                  />
                }
                iconSpacing={0}
              >
                Search
              </Button>
              <ArrowDownIcon
                pos="absolute"
                bottom={0}
                right={0}
                bg="white"
                rounded="lg"
                boxSize="1em"
                color={primary}
              />
            </Box>
            <Button
              colorScheme="blue"
              onClick={() =>
                trackClick("store-disable-from-table", "", () => {
                  if (location.pathname.includes("storelocator")) {
                    navigate(`/storelocator/stores`, {
                      state: { tabIndex: 1 },
                    });
                    return;
                  }
                  navigate(`/StoresTabs/stores-manage`, {
                    state: { tabIndex: 1 },
                  });
                })
              }
            >
              Select from your locations
            </Button>
            <Text fontSize={12}>
              This is the best option to disable locations from your locations
              table.
            </Text>
            <Spacer />
            <Button
              variant="link"
              w="100%"
              whiteSpace="initial"
              onClick={() =>
                window.open(
                  "https://dathic.notion.site/Enable-or-disable-locations-b1568dec533f4432a8d675eb89558ab2?pvs=4"
                )
              }
            >
              Learn how to disable locations from your locations table
            </Button>
          </VStack>
          <VStack
            rounded="lg"
            p={10}
            border={`1px solid ${primaryLight}`}
            flex={1}
            bg="white"
          >
            <Box pos="relative" boxSize="4em">
              <Button
                variant="link"
                as="div"
                padding="12px 10px"
                isActive
                _hover={{ textDecoration: "none" }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                leftIcon={
                  <Icon
                    as={BsFileEarmarkSpreadsheetFill}
                    boxSize="1rem"
                    color="blue.400"
                  />
                }
                iconSpacing={0}
              >
                File
              </Button>
              <ArrowDownIcon
                pos="absolute"
                bottom={0}
                right={0}
                bg="white"
                rounded="lg"
                boxSize="1em"
                color={primary}
              />
            </Box>
            <Button
              colorScheme="blue"
              onClick={() =>
                trackClick("store-disable-from-spreadsheet", "", () =>
                  setTabActive(1)
                )
              }
              display="flex"
              flexDir="column"
            >
              Disable from a spreadsheet
            </Button>
            <Text fontSize={12}>
              This is the best option to disable locations you have on an
              external database.
            </Text>
            <Spacer />
            <Button
              variant="link"
              w="100%"
              whiteSpace="initial"
              onClick={() =>
                window.open(
                  "https://dathic.notion.site/Disable-locations-with-a-spreadsheet-0a9ea649bb1442d88f8eb6b86d46a048?pvs=4"
                )
              }
            >
              Learn about disabling from a Spreadsheet
            </Button>
          </VStack>
        </SimpleGrid>
      )}
      {tabActive === 1 && (
        <DisableStoresSteps onFinish={() => setTabActive(0)} />
      )}
    </VStack>
  );
}
