// INITIAL_STATE
import { createAsyncThunk, createSlice, unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  deleteLayer,
  getLayer,
  postLayer,
  putLayer,
} from "../../services/api.service";
import { appendVisData } from "../map/keplerReducer";
import { removeLayer, wrapTo } from "kepler.gl/actions";
import { unparse } from "papaparse";
import { processCsvData } from "kepler.gl/dist/processors";
import { RESOURCES } from "../../constants/user-constants";
import {
  onLayerClick,
  removeDataset,
} from "kepler.gl/dist/actions/vis-state-actions";
import {
  defaultStoreLayerConfig,
  rankStores,
} from "../map-selection/mapSelectionSlice";
import {
  defaultAudienceLayerConfig,
  rankZipcodes,
} from "../audience/audienceSlice";
import { defaultInsightLayerConfig } from "../insights/insightSlice";
import { KeplerGlSchema } from "kepler.gl/schemas";

const _ = require("lodash");

const initialState = {
  layers: {},
  deletedLayers: [],
  loadingLayers: [],
  loadedLayers: [],
  failedLayers: [],
};

export const fetchLayerData = createAsyncThunk(
  "mapViews/getLayer",
  async (
    { layerId, mapConfig, mapViewConfig, viewKey },
    { dispatch, getState }
  ) => {
    const { accessToken, currentUser, orgProperties } = getState().app;
    const { views, activeView } = getState().mapViews;
    const result = await getLayer(layerId, accessToken);
    const _mapConfig = _.cloneDeep(mapConfig) || {};
    if (_mapConfig.config) {
      delete _mapConfig.config.mapStyle;
    }

    if (result) {
      const layerType = result.config.info.id.includes("fetched_org_stores")
        ? "org_stores"
        : result.config.info.id.includes("fetched_stores")
        ? "stores"
        : result.config.info.id.includes("annotations")
        ? "annotations"
        : result.config.info.id.includes("selected_audience")
        ? "audience"
        : result.config.info.id.includes("insights")
        ? "insights"
        : "generic";
      dispatch(
        setLayers((oldLayers) => ({
          ...oldLayers,
          [`view${views[`view${activeView}`].id}`]: [
            ...(oldLayers?.[`view${views[`view${activeView}`].id}`] || []),
            { id: result.id, datasetId: result.config.info.id },
          ],
        }))
      );
      const data = result.geolayer_details
        ?.map((layerDetail, id) => {
          let item = Object.values(layerDetail).find(
            (item) => item && !!Object.keys(item).length
          );
          item.insights = item.insights?.map((insight) =>
            Object.keys(insight).reduce(
              (acc, key) =>
                result.config.columns?.includes(key) && key !== "id"
                  ? { ...acc, [key]: insight[key] }
                  : acc,
              {}
            )
          );
          Object.keys(item.insights?.[0] || {}).forEach((key) => {
            item[key] = item.insights[0][key];
          });
          item.geolayer_detail_id = layerDetail.id;
          item.score = layerDetail.score_store || layerDetail.score_demographic;
          let geometry =
            item.geometry || item.geometry_mid || item.geometry_low;
          geometry =
            typeof geometry === "string"
              ? JSON.parse(geometry)
              : geometry || undefined;
          item =
            (layerDetail.geolayer_detail_generic_id &&
              item.properties &&
              JSON.parse(item.properties)) ||
            item;
          if (layerType === "insights") {
            const latitude = item.latitude;
            const longitude = item.longitude;
            item = item.properties;
            item.latitude = latitude;
            item.longitude = longitude;
          }
          if (result.color_by && !item[result.color_by]) {
            const sumAudience = result.color_by
              .split("_&_")
              .reduce((acc, audience) => acc + (item[audience] || 0), 0);
            item[result.color_by] = sumAudience;
          }
          const _geojson = geometry
            ? JSON.stringify(
                geometry.type === "FeatureCollection"
                  ? { ...geometry.features[0], properties: item }
                  : {
                      geometry,
                      id,
                      properties: item,
                      type: "Feature",
                    }
              )
            : item.latitude && item.longitude
            ? JSON.stringify({
                geometry: {
                  coordinates: [item.longitude, item.latitude],
                  type: "Point",
                },
                id,
                properties: item,
                type: "Feature",
              })
            : undefined;
          if (_geojson) {
            Object.keys(item).forEach((key) => {
              if (
                key === "lat" ||
                key === "latitude" ||
                key.includes("lng") ||
                key.includes("longi") ||
                key.includes("geometry") ||
                key.includes("_lon")
              ) {
                delete item[key];
              }
            });
          }

          delete item.deleted_at;
          Object.keys(item).forEach((key) => {
            if (item[key] === null || item[key] === undefined) {
              delete item[key];
            }
          });
          return _geojson
            ? {
                ...item,
                _geojson,
              }
            : item;
        })
        .filter(
          (item) =>
            item.icon ||
            !!item._geojson ||
            ((item.lat || item.latitude) && (item.lng || item.longitude))
        )
        .sort((a, b) => Object.values(b).length - Object.values(a).length);

      if (data?.length) {
        const newDataCsv = unparse(data);
        let layerConfig = _mapConfig.config?.visState?.layers?.find(
          (layer) => layer.config.dataId === result.config.info.id
        );
        if (layerConfig) {
          _mapConfig.config.visState.layers = [layerConfig];
        } else {
          if (layerType === "audience") {
            const audienceConfig = defaultAudienceLayerConfig(
              result.config.info,
              currentUser.resources,
              result.color_by,
              result.color_by_secondary
            );

            _mapConfig.config = audienceConfig.config;
          } else if (["stores", "org_stores"].includes(layerType)) {
            let storesConfig = defaultStoreLayerConfig(
              result.config.info,
              layerType === "org_stores",
              orgProperties,
              result.color_by,
              result.color_by_secondary
            );

            _mapConfig.config = storesConfig.config;
          } else if (layerType === "insights") {
            const insightsConfig = defaultInsightLayerConfig(
              result.config.info,
              currentUser,
              orgProperties,
              result.color_by,
              result.color_by_secondary
            );
            _mapConfig.config = insightsConfig.config;
          }
        }
        const processedData = processCsvData(newDataCsv);
        try {
          dispatch(
            wrapTo(
              `view${viewKey}`,
              appendVisData({
                // datasets
                datasets: {
                  info: result.config.info,
                  data: processedData,
                },
                // option
                options: {
                  centerMap: true,
                  keepExistingConfig: true,
                },
                config: _mapConfig,
              })
            )
          );
        } catch (error) {
          toast.error(error);
        }

        let wrappedRanking;
        if (["stores", "org_stores"].includes(layerType)) {
          wrappedRanking = await dispatch(
            rankStores({
              layerId,
              stores: data,
              orgLayer: layerType === "org_stores",
              config: _mapConfig,
              layerDataId: result.config.info.id,
              nationalities: mapViewConfig?.audience?.selectedAudience,
            })
          );
        } else if (layerType === "audience") {
          wrappedRanking = await dispatch(
            rankZipcodes({
              layerId,
              zipcodes: data,
              layerDataId: result.config.info.id,
              config: _mapConfig,
              colorBy: result.color_by,
            })
          );
        }
        let unwrappedRanking;
        if (wrappedRanking) {
          try {
            unwrappedRanking = await unwrapResult(wrappedRanking);
            if (unwrappedRanking?.length) {
              dispatch(
                updateGeoLayer({
                  id: layerId,
                  geolayer_details: unwrappedRanking
                    .map((item) => ({
                      id: data.find((dataItem) => dataItem.id === item.id)
                        ?.geolayer_detail_id,
                      [["stores", "org_stores"].includes(layerType)
                        ? "store_id"
                        : "demographic_id"]: item.id,
                      [["stores", "org_stores"].includes(layerType)
                        ? "score_store"
                        : "score_demographic"]: item.score,
                    }))
                    .filter((detail) => !!detail.id),
                  layer_type:
                    layerType === "org_stores" ? layerType : undefined,
                })
              );
            }
          } catch (error) {
            console.log(error?.message);
          }
        }
      }
      return { info: result.config.info, data };
    }
  }
);

export const changeLayerData = createAsyncThunk(
  "layers/changeLayerData",
  async ({ layerId, newData }, { dispatch, getState }) => {
    const { views, activeView } = getState().mapViews;
    const view = views?.[`view${activeView}`];
    const currentMap = getState().keplerGl[`view${view?.id}`];
    const clicked =
      currentMap?.visState?.clicked || currentMap?.visState?.customClicked;
    const _mapConfig = KeplerGlSchema.getConfigToSave(currentMap) || {};
    if (_mapConfig.config) {
      delete _mapConfig.config.mapStyle;
    }

    let layerConfig = _mapConfig.config?.visState?.layers?.find(
      (layer) => layer.id === layerId
    );
    if (layerConfig) {
      const layersToDelete = _mapConfig.config?.visState?.layers?.filter(
        (layer) => layer.id === layerId
      );

      dispatch(
        deleteGeoLayer({
          layersToDelete: layersToDelete.map((layer) => layer.config.dataId),
        })
      );
      layerConfig.config.dataId =
        layerConfig.config.dataId + new Date().getTime();
      _mapConfig.config.visState.layers = [layerConfig];
      const info = {
        label: layerConfig.config.label,
        id: layerConfig.config.dataId,
      };
      const layerType = info.id.includes("fetched_org_stores")
        ? "org_stores"
        : info.id.includes("fetched_stores")
        ? "stores"
        : info.id.includes("annotations")
        ? "annotations"
        : info.id.includes("selected_audience")
        ? "audience"
        : "generic";
      if (newData?.length) {
        let columns = [];
        let newGeoLayer;
        const wrappedGeolayer = await dispatch(
          createGeoLayer({
            name: info.label,
            geolayer_details: newData.map((item) => {
              columns = [...new Set([...columns, ...Object.keys(item)])];
              return {
                geolayer_detail_generic:
                  layerType === "generic"
                    ? {
                        // delivery or insights or generic
                        geometry: JSON.stringify(
                          (item._geojson &&
                            (typeof item._geojson === "string"
                              ? JSON.parse(item._geojson)
                              : item._geojson
                            ).geometry) ||
                            item.geometry
                        ),
                        properties: JSON.stringify(item),
                      }
                    : undefined,
                ...([
                  "stores",
                  "org_stores",
                  "annotations",
                  "audience",
                ].includes(layerType)
                  ? {
                      [["stores", "org_stores"].includes(layerType)
                        ? "store_id"
                        : layerType === "annotations"
                        ? "annotation_id"
                        : "demographic_id"]: item.id,
                    }
                  : {}),
                ...(["stores", "org_stores", "audience"].includes(layerType)
                  ? {
                      [["stores", "org_stores"].includes(layerType)
                        ? "score_store"
                        : "score_demographic"]: item.score,
                    }
                  : {}),
              };
            }),
            color_by: layerConfig.visualChannels.colorField.name,
            config: {
              info,
              columns,
            },
            view_id: view?.id,
            layer_type: layerType === "org_stores" ? layerType : undefined,
          })
        );
        newGeoLayer = await unwrapResult(wrappedGeolayer);
        newData = newData.map((item) => ({
          ...item,
          geolayer_detail_id: newGeoLayer.geolayer_details.find(
            (detail) =>
              detail[
                ["stores", "org_stores"].includes(layerType)
                  ? "store_id"
                  : layerType === "annotations"
                  ? "annotation_id"
                  : "demographic_id"
              ] === item.id
          )?.id,
        }));
        const newDataCsv = unparse(newData);
        const processedData = processCsvData(newDataCsv);
        try {
          dispatch(
            wrapTo(
              `view${view?.id}`,
              appendVisData({
                // datasets
                datasets: {
                  info,
                  data: processedData,
                },
                // option
                options: {
                  centerMap: true,
                  keepExistingConfig: true,
                },
                config: _mapConfig,
              })
            )
          );
          const newMap = getState().keplerGl[`view${view?.id}`];
          const newClickedObject = newData.find(
            (d) =>
              JSON.parse(d._geojson || null)?.properties?.id ===
              clicked?.object?.properties?.id
          );
          if (newClickedObject) {
            const newLayerId = newMap.visState.layers.find(
              (l) => l.config.dataId === info.id
            ).id;
            const clickedInfo = {
              ...clicked,
              object: JSON.parse(newClickedObject._geojson),
              layer: { ...clicked.layer, id: newLayerId },
            };
            dispatch(wrapTo(`view${view?.id}`, onLayerClick(clickedInfo)));
          }
        } catch (error) {
          toast.error(error);
        }
      }

      return { info: layerConfig.config.info, newData };
    }
  }
);

// REDUCER
const layersSlice = createSlice({
  name: "layers",
  initialState,
  reducers: {
    setLayers: (state, action) => {
      state.layers =
        typeof action.payload === "function"
          ? action.payload(state.layers) || []
          : action.payload || [];
    },
    setDeletedLayers: (state, action) => {
      state.deletedLayers =
        typeof action.payload === "function"
          ? action.payload(state.deletedLayers) || {}
          : action.payload || {};
    },
    setLoadingLayers: (state, action) => {
      state.loadingLayers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLayerData.pending, (state, action) => {
        state.loadingLayers = [...state.loadingLayers, action.meta.arg.layerId];
      })
      .addCase(fetchLayerData.fulfilled, (state, action) => {
        state.loadingLayers = state.loadingLayers.filter(
          (layerId) => layerId !== action.meta.arg.layerId
        );
        state.loadedLayers = [
          ...(state.loadedLayers || []),
          action.meta.arg.layerId,
        ];
      })
      .addCase(fetchLayerData.rejected, (state, action) => {
        state.loadingLayers = state.loadingLayers.filter(
          (layerId) => layerId !== action.meta.arg.layerId
        );
        state.failedLayers = [
          ...(state.failedLayers || []),
          { id: action.meta.arg.layerId, message: action.error?.message },
        ];
      })
      .addCase("layers/deleteGeoLayer/rejected", (state, action) => {
        toast.error(`Layer failed to delete`);
      });
  },
});

export const deleteGeoLayer = createAsyncThunk(
  "layers/deleteGeoLayer",
  async (payload, { dispatch, getState }) => {
    const { accessToken } = getState().app;
    const { layers } = getState().layers;
    const { views, activeView } = getState().mapViews;
    const datasetId =
      payload?.layersToDelete ||
      getState().keplerGl[`view${views?.[`view${activeView}`]?.id}`]?.visState
        ?.removeLayer;

    const layersToDelete =
      layers[`view${views?.[`view${activeView}`]?.id}`]?.filter((layer) =>
        [...(typeof datasetId === "string" ? [datasetId] : datasetId)].includes(
          layer.datasetId
        )
      ) || [];

    const deletedLayers = [];
    await Promise.all(
      layersToDelete.map(
        (layerToDelete) =>
          new Promise(async (resolve, reject) => {
            try {
              await deleteLayer(layerToDelete.id, accessToken);
              deletedLayers.push(layerToDelete);
              const keplerLayers = [
                ...(getState().keplerGl[
                  `view${views?.[`view${activeView}`]?.id}`
                ]?.visState?.layers || []),
              ];
              const layerIndex = keplerLayers.findIndex(
                (layer) => layer.config.dataId === layerToDelete.datasetId
              );
              if (layerIndex >= 0) {
                dispatch(
                  wrapTo(
                    `view${views?.[`view${activeView}`]?.id}`,
                    removeLayer(layerIndex)
                  )
                );
              }
            } catch (error) {
              if (payload?.layersToDelete) {
                toast.error(
                  `Could not delete layer: ${layerToDelete.id} - ${error.message}`
                );
              }
            }
            resolve();
          })
      )
    );

    if (deletedLayers.length) {
      dispatch(
        setLayers((oldLayers) => ({
          ...oldLayers,
          [`view${views[`view${activeView}`].id}`]: oldLayers?.[
            `view${views[`view${activeView}`].id}`
          ]?.filter(
            (layerToTest) =>
              !deletedLayers.find((deleted) => deleted.id === layerToTest.id)
          ),
        }))
      );
      [...(typeof datasetId === "string" ? [datasetId] : datasetId)].forEach(
        (id) => {
          dispatch(
            wrapTo(`view${views[`view${activeView}`].id}`, removeDataset(id))
          );
        }
      );
    }
    return deletedLayers;
  },
  {
    condition: (arg, { getState }) => {
      const state = getState();
      const { currentUser } = state.app;
      const { activeView, viewAccess } = state.mapViews;
      const userResources = currentUser.resources;

      return !!(
        userResources?.includes(RESOURCES.LAYER_DELETE) &&
        viewAccess[activeView] !== "read"
      );
    },
  }
);

export const createGeoLayer = createAsyncThunk(
  "layers/createGeoLayer",
  async (layerToCreate, { dispatch, getState }) => {
    const { accessToken } = getState().app;
    const { views, activeView } = getState().mapViews;

    let result = await postLayer(layerToCreate, accessToken);
    dispatch(
      setLayers((oldLayers) => ({
        ...oldLayers,
        [`view${views[`view${activeView}`].id}`]: [
          ...(oldLayers?.[`view${views[`view${activeView}`].id}`] || []),
          { id: result.id, datasetId: result.config.info.id },
        ],
      }))
    );
    return result;
  },
  {
    condition: (arg, { getState }) => {
      const state = getState();
      const { currentUser } = state.app;
      const { activeView, viewAccess } = state.mapViews;
      const userResources = currentUser.resources;

      return !!(
        userResources?.includes(RESOURCES.LAYER_CREATE) &&
        !!arg.view_id &&
        viewAccess[activeView] !== "read"
      );
    },
  }
);

export const updateGeoLayer = createAsyncThunk(
  "layers/updateGeoLayer",
  async (layerToUpdate, { dispatch, getState }) => {
    const { accessToken } = getState().app;

    let result = await putLayer(layerToUpdate, accessToken);
    return result;
  },
  {
    condition: (arg, { getState }) => {
      const state = getState();
      const { currentUser } = state.app;
      const { activeView, viewAccess } = state.mapViews;
      const userResources = currentUser.resources;

      return !!(
        userResources?.includes(RESOURCES.LAYER_EDIT_CONFIG) &&
        !!arg.id &&
        viewAccess[activeView] !== "read"
      );
    },
  }
);

export const selectLoadingLayers = (state) => state.layers.loadingLayers;

export const selectLoadedLayers = (state) => state.layers.loadedLayers;

export const selectFailedLayers = (state) => state.layers.failedLayers;

export const { setLayers, setLoadingLayers } = layersSlice.actions;

export default layersSlice.reducer;
