import { QuestionIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Img,
  Input,
  InputGroup,
  InputRightAddon,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import React, { useContext, useState } from "react";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { toast } from "react-toastify";
import { selectAppInitialized, selectCurrentUser } from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import logo from "../../assets/images/logo.png";
import ringBottomLeft from "../../assets/images/ring_bottomleft.png";
import ringTopRight from "../../assets/images/ring_topright.png";
import MessageModalContext from "../../contexts/MessageModalContext";
import {
  getOrgProperties,
  listUsers,
  postOrganization,
  postOrgProperties,
  postUserRole,
  putUser,
} from "../../services/api.service";
import { createUser, login } from "../../services/auth.service";

const PasswordInput = ({
  password,
  setPassword,
}: {
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input
        type={show ? "text" : "password"}
        placeholder="Enter your password"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
      />
      <InputRightAddon>
        {!show && <IoEye onClick={handleClick} />}
        {show && <IoEyeOff onClick={handleClick} />}
      </InputRightAddon>
    </InputGroup>
  );
};

export function PasswordResetMessage({
  email,
  setEmail,
  disableInput,
}: {
  email: string;
  setEmail: (e: string) => void;
  disableInput?: boolean;
}) {
  return (
    <VStack>
      <Text>
        An email will be sent with instructions to the following email
      </Text>
      <FormControl isRequired>
        <FormLabel>Email address</FormLabel>
        <Input
          type="email"
          placeholder="name@example.com"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          isDisabled={!!disableInput}
        />
      </FormControl>
    </VStack>
  );
}

function _Login({ registering }: { registering?: boolean }) {
  const currentUser = useAppSelector(selectCurrentUser);
  const appInitialized = useAppSelector(selectAppInitialized);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);
  const [isRegistering, setIsRegistering] = useState(registering);
  const [organization, setOrganization] = useState("");
  const messageModalContext = useContext(MessageModalContext);
  const auth = getAuth();

  const handleLogin: React.FormEventHandler<HTMLFormElement> = async (
    event
  ) => {
    event.preventDefault();

    setLoggingIn(true);
    try {
      let dathicCredential;
      if (isRegistering) {
        dathicCredential = await createUser(email, password, name);
        if (dathicCredential?.user_id) {
          const userFromDB = await listUsers(
            dathicCredential?.user_id,
            dathicCredential?.access_token
          );
          const newOrg = await postOrganization(
            organization,
            dathicCredential?.access_token
          );
          const defaultOrpProps = await getOrgProperties(
            dathicCredential?.access_token,
            12
          );
          await postOrgProperties(
            {
              organization_id: newOrg.id,
              facebook_ad_config: defaultOrpProps.facebook_ad_config,
              facebook_dathic_config: defaultOrpProps.facebook_dathic_config,
              facebook_leads: defaultOrpProps.facebook_leads,
            },
            dathicCredential?.access_token
          );
          await putUser(
            {
              id: userFromDB.id,
              access_token: userFromDB.access_token,
              token: userFromDB.token,
              username: userFromDB.username,
              user_id: userFromDB.user_id,
              organization_id: newOrg?.id,
            },
            dathicCredential?.access_token
          );
          await postUserRole(
            { user_id: dathicCredential?.user_id, role_id: 2 },
            dathicCredential?.access_token
          );
        } else {
          throw new Error("Could not create user");
        }
      } else {
        dathicCredential = await login(email, password);
        if (dathicCredential.access_token?.message) {
          setLoggingIn(false);
          toast.warn(dathicCredential.access_token?.message);
          return;
        }
      }
      localStorage.setItem(
        "dathicCredential",
        JSON.stringify(dathicCredential)
      );
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error: any) {
      setLoggingIn(false);
      toast.error(error.message);
    }
  };

  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      background="lightBlue.50"
      height="100%"
      width="100%"
      pos="absolute"
    >
      {currentUser === null && (
        <SimpleGrid zIndex={2} gap={5} columns={1} w={"lg"}>
          <Image data-testid="logo" src={logo} alt="dathic" w="50%" m="auto" />
          <Heading as="h1">Welcome!</Heading>
          <form onSubmit={handleLogin}>
            <VStack p={10} bgColor="white" borderRadius={10} spacing={5}>
              {isRegistering && (
                <React.Fragment>
                  <FormControl isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input
                      placeholder="Name"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </FormControl>
                </React.Fragment>
              )}
              <FormControl isRequired>
                <FormLabel>Email address</FormLabel>
                <Input
                  type="email"
                  placeholder="name@example.com"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Password</FormLabel>
                <FormHelperText
                  pos="absolute"
                  margin="0.1rem 0 0 0"
                  top={0}
                  right={0}
                >
                  <Button
                    variant="link"
                    onClick={() => {
                      const catchError = (error: any) => {
                        switch (error.code) {
                          case "auth/invalid-email":
                            toast.error("Please enter a valid email");
                            break;
                          case "auth/user-not-found":
                            toast.error("A user with this email was not found");
                            break;

                          default:
                            toast.error("Error sending password reset link");
                            break;
                        }
                      };
                      messageModalContext.showModal({
                        title: "Password Reset",
                        initialDialogState: { email },
                        message: (dialogState, setDialogState) => (
                          <PasswordResetMessage
                            email={dialogState.email}
                            setEmail={(e: string) =>
                              setDialogState({ email: e })
                            }
                          />
                        ),
                        actions: [
                          {
                            label: "Cancel",
                            isLeastDestructive: true,
                          },
                          {
                            label: "Reset password",
                            props: { colorScheme: "blue" },
                            callback: (_, dialogState) => {
                              try {
                                sendPasswordResetEmail(auth, dialogState.email)
                                  .then(() => {
                                    toast(
                                      "Your password reset link has been sent to your email"
                                    );
                                  })
                                  .catch(catchError);
                              } catch (error) {
                                catchError(error);
                              }
                            },
                          },
                        ],
                      });
                    }}
                  >
                    Forgot password?
                  </Button>
                </FormHelperText>
                <PasswordInput password={password} setPassword={setPassword} />
              </FormControl>
              {isRegistering && (
                <React.Fragment>
                  <FormControl isRequired>
                    <FormLabel>Organization</FormLabel>
                    <Input
                      placeholder="Organization"
                      value={organization}
                      onChange={(event) => setOrganization(event.target.value)}
                    />
                  </FormControl>
                </React.Fragment>
              )}
              <Button isLoading={loggingIn} variant="solid" type="submit">
                {isRegistering ? "Register" : "Log in"}
              </Button>
            </VStack>
          </form>
          {!isRegistering && (
            <Text>
              Don't have an acccount?{" "}
              <Button
                variant="link"
                colorScheme="blue"
                onClick={() =>
                  window.open(
                    "https://calendly.com/laura-rocha/30_mins_dathic_product_locator_intro"
                  )
                }
              >
                Book a demo
              </Button>
            </Text>
          )}
        </SimpleGrid>
      )}
      {!appInitialized && currentUser !== null && (
        <VStack>
          <Spinner />
          <Text>Starting application...</Text>
        </VStack>
      )}
      <Img src={ringTopRight} top={0} right={0} pos="absolute" opacity={0.5} />
      <Img
        src={ringBottomLeft}
        bottom={0}
        left={0}
        pos="absolute"
        opacity={0.5}
      />
      <IconButton
        aria-label={"Support button"}
        icon={<QuestionIcon fontSize={28} />}
        colorScheme="blue"
        size="lg"
        rounded="full"
        bottom={3}
        right={3}
        pos="absolute"
        onClick={() =>
          window.open(
            "https://calendly.com/laura-rocha/30-mins-dathic--locator-support"
          )
        }
      />
    </Flex>
  );
}

export default _Login;
