import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { startCase } from "lodash";
import { useMemo } from "react";
import {
  selectCurrentUser,
  selectOrgProperties,
  selectUserRoles,
} from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import PageLayout from "../../components/PageLayout";
import MyAccount from "./MyAccount";
import OrgOverview from "./OrgOverview";

export default function OrgScreen() {
  const orgProperties = useAppSelector(selectOrgProperties);
  const userRoles = useAppSelector(selectUserRoles);
  const currentUser = useAppSelector(selectCurrentUser);
  const userIsOnlyStoreLocator = useMemo(
    () =>
      userRoles.find((ur: any) => ur.role?.name.includes("store_locator")) &&
      !userRoles.find((ur: any) => !ur.role?.name.includes("store_locator")),
    [userRoles]
  );
  return (
    <PageLayout
      label={[
        currentUser?.name,
        startCase(userRoles?.[0]?.role?.name),
        orgProperties?.organization?.name,
      ].join(" - ")}
      title="My Account"
    >
      <Tabs w="100%">
        {!userIsOnlyStoreLocator && (
          <TabList>
            <Tab>Overview</Tab>
            <Tab>My Account</Tab>
          </TabList>
        )}
        <TabPanels>
          {!userIsOnlyStoreLocator && (
            <TabPanel>
              <OrgOverview />
            </TabPanel>
          )}
          <TabPanel>
            <MyAccount />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageLayout>
  );
}
