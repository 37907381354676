import {
  HStack,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderProps,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
  Slider as ChakraSlider,
  SliderProps,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";

type Props =
  | {
      type?: "range";
      sliderProps: RangeSliderProps;
    }
  | {
      type?: "single";
      sliderProps: SliderProps;
    };

export default function Slider({
  type = "range",
  sliderProps = {},
  children,
}: PropsWithChildren<Props>) {
  const rangeSliderProps = sliderProps as RangeSliderProps;
  const _sliderProps = sliderProps as SliderProps;
  return (
    <HStack p={3} w="100%">
      <Text>
        {(sliderProps?.min ?? 0).toLocaleString(undefined, {
          notation: "compact",
        })}
      </Text>
      {type === "range" ? (
        <RangeSlider
          {...rangeSliderProps}
          value={
            (!!rangeSliderProps.value?.[0] &&
              !!rangeSliderProps.value?.[1] &&
              rangeSliderProps.value) ||
            undefined
          }
        >
          {children}
          <RangeSliderTrack>
            <RangeSliderFilledTrack />
          </RangeSliderTrack>
          <RangeSliderThumb index={0} pos="relative">
            <Text pos="absolute" top={-6}>
              {rangeSliderProps.value?.[0] &&
                (rangeSliderProps.value?.[0]).toLocaleString(undefined, {
                  notation: "compact",
                })}
            </Text>
          </RangeSliderThumb>
          <RangeSliderThumb index={1} pos="relative">
            <Text pos="absolute" top={-6}>
              {rangeSliderProps.value?.[1] &&
                (rangeSliderProps.value?.[1] || 0).toLocaleString(undefined, {
                  notation: "compact",
                })}
            </Text>
          </RangeSliderThumb>
        </RangeSlider>
      ) : (
        <ChakraSlider {..._sliderProps}>
          {children}
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb>
            <Text pos="absolute" top={-6}>
              {_sliderProps.value &&
                (_sliderProps.value || 0).toLocaleString(undefined, {
                  notation: "compact",
                })}
            </Text>
          </SliderThumb>
        </ChakraSlider>
      )}
      <Text>
        {(sliderProps?.max ?? 0).toLocaleString(undefined, {
          notation: "compact",
        })}
      </Text>
    </HStack>
  );
}
