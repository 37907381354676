export const AUTH_TOKENS = {
  MAPBOX_TOKEN:
    "pk.eyJ1IjoiZGF0aGljIiwiYSI6ImNrYzg2cHBmMTE0ODQycXQ2dHEzdmZlajEifQ.OZvk_-izmIuFlYmkq7kRIw",
};

export const APP_INFO = {
  VERSION: "0.0.1",
  NAME: "Dathic Growth",
};

export const DEFAULT_MAP_CONFIG = {}
