import { VStack } from "@chakra-ui/react";
import PageLayout from "../../components/PageLayout";
import { ReferralLinks } from "../../components/ReferralLinks";

function ReferScreen() {
  return (
    <PageLayout title="Refer a friend to Dathic">
      <VStack w="100%" spacing={10} textAlign="start">
        <VStack w="100%">
          <VStack p={3} bg="white" rounded="lg" w="100%" spacing={5}>
            <ReferralLinks />
          </VStack>
        </VStack>
      </VStack>
    </PageLayout>
  );
}

export default ReferScreen;
