import {
  Box,
  Button,
  CloseButton,
  Flex,
  Heading,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { wrapTo } from "kepler.gl/actions";
import { onLayerClick } from "kepler.gl/dist/actions/vis-state-actions";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResponsiveModal } from "../../../components/responsive-modal";
import { setComparisonSelection } from "../../map-selection/mapSelectionSlice";
import { selectCurrentView, selectFavorites } from "../mapViewsSlice";
import FavoriteDisplay from "./FavoriteDisplay";
import { selectOrgProperties } from "../../../app/appSlice";
import { selectClickedObject } from "../../map/keplerReducer";
import { useNavigate } from "react-router";

var pluralize = require("pluralize");

export const FavoritesContent = ({ onClose }) => {
  const navigate = useNavigate();
  const orgConfig = useSelector(selectOrgProperties);
  const favorites = useSelector(selectFavorites);
  const activeView = useSelector(selectCurrentView);
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState([]);
  const checkedZipcodes = useMemo(
    () => [
      ...new Set(
        checked
          .map((item) => item.store?.zipcode || item.demographic?.zipcode)
          .filter((item) => !!item)
      ),
    ],
    [checked]
  );
  const groupedFavorites = (favorites || []).reduce((acc, fav) => {
    const type = fav.store_id ? "Stores" : "Zipcodes";
    return { ...acc, [type]: [...(acc[type] || []), fav] };
  }, {});
  const storeLabel = orgConfig?.properties?.storeNameReplacement || "Store";
  return (
    <VStack>
      <Flex
        w="100%"
        flexDir="row"
        justifyContent="space-between"
        alignItems={"center"}
        p={2}
      >
        <Button
          disabled={checkedZipcodes.length < 2}
          variant="outline"
          colorScheme={"blue"}
          onClick={() => {
            dispatch(
              setComparisonSelection({
                demographic: checked
                  .filter((c) => !!c.demographic)
                  .map((d) => d.demographic),
                store: checked.filter((c) => !!c.store).map((c) => c.store),
              })
            );
            setChecked([]);
            onClose?.();
            navigate("/");
          }}
        >
          Compare
        </Button>
        <Heading textAlign={"center"} w="100%">
          Favorites
        </Heading>
        {onClose ? <CloseButton size="md" onClick={onClose} /> : <Box></Box>}
      </Flex>
      <Tabs w={"100%"} isFitted>
        <TabList>
          <Tab>Zipcodes</Tab>
          <Tab>{pluralize(storeLabel)}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <FavoriteDisplay
              onClose={onClose || (() => {})}
              checked={checked}
              setChecked={setChecked}
              checkedZipcodes={checkedZipcodes}
              groupedFavorites={groupedFavorites}
              type={"Zipcodes"}
              clickObject={(payload) =>
                dispatch(
                  wrapTo(
                    `view${activeView}`,
                    onLayerClick({ object: { properties: payload } })
                  )
                )
              }
              activeView={activeView?.id}
            />
          </TabPanel>
          <TabPanel>
            <FavoriteDisplay
              checked={checked}
              setChecked={setChecked}
              checkedZipcodes={checkedZipcodes}
              groupedFavorites={groupedFavorites}
              type={"Stores"}
              clickObject={(payload) =>
                dispatch(
                  wrapTo(
                    `view${activeView}`,
                    onLayerClick({ object: { properties: payload } })
                  )
                )
              }
              activeView={activeView?.id}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

function FavoritesModal({ isOpen, onClose }) {
  const clickedObject = useSelector(selectClickedObject);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  return (
    <ResponsiveModal
      isCentered
      isOpen={isOpen && !clickedObject}
      scrollBehavior="inside"
    >
      <ModalOverlay opacity={50} />
      <ModalContent h={560} w={isMobile ? "90%" : "35%"}>
        <ModalBody>
          <FavoritesContent onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </ResponsiveModal>
  );
}
export default FavoritesModal;
