import { CopyIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Code,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { cloneDeep, kebabCase, lowerCase, merge } from "lodash";
import QRCode from "qrcode";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { IoTrash } from "react-icons/io5";
import { toast } from "react-toastify";
import {
  selectAccessToken,
  selectOrgProperties,
  updateOrgProperties,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { PageSection } from "../../components/PageSection";
import { ShortUrl } from "../../domain/ShortUrl";
import { generateShortUrl } from "../../services/shrtco.service";
import QRSearch from "./QRSearch";
import { StoreLocatorConfiguration } from "./StoreLocatorConfig";

export type QrCode = {
  title: string;
  codeUrl: string;
  shortUrl?: string;
  shortUrlId?: number;
  customUrl?: string;
  type: "suggest_store_form" | "store_locator" | "search_query" | "custom_url";
};

type Props = {
  qrCodes?: QrCode[];
  onChange?: (newCodes: QrCode[]) => void;
  onSubmit?: (newConfig: StoreLocatorConfiguration) => void;
};

function QrCodeForm({
  addCode,
  existingQrCodes = [],
}: {
  addCode?: (newCodes: QrCode) => void;
  existingQrCodes?: QrCode[];
}) {
  const accessToken = useAppSelector(selectAccessToken);
  const [qrCodeUrl, setQrCodeUrl] = useState<QrCode["codeUrl"]>("");
  const [qrCodeType, setQrCodeType] =
    useState<QrCode["type"]>("suggest_store_form");
  const [codeTitle, setCodeTitle] = useState<QrCode["title"]>("");
  const [customUrl, setCustomUrl] = useState<QrCode["customUrl"]>("");

  const orgProperties = useAppSelector(selectOrgProperties);
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";

  const canvasRef = useRef();

  useEffect(() => {
    if (orgProperties?.properties?.storeLocatorUrl) {
      let urlStr: string = orgProperties.properties?.storeLocatorUrl;
      const url = new URL(urlStr);
      url.searchParams.append("utm_source", "dathic_qr");
      url.searchParams.append("utm_medium", "qr_code");
      url.searchParams.append("utm_campaign", codeTitle);
      switch (qrCodeType) {
        case "suggest_store_form":
          url.hash = "/suggest_form";
          break;
        case "custom_url":
          url.hash = "/url_redirect";
          url.searchParams.append(
            "redirect",
            encodeURIComponent(customUrl || "")
          );
          break;
        case "store_locator":
          break;
        case "search_query":
          // generate params
          break;

        default:
          break;
      }
      setQrCodeUrl(url.toString());
    }
  }, [codeTitle, customUrl, orgProperties, qrCodeType]);

  useEffect(() => {
    if (canvasRef.current) {
      QRCode.toCanvas(
        canvasRef.current,
        // QR code doesn't work with an empty string
        // so we are using a blank space as a fallback
        qrCodeUrl || " ",
        { width: 200 },
        (error) => error && console.error(error)
      );
    }
  }, [qrCodeUrl]);

  return (
    <HStack w="100%" alignItems="flex-end">
      <FormControl>
        <FormLabel>Link Name</FormLabel>
        <Input
          value={codeTitle}
          onChange={(e) => setCodeTitle(e.target.value || "")}
          placeholder="Insert name"
        />
      </FormControl>
      <FormControl>
        <FormLabel>Link Type</FormLabel>
        <Select
          value={qrCodeType}
          onChange={(e) => setQrCodeType(e.target.value as QrCode["type"])}
        >
          <option value="suggest_store_form">Suggest Form</option>
          <option value="store_locator">{storeLabel} Locator</option>
          <option value="custom_url">Custom URL</option>
          {/* <option value="search_query">Search Query</option> */}
        </Select>
      </FormControl>
      {qrCodeType === "custom_url" && (
        <FormControl
          isInvalid={!!customUrl && !/^https?:\/\/.*/.test(customUrl)}
        >
          <FormLabel>Custom URL</FormLabel>
          <Input
            value={customUrl}
            onChange={(e) => setCustomUrl(e.target.value)}
            placeholder="Insert destination URL"
          />
          {customUrl && !/^https?:\/\/.*/.test(customUrl) && (
            <FormErrorMessage>
              URL must begin with http:// or https://
            </FormErrorMessage>
          )}
        </FormControl>
      )}
      {qrCodeType === "search_query" && <QRSearch />}
      {addCode && (
        <Button
          onClick={() => {
            if (customUrl && !/^https?:\/\/.*/.test(customUrl)) {
              toast.warn("URL must begin with http:// or https://");
              return;
            }
            if (existingQrCodes.find((q) => q.codeUrl === qrCodeUrl)) {
              toast.warn("You already have a QR Code with the same settings");
              return;
            }
            generateShortUrl(qrCodeUrl, accessToken)
              .then((shortUrl: ShortUrl) => {
                if (shortUrl) {
                  addCode({
                    title: codeTitle,
                    codeUrl: qrCodeUrl,
                    shortUrl: "https://api-1.dathic.com/r/" + shortUrl.short_id,
                    shortUrlId: shortUrl.id,
                    type: qrCodeType,
                  });
                } else {
                  addCode({
                    title: codeTitle,
                    codeUrl: qrCodeUrl,
                    type: qrCodeType,
                  });
                }
              })
              .catch(() => {
                addCode({
                  title: codeTitle,
                  codeUrl: qrCodeUrl,
                  type: qrCodeType,
                });
              })
              .finally(() => {
                setCodeTitle(" ");
                setCodeTitle("");
                setQrCodeUrl("");
                setQrCodeType("suggest_store_form");
              });
          }}
          flexShrink={0}
        >
          Create Code
        </Button>
      )}
    </HStack>
  );
}

function QrCodeCard({ qrCode }: { qrCode: QrCode }) {
  const [qrCodeUrl, setQrCodeUrl] = useState<QrCode["codeUrl"]>(
    qrCode.codeUrl || ""
  );
  const qrCodeType = qrCode.type || "suggest_store_form";
  const codeTitle = qrCode.title || "";
  const [customUrl, setCustomUrl] = useState<QrCode["customUrl"]>(
    qrCode.customUrl || ""
  );

  const orgProperties = useAppSelector(selectOrgProperties);

  const canvasRef = useRef();

  useEffect(() => {
    if (orgProperties?.properties?.storeLocatorUrl) {
      let urlStr: string = orgProperties.properties?.storeLocatorUrl;
      const url = new URL(urlStr);
      url.searchParams.append("utm_source", "dathic_qr");
      url.searchParams.append("utm_medium", "qr_code");
      url.searchParams.append("utm_campaign", codeTitle);
      switch (qrCodeType) {
        case "suggest_store_form":
          url.hash = "/suggest_form";
          break;
        case "custom_url":
          url.hash = "/url_redirect";
          url.searchParams.append(
            "redirect",
            encodeURIComponent(customUrl || "")
          );
          break;
        case "store_locator":
          break;
        case "search_query":
          // generate params
          break;

        default:
          break;
      }
      setQrCodeUrl(url.toString());
    }
  }, [codeTitle, customUrl, orgProperties, qrCodeType]);

  useEffect(() => {
    if (canvasRef.current) {
      QRCode.toCanvas(
        canvasRef.current,
        // QR code doesn't work with an empty string
        // so we are using a blank space as a fallback
        qrCode.shortUrl || qrCodeUrl || " ",
        { width: 200 },
        (error) => error && console.error(error)
      );
    }
  }, [qrCode, qrCodeUrl]);

  const downloadQRImage = () => {
    if (canvasRef.current && qrCode) {
      var url = (canvasRef.current as any).toDataURL("image/png");
      var link = document.createElement("a");
      link.download = `qr_${kebabCase(qrCode.title || "no title")}.png`;
      link.href = url;
      link.click();
    }
  };

  return (
    <VStack>
      <IconButton
        aria-label="download qr code button"
        position="absolute"
        variant="link"
        top={2}
        left={0}
        color="blue.400"
        icon={<DownloadIcon />}
        onClick={downloadQRImage}
      />
      <VStack w="200px" p={3} rounded="lg" shadow="lg" bg="white">
        <Text color={qrCode.title ? undefined : "gray.200"}>
          {qrCode.title || "No title"}
        </Text>
        <Box h="200px">
          <canvas
            ref={
              canvasRef as unknown as
                | React.LegacyRef<HTMLCanvasElement>
                | undefined
            }
          />
        </Box>
        {!!(qrCode.shortUrl || qrCode.codeUrl) && (
          <Flex dir="row" w="100%">
            <Code noOfLines={1}>{qrCode.shortUrl || qrCode.codeUrl}</Code>
            <IconButton
              aria-label="Copy qr link to clipboard"
              icon={<CopyIcon />}
              onClick={() => {
                const text = qrCode.shortUrl || qrCode.codeUrl;
                navigator.clipboard.writeText(text);
              }}
            />
          </Flex>
        )}
        <Text fontSize={12}>
          Sends scanners to{" "}
          <strong>
            {qrCode.type === "custom_url"
              ? qrCode.customUrl
              : lowerCase(qrCode.type)}
          </strong>
        </Text>
        {qrCodeType === "custom_url" && (
          <FormControl
            isInvalid={!!customUrl && !/^https?:\/\/.*/.test(customUrl)}
          >
            <Input
              value={customUrl}
              onChange={(e) => setCustomUrl(e.target.value)}
              placeholder="Insert destination URL"
            />
            {customUrl && !/^https?:\/\/.*/.test(customUrl) && (
              <FormErrorMessage>
                URL must begin with http:// or https://
              </FormErrorMessage>
            )}
          </FormControl>
        )}
        {qrCodeType === "search_query" && <QRSearch />}
      </VStack>
    </VStack>
  );
}

export default function QRCampaigns({ onSubmit }: Props) {
  const orgProperties = useAppSelector(selectOrgProperties);
  const accessToken = useAppSelector(selectAccessToken);
  const dispatch = useAppDispatch();
  const currentConfig = orgProperties?.store_locator;

  const { handleSubmit, watch, setValue, formState } =
    useForm<StoreLocatorConfiguration>({
      defaultValues: merge(
        {
          storeDetails: {
            qrCodes: [],
          },
        },
        currentConfig || {}
      ),
    });

  const saveChanges = async (newConfig: StoreLocatorConfiguration) => {
    const newOrgConfig = cloneDeep(orgProperties || {});
    merge(newOrgConfig, {
      store_locator: {
        storeDetails: { qrCodes: newConfig.storeDetails.qrCodes || [] },
      },
    });
    // @ts-ignore
    const wrapped = dispatch(updateOrgProperties(newOrgConfig));
    // @ts-ignore
    return unwrapResult(wrapped);
  };

  const changes = watch();
  const qrCodes = changes.storeDetails?.qrCodes;

  useEffect(() => {
    const createDefaultQR = async () => {
      let shortUrl: ShortUrl = await generateShortUrl(
        orgProperties?.properties?.storeLocatorUrl,
        accessToken
      );
      try {
        shortUrl = await generateShortUrl(
          orgProperties?.properties?.storeLocatorUrl,
          accessToken
        );
      } catch (error) {
        console.log(error);
      }
      const newCodes: StoreLocatorConfiguration["storeDetails"]["qrCodes"] = [
        {
          title: "Store Locator URL",
          codeUrl: orgProperties?.properties?.storeLocatorUrl,
          shortUrl: shortUrl
            ? "https://api-1.dathic.com/r/" + shortUrl.short_id
            : undefined,
          shortUrlId: shortUrl?.id,
          type: "store_locator",
        },
      ];
      setValue("storeDetails.qrCodes", newCodes, {
        shouldDirty: true,
      });
      // @ts-ignore
      saveChanges({ storeDetails: { qrCodes: newCodes } });
    };
    if (!qrCodes?.length && orgProperties?.properties?.storeLocatorUrl) {
      createDefaultQR();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <form
      onSubmit={handleSubmit((newConfig) =>
        onSubmit ? onSubmit(newConfig) : saveChanges(newConfig)
      )}
      style={{ width: "100%" }}
    >
      <VStack w="100%" spacing={10}>
        {!!orgProperties?.properties?.storeLocatorUrl && (
          <PageSection
            title="Generate QR codes and links"
            label="Generate QR codes or links to redirect visitors to your store locator or suggest form and track conversions"
          >
            <QrCodeForm
              existingQrCodes={qrCodes}
              addCode={(newCode) =>
                setValue("storeDetails.qrCodes", [...qrCodes, newCode], {
                  shouldDirty: true,
                })
              }
            />
          </PageSection>
        )}
        {!!orgProperties?.properties?.storeLocatorUrl && (
          <PageSection
            title="My campaigns"
            label="Select a campaign. Copy or send a QR or a link"
          >
            {qrCodes.map((s, index) => (
              <Box pos="relative">
                <IconButton
                  aria-label="delete qr code button"
                  position="absolute"
                  variant="link"
                  top={2}
                  right={0}
                  color="red.400"
                  icon={<IoTrash />}
                  onClick={() => {
                    // TODO: delete ShortUrl
                    const newServices = [...qrCodes];
                    newServices.splice(index, 1);
                    setValue("storeDetails.qrCodes", newServices, {
                      shouldDirty: true,
                    });
                  }}
                />
                <QrCodeCard qrCode={s} />
              </Box>
            ))}
          </PageSection>
        )}
        {!!formState.isDirty && (
          <Button type="submit" ml={3}>
            Apply
          </Button>
        )}
      </VStack>
    </form>
  );
}
