import keplerGlReducer, {
  combinedUpdaters,
  visStateUpdaters,
} from "kepler.gl/reducers";
import { createAction, createSelector } from "@reduxjs/toolkit";
import { joinWords } from "../../utils/stringUtils";
import { Store } from "../../domain/Store";
import { RootState } from "../../app/store";

export const appendVisData = createAction("APPEND_VIS_DATA");
export const replaceLayer = createAction("REPLACE_LAYER");
export const toggleGeocoder = createAction("TOGGLE_GEOCODER");
export const toggleMapControl = createAction("TOGGLE_MAP_CONTROL");
export const hideAndShowSidePanel = createAction("HIDE_AND_SHOW_SIDE_PANEL");
export const removeLayers = createAction("REMOVE_LAYERS");

const _ = require("lodash");

export const selectActiveView = (state: RootState) => {
  return state?.mapViews?.activeView;
};

export const selectActiveViewKey = (state: RootState) => {
  return state?.mapViews?.views?.[`view${state?.mapViews?.activeView}`]?.id;
};

export const selectVisState = (state: RootState) =>
  state?.keplerGl?.[`view${selectActiveViewKey(state)}`]?.visState;

export const selectDatasets = (state: RootState) =>
  selectVisState(state)?.datasets;

export const selectSearchedStores = createSelector(
  selectVisState,
  (visState) => {
    return Array.from(
      visState?.layers
        ?.filter((layer: any) => layer.config.dataId.includes("fetched_stores"))
        ?.flatMap((layer: any) => layer.dataToFeature) || []
    )
      .reduce<Store[]>(
        (acc, feature: any) =>
          acc.find((item) => item?.id === feature?.properties?.id)
            ? acc
            : [...acc, new Store(feature?.properties)],
        []
      )
      .filter((prop) => !!prop);
  }
);

export const selectClickedObject = createSelector(
  selectVisState,
  (visState) => {
    return (visState?.clicked || visState?.customClicked)?.object?.properties;
  }
);

export const selectMyStores = createSelector(selectVisState, (visState) => {
  return Array.from(
    visState?.layers
      ?.filter((layer: any) =>
        layer.config.dataId.includes("fetched_org_stores")
      )
      ?.flatMap((layer: any) => layer.dataToFeature) || []
  )
    .reduce<Store[]>(
      (acc, feature: any) =>
        !feature?.properties ||
        acc.find((item) => item?.id === feature.properties.id)
          ? acc
          : [...acc, new Store(feature?.properties)],
      []
    )
    .filter((prop) => !!prop);
});

export const selectLoadedZipcodes = createSelector(
  selectVisState,
  (visState) => {
    return visState?.layers
      ?.filter((layer: any) =>
        layer.config.dataId.includes("selected_audience")
      )
      ?.flatMap((layer: any) => layer.dataToFeature)
      ?.reduce(
        (acc: any[], feature: any) =>
          acc.find((item: any) => item?.id === feature?.properties?.id)
            ? acc
            : [...acc, feature?.properties],
        []
      )
      .filter((prop: any) => !!prop);
  }
);

export const selectSelectedStates = createSelector(
  selectVisState,
  (visState) => {
    return Array.from(
      new Set(
        visState?.layers
          ?.filter(
            (layer: any) =>
              !!layer.config?.dataId?.includes("selected_audience")
          )
          ?.flatMap((layer: any) => layer.dataToFeature)
          ?.map((f: any) => f.properties?.state)
          ?.filter((s: any) => !!s) || []
      )
    );
  }
);

export const selectSelectedAudiences = createSelector(
  selectVisState,
  (visState) => {
    return Array.from(
      new Set<string>(
        visState?.layers
          ?.filter(
            (layer: any) =>
              !!layer.config?.dataId?.includes("selected_audience")
          )
          ?.flatMap((layer: any) => layer.dataToFeature)
          ?.flatMap((f: any) =>
            Object.keys(f.properties || {})
              .filter((k) => k.startsWith("prob_non"))
              .map((k) => k.split("prob_non_")[1])
          ) || []
      )
    );
  }
);

const keplerReducer = keplerGlReducer
  .initialState({
    uiState: {
      // hide side panel to disallow user customize the map
      readOnly: true,
      currentModal: null,
      mapControls: {
        mapDraw: { show: false, active: false, activeMapIndex: 0 },

        mapLegend: { show: true, active: true, activeMapIndex: 0 },

        mapLocale: { show: false, active: false, activeMapIndex: 0 },

        splitMap: { show: false, active: false, activeMapIndex: 0 },

        toggle3d: { show: false, active: false, activeMapIndex: 0 },

        visibleLayers: { show: true, active: false, activeMapIndex: 0 },
      },
    },
    mapStyle: { styleType: "light" },
  })
  // handle additional actions
  .plugin({
    HIDE_AND_SHOW_SIDE_PANEL: (state: any, action: any) => ({
      ...state,
      uiState: {
        ...state.uiState,
        readOnly: action.payload?.hidden ?? !state.uiState.readOnly,
      },
    }),
    REMOVE_LAYERS: (state: any, action: any) => ({
      ...state,
      visState: {
        ...state.visState,
        removeLayer: action.payload,
      },
    }),
    APPEND_VIS_DATA: (state: any, action: any) => {
      action.payload?.config?.config?.visState?.layers?.forEach(
        (layer: any) => {
          action.payload.config.config.visState.interactionConfig?.tooltip?.fieldsToShow?.[
            layer.config.dataId
          ]?.forEach((field: any) => {
            field.name = joinWords(field.name);
          });
          if (layer.visualChannels?.colorField?.name) {
            layer.visualChannels.colorField.name = joinWords(
              layer.visualChannels.colorField.name
            );
          }

          if (layer.visualChannels?.strokeColorField?.name) {
            layer.visualChannels.strokeColorField.name = joinWords(
              layer.visualChannels.strokeColorField.name
            );
          }
        }
      );
      let newState;
      let error: any;
      try {
        newState = combinedUpdaters.addDataToMapUpdater(state, {
          ...action,
          type: "@@kepler.gl/ADD_DATA_TO_MAP",
        });
      } catch (err) {
        error = err;
      }
      if (newState) {
        const layerIndex = state.visState.layers.findIndex(
          (layer: any) =>
            layer.config.dataId === action.payload.datasets.info?.id
        );
        let layerData = [...state.visState.layerData];
        let layers = [...state.visState.layers];
        if (typeof layerIndex === "number" && layerIndex >= 0) {
          layerData[layerIndex] = {
            ...layerData[layerIndex],
            data: [
              ...layerData[layerIndex].data,
              ...newState.visState.layerData[layerIndex].data,
            ],
          };
          layers[layerIndex].dataToFeature = [
            ...(layers[layerIndex].dataToFeature || []),
            ...newState.visState.layerData[layerIndex].data,
          ];
        } else {
          newState.visState.layers?.forEach((layer: any) => {
            if (layer.config?.dataId && layer.config?.label === "Point") {
              layer.config.label = layer.config.dataId;
            }
          });
          layers = newState.visState.layers;
          layerData = newState.visState.layerData;
        }
        const mergedState = {
          ...state,
          ...newState,
          visState: {
            ...state.visState,
            ...newState.visState,
            layerData: layerData,
            layers: (layers || []).reduce(
              (acc, layer) =>
                acc.find((l: any) => l.config.dataId === layer.config.dataId)
                  ? acc
                  : [...acc, layer],
              []
            ),
            filters: newState.visState.filters?.reduce(
              (acc: any[], filter: any) =>
                acc.find((f) => f.id === filter.id) ? acc : [...acc, filter],
              []
            ),
            datasets: {
              ...state.visState.datasets,
              ...newState.visState.datasets,
            },
            interactionConfig: {
              ...state.visState.interactionConfig,
              ...newState.visState.interactionConfig,
              tooltip: {
                ...state.visState.interactionConfig.tooltip,
                ...newState.visState.interactionConfig.tooltip,
                config: {
                  ...state.visState.interactionConfig.tooltip.config,
                  ...newState.visState.interactionConfig.tooltip.config,
                  fieldsToShow: {
                    ...state.visState.interactionConfig.tooltip.config
                      .fieldsToShow,
                    ...newState.visState.interactionConfig.tooltip.config
                      .fieldsToShow,
                  },
                },
              },
            },
          },
        };

        /* This commented code is to camel case each variable word */

        // Object.values(mergedState.visState?.datasets || {})?.forEach(
        //   (dataset) => {
        //     dataset?.fields?.forEach((field) => {
        //       if (unfilterableFields.includes(field.name)) {
        //         field.type = "geojson";
        //       }
        //       field.name = camelCaseWords(field.name);
        //     });
        //   }
        // );
        // mergedState.visState?.layers?.forEach((layer) => {
        //   mergedState.visState.interactionConfig.tooltip.config.fieldsToShow[
        //     layer.config.dataId
        //   ].forEach((field) => {
        //     field.name = camelCaseWords(field.name);
        //   });
        // });

        const orderedLayers = [...mergedState.visState.layers].sort((a, b) => {
          const aIsPoint =
            a.config.columns?.icon ||
            a.dataToFeature?.[0]?.geometry?.type === "Point";
          const bIsPoint =
            b.config.columns?.icon ||
            b.dataToFeature?.[0]?.geometry?.type === "Point";
          return aIsPoint && !bIsPoint ? -1 : bIsPoint && !aIsPoint ? 1 : 0;
        });
        const visStateWithNewOrder = visStateUpdaters.reorderLayerUpdater(
          mergedState.visState,
          {
            order: orderedLayers.map((layer) => {
              return mergedState.visState.layers.findIndex((item: any) => {
                return item.id === layer.id;
              });
            }),
          }
        );
        mergedState.visState = visStateWithNewOrder;
        return mergedState;
      } else if (error) {
        if (
          error.message.includes(
            "Cannot use 'in' operator to search for 'id' in undefined"
          )
        ) {
          return {
            ...state,
            visState: {
              ...state.visState,
              removeLayer: action.payload.datasets.info?.id,
            },
          };
        }
      }
    },
    REPLACE_LAYER: (state: any, action: any) => {
      const { oldLayer, newLayer } = action.payload;
      const layerIndex = state.visState.layers.findIndex(
        (layer: any) => layer.id === oldLayer?.id
      );
      if (typeof layerIndex === "number" && layerIndex >= 0) {
      }
      const mergedState = _.cloneDeep(state);
      mergedState.visState.layers[layerIndex] = newLayer;
      return mergedState;
    },
    TOGGLE_GEOCODER: (state: any, action: any) => ({
      ...state,
      visState: {
        ...state.visState,
        interactionConfig: {
          ...state.visState.interactionConfig,
          geocoder: {
            ...state.visState.interactionConfig.geocoder,
            enabled: !!!state.visState.interactionConfig.geocoder.enabled,
          },
        },
      },
    }),
    TOGGLE_MAP_CONTROL: (state: any, action: any) => ({
      ...state,
      uiState: {
        ...state.uiState,
        mapControls: {
          ...state.uiState.mapControls,
          [action.payload.control]: {
            ...state.uiState.mapControls[action.payload.control],
            active:
              action.payload.active ??
              !!!state.uiState.mapControls[action.payload.control].active,
          },
        },
      },
    }),
  });

const composedReducer = (state: any, action: any) => {
  const newState = { ...state };
  const newAction = { ...action };
  switch (action.type) {
    case "@@kepler.gl/REMOVE_LAYER":
      newAction.payload = {
        ...newAction.payload,
        layers: state[action.payload.meta._id_].visState.layers,
      };
      const layerRemoved =
        state[action.payload.meta._id_].visState.layers[action.payload.idx];
      const layersOfDataset = state[
        action.payload.meta._id_
      ].visState.layers.filter(
        (layer: any) => layer.config.dataId === layerRemoved.config.dataId
      );
      if (layersOfDataset.length === 1) {
        newState[action.payload.meta._id_].visState.removeLayer =
          layerRemoved.config.dataId;
      }
      break;
    case "@@kepler.gl/LAYER_CLICK":
      const tooltipEnabled =
        newState[action.payload.meta._id_].visState.interactionConfig?.tooltip
          ?.enabled;

      if (
        newAction.payload.info?.object?.icon &&
        newAction.payload.info?.object?.data?.[6]
      ) {
        newAction.payload.info.object.properties = JSON.parse(
          newAction.payload.info?.object?.data?.[6]
        );
      }
      const clickedObject = newAction.payload.info?.object?.properties;
      if (clickedObject && tooltipEnabled) {
        newState[
          action.payload.meta._id_
        ].visState.interactionConfig.tooltip.enabled = 0;
      } else if (!clickedObject && tooltipEnabled === 0) {
        newState[
          action.payload.meta._id_
        ].visState.interactionConfig.tooltip.enabled = true;
      }
      newState[action.payload.meta._id_].visState.customClicked =
        newAction.payload.info;
      break;

    default:
      break;
  }
  return keplerReducer(newState, newAction);
};

export default composedReducer;
