import TagManager from "react-gtm-module";

export const trackClick = (
  id: string,
  elementContent: string,
  action?: () => void
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "snowplowClick",
      clickID: id,
      elementContent: elementContent,
    },
  });
  if (action) action();
};
