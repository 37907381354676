import { mode } from "@chakra-ui/theme-tools";
import type { SystemStyleFunction } from "@chakra-ui/theme-tools";
import { ComponentStyleConfig } from "@chakra-ui/react";

const variantSolid: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  if (c === "gray") {
    const bg = mode(`gray.400`, `whiteAlpha.200`)(props);

    return {
      bg,
      color: mode("blue.900", `gray.800`)(props),
      _hover: {
        bg: mode(`gray.200`, `whiteAlpha.300`)(props),
        _disabled: {
          bg,
        },
      },
      _active: {
        bg: mode(`blue.300`, `whiteAlpha.400`)(props),
        color: mode("white", `gray.800`)(props),
      },
    };
  }

  const {
    bg = `${c}.400`,
    color = "white",
    hoverBg = `${c}.500`,
    activeBg = `${c}.600`,
  } = {};

  const background = mode(bg, `${c}.200`)(props);

  return {
    bg: background,
    color: mode(color, `gray.800`)(props),
    _hover: {
      bg: mode(hoverBg, `${c}.300`)(props),
      _disabled: {
        bg: background,
      },
    },
    _active: { bg: mode(activeBg, `${c}.400`)(props) },
  };
};

const variantLink: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;
  if (c === "gray") {
    return {
      color: mode("blue.900", `gray.800`)(props),
      _active: {
        bg: mode(`lightBlue.400`, `whiteAlpha.400`)(props),
        color: mode("blue.400", `gray.800`)(props),
      },
    };
  }
  return {
    color: mode(`${c}.400`, `${c}.200`)(props),
  };
};
const variants = {
  solid: variantSolid,
  link: variantLink,
};
const buttonTheme: ComponentStyleConfig = {
  defaultProps: { colorScheme: "gray" },
  baseStyle: { borderRadius: 10 },
  variants,
};
export default buttonTheme;
