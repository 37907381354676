import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { ResponsiveModal } from "../responsive-modal";
import EmbeddedReport from "./EmbeddedReport";

const _ = require("lodash");

function EmbeddedReportModal({ isOpen, onClose, url }) {
  return (
    <ResponsiveModal isOpen={isOpen} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={0}>
          <ModalCloseButton onClick={onClose} />

          <EmbeddedReport url={url} />
        </ModalBody>
      </ModalContent>
    </ResponsiveModal>
  );
}

export default EmbeddedReportModal;
