//@flow
import { processCsvData } from "kepler.gl/dist/processors";
import { unparse } from "papaparse";
import {
  AsyncThunkAction,
  createAsyncThunk,
  createSlice,
  unwrapResult,
} from "@reduxjs/toolkit";
import { wrapTo } from "kepler.gl/dist/actions";
import { toast } from "react-toastify";
import {
  getPrediction,
  getStores,
  getWebsiteSales,
  getZipcodesInRadius,
} from "../../services/api.service";
import { createGeoLayer, deleteGeoLayer } from "../layers/layersSlice";
import {
  appendVisData,
  selectLoadedZipcodes,
  selectSearchedStores,
  selectSelectedAudiences,
} from "../map/keplerReducer";
import { removeDataset } from "kepler.gl/dist/actions/vis-state-actions";

const layerConfigFile = require("../../data/layer_config.json");
var pluralize = require("pluralize");

// INITIAL_STATE
export const initialState = {
  isSelecting: false,
  isViewingItemOnMap: false,
  usingRadius: true,
  loadingStoreRank: false,
  loading: {
    stores: false,
    comments: false,
    prediction: false,
    salesExplanation: false,
  },
  comparisonSelection: {},
};

export function defaultStoreLayerConfig(
  info,
  orgLayer,
  orgProperties,
  colorBy,
  colorBySecondary
) {
  const layerConfig = JSON.parse(JSON.stringify(layerConfigFile));
  const storesConfig = JSON.parse(JSON.stringify(layerConfig.storesConfig));
  if (colorBy?.name) {
    storesConfig.config.visState.layers[0].visualChannels.colorField.name =
      colorBy.name;
    storesConfig.config.visState.layers[0].visualChannels.colorField.type =
      colorBy.type;
  }
  if (colorBySecondary?.name) {
    storesConfig.config.visState.layers[0].visualChannels.strokeColorField.name =
      colorBySecondary.name;
    storesConfig.config.visState.layers[0].visualChannels.strokeColorField.type =
      colorBySecondary.type;
  }
  storesConfig.config.visState.interactionConfig.tooltip.fieldsToShow[info.id] =
    storesConfig.config.visState.interactionConfig.tooltip.fieldsToShow.fetched_stores;
  storesConfig.config.visState.layers[0].config.label = info.label;
  storesConfig.config.visState.layers[0].config.dataId = info.id;
  storesConfig.config.visState.layers[0].config.id = info.id;
  storesConfig.config.visState.layers[0].id = info.id;
  storesConfig.config.visState.layers[0].config.visConfig.stroked = false;
  storesConfig.config.visState.layers[0].config.isVisible =
    !orgLayer || !!orgProperties?.properties?.loadOrgStoresOnViewLoad;
  return storesConfig;
}

//$FlowFixMe
export const fetchStores = createAsyncThunk(
  "mapSelection/fetchStoresStatus",
  async (filters, { getState, dispatch }) => {
    const state = getState();
    const {
      orgOnly,
      orgLayer,
      states,
      chains,
      zipcode,
      category,
      store_id,
      search_query,
      mapId,
      config,
      shouldCreateLayer,
      insightParams,
      colorBy,
      colorBySecondary,
    } = filters || {};
    const { accessToken, orgProperties } = state.app;
    const storeLabel =
      orgProperties?.properties?.storeNameReplacement || "Store";
    const { activeView, views } = state.mapViews;
    const { visState } =
      state.keplerGl?.[`view${views?.[`view${activeView}`]?.id}`] || {};
    const selectedStates =
      (orgLayer ? [] : states?.map((s) => s.id)) ||
      Array.from(
        new Set(
          visState?.layers
            ?.filter(
              (layer) => !!layer.config?.dataId?.includes("selected_audience")
            )
            ?.flatMap((layer) => layer.dataToFeature)
            ?.map((f) => f.properties?.state)
            ?.filter((s) => !!s) || []
        )
      );
    const view = views[`view${activeView}`];
    let storesJson = await getStores(
      orgProperties?.properties?.limitedStates || selectedStates,
      chains,
      zipcode,
      category,
      store_id,
      search_query,
      orgOnly || orgLayer,
      accessToken,
      orgProperties?.properties?.country,
      insightParams
    );
    const data = (storesJson || [])
      .map((store, id) => {
        const item = { ...store };
        const _geojson = JSON.stringify({
          geometry: {
            coordinates: [store.longitude, store.latitude],
            type: "Point",
          },
          id,
          properties: store,
          type: "Feature",
        });

        Object.keys(item.insights?.[0] || {}).forEach((key) => {
          if (
            !!item.insights[0][key] &&
            typeof item.insights[0][key] === "object"
          ) {
            Object.keys(item.insights[0][key]).forEach((subKey) => {
              item[subKey] = item.insights[0][key][subKey];
            });
          } else {
            item[key] = item.insights[0][key];
          }
        });
        delete item.latitude;
        delete item.longitude;
        delete item.geometry;
        delete item.geometry_mid;
        delete item.geometry_low;
        delete item.deleted_at;
        Object.keys(item).forEach((key) => {
          if (item[key] === null || item[key] === undefined) {
            delete item[key];
          }
        });
        if (colorBy && !store[colorBy]) {
          const sumAudience = colorBy
            .split("_&_")
            .reduce((acc, audience) => acc + (store[audience] || 0), 0);
          store[colorBy] = sumAudience;
        }
        return {
          ...item,
          is_selected: false,
          _geojson,
        };
      })
      .sort((a, b) => Object.values(b).length - Object.values(a).length);
    const storesCsv = unparse(data);
    if (storesCsv) {
      if (!shouldCreateLayer) {
        const storeLayers = getState().keplerGl[
          `view${views?.[`view${activeView}`]?.id}`
        ]?.visState?.layers?.filter((layer) =>
          layer.config.dataId.includes(
            orgLayer ? "fetched_org_stores" : "fetched_stores"
          )
        );

        dispatch(
          deleteGeoLayer({
            layersToDelete: storeLayers.map((layer) => layer.config.dataId),
          })
        );
      }
      let newId = orgLayer ? "fetched_org_stores" : "fetched_stores";

      newId = newId + new Date().getTime();
      const info = {
        label:
          chains?.length === 1
            ? `${[...new Set(data.map((store) => store.chain))].join(
                " & "
              )} ${pluralize(storeLabel)}`
            : orgLayer
            ? `Organization ${pluralize(storeLabel)}`
            : `${pluralize(storeLabel)}`,
        id: newId,
      };
      const processedGeojson = processCsvData(storesCsv);
      let storesConfig = defaultStoreLayerConfig(
        info,
        orgLayer,
        orgProperties,
        {
          name: colorBy,
          type: processedGeojson.fields.find((f) => f.name === colorBy)?.type,
        },
        {
          name: colorBySecondary,
          type: processedGeojson.fields.find((f) => f.name === colorBySecondary)
            ?.type,
        }
      );
      dispatch(
        wrapTo(
          mapId || `view${view?.id}`,
          appendVisData({
            // datasets
            datasets: {
              info,
              data: processedGeojson,
            },
            // option
            options: {
              keepExistingConfig: true,
            },
            config: config || storesConfig,
          })
        )
      );

      storesConfig.config.visState.layers[0].config.visConfig.stroked = true;
      const wrappedRanking = await dispatch(
        rankStores({
          layerId: "stores",
          stores: data,
          orgLayer: orgLayer,
          config: config || storesConfig,
          layerDataId: info.id,
          colorBy,
          colorBySecondary,
        })
      );
      let unwrappedRanking;
      try {
        unwrappedRanking = await unwrapResult(wrappedRanking);
      } catch (error) {
        console.log(error?.message);
      }
      storesJson = unwrappedRanking || storesJson;

      if (!mapId) {
        dispatch(
          createGeoLayer({
            name: info.label,
            geolayer_details: storesJson.map((item) => ({
              store_id: item.id,
              score_store: item.score,
            })),
            color_by: colorBy,
            config: {
              info,
              columns: (insightParams || []).concat("insights"),
            },
            view_id: view.id,
            layer_type: orgLayer ? "org_stores" : undefined,
          })
        );
      }
    } else if (!orgLayer) {
      toast.info(`No ${pluralize(storeLabel)} found for your filter`);
    }
    return storesJson;
  }
);

//$FlowFixMe
export const fetchSales = createAsyncThunk(
  "mapSelection/fetchSalesStatus",
  async (filters, { getState, dispatch }) => {
    const state = getState();
    const {
      start_date,
      end_date,
      shouldCreateLayer,
      colorBy,
      colorBySecondary,
      config,
    } = filters || {};
    const { accessToken, orgProperties } = state.app;
    const storeLabel =
      orgProperties?.properties?.storeNameReplacement || "Store";
    const { activeView, views } = state.mapViews;
    const view = views[`view${activeView}`];
    let storesJson = await getWebsiteSales(start_date, end_date, accessToken);
    const data = (storesJson || [])
      .map((store, id) => {
        const item = { ...store };

        item.website_source = item.website?.source;
        item.website_name = item.website?.name;
        item.website_type = item.website?.website_type;
        item.website_category = item.website?.category;
        const _geojson = JSON.stringify({
          geometry: {
            coordinates: [store.shipping_longitude, store.shipping_latitude],
            type: "Point",
          },
          id,
          properties: store,
          type: "Feature",
        });

        Object.keys(item).forEach((key) => {
          if (item[key] === null || item[key] === undefined) {
            delete item[key];
          }
        });
        return {
          ...item,
          is_selected: false,
          _geojson,
        };
      })
      .sort((a, b) => Object.values(b).length - Object.values(a).length);
    const storesCsv = unparse(data);
    if (storesCsv) {
      if (!shouldCreateLayer) {
        const storeLayers = getState().keplerGl[
          `view${views?.[`view${activeView}`]?.id}`
        ]?.visState?.layers?.filter((layer) =>
          layer.config.dataId.includes("website_sales")
        );

        dispatch(
          deleteGeoLayer({
            layersToDelete: storeLayers.map((layer) => layer.config.dataId),
          })
        );
      }
      let newId = "website_sales";

      newId = newId + new Date().getTime();
      const info = {
        label: `Website Sales`,
        id: newId,
      };
      const processedGeojson = processCsvData(storesCsv);
      let storesConfig = defaultStoreLayerConfig(
        info,
        false,
        orgProperties,
        {
          name: colorBy,
          type: processedGeojson.fields.find((f) => f.name === colorBy)?.type,
        },
        {
          name: colorBySecondary,
          type: processedGeojson.fields.find((f) => f.name === colorBySecondary)
            ?.type,
        }
      );
      dispatch(
        wrapTo(
          `view${view?.id}`,
          appendVisData({
            // datasets
            datasets: {
              info,
              data: processedGeojson,
            },
            // option
            options: {
              keepExistingConfig: true,
            },
            config: config || storesConfig,
          })
        )
      );

      storesConfig.config.visState.layers[0].config.visConfig.stroked =
        !!colorBySecondary;

      dispatch(
        createGeoLayer({
          name: info.label,
          geolayer_details: data.map((item) => ({
            geolayer_detail_generic: {
              geometry:
                (item._geojson &&
                  (typeof item._geojson === "string"
                    ? JSON.parse(item._geojson)
                    : item._geojson
                  ).geometry) ||
                item.geometry,
              properties: JSON.stringify(item),
            },
          })),
          color_by: colorBy,
          config: {
            info,
          },
          view_id: view.id,
          layer_type: "website_sales",
        })
      );
    } else {
      toast.info(`No ${pluralize(storeLabel)} found for your filter`);
    }
    return storesJson;
  }
);

//$FlowFixMe
export const rankStores = createAsyncThunk(
  "mapSelection/rankStores",
  async (payload, { getState, dispatch }) => {
    const {
      stores,
      zipcodes,
      mapId,
      nationalities,
      orgLayer,
      config,
      layerDataId,
      colorBy,
      colorBySecondary,
    } = payload || {};
    const state = getState();
    const { accessToken, orgProperties, currentUser } = state.app;
    const storeLabel =
      orgProperties?.properties?.storeNameReplacement || "Store";
    const _selectedAudience = selectSelectedAudiences(state);
    const selectedAudience = nationalities || _selectedAudience;
    const currentMap =
      state.keplerGl[
        mapId ||
          `view${
            state?.mapViews?.views?.[`view${state?.mapViews?.activeView}`]?.id
          }`
      ];
    const oldLayer = currentMap?.visState?.layers?.find(
      (layer) => layer.config.dataId === layerDataId
    );

    const storesPrediction = await getPrediction(
      selectedAudience,
      stores?.map((store) => store.id),
      zipcodes,
      orgProperties?.properties?.predictionType,
      orgProperties?.properties?.rankingThreshold,
      accessToken,
      orgProperties?.properties?.zipcodePredictVersion
    );
    if (storesPrediction?.length) {
      const storesCsv = unparse(
        oldLayer.dataToFeature
          ?.map((feature) => {
            const storePrediction = storesPrediction.find(
              (pred) => pred.id === feature?.properties?.id
            );
            const store = {
              ...(storePrediction || {}),
              ...(feature?.properties || {}),
              is_selected: !!storePrediction?.is_selected,
            };
            const _geojson = JSON.stringify({
              ...(feature || {}),
              properties: store,
            });

            Object.keys(store.insights?.[0] || {}).forEach((key) => {
              if (
                !!store.insights[0][key] &&
                typeof store.insights[0][key] === "object"
              ) {
                Object.keys(store.insights[0][key]).forEach((subKey) => {
                  store[subKey] = store.insights[0][key][subKey];
                });
              } else {
                store[key] = store.insights[0][key];
              }
            });
            delete store.latitude;
            delete store.longitude;
            delete store.geometry;
            delete store.geometry_mid;
            delete store.geometry_low;
            delete store.deleted_at;
            Object.keys(store).forEach((key) => {
              if (store[key] === null || store[key] === undefined) {
                delete store[key];
              }
            });
            if (colorBy && !store[colorBy]) {
              const sumAudience = colorBy
                .split("_&_")
                .reduce((acc, audience) => acc + (store[audience] || 0), 0);
              store[colorBy] = sumAudience;
            }
            return {
              ...store,
              _geojson,
            };
          })
          .sort((a, b) => Object.values(b).length - Object.values(a).length)
      );
      if (storesCsv) {
        const processedGeojson = processCsvData(storesCsv);
        const info = {
          label: oldLayer.config.label,
          id: oldLayer.config.dataId,
        };
        let storesConfig = defaultStoreLayerConfig(
          info,
          orgLayer,
          orgProperties,
          {
            name: colorBy,
            type: processedGeojson.fields.find((f) => f.name === colorBy)?.type,
          },
          {
            name: colorBySecondary,
            type: processedGeojson.fields.find(
              (f) => f.name === colorBySecondary
            )?.type,
          }
        );
        storesConfig.config.visState.layers.forEach((layer) => {
          layer.config.visConfig.stroked = true;
        });
        dispatch(
          wrapTo(
            mapId ||
              `view${
                state?.mapViews?.views?.[`view${state?.mapViews?.activeView}`]
                  ?.id
              }`,
            removeDataset(info.id)
          )
        );
        dispatch(
          wrapTo(
            mapId ||
              `view${
                state?.mapViews?.views?.[`view${state?.mapViews?.activeView}`]
                  ?.id
              }`,
            appendVisData({
              // datasets
              datasets: {
                info,
                data: processedGeojson,
              },
              // option
              options: {
                keepExistingConfig: true,
              },
              config: config || storesConfig,
            })
          )
        );
      }
      return stores.map((store) => {
        const storePrediction = storesPrediction.find(
          (pred) => pred.id === store.id
        );
        return {
          ...store,
          ...(storePrediction || {}),
          is_selected: !!storePrediction?.is_selected,
        };
      });
    } else {
      toast.info(`No ${storeLabel} predictions`, { toastId: "stores" });
    }
  },
  {
    condition: (arg, { getState }) => {
      const { orgProperties } = getState().app;
      return !orgProperties?.properties?.preventStoreRanking;
    },
  }
);

//$FlowExpectedError
export const predictSalesStore: AsyncThunkAction = createAsyncThunk(
  "mapSelection/predictSalesStore",
  async (payload, { getState, dispatch }) => {
    const { storeId } = payload || {};
    const state = getState();
    const oldLayer = selectSearchedStores(state);

    const storePrediction = undefined;
    if (storePrediction) {
      const storesCsv = unparse(
        oldLayer.dataToFeature?.map((feature) => {
          const store = {
            ...feature?.properties,
            ...(storeId === feature?.properties?.id ? storePrediction : {}),
          };
          const _geojson = JSON.stringify({
            ...(feature || {}),
            properties: store,
          });
          delete store.latitude;
          delete store.longitude;
          delete store.geometry;
          delete store.geometry_mid;
          delete store.geometry_low;
          delete store.deleted_at;
          Object.keys(store).forEach((key) => {
            if (store[key] === null || store[key] === undefined) {
              delete store[key];
            }
          });
          return {
            ...store,
            _geojson,
          };
        })
      );
      if (storesCsv) {
        const processedGeojson = processCsvData(storesCsv);
        const layerConfig = require("../../data/layer_config.json");
        const info = {
          label: oldLayer.config.label,
          id: oldLayer.config.dataId,
        };
        dispatch(
          wrapTo(
            `view${
              state?.mapViews?.views?.[`view${state?.mapViews?.activeView}`]?.id
            }`,
            removeDataset(info.id)
          )
        );
        dispatch(
          wrapTo(
            `view${
              state?.mapViews?.views?.[`view${state?.mapViews?.activeView}`]?.id
            }`,
            appendVisData({
              // datasets
              datasets: {
                info,
                data: processedGeojson,
              },
              // option
              options: {
                keepExistingConfig: true,
              },
              config: layerConfig.storesConfig,
            })
          )
        );
      }
    }
  }
);

//$FlowFixMe
export const fetchZipcodesInRadius = createAsyncThunk(
  "mapSelection/fetchZipcodesInRadius",
  async (filter, { getState, dispatch }) => {
    const state = getState();
    const { accessToken } = state.app;
    const loadedDemographics = selectLoadedZipcodes(state);

    const latitude = filter.latitude;
    const longitude = filter.longitude;
    const radius_mi = filter.radius_mi;
    const res = await getZipcodesInRadius(
      latitude,
      longitude,
      radius_mi,
      accessToken
    );
    dispatch(
      setMapSelection((oldSelection) => ({
        ...(oldSelection || {}),
        zipcodes: loadedDemographics?.filter((demo) =>
          (res?.zipcodes || []).includes(demo.zipcode)
        ),
      }))
    );
  }
);

// REDUCER
//$FlowFixMe
const mapSelectionSlice = createSlice({
  name: "mapSelection",
  initialState,
  reducers: {
    setIsSelectingOnMap: (state, action) => {
      state.isSelecting = action.payload;
    },
    setComparisonSelection: (state, action) => {
      state.comparisonSelection =
        typeof action.payload === "function"
          ? action.payload(state.comparisonSelection)
          : action.payload;
    },
    setIsViewingItemOnMap: (state, action) => {
      state.isViewingItemOnMap = action.payload;
    },
    setIsUsingRadius: (state, action) => {
      state.usingRadius = action.payload;
    },
    setMapSelection: (state, action) => {
      state.selection =
        typeof action.payload === "function"
          ? action.payload(state.selection)
          : action.payload;
    },
  },
  extraReducers: {
    [fetchStores.pending]: (state, action) => {
      state.loading.stores = true;
    },
    [fetchStores.fulfilled]: (state, action) => {
      state.loading.stores = false;
    },
    [fetchStores.rejected]: (state, action) => {
      state.loading.stores = false;
    },
    [fetchSales.pending]: (state, action) => {
      state.loading.sales = true;
    },
    [fetchSales.fulfilled]: (state, action) => {
      state.loading.sales = false;
    },
    [fetchSales.rejected]: (state, action) => {
      state.loading.sales = false;
    },
    [rankStores.pending]: (state, action) => {
      state.loadingStoreRank = true;
    },
    [rankStores.fulfilled]: (state, action) => {
      state.loadingStoreRank = false;
    },
    [rankStores.rejected]: (state, action) => {
      state.loadingStoreRank = false;
    },
  },
});

export const selectIsSelectingOnMap = (state) => {
  return state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
    ?.isSelecting;
};
export const selectMapSelection = (state) => {
  return state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
    ?.selection;
};
export const selectLoadingStores = (state) =>
  state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
    ?.loading?.stores;
export const selectLoadingSales = (state) =>
  state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
    ?.loading?.sales;

export const {
  setIsSelectingOnMap,
  setComparisonSelection,
  setIsViewingItemOnMap,
  setIsUsingRadius,
  setMapSelection,
} = mapSelectionSlice.actions;

export default mapSelectionSlice.reducer;
