import PageLayout from "../../components/PageLayout";
import OrgIntegrations from "./OrgIntegrations";

function IntegrationsScreen() {
  return (
    <PageLayout title="Integrations">
      <OrgIntegrations />
    </PageLayout>
  );
}
export default IntegrationsScreen;
