import { IconButton } from "@chakra-ui/button";
import { CloseIcon } from "@chakra-ui/icons";
import { Box, HStack, Text } from "@chakra-ui/layout";
import { Spacer, Spinner } from "@chakra-ui/react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  fetchOrgProperties,
  selectAccessToken,
  selectCurrentUser,
  selectIsShopifyApp,
  selectLatestStoreLocatorVersion,
  selectOrgProperties,
  updateOrgProperties,
} from "../../app/appSlice";
import { semverGreaterThan } from "../../app/CacheBuster";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { postGenStorelocator } from "../../services/api.service";
import StoreLocatorCompletedModal from "./StoreLocatorCompletedModal";
import { refreshIframe, toggleConfigCompletedModal } from "./storeLocatorSlice";

const _ = require("lodash");

function StoreLocatorContainer() {
  const navigate = useNavigate();
  let location = useLocation();

  const [isUpdating, setIsUpdating] = useState(false);

  const latestVersion = useAppSelector(selectLatestStoreLocatorVersion);
  const accessToken = useAppSelector(selectAccessToken);
  const orgProperties = useAppSelector(selectOrgProperties);
  const isShopifyApp = useAppSelector(selectIsShopifyApp);
  const currentUser = useAppSelector(selectCurrentUser);

  const storeLocatorCreated = useRef(false);

  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (orgProperties?.id) {
      let params = new URLSearchParams(location.search);
      const paymentSuccess = params.get("paymentsuccess");
      const paymentcanceled = params.get("paymentcanceled");

      if (paymentSuccess || paymentcanceled) {
        toast(
          `Your payment was ${paymentSuccess ? "successful" : "cancelled"}`,
          {
            type: paymentSuccess ? "success" : "warning",
          }
        );
        const queryParams = new URLSearchParams(location.search);

        queryParams.delete("paymentsuccess");
        queryParams.delete("paymentcanceled");
        navigate(location.pathname, {
          replace: true,
          state: {
            search: queryParams.toString(),
          },
        });
      }
      if (paymentSuccess) {
        dispatch(toggleConfigCompletedModal(true));
      } else if (paymentcanceled) {
        const newConfig = _.cloneDeep(orgProperties);
        newConfig.store_locator = null;
        dispatch(updateOrgProperties(newConfig));
      }
    }
  }, [accessToken, dispatch, navigate, location.search, orgProperties]);

  useEffect(() => {
    const createStoreLocator = () => {
      setIsUpdating(true);
      toast.info(
        () => (
          <HStack>
            <Text>Preparing your {storeLabel} Locator</Text>
            <Spinner size="lg" />
          </HStack>
        ),
        {
          autoClose: false,
          position: "bottom-right",
          toastId: "creating_storelocator",
          closeOnClick: false,
          closeButton: false,
        }
      );
      postGenStorelocator(accessToken)
        .then((res) => {
          if (res?.url) {
            toast.success(storeLabel + " Locator is ready");
          }
          return fetchOrgProperties();
        })
        .finally(() => {
          setIsUpdating(false);
          dispatch(refreshIframe());
          toast.dismiss("creating_storelocator");
        });
    };

    if (
      !orgProperties?.properties?.storeLocatorUrl &&
      orgProperties?.store_locator &&
      !storeLocatorCreated.current
    ) {
      storeLocatorCreated.current = true;
      createStoreLocator();
    }
  }, [accessToken, dispatch, orgProperties, storeLabel]);

  useEffect(() => {
    const updateStoreLocator = () => {
      setIsUpdating(true);
      toast.info(
        () => (
          <HStack>
            <Text>Updating {storeLabel} Locator</Text>
            <Spinner size="lg" />
          </HStack>
        ),
        {
          autoClose: false,
          position: "bottom-right",
          toastId: "updating_storelocator",
          closeOnClick: false,
          closeButton: false,
        }
      );
      postGenStorelocator(accessToken, true)
        .then((res) => {
          if (res?.url) {
            toast.success(storeLabel + " Locator Updated");
          }
          return fetchOrgProperties();
        })
        .finally(() => {
          setIsUpdating(false);
          dispatch(refreshIframe());
          toast.dismiss("updating_storelocator");
        });
    };

    if (latestVersion && orgProperties?.properties) {
      const updateAvailable = semverGreaterThan(
        latestVersion,
        orgProperties?.properties?.storeLocatorVersion || ""
      );
      if (
        orgProperties?.properties?.storeLocatorUrl &&
        updateAvailable &&
        !isUpdating
      ) {
        toast.info(
          () => (
            <Box>
              <Text>New {storeLabel} Locator Version</Text>
              <strong>Click to update</strong>
            </Box>
          ),
          {
            autoClose: false,
            onClick: updateStoreLocator,
            position: "bottom-right",
            toastId: "update_storelocator",
            closeOnClick: true,
          }
        );
      } else {
        toast.dismiss("update_storelocator");
      }
    }
  }, [accessToken, dispatch, isUpdating, latestVersion, orgProperties]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Box w={width} h={height}>
          <StoreLocatorCompletedModal />
          <Tabs
            isFitted
            isLazy
            align="end"
            w="100%"
            h="100%"
            display="flex"
            flexDirection="column"
            borderRight={0}
            textAlign="center"
          >
            {!isShopifyApp && location.pathname !== "/storelocator" && (
              <TabList bg="white" pr={3} id="view-tabs">
                <Tab bg="white" as={HStack}>
                  <Spacer />
                  <IconButton
                    className="store-locator-close-btn"
                    id="store-locator-close-btn"
                    ml="auto"
                    height={18}
                    bg="transparent"
                    icon={<CloseIcon />}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/storelocator");
                    }}
                  />
                </Tab>
              </TabList>
            )}

            <TabPanels
              display="flex"
              flexDir="row"
              flex={1}
              height="95%"
              overflow="auto"
            >
              <TabPanel flex={1} p={0} pos="relative">
                <Box pos="absolute" top={0} right={0} bottom={0} left={0}>
                  <Outlet />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      )}
    </AutoSizer>
  );
}
export default StoreLocatorContainer;
