import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Portal,
  Text,
  VStack,
} from "@chakra-ui/react";
import pluralize from "pluralize";
import { useEffect } from "react";
import {
  addOpenUploadProxy,
  addStatusMessage,
  selectAccessToken,
  selectOrgProperties,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  getStoreLocatorBckwRecommendationsSummary,
  getStoreLocatorRecommendationsSummary,
} from "../../services/api.service";
import { PopoverTrigger } from "../org-screen/OrgOverview";

const DathicAutopilotPopover = () => (
  <Popover trigger="hover">
    <PopoverTrigger>
      <Button variant="link" color="white" textDecor="underline">
        Dathic Copilot
      </Button>
    </PopoverTrigger>
    <Portal>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          Auto-populate your store locator in a few clicks scanning your brand
          in over 50k stores.
        </PopoverBody>
      </PopoverContent>
    </Portal>
  </Popover>
);

export const useRecommendationAlerts = () => {
  const accessToken = useAppSelector(selectAccessToken);
  const orgProperties = useAppSelector(selectOrgProperties);
  const dispatch = useAppDispatch();

  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";

  useEffect(() => {
    const checkRecommendationSummary = async () => {
      const newStoresSummary = await getStoreLocatorRecommendationsSummary(
        accessToken
      );
      if (newStoresSummary?.new_stores) {
        dispatch(
          addStatusMessage({
            id: "recommendedstores",
            title: (
              <VStack>
                <Text>
                  New recommendations from <DathicAutopilotPopover />
                </Text>
                <Text fontWeight="extrabold" fontSize="18">
                  🎉 {newStoresSummary.new_stores} new{" "}
                  {pluralize(storeLabel, newStoresSummary.new_stores)}
                </Text>
              </VStack>
            ),
            action: {
              label: "Approve",
              callback: () =>
                dispatch(
                  addOpenUploadProxy({
                    name: "StoreRecommendationsProxy",
                    props: { type: "new" },
                  })
                ),
            },
          })
        );
      }
    };
    const checkDiscontinuedSummary = async () => {
      const discontinuedStoresSummary =
        await getStoreLocatorBckwRecommendationsSummary(accessToken);
      if (discontinuedStoresSummary?.stop_selling_stores) {
        dispatch(
          addStatusMessage({
            id: "discontinuedstores",
            title: (
              <Text>
                Your brand stopped selling in{" "}
                {discontinuedStoresSummary.stop_selling_stores}{" "}
                {pluralize(storeLabel)}. Found by <DathicAutopilotPopover />
              </Text>
            ),
            action: {
              label: "Approve",
              callback: () =>
                dispatch(
                  addOpenUploadProxy({
                    name: "StoreDiscontinuedProxy",
                    props: { type: "discontinued" },
                  })
                ),
            },
          })
        );
      }
    };

    if (!orgProperties?.properties?.hideStoreRecommendations) {
      checkRecommendationSummary();
    }
    if (orgProperties?.properties?.showDiscontinueRecommendations) {
      checkDiscontinuedSummary();
    }
  }, [accessToken, dispatch, orgProperties, storeLabel]);
};
