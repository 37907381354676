import {
  Button,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { IoInformationCircle } from "react-icons/io5";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useAppSelector } from "../../app/store";
import { addOpenTour, selectOrgProperties } from "../../app/appSlice";
import { RESOURCES } from "../../constants/user-constants";

function HelpBtn({ openTour, userResources }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { properties: orgConfig } = useAppSelector(selectOrgProperties) || {};

  const storeLabel = orgConfig?.storeNameReplacement || "Store";
  return (
    <div data-tour="tours-btn-icon">
      <Menu>
        <MenuButton as={"div"} _hover={{ textDecoration: "underline" }}>
          <Button
            id="tours-btn"
            className="tours-btn"
            variant="ghost"
            leftIcon={
              <IoInformationCircle
                size="1.5rem"
                id="tours-btn"
                className="tours-btn"
              />
            }
            iconSpacing={["0.5rem", null, 0]}
          >
            <Text display={["block", null, "none"]}>Tutorials</Text>
          </Button>
        </MenuButton>
        <MenuList>
          <MenuGroup>
            {!location.pathname?.includes("storelocator") && (
              <MenuItem
                onClick={() => {
                  navigate("/", { replace: true });
                  openTour("homeTour");
                }}
              >
                Home Tour
              </MenuItem>
            )}
            {!!userResources?.includes(RESOURCES.STORE_LOCATOR_BTN) && (
              <MenuItem
                onClick={() => {
                  if (location.pathname !== "/storelocator") {
                    window.location.href = "/storelocator";
                  }
                  openTour("storelocatorTour");
                }}
              >
                {storeLabel} Locator Tour
              </MenuItem>
            )}
          </MenuGroup>
        </MenuList>
      </Menu>
    </div>
  );
}

export default connect(
  (state) => ({
    userResources: state.app.currentUser?.resources,
  }),
  (dispatch) => ({
    openTour: (tourName) => dispatch(addOpenTour(tourName)),
  })
)(HelpBtn);
