import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  Box,
  Button,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Img,
  List,
  ListItem,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import {
  CloseIcon,
  PlusSquareIcon,
  ViewIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import { IoClose, IoPeople } from "react-icons/io5";
import {
  changeActiveView,
  fetchView,
  setSideView,
  setViews,
  updateView,
} from "./mapViewsSlice";
import {
  hideAndShowSidePanel,
  selectVisState,
  toggleMapControl,
} from "../map/keplerReducer";
import Map from "../map/Map";
import ViewSettingsModal from "./ViewSettingsModal";
import { RESOURCES } from "../../constants/user-constants";
import FacebookCampaignCreation from "../data-integrations/facebook/facebook-campaign-creation";
import { useLocation } from "react-router-dom";
import FavoritesBtn from "./FavoritesBtn";
import { getViews, putView } from "../../services/api.service";
import MapItemPanel from "../../components/mapItemPanel/MapItemPanel";
import MessageModalContext from "../../contexts/MessageModalContext";
import ComparisonCard from "./Favorites/ComparisonCard/ComparisonCard";
import DathicChat from "../dathic-chat/DathicChat";
import menuIconSettings from "../../assets/images/menu-icon-settings.png";

const _ = require("lodash");

const MapViewTabs = (props) => {
  const {
    activeView,
    setActiveView,
    views,
    appIsReadOnly,
    viewStatus,
    activeViewStatus,
    requestViewUpdate,
    loadView,
    viewAccess,
    activeSideView,
    setSideView,
    currentUser,
    hideSidePanel,
    userResources,
    toggleLegend,
    orgProperties,
    changeViewsList,
    accessToken,
    clicked,
    isSelectingOnMap,
    loadingLayers,
    comparisonSelection,
  } = props;
  const shareModalDisclosure = useDisclosure();
  const [isCreatingView, setIsCreatingView] = useState(false);
  const [hoveringTab, setHoveringTab] = useState();
  const [userClosedLoadingMessage, setUserClosedLoadingMessage] =
    useState(false);
  const [isMobile] = useMediaQuery("(max-width: 765px)");
  let location = useLocation();
  const loadViewPromise = useRef();
  const modalsContext = useContext(MessageModalContext);

  useEffect(() => {
    requestViewUpdate();
  }, [requestViewUpdate, views]);

  useEffect(() => {
    if (activeViewStatus?.fetching === "idle" && location.pathname === "/") {
      if (loadViewPromise.current) {
        loadViewPromise.current.abort();
      }
      loadViewPromise.current = loadView(activeView);
      loadViewPromise.current.then(() => {
        loadViewPromise.current = undefined;
      });
    }
  }, [activeView, activeViewStatus, loadView, location.pathname]);

  useEffect(() => {
    const windowIsSmall = window.innerWidth < 620;
    if (
      Object.values(views || {})?.find(
        (view) => !view?.config?.closed && view?.id
      )
    ) {
      if (!!activeSideView?.name) {
        hideSidePanel(true);
        toggleLegend(false);
      } else {
        hideSidePanel(!!orgProperties?.hideLayerPanel || windowIsSmall);
        toggleLegend(!orgProperties?.hideLegend && true);
      }
    }
  }, [
    activeSideView,
    hideSidePanel,
    orgProperties,
    toggleLegend,
    userResources,
    views,
  ]);

  const handleTabChange = (selectedTab) => {
    setActiveView(selectedTab);
  };

  const openOrCloseView = async (action, view, index) => {
    getViews(view.id, accessToken).then((upToDateView) => {
      return putView(
        {
          id: upToDateView.id,
          name: upToDateView.name,
          description: upToDateView.description,
          config: {
            ...(upToDateView.config || {}),
            closed: action === "close",
          },
        },
        accessToken
      );
    });
    changeViewsList((oldViews) => {
      const newList = { ...oldViews };
      const updatedView = _.merge(newList[`view${index}`], {
        config: { closed: action === "close" },
      });
      newList[`view${index}`] = updatedView;
      return newList;
    });
  };

  return (
    <AutoSizer
      style={{
        visibility: ["/", "/index.html"].includes(location.pathname)
          ? "visible"
          : "hidden",
      }}
    >
      {({ height, width }) => (
        <Tabs
          isFitted
          isLazy
          align="end"
          index={activeView}
          w={width}
          h={height}
          display="flex"
          flexDirection="column"
        >
          {!appIsReadOnly && (
            <TabList
              bg="white"
              pr={3}
              id="view-tabs"
              onMouseLeave={() => setHoveringTab()}
            >
              {views &&
                Object.values(views).map((view, index) =>
                  view.config?.closed ? (
                    <Tab flex={0} p={0}></Tab>
                  ) : (
                    <Tab
                      id="tab-button"
                      position="relative"
                      boxShadow="lg"
                      bg={activeView === index ? "white" : "#EDF2F7"}
                      onMouseEnter={() => setHoveringTab(index)}
                      onClick={(e) => {
                        if (
                          e.target.id.includes("-tab-") ||
                          e.target.id.includes("tab_name")
                        ) {
                          e.preventDefault();
                          handleTabChange(index);
                        }
                      }}
                    >
                      <IconButton
                        className="view-close-btn"
                        id="view-close-btn"
                        visibility={
                          hoveringTab === index ? "visible" : "hidden"
                        }
                        height={18}
                        bg="transparent"
                        icon={<CloseIcon />}
                        onClick={(e) => {
                          e.preventDefault();
                          openOrCloseView("close", view, index);
                        }}
                      />
                      {Object.values(viewStatus[index] || {}).includes(
                        "loading"
                      ) && <Spinner />}
                      {Object.values(viewStatus[index] || {}).find(
                        (status) => typeof status === "object"
                      ) && (
                        <Tooltip
                          hasArrow
                          label={
                            Object.values(viewStatus[index] || {}).find(
                              (status) => typeof status === "object"
                            ).error
                          }
                          bg="gray.300"
                          color="black"
                        >
                          <WarningIcon />
                        </Tooltip>
                      )}
                      {view.type === "shared" && <Icon as={IoPeople} />}
                      <Text
                        id={"tab_name-" + (view.name || `View ${index + 1}`)}
                        ml={3}
                      >
                        {view.name || `View ${index + 1}`}
                      </Text>
                      {viewAccess[index] === "owner" &&
                        activeView === index && (
                          <IconButton
                            className="view-settings-btn"
                            id="view-settings-btn"
                            height={18}
                            bg="transparent"
                            icon={
                              <Img
                                src={menuIconSettings}
                                height="1rem"
                                width="1rem"
                                id="view-settings-btn"
                                className="view-settings-btn"
                              />
                            }
                            onClick={shareModalDisclosure.onOpen}
                            isDisabled={
                              !currentUser.resources?.includes(
                                RESOURCES.VIEW_UPDATE
                              )
                            }
                          />
                        )}
                      {viewAccess[index] === "read" && (
                        <Tooltip
                          hasArrow
                          label="You currently have read access. Your changes will not be saved"
                          bg="gray.300"
                          color="black"
                        >
                          <ViewIcon ml={3} />
                        </Tooltip>
                      )}
                      <FavoritesBtn
                        activeView={index}
                        containerProps={{ marginLeft: "auto" }}
                      />
                    </Tab>
                  )
                )}
            </TabList>
          )}

          <TabPanels display="flex" flexDir="row" flex={1} height="95%">
            {Object.values(views).map((view, index) =>
              view.config?.closed ? (
                <></>
              ) : (
                <TabPanel flex={1} p={0} w="100%">
                  <Stack h="100%" direction={["column", null, "row"]}>
                    {!clicked &&
                      Object.keys(comparisonSelection || {}).map((key) =>
                        comparisonSelection[key]?.length > 1 ? (
                          <ComparisonCard key={key} accessToken={accessToken} />
                        ) : (
                          <></>
                        )
                      )}
                    {["zipcode", "state"].every((k) => !!clicked?.[k]) &&
                      !isSelectingOnMap &&
                      !isMobile &&
                      userResources?.includes(RESOURCES.VIEW_MAP_MODAL) && (
                        <MapItemPanel />
                      )}
                    <Box h="100%" flex={1}>
                      <Map id={view.id} />
                    </Box>
                    {["zipcode", "state"].every((k) => !!clicked?.[k]) &&
                      !isSelectingOnMap &&
                      isMobile &&
                      userResources?.includes(RESOURCES.VIEW_MAP_MODAL) && (
                        <MapItemPanel />
                      )}
                  </Stack>
                </TabPanel>
              )
            )}
            {!Object.values(views).filter((view) => !view.config?.closed)
              .length && (
              <Flex
                flex={1}
                alignItems="center"
                flexDir="column"
                justifyContent="center"
              >
                {!Object.values(views).filter((view) => view.config?.closed)
                  .length && (
                  <Button
                    size="lg"
                    rightIcon={<PlusSquareIcon />}
                    onClick={() => {
                      setIsCreatingView(true);
                      shareModalDisclosure.onOpen();
                    }}
                    isDisabled={
                      !currentUser.resources?.includes(RESOURCES.VIEW_CREATE)
                    }
                  >
                    Create new map
                  </Button>
                )}
                {!!Object.values(views).filter((view) => view.config?.closed)
                  .length && (
                  <VStack p={5} bg="white" borderRadius={10}>
                    <Text fontWeight="Bold" fontSize={18} mb={3}>
                      Closed Maps
                    </Text>
                    <List spacing={3} maxH={400} overflowY="auto">
                      {Object.keys(views)
                        .filter((viewKey) => !!views[viewKey].config?.closed)
                        .map((viewKey) => (
                          <ListItem
                            position="relative"
                            bg="whitesmoke"
                            textAlign="start"
                            borderRadius={10}
                            p={3}
                            pr={20}
                          >
                            <Text maxW={300} fontWeight="bold">
                              {views[viewKey].name}
                            </Text>
                            <Text maxW={300}>{views[viewKey].description}</Text>
                            <Button
                              position="absolute"
                              right={5}
                              bottom={3}
                              colorScheme="blue"
                              onClick={() =>
                                openOrCloseView(
                                  "open",
                                  views[viewKey],
                                  +viewKey.split("view")[1]
                                )
                              }
                            >
                              Open
                            </Button>
                          </ListItem>
                        ))}
                    </List>
                  </VStack>
                )}
              </Flex>
            )}
            <Collapse
              in={!!activeSideView?.name}
              style={{
                width: 800,
                height: "100%",
                overflowY: "auto",
                padding: "1rem",
              }}
            >
              <Box
                h="100%"
                bg="white"
                p={10}
                textAlign="start"
                position="relative"
                overflowY="auto"
              >
                <IconButton
                  icon={<IoClose />}
                  onClick={() => setSideView({}, activeView)}
                  position="absolute"
                  top={5}
                  right={5}
                />
                {activeSideView?.name === "fbCampaign" && (
                  <FacebookCampaignCreation
                    {...(activeSideView.props || {})}
                    onClose={() => setSideView({}, activeView)}
                  />
                )}
                {activeSideView?.name === "dathicChat" && (
                  <DathicChat
                    {...(activeSideView.props || {})}
                    onClose={() => setSideView({}, activeView)}
                  />
                )}
              </Box>
            </Collapse>
          </TabPanels>
          <ViewSettingsModal
            isOpen={shareModalDisclosure.isOpen}
            onClose={() => {
              setIsCreatingView(false);
              shareModalDisclosure.onClose();
            }}
            isCreatingView={isCreatingView}
          />
        </Tabs>
      )}
    </AutoSizer>
  );
};

export default connect(
  (state) => {
    const clicked =
      selectVisState(state)?.clicked || selectVisState(state)?.customClicked;
    return {
      currentUser: state.app.currentUser,
      appIsReadOnly: state.app.readOnly,
      activeView: state.mapViews.activeView,
      views: state.mapViews.views,
      viewStatus: state.mapViews.viewStatus,
      activeSideView: state.mapViews.sideView[state.mapViews.activeView],
      activeViewStatus: state.mapViews.viewStatus[state.mapViews.activeView],
      viewAccess: state.mapViews.viewAccess,
      keplerMaps: state.keplerGl,
      userResources: state.app.currentUser.resources,
      orgProperties: state.app.orgProperties?.properties,
      isSelectingOnMap:
        state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
          ?.isSelecting,
      accessToken: state.app.accessToken,
      clicked: clicked?.object?.properties,
      loadingLayers: state.layers.loadingLayers,
      comparisonSelection:
        state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
          ?.comparisonSelection,
    };
  },
  (dispatch) => ({
    setActiveView: (newView) => dispatch(changeActiveView(newView)),
    requestViewUpdate: (view) => dispatch(updateView(view)),
    loadView: (viewKey) => dispatch(fetchView(viewKey)),
    changeViewsList: (newView) => dispatch(setViews(newView)),
    hideSidePanel: (hidden) => dispatch(hideAndShowSidePanel({ hidden })),
    toggleLegend: (active) =>
      dispatch(toggleMapControl({ control: "mapLegend", active })),
    setSideView: (sideView, viewKey) =>
      dispatch(
        setSideView({
          viewKey,
          sideView,
        })
      ),
  })
)(MapViewTabs);
