import { startCase } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import PieChart from "../../charts/pie-chart";
import { connect } from "react-redux";
import {
  selectLoadedZipcodes,
  selectSearchedStores,
  selectSelectedAudiences,
  selectVisState,
} from "../../../features/map/keplerReducer";
import { getStores } from "../../../services/api.service";
import PopularStores from "./PopularStores";
import Slider from "./Slider";
import { ArrowBackIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import ZipStoreCard from "./ZipStore/ZipStoreCard";
import EmbeddedReportModal from "../../embed/EmbeddedReportModal";
import { RESOURCES } from "../../../constants/user-constants";
import { useAppSelector } from "../../../app/store";
import { Store } from "../../../domain/Store";

var pluralize = require("pluralize");

function createPieJSON(
  clickedObject,
  selectedAudiences,
  extraData,
  audienceVariables,
  country = "US"
) {
  if (clickedObject) {
    const selectedColors = [
      "#92DCBC",
      "#5FC197",
      "#3CA87A",
      "#229966",
      "#0F7C4D",
    ];
    const selectedItems = selectedAudiences?.find(
      (item) => item === "hispanic_or_latino"
    )
      ? Object.keys(extraData).filter(
          (data) =>
            audienceVariables.includes(data) &&
            !["total", "hispanic_or_latino", "not_hispanic_or_latino"].includes(
              data
            )
        )
      : selectedAudiences;
    const selectedNationalities = selectedItems?.map((nationality, index) => ({
      name: startCase(nationality),
      value: clickedObject[nationality] || extraData[nationality],
      hex: selectedColors[index % 5],
    }));

    return country === "US"
      ? {
          children: [
            {
              name: "Not Latinos",
              hex: "#F68457",
              value: clickedObject.total - clickedObject.hispanic_or_latino,
            },
            {
              name: "Latinos",
              children: selectedNationalities
                ? [
                    ...selectedNationalities,
                    {
                      name: "Other Latinos",
                      hex: "#436aa2",
                      value:
                        clickedObject.hispanic_or_latino -
                        selectedNationalities.reduce(
                          (numLatinx, nationality) => {
                            return numLatinx + nationality.value;
                          },
                          0
                        ),
                    },
                  ]
                : [
                    {
                      name: "Other Latinos",
                      hex: "#436aa2",
                      value: clickedObject.hispanic_or_latino,
                    },
                  ],
            },
          ],
        }
      : {
          children: [
            {
              name: "Other audiences",
              hex: "#F68457",
              value:
                clickedObject.total -
                selectedNationalities.reduce((numLatinx, nationality) => {
                  return numLatinx + nationality.value;
                }, 0),
            },
            {
              name: "Selection",
              children: selectedNationalities,
            },
          ],
        };
  }
  return null;
}

const ZipDemographics = (props) => {
  const {
    loadedStores,
    loadedDemographics,
    extraData,
    accessToken,
    audienceVariables,
    orgProperties,
    selectedExplanation,
    notSelectedExplanation,
    zipcodeReportUrl,
    userResources,
  } = props;
  let { clickedObject } = props;
  const selectedAudiences = useAppSelector(selectSelectedAudiences);
  const [pieData, setPieData] = useState();
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [zipStores, setZipStores] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedViewStore, setSelectedViewStore] = useState({});
  const zipcodeReportDisclosure = useDisclosure();
  const memoizedZipcode = useMemo(() => {
    const { zipcode } = clickedObject;
    return zipcode;
  }, [clickedObject]);
  const memoizedState = useMemo(() => {
    const { state } = clickedObject;
    return state;
  }, [clickedObject]);
  const storeLabel = orgProperties?.storeNameReplacement || "Store";

  useEffect(() => {
    async function getStoresData() {
      setIsLoading(true);
      const stores = await getStores(
        [memoizedState],
        [],
        [memoizedZipcode],
        [],
        [],
        undefined,
        false,
        accessToken,
        orgProperties?.country
      );
      setZipStores(stores);
      setIsLoading(false);
    }
    getStoresData();
  }, [accessToken, memoizedZipcode, memoizedState, orgProperties]);

  useEffect(() => {
    setSelectedViewStore({});
  }, [clickedObject]);

  if (loadedDemographics && clickedObject && !clickedObject.total) {
    const foundZip = loadedDemographics?.find(
      (item) => item.zipcode === clickedObject.zipcode
    );
    clickedObject = {
      ...foundZip,
      ...clickedObject,
    };
  }

  const mapObjectForTable = () => {
    const demographic = clickedObject;
    let population_by_age;
    let population_by_race;
    let educational_attainment;
    let transportation;
    let travelTime;
    try {
      population_by_age = demographic.population_by_age;
      population_by_race = demographic.population_by_race;
      educational_attainment =
        demographic.educational_attainment_for_population_25_and_over;
      transportation =
        demographic.means_of_transportation_to_work_for_workers_16_and_over;
      travelTime = demographic.travel_time_to_work_in_minutes;
    } catch (error) {
      console.log(error);
    }
    let ageGroups =
      (population_by_age || []).find((item) => item.key === "Total")?.values ||
      [];
    let latinoRaceGroups = Object.keys(extraData)
      .filter(
        (data) =>
          audienceVariables.includes(data) &&
          !["total", "hispanic_or_latino"].includes(data)
      )
      .map((key) => ({ x: startCase(key), y: extraData[key] }));
    let raceGroups = (population_by_race || [])[0]?.values || [];
    let educationGroups = (educational_attainment || [])[0]?.values || [];
    let transportationGroups = (transportation || [])[0]?.values || [];
    let travelTimeGroups = (travelTime || [])[0]?.values || [];

    const engagementAudience = selectedAudiences?.reduce(
      (nationalities, nationality) => {
        const prob = demographic[`prob_${nationality}`];
        const nationalityTweets = demographic.weekly_tweets * prob;
        return {
          ...nationalities,
          [nationality]: nationalityTweets || 0,
        };
      },
      {}
    );
    return {
      age: ageGroups || [],
      latino_race: latinoRaceGroups || [],
      race: raceGroups || [],
      income: demographic.median_household_income,
      engagement: [
        {
          y:
            (demographic.weekly_tweets || 0) -
            ((engagementAudience &&
              Object.keys(engagementAudience).reduce(
                (otherEngagement, key) =>
                  otherEngagement + engagementAudience[key],
                0
              )) ||
              0),
          x: "Other",
        },
        ...(engagementAudience
          ? Object.keys(engagementAudience).map((key) => ({
              y: engagementAudience[key],
              x: key,
            }))
          : []),
      ],
      housing_units: [
        {
          x: "Occupied",
          y: demographic.occupied_housing_units,
        },
        {
          x: "Not Occupied",
          y:
            (demographic.housing_units || 0) -
            (demographic.occupied_housing_units || 0),
        },
      ],
      avg_rent: Object.keys(demographic).reduce((demoRent, key) => {
        if (key.startsWith("monthly_rent")) {
          let monthly_rent;
          try {
            monthly_rent = demographic[key];
            let rentGroups = (monthly_rent || [])[0]?.values || [];
            return rentGroups.map((rentGroup) => {
              const group = demoRent.find((v) => v.x === rentGroup.x);
              return {
                x: rentGroup.x,
                y: rentGroup.y + (group?.y ?? 0),
              };
            });
          } catch (error) {}
        }
        return demoRent;
      }, []),
      education: educationGroups || [],
      num_stores: loadedStores?.reduce((numStores, store) => {
        if (store.zipcode === demographic.zipcode) return numStores + 1;
        return numStores;
      }, 0),
      transportation: transportationGroups || [],
      travel_time: travelTimeGroups || [],
      num_audience: selectedAudiences?.reduce(
        (num_audience, nationality) => demographic[nationality] + num_audience,
        0
      ),
      ...(selectedAudiences?.reduce(
        (audiences, nationality) => ({
          ...audiences,
          [nationality]:
            demographic[nationality] + (audiences[nationality] ?? 0),
        }),
        {}
      ) || {}),
      zipcode: demographic.zipcode,
    };
  };

  let objectForTable;
  if (clickedObject) {
    objectForTable = mapObjectForTable();
  }

  return (
    <Flex w="100%" alignItems="center" direction="column" pos="relative">
      {!!Object.keys(selectedViewStore).length && (
        <IconButton
          icon={<ArrowBackIcon />}
          pos="absolute"
          top={3}
          left={3}
          onClick={() => setSelectedViewStore({})}
        />
      )}
      {Object.keys(selectedViewStore).length ? (
        <ZipStoreCard
          isLoading={isLoading}
          zipStores={zipStores}
          clickedObject={selectedViewStore}
        />
      ) : clickedObject.store_id || clickedObject.address ? (
        <ZipStoreCard
          isLoading={isLoading}
          zipStores={zipStores}
          clickedObject={new Store(clickedObject)}
        />
      ) : (
        <Flex direction="column" alignItems="center" w="100%">
          <Flex m={"3"} justify="center">
            {!!zipcodeReportUrl && (
              <Button
                className="zipcode-report-btn"
                id="zipcode-report-btn"
                variant="outline"
                colorScheme="blue"
                w={300}
                isDisabled={!userResources?.includes(RESOURCES.REPORT_ZIPCODE)}
                onClick={zipcodeReportDisclosure.onOpen}
                zIndex={2}
              >
                View Report
              </Button>
            )}
            <EmbeddedReportModal
              isOpen={zipcodeReportDisclosure.isOpen}
              onClose={zipcodeReportDisclosure.onClose}
              url={zipcodeReportUrl}
              urlParams={{
                zipcode: clickedObject?.zipcode || clickedObject?.["Zip Code"],
              }}
            />
          </Flex>
          <Flex>
            <PieChart
              width={250}
              createData={() =>
                createPieJSON(
                  clickedObject,
                  selectedAudiences,
                  extraData,
                  audienceVariables,
                  orgProperties?.country
                )
              }
              data={pieData}
              initialFinalValue={{
                finalPath: "Total Population",
                value: clickedObject?.total,
              }}
              onDataChange={setPieData}
              shouldRefresh={shouldRefresh}
              onRefresh={() => setShouldRefresh(false)}
            />
            <Flex
              fontWeight="semibold"
              justify="space-evenly"
              direction="column"
            >
              {clickedObject.median_household_income && (
                <Flex align="flex-start" direction="column">
                  <Text>Avg Income</Text>
                  <Text>
                    {`$${clickedObject?.median_household_income?.toLocaleString()}`}
                  </Text>
                </Flex>
              )}
              <Flex align="flex-start" direction="column">
                <Text>
                  Number of{" "}
                  {selectedAudiences?.map((a) => startCase(a))?.join(" & ")}
                </Text>
                <Text>{objectForTable?.num_audience?.toLocaleString()}</Text>
              </Flex>
              <Flex alignItems="flex-start" direction="column">
                <Text textAlign="left">Number of Retailers</Text>
                <Text textAlign="left">
                  {zipStores?.length}
                  <Button disabled m="1" colorScheme="blue" variant="outline">
                    See {pluralize(storeLabel)}
                  </Button>
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Slider
            min={0}
            max={100000}
            bg={"gray.100"}
            thumbToolTipLabel={`$${clickedObject?.median_household_income?.toLocaleString()}`}
            leftSliderMarkLabel={"0"}
            rightSliderMarkLabel={">$100k"}
            leftSliderMark={0}
            rightSliderMark={100000}
            clickedObject={clickedObject}
            width={"85%"}
            label="Average Zipcode Income"
            sliderValue={clickedObject?.median_household_income}
            CustomToolTip={
              <Tooltip
                hasArrow
                textAlign="center"
                label="Showing the zipcode average income"
                aria-label="tooltip-ex-1"
                placement="right"
              >
                <InfoOutlineIcon />
              </Tooltip>
            }
          />
          <PopularStores
            setSelectedViewStore={setSelectedViewStore}
            isLoading={isLoading}
            zipStores={zipStores}
          />
        </Flex>
      )}
      <Divider mt={2} />
      {!orgProperties?.hideRankingInModal && (
        <>
          {typeof clickedObject?.is_selected === "boolean" && (
            <Stat>
              <StatLabel>Recommended</StatLabel>
              <StatNumber>
                {clickedObject.is_selected ? "Yes" : "No"}
              </StatNumber>
            </Stat>
          )}

          {!!clickedObject?.score && (
            <Slider
              min={0}
              max={1000}
              leftSliderMarkLabel={"0"}
              rightSliderMarkLabel={"1000"}
              leftSliderMark={"0"}
              rightSliderMark={"1000"}
              width={"85%"}
              label={`Dathic Recommendation ${(
                (clickedObject?.score || 0) * 1000
              ).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}/1000`}
              sliderValue={(clickedObject?.score || 0) * 1000}
            />
          )}

          {!!clickedObject?.predicted_avg_units && (
            <Stat>
              <StatLabel>Pred Units / Week</StatLabel>
              <StatNumber>
                {clickedObject.predicted_avg_units.toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })}
              </StatNumber>
            </Stat>
          )}
          <Box flex={0} mt={5} mb={5}>
            {!!clickedObject?.is_selected && (
              <Box fontSize="sm">{selectedExplanation}</Box>
            )}
            {clickedObject?.is_selected === false && (
              <Box fontSize="sm">{notSelectedExplanation}</Box>
            )}
          </Box>
        </>
      )}
    </Flex>
  );
};

export default connect((state) => ({
  clickedObject: (
    selectVisState(state)?.clicked || selectVisState(state)?.customClicked
  )?.object?.properties,
  loadedDemographics: selectLoadedZipcodes(state),
  selectedExplanation: state.app.orgProperties?.properties?.selectedExplanation,
  notSelectedExplanation:
    state.app.orgProperties?.properties?.notSelectedExplanation,
  loadedStores: selectSearchedStores(state),
  orgProperties: state.app.orgProperties?.properties,
  audienceVariables:
    state.app.properties?.find((properties) => properties.name === "audiences")
      ?.value || [],
  zipcodeReportUrl: state?.app?.orgProperties?.properties?.zipcodeReportUrl,
  userResources: state.app.currentUser?.resources,
}))(ZipDemographics);
