import { StarIcon } from "@chakra-ui/icons";
import { Box, Button, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { connect } from "react-redux";
import FavoritesModal from "./Favorites/FavoritesModal";

const FavoritesBtn = ({ favorites, containerProps = {} }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Box {...containerProps}>
      {!!favorites?.length && (
        <Button
          id="favorites-btn"
          variant="link"
          leftIcon={<StarIcon />}
          size="xs"
          onClick={onOpen}
        >
          {`{${favorites?.length}}`}
        </Button>
      )}
      <FavoritesModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default connect((state, props) => ({
  favorites:
    state.mapViews.views[`view${props.activeView || state.mapViews.activeView}`]
      ?.favorites,
}))(FavoritesBtn);
