import { StarIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { DataType, SortDirection } from "ka-table/enums";
import { ICellEditorProps, ICellTextProps } from "ka-table/props";
import { pick } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FaEdit, FaTags } from "react-icons/fa";
import {
  MdChangeCircle,
  MdMoreVert,
  MdVisibility,
  MdVisibilityOff,
} from "react-icons/md";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { rrulestr } from "rrule";
import {
  selectAccessToken,
  selectOrgProperties,
  selectStates,
  selectUserResources,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  BatchActionRenderProps,
  CustomColumn,
  EntityTable,
} from "../../components/entity-table/EntityTable";
import { RESOURCES } from "../../constants/user-constants";
import MessageModalContext from "../../contexts/MessageModalContext";
import { Store } from "../../domain/Store";
import {
  getStores,
  putStore,
  putStoresBatch,
} from "../../services/api.service";
import { trackClick } from "../../services/tracking.service";
import { camelCaseWords, replacePlaceholders } from "../../utils/stringUtils";
import { refreshIframe } from "../store-locator/storeLocatorSlice";
import { ManageProductsButton } from "./BatchProductStoreForm";
import ManageFeaturedContentButton from "./ManageFeaturedContentBtn";
import StoreFilterByProduct from "./StoreFilterByProduct";
import StoreForm from "./StoreForm";
import StoreReviews from "./StoreReviews";

const pluralize = require("pluralize");
const { wrapTo } = require("kepler.gl/actions");
const { fitBounds } = require("kepler.gl/dist/actions/map-state-actions");

const ToggleStoresVisibility = ({
  selectedRows,
  onToggleFinish,
}: {
  selectedRows: Store[];
  onToggleFinish: (updatedStores: Store[]) => void;
}) => {
  const userResources = useAppSelector(selectUserResources);
  const accessToken = useAppSelector(selectAccessToken);
  const orgProperties = useAppSelector(selectOrgProperties);
  const messageModalContext = useContext(MessageModalContext);
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const toEnable = selectedRows.filter((s) => s.status === "not_selling");
  const toDisable = selectedRows.filter(
    (s) => !s.status || s.status === "selling"
  );

  const batchUpdateStores = (
    stores: Store[],
    newStatus: "selling" | "not_selling"
  ) => {
    messageModalContext.showModal({
      title: "Confirm " + (newStatus === "selling" ? "enable" : "disable"),
      message: `You will ${
        newStatus === "selling" ? "enable" : "disable"
      } ${pluralize(storeLabel, stores.length, true)} and will ${
        newStatus === "selling" ? "" : "not"
      } be visible`,
      actions: [
        {
          label: "Cancel",
          isLeastDestructive: true,
        },
        {
          label: "Confirm",
          props: { colorScheme: "blue" },
          callback: async () => {
            try {
              setIsLoading(true);
              let res: { result: string };
              try {
                res = await putStoresBatch(
                  accessToken,
                  stores.map((s) => {
                    const toUpdate = new Store({
                      id: s.id,
                      address: s.address,
                      secondary_images: s.secondary_images,
                    });
                    toUpdate.status = newStatus;
                    return toUpdate.buildForUpdate();
                  })
                );
              } catch (e) {
                res = { result: "error" };
              }
              if (res.result === "ok") {
                setIsLoading(false);

                toast.success("Updated items");
                dispatch(refreshIframe());
                onToggleFinish(
                  stores.map((s) => {
                    s.status = newStatus;
                    return s;
                  })
                );
              }
            } catch (error) {
              console.log(error);
            }
          },
        },
      ],
    });
  };
  return !toEnable.length || !toDisable.length ? (
    <Button
      leftIcon={
        !toEnable.length && !!toDisable.length ? (
          <MdVisibilityOff size="1rem" />
        ) : (
          <MdVisibility size="1rem" />
        )
      }
      isLoading={isLoading}
      variant="link"
      isDisabled={
        !(toEnable.length + toDisable.length) ||
        !userResources?.includes(RESOURCES.STORES_UPDATE)
      }
      aria-label={"Batch store disable or enable button"}
      onClick={async () => {
        const stores = toEnable.length ? toEnable : toDisable;
        batchUpdateStores(stores, toEnable.length ? "selling" : "not_selling");
      }}
    >
      {toDisable.length ? "Disable" : "Enable"}
    </Button>
  ) : (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<MdVisibility size="1rem" />}
        isLoading={isLoading}
        variant="link"
        isDisabled={!userResources?.includes(RESOURCES.STORES_UPDATE)}
        aria-label={"Batch store disable or enable button"}
      >
        Enable or disable
      </MenuButton>
      <MenuList>
        <MenuItem
          icon={<MdVisibility size="1rem" />}
          onClick={() => batchUpdateStores(toEnable, "selling")}
        >
          Enable {pluralize(storeLabel, toEnable.length, true)}
        </MenuItem>
        <MenuItem
          icon={<MdVisibilityOff size="1rem" />}
          onClick={() => batchUpdateStores(toDisable, "not_selling")}
        >
          Disable {pluralize(storeLabel, toDisable.length, true)}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const StoreTypeSelector = ({
  selectedRows,
  onToggleFinish,
  storeTypes,
}: {
  selectedRows: Store[];
  onToggleFinish: (updatedStores: Store[]) => void;
  storeTypes?: (string | undefined)[];
}) => {
  const userResources = useAppSelector(selectUserResources);
  const accessToken = useAppSelector(selectAccessToken);
  const orgProperties = useAppSelector(selectOrgProperties);
  const messageModalContext = useContext(MessageModalContext);
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const batchUpdateStores = (stores: Store[], newStatus: string) => {
    const storeType = newStatus !== "store" ? newStatus : "megastore";
    messageModalContext.showModal({
      title: "Confirm change location type to " + newStatus,
      message: `You will change the type of location of ${pluralize(
        storeLabel,
        stores.length,
        true
      )} to ${newStatus}`,
      actions: [
        {
          label: "Cancel",
          isLeastDestructive: true,
        },
        {
          label: "Confirm",
          props: { colorScheme: "blue" },
          callback: async () => {
            try {
              setIsLoading(true);
              let res: { result: string };
              const toUpdate = stores.map((s) => {
                const toUpdate = new Store({
                  id: s.id,
                  address: s.address,
                  secondary_images: s.secondary_images,
                });
                toUpdate.type = storeType;
                toUpdate.category =
                  newStatus === "restaurant"
                    ? ["Restaurants"]
                    : toUpdate.category;
                return toUpdate.buildForUpdate();
              });
              try {
                res = await putStoresBatch(accessToken, toUpdate);
              } catch (e) {
                res = { result: "error" };
              }
              if (res.result === "ok") {
                setIsLoading(false);

                toast.success("Updated items");
                dispatch(refreshIframe());
                onToggleFinish(
                  stores.map((s) => {
                    const toUpdate = new Store(s);
                    toUpdate.type = storeType;
                    toUpdate.category =
                      newStatus === "restaurant"
                        ? ["Restaurants"]
                        : toUpdate.category;
                    return toUpdate;
                  })
                );
              }
            } catch (error) {
              console.log(error);
            }
          },
        },
      ],
    });
  };
  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<MdChangeCircle size="1rem" />}
        isLoading={isLoading}
        variant="link"
        isDisabled={
          !selectedRows.length ||
          !userResources?.includes(RESOURCES.STORES_UPDATE)
        }
        aria-label={"Batch store disable or enable button"}
      >
        Change type
      </MenuButton>
      <MenuList>
        {!storeTypes?.includes("store") && (
          <MenuItem
            onClick={() => batchUpdateStores(selectedRows, "megastore")}
          >
            Store
          </MenuItem>
        )}
        {!storeTypes?.includes("restaurant") && (
          <MenuItem
            onClick={() => batchUpdateStores(selectedRows, "restaurant")}
          >
            Restaurant
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

const EditStoreButton = ({
  selectedRows,
  onClick,
}: {
  selectedRows: Store[];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const userResources = useAppSelector(selectUserResources);
  return (
    <Button
      leftIcon={<FaEdit />}
      variant="link"
      isDisabled={
        selectedRows.length !== 1 ||
        !userResources?.includes(RESOURCES.STORES_UPDATE)
      }
      aria-label={"Store edit button"}
      onClick={onClick}
    >
      Edit
    </Button>
  );
};

const OptionsButton = ({
  rowData,
  onEdit,
}: {
  rowData?: ICellTextProps["rowData"];
  onEdit?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const userResources = useAppSelector(selectUserResources);
  const orgProperties = useAppSelector(selectOrgProperties);
  const messageContext = useContext(MessageModalContext);
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";
  return rowData ? (
    <Box>
      <Menu>
        <MenuButton disabled={!rowData.id}>
          <IconButton
            variant="link"
            icon={<MdMoreVert />}
            colorScheme="blue.900"
            aria-label={""}
          />
        </MenuButton>
        <Portal>
          <MenuList maxHeight={200} overflowY="auto">
            <MenuItem
              icon={<FaEdit />}
              isDisabled={!userResources?.includes(RESOURCES.STORES_UPDATE)}
              onClick={onEdit}
            >
              Edit {storeLabel}
            </MenuItem>
            <MenuItem
              leftIcon={<StarIcon />}
              onClick={() => {
                messageContext.showModal({
                  title: `Reviews for ${pluralize(storeLabel)} ${
                    rowData.name || rowData.address
                  }`,
                  message: <StoreReviews store={rowData} />,
                });
              }}
            >
              View Reviews
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  ) : (
    <></>
  );
};

export default function MyStoresTable({
  storeTypes,
  storeId,
}: {
  storeTypes?: (string | undefined)[];
  storeId?: number;
}) {
  const location = useLocation();
  const [editingStore, setEditingStore] = useState<Store | undefined>();
  const [filteredProducts, setFilteredProducts] = useState<string[]>([]);
  const [stores, setStores] = useState<Store[] | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStores] = useState(location.state?.selectedStores);
  const orgProperties = useAppSelector(selectOrgProperties);
  const storeLocatorConfig = orgProperties?.store_locator;
  const states = useAppSelector(selectStates);
  const accessToken = useAppSelector(selectAccessToken);
  const userResources = useAppSelector(selectUserResources);
  const dispatch = useAppDispatch();
  const [statesLabel] = useState(
    orgProperties?.properties?.statesNameReplacement || "State"
  );
  const [storeLabel] = useState(
    orgProperties?.properties?.storeNameReplacement || "Store"
  );
  const [zipcodeLabel] = useState(
    orgProperties?.properties?.zipcodeNameReplacement || "Zipcode"
  );

  const filterStoresByType = useCallback(
    (toFilter: Store[]) =>
      storeTypes
        ? toFilter?.filter((s: any) => {
            if (storeTypes.includes("store") && s.type !== "restaurant") {
              return !s.start_date && !s.end_date;
            }
            if (storeTypes.includes("restaurant") && s.type === "restaurant") {
              return true;
            }
            return (
              storeTypes.includes("popup_store") && s.start_date && s.end_date
            );
          })
        : toFilter,
    [storeTypes]
  );

  useEffect(() => {
    if (!stores && !isLoading) {
      setIsLoading(true);
      getStores(
        [],
        [],
        [],
        [],
        [],
        undefined,
        true,
        accessToken,
        orgProperties?.properties?.country,
        [],
        [],
        [],
        [],
        undefined,
        storeTypes && storeTypes[0] !== "store" ? storeTypes : [],
        [
          "id",
          "store_id",
          "parent_id",
          "name",
          "address",
          "chain",
          "state",
          "county",
          "city",
          "zipcode",
          "latitude",
          "longitude",
          "status",
          "created_at",
          "category",
          "products",
          "email",
          "type",
          "phone",
          "description",
          "image_url",
          "featured_content",
          "secondary_images",
          "start_date",
          "end_date",
          "recurrence_rule",
          "origin",
        ]
      )
        .then((stores: Store[]) =>
          setStores(
            (filterStoresByType(stores) || []).map(
              (s: Store) => new Store({ ...s, status: s.status || "selling" })
            )
          )
        )
        .catch(() => setStores([]))
        .finally(() => setIsLoading(false));
    }
  }, [
    accessToken,
    filterStoresByType,
    isLoading,
    orgProperties,
    states,
    storeTypes,
    stores,
  ]);

  useEffect(() => {
    if (storeId) {
      getStores(
        [],
        [],
        [],
        [],
        [],
        undefined,
        true,
        accessToken,
        undefined,
        [],
        [storeId],
        [],
        [],
        undefined,
        [],
        [
          "id",
          "store_id",
          "parent_id",
          "name",
          "address",
          "chain",
          "state",
          "county",
          "city",
          "zipcode",
          "latitude",
          "longitude",
          "status",
          "created_at",
          "category",
          "products",
          "email",
          "type",
          "phone",
          "description",
          "image_url",
          "featured_content",
          "secondary_images",
          "start_date",
          "end_date",
          "recurrence_rule",
          "origin",
        ]
      ).then((stores: Store[]) => {
        if (stores?.[0]) {
          setEditingStore(stores?.[0]);
        }
      });
    }
  }, [accessToken, storeId]);

  const StatusSelect = (
    props: ICellEditorProps & {
      isEditing?: boolean;
      data?: any[];
      thisDispatch: any;
    }
  ) => {
    const { rowData, data, thisDispatch } = props;
    const [isLoading, setIsLoading] = useState(false);
    const accessToken = useAppSelector(selectAccessToken);
    return (
      <Select
        defaultValue={rowData.status}
        value={rowData?.status}
        isDisabled={isLoading}
        onChange={(event) => {
          const newStore = new Store(rowData);
          newStore.status = event.currentTarget.value;
          setIsLoading(true);
          putStore(newStore.buildForUpdate(), accessToken)
            .then(() => {
              thisDispatch(refreshIframe());
              toast.success(`Status updated`);
              const newStores = [...(data || [])];
              newStores[
                (data || []).findIndex((item) => item.id === rowData.id)
              ] = newStore;
              setStores(newStores || []);
            })
            .finally(() => setIsLoading(false));
        }}
      >
        <option value="" />
        {["selling", "not_selling"].map((option) => (
          <option key={option} value={option}>
            {option === "selling" ? "Enabled" : "Disabled"}
          </option>
        ))}
      </Select>
    );
  };

  const batchActions = useMemo(() => {
    const onFinishBatchAction = (newRows: Store[], oldRows?: any[]) => {
      dispatch(refreshIframe());
      setStores(
        filterStoresByType(
          Array.from(
            new Set(
              [
                ...newRows,
                ...(oldRows || []).filter(
                  (s) => !newRows.find((n) => n.id === s.id)
                ),
              ].map((s) => s.id)
            )
          )
            .map((id) =>
              [...newRows, ...(oldRows || [])].find((s) => s.id === id)
            )
            .filter((s) => !!s) || []
        )
      );
    };
    return [
      {
        Render: ({
          selectedRows,
          data,
        }: BatchActionRenderProps<Partial<Store>>) => (
          <ToggleStoresVisibility
            selectedRows={
              stores?.filter((store) =>
                selectedRows.find((s) => s.id === store.id)
              ) || []
            }
            onToggleFinish={(newRows) =>
              onFinishBatchAction(
                newRows,
                stores?.filter((store) =>
                  (data || []).find((s) => s.id === store.id)
                )
              )
            }
          />
        ),
      },
      {
        Render: ({ selectedRows }: BatchActionRenderProps<Partial<Store>>) => (
          <EditStoreButton
            selectedRows={
              stores?.filter((store) =>
                selectedRows.find((s) => s.id === store.id)
              ) || []
            }
            onClick={() =>
              setEditingStore(stores?.find((s) => s.id === selectedRows[0].id))
            }
          />
        ),
      },
      {
        Render: ({
          selectedRows,
          data,
        }: BatchActionRenderProps<Partial<Store>>) => (
          <ManageProductsButton
            stores={stores?.filter((store) =>
              selectedRows.find((s) => s.id === store.id)
            )}
            onFinish={(newRows) =>
              onFinishBatchAction(
                newRows,
                stores?.filter((store) =>
                  (data || []).find((s) => s.id === store.id)
                )
              )
            }
          />
        ),
      },
      {
        Render: ({
          selectedRows,
          data,
        }: BatchActionRenderProps<Partial<Store>>) => (
          <ManageFeaturedContentButton
            stores={stores?.filter((store) =>
              selectedRows.find((s) => s.id === store.id)
            )}
            onFinish={(newStores) => {
              trackClick(
                "store-update-featured-content",
                storeTypes?.[0] || ""
              );
              onFinishBatchAction(
                newStores,
                stores?.filter((store) =>
                  (data || []).find((s) => s.id === store.id)
                )
              );
            }}
          />
        ),
      },
      {
        Render: ({
          selectedRows,
          data,
        }: BatchActionRenderProps<Partial<Store>>) => (
          <StoreTypeSelector
            selectedRows={
              stores?.filter((store) =>
                selectedRows.find((s) => s.id === store.id)
              ) || []
            }
            onToggleFinish={(newRows) =>
              onFinishBatchAction(
                newRows,
                stores?.filter((store) =>
                  (data || []).find((s) => s.id === store.id)
                )
              )
            }
            storeTypes={storeTypes}
          />
        ),
      },
      {
        Render: ({ selectedRows }: BatchActionRenderProps<Partial<Store>>) =>
          selectedRows.length === 1 ? (
            <OptionsButton
              rowData={stores?.find((s) => s.id === selectedRows[0].id)}
              onEdit={() =>
                setEditingStore(
                  stores?.find((s) => s.id === selectedRows[0].id)
                )
              }
            />
          ) : (
            <></>
          ),
      },
    ];
  }, [dispatch, filterStoresByType, storeTypes, stores]);

  const columns: CustomColumn<any>[] = useMemo(
    () => [
      {
        title: "ID",
        key: "id",
        visible: false,
      },
      {
        title: "Status",
        dataType: DataType.String,
        key: "status",
        info: `${pluralize(
          storeLabel
        )} that have the status (Enabled) will appear in your ${storeLabel} Locator`,
        filterOptions: ["selling", "not_selling", "review"].map((status) => ({
          value: status,
          label: status === "selling" ? "Enabled" : "Disabled",
        })),
        Render: (p: any) => <StatusSelect {...p} thisDispatch={dispatch} />,
        format: (value: string) => camelCaseWords(value),
        default: "None",
      },
      {
        title: "Creation date",
        key: "created_at",
        sortDirection: SortDirection.Descend,
        dataType: DataType.Date,
      },
      {
        title: "",
        key: "hasFeaturedContent",
        filterOptions: [
          { value: "true", label: "Has featured content" },
          { value: "false", label: "No featured content" },
        ],
        width: 80,
        Render: (props: ICellEditorProps) =>
          props.value === "true" ? (
            <Icon as={FaTags} size="lg" color={"green"} />
          ) : (
            <></>
          ),
      },
      {
        title: "Name",
        key: "name",
        Render: (p) => (
          <Text>
            {storeLocatorConfig?.storeDetails.nameFormat
              ? replacePlaceholders(
                  storeLocatorConfig?.storeDetails.nameFormat,
                  p.rowData
                )
              : p.rowData.name}
          </Text>
        ),
      },
      {
        title: "Chain",
        key: "chain",
      },
      {
        title: "Address",
        key: "address",
      },
      ...(!location.pathname.includes("storelocator")
        ? [
            {
              title: "Score",
              dataType: DataType.Number,
              key: "score",
              sortDirection: SortDirection.Descend,
              filterRowOperator: ">",
              format: (value: number) =>
                ((value ?? 0) * 1000).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
            },
            {
              title: "Sales Prediction",
              dataType: DataType.Number,
              key: "predicted_avg_units",
              format: (value: number) =>
                (value ?? 0)?.toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                }),
            },
          ]
        : []),
      {
        title: pluralize(statesLabel, 1),
        key: "state",
      },
      {
        title: "City",
        key: "city",
      },
      {
        title: pluralize(zipcodeLabel, 1),
        key: "zipcode",
      },
      ...(storeTypes?.includes("popup_store")
        ? [
            {
              title: "Start Date",
              dataType: DataType.Date,
              key: "start_date",
            },
            {
              title: "End Date",
              dataType: DataType.Date,
              key: "end_date",
            },
            {
              title: "Recurrence",
              dataType: DataType.String,
              key: "recurrence_rule",
              format: (val: string) =>
                val ? rrulestr(val).toText() : "No recurrence",
            },
          ]
        : []),
      {
        title: "Origin",
        key: "origin",
        default: "User Submitted",
      },
    ],
    [
      dispatch,
      location.pathname,
      statesLabel,
      storeLabel,
      storeTypes,
      zipcodeLabel,
    ]
  );
  const pickedStores = useMemo(
    () =>
      (stores || []).map((s) => ({
        ...pick(
          s,
          columns.map((c) => c.key)
        ),
        hasFeaturedContent: (typeof s.featured_content === "string"
          ? JSON.parse(s.featured_content)
          : s.featured_content || []
        )?.length
          ? "true"
          : "false",
      })),
    [columns, stores]
  );

  const extendedFilter = useCallback(
    (rows: Partial<Store>[]) =>
      rows.filter((s) => {
        const storeProducts =
          stores?.find((store) => store.id === s.id)?.products || [];
        return !(
          filteredProducts.length &&
          !filteredProducts.every((fp) =>
            storeProducts.find((ps: any) => ps.product_id === +fp)
          )
        );
      }),
    [filteredProducts, stores]
  );

  return (
    <VStack position="relative" alignItems="start" height="100%">
      {!!editingStore && (
        <Box
          pos="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          backgroundColor="white"
          zIndex={99}
          p={3}
        >
          <StoreForm
            store={editingStore}
            storeTypes={storeTypes}
            onFinish={(result) => {
              if (result) {
                setStores((oldStores) => {
                  if (!oldStores?.length && !!isLoading) {
                    setIsLoading(false);
                    return undefined;
                  }
                  const index = (oldStores || []).findIndex(
                    (s) => s.id === result.id
                  );
                  const newStores = [...(oldStores || [])];
                  if (index > -1) newStores.splice(index, 1, result);
                  return newStores;
                });
                dispatch(refreshIframe());
              }
              setEditingStore(undefined);
            }}
          />
        </Box>
      )}
      <Box w="100%" flex={1}>
        <EntityTable
          initialTableProps={{
            columns,
            selectedRows: selectedStores,
          }}
          extendedFilter={filteredProducts?.length ? extendedFilter : undefined}
          containerProps={{ height: "100%" }}
          LeftButton={({ rowData }) => (
            <OptionsButton
              rowData={stores?.find((s) => s.id === rowData.id)}
              onEdit={() =>
                setEditingStore(stores?.find((s) => s.id === rowData.id))
              }
            />
          )}
          allowBatchActions
          additionalBatchActions={batchActions}
          loading={{ enabled: !stores }}
          extraFilters={[
            <StoreFilterByProduct
              value={filteredProducts}
              onChange={setFilteredProducts}
            />,
          ]}
          onClearFilters={() => setFilteredProducts([])}
          allowDownload={
            userResources.includes(RESOURCES.DOWNLOAD_STORES) &&
            orgProperties?.store_locator?.status === "payed"
          }
          mapForDownload={({
            name,
            chain,
            state,
            city,
            zipcode,
            address,
            origin,
          }) => ({
            name: name || "",
            chain: chain || "",
            address: address || "",
            state: state || "",
            city: city || "",
            zipcode: zipcode || "",
            origin: origin || "",
          })}
          filterForDownload={(s) => s.status !== "not_selling"}
          downloadLabel="Download all enabled"
          entityName={storeLabel}
          dataFromOutside={pickedStores}
          setOutsideData={(newData) =>
            setStores((oldData) =>
              (newData || []).reduce<Store[]>((acc, s) => {
                const foundStore = (oldData || []).find(
                  (store) => s.id === store.id
                );
                return foundStore
                  ? [...acc, { ...foundStore, ...s } as Store]
                  : acc;
              }, [])
            )
          }
        />
      </Box>
    </VStack>
  );
}
