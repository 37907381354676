import React, { FunctionComponent } from "react";
import { Center, Text } from "@chakra-ui/react";

interface OwnProps {}

type Props = OwnProps;

const PageUnavailable: FunctionComponent<Props> = (props) => {
  return (
    <Center h="100%" w="100%">
      <Text color="blue.500">This page is unavailable</Text>
    </Center>
  );
};

export default PageUnavailable;
