import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { ViewIcon } from "@chakra-ui/icons";
import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/layout";
import { DataType, SortDirection } from "ka-table/enums";
import { startCase } from "lodash";
import { toast } from "react-toastify";
// @ts-ignore
import { wrapTo } from "kepler.gl/actions";
// @ts-ignore
import { Button, IconButton } from "@chakra-ui/button";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import { Img, useDisclosure } from "@chakra-ui/react";
import { Switch } from "@chakra-ui/switch";
import { Tag, TagLabel } from "@chakra-ui/tag";
import { ICellTextProps } from "ka-table/props";
import { fitBounds } from "kepler.gl/dist/actions/map-state-actions";
import pluralize from "pluralize";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import {
  selectMenus,
  selectOrgProperties,
  selectS3Client,
  selectUserResources,
} from "../app/appSlice";
import { useAppDispatch, useAppSelector } from "../app/store";
import menuIconLocation from "../assets/images/menu-icon-location.png";
import { RESOURCES } from "../constants/user-constants";
import {
  selectActiveIntegrations,
  toggleIntegrationsModal,
} from "../features/data-integrations/integrationSlice";
import {
  selectMapSelection,
  setMapSelection,
} from "../features/map-selection/mapSelectionSlice";
import {
  selectCurrentView,
  setSideView,
} from "../features/map-views/mapViewsSlice";
import {
  selectActiveView,
  selectLoadedZipcodes,
  selectSelectedAudiences,
} from "../features/map/keplerReducer";
import { trackClick } from "../services/tracking.service";
import { EntityTable } from "./entity-table/EntityTable";

function _ZipcodeTab({ onClose }: { onClose?: () => void }) {
  const noIntegrationAlertDisclosure = useDisclosure();
  const activeIntegrations = useAppSelector(selectActiveIntegrations);
  const fbIntegration = activeIntegrations?.facebook;
  const navigate = useNavigate();
  const s3client = useAppSelector(selectS3Client);
  const activeViewKey = useAppSelector(selectActiveView);
  const mapSelection = useAppSelector(selectMapSelection);
  const loadedDemographics = useAppSelector(selectLoadedZipcodes);
  const orgProperties = useAppSelector(selectOrgProperties);
  const activeView = useAppSelector(selectCurrentView);
  const selectedAudiences = useAppSelector(selectSelectedAudiences);
  const dispatch = useAppDispatch();
  const orgConfig = orgProperties?.properties;
  const organizationId = orgProperties?.organization_id;
  const activeViewId = activeView?.id;
  const zipcodeLabel = orgConfig?.zipcodeNameReplacement || "Zipcode";
  const statesLabel = orgConfig?.statesNameReplacement || "States";
  const setupDefaultCampaignValues = async () => {
    const folderKey = encodeURIComponent("default_fb_images") + "/";
    const fileName = `${organizationId}.png`;
    const fileKey = folderKey + fileName;
    const url = await getSignedUrl(
      s3client,
      // @ts-ignore
      new GetObjectCommand({
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: fileKey,
        ResponseContentType: "image/png",
      }),
      { expiresIn: 15 * 60 }
    );
    const response = await fetch(url);
    const blob = await response.blob();

    const image = await new Promise((resolve) => {
      var reader = new FileReader();
      reader.onload = function (e) {
        resolve({
          file: new File([blob], fileName),
          fileUrl: e.target?.result,
        });
      };
      reader.readAsDataURL(blob);
    });
    const adValues = {
      images: [image],
    };

    return {
      adValues,
    };
  };

  useEffect(() => {
    localStorage.setItem("isOnlineStores", "store");
  }, []);

  const GoToZipcodeBtn = (props: ICellTextProps) => {
    const { rowData } = props;
    return (
      <VStack>
        <Switch
          isChecked={!!rowData.selectedInMap}
          onChange={() =>
            dispatch(
              setMapSelection((oldSelection: any) => {
                return {
                  ...(oldSelection || {}),
                  zipcodes:
                    (!rowData.selectedInMap && [
                      ...((oldSelection || {}).zipcodes || []),
                      rowData,
                    ]) ||
                    (oldSelection || {}).zipcodes.filter(
                      (item: any) => item.zipcode !== rowData.zipcode
                    ) ||
                    undefined,
                };
              })
            )
          }
        />
        <IconButton
          icon={<ViewIcon />}
          variant="link"
          onClick={
            rowData &&
            (() => {
              dispatch(
                wrapTo(
                  `view${activeViewId}`,
                  fitBounds([
                    rowData.bounds_west,
                    rowData.bounds_south,
                    rowData.bounds_east,
                    rowData.bounds_north,
                  ])
                )
              );
              if (onClose) onClose();
            })
          }
          aria-label="view location"
        ></IconButton>
      </VStack>
    );
  };
  return (
    <VStack h="100%" p={3}>
      <AlertDialog
        onClose={noIntegrationAlertDisclosure.onClose}
        isOpen={noIntegrationAlertDisclosure.isOpen}
        isCentered
        closeOnOverlayClick
        leastDestructiveRef={undefined}
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            Looks like you haven’t integrated a Social Media Account yet for Ads
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Go to integrations to set up your Ads Social Media Account
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              variant="solid"
              colorScheme="blue"
              onClick={() => {
                noIntegrationAlertDisclosure.onClose();
                dispatch(toggleIntegrationsModal(true));
              }}
            >
              INTEGRATIONS
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Flex flexDir="row" justifyContent="space-between" w="100%">
        <Button
          variant="link"
          onClick={() => {
            dispatch(
              setMapSelection(
                (oldSelection: any) =>
                  (oldSelection && { ...oldSelection, zipcodes: undefined }) ||
                  undefined
              )
            );
          }}
          disabled={!mapSelection?.zipcodes?.length}
        >
          Clear selection
        </Button>
      </Flex>
      <Box flex={1} w="100%">
        <EntityTable
          containerProps={{ height: "100%" }}
          initialTableProps={{
            columns: [
              {
                title: "Selected",
                key: "selectedInMap",
                sortDirection: SortDirection.Descend,
                Render: GoToZipcodeBtn,
                dataType: DataType.Boolean,
              },
              ...(!!loadedDemographics?.find((demo: any) => !!demo.score)
                ? [
                    {
                      title: "Score",
                      dataType: DataType.Number,
                      key: "score",
                      filterRowOperator: ">",
                      format: (value: any) =>
                        ((value ?? 0) * 1000).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }),
                    },
                    {
                      title: "Sales Prediction",
                      dataType: DataType.Number,
                      key: "predicted_avg_units",
                      format: (value: any) =>
                        (value ?? 0)?.toLocaleString(undefined, {
                          maximumFractionDigits: 0,
                        }),
                    },
                  ]
                : []),
              {
                title: pluralize(zipcodeLabel, 1),
                key: "zipcode",
                dataType: DataType.String,
              },
              {
                title: "City",
                key: "major_city",
                dataType: DataType.String,
              },
              {
                title: pluralize(statesLabel, 1),
                key: "state",
                dataType: DataType.String,
              },
              ...(selectedAudiences?.length
                ? selectedAudiences?.map((nat) => ({
                    title: startCase(nat),
                    dataType: DataType.Number,
                    filterRowOperator: ">=",
                    key: nat,
                  }))
                : []),
              {
                title: "Latinx",
                dataType: DataType.Number,
                filterRowOperator: ">=",
                key: "hispanic_or_latino",
                sortDirection: !selectedAudiences?.length
                  ? SortDirection.Descend
                  : undefined,
              },
              {
                title: "Population",
                dataType: DataType.Number,
                filterRowOperator: ">=",
                key: "total",
              },
            ],
          }}
          dataFromOutside={loadedDemographics
            ?.reduce((acc: any, demo: any) => {
              const _demo = {
                ...demo,
                selectedInMap: !!mapSelection?.zipcodes?.find(
                  (item: any) => item.zipcode === demo.zipcode
                ),
              };
              if (acc.find((item: any) => item.zipcode === demo.zipcode)) {
                return acc;
              }
              return [...acc, _demo];
            }, [])
            ?.sort((a: any, b: any) => {
              const audience = selectedAudiences?.[0];
              return a[audience] < b[audience]
                ? 1
                : a[audience] > b[audience]
                ? -1
                : 0;
            })}
        />
      </Box>
      {!!mapSelection?.zipcodes?.length && (
        <Text alignSelf="flex-start">
          Selected {pluralize(zipcodeLabel, 2)}:
        </Text>
      )}
      <HStack overflowX="auto" w="100%">
        {mapSelection?.zipcodes?.map((zip: any) => (
          <Tag key={zip.zipcode} minW={14}>
            <TagLabel>{zip.zipcode}</TagLabel>
          </Tag>
        ))}
      </HStack>
      <Button
        onClick={() => {
          if (!fbIntegration?.connection) {
            noIntegrationAlertDisclosure.onOpen();
            return;
          }
          onClose?.();
          setupDefaultCampaignValues().then((defaultCampaignValues) => {
            dispatch(
              setSideView({
                viewKey: activeViewKey,
                sideView: {
                  name: "fbCampaign",
                  props: {
                    defaultValues: defaultCampaignValues,
                  },
                },
              })
            );
            navigate("/");
          });
        }}
        disabled={!mapSelection?.zipcodes?.length}
      >
        Create ad campaign
      </Button>
    </VStack>
  );
}
export const ZipcodeTab = _ZipcodeTab;

const LocationBtn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const menus = useAppSelector(selectMenus);
  const userResources = useAppSelector(selectUserResources);
  const growthMenu = menus?.find((m: any) => m.id === "location");
  return !location.pathname?.includes("storelocator") ? (
    <Box>
      <Button
        id="location-btn"
        className="location-btn"
        variant="link"
        as="div"
        padding="12px 10px"
        isActive={location.pathname.includes("location")}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        isDisabled={!userResources?.includes(RESOURCES.LOCATION_BTN)}
        leftIcon={
          <Img
            src={menuIconLocation}
            height="1rem"
            width="1rem"
            id="location-btn"
            className="location-btn"
            filter={
              location.pathname.includes("location")
                ? "invert(48%) sepia(45%) saturate(4075%) hue-rotate(177deg) brightness(97%) contrast(102%)"
                : undefined
            }
          />
        }
        iconSpacing={0}
        size="xs"
        onClick={(e) =>
          trackClick("location-btn", "Places", () => {
            if (!userResources?.includes(RESOURCES.LOCATION_BTN)) {
              e.preventDefault();
              toast.warn("You don't have access to this");
              return;
            }
            const child = growthMenu?.children?.[0];
            child?.embedUrl
              ? navigate(
                  `${child.embedUrl ? "embed/" : ""}location-${child.id}`,
                  {
                    state: {
                      from: location,
                      url: child.embedUrl,
                    },
                  }
                )
              : child?.component
              ? navigate(`${child.component}/location-${child.id}`)
              : navigate(`not_available/location-${child.id}`);
          })
        }
      >
        Places
      </Button>
    </Box>
  ) : (
    <></>
  );
};

export default LocationBtn;
