import { CheckIcon } from "@chakra-ui/icons";
import { Box, Button, HStack, Input, Text } from "@chakra-ui/react";
import {
  getAuth,
  sendEmailVerification,
  verifyBeforeUpdateEmail,
} from "firebase/auth";
import { useState } from "react";
import { toast } from "react-toastify";
import { selectCurrentUserEmail } from "../app/appSlice";
import { useAppSelector } from "../app/store";

function EmailVerification() {
  const [isLoading, setIsLoading] = useState(false);
  const [didSend, setDidSend] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const userEmail = useAppSelector(selectCurrentUserEmail);
  const auth = getAuth();
  return auth.currentUser?.emailVerified ? (
    <Text color="green">
      Email verified <CheckIcon />
    </Text>
  ) : auth.currentUser?.email || userEmail ? (
    <Box>
      {!didSend && (
        <Button
          isDisabled={isLoading}
          isLoading={isLoading}
          onClick={() => {
            setIsLoading(true);
            (auth.currentUser?.email
              ? sendEmailVerification(auth.currentUser, {
                  url: window.location.href,
                })
              : verifyBeforeUpdateEmail(auth.currentUser!, userEmail, {
                  url: window.location.href,
                })
            )
              ?.then(() => setDidSend(true))
              .catch((e) => {
                toast.error(e.message);
              })
              .finally(() => setIsLoading(false));
          }}
          colorScheme="blue"
        >
          Send verification link
        </Button>
      )}
      {didSend && (
        <Text>
          Verificacion email sent: Please check your email{" "}
          {auth.currentUser?.email} and click the verification link.
        </Text>
      )}
    </Box>
  ) : (
    <Box>
      <HStack>
        <Input
          placeholder="Insert email"
          value={emailValue}
          onChange={(e) => setEmailValue(e.target.value)}
        />
        <Button
          isLoading={isLoading}
          isDisabled={!emailValue || isLoading}
          onClick={(e) => {
            setIsLoading(true);
            verifyBeforeUpdateEmail(auth.currentUser!, emailValue, {
              url: window.location.href,
            })
              .then(() => setDidSend(true))
              .catch((e) => {
                toast.error(e.message);
              })
              .finally(() => setIsLoading(false));
          }}
        >
          Verify email
        </Button>
      </HStack>
    </Box>
  );
}

export default EmailVerification;
