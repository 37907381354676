import { assign, keys, pick } from "lodash";
import { ProductDeliveryProduct } from "./ProductDeliveryProduct";

export class Product {
  id!: number;
  name!: string;
  url?: string;
  category?: string;
  description?: string;
  sku?: string;
  organization_id?: number;
  flavor?: string;
  nutrients?: string;
  gtin_upc?: string;
  manufacturer?: string;
  ingredients?: string;
  origin?: string;
  status?: "selling" | "review" | "not_selling";
  urls?: string[];
  chain_images?: { [cleanChain: string]: string };
  delivery_products?: ProductDeliveryProduct[];

  constructor(candidate: any) {
    assign(this, pick(candidate, keys(this)));
  }

  buildForPost() {
    const newReview = pick(
      JSON.parse(JSON.stringify(this)),
      keys(new Product({}))
    );
    delete newReview.id;
    delete newReview.organization_id;
    newReview.delivery_products = this.delivery_products?.map((de) => {
      return new ProductDeliveryProduct(de).buildForPost();
    });
    newReview.urls =
      typeof newReview.urls === "string"
        ? JSON.parse(newReview.urls || "[]")
        : newReview.urls;
    newReview.origin = newReview.origin || "Manual";
    return new Product(newReview);
  }

  buildForUpdate() {
    const newReview = JSON.parse(JSON.stringify(this));
    delete newReview.delivery_products;
    newReview.urls =
      typeof newReview.urls === "string"
        ? JSON.parse(newReview.urls || "[]")
        : newReview.urls;
    return new Product(newReview);
  }
}
