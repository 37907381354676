import { extendTheme, ThemeOverride, withDefaultSize } from "@chakra-ui/react";
import type { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { mode } from "@chakra-ui/theme-tools";
import components from "./components";

const dathicChakraTheme: ThemeOverride = extendTheme(
  {
    styles: {
      global: (props: StyleFunctionProps) => ({
        body: {
          color: mode("blue.900", "whiteAlpha.900")(props),
        },
      }),
    },
    fonts: {
      body: "'Montserrat', sans-serif",
      heading: "'Montserrat', sans-serif",
    },
    colors: {
      blue: {
        900: "#032536",
        800: "#395463",
        700: "#516976",
        600: "#758994",
        500: "#9CABB3",
        400: "#0299F7",
        300: "#3BAAF4",
        200: "#52B5F5",
        100: "#76C5F7",
        50: "#9DD6F9",
      },
      lightBlue: {
        400: "rgb(207,233,249)",
        300: "#eef1f5",
        200: "rgb(224,241,251)",
        100: "rgb(231,245,252)",
        50: "rgb(238,247,253)",
      },
      red: {
        400: "rgb(166,30,31)",
        300: "rgba(166,30,31,0.75)",
        200: "rgba(166,30,31,0.65)",
        100: "rgba(166,30,31,0.50)",
        50: "rgba(166,30,31,0.35)",
      },
      green: {
        400: "rgb(40,139,30)",
        300: "rgba(40,139,30,0.75)",
        200: "rgba(40,139,30,0.65)",
        100: "rgba(40,139,30,0.50)",
        50: "rgba(40,139,30,0.35)",
      },
      yellow: {
        400: "rgb(247,210,80)",
        300: "rgba(247,210,80,0.75)",
        200: "rgba(247,210,80,0.65)",
        100: "rgba(247,210,80,0.50)",
        50: "rgba(247,210,80,0.35)",
      },
      gray: {
        900: "#8F8F8F",
        800: "rgb(161,161,161)",
        700: "rgb(173,173,173)",
        600: "rgb(192,192,192)",
        500: "rgb(211,211,211)",
        400: "#E0E0E0",
        300: "rgb(228,228,228)",
        200: "rgb(232,232,232)",
        100: "rgb(236,236,236)",
        50: "rgb(242,242,242)",
      },
    },
    components,
  },
  withDefaultSize({
    size: "sm",
    components: [
      "Input",
      "Button",
      "Select",
      "Textarea",
      "NumberInput",
      "NumberInput",
    ],
  })
);
export default dathicChakraTheme;
