import React from "react";
import { Modal } from "@chakra-ui/react";

export const ResponsiveModal = (props) => {
  return (
    <Modal
      size="6xl"
      maxH="100%"
      scrollBehavior="inside"
      onClose={props.onClose || (() => {})}
      {...props}
    >
      {props.children}
    </Modal>
  );
};
