import { Button } from "@chakra-ui/button";
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/form-control";
import { CloseIcon } from "@chakra-ui/icons";
import { Input, InputProps } from "@chakra-ui/input";
import { Flex } from "@chakra-ui/layout";
import { Tag, TagLabel } from "@chakra-ui/tag";
import { useFormContext } from "react-hook-form";
import { FaAsterisk } from "react-icons/fa";
import AutocompleteInput from "./AutocompleteInput";

type Option<T> = string | T;

interface Props<T> {
  formControlProps?: FormControlProps;
  label?: string;
  autocompleteProps: any;
  isDisabled?: boolean;
  disabledReason?: string;
  value: Option<T>[];
  onChange: (option: Option<T>[]) => void;
  getOptionValue?: (option: Option<T>) => boolean | number | string;
  inputProps?: InputProps;
  helperText?: string;
  valueSep?: string;
  required?: boolean;
}

export const RequiredAsterisk = () => {
  return (
    <FaAsterisk
      color="salmon"
      fontSize="xs"
      style={{ marginLeft: 3, marginBottom: 6 }}
    />
  );
};

const MultiSelectFormControl = <T extends unknown>({
  formControlProps,
  label = undefined,
  autocompleteProps,
  isDisabled = false,
  disabledReason,
  value = [],
  onChange,
  getOptionValue,
  inputProps,
  helperText,
  required = false,
  valueSep = ",",
}: Props<T>) => {
  const { formState } = useFormContext() || {};
  const { errors = {} } = formState || {};
  const removeOption = (option: Option<T>) => {
    let newList = [...(value || [])];
    newList.splice(
      newList.findIndex((searchItem) => searchItem === option),
      1
    );
    onChange(newList);
  };

  const clearOptions = () => {
    onChange([]);
  };
  return (
    <FormControl
      isInvalid={!!errors?.[label || ""]?.message}
      {...(formControlProps || {})}
    >
      {label && (
        <FormLabel>
          <Flex>
            {label}
            {required && <RequiredAsterisk />}
          </Flex>
        </FormLabel>
      )}
      {isDisabled && disabledReason && (
        <FormHelperText>{disabledReason}</FormHelperText>
      )}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {!!autocompleteProps?.options && (
        <AutocompleteInput
          disabled={
            isDisabled ||
            (!autocompleteProps.fetchSuggestions &&
              autocompleteProps.options.length === 0)
          }
          inputProps={inputProps}
          getSuggestionValue={getOptionValue}
          {...(autocompleteProps || {})}
          handleSearchResultClick={(selection: { suggestion: Option<T> }) => {
            onChange([...(value || []), selection.suggestion]);
          }}
        />
      )}
      {!autocompleteProps?.options && (
        <Input
          datatype="list"
          disabled={isDisabled}
          {...(inputProps || {})}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              const newOptions = e.target.value
                .split(valueSep)
                .map((option) => option.trim())
                .filter((option) => !value?.includes(option));
              onChange([...(value || []), ...newOptions]);
            }
          }}
          onBlur={(e) => {
            if (e.target.value) {
              const newOptions = e.target.value
                .split(valueSep)
                .map((option) => option.trim())
                .filter((option) => !value?.includes(option));
              onChange([...(value || []), ...newOptions]);
            }
          }}
        />
      )}
      {errors?.[label || ""]?.message && (
        <FormErrorMessage>{errors?.[label || ""]?.message}</FormErrorMessage>
      )}
      {!!value?.length && (
        <Flex overflowX="auto" mt={2}>
          {value?.map((item, index) => (
            <Tag
              key={index}
              mr={1}
              mb={2}
              minW={20}
              justifyContent="space-between"
            >
              <TagLabel>
                {(getOptionValue && getOptionValue(item)) || `${item}`}
              </TagLabel>
              {!isDisabled && <CloseIcon onClick={() => removeOption(item)} />}
            </Tag>
          ))}
        </Flex>
      )}
      {!isDisabled && !!value?.length && (
        <Button variant="link" onClick={clearOptions}>
          clear
        </Button>
      )}
    </FormControl>
  );
};

export default MultiSelectFormControl;
