import { HStack } from "@chakra-ui/layout";
import {
  createAsyncThunk,
  createSlice,
  Dispatch,
  PayloadAction,
} from "@reduxjs/toolkit";
import { IconType } from "react-icons";
import { FaShopify } from "react-icons/fa";
import { IoLogoFacebook, IoLogoInstagram } from "react-icons/io5";
import { setCurrentUser } from "../../app/appSlice";
import { RootState } from "../../app/store";
import FacebookAdAccountSelect from "../../components/FacebookAdAccountSelect";
import { facebookAuth } from "../../services/facebook_api.service";
import { loginWithFacebook, logoutOfFacebook } from "./facebook/facebook-api";
import ConnectForm from "./shopify/ConnectForm";

const FacebookInstagramLogo = ({ size }: { size?: string | number }) => (
  <HStack>
    <IoLogoFacebook size={size} />
    <IoLogoInstagram size={size} />
  </HStack>
);

type Action = {
  label: string;
  action: (dispatch: Dispatch, accessToken: string) => void;
};

export type Integration = {
  id: string;
  name: string;
  category: string;
  Icon: IconType;
  description: string;
  connectAction?: Action;
  disconnectAction?: Action;
  settings: { label: string; action?: Action; Element?: () => JSX.Element }[];
};

type ActiveIntegration = { config?: any; connection?: any };

type IntegrationState = {
  modalIsOpen: boolean;
  selectedIntegration?: Integration;
  availableIntegrations: Integration[];
  activeIntegrations: {
    [key: string]: ActiveIntegration;
  };
  shouldOpen: {
    [key: string]: boolean;
  };
};

const storeLocatorIntegrations = ["shopify"];

// INITIAL_STATE
const initialState: IntegrationState = {
  modalIsOpen: false,
  selectedIntegration: undefined,
  availableIntegrations: [
    {
      id: "facebook",
      name: "Facebook & Instagram",
      category: "Marketing",
      Icon: FacebookInstagramLogo,
      description:
        "Manage your facebook advertisments with dathic to reach the audience near the best recommended places.",
      connectAction: {
        action: (dispatch, accessToken) =>
          loginWithFacebook().then((response) => {
            if (response?.authResponse?.accessToken) {
              facebookAuth(
                response?.authResponse?.accessToken,
                accessToken
              ).then((newUser) => {
                if (newUser) {
                  dispatch(
                    setActiveIntegrations((active) => ({
                      ...active,
                      facebook: { connection: response.authResponse },
                    }))
                  );
                  dispatch(
                    setCurrentUser((oldUser: any) => ({
                      ...oldUser,
                      ...newUser,
                    }))
                  );
                }
              });
            }
          }),
        label: "Login with facebook",
      },
      disconnectAction: {
        action: async (dispatch) => {
          const removedSuccessfully = await logoutOfFacebook();
          if (removedSuccessfully) {
            dispatch(
              setActiveIntegrations((active) => {
                const newActives = { ...active };
                delete newActives.facebook;
                return newActives;
              })
            );
          }
        },
        label: "Remove facebook integration",
      },
      settings: [
        {
          label: "Choose your Ad Account",
          Element: FacebookAdAccountSelect,
        },
        {
          label: "Facebook dashboard",
          action: {
            label: "Go to facebook dashboard",
            action: (dispatch) => {
              dispatch(toggleIntegrationsModal(false));
              dispatch(
                setShouldOpen((old) => ({ ...(old || {}), facebook: true }))
              );
            },
          },
        },
      ],
    },
    {
      id: "shopify",
      name: "Shopify",
      category: "Products and Sales",
      Icon: FaShopify,
      description: "Sync your shopify products and orders with dathic.",
      connectAction: {
        action: (dispatch, accessToken) => {},
        label: "Connect",
      },
      settings: [
        {
          label: "",
          Element: ConnectForm,
        },
      ],
    },
    // {
    //   id: "google",
    //   name: "Google",
    //   Icon: IoLogoGoogle,
    //   description: "View your google analytics data on the map.",
    //   connectAction: undefined /* {
    //     action: (dispatch) => authenticateGoogleApis(),
    //     label: "Login with google",
    //   } */,
    //   disconnectAction: {
    //     action: async (dispatch) => {
    //       await disconnectGoogle();
    //       dispatch(
    //         setActiveIntegrations((active) => ({
    //           ...active,
    //           google: undefined,
    //         }))
    //       );
    //     },
    //     label: "Remove google integration",
    //   },
    //   settings: [
    //     {
    //       label: "Google dashboard",
    //       action: {
    //         label: "Go to google dashboard",
    //         action: (dispatch) => {
    //           dispatch(toggleIntegrationsModal(false));
    //           dispatch(
    //             setShouldOpen((old) => ({ ...(old || {}), google: true }))
    //           );
    //         },
    //       },
    //     },
    //     {
    //       label: "Select view",
    //       Element: ({ state, dispatch, selectedIntegration }) => {
    //         const { selectedGaView } =
    //           state.activeIntegrations[selectedIntegration.id].config || {};
    //         const [gaViews, setGaViews] = useState([]);
    //         useEffect(() => {
    //           getGAViews().then((results) => {
    //             setGaViews(results || []);
    //             if (!selectedGaView && results?.length) {
    //               dispatch(
    //                 setIntegrationConfig({
    //                   integrationId: selectedIntegration.id,
    //                   payload: (oldConfig) => ({
    //                     ...oldConfig,
    //                     selectedGaView: results[0],
    //                   }),
    //                 })
    //               );
    //             }
    //           });
    //           // eslint-disable-next-line react-hooks/exhaustive-deps
    //         }, []);
    //         return gaViews?.length ? (
    //           <Select
    //
    //             value={gaViews?.indexOf(selectedGaView)}
    //             onChange={(event) => {
    //               dispatch(
    //                 setIntegrationConfig({
    //                   integrationId: selectedIntegration.id,
    //                   payload: (oldConfig) => ({
    //                     ...oldConfig,
    //                     selectedGaView: gaViews[event.target.value],
    //                   }),
    //                 })
    //               );
    //             }}
    //           >
    //             {gaViews.map((option, index) => (
    //               <option key={option.id} value={index}>
    //                 {`${option.accountId} > ${option.webPropertyId} > ${option.id}`}
    //               </option>
    //             ))}
    //           </Select>
    //         ) : (
    //           <Text color="orangered">
    //             User does not have Google Analytics views
    //           </Text>
    //         );
    //       },
    //     },
    //   ],
    // },
  ],
  activeIntegrations: {},
  shouldOpen: {},
};

export const handleSettingAction = createAsyncThunk<
  void,
  { action?: Function; params?: any[] }
>("integrations/handleAction", async (payload, { getState, dispatch }) => {
  if (typeof payload?.action === "function") {
    await payload.action(dispatch, ...(payload?.params || []));
  }
});

const integrationSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    setActiveIntegrations: (
      state,
      action: PayloadAction<
        | ((
            currentlyActive: IntegrationState["activeIntegrations"]
          ) => IntegrationState["activeIntegrations"])
        | IntegrationState["activeIntegrations"]
      >
    ) => {
      state.activeIntegrations =
        typeof action.payload === "function"
          ? action.payload(state.activeIntegrations)
          : action.payload;
    },
    setShouldOpen: (
      state,
      action: PayloadAction<
        | ((
            currentlyActive: IntegrationState["shouldOpen"]
          ) => IntegrationState["shouldOpen"])
        | IntegrationState["shouldOpen"]
      >
    ) => {
      state.shouldOpen =
        typeof action.payload === "function"
          ? action.payload(state.shouldOpen)
          : action.payload;
    },
    setIntegrationConfig: (state, action) => {
      if (action.payload?.integrationId) {
        state.activeIntegrations[action.payload?.integrationId].config =
          typeof action.payload.payload === "function"
            ? action.payload.payload(
                state.activeIntegrations[action.payload?.integrationId].config
              )
            : action.payload.payload;
      }
    },
    toggleIntegrationsModal: (state, action) => {
      state.modalIsOpen = !!action.payload;
    },
    selectIntegration: (state, action) => {
      state.selectedIntegration = action.payload || undefined;
    },
    resetIntegrations: (state, action) => {
      state = initialState;
    },
  },
});

export const selectShouldOpenFacebook = (state: {
  integrations: IntegrationState;
}) => state.integrations.shouldOpen?.facebook;
export const selectSelectedIntegration = (state: {
  integrations: IntegrationState;
}) => state.integrations.selectedIntegration;
export const selectAvailableIntegrations = (state: RootState) => {
  const userRoles = state.app.currentUser?.roles;
  const userIsOnlyStoreLocator =
    userRoles.find((ur: any) => ur.role?.name.includes("store_locator")) &&
    !userRoles.find((ur: any) => !ur.role?.name.includes("store_locator"));
  return state.integrations.availableIntegrations.filter(
    (i) => !userIsOnlyStoreLocator || storeLocatorIntegrations.includes(i.id)
  );
};
export const selectActiveIntegrations = (state: {
  integrations: IntegrationState;
}) => state.integrations.activeIntegrations;

export const {
  setActiveIntegrations,
  setIntegrationConfig,
  toggleIntegrationsModal,
  setShouldOpen,
  resetIntegrations,
  selectIntegration,
} = integrationSlice.actions;

export default integrationSlice.reducer;
