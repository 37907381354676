//@flow
import { Box, Heading, Text } from "@chakra-ui/layout";
import React from "react";
import {
  AbstractSeries,
  DiscreteColorLegend,
  FlexibleWidthXYPlot,
  Hint,
  HorizontalBarSeries,
  VerticalBarSeries,
  XAxis,
  YAxis,
} from "react-vis";

type Props = {
  title: ?string,
  xAxis: string,
  yAxis: string,
  data: Object,
  xDomain: Array<string>,
  children: ?React.ChildrenArray<typeof AbstractSeries>,
  extraSeriesForLegend: ?Array<string>,
  filteringMode: ?("none" | "filterRow"),
};

const BarChart = ({
  title,
  xAxis,
  yAxis,
  data,
  xDomain,
  yDomain,
  children,
  extraSeriesForLegend,
  containerProps = {},
  xyPlotProps = {},
  headingProps = {},
  height = 400,
  hideLegend,
  chartContainerProps = {},
  xAxisTickAngle = -12,
  isHorizontal = false,
}: Props): React.Node => {
  const [hintValue, setHintValue] = React.useState(false);
  const BarSeries = isHorizontal ? HorizontalBarSeries : VerticalBarSeries;
  return (
    <Box {...containerProps}>
      {title && <Heading {...headingProps}>{title}</Heading>}
      <Box
        h={height}
        mt={3}
        mb={20}
        bg="#f5f5f5"
        border="solid 1px #d3d3d3"
        {...chartContainerProps}
      >
        {!Object.values(data || {}).filter((val) => !!val).length && (
          <Text>Not enough data</Text>
        )}
        <FlexibleWidthXYPlot
          margin={{ left: 100, top: 50, right: 50, bottom: 50 }}
          xType="ordinal"
          xDomain={xDomain}
          yDomain={yDomain}
          height={height}
          {...xyPlotProps}
        >
          <XAxis title={xAxis} tickLabelAngle={xAxisTickAngle} />
          <YAxis title={yAxis} />
          {Object.values(data || {}).map((serie, i) => (
            <BarSeries
              key={i}
              data={serie?.length ? serie : serie?.data}
              onValueMouseOver={(v) => setHintValue(v)}
              onSeriesMouseOut={(v) => setHintValue(false)}
              {...(serie?.props || {})}
            />
          ))}
          {children}
          {!hideLegend && (
            <DiscreteColorLegend
              items={[
                ...(Object.keys(data || {}) || []),
                ...(extraSeriesForLegend?.length ? extraSeriesForLegend : []),
              ]}
              orientation="horizontal"
            />
          )}
          {hintValue !== false && <Hint value={hintValue} />}
        </FlexibleWidthXYPlot>
      </Box>
    </Box>
  );
};

export default BarChart;
