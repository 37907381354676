import {
  ChevronLeftIcon,
  DeleteIcon,
  EditIcon,
  LinkIcon,
  PlusSquareIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  GridItem,
  HStack,
  IconButton,
  Image,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  SimpleGrid,
  Tag,
  TagLabel,
  Text,
  useEditableControls,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import { toLower } from "lodash";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import React, { useContext, useMemo, useState } from "react";
import { SuggestionSelectedEventData } from "react-autosuggest";
import { SubmitHandler, useForm, UseFormSetValue } from "react-hook-form";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  selectAccessToken,
  selectChains,
  selectOrgProperties,
  selectProducts,
  setProducts,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import AutocompleteInput from "../../components/AutocompleteInput";
import { DathicFileDrop } from "../../components/DathicFileDrop";
import MessageModalContext from "../../contexts/MessageModalContext";
import { Product } from "../../domain/Product";
import { postProduct, putProduct } from "../../services/api.service";
import { trackClick } from "../../services/tracking.service";
import { urlPattern } from "../../utils/compareUtils";
import { refreshIframe } from "../store-locator/storeLocatorSlice";

type Props = {
  product?: Product;
  onFinish?: (product: Product | undefined) => void;
  skipSuccessMessage?: boolean;
};

type UrlsFieldProps = {
  product?: Product;
  setUrlsValue: (urls: string[]) => void;
  formValues: Product;
};

const ProductUrlsField = ({
  product,
  setUrlsValue,
  formValues,
}: UrlsFieldProps) => {
  const [value, setValue] = useState("");
  const [prodUrls, setProdUrls] = useState(
    (typeof formValues.urls === "string"
      ? JSON.parse(formValues.urls || "[]")
      : formValues.urls || []) as string[]
  );
  const accessToken = useAppSelector(selectAccessToken);
  const originalUrls = useMemo(
    () =>
      (typeof formValues.urls === "string"
        ? JSON.parse(formValues.urls || "[]")
        : formValues.urls || []) as string[],
    [formValues.urls]
  );

  const editUrl = (newValue: string[]) => {
    setProdUrls(newValue);
    setUrlsValue(newValue);
    if (product?.id) {
      const p = new Product(product);
      p.urls = newValue;
      putProduct(p.buildForUpdate(), accessToken).then(() => {
        toast.success(`Urls updated`);
      });
    }
  };

  const changeUrl = (edited: string, index: number) => {
    if (/^https?:\/\//.test(edited)) {
      setProdUrls(() => {
        const newList = [...(prodUrls || [])];
        newList[index] = edited;
        return newList;
      });
    } else {
      toast.error('URLs must begin with "http://" or "https://"');
    }
  };

  const deleteUrl = (index: number) => {
    setProdUrls((oldValue) => {
      const newList = [...(oldValue || [])];
      newList.splice(index, 1);
      return newList;
    });
  };

  const addUrl: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (value?.length) {
      if (/^https?:\/\//.test(value)) {
        setProdUrls((oldValue) => [value, ...(oldValue || [])]);
        setValue("");
      }
    } else {
      toast.error("Please enter a url");
    }
  };

  const EditableIcon = () => {
    const { isEditing, getEditButtonProps } = useEditableControls();
    return isEditing ? (
      <></>
    ) : (
      <IconButton
        aria-label="edit-product-urls-btn"
        icon={<EditIcon />}
        variant="link"
        {...getEditButtonProps()}
      />
    );
  };
  return (
    <Box w="100%">
      <Popover
        placement="bottom-start"
        onClose={() => setProdUrls(originalUrls)}
      >
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <Button leftIcon={<LinkIcon />} w="100%">
                {prodUrls?.length || originalUrls?.length || 0} URLs
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <VStack maxH={200} overflowY="auto">
                  <Flex w="100%">
                    <form onSubmit={addUrl}>
                      <Input
                        flex={1}
                        value={value}
                        onChange={(e) => {
                          setValue(e.target.value);
                        }}
                        placeholder="Add a url"
                        rounded="md"
                      />
                    </form>
                  </Flex>
                  {value && !/^https?:\/\//.test(value) && (
                    <FormControl color="tomato">
                      URLs must begin with "http://" or "https://"
                    </FormControl>
                  )}
                  {prodUrls.map((url, index) => (
                    <Flex
                      flexDir="row"
                      justify="space-between"
                      w="100%"
                      shadow="base"
                      rounded="md"
                      p={2}
                    >
                      <Editable
                        key={url}
                        defaultValue={url}
                        onSubmit={(newValue) => changeUrl(newValue, index)}
                      >
                        <EditablePreview />
                        <EditableInput />
                        <EditableIcon />
                      </Editable>
                      <IconButton
                        aria-label="delete-product-url-btn"
                        icon={<DeleteIcon />}
                        onClick={() => deleteUrl(index)}
                      />
                    </Flex>
                  ))}
                  <Flex mt={2} mb="-2" alignSelf="flex-end">
                    <Button
                      variant="outline"
                      onClick={onClose}
                      colorScheme="red"
                    >
                      Cancel
                    </Button>
                    <Button
                      ml={2}
                      colorScheme="green"
                      disabled={!!value && !/^https?:\/\//.test(value)}
                      onClick={() => {
                        const urls = [
                          ...(value ? [value] : []),
                          ...(prodUrls || []),
                        ];
                        editUrl(urls);
                        onClose();
                      }}
                    >
                      Save
                    </Button>
                  </Flex>
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  );
};

const ImageCard = ({
  imageUrl,
  onChange,
  cleanChain,
  chains,
}: {
  imageUrl?: string;
  onChange: (url?: string) => void;
  cleanChain?: string;
  chains?: string[];
}) => {
  const orgProperties = useAppSelector(selectOrgProperties);
  return (
    <VStack>
      {(!imageUrl || !urlPattern.test(imageUrl)) && (
        <DathicFileDrop
          shouldUpload
          s3Params={{
            folderName: "product_images",
            getFileName: DateTime.now()
              .toFormat("yyyy-mm-dd_hh-mm-ss")
              .concat(`_${cleanChain || orgProperties?.organization?.id}`),
            isPublic: true,
          }}
          onUrl={onChange}
          accept="image/*"
          maxImageWidth={600}
          containerProps={{ w: 48, h: 48 }}
          messageDirection="column"
        />
      )}
      {imageUrl && urlPattern.test(imageUrl) && (
        <Box rounded="md" shadow="lg" w={48} h={48} overflow="hidden">
          <Image src={imageUrl} w="12rem" h="12rem" objectFit="contain" />
        </Box>
      )}
      <HStack w={"100%"} justifyContent={"space-between"}>
        <Tag>
          <TagLabel>
            {chains?.find(
              (c) => c.replace(/[^a-zA-Z0-9]/g, "").toLowerCase() === cleanChain
            ) || "Main"}
          </TagLabel>
        </Tag>
        {(!!cleanChain || !!imageUrl) && (
          <IconButton
            variant="link"
            icon={<DeleteIcon />}
            onClick={() => onChange("")}
            aria-label={"Remove product image"}
          />
        )}
      </HStack>
    </VStack>
  );
};

const ImageControl = ({
  imageUrl,
  errors,
  dirtyFields,
  setValue,
  chainImages = {},
}: {
  imageUrl: never;
  errors: any;
  dirtyFields: any;
  setValue: UseFormSetValue<Product>;
  chainImages?: { [cleanchain: string]: string };
}) => {
  const [showingSelect, setShowingSelect] = useState(false);
  const [showingChainInput, setShowingChainInput] = useState(false);
  const chains: string[] | undefined = useAppSelector(selectChains);
  return (
    <FormControl isInvalid={errors.url && dirtyFields.url}>
      <FormLabel>Image</FormLabel>
      <FormHelperText>
        Set the main image or a specific image for a certain chain
      </FormHelperText>
      <Wrap alignItems={"center"} spacing={5}>
        <ImageCard
          imageUrl={imageUrl}
          onChange={(url) => {
            setValue("url", url || "");
          }}
        />
        {Object.entries(chainImages || {}).map(([key, value]) => (
          <ImageCard
            imageUrl={value}
            onChange={(url) => {
              const newChainImages = { ...chainImages, [key]: url };
              if (!url) delete newChainImages[key];
              setValue("chain_images", newChainImages);
            }}
            cleanChain={key}
            chains={chains}
          />
        ))}
        <VStack>
          {!showingChainInput && (
            <IconButton
              icon={<PlusSquareIcon />}
              aria-label={"Add product image button"}
              onClick={() => setShowingChainInput(true)}
            />
          )}
          {!!showingChainInput && (
            <AutocompleteInput
              options={chains || []}
              inputProps={{
                placeholder: "Select a chain to customize",
                autoFocus: true,
                width: 300,
              }}
              handleSearchResultClick={(
                v: SuggestionSelectedEventData<string>
              ) => {
                setShowingChainInput(false);
                const cleanChain = v.suggestion
                  .replace(/[^a-zA-Z0-9]/g, "")
                  .toLowerCase();
                setValue("chain_images", { ...chainImages, [cleanChain]: "" });
              }}
              exclude={(chains || []).filter((c) =>
                Object.keys(chainImages || {}).includes(
                  c.replace(/[^a-zA-Z0-9]/g, "").toLowerCase()
                )
              )}
            />
          )}
        </VStack>
      </Wrap>
    </FormControl>
  );
};

function ProductForm({ product, onFinish, skipSuccessMessage }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const orgProperties = useAppSelector(selectOrgProperties);
  const accessToken = useAppSelector(selectAccessToken);
  const products = useAppSelector(selectProducts);
  const messageContext = useContext(MessageModalContext);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<Product>({
    defaultValues:
      product ||
      ({
        name: "",
        url: "",
        description: "",
        category: "",
        status: "selling",
        sku: "",
        chain_images: {},
      } as Product),
  });
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";

  const formValues = watch();
  const imageUrl = watch("url");
  const chainImages = watch("chain_images");

  const onSubmit: SubmitHandler<Product> = async (updatedProduct) => {
    let result: Product | undefined;
    try {
      const _products = [...products];
      const _product = new Product(updatedProduct);
      if (product?.id) {
        trackClick("product-update-confirm", "");
        result = (await putProduct(
          _product.buildForUpdate(),
          accessToken
        )) as Product;
        const index = products.findIndex((p) => p.id === result?.id);
        if (index > -1) _products.splice(index, 1, result);
      } else {
        trackClick("product-add-manually-confirm", "");
        result = await postProduct(_product.buildForPost(), accessToken);
        if (result) _products.push(result);
        reset();
      }
      dispatch(refreshIframe());
      dispatch(setProducts(_products));
      if (skipSuccessMessage) {
        if (onFinish) onFinish(result);
        return;
      }
      messageContext.showModal({
        title: `You have successfully ${
          product?.id ? "updated" : "added"
        } a product`,
        variant: "success",
        message: `Click ${pluralize(
          storeLabel
        )} to associate the product with the ${pluralize(
          storeLabel
        )} that handle it.`,
        actions: [
          {
            label: `Associate with ${pluralize(storeLabel)}`,
            callback: () => {
              if (location.pathname.includes("storelocator")) {
                navigate(`/storelocator/stores`, { state: { tabIndex: 1 } });
                return;
              }
              navigate(`/StoresTabs/stores-manage`, { state: { tabIndex: 1 } });
              if (onFinish) onFinish(result);
            },
          },
          ...(!product?.id
            ? [
                {
                  label: `Add another`,
                },
              ]
            : []),
        ],
      });
    } catch (error) {
      console.log(error);
      if (onFinish) onFinish(result);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      <VStack
        h="100%"
        w="100%"
        spacing={10}
        textAlign="start"
        bg="white"
        p={3}
        rounded="lg"
      >
        {onFinish && (
          <Button
            variant="link"
            alignSelf="flex-start"
            leftIcon={<ChevronLeftIcon />}
            onClick={() => {
              if (onFinish) onFinish(undefined);
            }}
          >
            Back
          </Button>
        )}
        <Text>Input information to add a new product to your organization</Text>
        <SimpleGrid columns={2} gap={5} w={"100%"}>
          <FormControl isInvalid={errors.name && dirtyFields.name}>
            <FormLabel>Name</FormLabel>
            <Input
              {...register("name", {
                required: true,
                validate: () =>
                  !products
                    .map((p) => toLower(p.name || ""))
                    .filter((n) => !!n)
                    .includes(toLower(formValues.name)) ||
                  (product?.id &&
                    toLower(product.name) === toLower(formValues.name)) ||
                  "You already have a product with this name",
              })}
              placeholder="Enter Product Name"
            />
            {!!errors.name?.message && (
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Input
              {...register("description")}
              placeholder="Enter Product Description"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Category</FormLabel>
            <Input
              {...register("category")}
              placeholder="Enter Product Category"
            />
          </FormControl>
          <FormControl>
            <FormLabel>SKU</FormLabel>
            <Input {...register("sku")} placeholder="Enter SKU" />
          </FormControl>
          <FormControl>
            <FormLabel>Status</FormLabel>
            <FormHelperText>
              If disabled, the product will not show up in {storeLabel} locator
            </FormHelperText>
            <Select {...register("status")} placeholder="Select">
              <option value="selling">Enabled</option>
              <option value="not_selling">Disabled</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>URLs</FormLabel>
            <FormHelperText>
              Insert e-commerce URLs of your product here
            </FormHelperText>
            <ProductUrlsField
              formValues={formValues as Product}
              setUrlsValue={(urls: string[]) => setValue("urls", urls)}
              product={product}
            />
          </FormControl>
          <GridItem rowSpan={2} colSpan={2}>
            <ImageControl
              imageUrl={imageUrl}
              setValue={setValue}
              errors={errors}
              dirtyFields={dirtyFields}
              chainImages={chainImages}
            />
          </GridItem>
        </SimpleGrid>
        <Button type="submit" colorScheme="blue" minH={8}>
          {product?.id ? "Update Product" : "Create Product"}
        </Button>
      </VStack>
    </form>
  );
}

export default ProductForm;
