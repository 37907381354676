import { useState } from "react";
import { Box } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
import { RadialChart, Hint } from "react-vis";

type Props = {
  value?: { y: number; x: string }[];
  height?: number;
  width?: number;
  [x: string | number | symbol]: unknown;
};

export const PieForTable = (props: Props) => {
  const { value, height = 100, width = 100, ...other } = props;
  const [hoveredPortion, setHoveredPortion] = useState<{
    [key: string]: any;
  }>();
  return !props.value ||
    !props.value.filter((v) => !!v.y).length ||
    props.value.filter((v) => !!v.y).length < 2 ? (
    <Text>Not enough data</Text>
  ) : (
    <Box>
      <RadialChart
        height={height}
        width={width}
        innerRadius={50}
        onValueMouseOut={(v) => setHoveredPortion(undefined)}
        onValueMouseOver={(v) => {
          const total = props.value?.reduce((sum, value) => sum + value.y, 0);
          const value = props.value?.find((va) => va.x === v.label);
          setHoveredPortion({
            [v.label || "value"]: `${(
              (100 * (value?.y || 0)) /
              (total || 1)
            ).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}%`,
          });
        }}
        data={(props.value || []).map((key) => ({
          angle: key.y,
          label: key.x,
        }))}
        {...other}
      >
        {hoveredPortion && (
          <Hint value={hoveredPortion} style={{ zIndex: 9999 }} />
        )}
      </RadialChart>
      <Text>{`Winner: ${
        (props.value || []).reduce((biggest, current) =>
          current.y > biggest?.y ? current : biggest
        )?.x
      }`}</Text>
    </Box>
  );
};

export default PieForTable;
