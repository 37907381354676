//@flow
import { processCsvData } from "kepler.gl/dist/processors";
import { unparse } from "papaparse";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { wrapTo } from "kepler.gl/dist/actions";
import { toast } from "react-toastify";
import { createGeoLayer, deleteGeoLayer } from "../layers/layersSlice";
import { appendVisData } from "../map/keplerReducer";
import { getDeliveryInventory } from "../../services/api.service";
const layerConfigFile = require("../../data/layer_config.json");

// INITIAL_STATE
export const initialState = {
  loading: {
    stores: false,
  },
};

export function defaultDeliveryEntityLayerConfig(info, colorBy) {
  const storesConfig = JSON.parse(
    JSON.stringify(layerConfigFile.deliveryEntityConfig)
  );
  storesConfig.config.visState.layers[0].config.label = info.label;
  storesConfig.config.visState.layers[0].config.dataId = info.id;
  storesConfig.config.visState.layers[0].config.id = info.id;
  storesConfig.config.visState.layers[0].id = info.id;

  if (colorBy) {
    storesConfig.config.visState.layers[0].visualChannels.colorField.name =
      colorBy;
  }
  return storesConfig;
}

//$FlowFixMe
export const showDeliveryEntites = createAsyncThunk(
  "delivery/showDeliveryEntites",
  async (filters, { getState, dispatch }) => {
    const state = getState();
    const {
      mapId,
      shouldCreateLayer,
      deliveryEntities,
      groups,
      onlyWhereAvailable,
      colorBy,
    } = filters || {};
    const { accessToken, orgProperties } = state.app;
    const { activeView, views } = state.mapViews;
    const { visState } =
      state.keplerGl?.[`view${views?.[`view${activeView}`]?.id}`] || {};
    const view = views?.[`view${activeView}`];
    const _selectedStates = Array.from(
      new Set(
        visState?.layers
          ?.filter(
            (layer) => !!layer.config?.dataId?.includes("selected_audience")
          )
          ?.flatMap((layer) => layer.dataToFeature)
          ?.map((f) => f.properties?.state)
          ?.filter((s) => !!s) || []
      )
    );
    const products = (groups || [])
      .flatMap((val) => val.items)
      .filter((val) => !!val?.uid);
    const results = await getDeliveryInventory(
      accessToken,
      products.map((prod) => prod.uid),
      deliveryEntities.map((prod) => prod.uid),
      undefined,
      undefined,
      undefined,
      undefined,
      onlyWhereAvailable
    );
    if (results?.length) {
      const stores = results.reduce((acc, item) => {
        const delivery_entity = deliveryEntities.find(
          (ent) => ent.uid === item.delivery_entity_uid
        );
        delete item.delivery_entity;
        let newAcc = [...acc];
        const groupKeys =
          (groups || []).filter(
            (group) =>
              !!group?.items?.find(
                (groupItem) => groupItem.uid === item.delivery_product_uid
              )
          ) || [];
        const storeIndex = newAcc.findIndex(
          (store) => store.uid === item.delivery_entity_uid
        );

        const selectedStates = _selectedStates || [];
        if (
          groupKeys.length > 1 ||
          (storeIndex > -1 &&
            newAcc[storeIndex].product_search !== groupKeys[0]?.name)
        ) {
          newAcc[storeIndex].product_search = "Both";
          newAcc[storeIndex].inventory = [
            ...newAcc[storeIndex].inventory,
            item,
          ];
        } else if (
          storeIndex === -1 &&
          selectedStates.includes(delivery_entity?.state) &&
          delivery_entity?.country ===
            (orgProperties?.properties?.country || "US")
        ) {
          newAcc = [
            ...newAcc,
            {
              ...delivery_entity,
              product_search: groupKeys[0]?.name,
              inventory: [item],
            },
          ];
        }
        return newAcc;
      }, []);

      if (!!stores?.length) {
        const groupNames = [];
        const data = (stores || []).map((store, id) => {
          if (!groupNames.find((group) => group === store.product_search))
            groupNames.push(store.product_search);

          Object.keys(store.insights?.[0] || {}).forEach((key) => {
            if (
              store.insights[0][key] &&
              typeof store.insights[0][key] === "object"
            ) {
              Object.keys(store.insights[0][key]).forEach((subKey) => {
                store[subKey] = store.insights[0][key][subKey];
              });
            } else {
              store[key] = store.insights[0][key];
            }
          });

          const _geojson = JSON.stringify({
            geometry: {
              coordinates: [store.longitude, store.latitude],
              type: "Point",
            },
            id,
            properties: store,
            type: "Feature",
          });
          delete store.latitude;
          delete store.longitude;
          delete store.geometry;
          delete store.geometry_mid;
          delete store.geometry_low;
          delete store.deleted_at;
          Object.keys(store).forEach((key) => {
            if (store[key] === null || store[key] === undefined) {
              delete store[key];
            }
          });
          return {
            ...store,
            is_selected: false,
            _geojson,
          };
        });
        const storesCsv = unparse(data);
        if (storesCsv) {
          if (!shouldCreateLayer) {
            const storeLayers = getState().keplerGl[
              `view${view?.id}`
            ]?.visState?.layers?.filter((layer) =>
              layer.config.dataId.includes("delivery_entities")
            );

            dispatch(
              deleteGeoLayer({
                layersToDelete: storeLayers.map((layer) => layer.config.dataId),
              })
            );
          }
          let newId = "delivery_entities";

          newId = newId + new Date().getTime();
          const info = {
            label: groupNames.length
              ? groupNames.join(" vs ")
              : "Product Search",
            id: newId,
          };
          const deliveryEntityConfig = defaultDeliveryEntityLayerConfig(
            info,
            colorBy
          );
          const processedGeojson = processCsvData(storesCsv);
          dispatch(
            wrapTo(
              mapId || `view${view?.id}`,
              appendVisData({
                // datasets
                datasets: {
                  info,
                  data: processedGeojson,
                },
                // option
                options: {
                  keepExistingConfig: true,
                },
                config: deliveryEntityConfig,
              })
            )
          );

          if (!mapId) {
            dispatch(
              createGeoLayer({
                name: info.label,
                geolayer_details: data.map((item) => ({
                  geolayer_detail_generic: {
                    geometry: JSON.stringify(
                      (item._geojson &&
                        (typeof item._geojson === "string"
                          ? JSON.parse(item._geojson)
                          : item._geojson
                        ).geometry) ||
                        item.geometry
                    ),
                    properties: JSON.stringify(item),
                  },
                })),
                color_by: colorBy,
                config: {
                  info,
                },
                view_id: view.id,
              })
            );
          }
        }
      }

      return stores;
    }
  }
);

// REDUCER
const deliverySlice = createSlice({
  name: "delivery",
  initialState,
  reducers: {},
  extraReducers: {
    [showDeliveryEntites.pending]: (state, action) => {
      state.loading.stores = true;
    },
    [showDeliveryEntites.fulfilled]: (state, action) => {
      state.loading.stores = false;
      toast(`Product locations loaded`, {
        autoClose: true,
        type: toast.TYPE.SUCCESS,
      });
    },
    [showDeliveryEntites.rejected]: (state, action) => {
      state.loading.stores = false;
      toast(`Product locations failed to load`, {
        autoClose: true,
        type: toast.TYPE.ERROR,
      });
    },
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = deliverySlice.actions;

export default deliverySlice.reducer;
