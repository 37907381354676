import { assign, keys, pick } from "lodash";

export class ImportHistory {
  id!: number;
  created_at!: string;
  organization_id!: number;
  restored_at?: string;
  user_id?: number;
  status?: string;
  is_restored?: boolean;
  original_filename?: string;
  original_file_key?: string;
  processed_file_key?: string;
  process_id?: string;

  constructor(candidate: any) {
    assign(this, pick(candidate, keys(this)));
  }

  buildForPost() {
    let newStore = JSON.parse(JSON.stringify(this));
    delete newStore.id;
    newStore = new ImportHistory(newStore);
    return newStore;
  }
}
