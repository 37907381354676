export const loginWithFacebook = async () => {
  // login with facebook then authenticate with the API to get a JWT auth token
  return new Promise((resolve) => {
    window.FB.login(
      (response) => {
        resolve(response);
      },
      {
        scope:
          "pages_show_list,public_profile,pages_manage_ads,leads_retrieval,email,ads_management,pages_read_engagement,pages_read_user_content",
      }
    );
  });
};

export const logoutOfFacebook = async () => {
  return new Promise((resolve) => {
    const userId = window.FB.getUserID();
    window.FB.api(`/${userId}/permissions`, "delete", null, () => {
      window.FB.logout();
      resolve(true);
    });
  });
};

const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

export function initFacebookSdk() {
  return new Promise((resolve) => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: facebookAppId,
        cookie: true,
        xfbml: true,
        version: "v17.0",
      });

      // auto authenticate with the api if already logged in with facebook
      window.FB.getLoginStatus(({ authResponse }) => {
        if (authResponse) {
          resolve(authResponse);
        } else {
          resolve();
        }
      });
    };

    // load facebook sdk script
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.onerror = function () {
        resolve();
      };
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  });
}
