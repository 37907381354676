import React, { useEffect, useState } from "react";
import { Button, HStack, VStack } from "@chakra-ui/react";
import { DataType } from "ka-table/enums";
import { ICellTextProps } from "ka-table/props";
import ReactStars from "react-stars";
import { EntityTable } from "../../components/entity-table/EntityTable";
import { Store } from "../../domain/Store";
import { RESOURCES } from "../../constants/user-constants";
import { updateCellValue, updateEditorValue } from "ka-table/actionCreators";
import {
  getStoreReviews,
  postStoreReview,
  putStoreReview,
} from "../../services/api.service";
import { DownloadIcon } from "@chakra-ui/icons";
import UploadBtn from "../../components/uploadBtn";
import { IoCloudUpload } from "react-icons/io5";
import { StoreReview } from "../../domain/StoreReview";
import { connect } from "react-redux";

type StoreReviewsModalProps = {
  onReviewsChange?: (r: StoreReview[]) => void;
  store?: Store;
  outsideReviews?: StoreReview[];
  userResources: number[];
  accessToken: string;
};

const reviewSchema = [
  {
    key: "title",
    label: "title",
    type: DataType.String,
  },
  { key: "comment", label: "comment", type: DataType.String },
  { key: "rating", label: "rating", type: DataType.Number },
];

const RatingStars = (props: ICellTextProps & { isEditing: boolean }) => {
  const { value, dispatch, rowKeyValue, column, isEditing } = props;
  return (
    <ReactStars
      count={5}
      edit={true}
      value={value}
      onChange={(val) => {
        const action = isEditing
          ? updateEditorValue(rowKeyValue, column.key, val)
          : updateCellValue(rowKeyValue, column.key, val);
        dispatch(action);
      }}
    />
  );
};

function StoreReviews({
  store,
  userResources,
  accessToken,
  outsideReviews,
  onReviewsChange,
}: StoreReviewsModalProps): JSX.Element {
  const [reviews, setReviews] = useState<StoreReview[]>(outsideReviews || []);

  useEffect(() => {
    if (onReviewsChange) onReviewsChange(reviews);
  }, [onReviewsChange, reviews]);

  useEffect(() => {
    if (store) {
      getStoreReviews(store.id, accessToken).then((res) =>
        setReviews(res || [])
      );
    }
  }, [accessToken, store]);

  return (
    <VStack>
      {userResources?.includes(RESOURCES.REVIEWS_CREATE) && (
        <HStack>
          <UploadBtn
            entityName="Reviews"
            postItem={
              store
                ? async (review: StoreReview) =>
                    postStoreReview(store.id, review, accessToken)
                : undefined
            }
            Icon={IoCloudUpload}
            isDisabled={!userResources?.includes(RESOURCES.REVIEWS_CREATE)}
            schema={reviewSchema.map((item) => ({
              key: item.key,
              title: item.label,
              dataType: item.type,
            }))}
            onFinish={(goodItems: { item: StoreReview; original: any }[]) => {
              if (store) {
                getStoreReviews(store.id, accessToken).then((res) =>
                  setReviews(res || [])
                );
              } else {
                setReviews((oldReviews) => [
                  ...(oldReviews || []),
                  ...(goodItems?.map((g) => g.item) || []),
                ]);
              }
            }}
            defaultSheet="Reviews"
            validator={undefined}
            proxyParams={undefined}
          />
          <Button
            // onClick={generateTemplate}
            // isDisabled={generatingTemplate}
            // isLoading={generatingTemplate}
            leftIcon={<DownloadIcon />}
            id="reviews_excel_template"
          >
            Excel template
          </Button>
          <Button
            // onClick={() => generateTemplate(true)}
            // isDisabled={generatingTemplate}
            // isLoading={generatingTemplate}
            leftIcon={<DownloadIcon />}
            id="reviews_csv_template"
          >
            CSV template
          </Button>
        </HStack>
      )}
      <EntityTable
        initialTableProps={{
          rowKeyField: "uid",
          columns: [
            {
              title: "Title",
              dataType: DataType.String,
              key: "title",
            },
            {
              title: "Comment",
              key: "comment",
            },
            {
              title: "Rating",
              key: "rating",
              Render: (p: any) => <RatingStars {...p} />,
            },
            {
              title: "Date",
              key: "date",
              dataType: DataType.Date,
              isEditable: false,
            },
          ],
        }}
        fetch={async () => {
          if (store) {
            const res = await getStoreReviews(store.id, accessToken);
            setReviews(res || []);
            return res;
          } else {
            return reviews || [];
          }
        }}
        postItem={
          userResources?.includes(RESOURCES.REVIEWS_CREATE)
            ? async (newSale: StoreReview) => {
                const saleToPost = new StoreReview(newSale);
                if (store) {
                  try {
                    const newReview = await postStoreReview(
                      `${store.id}`,
                      saleToPost,
                      accessToken
                    );
                    setReviews((oldReviews) => [...oldReviews, newReview]);
                  } catch (error) {
                    console.log(error);
                  }
                } else {
                  saleToPost.uid = `${reviews.length}`;
                  setReviews((oldReviews) => [...oldReviews, saleToPost]);
                }
              }
            : undefined
        }
        putItem={
          userResources?.includes(RESOURCES.REVIEWS_CREATE)
            ? async (storeId: string, updatedReview: StoreReview) => {
                if (updatedReview.id) {
                  try {
                    await putStoreReview(updatedReview, accessToken);
                  } catch (error) {
                    console.log(error);
                  }
                } else {
                  setReviews((oldReviews) => {
                    const newReviews = [...oldReviews];
                    const index = newReviews.findIndex(
                      (r) => r.uid === updatedReview.uid
                    );
                    newReviews.splice(index, 1, updatedReview);
                    return newReviews;
                  });
                }
              }
            : undefined
        }
      />
    </VStack>
  );
}

export default connect((state: any) => ({
  accessToken: state.app.accessToken,
  userResources: state.app.currentUser?.resources,
}))(StoreReviews);
