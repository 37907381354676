import { Box, Button, Img } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router";
import { selectMenus } from "../app/appSlice";
import { useAppSelector } from "../app/store";
import menuIconGrowth from "../assets/images/menu-icon-growth.png";
import { trackClick } from "../services/tracking.service";

export default function GrowthBtn() {
  const location = useLocation();
  const navigate = useNavigate();
  const menus = useAppSelector(selectMenus);
  const growthMenu = menus?.find((m: any) => m.id === "growth");
  return !location.pathname?.includes("storelocator") && !!growthMenu ? (
    <Box>
      <Button
        id="growth-btn"
        className="growth-btn"
        variant="link"
        as="div"
        color="blue.900"
        padding="12px 10px"
        borderRadius={20}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        leftIcon={
          <Img
            src={menuIconGrowth}
            height="1rem"
            width="1rem"
            id="growth-btn"
            className="growth-btn"
            filter={
              location.pathname.includes("growth")
                ? "invert(48%) sepia(45%) saturate(4075%) hue-rotate(177deg) brightness(97%) contrast(102%)"
                : undefined
            }
          />
        }
        isActive={location.pathname.includes("growth")}
        iconSpacing={0}
        size="xs"
        _hover={{ textDecoration: "underline" }}
        onClick={() =>
          trackClick("growth-btn", "Growth", () => {
            const child = growthMenu?.children?.[0];
            child?.embedUrl
              ? navigate(
                  `${child.embedUrl ? "embed/" : ""}growth-${child.id}`,
                  {
                    state: {
                      from: location,
                      url: child.embedUrl,
                    },
                  }
                )
              : child?.component
              ? navigate(`${child.component}/growth-${child.id}`)
              : navigate(`not_available/growth-${child.id}`);
          })
        }
      >
        Growth
      </Button>
    </Box>
  ) : (
    <></>
  );
}
