import { get, post } from "axios";
import { toast } from "react-toastify";
import {
  base_url,
  create_anonymous_token,
  create_user,
  login as loginMethod,
  login_with_token,
} from "../constants/serviceConfig";

export async function login(username, password) {
  try {
    let url = base_url;
    url = url + loginMethod;
    let params = {
      username,
      password,
    };
    const result = await post(url, params, {
      headers: { "Content-Type": "application/json" },
    });

    return result?.data;
  } catch (error) {
    toast.error(error.message);
    return { data: { access_token: null } };
  }
}

export async function createUser(
  username,
  password,
  displayName,
  role,
  accessToken
) {
  try {
    let url = base_url;
    url = url + create_user;
    let params = {
      username,
      password,
      display_name: displayName,
      roles: role ? [role] : [],
    };
    const result = await post(url, params, {
      headers: {
        "Content-Type": "application/json",
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      },
    });

    return result?.data;
  } catch (error) {
    toast.error(error.message);
    return undefined;
  }
}

export async function createAnonymousToken(token) {
  try {
    let url = base_url;
    url = url + create_anonymous_token;
    const result = await get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return result.data;
  } catch (error) {
    toast.error(error.message);
    return { data: { content: { data: [], type: "" } } };
  }
}

export async function loginWithToken(token) {
  try {
    let url = base_url;
    url = url + login_with_token;
    let params = {
      token,
    };
    const result = await post(url, params, {
      headers: { "Content-Type": "application/json" },
    });

    return result.data;
  } catch (error) {
    toast.error(error.message);
    return { data: { content: { data: [], type: "" } } };
  }
}
