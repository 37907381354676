import { Button } from "@chakra-ui/button";
import { NumberInput, NumberInputField } from "@chakra-ui/number-input";
import {
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputStepper,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { datetime, Frequency, Options, RRule, rrulestr } from "rrule";
import { ALL_WEEKDAYS } from "rrule/dist/esm/weekday";
import MessageModalContext from "../contexts/MessageModalContext";
import { dayNames, getOrdinal } from "../utils/stringUtils";

type Props = {
  options?: Options;
  rrule?: string;
  textInput?: string;
  startDateProp?: Date;
  endDateProp?: Date;
  allowCustom?: boolean;
  onChange?: (startDate: Date, endDate: Date, recurringRule?: string) => void;
};

const parseRRule = (
  options?: Options,
  rrule?: string,
  textInput?: string,
  startDateProp?: Date
) => {
  let newRule: RRule;
  if (options) {
    newRule = new RRule(options);
  } else if (rrule) {
    newRule = rrulestr(rrule);
  } else if (textInput) {
    newRule = RRule.fromText(textInput);
  } else {
    newRule = new RRule();
  }
  const now = startDateProp || new Date();
  const dtstart =
    newRule.origOptions.dtstart ||
    datetime(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0);
  newRule = new RRule({
    ...newRule.origOptions,
    dtstart,
    tzid: DateTime.fromJSDate(dtstart).zoneName,
  });
  return newRule;
};

export const RecurringEventForm = ({
  options,
  rrule,
  textInput,
  onChange,
  startDateProp,
  endDateProp,
  allowCustom = true,
}: Props) => {
  const messageModalContext = useContext(MessageModalContext);
  // @ts-ignore
  const aryIannaTimeZones = Intl.supportedValuesOf("timeZone");
  const [ruleOptions, setRuleOptions] = useState<Partial<Options>>(
    parseRRule(options, rrule, textInput, startDateProp).origOptions
  );
  const [selectedRepetition, setSelectedRepetition] = useState<string>(
    !!(options || rrule || textInput) ? new RRule(ruleOptions).toString() : ""
  );
  const [startDate, setStartDate] = useState(
    () =>
      startDateProp ||
      DateTime.now()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toJSDate()
  );
  const [endDate, setEndDate] = useState(
    endDateProp ||
      DateTime.now()
        .plus({ month: 1 })
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toJSDate()
  );

  const StartDateInput = ({
    onClick,
  }: {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  }) => {
    return (
      <Button onClick={onClick} variant="outline" fontWeight="normal">
        {`${DateTime.fromJSDate(startDate || new Date()).toFormat(
          "LLL dd, yyyy"
        )}`}
      </Button>
    );
  };

  const EndDateInput = ({
    onClick,
  }: {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  }) => {
    return (
      <Button
        onClick={onClick}
        variant="outline"
        fontWeight="normal"
        colorScheme={
          DateTime.fromJSDate(endDate).diff(
            DateTime.fromJSDate(
              ruleOptions.dtstart ||
                DateTime.now()
                  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                  .toJSDate()
            )
          ).milliseconds < 0
            ? "red"
            : undefined
        }
      >
        {endDate
          ? `${DateTime.fromJSDate(endDate).toFormat("LLL dd, yyyy")}`
          : "Select end date"}
      </Button>
    );
  };

  useEffect(() => {
    if (onChange) {
      const rrule = new RRule(ruleOptions);
      onChange(
        ruleOptions.dtstart || new Date(),
        endDate,
        selectedRepetition ? rrule.toString() : undefined
      );
    }
  }, [endDate, ruleOptions, selectedRepetition]);

  const CustomRecurrence = ({ options }: { options: Partial<Options> }) => {
    const [recurrenceOptions, setRecurrenceOptions] = useState(options);

    useEffect(() => {
      setRuleOptions(recurrenceOptions);
      setSelectedRepetition(new RRule(recurrenceOptions).toString());
    }, [recurrenceOptions]);

    return (
      <VStack spacing={5}>
        <FormControl>
          <FormLabel>Repeat every</FormLabel>
          <HStack>
            <NumberInput
              min={1}
              onChange={(str, num) =>
                setRecurrenceOptions((oldValue) => ({
                  ...oldValue,
                  interval: num,
                }))
              }
              allowMouseWheel
            >
              <NumberInputField
                value={recurrenceOptions.interval}
                onChange={(e) =>
                  setRecurrenceOptions((oldValue) => ({
                    ...oldValue,
                    interval: +e.target.value,
                  }))
                }
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Select
              value={recurrenceOptions.freq}
              onChange={(e) =>
                setRecurrenceOptions((oldValue) => ({
                  ...oldValue,
                  freq: +e.target.value,
                }))
              }
            >
              {RRule.FREQUENCIES.filter((f) =>
                ["YEARLY", "MONTHLY", "WEEKLY", "DAILY"].includes(f)
              ).map((v) => (
                <option value={RRule[v]}>{v}</option>
              ))}
            </Select>
          </HStack>
        </FormControl>
        {recurrenceOptions.freq === 2 && (
          <FormControl>
            <FormLabel>Repeat on</FormLabel>
            <CheckboxGroup>
              <HStack>
                {ALL_WEEKDAYS.map((day) => (
                  <Checkbox
                    key={RRule[day].weekday}
                    isChecked={(
                      (recurrenceOptions.byweekday as number[]) || []
                    ).includes(RRule[day].weekday)}
                    onChange={(e) =>
                      setRecurrenceOptions((oldValue) => ({
                        ...oldValue,
                        byweekday: !e.target.checked
                          ? ((oldValue.byweekday as number[]) || []).filter(
                              (d) => d !== RRule[day].weekday
                            )
                          : Array.from(
                              new Set([
                                ...((oldValue.byweekday as number[]) || []),
                                RRule[day].weekday,
                              ])
                            ),
                      }))
                    }
                  >
                    {day}
                  </Checkbox>
                ))}
              </HStack>
            </CheckboxGroup>
          </FormControl>
        )}
        <Text>{new RRule(recurrenceOptions as Partial<Options>).toText()}</Text>
      </VStack>
    );
  };

  const weekday = [[...ALL_WEEKDAYS].pop()!, ...ALL_WEEKDAYS][
    startDate.getDay()
  ];
  const dailyRule = new RRule({
    dtstart: startDate,
    tzid: ruleOptions.tzid || DateTime.fromJSDate(startDate).zoneName,
    freq: Frequency.DAILY,
    interval: 1,
  });
  const weeklyRule = new RRule({
    dtstart: startDate,
    tzid: ruleOptions.tzid || DateTime.fromJSDate(startDate).zoneName,
    freq: Frequency.WEEKLY,
    byweekday: RRule[weekday],
    interval: 1,
  });
  const monthlyRule = new RRule({
    dtstart: startDate,
    tzid: ruleOptions.tzid || DateTime.fromJSDate(startDate).zoneName,
    freq: Frequency.MONTHLY,
    byweekday: RRule[weekday].nth(Math.ceil(startDate.getDate() / 7)),
    interval: 1,
  });
  const yearlyRule = new RRule({
    dtstart: startDate,
    tzid: ruleOptions.tzid || DateTime.fromJSDate(startDate).zoneName,
    freq: Frequency.YEARLY,
    bymonth: [startDate.getMonth() + 1],
    bymonthday: startDate.getDate(),
    interval: 1,
  });
  const weekdaysRule = new RRule({
    dtstart: startDate,
    tzid: ruleOptions.tzid || DateTime.fromJSDate(startDate).zoneName,
    freq: Frequency.WEEKLY,
    byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
    interval: 1,
  });

  const changeRepetition: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (e.target.value !== "Custom") setSelectedRepetition(e.target.value);
    switch (e.target.value) {
      case "":
        setRuleOptions({ dtstart: startDate });
        break;
      case dailyRule.toString():
        setRuleOptions(dailyRule.origOptions);
        break;
      case weeklyRule.toString():
        setRuleOptions(weeklyRule.origOptions);
        break;
      case monthlyRule.toString():
        setRuleOptions(monthlyRule.origOptions);
        break;
      case yearlyRule.toString():
        setRuleOptions(yearlyRule.origOptions);
        break;
      case weekdaysRule.toString():
        setRuleOptions(weekdaysRule.origOptions);
        break;
      case "Custom":
        const newOptions = {
          dtstart: startDate,
          tzid: ruleOptions.tzid || DateTime.fromJSDate(startDate).zoneName,
          freq: Frequency.YEARLY,
          interval: 1,
        };
        setRuleOptions(newOptions);
        messageModalContext.showModal({
          title: "Custom recurrence",
          message: <CustomRecurrence options={newOptions} />,
        });
        break;
    }
  };

  return (
    <VStack>
      <HStack>
        <DatePicker
          onChange={(date) => {
            if (date) {
              date.setHours(0);
              date.setMinutes(0);
              date.setSeconds(0);
              date.setMilliseconds(0);
              setStartDate(date);
              if (ruleOptions) {
                setRuleOptions((oldValue) => ({
                  ...oldValue,
                  dtstart: date,
                }));
              }
            }
          }}
          selected={startDate}
          // @ts-ignore
          customInput={<StartDateInput />}
        />
        <Text>to</Text>
        <DatePicker
          onChange={(date) => {
            if (date) {
              date.setHours(0);
              date.setMinutes(0);
              date.setSeconds(0);
              date.setMilliseconds(0);
              setEndDate(date);
            }
          }}
          selected={endDate}
          minDate={startDate}
          // @ts-ignore
          customInput={<EndDateInput />}
        />
      </HStack>
      <HStack>
        <Select value={selectedRepetition} onChange={changeRepetition}>
          <option value="">Does not repeat</option>
          <option value={dailyRule.toString()}>Daily</option>
          <option value={weeklyRule.toString()}>
            Weekly on {dayNames[startDate.getDay()]}
          </option>
          <option value={monthlyRule.toString()}>
            Monthly on the {getOrdinal(Math.ceil(startDate.getDate() / 7))}{" "}
            {dayNames[startDate.getDay()]}
          </option>
          <option value={yearlyRule.toString()}>
            Annually on {startDate.toLocaleString("en-US", { month: "long" })}{" "}
            {startDate.getDate()}
          </option>
          <option value={weekdaysRule.toString()}>
            Every weekday (Monday to Friday)
          </option>
          {![
            dailyRule.toString(),
            weeklyRule.toString(),
            monthlyRule.toString(),
            yearlyRule.toString(),
            weekdaysRule.toString(),
            new RRule({ dtstart: startDate }).toString(),
          ].includes(new RRule(ruleOptions).toString()) && (
            <option value={new RRule(ruleOptions).toString()}>
              {new RRule(ruleOptions).toText()}
            </option>
          )}
          {allowCustom && <option value="Custom">Custom...</option>}
        </Select>
        <Select
          defaultValue={
            DateTime.fromJSDate(startDate || new Date()).zoneName || undefined
          }
          value={ruleOptions.tzid || undefined}
          onChange={(e) => {
            setRuleOptions((oldValue) => ({
              ...oldValue,
              tzid: e.target.value,
            }));
          }}
        >
          {aryIannaTimeZones.map((timeZone: string) => (
            <option>{timeZone}</option>
          ))}
        </Select>
      </HStack>
    </VStack>
  );
};
