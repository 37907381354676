import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Switch,
  Text,
  Button,
  Heading,
  Stack,
  Tag,
  TagLabel,
  IconButton,
  VStack,
  FormControl,
  FormLabel,
  HStack,
  Select,
  FormHelperText,
} from "@chakra-ui/react";
import { connect, useSelector } from "react-redux";
import MultiSelectFormControl, {
  RequiredAsterisk,
} from "../../components/MultiSelectFormControl";
import LimitationsMessage from "../../components/LimitationsMessage";
import { AudienceSelection } from "./audience-selection";
import { FormProvider, useForm } from "react-hook-form";
import { getProperties } from "../../services/api.service";
import MessageModalContext from "../../contexts/MessageModalContext";
import { RESOURCES } from "../../constants/user-constants";
import { camelCaseWords } from "../../utils/stringUtils";
import { CloseIcon } from "@chakra-ui/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { fetchAudience } from "./audienceSlice";
import { setCurrentUser } from "../../app/appSlice";
import { deepEqual } from "../../utils/compareUtils";
import { DateTime } from "luxon";
import { getInsights } from "../../services/insights.service";
import InsightsSelector from "../insights/InsightsSelector";
import { useAppSelector } from "../../app/store";
import {
  selectSelectedAudiences,
  selectSelectedStates,
} from "../map/keplerReducer";

var pluralize = require("pluralize");

const _LocationFilters = (props) => {
  const {
    userIsInDemo,
    orgConfig,
    states,
    accessToken,
    getAudience,
    activeView,
    currentUser,
    setUser,
    isLoading,
    userResources,
    onClose,
  } = props;
  const selectedStates = useAppSelector(selectSelectedStates);
  const selectedAudiences = useAppSelector(selectSelectedAudiences);

  const formMethods = useForm();
  const { handleSubmit, register, formState } = formMethods;

  const [selectedItems, changeSelectedItems] = useState({
    state: selectedStates,
    nationality: selectedAudiences.reduce((acc, aud) => ({ [aud]: true }), {}),
  });
  const [zoneLevel] = useState("zcta");
  const [savedAudiences, setSavedAudiences] = useState([]);
  const [indexType] = useState("aggregate");
  const [countyOptions, setCountyOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [insightsToColorBy, setInsightsToColorBy] = useState();
  const [insightsToBorderBy, setInsightsToBorderBy] = useState();
  const [zipcodeInsightOptions, setZipcodeInsightOptions] = useState();

  const modalsContext = useContext(MessageModalContext);

  const statesLabel = orgConfig?.statesNameReplacement || "States";
  const zipcodeLabel = orgConfig?.zipcodeNameReplacement || "Zipcode";
  const storeLabel = orgConfig?.storeNameReplacement || "Store";

  const clearForm = () => {
    changeSelectedItems({});
  };

  const fillForm = (savedAudience) => {
    changeSelectedItems(savedAudience?.selectedItems);
  };

  const getPropertiesForStates = useCallback(
    async (newStates) => {
      if (!newStates?.length) {
        return;
      }
      const properties = await getProperties(accessToken, "for_states", {
        states: newStates,
      });
      const newCountyOptions =
        properties?.find((properties) => properties.name === "county")?.value ||
        [];
      const newCityOptions =
        properties?.find((properties) => properties.name === "city")?.value ||
        [];
      setCountyOptions(newCountyOptions);
      setCityOptions(newCityOptions);
      changeSelectedItems((oldValue) => ({
        ...oldValue,
        counties: (oldValue.counties || []).filter(
          (county) =>
            !!newCountyOptions.find((newCounty) => newCounty === county)
        ),
        cities: (oldValue.cities || []).filter(
          (city) => !!newCityOptions.find((newCity) => newCity === city)
        ),
      }));
    },
    [accessToken]
  );

  useEffect(() => {
    const savedAudiencesStr = localStorage.getItem("savedAudiences");
    if (savedAudiencesStr) {
      try {
        const _savedAudiences = JSON.parse(savedAudiencesStr);
        setSavedAudiences(_savedAudiences || []);
        if (_savedAudiences?.length) {
          getPropertiesForStates(_savedAudiences[0].selectedItems.state || []);
          fillForm(_savedAudiences[0]);
        }
      } catch (e) {}
    }
  }, [getPropertiesForStates]);

  useEffect(() => {
    localStorage.setItem(
      "savedAudiences",
      JSON.stringify(savedAudiences || [])
    );
  }, [savedAudiences]);

  useEffect(() => {
    getInsights(accessToken, { level: "zipcode" }, "spec").then(
      setZipcodeInsightOptions
    );
  }, [accessToken]);

  const handleSelect = async (data, event, savedAudience) => {
    const insightParams = zipcodeInsightOptions
      ?.filter((op) =>
        Object.keys(selectedItems?.insights || {})
          .filter((key) => !!selectedItems.insights[key])
          .includes(`${op.id}`)
      )
      ?.reduce((acc, op) => {
        const queryParams = op.endpoint
          ?.split("?")?.[1]
          ?.split("&")
          .filter((param) => param.startsWith("q"))
          .map((param) => param.split("=")[1]);
        return [...new Set([...acc, ...(queryParams || [])])];
      }, []);
    const wrappedResult = await getAudience({
      indexType: savedAudience?.indexType || indexType,
      zoneLevel: savedAudience?.zoneLevel || zoneLevel,
      selectedItems: {
        nationality: Object.keys(
          (savedAudience?.selectedItems || selectedItems).nationality || {}
        )
          ?.filter(
            (key) =>
              !!((savedAudience?.selectedItems || selectedItems).nationality ||
                {})[key]
          )
          ?.map((item) => ({
            id: item,
            title: camelCaseWords(item),
          })),
        state: (savedAudience?.selectedItems || selectedItems).state?.map(
          (item) => ({
            id: item,
            title: states?.find((state) => state.abbr === item)?.name,
          })
        ),
        counties: (savedAudience?.selectedItems || selectedItems).counties,
        cities: (savedAudience?.selectedItems || selectedItems).cities,
        zipcodes: (savedAudience?.selectedItems || selectedItems).zipcodes?.map(
          (item) => ({ id: item })
        ),
        otherFilters: (savedAudience?.selectedItems || selectedItems)
          .otherFilters,
        dynamicFilters: Object.values(
          (savedAudience?.selectedItems || selectedItems).dynamicFilters || {}
        )
          .flat()
          .map((val) => JSON.stringify(val)),
      },
      activeView,
      shouldCreateLayer: !!(savedAudience?.selectedItems || selectedItems)
        ?.shouldCreateLayer,
      insightParams,
      colorBy: zipcodeInsightOptions
        ?.find((option) => option.id === +insightsToColorBy)
        ?.endpoint?.split("?")?.[1]
        ?.split("&")
        .filter((param) => param.startsWith("q"))
        .map((param) => param.split("=")[1])
        ?.join("_&_"),
      colorBySecondary: zipcodeInsightOptions
        ?.find((option) => option.id === +insightsToBorderBy)
        ?.endpoint?.split("?")?.[1]
        ?.split("&")
        .filter((param) => param.startsWith("q"))
        .map((param) => param.split("=")[1])
        ?.join("_&_"),
    });
    const result = await unwrapResult(wrappedResult);
    if (result?.errors) {
      modalsContext.showModal({
        title: "Could not fetch your audience",
        message: result.errors,
      });
    } else {
      setSavedAudiences((oldValue) => {
        const newList = [...oldValue];
        const newItem = {
          selectedItems,
          indexType,
          zoneLevel,
        };
        const foundIdentical = newList
          .map((item) => {
            const mapped = { ...item };
            delete mapped.date;
            return mapped;
          })
          .find((item) => deepEqual(item, newItem));
        newItem.date = new Date();
        return foundIdentical
          ? oldValue
          : [newItem, ...oldValue].slice(0, Math.min(oldValue.length + 1, 5));
      });
      setUser({
        ...currentUser,
        num_requests: (currentUser.num_requests || 0) + 1,
      });
    }
    if (onClose) onClose();
    return true;
  };
  return (
    <Flex direction="column">
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleSelect)}>
          <Accordion w="100%" defaultIndex={0} allowToggle>
            <AccordionItem>
              <AccordionButton fontSize={18} fontWeight="bold">
                Location filters
                <RequiredAsterisk />
                <AccordionIcon ml="auto" />
              </AccordionButton>
              <AccordionPanel>
                <LimitationsMessage
                  includedInMessage={[
                    statesLabel,
                    "county",
                    "city",
                    zipcodeLabel,
                  ]}
                />

                <MultiSelectFormControl
                  label={statesLabel}
                  required
                  inputProps={{
                    ...register("states", {
                      required: false,
                      validate: () =>
                        !!selectedItems.state?.length ||
                        `Please select at least one ${pluralize(
                          statesLabel,
                          1
                        )}`,
                    }),
                  }}
                  getOptionValue={(item) => item.label}
                  disabledReason={`Purchase plan to select more ${statesLabel}`}
                  value={selectedItems.state?.map((key) => ({
                    key,
                    label:
                      states?.find((state) => state.abbr === key)?.name || "",
                  }))}
                  isDisabled={
                    (userIsInDemo && selectedItems.state?.length >= 1) ||
                    orgConfig?.limitedStates?.length ===
                      (selectedItems.state || []).length
                  }
                  onChange={(selection) => {
                    const selectedAll = selection?.find(
                      (item) => item.key === "all"
                    );
                    const newStates = selectedAll
                      ? orgConfig?.limitedStates ||
                        states?.map((state) => state.abbr)
                      : (selection || []).map((variable) => variable.key);
                    changeSelectedItems((oldValues) => ({
                      ...oldValues,
                      state: newStates,
                    }));
                    getPropertiesForStates(newStates);
                  }}
                  autocompleteProps={{
                    options: [{ key: "all", label: "All" }].concat(
                      orgConfig?.limitedStates?.map((variable) => ({
                        key: variable,
                        label:
                          states?.find((state) => state.abbr === variable)
                            ?.name || "",
                      })) ||
                        states?.map((state) => ({
                          key: state.abbr,
                          label: state.name,
                        }))
                    ),
                    exclude: selectedItems.state?.map((key) => ({
                      key,
                      label:
                        states?.find((state) => state.abbr === key)?.name || "",
                    })),
                    focusInputOnSuggestionClick: true,
                  }}
                />
                <MultiSelectFormControl
                  label="Counties"
                  required={
                    !!orgConfig?.limitedCounties?.length &&
                    !selectedItems.counties?.length
                  }
                  inputProps={{
                    ...register("Counties", {
                      required: false,
                      validate: () =>
                        !orgConfig?.limitedCounties?.length ||
                        !!selectedItems.counties?.length ||
                        "Please select at least one county",
                    }),
                  }}
                  disabledReason="Purchase plan to select more counties"
                  value={selectedItems.counties}
                  isDisabled={
                    (userIsInDemo && selectedItems.counties?.length >= 10) ||
                    orgConfig?.limitedCounties?.length ===
                      (selectedItems.counties || []).length
                  }
                  onChange={(selection) => {
                    changeSelectedItems((oldValues) => ({
                      ...oldValues,
                      counties: selection,
                    }));
                  }}
                  autocompleteProps={{
                    options: orgConfig?.limitedCounties || countyOptions,
                    exclude: selectedItems.counties,
                    focusInputOnSuggestionClick: true,
                  }}
                />
                <MultiSelectFormControl
                  label="Cities"
                  required={
                    orgConfig?.limitedCities?.length &&
                    !selectedItems.cities?.length
                  }
                  disabledReason="Purchase plan to select more cities"
                  value={selectedItems.cities}
                  isDisabled={
                    (userIsInDemo && selectedItems.cities?.length >= 10) ||
                    orgConfig?.limitedCities?.length ===
                      (selectedItems.cities || [])?.length
                  }
                  onChange={(selection) => {
                    changeSelectedItems((oldValues) => ({
                      ...oldValues,
                      cities: selection,
                    }));
                  }}
                  inputProps={{
                    ...register("Cities", {
                      required: false,
                      validate: () =>
                        !orgConfig?.limitedCities?.length ||
                        !!selectedItems.cities?.length ||
                        "Please select at least one city",
                    }),
                  }}
                  autocompleteProps={{
                    options: orgConfig?.limitedCities || cityOptions,
                    exclude: selectedItems.cities,
                    focusInputOnSuggestionClick: true,
                  }}
                />
                <MultiSelectFormControl
                  label={pluralize(zipcodeLabel, 2)}
                  required={
                    orgConfig?.limitedZipcodes?.length &&
                    !selectedItems.zipcodes?.length
                  }
                  disabledReason={`Purchase plan to select more ${pluralize(
                    zipcodeLabel,
                    2
                  )}`}
                  value={selectedItems.zipcodes}
                  isDisabled={
                    (userIsInDemo && selectedItems.zipcodes?.length >= 10) ||
                    orgConfig?.limitedZipcodes?.length ===
                      (selectedItems.zipcodes || []).length
                  }
                  onChange={(selection) => {
                    const selectedAll = selection?.find(
                      (item) => item === "All"
                    );
                    changeSelectedItems((oldValues) => ({
                      ...oldValues,
                      zipcodes: selectedAll
                        ? orgConfig?.limitedZipcodes
                        : selection,
                    }));
                  }}
                  inputProps={{
                    ...register(pluralize(zipcodeLabel, 2), {
                      required: false,
                      validate: () =>
                        !orgConfig?.limitedZipcodes?.length ||
                        !!selectedItems.zipcodes?.length ||
                        `Please select at least one ${pluralize(
                          zipcodeLabel,
                          1
                        )}`,
                    }),
                  }}
                  autocompleteProps={{
                    options: orgConfig?.limitedZipcodes?.length
                      ? ["All"].concat(orgConfig.limitedZipcodes)
                      : undefined,
                    exclude: selectedItems.zipcodes,
                    focusInputOnSuggestionClick: true,
                  }}
                />
              </AccordionPanel>
            </AccordionItem>
            <AudienceSelection
              selectedItems={selectedItems}
              changeSelectedItems={changeSelectedItems}
              register={register}
              formState={formState}
            />
            <AccordionItem>
              <AccordionButton fontSize={18} fontWeight="bold">
                Insights
                <AccordionIcon ml="auto" />
              </AccordionButton>
              <AccordionPanel>
                <VStack spacing={5}>
                  <FormControl>
                    <FormLabel>Add zipcode insights</FormLabel>
                    <InsightsSelector
                      insightOptions={zipcodeInsightOptions}
                      value={selectedItems?.insights}
                      onChange={(value) =>
                        changeSelectedItems((oldValues) => ({
                          ...oldValues,
                          insights: value,
                        }))
                      }
                    />
                  </FormControl>
                  <HStack>
                    <FormControl>
                      <FormLabel>Fill color by</FormLabel>
                      <FormHelperText>
                        The selected property will be used to fill the color of
                        the {storeLabel}
                      </FormHelperText>
                      <Select
                        value={insightsToColorBy}
                        disabled={
                          Object.keys(selectedItems?.insights || {}).filter(
                            (k) => !!selectedItems?.insights?.[k]
                          ).length === 0
                        }
                        onChange={(event) =>
                          setInsightsToColorBy(event.target.value)
                        }
                      >
                        <option value="" selected>
                          Heritage
                        </option>
                        {zipcodeInsightOptions
                          ?.filter((variable) =>
                            Object.keys(selectedItems?.insights || {})
                              .filter((key) => !!selectedItems.insights[key])
                              .includes(`${variable.id}`)
                          )
                          .map((variable) => (
                            <option key={variable.id} value={variable.id}>
                              {variable.name}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Border color by</FormLabel>
                      <FormHelperText>
                        The selected property will be used to color the border
                        of the {storeLabel}
                      </FormHelperText>
                      <Select
                        value={insightsToBorderBy}
                        disabled={
                          Object.keys(selectedItems?.insights || {}).filter(
                            (k) => !!selectedItems?.insights?.[k]
                          ).length === 0
                        }
                        onChange={(event) =>
                          setInsightsToBorderBy(event.target.value)
                        }
                      >
                        <option value="" selected>
                          Select a property
                        </option>
                        {zipcodeInsightOptions
                          ?.filter((variable) =>
                            Object.keys(selectedItems?.insights || {})
                              .filter((key) => !!selectedItems.insights[key])
                              .includes(`${variable.id}`)
                          )
                          .map((variable) => (
                            <option key={variable.id} value={variable.id}>
                              {variable.name}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  </HStack>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Flex
            direction="row"
            justifyContent="space-between"
            position="absolute"
            right={0}
            bottom={0}
            bg="white"
            p={5}
            w="100%"
            borderTop="1px solid gainsboro"
            zIndex={9}
          >
            <Box>
              <Switch
                isChecked={!!selectedItems.shouldCreateLayer}
                onChange={(e) =>
                  changeSelectedItems((oldValues) => ({
                    ...oldValues,
                    shouldCreateLayer: !oldValues.shouldCreateLayer,
                  }))
                }
              >
                Create new layer
              </Switch>
              <Text fontSize={12} color="darkgray">
                {selectedItems.shouldCreateLayer
                  ? "Your current audience layers will stay the same"
                  : "Your current audience layers will be replaced with this search"}
              </Text>
            </Box>
            <Flex direction="row">
              <Button variant="link" onClick={clearForm}>
                Clear all
              </Button>
              <Button
                type="submit"
                isLoading={isLoading}
                colorScheme="blue"
                disabled={
                  isLoading ||
                  (!userIsInDemo &&
                    !userResources?.includes(RESOURCES.AUDIENCE_SELECT))
                }
                ml={3}
              >
                Show results
              </Button>
            </Flex>
          </Flex>
          {!!savedAudiences?.length && (
            <Flex direction="column" mt={5}>
              <Heading size="md">Search history</Heading>
              <Stack>
                {savedAudiences.map((audience, index) => (
                  <Tag key={index} p={2}>
                    <Flex flexDir="column" flex={1}>
                      <TagLabel mr="auto" fontWeight="bold">{`${Object.keys(
                        audience.selectedItems.nationality
                      )
                        ?.map((item) => camelCaseWords(item))
                        .join(" & ")} in ${audience.selectedItems.state
                        ?.map(
                          (key) =>
                            states?.find((state) => state.abbr === key)?.name
                        )
                        .join(" & ")}`}</TagLabel>
                      {Object.keys(audience.selectedItems || {})
                        .filter(
                          (key) => !["nationality", "state"].includes(key)
                        )
                        .map((key) => (
                          <TagLabel key={key}>
                            {key}: {JSON.stringify(audience.selectedItems[key])}
                          </TagLabel>
                        ))}
                    </Flex>
                    <TagLabel ml={5}>{`${DateTime[
                      typeof audience.date === "string"
                        ? "fromISO"
                        : "fromJSDate"
                    ](audience.date).toLocaleString(
                      DateTime.DATETIME_SHORT
                    )}`}</TagLabel>
                    <Button
                      colorScheme="blue"
                      ml={3}
                      onClick={() => fillForm(audience)}
                    >
                      Use
                    </Button>
                    <IconButton
                      icon={<CloseIcon />}
                      onClick={() => {
                        let newList = [...savedAudiences];
                        newList.splice(index, 1);
                        setSavedAudiences(newList);
                      }}
                    />
                  </Tag>
                ))}
              </Stack>
            </Flex>
          )}
        </form>
      </FormProvider>
    </Flex>
  );
};

export const LocationFilters = connect(
  (state) => {
    return {
      accessToken: state.app.accessToken,
      userIsInDemo: state.app.currentUser.is_demo,
      orgConfig: state.app.orgProperties?.properties,
      activeView: state.mapViews.activeView,
      currentUser: state.app.currentUser,
      isLoading:
        state.mapViews.views[`view${state.mapViews.activeView}`]?.audience
          ?.isLoading,
      userResources: state.app.currentUser.resources,
      educationalAttainmentOptions: (
        state.app.properties?.find(
          (properties) => properties.name === "options_educational_attainment"
        )?.value || []
      ).filter(
        (option) =>
          ![
            "10th grade",
            "11th grade",
            "12th grade, no diploma",
            "5th and 6th grade",
            "7th and 8th grade",
            "9th grade",
            "Nursery to 4th grade",
            "Professional school degree",
            "Some college, 1 or more years, no degree",
            "Some college, less than 1 year",
          ].includes(option)
      ),
      travelTimeToWorkOptions:
        state.app.properties?.find(
          (properties) => properties.name === "options_travel_time_to_work"
        )?.value || [],
      personalIncomeOptions:
        state.app.properties?.find(
          (properties) => properties.name === "options_personal_income"
        )?.value || [],
      genderOptions:
        state.app.properties?.find(
          (properties) => properties.name === "options_gender"
        )?.value || [],
      employmentOptions:
        state.app.properties?.find(
          (properties) => properties.name === "options_employment_status"
        )?.value || [],
      states: (
        state.app.properties?.find((properties) => properties.name === "states")
          ?.value || []
      )
        .map((val) => {
          try {
            return JSON.parse(val.replaceAll("'", '"'));
          } catch (error) {
            return undefined;
          }
        })
        .filter((val) => !!val),
    };
  },
  (dispatch) => ({
    getAudience: (payload) => dispatch(fetchAudience(payload)),
    setUser: (newUser) => dispatch(setCurrentUser(newUser)),
  })
)(_LocationFilters);
