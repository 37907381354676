import React, { useEffect } from "react";
import { AUTH_TOKENS, APP_INFO } from "../../constants/default-settings";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  injectComponents,
  PanelHeaderFactory,
  FileUploadFactory,
  // KeplerGl,
} from "kepler.gl/components";
import { connect } from "react-redux";
import CustomPanelHeaderFactory from "./panel-header";
import KeplerToolbar from "../../components/kepler/KeplerToolbar";
import { SelectionControl } from "../map-selection/selection-control";
import { PredictionModal } from "../../components/prediction-modal";
import {
  hideAndShowSidePanel,
  selectVisState,
  toggleMapControl,
} from "./keplerReducer";
import CustomFileUploadFactory from "./file-upload";
import { deleteGeoLayer } from "../layers/layersSlice";
import { Box } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { setIsViewingItemOnMap } from "../map-selection/mapSelectionSlice";

// Inject custom header into Kepler.gl,
const KeplerGl = injectComponents([
  [PanelHeaderFactory, CustomPanelHeaderFactory],
  // [PanelTitleFactory, myCustomHeaderFactory],
  // [PanelToggleFactory, CustomPanelToggleFactory],
  // [GeocoderPanelFactory, CustomGeocoderPanelFactory],
  // [CustomPanelsFactory, CustomSidePanelsFactory],
  // [SidebarFactory, CustomSidebarFactory],
  // [LayerPanelHeaderFactory, CustomLayerPanelHeaderFactory],
  [FileUploadFactory, CustomFileUploadFactory],
]);

function Map({
  hideSidePanel,
  userResources,
  id,
  layerToRemove,
  removeLayer,
  toggleLegend,
  orgProperties,
  setIsViewingItemOnMap,
  isViewingItemOnMap,
  readOnlyMap,
}) {
  useEffect(() => {
    const windowIsSmall = window.innerWidth < 620;
    hideSidePanel(!!orgProperties?.hideLayerPanel || windowIsSmall);
    toggleLegend(!orgProperties?.hideLegend && !windowIsSmall);
  }, [userResources, hideSidePanel, toggleLegend, orgProperties]);

  useEffect(() => {
    if (layerToRemove) {
      removeLayer();
    }
  }, [layerToRemove, removeLayer]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Box w={width} h={height} overflow="hidden">
          {!readOnlyMap && (
            <KeplerToolbar
              containerProps={{
                zIndex: 99,
                position: "absolute",
                top: 9,
                left: 9,
              }}
            />
          )}
          <KeplerGl
            mapboxApiAccessToken={AUTH_TOKENS.MAPBOX_TOKEN}
            id={`view${id}`}
            mint={false}
            width={width}
            height={height}
            theme="light"
            appName={APP_INFO.NAME}
            version={APP_INFO.VERSION}
            readOnly={true}
          />
          {isViewingItemOnMap && (
            <Button
              variant="solid"
              onClick={() => setIsViewingItemOnMap(false)}
              position="absolute"
              right={10}
              bottom={12}
              zIndex={1300}
            >
              Finish viewing item on map
            </Button>
          )}
          {!isViewingItemOnMap && <SelectionControl />}
          <PredictionModal />
        </Box>
      )}
    </AutoSizer>
  );
}

export default connect(
  (state) => ({
    userResources: state.app.currentUser.resources,
    layerToRemove: selectVisState(state)?.removeLayer,
    readOnlyMap:
      state.keplerGl[
        `view${
          state?.mapViews?.views?.[`view${state?.mapViews?.activeView}`]?.id
        }`
      ]?.uiState?.readOnly,
    orgProperties: state.app.orgProperties?.properties,
    isViewingItemOnMap:
      state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
        ?.isViewingItemOnMap,
  }),
  (dispatch) => ({
    hideSidePanel: (hidden) => dispatch(hideAndShowSidePanel({ hidden })),
    removeLayer: () => dispatch(deleteGeoLayer()),
    toggleLegend: (active) =>
      dispatch(toggleMapControl({ control: "mapLegend", active })),
    setIsViewingItemOnMap: (payload) =>
      dispatch(setIsViewingItemOnMap(payload)),
  })
)(Map);
