import { Box } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import EmbeddedReport from "../components/embed/EmbeddedReport";
import { ZipcodeTab } from "../components/LocationBtn";
import { EngageTabs } from "../components/MarketingBtn";
import { UploadSalesContent } from "../components/uploadSalesBtn";
import BillingScreen from "../features/billing/BillingScreen";
import IntegrationsContainer from "../features/data-integrations/integrations-container";
import IntegrationsScreen from "../features/data-integrations/IntegrationsScreen";
import Login from "../features/login/Login";
import { FavoritesContent } from "../features/map-views/Favorites/FavoritesModal";
import MapViewTabs from "../features/map-views/MapViewTabs";
import OrgScreen from "../features/org-screen/OrgScreen";
import ProductsTabs from "../features/products/ProductsTabs";
import HelpSupportScreen from "../features/settings/HelpSupportScreen";
import NotificationSettingsScreen from "../features/settings/NotificationSettingsScreen";
import ReferScreen from "../features/settings/ReferScreen";
import UsersRolesScreen from "../features/settings/UsersRolesScreen";
import ShareLocatorScreen from "../features/store-locator/ShareLocatorScreen";
import { StoreLocatorCheckoutScreen } from "../features/store-locator/StoreLocatorCheckoutScreen";
import { StoreLocatorConfigPage } from "../features/store-locator/StoreLocatorConfigPage";
import StoreLocatorContainer from "../features/store-locator/StoreLocatorContainer";
import { StoreLocatorPreview } from "../features/store-locator/StoreLocatorPreview";
import StoresTabs from "../features/stores/StoresTabs";
import AppTours from "../features/tours/AppTours";
import { selectAppInitialized } from "./appSlice";
import ErrorPage from "./ErrorPage";
import { Home } from "./home";
import PageUnavailable from "./PageUnavailable";
import { useAppSelector } from "./store";

type Props = {
  fbRespose: any;
};

const userMenuRoutes = [
  {
    path: "my_account",
    element: <OrgScreen />,
  },
  {
    path: "billing",
    element: <BillingScreen />,
  },
  {
    path: "users_roles",
    element: <UsersRolesScreen />,
  },
  {
    path: "integrations",
    element: <IntegrationsScreen />,
  },
  {
    path: "notifications",
    element: <NotificationSettingsScreen />,
  },
  {
    path: "referrals",
    element: <ReferScreen />,
  },
  {
    path: "help_support",
    element: <HelpSupportScreen />,
  },
  {
    path: "share_locator",
    element: <ShareLocatorScreen />,
  },
];

const DathicRouterProvider: FunctionComponent<Props> = ({ fbRespose }) => {
  const appInitialized = useAppSelector(selectAppInitialized);

  const router = createBrowserRouter([
    {
      path: "/",
      element: appInitialized ? (
        <>
          <AppTours />
          <IntegrationsContainer fbRespose={fbRespose} />
          <Home />
        </>
      ) : (
        <Login />
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <MapViewTabs />,
        },
        {
          path: "storelocator",
          element: <StoreLocatorContainer />,
          children: [
            {
              index: true,
              element: <StoreLocatorPreview />,
            },
            {
              path: "stores",
              element: <StoresTabs />,
            },
            {
              path: "stores/:storeId",
              element: <StoresTabs />,
            },
            {
              path: "products",
              element: <ProductsTabs />,
            },
            {
              path: "products/:productId",
              element: <ProductsTabs />,
            },
            {
              path: "config",
              element: <StoreLocatorConfigPage />,
            },
            {
              path: "embed/:menuId",
              element: <EmbeddedReport />,
            },
            ...userMenuRoutes,
          ],
        },
        {
          path: "embed/:menuId",
          element: <EmbeddedReport />,
        },
        {
          path: "not_available",
          element: <PageUnavailable />,
        },
        {
          path: "not_available/:menuId",
          element: <PageUnavailable />,
        },
        {
          path: "ZipcodeTab/:menuId",
          element: <ZipcodeTab />,
        },
        {
          path: "StoresTabs/:menuId",
          element: <StoresTabs />,
        },
        {
          path: "UploadSalesBtn/:menuId",
          element: (
            <Box p="4rem 2rem">
              <UploadSalesContent />
            </Box>
          ),
        },
        {
          path: "ProductsTabs/:menuId",
          element: <ProductsTabs />,
        },
        {
          path: "EngageTabs/:menuId",
          element: <EngageTabs onClose={undefined} type={undefined} />,
        },
        {
          path: "FavoritesContent/:menuId",
          element: <FavoritesContent onClose={undefined} />,
        },
        ...userMenuRoutes,
      ],
    },
    { path: "/login", element: <Login /> },
    { path: "/checkout", element: <StoreLocatorCheckoutScreen /> },
  ]);
  return <RouterProvider router={router} />;
};

export default DathicRouterProvider;
