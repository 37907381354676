import { DateTime } from "luxon";
import { selectVisState } from "../../features/map/keplerReducer";

const { useDisclosure, Image, Box, Button } = require("@chakra-ui/react");
const { DataType } = require("ka-table/enums");

const { connect } = require("react-redux");
const {
  default: FacebookCampaignCreation,
} = require("../../features/data-integrations/facebook/facebook-campaign-creation");
const { EntityTable } = require("../entity-table/EntityTable");

function _FacebookAds({ clickedObject, zipcodeAds }) {
  const createCampaignDisclosure = useDisclosure();
  const ImageCell = ({ rowKeyValue }) => {
    const ad = zipcodeAds.find((item) => item.id === rowKeyValue);
    return <Image src={ad.image_url} />;
  };
  return (
    <Box>
      <Box mb={20}>
        <Button onClick={createCampaignDisclosure.onOpen} mb={5}>
          Create new ad campaign
        </Button>
        <EntityTable
          initialTableProps={{
            columns: [
              {
                title: "Name",
                dataType: DataType.String,
                key: "adset_name",
              },
              {
                title: "Impressions",
                dataType: DataType.Number,
                key: "impressions",
              },
              {
                title: "Clicks",
                dataType: DataType.Number,
                key: "clicks",
              },
              {
                title: "Start date",
                dataType: DataType.Date,
                key: "start_date",
              },
              {
                title: "End date",
                dataType: DataType.Date,
                key: "end_date",
              },
              {
                title: "Cost per click",
                dataType: DataType.Number,
                key: "cpc",
              },
              {
                title: "Cost per impression",
                dataType: DataType.Number,
                key: "cpm",
              },
              {
                title: "CPP",
                dataType: DataType.Number,
                key: "cpp",
              },
            ],
          }}
          LeftButton={ImageCell}
          dataFromOutside={zipcodeAds}
        />
      </Box>
      <FacebookCampaignCreation
        isOpen={createCampaignDisclosure.isOpen}
        onClose={createCampaignDisclosure.onClose}
        defaultValues={{
          adsetValues: {
            zipcodes: clickedObject ? [clickedObject.zipcode] : [],
            country: "US",
            billing_event: "IMPRESSIONS",
            start_time: DateTime.now()
              .plus({ day: 1 })
              .set({ hour: 8, minute: 0 })
              .toJSDate(),
          },
        }}
      />
    </Box>
  );
}

export default connect((state) => ({
  clickedObject: (
    selectVisState(state)?.clicked || selectVisState(state)?.customClicked
  )?.object?.properties,
  userResources: state.app.currentUser?.resources,
}))(_FacebookAds);
