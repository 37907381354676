import * as React from "react";
import { useCallback, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  GridItem,
  HStack,
  Select,
  Switch,
  Text,
} from "@chakra-ui/react";
import Form, { SchemaItem } from "../../components/Form";
import { RESOURCES } from "../../constants/user-constants";
import {
  fetchSales,
  selectLoadingSales,
} from "../map-selection/mapSelectionSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  selectOrgProperties,
  selectUserResources,
  updateOrgProperties,
} from "../../app/appSlice";
import { cloneDeep, merge, startCase } from "lodash";
import { unwrapResult } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

const colorByProperties = [
  "website_source",
  "website_name",
  "website_type",
  "website_category",
  "source",
  "grand_total",
  "taxes",
  "currency",
  "shipping_cost",
  "discount",
  "shipping_discount",
  "order_status",
  "shipping_zipcode",
  "shipping_state",
  "shipping_country",
  "num_items",
  "num_units",
  "shipping_city",
  "gross_sales",
  "fulfillment_status",
  "billing_company",
  "billing_zipcode",
  "billing_state",
  "billing_country",
  "shipping_company",
  "billing_city",
  "refunded",
  "payment_method",
  "risk_level",
];

type Props = { onClose?: () => void };
export const SalesSearch = ({ onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [shouldCreateLayer, setShouldCreateLayer] = useState(false);
  const [loadingAutoUpdateToggle, setLoadingAutoUpdateToggle] = useState(false);
  const [attrToColorBy, setAttrToColorBy] = useState<string>();
  const [attrToBorderBy, setAttrToBorderBy] = useState<string>();
  const isLoadingSales = useAppSelector(selectLoadingSales);
  const userResources = useAppSelector(selectUserResources);
  const orgProperties = useAppSelector(selectOrgProperties);
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";
  const autoUpdateSalesLayer =
    !!orgProperties?.properties?.autoUpdateSalesLayer;

  const salesFilterSchema: SchemaItem[] = [
    {
      key: "start_date",
      label: "Start Date",
      type: "date",
      placeholder: "Select a start date",
    },
    {
      key: "end_date",
      label: "End Date",
      type: "date",
      placeholder: "Select an end date",
    },
    {
      key: "advanced",
      label: "Advanced",
      renderFormControl: () => (
        <GridItem colSpan={2}>
          <Accordion w="100%" allowToggle defaultIndex={0}>
            <AccordionItem>
              <AccordionButton fontSize={18} fontWeight="bold">
                Attributes to color by
                <AccordionIcon ml="auto" />
              </AccordionButton>
              <AccordionPanel>
                <HStack>
                  <FormControl>
                    <FormLabel>Fill color by</FormLabel>
                    <FormHelperText>
                      The selected property will be used for the fill
                    </FormHelperText>
                    <Select
                      value={attrToColorBy}
                      onChange={(event) => setAttrToColorBy(event.target.value)}
                    >
                      <option value="" selected>
                        Select a property
                      </option>
                      {colorByProperties.map((variable) => (
                        <option key={variable} value={variable}>
                          {startCase(variable)}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Border color by</FormLabel>
                    <FormHelperText>
                      The selected property will be used to color the border
                    </FormHelperText>
                    <Select
                      value={attrToBorderBy}
                      onChange={(event) =>
                        setAttrToBorderBy(event.target.value)
                      }
                    >
                      <option value="" selected>
                        Select a property
                      </option>
                      {colorByProperties.map((variable) => (
                        <option key={variable} value={variable}>
                          {startCase(variable)}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </HStack>
              </AccordionPanel>
            </AccordionItem>
            {/*<AccordionItem>
              <AccordionButton fontSize={18} fontWeight="bold">
                Advanced options
                <AccordionIcon ml="auto" />
              </AccordionButton>
              <AccordionPanel>
                <VStack spacing={5}>
                  <Flex justify="space-between" alignItems="center" w="100%">
                    <Box>
                      <Switch
                        isChecked={autoUpdateSalesLayer}
                        isDisabled={loadingAutoUpdateToggle}
                        onChange={(e) =>
                          setAutomaticallyUpdate(e.target.checked)
                        }
                      >
                        Update sales layer automatically
                      </Switch>
                      <Text fontSize={12} color="darkgray">
                        {autoUpdateSalesLayer
                          ? `Sales will be updated automatically every time you open the map.`
                          : `Sales layer on the map will need to be updated manually through this form.`}
                      </Text>
                    </Box>
                  </Flex>
                </VStack>
              </AccordionPanel>
            </AccordionItem>*/}
          </Accordion>
        </GridItem>
      ),
    },
  ];

  const setAutomaticallyUpdate = useCallback(
    async (shouldAutoUpdate: boolean) => {
      setLoadingAutoUpdateToggle(true);
      const newOrgConfig = cloneDeep(orgProperties || {});
      merge(newOrgConfig, {
        properties: {
          autoUpdateSalesLayer: false,
        },
      });
      // @ts-ignore
      newOrgConfig.properties = {
        // @ts-ignore
        ...newOrgConfig.properties,
        autoUpdateSalesLayer: shouldAutoUpdate,
      };
      // @ts-ignore
      const wrapped = await dispatch(updateOrgProperties(newOrgConfig));
      setLoadingAutoUpdateToggle(false);
      // @ts-ignore
      return unwrapResult(wrapped);
    },
    [dispatch, orgProperties]
  );

  const onSubmit = async (formValues: any) => {
    setShouldSubmit(false);
    const _formValues = { ...(formValues || {}) };
    await dispatch(
      // @ts-ignore
      fetchSales({
        start_date: _formValues.start_date
          ? `${DateTime.fromJSDate(_formValues.start_date).toSQL({
              includeOffset: false,
            })}`
          : undefined,
        end_date: _formValues.end_date
          ? `${DateTime.fromJSDate(_formValues.end_date).toSQL({
              includeOffset: false,
            })}`
          : undefined,
        colorBy: attrToColorBy,
        colorBySecondary: attrToBorderBy,
        shouldCreateLayer,
      })
    );
    if (onClose) onClose();
  };
  return (
    <Box h="100%">
      <Form
        schema={salesFilterSchema}
        submitFromOutside={{ shouldSubmit }}
        onSubmit={onSubmit}
        gridProps={{ minH: 100 }}
      />
      <Flex
        direction="row"
        justifyContent="space-between"
        position="absolute"
        right={0}
        bottom={0}
        bg="white"
        p={5}
        w="100%"
        borderTop="1px solid gainsboro"
        zIndex={9}
      >
        <Box>
          <Switch
            isChecked={shouldCreateLayer}
            onChange={() => setShouldCreateLayer((oldValue) => !oldValue)}
          >
            Create new layer
          </Switch>
          <Text fontSize={12} color="darkgray">
            {shouldCreateLayer
              ? `Your current ${storeLabel} layers will stay the same`
              : `Your current ${storeLabel} layers will be replaced with this search`}
          </Text>
        </Box>
        <Flex direction="row">
          <Button
            isLoading={isLoadingSales}
            colorScheme="blue"
            disabled={
              isLoadingSales || !userResources?.includes(RESOURCES.STORES_GET)
            }
            ml={3}
            onClick={() => {
              setShouldSubmit(true);
            }}
          >
            Show results
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
