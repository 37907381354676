import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import DeliveryIndicatorsTab from "./DeliveryIndicatorsTab";
import MyProductsTab from "./MyProductsTab";

export default function _ProductsTab(props) {
  const { onFinishLoading, tabIsActive } = props;
  const [indicatorsLoaded, setIndicatorsLoaded] = useState();
  const [productsLoaded, setProductsLoaded] = useState();

  useEffect(() => {
    if (
      onFinishLoading &&
      indicatorsLoaded !== undefined &&
      productsLoaded !== undefined
    ) {
      onFinishLoading(indicatorsLoaded || productsLoaded, "Products");
    }
  }, [indicatorsLoaded, onFinishLoading, productsLoaded]);

  return (
    <Tabs>
      <TabList>
        <Tab>Insights</Tab>
        <Tab>My Products</Tab>
      </TabList>
      <TabPanels>
        <TabPanel paddingLeft={0} paddingRight={0}>
          <DeliveryIndicatorsTab
            tabIsActive={tabIsActive}
            onFinishLoading={setIndicatorsLoaded}
          />
        </TabPanel>
        <TabPanel>
          <MyProductsTab onFinishLoading={setProductsLoaded} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
