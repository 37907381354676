import { ThemeOverride } from "@chakra-ui/react";
import Button from "./button";
import Checkbox from "./checkbox";
import Form from "./form";
import Input from "./input";
import Modal from "./modal";
import NumberInput from "./number-input";
import Select from "./select";
import Tag from "./tag";

const components: ThemeOverride["components"] = {
  Button,
  Form,
  Checkbox,
  Input,
  Select,
  Textarea: {
    baseStyle: {
      borderRadius: 10,
      _placeholder: {
        color: "gray.600",
      },
    },
  },
  NumberInput,
  Tag,
  Modal,
  Box: { baseStyle: { borderRadius: 10 } },
};
export default components;
