import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Checkbox,
  HStack,
  Input,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CheckboxGridInput from "../../components/CheckboxGridInput";

export default function InsightsSelector({ value, onChange, insightOptions }) {
  const [filterText, setFilterText] = useState("");
  const [isShowingAll, setIsShowingAll] = useState(false);

  const _insightOptions = filterText
    ? insightOptions?.filter(
        (o) =>
          o.category?.toLowerCase().includes(filterText.toLowerCase()) ||
          o.name?.toLowerCase().includes(filterText.toLowerCase())
      ) || []
    : insightOptions;

  const selectedInsights = Object.keys(value || {}).reduce((acc, insightId) => {
    const option = _insightOptions?.find((o) => o.id === +insightId);
    if (!option) {
      return acc;
    }
    const category = option.category || "nocategory";
    return {
      ...acc,
      [category]: { ...acc[category], [insightId]: value[insightId] },
    };
  }, {});

  const handleSelectChange = (category, values) => {
    const _selectedInsights = {
      ...selectedInsights,
      [category || "nocategory"]: values,
    };
    const newValue = Object.keys(_selectedInsights).reduce(
      (acc, category) => ({ ...acc, ...(_selectedInsights[category] || {}) }),
      {}
    );
    onChange(newValue);
  };

  const handleCategoryCheckboxChange = (e, category) => {
    e.stopPropagation();
    handleSelectChange(
      category,
      _insightOptions
        ?.filter((o) => o.category === category)
        .reduce((acc, o) => ({ ...acc, [o.id]: e.target.checked }), {})
    );
  };

  const allChecked = (category) =>
    _insightOptions
      ?.filter((o) => o.category === category)
      .map((o) => !!selectedInsights?.[category || "nocategory"]?.[o.id])
      .every(Boolean);
  const isIndeterminate = (category) =>
    _insightOptions
      ?.filter((o) => o.category === category)
      .map((o) => !!selectedInsights?.[category || "nocategory"]?.[o.id])
      .some(Boolean) && !allChecked(category);

  return (
    <VStack>
      <HStack w="100%">
        <Input
          flex={1}
          placeholder={`Filter more insights`}
          rounded="md"
          value={filterText}
          onChange={(event) => setFilterText(event.target.value)}
        />
        <Button variant="link" onClick={() => setFilterText("")}>
          Clear
        </Button>
        <Checkbox
          isChecked={isShowingAll}
          onChange={(e) => setIsShowingAll((oldValue) => !oldValue)}
        >
          Show all
        </Checkbox>
      </HStack>
      <Accordion w="100%" allowToggle allowMultiple mt={3}>
        {[...new Set(_insightOptions?.map((o) => o.category) || [])]
          .slice(0, isShowingAll ? undefined : 3)
          .map((category) =>
            _insightOptions?.filter((o) => o.category === category).length ? (
              <AccordionItem>
                <AccordionButton>
                  <AccordionIcon />
                  {category || "Other"}
                  <Checkbox
                    ml={3}
                    isChecked={allChecked(category)}
                    isIndeterminate={isIndeterminate(category)}
                    onChange={(e) => handleCategoryCheckboxChange(e, category)}
                  />
                </AccordionButton>
                <AccordionPanel>
                  <CheckboxGridInput
                    value={value}
                    name="insights"
                    options={_insightOptions
                      ?.filter((o) => o.category === category)
                      .map((variable) => ({
                        value: variable.id,
                        label: variable.name,
                        description: variable.description,
                      }))}
                    onChange={(value) =>
                      handleSelectChange(category || "nocategory", value)
                    }
                  />
                </AccordionPanel>
              </AccordionItem>
            ) : (
              <></>
            )
          )}
      </Accordion>
    </VStack>
  );
}
