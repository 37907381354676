import React, { useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Select,
  Switch,
  Tag,
  TagLabel,
  Text,
  VStack,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import {
  fetchZipcodesInRadius,
  setIsSelectingOnMap,
  setIsUsingRadius,
  setMapSelection,
} from "./mapSelectionSlice";
import { onLayerClick } from "kepler.gl/dist/actions/vis-state-actions";
import { wrapTo } from "kepler.gl/dist/actions";
import { CloseIcon } from "@chakra-ui/icons";
import { selectLoadedZipcodes, selectVisState } from "../map/keplerReducer";
var pluralize = require("pluralize");

const _SelectionSection = (props) => {
  const {
    type,
    items = [],
    setSelection,
    removeItem,
    clearSection,
    fetchZipcodesInRadius,
  } = props;

  const _clearSection = () => {
    setSelection(
      (oldSelection) =>
        (oldSelection && { ...oldSelection, [type]: undefined }) || undefined
    );
  };

  const _removeItem = (index) => {
    setSelection((oldSelection) => {
      let newList = [...oldSelection[type]];
      newList.splice(index, 1);
      newList = newList.length === 0 ? undefined : newList;
      return { ...oldSelection, [type]: newList };
    });
  };

  const getTagText = (item) => {
    if (["zipcodes", "stores"].includes(type)) {
      return item[type === "zipcodes" ? "zipcode" : "store_id"];
    } else if (type === "radius") {
      return `center: (${item.latitude}, ${item.longitude})`;
    }
  };

  return (
    <Box mt={5} mb={5} width="100%" bg="rgb(255,255,255,0.75)" p={3}>
      <Flex direction="row" justify="space-between">
        {type?.length > 1 && (
          <Heading size="md" mr={20}>
            {`${type[0].toUpperCase()}${type.substr(1)}`}
          </Heading>
        )}
        <Button variant="link" onClick={clearSection || _clearSection}>
          CLEAR
        </Button>
      </Flex>
      <VStack
        direction="column"
        mb={3}
        spacing={3}
        align="stretch"
        maxHeight={60}
        overflowY="scroll"
      >
        {items.map((item, index) => (
          <Tag justifyContent="space-between">
            <TagLabel>{getTagText(item)}</TagLabel>
            <CloseIcon onClick={() => (removeItem || _removeItem)(index)} />
          </Tag>
        ))}
        {type === "radius" && (
          <Box>
            {(!items || !items.length) && <Text>Select point on map</Text>}
            {items?.length && (
              <Select
                value={items[0].radius_mi}
                onChange={(e) => {
                  setSelection((oldSelection) => ({
                    ...(oldSelection || {}),
                    radius: [
                      {
                        ...(((oldSelection || {}).radius || [])[0] || {}),
                        radius_mi: e.target.value,
                      },
                    ],
                  }));

                  fetchZipcodesInRadius({
                    latitude: items[0].latitude,
                    longitude: items[0].longitude,
                    radius_mi: e.target.value,
                  });
                }}
              >
                {[10, 15, 20, 30, 40].map((miles) => (
                  <option value={miles}>{miles} miles</option>
                ))}
              </Select>
            )}
          </Box>
        )}
      </VStack>
    </Box>
  );
};
export const SelectionSection = connect(
  (state) => ({
    loadedDemographics: selectLoadedZipcodes(state),
  }),
  (dispatch) => ({
    setSelection: (newSelection) => dispatch(setMapSelection(newSelection)),
    fetchZipcodesInRadius: (payload) =>
      dispatch(fetchZipcodesInRadius(payload)),
  })
)(_SelectionSection);

const _SelectionControl = (props) => {
  const {
    isSelectingOnMap,
    mapSelection,
    clickedObject,
    setIsNotSelecting,
    removeClickedObject,
    setSelection,
    activeViewId,
    clickedCoordinate,
    setIsSelecting,
    setIsUsingRadius,
    usingRadius,
    fetchZipcodesInRadius,
    orgConfig,
  } = props;
  const storeLabel = orgConfig?.storeNameReplacement || "Store";

  /* Listen to object click on map */
  useEffect(() => {
    if (clickedObject && isSelectingOnMap && !usingRadius) {
      const objectType = clickedObject.store_id ? "stores" : "zipcodes";
      removeClickedObject(activeViewId);
      setSelection((oldSelection) => {
        return {
          ...(oldSelection || {}),
          [objectType]:
            (((oldSelection || {})[objectType] || []).filter(
              (item) =>
                item[objectType === "stores" ? "id" : "zipcode"] ===
                clickedObject[objectType === "stores" ? "id" : "zipcode"]
            ).length === 0 && [
              ...((oldSelection || {})[objectType] || []),
              clickedObject,
            ]) ||
            (oldSelection || {})[objectType] ||
            undefined,
        };
      });
    }
  }, [
    activeViewId,
    clickedObject,
    isSelectingOnMap,
    removeClickedObject,
    setSelection,
    usingRadius,
  ]);

  /* Listen to radius click on map */
  useEffect(() => {
    if (clickedCoordinate && isSelectingOnMap && usingRadius) {
      removeClickedObject(activeViewId);
      let radius_mi = 10;
      setSelection((oldSelection) => {
        radius_mi =
          (oldSelection?.radius || [{ radius_mi: 10 }])[0].radius_mi || 10;
        return {
          ...(oldSelection || {}),
          radius: [
            {
              latitude: clickedCoordinate[1],
              longitude: clickedCoordinate[0],
              radius_mi,
            },
          ],
        };
      });
      fetchZipcodesInRadius({
        latitude: clickedCoordinate[1],
        longitude: clickedCoordinate[0],
        radius_mi,
      });
    }
  }, [
    activeViewId,
    clickedCoordinate,
    fetchZipcodesInRadius,
    isSelectingOnMap,
    removeClickedObject,
    setSelection,
    usingRadius,
  ]);
  return (
    <Flex
      position="absolute"
      right={4}
      bottom={6}
      zIndex={1300}
      direction="column"
      align="flex-end"
    >
      {isSelectingOnMap && (
        <React.Fragment>
          {mapSelection?.zipcodes && (
            <SelectionSection type="zipcodes" items={mapSelection.zipcodes} />
          )}
          {mapSelection?.stores && (
            <SelectionSection type="stores" items={mapSelection.stores} />
          )}
          {usingRadius && (
            <SelectionSection type="radius" items={mapSelection?.radius} />
          )}
        </React.Fragment>
      )}
      {isSelectingOnMap && (
        <HStack>
          <Text textColor="white" fontWeight="bold">
            Use radius
          </Text>
          <Switch
            isChecked={!!usingRadius}
            onChange={(e) => {
              if (usingRadius) {
                setSelection(
                  (oldSelection) =>
                    (oldSelection && { ...oldSelection, radius: undefined }) ||
                    undefined
                );
              }
              setIsUsingRadius(!usingRadius);
            }}
          ></Switch>
          <Button variant="solid" onClick={setIsNotSelecting}>
            Finish Selection
          </Button>
        </HStack>
      )}
      {!isSelectingOnMap && (
        <Button
          className="selection-btn"
          id="selection-btn"
          variant="solid"
          onClick={setIsSelecting}
        >
          {mapSelection?.zipcodes?.length || mapSelection?.stores?.length
            ? `${mapSelection?.zipcodes?.length || 0} zipcodes, ${
                mapSelection?.stores?.length || 0
              } ${pluralize(storeLabel)} selected`
            : "Map Selection"}
          <br />
          Click to change
        </Button>
      )}
    </Flex>
  );
};

export const SelectionControl = connect(
  (state) => {
    const clicked =
      selectVisState(state)?.clicked || selectVisState(state)?.customClicked;
    return {
      clickedObject: clicked?.object?.properties,
      clickedCoordinate: clicked?.coordinate,
      placeSelection: state.app.placeSelection,
      orgConfig: state.app.orgProperties?.properties,
      isSelectingOnMap:
        state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
          ?.isSelecting,
      usingRadius:
        state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
          ?.usingRadius,
      mapSelection:
        state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
          ?.selection,
      activeViewId:
        state?.mapViews?.views?.[`view${state?.mapViews?.activeView}`]?.id,
    };
  },
  (dispatch) => ({
    setIsNotSelecting: () => dispatch(setIsSelectingOnMap(false)),
    setIsSelecting: () => dispatch(setIsSelectingOnMap(true)),
    setIsUsingRadius: (payload) => dispatch(setIsUsingRadius(payload)),
    removeClickedObject: (activeView) =>
      dispatch(wrapTo(`view${activeView}`, onLayerClick(null))),
    setSelection: (newSelection) => dispatch(setMapSelection(newSelection)),
    fetchZipcodesInRadius: (payload) =>
      dispatch(fetchZipcodesInRadius(payload)),
  })
)(_SelectionControl);
