import React, { Component, createRef } from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { injectIntl } from "react-intl";
import { media } from "kepler.gl/styles";
import { USER_GUIDE_DOC } from "kepler.gl/constants";
import { FileUploadProgress, Icons, withState } from "kepler.gl/components";
import FileDrop from "kepler.gl/dist/components/common/file-uploader/file-drop";
import UploadButton from "kepler.gl/dist/components/common/file-uploader/upload-button";
import { FormattedMessage } from "kepler.gl/localization";
import { isChrome } from "kepler.gl/dist/utils/utils";
import { createGeoLayer } from "../layers/layersSlice";
import { unparse } from "papaparse";
import FileUploadProxy from "../../components/file-upload-proxy";
import { appendVisData } from "./keplerReducer";
import { wrapTo } from "kepler.gl/dist/actions";
import { processCsvData } from "kepler.gl/dist/processors";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const fileIconColor = "#D3D8E0";

const LinkRenderer = (props) => {
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};
const StyledUploadMessage = styled.div`
  color: ${(props) => props.theme.textColorLT};
  font-size: 14px;
  margin-bottom: 12px;
  ${media.portable`
    font-size: 12px;
  `};
`;

export const WarningMsg = styled.span`
  margin-top: 10px;
  color: ${(props) => props.theme.errorColor};
  font-weight: 500;
`;

const StyledFileDrop = styled.div`
  background-color: white;
  border-radius: 4px;
  border-style: ${(props) => (props.dragOver ? "solid" : "dashed")};
  border-width: 1px;
  border-color: ${(props) =>
    props.dragOver ? props.theme.textColorLT : props.theme.subtextColorLT};
  text-align: center;
  width: 100%;
  padding: 48px 8px 0;
  height: 360px;
  .file-upload-or {
    color: ${(props) => props.theme.linkBtnColor};
    padding-right: 4px;
  }
  .file-type-row {
    opacity: 0.5;
  }
  ${media.portable`
    padding: 16px 4px 0;
  `};
`;

const MsgWrapper = styled.div`
  color: ${(props) => props.theme.modalTitleColor};
  font-size: 20px;
  height: 36px;
`;

const StyledDragNDropIcon = styled.div`
  color: ${fileIconColor};
  margin-bottom: 48px;
  ${media.portable`
    margin-bottom: 16px;
  `};
  ${media.palm`
    margin-bottom: 8px;
  `};
`;

const StyledFileTypeFow = styled.div`
  margin-bottom: 24px;
  ${media.portable`
    margin-bottom: 16px;
  `};
  ${media.palm`
    margin-bottom: 8px;
  `};
`;

const StyledFileUpload = styled.div`
  .file-drop {
    position: relative;
  }
`;

const StyledDragFileWrapper = styled.div`
  margin-bottom: 32px;
  ${media.portable`
    margin-bottom: 24px;
  `};
  ${media.portable`
    margin-bottom: 16px;
  `};
`;

function CustomFileUploadFactory() {
  class FileUpload extends Component {
    state = {
      dragOver: false,
      fileLoading: false,
      files: [],
      errorFiles: [],
    };

    static getDerivedStateFromProps(props, state) {
      if (
        state.fileLoading &&
        props.fileLoading === false &&
        state.files.length
      ) {
        return {
          files: [],
          fileLoading: props.fileLoading,
        };
      }
      return {
        fileLoading: props.fileLoading,
      };
    }

    frame = createRef();

    _isValidFileType = (filename) => {
      const { fileExtensions = [] } = this.props;
      const fileExt = fileExtensions.find(
        (ext) =>
          filename.endsWith(ext) ||
          filename.endsWith("xlsx") ||
          filename.endsWith("xlsm")
      );

      return Boolean(fileExt);
    };

    /** @param {FileList} fileList */
    _handleFileInput = (fileList, event) => {
      if (event) {
        event.stopPropagation();
      }

      const files = [...fileList].filter(Boolean);

      const { disableExtensionFilter = false } = this.props;

      const filesToLoad = [];
      const errorFiles = [];
      for (const file of files) {
        if (disableExtensionFilter || this._isValidFileType(file.name)) {
          filesToLoad.push(file);
        } else {
          errorFiles.push(file.name);
        }
      }

      const nextState = {
        files: filesToLoad,
        errorFiles,
        dragOver: false,
      };

      this.setState(nextState);

      // this.setState(nextState, () =>
      //   nextState.files.length ? this.props.onFileUpload(nextState.files) : null
      // );
    };

    _toggleDragState = (newState) => {
      this.setState({ dragOver: newState });
    };

    render() {
      const { dragOver, files, errorFiles } = this.state;
      const {
        fileLoading,
        fileLoadingProgress,
        theme,
        intl,
        createGeoLayer,
        wrapTo,
        currentView,
      } = this.props;
      const { fileExtensions = [], fileFormatNames = [] } = this.props;
      return (
        <StyledFileUpload className="file-uploader" ref={this.frame}>
          {FileDrop ? (
            <FileDrop
              frame={this.frame.current || document}
              onDragOver={() => this._toggleDragState(true)}
              onDragLeave={() => this._toggleDragState(false)}
              onDrop={this._handleFileInput}
              className="file-uploader__file-drop"
            >
              <StyledUploadMessage className="file-upload__message">
                <ReactMarkdown
                  source={`${intl.formatMessage(
                    {
                      id: "fileUploader.configUploadMessage",
                    },
                    {
                      fileFormatNames: fileFormatNames
                        .map((format) => `**${format}**`)
                        .join(", "),
                    }
                  )}(${USER_GUIDE_DOC.GUIDES_FILE_FORMAT_DOC}).`}
                  renderers={{ link: LinkRenderer }}
                />
              </StyledUploadMessage>
              <StyledFileDrop dragOver={dragOver}>
                <StyledFileTypeFow className="file-type-row">
                  {fileExtensions.map((ext) => (
                    <Icons.FileType
                      key={ext}
                      ext={ext}
                      height="50px"
                      fontSize="9px"
                    />
                  ))}
                </StyledFileTypeFow>
                {fileLoading ? (
                  <FileUploadProgress
                    fileLoadingProgress={fileLoadingProgress}
                    theme={theme}
                  />
                ) : (
                  <>
                    <div
                      style={{ opacity: dragOver ? 0.5 : 1 }}
                      className="file-upload-display-message"
                    >
                      <StyledDragNDropIcon>
                        <Icons.DragNDrop height="44px" />
                      </StyledDragNDropIcon>

                      {errorFiles.length ? (
                        <WarningMsg>
                          <FormattedMessage
                            id={"fileUploader.fileNotSupported"}
                            values={{ errorFiles: errorFiles.join(", ") }}
                          />
                        </WarningMsg>
                      ) : null}
                    </div>
                    {!files.length ? (
                      <StyledDragFileWrapper>
                        <MsgWrapper>
                          <FormattedMessage id={"fileUploader.message"} />
                        </MsgWrapper>
                        <span className="file-upload-or">
                          <FormattedMessage id={"fileUploader.or"} />
                        </span>
                        <UploadButton onUpload={this._handleFileInput}>
                          <FormattedMessage id={"fileUploader.browseFiles"} />
                        </UploadButton>
                      </StyledDragFileWrapper>
                    ) : (
                      <FileUploadProxy
                        file={files[0]}
                        schemas={{
                          Point: [
                            {
                              name: "lat",
                              helperText: "Latitude for point",
                              isRequired: true,
                              format: (value) => +`${value}`.replace(",", "."),
                            },
                            {
                              name: "lng",
                              helperText: "Longitude for point",
                              isRequired: true,
                              format: (value) => +`${value}`.replace(",", "."),
                            },
                            {
                              name: "color_by",
                              helperText: "Attribute to use for color gradient",
                              isRequired: true,
                            },
                          ],
                          Shape: [
                            {
                              name: "_geojson",
                              build: (fileFieldName, item, index) =>
                                JSON.stringify(
                                  item._geojson || {
                                    geometry: item[fileFieldName],
                                    id: index,
                                    properties: item,
                                    type: "Feature",
                                  }
                                ),
                            },
                            {
                              name: "color_by",
                              helperText: "Attribute to use for color gradient",
                            },
                          ],
                        }}
                        onFinishProxy={async (selectedFields, newData) => {
                          this.setState();
                          toast("Uploading file and creating layer.", {
                            autoClose: false,
                            closeButton: false,
                            closeOnClick: false,
                            progress: true,
                            toastId: "creating-layer",
                          });

                          try {
                            const info = {
                              label: files[0].name,
                              id: files[0].name.trim().replaceAll(" ", "_"),
                            };

                            let newGeoLayer;
                            if (newData?.length) {
                              const wrappedGeolayer = await createGeoLayer({
                                name: files[0].name,
                                geolayer_details: newData.map((item) => ({
                                  geolayer_detail_generic: {
                                    geometry: (typeof item._geojson === "string"
                                      ? JSON.parse(item._geojson)
                                      : item._geojson
                                    )?.geometry,
                                    properties: JSON.stringify(item),
                                  },
                                })),
                                config: {
                                  info,
                                },
                                color_by: selectedFields.index,
                                view_id: currentView.id,
                              });
                              newGeoLayer = await unwrapResult(wrappedGeolayer);
                              newData = newGeoLayer.geolayer_details.map(
                                (detail) => ({
                                  ...JSON.parse(
                                    detail.geolayer_detail_generic.properties ||
                                      "{}"
                                  ),
                                  geolayer_detail_id: detail.id,
                                })
                              );
                            }

                            const newDataCsv = unparse(newData);
                            const processedData = processCsvData(newDataCsv);
                            if (newDataCsv) {
                              wrapTo(
                                `view${currentView.id}`,
                                appendVisData({
                                  // datasets
                                  datasets: {
                                    info,
                                    data: processedData,
                                  },
                                  // option
                                  options: {
                                    keepExistingConfig: true,
                                  },
                                  config: {
                                    config: {
                                      visState: {
                                        layers: [
                                          {
                                            config: {
                                              dataId: info.id,
                                              label: info.label,
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  },
                                })
                              );
                            } else {
                              this.props.onFileUpload(files);
                            }
                            toast.success("Layer created from file");
                            toast.dismiss("creating-layer");
                          } catch (error) {
                            toast.error("Error creating layer: " + error);
                            toast.dismiss("creating-layer");
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </StyledFileDrop>
            </FileDrop>
          ) : null}

          <WarningMsg>
            {isChrome() ? (
              <FormattedMessage id={"fileUploader.chromeMessage"} />
            ) : (
              ""
            )}
          </WarningMsg>
        </StyledFileUpload>
      );
    }
  }

  return withState(
    [],
    (state) => ({
      currentView: state.mapViews.views[`view${state.mapViews.activeView}`],
    }),
    {
      createGeoLayer,
      wrapTo,
    }
  )(injectIntl(FileUpload));
}

export default CustomFileUploadFactory;
export const FileUpload = CustomFileUploadFactory();
