import React, { FunctionComponent, useState } from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Heading,
  Text,
} from "@chakra-ui/react";
import { IoChatbox } from "react-icons/all";
import DathicChat from "./DathicChat";
import { useLocation } from "react-router-dom";

type Props = {};

const DathicChatBtn: FunctionComponent<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  return !location.pathname.includes("storelocator") ? (
    <>
      <Button
        id="dathic-chat-btn"
        className="dathic-chat-btn"
        variant="ghost"
        leftIcon={
          <IoChatbox
            size="1.5rem"
            id="dathic-chat-btn"
            className="dathic-chat-btn"
          />
        }
        iconSpacing={["0.5rem", null, 0]}
        onClick={() => {
          console.log("testing");
          setIsOpen(true);
        }}
      >
        <Text display={["block", null, "none"]}>Dathic Chat</Text>
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => setIsOpen(false)}
        size="xl"
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody h="100vh" display="flex" flexDir="column">
              <Heading>Dathic Chat</Heading>
              <DathicChat />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  ) : (
    <></>
  );
};

export default DathicChatBtn;
