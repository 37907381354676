import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box, Flex, HStack, SimpleGrid, Text, VStack } from "@chakra-ui/layout";
import {
  Button,
  Collapse,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { scaleLinear } from "d3-scale";
import { FilteringMode, SortDirection } from "ka-table/enums";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TagCloud } from "react-tagcloud";
import { PieForTable } from "../PieForTable";
import { selectVisState } from "../../features/map/keplerReducer";
import { getTopics, getTwitterIndicators } from "../../services/api.service";
import { getStoreNLPInsights } from "../../services/insights.service";
import { primaryLight, secondaryDark } from "../../theme/colors";
import { camelCaseWords } from "../../utils/stringUtils";
import { EntityTable } from "../entity-table/EntityTable";
import RadialTree from "../RadialTree";

const UserTag: React.FC<ICellTextProps> = (props) => {
  const { value } = props;
  return (
    <Button
      variant="link"
      onClick={() => window.open(`https://twitter.com/${value}`)}
    >
      @{value}
    </Button>
  );
};

const HashTag: React.FC<ICellTextProps> = (props) => {
  const { value } = props;
  return (
    <Button
      variant="link"
      onClick={() => window.open(`https://twitter.com/hashtag/${value}`)}
    >
      #{value}
    </Button>
  );
};

function NLPInsightsSource({ data }) {
  const positiveWordsDisclosure = useDisclosure();
  const negativeWordsDisclosure = useDisclosure();
  const childShade = scaleLinear()
    .domain([0, 1])
    .range([secondaryDark, primaryLight]);
  return (
    <VStack>
      <HStack alignItems="flex-start">
        <Stat flex={1}>
          <StatLabel>Source</StatLabel>
          <StatNumber>{data.source}</StatNumber>
        </Stat>
        <Stat flex={1}>
          <StatLabel>Total comments analyzed</StatLabel>
          <StatNumber>{data.total_comments}</StatNumber>
        </Stat>
        <Stat flex={1}>
          <StatLabel>Date range</StatLabel>
          <StatHelpText>
            {DateTime.fromISO(data.initial_date).toLocaleString(
              DateTime.DATE_MED
            )}{" "}
            -{" "}
            {DateTime.fromISO(data.last_date).toLocaleString(DateTime.DATE_MED)}
          </StatHelpText>
        </Stat>
      </HStack>
      <HStack alignItems="flex-start">
        {data.summary_english && (
          <VStack flex={1} shadow="md" rounded="lg" bg="whitesmoke" p={3}>
            <Text>English</Text>
            <Text p={3} bg="white" rounded="lg">
              {data.summary_english}
            </Text>
          </VStack>
        )}
        {data.summary_spanish && (
          <VStack flex={1} shadow="md" rounded="lg" bg="whitesmoke" p={3}>
            <Text>Spanish</Text>
            <Text p={3} bg="white" rounded="lg">
              {data.summary_spanish}
            </Text>
          </VStack>
        )}
        {data.summary_to_improve && (
          <VStack flex={1} shadow="md" rounded="lg" bg="whitesmoke" p={3}>
            <Text>Improvement</Text>
            <Text p={3} bg="white" rounded="lg">
              {data.summary_to_improve}
            </Text>
          </VStack>
        )}
      </HStack>
      <HStack alignItems="flex-start">
        <VStack flex={1} shadow="md" rounded="lg" bg="whitesmoke" p={3}>
          <Text>Positive keywords</Text>
          <Box p={3} bg="white" rounded="lg">
            <Text
              fontSize={12}
              p={5}
              onClick={positiveWordsDisclosure.onToggle}
              cursor="pointer"
            >
              {pluralize(
                "word",
                data.positive_keywords?.values?.length || 0,
                true
              )}
              {positiveWordsDisclosure.isOpen ? (
                <ChevronUpIcon float="right" />
              ) : (
                <ChevronDownIcon float="right" />
              )}
            </Text>
            <Collapse
              in={positiveWordsDisclosure.isOpen}
              animateOpacity
              style={{ width: "100%" }}
            >
              <Flex justifyContent={"flex-start"} direction={"column"}>
                {data.positive_keywords?.values?.map((word) => (
                  <Button
                    key={word.x}
                    _hover={false}
                    borderRadius={0}
                    bg={childShade(word.y)}
                    color="white"
                    justifyContent="center"
                  >
                    {word.x}
                  </Button>
                ))}
              </Flex>
            </Collapse>
          </Box>
        </VStack>

        <VStack flex={1} shadow="md" rounded="lg" bg="whitesmoke" p={3}>
          <Text>Negative keywords</Text>
          <Box p={3} bg="white" rounded="lg">
            <Text
              fontSize={12}
              p={5}
              onClick={negativeWordsDisclosure.onToggle}
              cursor="pointer"
            >
              {pluralize(
                "word",
                data.negative_keywords?.values?.length || 0,
                true
              )}
              {negativeWordsDisclosure.isOpen ? (
                <ChevronUpIcon float="right" />
              ) : (
                <ChevronDownIcon float="right" />
              )}
            </Text>
            <Collapse
              in={negativeWordsDisclosure.isOpen}
              animateOpacity
              style={{ width: "100%" }}
            >
              <Flex justifyContent={"flex-start"} direction={"column"}>
                {data.negative_keywords?.values?.map((word) => (
                  <Button
                    key={word.x}
                    _hover={false}
                    borderRadius={0}
                    bg={childShade(word.y)}
                    color="white"
                    justifyContent="center"
                  >
                    {word.x}
                  </Button>
                ))}
              </Flex>
            </Collapse>
          </Box>
        </VStack>
        {data.sentiment_distribution && (
          <VStack flex={1} shadow="md" rounded="lg" bg="whitesmoke" p={3}>
            <Text>Sentiment</Text>
            <Flex p={3} bg="white" rounded="lg">
              <PieForTable
                height={100}
                width={100}
                value={data.sentiment_distribution.values}
              />
            </Flex>
          </VStack>
        )}
        {data.language_distribution && (
          <VStack flex={1} shadow="md" rounded="lg" bg="whitesmoke" p={3}>
            <Text>Language</Text>
            <Flex p={3} bg="white" rounded="lg">
              <PieForTable
                height={100}
                width={100}
                value={data.language_distribution.values}
              />
            </Flex>
          </VStack>
        )}
      </HStack>
    </VStack>
  );
}

function TwitterIndicators({ zipcodes = [], accessToken, onFinishLoading }) {
  const [indicators, setIndicators] = useState([]);

  useEffect(() => {
    getTwitterIndicators(undefined, zipcodes, [], accessToken).then(
      (result) => {
        const newIndicator = {
          ...(result?.[0] || {}),
          ...[
            "most_active_users",
            "most_influential_users",
            "most_liked_users",
            "hashtags",
          ].reduce(
            (acc, key) => ({
              ...acc,
              [key]: JSON.parse(
                result?.[0]?.[key]?.replaceAll("'", '"') || "{}"
              )
                .values?.sort((a, b) => b.y - a.y)
                .splice(0, 10)
                .sort((a, b) => a.y - b.y)
                .map((val) => ({ x: val.y, y: val.x })),
            }),
            {}
          ),
        };
        setIndicators([newIndicator]);
        if (onFinishLoading)
          onFinishLoading(
            !!Object.keys(result?.[0] || {}).find((key) => !!result[0][key])
          );
      }
    );
  }, [accessToken, onFinishLoading, zipcodes]);

  const UserRank: React.FC<ICellTextProps> = (props) => {
    const { field, value } = props;
    const values = indicators?.[0]?.[field]?.map((val) => val.x) || [];
    const [minRank, maxRank] = [Math.min(...values), Math.max(...values)];
    const percentage = Math.round(
      (100 * (value - minRank)) / (maxRank - minRank)
    );
    return (
      <Flex position="relative" h="100%" w={`${percentage}%`} bg="gray.100">
        <Text>{value}</Text>
      </Flex>
    );
  };

  return (
    <VStack>
      {!Object.values(indicators?.[0] || {}).filter((ind) => !!ind).length && (
        <Text>Not enough data</Text>
      )}
      {!!Object.values(indicators?.[0] || {}).filter((ind) => !!ind).length && (
        <SimpleGrid
          columns={6}
          width="100%"
          alignItems="flex-start"
          spacing={3}
        >
          {[
            "mean_favorite_count",
            "mean_rt_count",
            "users_count",
            "tweets_count",
            "spanish_users_count",
            "platforms_count",
          ].map((key) =>
            typeof indicators?.[0]?.[key] === "number" ? (
              <Stat
                key={key}
                bg="#D6D9DF"
                borderRadius={10}
                p={3}
                alignItems="center"
                h="100%"
              >
                <StatLabel>
                  {camelCaseWords(
                    key.replace("_count", "").replace("rt", "retweet")
                  )}
                </StatLabel>
                <StatNumber fontWeight="Bold">
                  {indicators?.[0]?.[key]}
                </StatNumber>
              </Stat>
            ) : (
              <div></div>
            )
          )}
          <SimpleGrid columns={3} gridColumn="span 6">
            {[
              "platforms_distribution",
              "hispanic_platforms_distribution",
              "spanish_tweets",
            ].map((key) => (
              <Text key={key} fontSize={18} fontWeight="bold">
                {camelCaseWords(key)}
              </Text>
            ))}
            {["platforms_distribution", "hispanic_platforms_distribution"].map(
              (key) => (
                <PieForTable
                  key={key}
                  height={200}
                  width={200}
                  value={
                    JSON.parse(
                      indicators?.[0]?.[key]?.replaceAll("'", '"') || "{}"
                    ).values
                  }
                />
              )
            )}
            <PieForTable
              height={200}
              width={200}
              value={[
                { x: "Spanish", y: indicators?.[0]?.spanish_tweets_count },
                {
                  x: "Rest of tweets",
                  y:
                    indicators?.[0]?.tweets_count -
                    indicators?.[0]?.spanish_tweets_count,
                },
              ]}
            />
          </SimpleGrid>
          {!!indicators?.[0]?.["hashtags"]?.length && (
            <Box gridColumn="span 6">
              <Text fontSize={18} fontWeight="bold">
                Top 10 Hashtags
              </Text>
              {indicators?.[0]?.hashtags?.find(
                (hash) => !!hash.x && hash.x !== 1
              ) && (
                <EntityTable
                  initialTableProps={{
                    rowKeyField: "hashtags",
                    filteringMode: FilteringMode.None,
                    columns: [
                      {
                        title: "Hashtag",
                        key: "y",
                        Render: HashTag,
                      },
                      {
                        title: "Rank",
                        key: "x",
                        Render: UserRank,
                        sortDirection: SortDirection.Descend,
                      },
                    ],
                  }}
                  hideAddColumn
                  dataFromOutside={indicators?.[0]?.hashtags || []}
                />
              )}
              {!indicators?.[0]?.hashtags?.find(
                (hash) => !!hash.x && hash.x !== 1
              ) && (
                <Wrap>
                  {indicators?.[0]?.hashtags.map((hash) => (
                    <WrapItem align="center">
                      <HashTag value={hash.y} />
                    </WrapItem>
                  ))}
                </Wrap>
              )}
            </Box>
          )}
          {[
            "most_active_users",
            "most_influential_users",
            "most_liked_users",
          ].map((key) => (
            <Box key={key} gridColumn="span 2">
              <Text fontSize={18} fontWeight="bold">
                {camelCaseWords(key)}
              </Text>
              <EntityTable
                key={key}
                initialTableProps={{
                  key,
                  filteringMode: FilteringMode.None,
                  columns: [
                    {
                      title: "User",
                      key: "y",
                      Render: UserTag,
                      style: { width: 100, overflow: "hidden" },
                    },
                    {
                      title: "Rank",
                      key: "x",
                      Render: UserRank,
                      style: { width: 100 },
                      sortDirection: SortDirection.Descend,
                    },
                  ],
                }}
                hideAddColumn
                dataFromOutside={indicators?.[0]?.[key] || []}
              />
            </Box>
          ))}
        </SimpleGrid>
      )}
    </VStack>
  );
}

function TopicsTab({
  clickedObject,
  accessToken,
  orgConfig,
  onFinishLoading,
  tabIsActive,
}) {
  const [treeData, setTreeData] = useState();
  const [indicatorsResult, setIndicatorsResult] = useState();
  const [nlpData, setNlpData] = useState();
  const zipcodeLabel = orgConfig?.zipcodeNameReplacement || "Zipcode";
  const storeLabel = orgConfig?.storeNameReplacement || "Store";

  useEffect(() => {
    if (clickedObject?.address && clickedObject?.id) {
      getStoreNLPInsights(accessToken, undefined, [clickedObject?.id]).then(
        setNlpData
      );
    }
  }, [accessToken, clickedObject]);

  useEffect(() => {
    const parseTopicData = (topicData) => {
      if (!topicData || topicData.length === 0) {
        setTreeData(null);
        return;
      }
      const parsedData = Object.keys(topicData[0])
        .filter(
          (key) =>
            !key.includes("words_") &&
            !["date", "id", "spanish_tweets", "zipcode"].includes(key)
        )
        .map((topic) => {
          const score = topicData[0][topic];
          const words = topicData[0][`words_` + topic];
          let json = {
            children:
              (words &&
                words.map((word) => ({ name: camelCaseWords(word) }))) ||
              undefined,
            score,
          };
          return json ? { name: camelCaseWords(topic), ...json } : undefined;
        })
        .filter((data) => !!data)
        .sort((a, b) => (a.score > b.score ? -1 : a.score < b.score ? 1 : 0));
      setTreeData({ name: "", children: parsedData });
    };

    if (clickedObject) {
      getTopics([clickedObject.zipcode], accessToken).then(parseTopicData);
    }
  }, [accessToken, clickedObject, onFinishLoading]);

  useEffect(() => {
    if (typeof indicatorsResult === "boolean") {
      if (onFinishLoading) onFinishLoading(indicatorsResult, "Social");
    }
  }, [indicatorsResult, onFinishLoading]);

  return (
    <Flex flexDir="column">
      <Text fontSize={24} fontWeight="bold" mb={5}>
        What people talk more about in this {pluralize(zipcodeLabel, 1)}
      </Text>
      <VStack>
        {treeData && (
          <TagCloud
            style={{ width: "50%", textAlign: "center" }}
            minSize={12}
            maxSize={35}
            tags={treeData.children?.map((child) => ({
              value: child.name,
              count: child.score,
            }))}
          />
        )}
        {treeData && (
          <RadialTree
            h={400}
            w="100%"
            mt={5}
            data={treeData}
            dimensions={{
              height: 400,
              width: 650,
              margin: { top: 10, right: 10, bottom: 10, left: 10 },
            }}
          />
        )}
      </VStack>
      {!!nlpData?.length && (
        <>
          <Text fontSize={24} fontWeight="bold" mb={5}>
            What people say about this {storeLabel}
          </Text>
          {nlpData.length === 1 ? (
            <NLPInsightsSource data={nlpData[0]} />
          ) : (
            nlpData.map((d) => <NLPInsightsSource data={d} />)
          )}
        </>
      )}
      <Text fontSize={24} fontWeight="bold" mb={5}>
        Twitter Indicators
      </Text>
      {tabIsActive && (
        <TwitterIndicators
          zipcodes={[clickedObject?.zipcode]}
          accessToken={accessToken}
          onFinishLoading={setIndicatorsResult}
        />
      )}
    </Flex>
  );
}
export default connect((state) => ({
  clickedObject: (
    selectVisState(state)?.clicked || selectVisState(state)?.customClicked
  )?.object?.properties,
  accessToken: state.app.accessToken,
  orgConfig: state.app.orgProperties?.properties,
}))(TopicsTab);
