import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { MouseEventHandler } from "react";
import { toast } from "react-toastify";
import { MessageAction } from "../contexts/MessageModalContext";
import { Product } from "../domain/Product";
import { StoreLocatorConfiguration } from "../features/store-locator/StoreLocatorConfig";
import {
  getOrgProperties,
  getProducts,
  getProperties,
  putOrgProperties,
} from "../services/api.service";
import { RootState } from "./store";

export type OrgProperties = {
  id: number;
  organization: {
    id: number;
    name: string;
  };
  organization_id: number;
  facebook_ad_config: object;
  facebook_dathic_config: object;
  properties?: any;
  facebook_leads?: object;
  store_locator?: StoreLocatorConfiguration;
  dathic_analysis?: any;
};

type UploadProxy = {
  name: string;
  props?: any;
};

export type StatusMessageObject = {
  title?: string;
  message?: string;
  actions?: {
    action: MouseEventHandler<HTMLButtonElement>;
    label: string;
    [key: string]: any;
  }[];
};

export type StatusMessage = {
  id: string | number;
  title: string | React.ReactNode;
  loading?: boolean;
  message?: string;
  bg?: string;
  objects?: StatusMessageObject[];
  action?: MessageAction;
};

export type AppState = {
  appName: string;
  loaded: boolean;
  loginStatus: string;
  currentUser?: any;
  readOnly: boolean;
  accessToken?: string;
  selectedDateRange?: {
    start: Date;
    end: Date;
  };
  properties: { name: string; value: any }[];
  products: Product[];
  orgProperties?: OrgProperties;
  s3client?: any;
  openTours: string[];
  openUploadProxy: UploadProxy[];
  configIsUpdating: boolean;
  predictionModalOpen: boolean;
  statusMessages: StatusMessage[];
};

// INITIAL_STATE
const initialState: AppState = {
  appName: "example",
  loaded: false,
  loginStatus: "idle",
  currentUser: undefined,
  readOnly: true,
  accessToken: "",
  selectedDateRange: {
    start: DateTime.now().minus({ month: 1 }).toJSDate(),
    end: new Date(),
  },
  properties: [],
  products: [],
  orgProperties: undefined,
  s3client: undefined,
  openTours: [],
  configIsUpdating: false,
  predictionModalOpen: false,
  openUploadProxy: [],
  statusMessages: [],
};

export const fetchProperties = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("app/fetchProperties", async (payload, { getState, dispatch }) => {
  const { accessToken } = getState().app;
  const properties = await getProperties(accessToken);
  dispatch(setProperties(properties));
});

export const fetchOrgProperties = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("app/fetchOrgProperties", async (payload, { getState, dispatch }) => {
  const { accessToken } = getState().app;
  const properties = await getOrgProperties(accessToken);
  dispatch(setOrgProperties(properties));
  return properties;
});

export const updateOrgProperties = createAsyncThunk<
  void,
  any,
  { state: RootState }
>("app/updateOrgProperties", async (payload, { getState, dispatch }) => {
  const { accessToken, orgProperties } = getState().app;
  if (!payload.id) {
    payload.id = orgProperties?.id;
  }
  const properties = await putOrgProperties(payload, accessToken);
  dispatch(setOrgProperties(properties));
});

export const fetchProducts = createAsyncThunk<void, void, { state: RootState }>(
  "app/fetchProducts",
  async (payload, { getState, dispatch }) => {
    const { accessToken } = getState().app;
    const products = await getProducts(accessToken);
    dispatch(
      setProducts(
        products?.map(
          (p: any) => new Product({ ...p, status: p.status || "selling" })
        )
      )
    );
    return products;
  }
);

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    appInit: (state) => {
      state.loaded = true;
    },
    setIsPredictionModalOpen: (state, action) => {
      state.predictionModalOpen = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.currentUser =
        typeof action.payload === "function"
          ? action.payload(state.currentUser)
          : action.payload;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setSelectedDateRange: (state, action) => {
      state.selectedDateRange = action.payload;
    },
    setProperties: (state, action) => {
      state.properties = action.payload;
    },
    setOrgProperties: (state, action) => {
      state.orgProperties = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setS3Client: (state, action) => {
      state.s3client = action.payload;
    },
    setOpenTours: (state, action) => {
      state.openTours =
        typeof action.payload === "function"
          ? action.payload(state.openTours)
          : action.payload;
    },
    addOpenTour: (state, action) => {
      state.openTours = Array.from(
        new Set([...state.openTours, action.payload])
      );
    },
    addOpenUploadProxy: (
      state,
      action: { payload: UploadProxy; type: string }
    ) => {
      state.openUploadProxy = [
        ...state.openUploadProxy.filter(
          (uploadProxy) => `${uploadProxy.name}` !== `${action.payload.name}`
        ),
        action.payload,
      ];
    },
    addStatusMessage: (
      state,
      action: { payload: StatusMessage; type: string }
    ) => {
      state.statusMessages = [
        ...(state.statusMessages || []).filter(
          (message) => message.id !== action.payload.id
        ),
        action.payload,
      ];
    },
    removeOpenTour: (state, action) => {
      state.openTours = state.openTours.filter((t) => t !== action.payload);
    },
    removeOpenUploadProxy: (
      state,
      action: { payload: string; type: string }
    ) => {
      state.openUploadProxy = [...state.openUploadProxy].filter(
        (uploadProxy) => `${uploadProxy.name}` !== `${action.payload}`
      );
    },
    removeStatusMessage: (state, action: { payload: string; type: string }) => {
      state.statusMessages = [...state.statusMessages].filter(
        (uploadProxy) => `${uploadProxy.id}` !== `${action.payload}`
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateOrgProperties.pending, (state) => {
        state.configIsUpdating = true;
        toast(`Updating configuration`, {
          autoClose: false,
          toastId: "orgConfigUpdating",
        });
      })
      .addCase(updateOrgProperties.fulfilled, (state) => {
        state.configIsUpdating = false;
        toast.update("orgConfigUpdating", {
          type: toast.TYPE.SUCCESS,
          render: `Configuration saved`,
          autoClose: 5000,
        });
      })
      .addCase(updateOrgProperties.rejected, (state) => {
        state.configIsUpdating = false;
        toast.update("orgConfigUpdating", {
          type: toast.TYPE.ERROR,
          render: `Configuration failed to save`,
        });
      })
      .addMatcher(
        (action) =>
          ["setCurrentUser"].filter((reducerType) =>
            action.type.endsWith(reducerType)
          ).length > 0,
        (state, action) => {
          state.readOnly = !action.payload;
        }
      );
  },
});

// Selectors
export const selectOrgProperties = (state: RootState) =>
  state.app.orgProperties;
export const selectStripeCustomerID = (state: RootState) =>
  state.app.orgProperties?.properties?.customer_id ||
  state.app.orgProperties?.store_locator?.customer_id ||
  state.app.orgProperties?.dathic_analysis?.customer_id;
export const selectAppInitialized = (state: RootState) =>
  !!(
    state.app.properties?.length &&
    state.app.orgProperties &&
    state.app.currentUser
  );
export const selectShopifyDomain = (state: RootState) =>
  state.app.orgProperties?.properties?.shopifyData?.shop?.domain;
export const selectIsShopifyConnected = (state: RootState) =>
  !!state.app.orgProperties?.properties?.shopifyData?.shop?.domain &&
  !!state.app.orgProperties?.properties?.shopifyData?.access_token &&
  !!state.app.orgProperties?.properties?.shopifyData?.api_key;
export const selectIsShopifyApp = (state: RootState) =>
  !!state.app.orgProperties?.properties?.shopifyData?.shop?.domain &&
  !!state.app.orgProperties?.properties?.shopifyData?.access_token &&
  !!state.app.orgProperties?.properties?.shopifyData?.shop?.customer_email;
export const selectS3Client = (state: RootState) => state.app.s3client;
export const selectCurrentUser = (state: RootState) => state.app.currentUser;
export const selectUserResources = (state: RootState) =>
  state.app.currentUser?.resources;
export const selectResources = (state: RootState) =>
  (
    (state.app.properties?.find((properties) => properties.name === "resources")
      ?.value || []) as string[]
  )
    .map<{ id: number; name: string } | undefined>((val) => {
      try {
        return JSON.parse(val.replaceAll("'", '"'));
      } catch (error) {
        return undefined;
      }
    })
    .filter((val) => !!val);
export const selectCurrentUserEmail = (state: RootState) =>
  state.app.currentUser?.email;
export const selectUserRoles = (state: RootState) =>
  state.app.currentUser?.roles;
export const selectAccessToken = (state: RootState) => state.app.accessToken;
export const selectIsReadOnly = (state: RootState) => state.app.readOnly;
export const selectSelectedDateRange = (state: RootState) =>
  state.app.selectedDateRange;
export const selectUserIsInDemo = (state: RootState) =>
  state.app.currentUser?.is_demo;
export const selectProducts = (state: RootState) => state.app.products;
export const selectStates = (state: RootState) =>
  (
    (state.app.properties?.find((properties) => properties.name === "states")
      ?.value || []) as string[]
  )
    .map<{ abbr: string; name: string }>((val: string) => {
      let parsed;
      try {
        parsed = JSON.parse(val.replaceAll("'", '"'));
        return parsed;
      } catch (error) {
        return undefined;
      }
    })
    .filter((val) => !!val);
export const selectStoreTypes = (state: RootState) =>
  state.app.properties?.find((properties) => properties.name === "type")
    ?.value || [];
export const selectChains = (state: RootState) =>
  Array.from<string>(
    state.app.properties?.find((properties) => properties.name === "chain")
      ?.value || []
  ).sort((a, b) => a.length - b.length);
export const selectHelpExiprySec = (state: RootState) =>
  Number(
    state.app.properties?.find(
      (properties) => properties.name === "dathic_growth_help_exipry_sec"
    )?.value?.[0] || undefined
  );
export const selectCategories = (state: RootState) =>
  state.app.properties?.find((properties) => properties.name === "categories")
    ?.value || [];
export const selectOpenTours = (state: RootState) => state.app.openTours;
export const selectOpenUploadProxy = (state: RootState) =>
  state.app.openUploadProxy;
export const selectStatusMessages = (state: RootState) =>
  state.app.statusMessages;
export const selectLatestGrowthVersion = (state: RootState) =>
  state.app.properties?.find(
    (properties) => properties.name === "growth_front_version"
  )?.value?.[0] || "";
export const selectMenus = (state: RootState) =>
  state.app.orgProperties?.properties?.menus ||
  (
    (state.app.properties?.find(
      (properties) => properties.name === "dathic_growth_menus"
    )?.value || []) as string[]
  )
    .map<any | undefined>((val: string) => {
      let parsed;
      try {
        parsed = JSON.parse(val.replaceAll("'", '"'));
        return parsed;
      } catch (error) {
        return undefined;
      }
    })
    .filter((val) => !!val);
export const selectStoreLocatorLookerParams = (state: RootState) =>
  (
    (state.app.properties?.find(
      (properties) => properties.name === "store_locator_looker_params"
    )?.value || []) as string[]
  ).reduce(
    (acc, key) => ({
      ...acc,
      [key]: `${state.app.orgProperties?.organization_id}`,
    }),
    {}
  );
export const selectTidioGuides = (state: RootState) =>
  (
    (state.app.properties?.find(
      (properties) => properties.name === "tidio_guides"
    )?.value || []) as string[]
  )
    .map<{ msg: string; guide: string }>((val: string) => {
      let parsed;
      try {
        parsed = JSON.parse(val.replaceAll("'", '"'));
        return parsed;
      } catch (error) {
        return undefined;
      }
    })
    .filter((val) => !!val);
export const selectLatestStoreLocatorVersion = (state: RootState) =>
  state.app.properties?.find(
    (properties) => properties.name === "storelocator_front_version"
  )?.value?.[0] || "";

export const {
  appInit,
  setAccessToken,
  setSelectedDateRange,
  setIsPredictionModalOpen,
  setCurrentUser,
  setProperties,
  setOrgProperties,
  setProducts,
  setS3Client,
  setOpenTours,
  addOpenTour,
  removeOpenTour,
  addOpenUploadProxy,
  removeOpenUploadProxy,
  addStatusMessage,
  removeStatusMessage,
} = appSlice.actions;

export default appSlice.reducer;
