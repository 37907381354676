import { get, post } from "axios";
import TagManager from "react-gtm-module";
import { toast } from "react-toastify";
import {
  base_url,
  billing_portal_session,
  checkout_session,
  stripe_customer,
  stripe_customer_session,
  stripe_product,
  strip_active_store_locator_invoices,
} from "../constants/serviceConfig";

const errorHandler = (error, noToast) => {
  const response = error.response;
  let message = error.message;
  const code = response?.status || 0;
  switch (code) {
    case 400:
      message = "Item already exists";
      break;
    case 408:
      message = "Your request took too long";
      break;

    default:
      break;
  }
  const tagManagerArgs = {
    dataLayer: {
      event: "error",
      "gtm.errorMessage": `APIError: ${message}`,
      "gtm.errorLineNumber": error.config?.params?.toString(),
      "gtm.errorUrl": error.config?.url,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
  if (!noToast) {
    toast.error(
      `${message}: ${response?.data?.msg || response?.data?.message}`
    );
  }
};

export async function postCheckoutSession(session, accessToken) {
  try {
    let url = base_url;
    url = url + checkout_session;
    const result = await post(url, session, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function postBillingPortalSession(accessToken) {
  try {
    let url = base_url;
    url = url + billing_portal_session;
    const result = await post(url, undefined, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getCustomer(accessToken) {
  try {
    let url = base_url;
    url = url + stripe_customer;
    const result = await get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getCustomerSession(accessToken) {
  try {
    let url = base_url;
    url = url + stripe_customer_session;
    const result = await get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getProducts(accessToken) {
  try {
    let url = base_url;
    url = url + stripe_product;
    const result = await get(url, {});

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getCustomerInvoices(accessToken) {
  try {
    let url = base_url;
    url = url + strip_active_store_locator_invoices;
    const result = await get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}
