import {
  api_insights,
  api_insights_independent_level,
  api_insights_store_nlp_insights,
  base_url,
} from "../constants/serviceConfig";
import axios from "axios";
import { getParams, handleErrorMessage } from "./api.service";

const errorHandler = (error, noToast) => {
  const response = error.response;
  let message = error.message;
  const code = response?.status || 0;
  switch (code) {
    case 400:
      message = "Item already exists";
      break;
    case 408:
      message = "Your request took too long";
      break;

    default:
      break;
  }
  handleErrorMessage(message, error, noToast, response);
};

export async function getInsights(accessToken, parameters, endpoint) {
  try {
    let { params, url } = getParams(parameters);
    url = url + api_insights + (endpoint ? `/${endpoint}` : "");
    const result = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getIndieInsights(
  accessToken,
  insight_spec_id,
  zipcodes = [],
  cities = [],
  counties = [],
  states = []
) {
  try {
    let params = new URLSearchParams();
    let paramsObj = {
      cities,
      states,
      zipcodes,
      counties,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key].forEach((param) => {
        params.append(key, param);
      });
    });
    if (insight_spec_id) params.append("insight_spec_id", insight_spec_id);
    let url = base_url;
    url = url + api_insights_independent_level;
    const result = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getStoreNLPInsights(
  accessToken,
  source = [],
  store_id = []
) {
  try {
    let params = new URLSearchParams();
    let paramsObj = {
      source,
      store_id,
    };
    Object.keys(paramsObj).forEach((key) => {
      paramsObj[key].forEach((param) => {
        params.append(key, param);
      });
    });
    let url = base_url;
    url = url + api_insights_store_nlp_insights;
    const result = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}
