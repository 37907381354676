import {
  Box,
  Button,
  Divider,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  interactionConfigChange,
  layerColorUIChange,
  layerConfigChange,
  layerTextLabelChange,
  layerTypeChange,
  layerVisConfigChange,
  layerVisualChannelConfigChange,
  removeLayer,
  reorderLayer,
  toggleModal,
  updateLayerBlending,
  wrapTo,
} from "kepler.gl/actions";
import {
  appInjector,
  FieldSelectorFactory,
  LayerConfiguratorFactory,
} from "kepler.gl/components";
import { RootContext } from "kepler.gl/dist/components/context";
import { messages } from "kepler.gl/localization";
import { themeLT } from "kepler.gl/styles";
import { useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  IoChatbox,
  IoCloudUpload,
  IoEye,
  IoEyeOff,
  IoSettings,
  IoTrash,
} from "react-icons/io5";
import { MdDragIndicator } from "react-icons/md";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { ThemeProvider } from "styled-components";
import { RESOURCES } from "../../constants/user-constants";
import { setMapSelection } from "../../features/map-selection/mapSelectionSlice";
import { selectVisState } from "../../features/map/keplerReducer";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function KeplerLayerItem({
  layer,
  index,
  onLabelChange,
  toggleVisible,
  removeLayer,
  propsForConfig,
  dataset,
  tooltipConfig,
  userResources,
  interactionConfigChange,
}) {
  const [isHovering, setIsHovering] = useState(false);
  const configDisclosure = useDisclosure();
  const keplerRoot = useRef();

  const LayerConfigurator = appInjector.get(LayerConfiguratorFactory);
  const FieldSelector = appInjector.get(FieldSelectorFactory);
  const dataId = layer.config.dataId;

  return (
    <Draggable
      key={layer.config.dataId}
      draggableId={layer.config.dataId}
      index={index}
    >
      {(provided) => (
        <div
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <Flex
            w="100%"
            bg="whitesmoke"
            p={1}
            borderLeft={`4px solid rgb(${layer.config.color[0]},${layer.config.color[1]},${layer.config.color[2]})`}
            mb={3}
            alignItems="center"
          >
            <div {...provided.dragHandleProps}>
              <MdDragIndicator
                size="xl"
                visibility={isHovering ? "visible" : "hidden"}
              />
            </div>
            <Editable
              defaultValue={layer.config.label}
              onChange={onLabelChange}
            >
              <EditablePreview
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                width={130}
                fontSize="md"
              />
              <EditableInput />
            </Editable>
            <Box ml="auto">
              {!!isHovering && (
                <>
                  <IconButton
                    icon={<IoTrash />}
                    variant="link"
                    onClick={removeLayer}
                  />
                  <IconButton
                    icon={layer.config.isVisible ? <IoEye /> : <IoEyeOff />}
                    variant="link"
                    onClick={toggleVisible}
                  />
                  <Popover isLazy>
                    <PopoverTrigger>
                      <IconButton
                        icon={<IoChatbox />}
                        variant="link"
                        isDisabled={
                          !userResources?.includes(RESOURCES.LAYER_EDIT_CONFIG)
                        }
                      />
                    </PopoverTrigger>
                    <PopoverContent height={400}>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Tooltip Fields</PopoverHeader>
                      <PopoverBody overflowY="auto" h="100%">
                        <IntlProvider locale={"en"} messages={messages["en"]}>
                          <ThemeProvider theme={themeLT}>
                            <FieldSelector
                              fields={dataset.fields}
                              value={tooltipConfig.config.fieldsToShow[dataId]}
                              onSelect={(selected) => {
                                const newConfig = {
                                  ...tooltipConfig.config,
                                  fieldsToShow: {
                                    ...tooltipConfig.config.fieldsToShow,
                                    [dataId]: selected.map(
                                      (f) =>
                                        tooltipConfig.config.fieldsToShow[
                                          dataId
                                        ].find(
                                          (tooltipField) =>
                                            tooltipField.name === f.name
                                        ) || {
                                          name: f.name,
                                          // default initial tooltip is null
                                          format: null,
                                        }
                                    ),
                                  },
                                };
                                interactionConfigChange({
                                  ...tooltipConfig,
                                  config: newConfig,
                                });
                              }}
                              closeOnSelect={false}
                              multiSelect
                              inputTheme="secondary"
                            />
                          </ThemeProvider>
                        </IntlProvider>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </>
              )}
              <Popover
                isLazy
                placement="right"
                isOpen={configDisclosure.isOpen}
              >
                <PopoverTrigger>
                  <IconButton
                    icon={<IoSettings />}
                    variant="link"
                    isDisabled={
                      !userResources?.includes(RESOURCES.LAYER_EDIT_CONFIG)
                    }
                    onClick={() => configDisclosure.onOpen()}
                  />
                </PopoverTrigger>
                <PopoverContent height={400}>
                  <PopoverArrow />
                  <PopoverCloseButton
                    onClick={() => configDisclosure.onClose()}
                  />
                  <PopoverHeader>Layer configuration</PopoverHeader>
                  <PopoverBody overflowY="auto">
                    <Box position="relative">
                      <RootContext.Provider value={keplerRoot}>
                        <IntlProvider locale={"en"} messages={messages["en"]}>
                          <Box w="100%" ref={keplerRoot}>
                            <ThemeProvider theme={themeLT}>
                              <LayerConfigurator
                                layer={layer}
                                {...propsForConfig}
                              />
                            </ThemeProvider>
                          </Box>
                        </IntlProvider>
                      </RootContext.Provider>
                    </Box>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          </Flex>
        </div>
      )}
    </Draggable>
  );
}

function KeplerLayerList({
  viewId,
  openAddDataModal,
  changeLayerBlending,
  layerBlending,
  layers,
  layerOrder,
  reorderLayers,
  changeLayerConfig,
  removeLayer,
  datasets,
  layerTypeOptions,
  updateLayerColorUI,
  updateLayerVisualChannelConfig,
  updateLayerType,
  updateLayerTextLabel,
  updateLayerVisConfig,
  mapSelection,
  setSelection,
  userResources,
  tooltipConfig,
  interactionConfigChange,
  ...other
}) {
  const handleReorder = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      layerOrder,
      result.source.index,
      result.destination.index
    );

    reorderLayers(viewId, items);
  };

  const removeSelectedItems = (layer) => {
    if (
      layer.config?.dataId?.includes("selected_audience") ||
      layer.config?.dataId?.includes("fetched_stores")
    ) {
      const layerType = layer.config?.dataId?.includes("selected_audience")
        ? "zipcodes"
        : "stores";
      const leftOverItems = layers
        .filter(
          (layerItem) =>
            layerItem.id !== layer.id &&
            layerItem.config.dataId.includes(
              layerType === "zipcodes" ? "selected_audience" : "fetched_stores"
            )
        )
        .flatMap((layerItem) => layerItem.dataToFeature)
        ?.map((feature) => feature?.properties)
        .filter((prop) => !!prop);
      const newSelectedItems = mapSelection?.[layerType]?.filter(
        (item) =>
          !!leftOverItems.find((searchItem) => searchItem.id === item.id)
      );

      setSelection((oldSelection) => ({
        ...(oldSelection || {}),
        [layerType]: newSelectedItems,
      }));
    }
  };
  const datasetForLayer = (layer) => {
    return datasets[layer.config.dataId];
  };
  return (
    <VStack {...other}>
      <Button
        leftIcon={<IoCloudUpload />}
        onClick={() => openAddDataModal(viewId)}
        mb={3}
        colorScheme="blue"
      >
        Create layer from file
      </Button>
      <DragDropContext onDragEnd={handleReorder}>
        <Droppable droppableId="keplerLayersList">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ width: "100%" }}
            >
              {(layerOrder || [])
                .map((layerIndex) => {
                  return layers[layerIndex];
                })
                .map((layer, index) => (
                  <KeplerLayerItem
                    key={index}
                    dataset={datasetForLayer(layer)}
                    userResources={userResources}
                    tooltipConfig={tooltipConfig}
                    interactionConfigChange={(config) => {
                      interactionConfigChange(viewId, config);
                    }}
                    layer={layer}
                    index={index}
                    onLabelChange={(newLabel) => {
                      changeLayerConfig(viewId, layer, { label: newLabel });
                    }}
                    toggleVisible={() =>
                      changeLayerConfig(viewId, layer, {
                        isVisible: !layer.config.isVisible,
                      })
                    }
                    removeLayer={() => {
                      removeSelectedItems(layer);
                      const layerIdx = layerOrder[index];
                      removeLayer(viewId, layerIdx);
                    }}
                    propsForConfig={{
                      datasets,
                      layerTypeOptions,
                      updateLayerColorUI: (...params) => {
                        return updateLayerColorUI(viewId, layer, ...params);
                      },
                      updateLayerConfig: (...params) => {
                        return changeLayerConfig(viewId, layer, ...params);
                      },
                      updateLayerVisualChannelConfig: (...params) => {
                        return updateLayerVisualChannelConfig(
                          viewId,
                          layer,
                          ...params
                        );
                      },
                      updateLayerType: (...params) => {
                        return updateLayerType(viewId, layer, ...params);
                      },
                      updateLayerTextLabel: (...params) => {
                        return updateLayerTextLabel(viewId, layer, ...params);
                      },
                      updateLayerVisConfig: (...params) => {
                        return updateLayerVisConfig(viewId, layer, ...params);
                      },
                    }}
                  />
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Divider />
      <FormControl
        value={layerBlending}
        onChange={(e) => changeLayerBlending(viewId, e.target.value)}
      >
        <FormLabel>Layer Blending</FormLabel>
        <Select variant="outline" colorScheme="gray">
          <option>additive</option>
          <option>normal</option>
          <option>subtractive</option>
        </Select>
      </FormControl>
    </VStack>
  );
}

export default connect(
  (state) => {
    const visState = selectVisState(state);
    return {
      viewId:
        state?.mapViews?.views?.[`view${state?.mapViews?.activeView}`]?.id,
      tooltipConfig: visState?.interactionConfig?.tooltip,
      layers: visState?.layers,
      layerOrder: visState?.layerOrder,
      userResources: state.app.currentUser.resources,
      layerBlending: visState?.layerBlending,
      datasets: visState?.datasets,
      mapSelection:
        state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
          ?.selection,
      layerTypeOptions: visState?.layerClasses
        ? Object.keys(visState.layerClasses).map((key) => {
            const layer = new visState.layerClasses[key]();
            return {
              id: key,
              label: layer.name,
              icon: layer.layerIcon,
              requireData: layer.requireData,
            };
          })
        : [],
    };
  },
  (dispatch) => ({
    openAddDataModal: (viewId) =>
      dispatch(wrapTo(`view${viewId}`, toggleModal("addData"))),
    removeLayer: (viewId, layerId) =>
      dispatch(wrapTo(`view${viewId}`, removeLayer(layerId))),
    interactionConfigChange: (viewId, config) =>
      dispatch(wrapTo(`view${viewId}`, interactionConfigChange(config))),
    changeLayerBlending: (viewId, newBlending) =>
      dispatch(wrapTo(`view${viewId}`, updateLayerBlending(newBlending))),
    reorderLayers: (viewId, newOrder) =>
      dispatch(wrapTo(`view${viewId}`, reorderLayer(newOrder))),
    changeLayerConfig: (viewId, layer, newConfig) =>
      dispatch(wrapTo(`view${viewId}`, layerConfigChange(layer, newConfig))),
    updateLayerColorUI: (viewId, ...other) =>
      dispatch(wrapTo(`view${viewId}`, layerColorUIChange(...other))),
    updateLayerVisualChannelConfig: (viewId, ...other) =>
      dispatch(
        wrapTo(`view${viewId}`, layerVisualChannelConfigChange(...other))
      ),
    updateLayerType: (viewId, ...other) =>
      dispatch(wrapTo(`view${viewId}`, layerTypeChange(...other))),
    updateLayerTextLabel: (viewId, ...other) =>
      dispatch(wrapTo(`view${viewId}`, layerTextLabelChange(...other))),
    updateLayerVisConfig: (viewId, ...other) =>
      dispatch(wrapTo(`view${viewId}`, layerVisConfigChange(...other))),
    setSelection: (newSelection) => dispatch(setMapSelection(newSelection)),
  })
)(KeplerLayerList);
