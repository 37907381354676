import React from "react";
import {
  PanelHeaderFactory,
  SaveExportDropdownFactory,
  PanelHeaderDropdownFactory,
} from "kepler.gl/components";

// Custom Panel Header renders default panel header, changing its default props
// to avoid rendering any action items on the top right
export function CustomPanelHeaderFactory() {
  const PanelHeader = PanelHeaderFactory(
    SaveExportDropdownFactory(PanelHeaderDropdownFactory())
  );

  PanelHeader.defaultProps = {
    ...PanelHeader.defaultProps,
    logoComponent: () => <div></div>,
  };
  return PanelHeader;
}

export default CustomPanelHeaderFactory;
