import { Input } from "@chakra-ui/input";
import { Box } from "@chakra-ui/layout";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { useRef, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { HStack, Text, VStack } from "@chakra-ui/react";

const LocationSearchInput = ({
  onGeocode,
  style,
  inputProps,
  value,
  onChange,
  onTimeout,
}) => {
  const [isGeocoding, setIsGeocoding] = useState(false);
  const [address, setAddress] = useState(inputProps?.defaultValue || "");
  const [errorMessage, setErrorMessage] = useState("");
  const [_suggestions, setSuggestions] = useState();
  const [shouldFetchSuggestions, setShouldFetchSuggestions] = useState(false);
  const typeTimeout = useRef();

  const handleChange = (address) => {
    if (onChange) onChange(address);
    setAddress(address);
    setErrorMessage("");
    clearTimeout(typeTimeout.current);
    setSuggestions();
    setShouldFetchSuggestions(false);
    typeTimeout.current = setTimeout(() => {
      if (onTimeout) onTimeout(address);
      if (address?.length > 2) {
        setShouldFetchSuggestions(true);
      }
    }, 400);
  };

  const handleSelect = (address) => {
    setErrorMessage("");
    setSuggestions();
    setShouldFetchSuggestions(false);
    setIsGeocoding(true);
    setAddress(address);
    geocodeByAddress(address)
      .then((res) => {
        setIsGeocoding(false);
        if (onGeocode && res?.length) onGeocode(res[0]);
      })
      .catch((error) => {
        setIsGeocoding(false);
        console.log("error", error); // eslint-disable-line no-console
      });
  };

  const handleError = (status, clearSuggestions) => {
    console.log("Error from Google Maps API", status); // eslint-disable-line no-console
    setErrorMessage(status);
    clearSuggestions();
  };

  return (
    <Box style={style}>
      <PlacesAutocomplete
        onChange={handleChange}
        onSelect={handleSelect}
        value={typeof value === "string" ? value : address}
        onError={handleError}
        shouldFetchSuggestions={shouldFetchSuggestions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          if (suggestions?.length) {
            setSuggestions(suggestions);
          }
          return (
            <div style={{ postition: "relative", zIndex: 1 }}>
              <Input
                {...getInputProps({
                  placeholder: "Search address or name of place ...",
                  ...(inputProps || {}),
                })}
              />
              {loading && <div>Loading...</div>}
              <Menu isOpen={shouldFetchSuggestions && !!_suggestions?.length}>
                <MenuButton
                  h={0}
                  w="100%"
                  mt={0}
                  m={0}
                  display="block"
                ></MenuButton>
                <MenuList maxHeight={200} overflowY="auto">
                  <MenuItem key={address} onClick={() => setSuggestions()}>
                    <HStack>
                      <Text>Search for</Text> <strong>{address}</strong>
                    </HStack>
                  </MenuItem>
                  {_suggestions?.map((option, index) => (
                    <MenuItem key={index} {...getSuggestionItemProps(option)}>
                      <VStack alignItems="flex-start">
                        <strong>{option.formattedSuggestion.mainText}</strong>
                        <small>
                          {option.formattedSuggestion.secondaryText}
                        </small>
                      </VStack>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </div>
          );
        }}
      </PlacesAutocomplete>
      {errorMessage.length > 0 && (
        <div className="Demo__error-message">{errorMessage}</div>
      )}

      {isGeocoding && (
        <div>
          <h3 className="Demo__geocode-result-header">Geocode result</h3>
          {isGeocoding && (
            <div>
              <i className="fa fa-spinner fa-pulse fa-3x fa-fw Demo__spinner" />
            </div>
          )}
        </div>
      )}
    </Box>
  );
};

export default LocationSearchInput;
