import {
  Box,
  Button,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import { selectProducts } from "../../app/appSlice";
import { useAppSelector } from "../../app/store";

type Props = {
  value: string[];
  onChange: (newFiltered: string[]) => void;
  exclude?: number[];
  only?: number[];
};

const StoreFilterByProduct: FunctionComponent<Props> = ({
  value,
  onChange,
  exclude,
  only,
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const products = useAppSelector(selectProducts);

  return (
    <Menu closeOnSelect={false} placement="bottom-start">
      <MenuButton as={Button}>
        By product{value.length ? ` (${value.length})` : ""}
      </MenuButton>
      <MenuList
        width="300px"
        maxH="400px"
        display="flex"
        flexDirection="column"
      >
        <Box h={8} w="100%">
          <Input
            placeholder="Search product"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            h={8}
          />
        </Box>
        <MenuDivider />
        <Box w="100%" flex={1} overflowY="auto">
          <MenuOptionGroup
            title="Products"
            type="checkbox"
            value={value}
            onChange={(newFilteredProducts) =>
              onChange(
                typeof newFilteredProducts === "string"
                  ? [newFilteredProducts]
                  : newFilteredProducts
              )
            }
          >
            {products
              .filter((p) => value.includes(`${p.id}`))
              .map((p) => (
                <MenuItemOption value={`${p.id}`}>
                  <Text
                    noOfLines={3}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    maxH="4.5em"
                    fontSize="sm"
                  >
                    {p.name}
                  </Text>
                </MenuItemOption>
              ))}
            {products
              .filter(
                (p) =>
                  p.name.toLowerCase().includes(searchText.toLowerCase()) &&
                  !value.includes(`${p.id}`) &&
                  (!exclude?.length || exclude.find((e) => e !== p.id)) &&
                  (!only || only.includes(p.id))
              )
              .map((p) => (
                <MenuItemOption value={`${p.id}`}>
                  <Text
                    noOfLines={3}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    maxH="4.5em"
                  >
                    {p.name}
                  </Text>
                </MenuItemOption>
              ))}
          </MenuOptionGroup>
        </Box>
      </MenuList>
    </Menu>
  );
};

export default StoreFilterByProduct;
