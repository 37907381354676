import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Select } from "@chakra-ui/select";
import React from "react";
import { connect } from "react-redux";
import { setActiveIntegrations } from "../features/data-integrations/integrationSlice";
import { getCreatives } from "../services/facebook_api.service";

function FacebookAdAccountSelect({
  adAccounts,
  selectedAdAccount,
  setSelectedAdAccount,
  setCreatives,
  accessToken,
  onChange,
  ...other
}) {
  function updateCreativesList(newAdAccount) {
    getCreatives(adAccounts[newAdAccount].account_id, accessToken).then(
      setCreatives
    );
  }
  return (
    <FormControl isRequired {...other}>
      <FormLabel>Ad Account:</FormLabel>
      <Select
        value={adAccounts?.findIndex(
          (item) => item.id === selectedAdAccount?.id
        )}
        onChange={(event) => {
          const adAccountIndex = event.target.value;
          localStorage.setItem(
            "selectedAdAccount",
            adAccounts[adAccountIndex].id
          );
          setSelectedAdAccount(adAccounts[adAccountIndex]);
          updateCreativesList(adAccountIndex);
          if (onChange) onChange(adAccounts[adAccountIndex]);
        }}
      >
        <option value={undefined} disabled selected>
          -- Choose an ad account --
        </option>
        {adAccounts?.map((account, index) => (
          <option key={account.id} value={index}>
            {account.name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
export default connect(
  (state) => ({
    accessToken: state.app.accessToken,
    adAccounts: state.integrations.activeIntegrations?.facebook?.adAccounts,
    selectedAdAccount:
      state.integrations.activeIntegrations?.facebook?.selectedAdAccount,
  }),
  (dispatch) => ({
    setSelectedAdAccount: (adAccount) =>
      dispatch(
        setActiveIntegrations((otherIntegrations) => ({
          ...otherIntegrations,
          facebook: {
            ...(otherIntegrations.facebook || {}),
            selectedAdAccount: adAccount,
          },
        }))
      ),
    setCreatives: (creatives) =>
      dispatch(
        setActiveIntegrations((otherIntegrations) => ({
          ...otherIntegrations,
          facebook: {
            ...(otherIntegrations.facebook || {}),
            creatives,
          },
        }))
      ),
  })
)(FacebookAdAccountSelect);
