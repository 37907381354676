import { CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Spacer,
  Tag,
  TagLabel,
  Text,
  VStack,
} from "@chakra-ui/react";
import pluralize from "pluralize";
import React, { useCallback, useContext, useMemo } from "react";
import { IoCube } from "react-icons/io5";
import { toast } from "react-toastify";
import {
  selectAccessToken,
  selectOrgProperties,
  selectProducts,
  selectUserResources,
} from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import AutocompleteInput from "../../components/AutocompleteInput";
import { RESOURCES } from "../../constants/user-constants";
import MessageModalContext from "../../contexts/MessageModalContext";
import { Product } from "../../domain/Product";
import { ProductStore } from "../../domain/ProductStore";
import { Store } from "../../domain/Store";
import {
  deleteProductStoresBatch,
  postProductStoresBatch,
  putProductStoresBatch,
} from "../../services/api.service";

type Props = {
  stores?: Store[];
  onFinish?: (newStores: Store[]) => void;
};

type FormProps = {
  dialogState: {
    selectedProducts: ProductStore[];
    toAdd: ProductStore[];
    toUpdate: ProductStore[];
    toDelete: ProductStore[];
    stores: Store[];
    status?: string;
    isLoading?: boolean;
  };
  setDialogState: React.Dispatch<any>;
};

function _getSelectedProducts(selectedProducts: ProductStore[]) {
  return Array.from(new Set(selectedProducts.map((ps) => ps.product_id))).map(
    (product_id) => {
      const productStores = selectedProducts.filter(
        (s) => s.product_id === product_id
      );
      const product = selectedProducts.find(
        (s) => s.product_id === product_id && s.product
      )?.product;
      const units = productStores?.[0]?.units;
      const allProductStoreHaveSameUnit =
        typeof units === "number" &&
        productStores.every((s) => s.units === units);
      return {
        product_id,
        units: allProductStoreHaveSameUnit ? units : undefined,
        product,
      };
    }
  );
}

function BatchProductStoreForm({ dialogState, setDialogState }: FormProps) {
  const orgProperties = useAppSelector(selectOrgProperties);
  const userResources = useAppSelector(selectUserResources);
  const products = useAppSelector(selectProducts);
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";
  const { selectedProducts, stores } = dialogState || {};

  const originalProductStores = useMemo(
    () => stores.flatMap((s) => s.products || []),
    [stores]
  );

  const handleSelectedProductChange = useCallback(
    (newSelection: ProductStore[]) => {
      const toAdd = newSelection.filter((ps) => !ps.id);
      const toUpdate = newSelection.filter(
        (ps) =>
          !!ps.id &&
          originalProductStores.find((ops) => ops.id === ps.id)?.units !==
            ps.units
      );
      const toDelete = originalProductStores.filter(
        (ps) => !!ps.id && !newSelection.find((s) => s.id === ps.id)
      );
      setDialogState((oldState: FormProps["dialogState"]) => ({
        ...(oldState || {}),
        selectedProducts: newSelection,
        toAdd,
        toUpdate,
        toDelete,
      }));
    },
    [originalProductStores, setDialogState]
  );

  function notInAllStores(item: {
    product: Product | undefined;
    product_id: number | undefined;
    units: number | undefined;
  }) {
    return (
      new Set(
        selectedProducts
          .filter((ps) => ps.product_id === item.product_id)
          .map((ps) => ps.store_id)
      ).size < stores.length
    );
  }

  function inStoreCount(item: {
    product: Product | undefined;
    product_id: number | undefined;
    units: number | undefined;
  }) {
    return new Set(
      selectedProducts
        .filter((ps) => ps.product_id === item.product_id)
        .map((ps) => ps.store_id)
    ).size;
  }

  function addToAllStores(item: {
    product: Product | undefined;
    product_id: number | undefined;
    units: number | undefined;
  }) {
    return () => {
      const newSelection = stores
        .filter(
          (s) =>
            !selectedProducts.find(
              (ps) => ps.store_id === s.id && ps.product_id === item.product_id
            )
        )
        .map((s) => new ProductStore({ ...item, store_id: s.id }));
      const newProducts = [
        ...(selectedProducts || []),
        ...newSelection.filter((r: any) => !!r),
      ];
      handleSelectedProductChange(newProducts);
    };
  }

  function handleResultClick() {
    return async (selection: { suggestion: Product }) => {
      const selectedAll = selection.suggestion.sku === "all_products";
      const newSelection = stores
        .flatMap<ProductStore>((store) => {
          return selectedAll
            ? products.map(
                (p) =>
                  new ProductStore({
                    store_id: store?.id,
                    product_id: p.id,
                    product: p,
                  })
              )
            : [
                new ProductStore({
                  store_id: store?.id,
                  product_id: selection.suggestion.id,
                  product: selection.suggestion,
                }),
              ];
        })
        .filter(
          (ps) =>
            !selectedProducts.find(
              (s) =>
                s.store_id === ps.store_id && s.product_id === ps.product_id
            )
        );
      if (newSelection.length) {
        const newProducts = [
          ...(selectedProducts || []),
          ...newSelection.filter((r: any) => !!r),
        ];
        handleSelectedProductChange(newProducts);
      }
    };
  }

  function productsToExclude() {
    return Array.from(
      new Set(
        [...originalProductStores, ...selectedProducts].map(
          (ps) => ps.product_id
        )
      )
    )
      .map((product_id) => products.find((p) => p.id === product_id))
      .filter((p) => !!p);
  }

  return (
    <VStack>
      <FormControl
        w="100%"
        h={"100%"}
        mb={3}
        isDisabled={!userResources?.includes(RESOURCES.STORE_PRODUCTS_CREATE)}
      >
        <FormLabel>Add In-{storeLabel} products</FormLabel>
        <AutocompleteInput
          options={[
            { sku: "all_products", name: "All" } as unknown as Product,
          ].concat(products)}
          getSuggestionValue={(s: Product) => {
            return s.name;
          }}
          handleSearchResultClick={handleResultClick()}
          exclude={productsToExclude()}
          highlightFirstSuggestion={false}
          disabled={undefined}
          value={undefined}
          onChange={undefined}
          focusInputOnSuggestionClick={undefined}
          inputProps={undefined}
          fetchSuggestions={undefined}
          onNewSuggestion={undefined}
        />
        <VStack mt={2} overflowY={"auto"} h={400}>
          {_getSelectedProducts(selectedProducts).map((item) => (
            <Tag
              key={item.product_id}
              p={3}
              w={"100%"}
              minH={notInAllStores(item) ? "74px" : "40px"}
            >
              <VStack alignItems="flex-start">
                <TagLabel>{item.product?.name}</TagLabel>
                {notInAllStores(item) && (
                  <HStack>
                    <Text>
                      Only in {pluralize(storeLabel, inStoreCount(item), true)}
                    </Text>
                    <Button onClick={addToAllStores(item)}>Add to all</Button>
                  </HStack>
                )}
              </VStack>
              <Input
                placeholder="Units"
                type="number"
                w={75}
                ml="auto"
                mr={3}
                min={0}
                defaultValue={item.units}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    e.target.blur();
                  }
                }}
                onBlur={async (e) => {
                  let newList = selectedProducts.map(
                    (ps) =>
                      new ProductStore({
                        ...ps,
                        units:
                          ps.product_id === item.product_id
                            ? Number(e.target.value)
                            : ps.units,
                      })
                  );
                  handleSelectedProductChange(newList);
                }}
              />
              <CloseIcon
                onClick={async () => {
                  let newList = selectedProducts.filter(
                    (ps) => ps.product_id !== item.product_id
                  );
                  handleSelectedProductChange(newList);
                }}
              />
            </Tag>
          ))}
        </VStack>
      </FormControl>
    </VStack>
  );
}

export const ManageProductsButton = ({ stores = [], onFinish }: Props) => {
  const messageModalContext = useContext(MessageModalContext);
  const accessToken = useAppSelector(selectAccessToken);
  const products = useAppSelector(selectProducts);
  const orgProperties = useAppSelector(selectOrgProperties);
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";

  const handleSave = async (
    i: number,
    dialogState: FormProps["dialogState"],
    setDialogState: React.Dispatch<
      React.SetStateAction<FormProps["dialogState"]>
    >
  ) => {
    const { selectedProducts, toAdd, toUpdate, toDelete } = dialogState || {};
    setDialogState((oldState: FormProps["dialogState"]) => ({
      ...(oldState || {}),
      status: `Adding new products`,
      isLoading: true,
    }));
    const productStoresToAdd = toAdd.map((ps) => new ProductStore(ps));
    const productStoresToUpdate = toUpdate.map((ps) => new ProductStore(ps));
    const productStoresToDelete = toDelete.map((ps) => new ProductStore(ps));
    const addedProducts = productStoresToAdd.map((productStoreToAdd: any) =>
      new ProductStore(productStoreToAdd).buildForPost()
    );
    const { product_store_id } = await postProductStoresBatch(
      accessToken,
      addedProducts
    );
    productStoresToAdd.forEach((productStore: any, index: number) => {
      productStore.id = product_store_id[index];
    });

    // Remove products
    setDialogState((oldState: any) => ({
      ...(oldState || {}),
      status: `Removing products`,
    }));
    await deleteProductStoresBatch(
      accessToken,
      productStoresToDelete.map((ps: any) => ps.id)
    );

    //               Update products
    setDialogState((oldState: any) => ({
      ...(oldState || {}),
      status: `Updating products`,
    }));
    const updatedProducts = productStoresToUpdate.map(
      (productStoreToUpdate: any) =>
        new ProductStore(productStoreToUpdate).buildForPut()
    );
    await putProductStoresBatch(accessToken, updatedProducts);

    setDialogState((oldState: any) => ({
      ...(oldState || {}),
      status: "",
      isLoading: false,
    }));
    toast.success(
      `Added ${productStoresToAdd.length}, removed ${productStoresToDelete.length} and updated ${productStoresToUpdate.length} products`
    );
    const cleanProductStores: ProductStore[] = [
      ...selectedProducts
        .filter((ps) => !toDelete.find((d) => d.id === ps.id))
        .map((ps) => productStoresToUpdate.find((d) => d.id === ps.id) || ps),
      ...productStoresToAdd,
    ]
      .filter((ps) => !!ps.id)
      .map(
        (ps: any) =>
          new ProductStore({
            ...ps,
            product: products.find((p: any) => p.id === ps.product_id),
            store: stores.find((s: any) => s.id === ps.store_id),
          })
      );
    setDialogState((oldState: any) => ({
      ...(oldState || {}),
      selectedProducts: cleanProductStores,
    }));
    const newStores = stores.map(
      (store: any) =>
        new Store({
          ...store,
          products: cleanProductStores.filter((ps) => ps.store_id === store.id),
        })
    );
    onFinish?.(newStores);
    messageModalContext.dismissModal(i);
  };

  return (
    <>
      <Button
        leftIcon={<IoCube />}
        variant="link"
        aria-label={"Batch store products button"}
        isDisabled={!stores.length}
        onClick={() => {
          messageModalContext.showModal({
            title: `Manage products for ${pluralize(
              storeLabel,
              stores?.length,
              true
            )}`,
            actions: [
              {
                label: "Remove all",
                props: { variant: "link" },
                preventDismiss: true,
                callback: (index, dialogState, setDialogState) => {
                  setDialogState((oldState: any) => ({
                    ...(oldState || {}),
                    selectedProducts: [],
                  }));
                },
              },
              { label: "spacer", Render: () => <Spacer /> },
              {
                label: "spacer",
                Render: (props) =>
                  !!props?.status ? (
                    <Text color="gray" fontSize="sm">
                      {props.status}...
                    </Text>
                  ) : (
                    <></>
                  ),
              },
              {
                label: "spacer",
                Render: (props) => {
                  const newProducts = Array.from<number>(
                    new Set(
                      props?.selectedProducts
                        ?.filter(
                          (ps: any) =>
                            !stores
                              .flatMap((s: any) => s.products || [])
                              .map((sps: any) => sps.product_id)
                              .includes(ps.product_id)
                        )
                        .map((ps: any) => ps.product_id || 0)
                        .filter((id: any) => !!id) || []
                    )
                  );
                  const deletedProducts = Array.from(
                    new Set(
                      stores
                        .flatMap((s: any) => s.products || [])
                        .filter(
                          (ps: any) =>
                            !!ps.id &&
                            !props?.selectedProducts
                              ?.map((ps: any) => ps.id)
                              .includes(ps.id)
                        )
                    )
                  );

                  return (
                    <VStack alignItems="flex-start">
                      {!!newProducts?.length && (
                        <Text color="green">
                          {pluralize("Product", newProducts?.length, true)} to
                          add
                        </Text>
                      )}
                      {!!Array.from(
                        new Set(deletedProducts.map((ps: any) => ps.product_id))
                      ).filter((id) => !!id).length && (
                        <Text color="red">
                          {pluralize(
                            "Product",
                            Array.from(
                              new Set(
                                deletedProducts.map((ps: any) => ps.product_id)
                              )
                            ).filter((id) => !!id).length,
                            true
                          )}{" "}
                          to remove
                        </Text>
                      )}
                    </VStack>
                  );
                },
              },
              { label: "spacer", Render: () => <Spacer /> },
              {
                label: "Cancel",
                isLeastDestructive: true,
              },
              {
                label: "Save",
                props: { colorScheme: "blue", ml: 3 },
                preventDismiss: true,
                callback: (i, dialogState, setDialogState) =>
                  handleSave(i, dialogState, setDialogState),
              },
            ],
            initialDialogState: {
              stores: stores,
              selectedProducts: stores.flatMap((s) => s.products || []),
              toAdd: [],
              toUpdate: [],
              toDelete: [],
            },
            message: (dialogState, setDialogState) => (
              <BatchProductStoreForm
                dialogState={dialogState}
                setDialogState={setDialogState}
              />
            ),
          });
        }}
      >
        Products
      </Button>
    </>
  );
};
