import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useAppSelector } from "../app/store";
import { LocationFilters } from "../features/audience/LocationFilters";
import InsightSearch from "../features/insights/InsightSearch";
import {
  selectSelectedAudiences,
  selectSelectedStates,
} from "../features/map/keplerReducer";
import ProductSearch from "../features/products/ProductSearch";
import { SalesSearch } from "../features/sales/SalesSearch";
import StoresSearch from "../features/stores/StoresSearch";

var pluralize = require("pluralize");

const _AppDrawer = (props) => {
  const { isOpen, onClose, isSelectingOnMap, orgConfig } = props;
  const [activeTab, setActiveTab] = useState(0);
  const selectedStates = useAppSelector(selectSelectedStates);
  const selectedAudiences = useAppSelector(selectSelectedAudiences);
  const storeLabel = orgConfig?.storeNameReplacement || "Store";

  useEffect(() => {
    if (selectedStates?.length && selectedAudiences?.length) {
      setActiveTab(0);
    }
  }, [selectedAudiences, selectedStates]);

  return (
    <Drawer
      isOpen={isOpen && !isSelectingOnMap}
      placement="left"
      onClose={onClose}
      size="xl"
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody pb={40} display="flex" flexDir="column">
            <Heading>Explore</Heading>
            <Tabs
              isFitted
              index={activeTab}
              onChange={(index) => setActiveTab(index)}
              mt={10}
              flex={1}
              display="flex"
              flexDir="column"
            >
              <TabList>
                <Tab fontSize="lg">Distribution</Tab>
                <Tab
                  fontSize="lg"
                  isDisabled={
                    !selectedStates?.length || !selectedAudiences?.length
                  }
                >
                  {pluralize(storeLabel)}
                </Tab>
                <Tab
                  fontSize="lg"
                  isDisabled={
                    !selectedStates?.length || !selectedAudiences?.length
                  }
                >
                  Products & Categories
                </Tab>
                <Tab
                  fontSize="lg"
                  isDisabled={
                    !selectedStates?.length || !selectedAudiences?.length
                  }
                >
                  Insights
                </Tab>
                <Tab
                  fontSize="lg"
                  isDisabled={
                    !selectedStates?.length || !selectedAudiences?.length
                  }
                >
                  Sales
                </Tab>
              </TabList>

              <TabPanels flex={1}>
                <TabPanel>
                  <LocationFilters onClose={onClose} />
                </TabPanel>
                <TabPanel>
                  <StoresSearch onClose={onClose} />
                </TabPanel>
                <TabPanel>
                  <ProductSearch onClose={onClose} />
                </TabPanel>
                <TabPanel>
                  <InsightSearch onClose={onClose} />
                </TabPanel>
                <TabPanel h="100%">
                  <SalesSearch onClose={onClose} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export const AppDrawer = connect(
  (state) => ({
    isSelectingOnMap:
      state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
        ?.isSelecting,
    orgConfig: state.app.orgProperties?.properties,
  }),
  (dispatch) => ({})
)(_AppDrawer);
