import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Heading } from "@chakra-ui/layout";
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Image,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Text,
  VStack,
} from "@chakra-ui/react";
import pluralize from "pluralize";
import { useMemo, useState } from "react";
import { FaDownload, FaVideo } from "react-icons/fa";
import XLSX from "xlsx";
import { selectOrgProperties, selectProducts } from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import product_unit_preview from "../../assets/images/product_unit_preview.png";
import { DathicFileDrop } from "../../components/DathicFileDrop";
import { Store } from "../../domain/Store";
import StoreFileParser from "../../utils/StoreFileParser";
import { PopoverTrigger } from "../products/ProductsTable";
import StoreUploadWizard from "./StoreUploadWizard";

type Props = {
  onFinish?: () => void;
  storeTypes?: (string | undefined)[];
};
export const ImportStoresSteps = ({
  onFinish,
  storeTypes: _storeTypes,
}: Props) => {
  const orgProperties = useAppSelector(selectOrgProperties);
  const products = useAppSelector(selectProducts);
  const [generatingTemplate, setGeneratingTemplate] = useState(false);
  const [file, setFile] = useState<File>();
  const [fileForMapping, setFileForMapping] = useState<File>();

  const storeTypes: (string | undefined)[] | undefined = useMemo(
    () => storeTypes,
    []
  );

  const storeLabel = storeTypes?.includes("restaurant")
    ? "Restaurant"
    : orgProperties?.properties?.storeNameReplacement || "Store";
  const statesLabel =
    orgProperties?.properties?.statesNameReplacement || "State";
  const zipcodeLabel =
    orgProperties?.properties?.zipcodeNameReplacement || "Zipcode";

  const storeSchema = Store.getSchema(statesLabel, zipcodeLabel, storeTypes);

  const generateTemplate = async (isCsv: boolean) => {
    setGeneratingTemplate(true);

    const dataToSave = [
      {
        store_id: "",
        chain: "",
        name: "",
        description: "",
        address: "",
        zipcode: "",
        city: "",
        state: "",
        phone: "",
        ...(storeTypes?.includes("popup_store")
          ? { start_date: "", end_date: "", recurrence_rule: "" }
          : {}),
        ...products.reduce(
          (acc, p) => ({ ...acc, [`${p.id} - ${p.name}`]: "" }),
          {}
        ),
      },
    ];
    if (!isCsv) {
      const wb = XLSX.utils.book_new();

      const storesSheet = XLSX.utils.json_to_sheet(dataToSave);
      XLSX.utils.book_append_sheet(wb, storesSheet, "Stores");
      XLSX.writeFile(wb, "StoreTemplate.xlsx");
    } else {
      const header = [
        "store_id",
        "chain",
        "name",
        "description",
        "address",
        "zipcode",
        "city",
        "state",
        "phone",
        ...(storeTypes?.includes("popup_store")
          ? ["start_date", "end_date", "recurrence_rule"]
          : []),
        ...products.map((p) => `${p.id} - ${p.name}`),
      ];
      const rows = [header].concat(
        dataToSave.map((row) => [
          ...header.map((h) => row[h as keyof typeof row] || ""),
        ])
      );

      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const element = document.createElement("a");
      element.setAttribute("href", encodedUri);
      element.setAttribute("download", "StoreTemplate");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
    setGeneratingTemplate(false);
  };

  return (
    <VStack
      w="100%"
      textAlign="start"
      spacing={5}
      bg="white"
      p={3}
      rounded="lg"
    >
      {onFinish && (
        <Button
          variant="link"
          alignSelf="flex-start"
          leftIcon={<ChevronLeftIcon />}
          onClick={() => {
            if (onFinish) onFinish();
          }}
        >
          Back
        </Button>
      )}
      <HStack w="100%" justifyContent="space-between">
        <Heading size="lg">
          Import {pluralize(storeLabel)} with a spreadsheet
        </Heading>
        <Button variant="outline" leftIcon={<FaVideo />} isDisabled>
          Questions? Watch video
        </Button>
      </HStack>
      <Divider />
      <HStack
        w="100%"
        paddingX={5}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <VStack flex={1} maxW={800} alignItems="flex-start">
          <Heading size="md">Step 1: Download Template</Heading>
          <Text fontSize="md">
            Use this template to match how your information is organized in
            Dathic.
          </Text>
          <Text fontSize="sm" color="gray.800" fontStyle={"italic"}>
            *If you have a different file, ensure it has the required fields.
            Our system will try to match the data, saving you from manual
            transfers.
          </Text>
        </VStack>
        <HStack justifyContent="center" alignSelf={"center"}>
          <Button
            whiteSpace="normal"
            leftIcon={<FaDownload />}
            onClick={() => generateTemplate(true)}
            isDisabled={generatingTemplate}
            isLoading={generatingTemplate}
            id="product_csv_template"
          >
            CSV
          </Button>
          <Button
            whiteSpace="normal"
            leftIcon={<FaDownload />}
            onClick={() => generateTemplate(false)}
            isDisabled={generatingTemplate}
            isLoading={generatingTemplate}
            id="product_excel_template"
          >
            Excel
          </Button>
        </HStack>
      </HStack>
      <Divider />
      <HStack
        w="100%"
        paddingX={5}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <VStack flex={1} maxW={800} alignItems="flex-start">
          <Heading size="md">Step 2: Add Store Information</Heading>
          <Text fontSize="md">
            Fill out the template with at least the mandatory fields. To provide
            better guidance for your shoppers, include as many details as
            possible.
          </Text>
          <Text fontSize="sm" color="gray.800" fontStyle={"italic"}>
            *When {storeLabel} name is not provided, shoppers will see the chain
            name instead.
          </Text>
        </VStack>
        <HStack alignSelf={"center"} justifyContent="center">
          <Popover trigger="hover">
            <PopoverTrigger>
              <Button>View mandatory fields</Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>
                Mandatory fields for {pluralize(storeLabel)}
              </PopoverHeader>
              <PopoverBody>
                <List spacing={3}>
                  {storeSchema
                    .filter((field) => !!field.isRequired)
                    .map((field) => (
                      <ListItem>
                        <FormControl isRequired>
                          <FormLabel>{field.label}</FormLabel>
                        </FormControl>
                      </ListItem>
                    ))}
                </List>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>
      </HStack>
      <Divider />
      <HStack
        w="100%"
        paddingX={5}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <VStack flex={1} maxW={800} alignItems="flex-start">
          <Heading size="md">
            Step 3: Specify products sold at each store
          </Heading>
          <Text fontSize="md">
            Put a 1 in the product's column if it's sold there. Leave it blank
            if not.
          </Text>
          <Text fontSize="sm" color="gray.800" fontStyle={"italic"}>
            *When products are not listed, shoppers will see “Check product
            availability with the {storeLabel}”
          </Text>
        </VStack>
        <HStack justifyContent="center">
          <Image
            src={product_unit_preview}
            alt="product_unit_preview"
            objectFit="contain"
            h={120}
          />
        </HStack>
      </HStack>
      <Divider />
      <HStack
        w="100%"
        paddingX={5}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <VStack flex={1} maxW={800} alignItems="flex-start">
          <Heading size="md">Step 4: Upload the file</Heading>
          <Text fontSize="md">We only support Excel and CSV files</Text>
        </VStack>
        <HStack justifyContent="center">
          <DathicFileDrop
            fileFromOutside={file}
            onChange={(f?: File) => {
              setFile(f);
              if (!f) return;
              const parser = new StoreFileParser(f);
              parser.parse().then(() => {
                setFile(parser.dataToCsvFile());
              });
            }}
            accept=".xls, .xlsx, .csv"
            message="We only support Excel and CSV files"
            shouldUpload={false}
          />
        </HStack>
      </HStack>
      <Divider />
      <HStack
        w="100%"
        paddingX={5}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <VStack flex={1} maxW={800} alignItems="flex-start">
          <Heading size="md">
            Step 5: Map fields, Check Stores & Products, and Confirm
          </Heading>
          <Text fontSize="md">
            Follow the steps to match fields in the file with the corresponding
            label in dathic, validate stores and products details and confirm
            your import.
          </Text>
        </VStack>
        <HStack alignSelf={"center"} justifyContent="center">
          <Button
            onClick={() => {
              setFileForMapping(undefined);
              setFileForMapping(file);
            }}
            isDisabled={!file}
            colorScheme="blue"
          >
            Start Import
          </Button>
          {fileForMapping && (
            <StoreUploadWizard
              file={fileForMapping}
              onClose={() => {
                setFileForMapping(undefined);
                if (onFinish) onFinish();
              }}
              storeTypes={storeTypes}
            />
          )}
        </HStack>
      </HStack>
    </VStack>
  );
};
