import { get, post } from "axios";
import TagManager from "react-gtm-module";
import { toast } from "react-toastify";
import {
  base_url,
  shopify_bulk_query_orders,
  shopify_connect_shopify,
  shopify_request_subscription,
  shopify_sync_products,
} from "../constants/serviceConfig";

const errorHandler = (error: any, noToast = false) => {
  const response = error.response;
  let message = error.message;
  const code = response?.status || 0;
  switch (code) {
    case 400:
      message = "Item already exists";
      break;
    case 408:
      message = "Your request took too long";
      break;

    default:
      break;
  }
  const tagManagerArgs = {
    dataLayer: {
      event: "error",
      "gtm.errorMessage": `APIError: ${message}`,
      "gtm.errorLineNumber": error.config?.params?.toString(),
      "gtm.errorUrl": error.config?.url,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
  if (!noToast) {
    toast.error(
      `${message}: ${response?.data?.msg || response?.data?.message}`
    );
  }
};

export async function postShopifySubscriptionRequest(
  session: {
    name: string;
    returnUrl: string;
    price: number;
    interval?: string;
    trialDays?: number;
  },
  accessToken: string
) {
  try {
    let url = base_url;
    url = url + shopify_request_subscription;
    const result = await post(url, session, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getShopifyConnectShopify(
  shopUrl: string,
  apiKey: string,
  shopify_access_token: string,
  accessToken: string
) {
  try {
    let params = new URLSearchParams();
    params.append("shopUrl", shopUrl);
    params.append("apiKey", apiKey);
    params.append("access_token", shopify_access_token);
    let url = base_url;
    url = url + shopify_connect_shopify;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function getBulkQueryOrders(
  start_date: string,
  end_date: string,
  accessToken: string
) {
  try {
    let params = new URLSearchParams();
    params.append("start_date", start_date);
    params.append("end_date", end_date);
    let url = base_url;
    url = url + shopify_bulk_query_orders;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

export async function syncProducts(accessToken: string) {
  try {
    let url = base_url;
    url = url + shopify_sync_products;
    const result = await get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result?.data;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}
