import { formAnatomy as parts } from "@chakra-ui/anatomy";
import type {
  PartsStyleFunction,
  SystemStyleFunction,
} from "@chakra-ui/theme-tools";
import { mode } from "@chakra-ui/theme-tools";

const baseStyleRequiredIndicator: SystemStyleFunction = (props) => {
  return {
    marginStart: 1,
    color: mode("red.500", "red.300")(props),
  };
};

const baseStyleHelperText: SystemStyleFunction = (props) => {
  return {
    mt: "-0.5rem",
    mb: 2,
    color: mode("gray.900", "whiteAlpha.600")(props),
    lineHeight: "normal",
    fontStyle: "italic",
    fontSize: "sm",
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  container: { width: "100%", position: "relative" },
  requiredIndicator: baseStyleRequiredIndicator(props),
  helperText: baseStyleHelperText(props),
});

const formTheme = {
  parts: parts.keys,
  baseStyle,
};
export default formTheme;
