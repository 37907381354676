import { SelectProps } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { selectAccessToken, selectUserResources } from "../app/appSlice";
import { useAppSelector } from "../app/store";
import { RESOURCES } from "../constants/user-constants";
import { getOrganizations } from "../services/api.service";
import MultiSelectFormControl from "./MultiSelectFormControl";

function OrgSelect(props: {
  value: SelectProps["value"];
  onChange: (value: SelectProps["value"]) => void;
}) {
  const [orgs, setOrgs] = useState<any[]>([]);
  const userResources = useAppSelector(selectUserResources);
  const accessToken = useAppSelector(selectAccessToken);

  useEffect(() => {
    getOrganizations(accessToken || "").then(setOrgs);
  }, [accessToken]);

  return (
    <MultiSelectFormControl
      formControlProps={{
        isDisabled: !userResources?.includes(RESOURCES.MANAGE_USER),
      }}
      autocompleteProps={{
        options: orgs,
      }}
      isDisabled={undefined}
      disabledReason={undefined}
      value={[props.value]}
      onChange={(selection) => {
        props.onChange(selection.pop()?.id);
      }}
      getOptionValue={(option) => option?.name}
      inputProps={{ placeholder: "Choose an org" }}
      helperText={undefined}
      required={undefined}
    />
  );
}
export default OrgSelect;
