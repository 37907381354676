export const currencyOffsets = {
  DZD: 100,
  ARS: 100,
  AUD: 100,
  BDT: 100,
  BOB: 100,
  BRL: 100,
  GBP: 100,
  CAD: 100,
  CLP: 1,
  CNY: 100,
  COP: 1,
  CRC: 1,
  CZK: 100,
  DKK: 100,
  EGP: 100,
  EUR: 100,
  GTQ: 100,
  HNL: 100,
  HKD: 100,
  HUF: 1,
  ISK: 1,
  INR: 100,
  IDR: 1,
  ILS: 100,
  JPY: 1,
  KES: 100,
  KRW: 1,
  MOP: 100,
  MYR: 100,
  MXN: 100,
  NZD: 100,
  NIO: 100,
  NGN: 100,
  NOK: 100,
  PKR: 100,
  PYG: 1,
  PEN: 100,
  PHP: 100,
  PLN: 100,
  QAR: 100,
  RON: 100,
  RUB: 100,
  SAR: 100,
  SGD: 100,
  ZAR: 100,
  SEK: 100,
  CHF: 100,
  TWD: 1,
  THB: 100,
  TRY: 100,
  AED: 100,
  USD: 100,
  UYU: 100,
  VEF: 100,
  VND: 1,
};

export const countries = [
  "AT",
  "AU",
  "BE",
  "CA",
  "CH",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  "GB",
  "HU",
  "IT",
  "LT",
  "LV",
  "MA",
  "MT",
  "NL",
  "NO",
  "NZ",
  "PL",
  "PT",
  "RU",
  "SK",
  "SM",
  "TH",
  "TR",
  "US",
  "PR",
  "CO",
];

export const objectives = {
  LINK_CLICKS: {
    name: "Link clicks",
    description:
      "Send people to your store locator, website, Facebook or Instagram profile or let them tap to call you.",
  },
  LEAD_GENERATION: {
    name: "Lead generation",
    description:
      "Use forms to gather info from people interested in your business or product.",
  },
  POST_ENGAGEMENT: {
    name: "Post engagement",
    description:
      "Generate brand awareness getting more likes, comments or shares in your post.",
  },
};

export const billingEvents = {
  APP_INSTALLS: "App installs",
  CLICKS: "Clicks",
  IMPRESSIONS: "Impressions",
  LINK_CLICKS: "Link clicks",
  OFFER_CLAIMS: "Offer claims",
  PAGE_LIKES: "Page likes",
  POST_ENGAGEMENT: "Post engagement",
  VIDEO_VIEWS: "Video views",
  THRUPLAY: "Thruplay",
};

export const callToActionTypes = [
  "BUY_NOW",
  "GET_OFFER",
  "LEARN_MORE",
  "SHOP_NOW",
  "SIGN_UP",
];

export const unfilterableFields = [
  "annual_individual_earnings",
  "average_household_income_over_time",
  "children_by_age",
  "educational_attainment_for_population_25_and_over",
  "employment_status",
  "families_vs_singles",
  "head_of_household_by_age",
  "household_income",
  "households_with_kids",
  "housing_occupancy",
  "housing_type",
  "investment_average_income_per_household_by_income_source",
  "investment_percent_of_households_receiving_investment_income",
  "means_of_transportation_to_work_for_workers_16_and_over",
  "monthly_rent_including_utilities_1_b",
  "monthly_rent_including_utilities_2_b",
  "monthly_rent_including_utilities_3plus_b",
  "monthly_rent_including_utilities_studio_apt",
  "owner_occupied_home_values",
  "population_by_age",
  "population_by_gender",
  "population_by_race",
  "population_by_year",
  "rental_properties_by_number_of_rooms",
  "retirement_average_income_per_household_by_income_source",
  "retirement_percent_of_households_receiving_retirement_incom",
  "school_enrollment_age_3_to_17",
  "source_of_earnings",
  "sources_average_income_per_household_by_income_source",
  "sources_percent_of_households_receiving_income",
  "travel_time_to_work_in_minutes",
  "vancancy_reason",
  "year_housing_was_built",
  "population_by_race",
];
