import { selectCurrentUser } from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import Login from "../login/Login";
import { CheckoutStep } from "./StoreLocatorCheckout";

export function StoreLocatorCheckoutScreen() {
  const currentUser = useAppSelector(selectCurrentUser);

  return !currentUser ? <Login registering /> : <CheckoutStep />;
}
