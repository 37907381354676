import React from "react";
import { Flex, Text } from "@chakra-ui/react";

export default function StoreInfo({ parameter, label }) {
  return (
    <Flex my={"1.5"} alignItems={"center"} w={"100%"}>
      <Text fontSize={"large"} fontWeight={"bold"}>{`${label}:`}</Text>
      <span style={{ width: "5px" }} />
      <Text fontSize={"large"}>
        {parameter || `No ${label.toLowerCase()} found`}
      </Text>
    </Flex>
  );
}
