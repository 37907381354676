import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
} from "@chakra-ui/modal";
import { secondaryDark } from "../../theme/colors";

function MarketingReportDrawer({ isOpen, onClose }) {
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="full"
      scrollBehavior="inside"
    >
      <DrawerContent>
        <DrawerCloseButton color="white" bgColor={secondaryDark} />
        <DrawerBody>
          <iframe
            src="https://datastudio.google.com/embed/reporting/cf02b8dd-72b1-46a2-89b1-8f537103ce26/page/lgmWC"
            frameBorder="0"
            style={{
              border: 0,
              position: "relative",
              top: "5%",
              left: "10%",
              aspectRatio: 1,
              width: "80%",
              height: "90%",
              boxShadow: "0px 0px 4px 0px rgba(50, 50, 50, 0.75)",
            }}
            allowFullScreen
            title="marketing-report"
          ></iframe>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default MarketingReportDrawer;
