import { Avatar } from "@chakra-ui/avatar";
import { Button } from "@chakra-ui/button";
import { Heading, HStack, Text, VStack } from "@chakra-ui/layout";
import { MenuDivider, MenuGroup, MenuItem, MenuList } from "@chakra-ui/menu";
import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { selectCurrentUser, selectOrgProperties } from "../app/appSlice";
import { useAppSelector } from "../app/store";

function _UserMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useAppSelector(selectCurrentUser);
  const [profilePhotoHash, setProfilePhotoHash] = useState(Date.now());

  const orgProperties = useAppSelector(selectOrgProperties);
  const storeLocatorConfigured = ["payed", "free_trial"].includes(
    orgProperties?.store_locator?.status || ""
  );

  const userName = currentUser?.name || currentUser?.email;

  const handleSignout = () => {
    const auth = getAuth();
    auth.signOut().catch((err) => console.error("failed signout", err));
  };

  const doNav = (path: string) => {
    navigate(
      location.pathname.includes("storelocator") ? `storelocator/${path}` : path
    );
  };

  useEffect(() => {
    setProfilePhotoHash(Date.now());
  }, [currentUser]);

  return (
    <MenuList>
      <HStack w="100%" p={3}>
        <Avatar
          name={userName}
          src={
            currentUser?.photoURL
              ? currentUser?.photoURL + "?" + profilePhotoHash
              : undefined
          }
          mr={3}
          colorScheme="blue"
        />
        <VStack spacing={0}>
          <Heading size="md" mb={0}>
            {userName}
          </Heading>
          {currentUser?.name && <Text fontSize="sm">{currentUser?.email}</Text>}
          <HStack>
            {orgProperties?.organization?.name && (
              <Text fontSize="sm">{orgProperties?.organization?.name}</Text>
            )}
            {!storeLocatorConfigured && (
              <Button
                colorScheme={"blue"}
                size="xs"
                onClick={() => doNav("billing")}
              >
                Upgrade
              </Button>
            )}
          </HStack>
        </VStack>
      </HStack>
      <MenuDivider />
      <MenuGroup>
        {location.pathname !== "storelocator" && (
          <MenuItem onClick={() => doNav("my_account")}>My account</MenuItem>
        )}
        <MenuItem onClick={() => doNav("users_roles")}>
          Users and roles
        </MenuItem>
        <MenuItem onClick={() => doNav("notifications")}>
          Notifications
        </MenuItem>
        <MenuItem onClick={() => doNav("billing")}>Billing</MenuItem>
        <MenuItem onClick={() => doNav("integrations")}>Integrations</MenuItem>
      </MenuGroup>
      <MenuDivider />
      <MenuGroup>
        <MenuItem onClick={() => doNav("share_locator")}>
          Share my locator
        </MenuItem>
        <MenuItem onClick={() => doNav("referrals")}>
          Refer a friend to Dathic
        </MenuItem>
      </MenuGroup>
      <MenuDivider />
      <MenuGroup>
        <MenuItem onClick={() => doNav("help_support")}>
          Help & Support
        </MenuItem>
        <MenuItem onClick={handleSignout}>Log out</MenuItem>
      </MenuGroup>
      <MenuDivider />
      <Button
        variant="link"
        size="xs"
        onClick={() => {
          window.location.href = "https://dathic.com/";
        }}
      >
        dathic.com
      </Button>
    </MenuList>
  );
}

export default _UserMenu;
