import { Box, Button, Img } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router";
import menuIconMaps from "../assets/images/menu-icon-maps.png";
import { trackClick } from "../services/tracking.service";

export default function MapsBtn() {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Box>
      <Button
        id="maps-btn"
        className="maps-btn"
        variant="link"
        as="div"
        color="blue.900"
        padding="12px 10px"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        leftIcon={
          <Img
            src={menuIconMaps}
            height="1rem"
            width="1rem"
            id="maps-btn"
            className="maps-btn"
            filter={
              location.pathname === "/" || location.pathname === "/storelocator"
                ? "invert(48%) sepia(45%) saturate(4075%) hue-rotate(177deg) brightness(97%) contrast(102%)"
                : undefined
            }
          />
        }
        isActive={
          location.pathname === "/" || location.pathname === "/storelocator"
        }
        iconSpacing={0}
        size="xs"
        _hover={{ textDecoration: "underline" }}
        onClick={() =>
          trackClick("maps-btn", "Maps", () => {
            if (location.pathname.includes("storelocator")) {
              navigate(`storelocator`);
              return;
            }
            navigate("/");
          })
        }
      >
        {location.pathname.includes("storelocator") ? "Preview" : "Maps"}
      </Button>
    </Box>
  );
}
