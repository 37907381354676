import React, { useEffect, useState } from "react";
import {
  Stack,
  Flex,
  Center,
  Text,
  Spinner,
  Divider,
  Box,
  useMediaQuery,
  Button,
  Collapse,
  HStack,
} from "@chakra-ui/react";
import { StarIcon, AddIcon, MinusIcon } from "@chakra-ui/icons";
import { connect } from "react-redux";
var pluralize = require("pluralize");

function StoresTab({ firstZipStores, secondZipStores, orgConfig }) {
  const [firstSorted, setFirstSorted] = useState([]);
  const [secondSorted, setSecondSorted] = useState([]);
  const [firstCategory, setFirstCategory] = useState([]);
  const [secondCategory, setSecondCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [openedItems, setOpenedItems] = useState([]);
  const storeLabel = orgConfig?.storeNameReplacement || "Store";

  const getStoreCategory = (stores, setState) => {
    const reducedStore = stores.reduce((acc, curr) => {
      if (curr.category) {
        if (!acc[curr.category[0]] || acc[curr.category[0]].length === 0) {
          acc[curr.category[0]] = [curr];
        } else {
          acc[curr.category[0]].push(curr);
        }
      }
      return acc;
    }, {});
    const sortedKeys = Object.keys(reducedStore)
      .map((key) => {
        return {
          key,
          value: reducedStore[key],
        };
      })
      .sort((a, b) => {
        return b.value.length - a.value.length;
      })
      .slice(0, 3);
    setState(sortedKeys);
  };

  const filteredStores = (stores, setState) => {
    setState(
      stores
        .filter((store) => store.getReviewCount() > 0)
        .sort((a, b) => b.getReviewCount() - a.getReviewCount())
        .slice(0, 3)
    );
  };
  useEffect(() => {
    setLoading(true);
    if (firstZipStores.length > 0 && secondZipStores.length > 0) {
      filteredStores(firstZipStores, setFirstSorted);
      getStoreCategory(firstZipStores, setFirstCategory);
      filteredStores(secondZipStores, setSecondSorted);
      getStoreCategory(secondZipStores, setSecondCategory);
    }
    setLoading(false);
  }, [firstZipStores, secondZipStores]);
  const getStar = (rating) => {
    let star = [];
    const roundedRating = Math.floor(rating);
    for (let i = 0; i < 5; i++) {
      star.push(
        <StarIcon
          key={i}
          color={i < roundedRating ? "yellow.300" : "gray.500"}
        />
      );
    }
    return star;
  };
  return (
    <Stack
      direction={["column", null, "row"]}
      height={["40%", null, "100%"]}
      background="white"
      rounded="md"
      width={["100%", null, 650]}
      textAlign="start"
      overflowY="auto"
    >
      <Flex flexDir="column" w="100%" h="100%">
        {loading ? (
          <Center h={"100%"}>
            <Spinner />
          </Center>
        ) : (
          <>
            <Text
              m={2}
              textAlign={"center"}
              fontSize={"xl"}
              fontWeight={"bold"}
            >
              Number of {pluralize(storeLabel)}
            </Text>
            <HStack justifyContent={"space-evenly"}>
              <Text fontSize={"xl"}>
                {firstZipStores.length || `No ${pluralize(storeLabel)} found`}
              </Text>
              <Text fontSize={"xl"}>
                {secondZipStores.length || `No ${pluralize(storeLabel)} found`}
              </Text>
            </HStack>
            <Divider />
            {firstZipStores.length > 0 && secondZipStores.length > 0 && (
              <Text
                m={2}
                fontSize={"xl"}
                fontWeight={"bold"}
                textAlign={"center"}
              >
                Top {pluralize(storeLabel)}
              </Text>
            )}
            <Flex>
              <SortedStoreCard
                sortedStores={firstSorted}
                isMobile={isMobile}
                getStar={getStar}
              />
              <Divider orientation="vertical" />
              <SortedStoreCard
                sortedStores={secondSorted}
                isMobile={isMobile}
                getStar={getStar}
              />
            </Flex>
            <Divider />
            {firstZipStores.length > 0 && secondZipStores.length > 0 && (
              <Text
                fontSize={"xl"}
                fontWeight={"bold"}
                textAlign={"center"}
                m={2}
              >
                Top Categories
              </Text>
            )}
            <Flex justifyContent={"space-around"}>
              <Flex w="100%" alignItems={"center"} direction={"column"}>
                {firstCategory.map((category) => {
                  return (
                    <ButtonCollapse
                      key={category.key}
                      category={category}
                      isMobile={isMobile}
                      getStar={getStar}
                      isOpen={openedItems.includes(category.key)}
                      setOpenedItems={setOpenedItems}
                    />
                  );
                })}
              </Flex>
              <Divider orientation="vertical" />
              <Flex w="100%" alignItems={"center"} direction={"column"}>
                {secondCategory.map((category) => {
                  return (
                    <ButtonCollapse
                      key={category.key}
                      category={category}
                      isMobile={isMobile}
                      getStar={getStar}
                      isOpen={openedItems.includes(category.key)}
                      setOpenedItems={setOpenedItems}
                    />
                  );
                })}
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </Stack>
  );
}

export default connect((state) => ({
  orgConfig: state.app.orgProperties?.properties,
}))(StoresTab);

const SortedStoreCard = ({ sortedStores, isMobile, getStar }) => {
  return (
    <Flex direction={"column"}>
      {sortedStores.map((store, i) => (
        <Box
          key={i}
          bg="white"
          border="1px"
          borderColor="blackAlpha.400"
          mx="5"
          my="2"
          shadow="md"
        >
          <Flex
            flexWrap="wrap"
            direction={isMobile ? "column" : "row"}
            m="2"
            key={store.id}
            alignItems="center"
          >
            <Flex w="100%" justifyContent={"space-between"}>
              <Flex justifyContent={"flex-start"}>
                <Text>{i + 1}.</Text>
                <Text
                  textOverflow="ellipsis"
                  w="125px"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  fontWeight="medium"
                  textAlign="left"
                >
                  {store.name.replace(/'/g, "").trim()}
                </Text>
              </Flex>
              <Flex alignItems={"center"} fontSize={"large"}>
                {getStar(store.getAvgRating())}
              </Flex>
            </Flex>
            <Flex color={"gray.500"} textAlign="center">
              <Text>Reviews:</Text>
              <Text>{store.getReviewCount()}</Text>
            </Flex>
          </Flex>
        </Box>
      ))}
    </Flex>
  );
};

const ButtonCollapse = ({
  category,
  isMobile,
  getStar,
  isOpen,
  setOpenedItems,
}) => {
  return (
    <>
      <Button
        m={1}
        w="90%"
        justifyContent={"flex-start"}
        leftIcon={isOpen ? <MinusIcon /> : <AddIcon />}
        onClick={() =>
          setOpenedItems((oldItems) => {
            const itemIndex = oldItems?.findIndex(
              (item) => item === category.key
            );
            const newItems = [...(oldItems || [])];
            if (itemIndex > -1) {
              newItems.splice(itemIndex, 1);
            } else {
              newItems.push(category.key);
            }
            return newItems;
          })
        }
      >
        {category.key}
      </Button>
      <Collapse in={isOpen} animateOpacity>
        {category.value
          .sort((a, b) => b.getReviewCount() - a.getReviewCount())
          .slice(0, 3)
          .map((store, i) => (
            <Box
              key={i}
              bg="white"
              border="1px"
              borderColor="blackAlpha.400"
              mx="5"
              my="2"
              shadow="md"
            >
              <Flex
                flexWrap="wrap"
                direction={isMobile ? "column" : "row"}
                m="2"
                key={store.id}
                alignItems="center"
              >
                <Text>{i + 1}.</Text>
                <Text
                  textOverflow="ellipsis"
                  w="150px"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  fontWeight="medium"
                  textAlign="left"
                >
                  {store.name.replace(/'/g, "").trim()}
                </Text>
                <Flex alignItems={"center"} fontSize={"large"}>
                  {getStar(store.getAvgRating())}
                </Flex>
                <Flex color={"gray.500"} textAlign="center">
                  <Text>Reviews:</Text>
                  <Text>{store.getReviewCount()}</Text>
                </Flex>
              </Flex>
            </Box>
          ))}
      </Collapse>
    </>
  );
};
