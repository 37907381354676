export const EDUCATION_LEVELS = {
  US: [
    "No schooling completed",
    "Nursery to 4th grade",
    "5th and 6th grade",
    "7th and 8th grade",
    "9th grade",
    "10th grade",
    "11th grade",
    "12th grade, no diploma",
    "High school graduate (includes equivalency)",
    "Some college, less than 1 year",
    "Some college, 1 or more years, no degree",
    "Professional school degree",
    "Bachelor's degree",
    "Master's degree",
    "Doctorate degree",
    "Associate's degree",
  ],
  CA: [
    "No certificate, diploma or degree",
    "Secondary (high) school diploma or equivalency certificate",
    "Postsecondary certificate, diploma or degree",
    "Apprenticeship or trades certificate or diploma",
    "Trades certificate or diploma other than Certificate of Apprenticeship or Certificate of Qualification",
    "Certificate of Apprenticeship or Certificate of Qualification",
    "College, CEGEP or other non-university certificate or diploma",
    "University certificate or diploma below bachelor level",
    "University certificate, diploma or degree at bachelor level or above",
    "Bachelor's degree",
    "University certificate or diploma above bachelor level",
    "Degree in medicine, dentistry, veterinary medicine or optometry",
    "Master's degree",
    "Earned doctorate",
  ],
};

export const TRAVEL_TIME_MINUTES = {
  US: [
    "< 5",
    "5-9",
    "10-14",
    "15-19",
    "20-24",
    "25-29",
    "30-34",
    "35-39",
    "40-44",
    "45-59",
    "60-89",
    "90+",
  ],
  CA: [
    "Less than 15 minutes",
    "15 to 29 minutes",
    "30 to 44 minutes",
    "45 to 59 minutes",
    "60 minutes and over",
  ],
};

export const DEFAULT_STORE_TYPES = ["Megastore", "Supermarket", "Express"];
