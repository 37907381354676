import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { Button } from "@chakra-ui/button";
import { ViewIcon } from "@chakra-ui/icons";
import { Box, HStack, Text, VStack } from "@chakra-ui/layout";
import { Switch } from "@chakra-ui/switch";
import { Tag, TagLabel } from "@chakra-ui/tag";
import { DataType, SortDirection } from "ka-table/enums";
import { wrapTo } from "kepler.gl/actions";
import { fitBounds } from "kepler.gl/dist/actions/map-state-actions";
import pluralize from "pluralize";
import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { setMapSelection } from "../features/map-selection/mapSelectionSlice";
import { setSideView } from "../features/map-views/mapViewsSlice";
import { selectSearchedStores } from "../features/map/keplerReducer";
import { EntityTable } from "./entity-table/EntityTable";
import { useNavigate } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import { Flex, useDisclosure } from "@chakra-ui/react";
import {
  selectActiveIntegrations,
  toggleIntegrationsModal,
} from "../features/data-integrations/integrationSlice";
import { useAppDispatch, useAppSelector } from "../app/store";

function _LoadedStoresTab({
  loadedStores,
  fitMapBounds,
  onClose,
  viewId,
  activeViewKey,
  setSelection,
  mapSelection,
  s3client,
  organizationId,
  setSideView,
  orgConfig,
  showCampaignButton = true,
  headerText,
}) {
  const navigate = useNavigate();
  const noIntegrationAlertDisclosure = useDisclosure();
  const activeIntegrations = useAppSelector(selectActiveIntegrations);
  const fbIntegration = activeIntegrations?.facebook;
  const dispatch = useAppDispatch();
  const filteredData = useRef();
  const statesLabel = orgConfig?.statesNameReplacement || "States";
  const zipcodeLabel = orgConfig?.zipcodeNameReplacement || "Zipcode";
  const storeLabel = orgConfig?.storeNameReplacement || "Store";

  const setupDefaultCampaignValues = async () => {
    const folderKey = encodeURIComponent("default_fb_images") + "/";
    const fileName = `${organizationId}.png`;
    const fileKey = folderKey + fileName;
    const url = await getSignedUrl(
      s3client,
      new GetObjectCommand({
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: fileKey,
        ResponseContentType: "image/png",
      }),
      { expiresIn: 15 * 60 }
    );
    const response = await fetch(url);
    const blob = await response.blob();

    const image = await new Promise((resolve) => {
      var reader = new FileReader();
      reader.onload = function (e) {
        resolve({ file: new File([blob], fileName), fileUrl: e.target.result });
      };
      reader.readAsDataURL(blob);
    });
    const zipcodes = [
      ...new Set(mapSelection?.stores?.map((store) => store.zipcode)),
    ];
    const adValues = {
      images: [image],
    };

    return {
      adValues,
      zipcodes,
    };
  };

  useEffect(() => {
    localStorage.setItem("isOnlineStores", "store");
  }, []);

  const GoToStoreBtn = (props) => {
    const { rowData } = props;
    const dist = 0.002;
    return (
      <VStack>
        <Switch
          isChecked={!!rowData.selectedInMap}
          onChange={() => {
            setSelection((oldSelection) => {
              return {
                ...(oldSelection || {}),
                stores:
                  (!rowData.selectedInMap && [
                    ...((oldSelection || {}).stores || []),
                    rowData,
                  ]) ||
                  (oldSelection || {}).stores.filter(
                    (item) => item.id !== rowData.id
                  ) ||
                  undefined,
              };
            });
          }}
        />
        <Button
          leftIcon={<ViewIcon />}
          onClick={
            rowData &&
            (() => {
              fitMapBounds(
                [
                  rowData.longitude - dist,
                  rowData.latitude - dist,
                  rowData.longitude + dist,
                  rowData.latitude + dist,
                ],
                viewId
              );
              if (onClose) onClose();
            })
          }
        >
          View
        </Button>
      </VStack>
    );
  };
  return (
    <VStack w="100%" height="100%">
      <AlertDialog
        onClose={noIntegrationAlertDisclosure.onClose}
        isOpen={noIntegrationAlertDisclosure.isOpen}
        isCentered
        closeOnOverlayClick
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            Looks like you haven’t integrated a Social Media Account yet for Ads
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Go to integrations to set up your Ads Social Media Account
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              variant="solid"
              colorScheme="blue"
              onClick={() => {
                noIntegrationAlertDisclosure.onClose();
                dispatch(toggleIntegrationsModal(true));
              }}
            >
              INTEGRATIONS
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Text>{headerText}</Text>
      <Text>
        {!!loadedStores?.length
          ? `The ${pluralize(storeLabel)} on the table are the same ${pluralize(
              storeLabel
            )} visible on the map according
        to your search. ${pluralize(
          storeLabel
        )} previously selected from other searches may still be selected`
          : `Use the Explore to search for ${pluralize(
              storeLabel
            )} and include them on the map`}
      </Text>
      {!!loadedStores?.length && (
        <Flex flexDir="column" w="100%" flex={1}>
          <HStack w="100%">
            <Button
              variant="link"
              onClick={() => {
                setSelection((oldSelection) => {
                  return {
                    ...(oldSelection || {}),
                    stores: undefined,
                  };
                });
              }}
              disabled={!mapSelection?.stores?.length}
            >
              Clear selection
            </Button>
            <Button
              variant="link"
              onClick={() => {
                setSelection((oldSelection) => {
                  return {
                    ...(oldSelection || {}),
                    stores: [
                      ...((oldSelection || {}).stores || []),
                      ...(filteredData.current || []).filter(
                        (item) =>
                          !((oldSelection || {}).stores || []).find(
                            (old) => old.id === item.id
                          )
                      ),
                    ],
                  };
                });
              }}
            >
              Select filtered items
            </Button>
          </HStack>
          <Box flex={1}>
            <EntityTable
              containerProps={{ height: "100%" }}
              onDataChange={(newFilteredData) => {
                filteredData.current = newFilteredData;
              }}
              initialTableProps={{
                columns: [
                  {
                    title: "Selected",
                    key: "selectedInMap",
                    sortDirection: SortDirection.Descend,
                    Render: GoToStoreBtn,
                    dataType: DataType.Boolean,
                  },
                  ...(!!loadedStores?.find((store) => !!store.score)
                    ? [
                        {
                          title: "Score",
                          dataType: DataType.Number,
                          key: "score",
                          filterRowOperator: ">",
                          format: (value) =>
                            ((value ?? 0) * 1000).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }),
                        },
                        {
                          title: "Sales Prediction",
                          dataType: DataType.Number,
                          key: "predicted_avg_units",
                          format: (value) =>
                            (value ?? 0)?.toLocaleString(undefined, {
                              maximumFractionDigits: 0,
                            }),
                        },
                      ]
                    : []),
                  {
                    title: "Address",
                    key: "address",
                  },
                  {
                    title: "Chain",
                    key: "chain",
                  },
                  {
                    title: pluralize(statesLabel, 1),
                    key: "state",
                  },
                  {
                    title: "City",
                    key: "city",
                  },
                  {
                    title: pluralize(zipcodeLabel, 1),
                    key: "zipcode",
                  },
                  {
                    title: "Name",
                    key: "name",
                  },
                ],
              }}
              dataFromOutside={loadedStores?.map((store) => ({
                ...store,
                selectedInMap: !!mapSelection?.stores?.find(
                  (item) => item.id === store.id
                ),
              }))}
            />
          </Box>
        </Flex>
      )}
      {!!mapSelection?.stores?.length && (
        <Text alignSelf="flex-start">Selected {pluralize(storeLabel)}:</Text>
      )}
      <HStack overflowX="auto" w="100%">
        {mapSelection?.stores?.map((store) => (
          <Tag key={store.id} minW={14}>
            <TagLabel>{store.address}</TagLabel>
          </Tag>
        ))}
      </HStack>
      {showCampaignButton && (
        <Button
          onClick={() => {
            if (!fbIntegration?.connection) {
              noIntegrationAlertDisclosure.onOpen();
              return;
            }
            if (onClose) onClose();
            setupDefaultCampaignValues().then((defaultCampaignValues) => {
              if (defaultCampaignValues?.zipcodes?.length > 50) {
                toast.warning(
                  `Facebook may limit your ad account to ${pluralize(
                    zipcodeLabel,
                    50,
                    true
                  )} per day`
                );
              }
              setSideView(
                {
                  name: "fbCampaign",
                  props: {
                    defaultValues: defaultCampaignValues,
                    zipcodes: defaultCampaignValues?.zipcodes,
                    useStores: true,
                  },
                },
                activeViewKey
              );
              navigate("/");
            });
          }}
          disabled={!mapSelection?.stores?.length}
        >
          Create ad campaign
        </Button>
      )}
    </VStack>
  );
}
export const LoadedStoresTab = connect(
  (state) => ({
    loadedStores: selectSearchedStores(state),
    s3client: state.app.s3client,
    viewId: state.mapViews.views[`view${state.mapViews.activeView}`]?.id,
    orgConfig: state.app.orgProperties?.properties,
    activeViewKey: state.mapViews.activeView,
    organizationId: state.app.currentUser?.organization_id,
    mapSelection:
      state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
        ?.selection,
  }),
  (dispatch) => ({
    setSelection: (newSelection) => dispatch(setMapSelection(newSelection)),
    fitMapBounds: (payload, viewKey) =>
      dispatch(wrapTo(`view${viewKey}`, fitBounds(payload))),
    setSideView: (sideView, viewKey) =>
      dispatch(
        setSideView({
          viewKey,
          sideView,
        })
      ),
  })
)(_LoadedStoresTab);
