import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
} from "@chakra-ui/modal";
import { secondaryDark } from "../../theme/colors";
import EmbeddedReport from "./EmbeddedReport";

function EmbeddedReportDrawer({ isOpen, onClose, url }) {
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="full"
      scrollBehavior="inside"
    >
      <DrawerContent>
        <DrawerCloseButton color="white" bgColor={secondaryDark} style={{zIndex: 9999}} />
        <DrawerBody p={0}>
          <EmbeddedReport url={url} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default EmbeddedReportDrawer;
