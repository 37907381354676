import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { selectUserRoles } from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import PageLayout from "../../components/PageLayout";
import RolesSettings from "./RolesSettings";
import UserSettings from "./UserSettings";

function UsersRolesScreen() {
  const userRoles = useAppSelector(selectUserRoles);
  const userIsOnlyStoreLocator =
    userRoles.find((ur: any) => ur.role?.name.includes("store_locator")) &&
    !userRoles.find((ur: any) => !ur.role?.name.includes("store_locator"));
  return (
    <PageLayout title="Users & Roles">
      <Tabs w="100%">
        <TabList>
          <Tab>Users</Tab>
          {!userIsOnlyStoreLocator && <Tab>Roles</Tab>}
        </TabList>
        <TabPanels>
          <TabPanel>
            <UserSettings />
          </TabPanel>
          {!userIsOnlyStoreLocator && (
            <TabPanel>
              <RolesSettings />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </PageLayout>
  );
}
export default UsersRolesScreen;
