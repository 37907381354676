import { Box, Button, Img } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router";
import { selectMenus } from "../app/appSlice";
import { useAppSelector } from "../app/store";
import menuIconShopper from "../assets/images/menu-icon-shopper.png";
import { trackClick } from "../services/tracking.service";

export default function ShopperBtn() {
  const location = useLocation();
  const navigate = useNavigate();
  const menus = useAppSelector(selectMenus);
  const shopperMenu = menus?.find((m: any) => m.id === "shopper");
  return !location.pathname?.includes("storelocator") && !!shopperMenu ? (
    <Box>
      <Button
        id="shopper-btn"
        className="shopper-btn"
        variant="link"
        as="div"
        color="blue.900"
        padding="12px 10px"
        borderRadius={20}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        leftIcon={
          <Img
            src={menuIconShopper}
            height="1rem"
            width="1rem"
            id="shopper-btn"
            className="shopper-btn"
            filter={
              location.pathname.includes("shopper")
                ? "invert(48%) sepia(45%) saturate(4075%) hue-rotate(177deg) brightness(97%) contrast(102%)"
                : undefined
            }
          />
        }
        isActive={location.pathname.includes("shopper")}
        iconSpacing={0}
        size="xs"
        _hover={{ textDecoration: "underline" }}
        onClick={() =>
          trackClick("shopper-btn", "Shopper", () => {
            const child = shopperMenu?.children?.[0];
            child?.embedUrl
              ? navigate(
                  `${child.embedUrl ? "embed/" : ""}shopper-${child.id}`,
                  {
                    state: {
                      from: location,
                      url: child.embedUrl,
                    },
                  }
                )
              : child?.component
              ? navigate(`${child.component}/shopper-${child.id}`)
              : navigate(`not_available/shopper-${child.id}`);
          })
        }
      >
        Shopper
      </Button>
    </Box>
  ) : (
    <></>
  );
}
