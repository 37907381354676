import React, { FunctionComponent, useState } from "react";
import { Box, HStack, IconButton, Input, Text, VStack } from "@chakra-ui/react";
import { IoIosSend } from "react-icons/all";
import { useForm } from "react-hook-form";

type ChatMessage = {
  message?: string;
  date?: Date;
};

type Props = {};

const DathicChat: FunctionComponent<Props> = (props) => {
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const { handleSubmit, reset, register } = useForm<ChatMessage>();

  const onSubmit = (chatValues: ChatMessage) => {
    setChatHistory((old) => [...old, chatValues]);
    reset();
  };
  return (
    <VStack w="100%" flex={1} maxH="100%" mt={10}>
      <VStack
        w="100%"
        flex={1}
        border="1px"
        borderColor="gray.400"
        bg="whitesmoke"
        rounded="lg"
        paddingX={5}
        spacing={5}
        justifyContent="flex-end"
        pb={5}
        overflowY="auto"
      >
        {chatHistory.map((message) => (
          <Box bg="white" rounded="lg" p={3} shadow="md" alignSelf="flex-end">
            <Text>{message.message}</Text>
          </Box>
        ))}
      </VStack>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <HStack w="100%">
          <Input {...register("message")} />
          <IconButton
            type="submit"
            aria-label="Send chat button"
            icon={<IoIosSend />}
          />
        </HStack>
      </form>
    </VStack>
  );
};

export default DathicChat;
