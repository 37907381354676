//@flow
import { Box, Flex, Text } from "@chakra-ui/layout";
import React from "react";
import { LabelSeries, Sunburst } from "react-vis";

const VALUE_LABEL_STYLE = {
  fontSize: "16px",
  textAnchor: "middle",
};

function convertToInternationalCurrencySystem(labelValue) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(labelValue));
}

/**
 * Recursively work backwards from highlighted node to find path of valud nodes
 * @param {Object} node - the current node being considered
 * @returns {Array} an array of strings describing the key route to the current node
 */
function getKeyPath(node) {
  if (!node?.parent) {
    return [""];
  }

  return [(node.data && node.data.name) || node.name].concat(
    getKeyPath(node.parent)
  );
}

/**
 * Recursively modify data depending on whether or not each cell has been selected by the hover/highlight
 * @param {Object|Boolean} keyPath - a map of keys that are in the highlight path
 * if this is false then all nodes are marked as selected
 * @returns {Object} Updated tree structure
 */
function updateData(data: Object, keyPath: ?(Object | false)) {
  if (data) {
    if (data.children) {
      data.children.map((child) => updateData(child, keyPath));
    }
    // add a fill to all the uncolored cells
    if (!data.hex) {
      data.style = {
        fill: "#101c58",
      };
    }
    data.style = {
      ...data.style,
      fillOpacity: keyPath && !keyPath[data.name] ? 0.2 : 1,
    };
  }

  return data;
}

type Props = {
  initialFinalValue?: { finalPath: string, value?: string | number },
  data?: Array<Object>,
  createData: () => {
    hex?: string,
    style?: Object,
    children?: Array<Object>,
  } | null,
  onDataChange: (Object) => ?Array<Object>,
  enableClick?: boolean | Boolean,
  shouldRefresh?: boolean | Boolean,
  onRefresh?: Function,
  height?: Number,
  width?: Number,
  style?: Object,
  containerStyle?: Object,
  showFooter?: Boolean,
  showLabel?: Boolean,
};

export default function PieChart({
  initialFinalValue,
  data,
  createData,
  onDataChange,
  enableClick,
  shouldRefresh,
  onRefresh,
  height,
  width,
  style,
  containerStyle,
  showFooter = true,
  showLabel = true,
}: Props): React.Node {
  const [finalValue, setFinalValue] = React.useState(initialFinalValue);
  const [pathValue, setPathValue] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);

  React.useEffect(() => {
    if (shouldRefresh) {
      setFinalValue(initialFinalValue);
      onDataChange(updateData(createData(), false));
      onRefresh && onRefresh();
    }
  }, [createData, initialFinalValue, onDataChange, onRefresh, shouldRefresh]);

  const getValue = (node) => {
    if (node.value) {
      return node.value;
    }
    // eslint-disable-next-line no-unused-vars
    return node.children?.reduce((acc, c) => acc + (getValue(c) || 0), 0);
  };

  return (
    <Flex direction="column" style={containerStyle} width={width}>
      {enableClick && (
        <Text fontSize="sm">
          {clicked ? "click to unlock selection" : "click to lock selection"}
        </Text>
      )}
      {data && (
        <Sunburst
          animation
          className="basic-sunburst-example"
          hideRootNode
          onValueMouseOver={(node) => {
            const path = getKeyPath(node).reverse();
            const pathAsMap = path.reduce((res, row) => {
              res[row] = true;
              return res;
            }, {});
            setFinalValue({
              finalPath: path[path.length - 1],
              value: getValue(node),
            });
            setPathValue(path.join(" > "));
            onDataChange(updateData(createData(), pathAsMap));
          }}
          onValueMouseOut={() => {
            if (!clicked) {
              setPathValue(false);
              setFinalValue(initialFinalValue);
              onDataChange(updateData(createData(), false));
            }
          }}
          onValueClick={
            enableClick && (() => setClicked((oldValue) => !oldValue))
          }
          style={{
            stroke: "#ddd",
            strokeOpacity: 0.3,
            strokeWidth: "2",
            ...(style || {}),
          }}
          colorType="literal"
          getSize={(d) => d.value}
          getColor={(d) => d.hex}
          data={data}
          height={height ?? 200}
          width={width ?? 350}
        >
          {finalValue && showLabel && (
            <LabelSeries
              data={[
                {
                  x: 0,
                  y: -10,
                  label: convertToInternationalCurrencySystem(
                    finalValue?.value || 0
                  ),
                  style: VALUE_LABEL_STYLE,
                },
              ]}
            />
          )}
        </Sunburst>
      )}
      <Box w="100%">
        {showFooter && (
          <Text fontSize="sm" w="100%">
            {pathValue || "Hover over"}
          </Text>
        )}
      </Box>
    </Flex>
  );
}
