import { numberInputAnatomy as parts } from "@chakra-ui/anatomy";
import { PartsStyleObject } from "@chakra-ui/theme-tools";

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  sm: {
    field: { borderRadius: 10 },
  },
};

const numberInputTheme = {
  parts: parts.keys,
  baseStyle: {
    field: {
      _placeholder: {
        color: "gray.600",
      },
    },
  },
  sizes,
};
export default numberInputTheme;
