import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  OrgProperties,
  selectOrgProperties,
  updateOrgProperties,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import PageLayout from "../../components/PageLayout";
import QRCampaigns from "./QRCampaigns";
import { CheckoutStep } from "./StoreLocatorCheckout";
import StoreLocatorConfig, {
  StoreLocatorConfiguration,
} from "./StoreLocatorConfig";
import { EmbeddingStep } from "./StoreLocatorEmbedding";

export function StoreLocatorConfigPage() {
  const orgProperties = useAppSelector(selectOrgProperties);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const storeLocatorConfigured = ["payed", "free_trial"].includes(
    orgProperties?.store_locator?.status || ""
  );

  const saveChanges = async (newConfig: StoreLocatorConfiguration) => {
    setIsLoading(true);
    const newOrgConfig = _.cloneDeep(orgProperties || {}) as OrgProperties;
    newOrgConfig.store_locator = newConfig;
    newOrgConfig.store_locator.s3Bucket =
      newOrgConfig.properties?.storeLocatorS3Bucket;
    // @ts-ignore
    const wrapped = await dispatch(updateOrgProperties(newOrgConfig));
    setIsLoading(false);
    // @ts-ignore
    return unwrapResult(wrapped);
  };

  const onConfigChange = (newConfig: StoreLocatorConfiguration) => {
    if (!newConfig?.initialCoordinates) {
      toast.warn("Please enter a location for the initial map coordinates");
      return;
    }
    if (
      (newConfig.UATrackingId || newConfig.GAMeasurementId) &&
      !new RegExp(`^${!!newConfig.UATrackingId ? "UA" : "G"}-`).test(
        newConfig.UATrackingId || newConfig.GAMeasurementId
      )
    ) {
      toast.warn(
        `Google Analytics ID must begin with ${
          newConfig.UATrackingId ? "UA" : "G"
        }-`
      );
      return;
    }
    newConfig.searchFilters =
      typeof newConfig?.searchFilters === "string"
        ? [newConfig?.searchFilters]
        : newConfig?.searchFilters;
    saveChanges(newConfig);
    if (storeLocatorConfigured) {
      return;
    } else {
      setTabIndex(1);
    }
  };

  return (
    <PageLayout title="Store Locator Settings">
      <Tabs
        index={tabIndex}
        onChange={setTabIndex}
        w={"100%"}
        data-tour="storelocator-config"
      >
        <TabList>
          <Tab>Settings</Tab>
          {!storeLocatorConfigured && <Tab>Pricing</Tab>}
          {storeLocatorConfigured && <Tab>Embed</Tab>}
          {storeLocatorConfigured && <Tab>Share</Tab>}
        </TabList>
        <TabPanels>
          <TabPanel>
            <StoreLocatorConfig
              onSubmit={onConfigChange}
              isLoading={isLoading}
            />
          </TabPanel>
          {!storeLocatorConfigured && (
            <TabPanel>
              <CheckoutStep />
            </TabPanel>
          )}
          {storeLocatorConfigured && (
            <TabPanel>
              <EmbeddingStep />
            </TabPanel>
          )}
          {storeLocatorConfigured && (
            <TabPanel>
              <QRCampaigns onSubmit={onConfigChange} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </PageLayout>
  );
}
