import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Select } from "@chakra-ui/select";
import React from "react";
import { connect } from "react-redux";
import { setActiveIntegrations } from "../features/data-integrations/integrationSlice";
import { getInstagramAccounts } from "../services/facebook_api.service";

function FacebookPageSelect({
  pages,
  selectedPage,
  setSelectedPage,
  accessToken,
  setInstagramAccounts,
}) {
  function updateInstagramList(pageIndex) {
    getInstagramAccounts(
      accessToken,
      pages[pageIndex].id,
      pages[pageIndex].access_token
    ).then(setInstagramAccounts);
  }
  return (
    <FormControl>
      <FormLabel>Page:</FormLabel>
      <Select
        isRequired
        value={
          (pages?.findIndex((item) => item.id === selectedPage?.id) ?? -1) + 1
        }
        onChange={(event) => {
          setSelectedPage(pages[+event.target.value - 1]);
          updateInstagramList(+event.target.value - 1);
        }}
      >
        <option value={0} disabled selected>
          {" "}
          -- select a page --{" "}
        </option>
        {pages?.map((page, index) => (
          <option key={page.id} value={index + 1}>
            {page.name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
export default connect(
  (state) => ({
    accessToken: state.app.accessToken,
    pages: state.integrations.activeIntegrations?.facebook?.pages,
    selectedPage: state.integrations.activeIntegrations?.facebook?.selectedPage,
  }),
  (dispatch) => ({
    setSelectedPage: (page) =>
      dispatch(
        setActiveIntegrations((otherIntegrations) => ({
          ...otherIntegrations,
          facebook: {
            ...(otherIntegrations.facebook || {}),
            selectedPage: page,
          },
        }))
      ),
    setInstagramAccounts: (instagramAccounts) =>
      dispatch(
        setActiveIntegrations((otherIntegrations) => ({
          ...otherIntegrations,
          facebook: {
            ...(otherIntegrations.facebook || {}),
            instagramAccounts,
            selectedInstagramAccount: instagramAccounts?.[0],
          },
        }))
      ),
  })
)(FacebookPageSelect);
