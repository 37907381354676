import { LinkIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Center,
  CloseButton,
  Divider,
  Flex,
  HStack,
  IconButton,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { wrapTo } from "kepler.gl/actions";
import { onLayerClick } from "kepler.gl/dist/actions/vis-state-actions";
import pluralize from "pluralize";
import React, { useEffect, useState } from "react";
import { IoStar, IoStarOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { RESOURCES } from "../../constants/user-constants";
import { crudFavorite } from "../../features/map-views/mapViewsSlice";
import { selectVisState } from "../../features/map/keplerReducer";
import { getDemographics } from "../../services/api.service";
import { getZipcodeAdvertisments } from "../../services/facebook_api.service";
import FacebookAdsTab from "../mapItemModal/FacebookAdsTab";
import InfoTab from "../mapItemModal/InfoTab";
import ProductsTab from "../mapItemModal/ProductsTab";
import SalesTab from "../../features/sales/SalesTab";
import TopicsTab from "../mapItemModal/TopicsTab";
import { AddNoteModal } from "../mapItemModal/ZipCodeCard/AddNoteModal";
import ZipDemographics from "../mapItemModal/ZipCodeCard/ZipDemographics";

const tabNames = ["Info", "Data", "Social", "Products", "Sales", "FB Ads"];

function MapItemPanel({
  width = 720,
  containerProps,
  products,
  accessToken,
  clickedObject,
  views,
  userResources,
  activeView,
  orgConfig,
  removeClickedObject,
  crudFavorite,
  audienceVariables,
  states,
  country,
  zipSize,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const [noDataTabs, setNoDataTabs] = useState({});
  const [filteredProduct, setFilteredProduct] = useState();
  const [zipcodeAds, setZipcodeAds] = useState([]);
  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const [extraData, setExtraData] = useState();
  const zipcodeLabel = orgConfig?.zipcodeNameReplacement || "Zipcode";
  const storeLabel = orgConfig?.storeNameReplacement || "Store";

  const getTabIsDisabled = (tabName) => {
    switch (tabName) {
      case "Products":
        return !userResources?.includes(RESOURCES.PRODUCTS_BTN);
      case "Sales":
        return !userResources?.includes(RESOURCES.SALES_UPLOAD);
      case "FB Ads":
        return !userResources?.includes(RESOURCES.INTEGRATION_FACEBOOK_CONNECT);
      default:
        return false;
    }
  };

  useEffect(() => {
    if (clickedObject) {
      getDemographics(
        "zcta",
        audienceVariables.filter(
          (key) => !["total", "hispanic_or_latino"].includes(key)
        ),
        [],
        orgConfig?.limitedStates || states?.map((state) => state.abbr),
        [],
        [],
        [clickedObject.zipcode?.substring(0, zipSize)],
        {},
        accessToken,
        undefined,
        false,
        country
      )
        .then((result) => {
          setExtraData(result?.[0] || {});
        })
        .catch(() => {
          setExtraData({});
        });
    } else {
      setExtraData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, audienceVariables, clickedObject]);

  useEffect(() => {
    if (filteredProduct) {
      getZipcodeAdvertisments([clickedObject.zipcode], accessToken).then(
        (productAdsResult) => {
          setZipcodeAds(productAdsResult);
        }
      );
    }
  }, [accessToken, clickedObject, filteredProduct]);

  const onFinishLoading = React.useCallback(
    (hasData, tabName) =>
      setNoDataTabs((oldValue) => ({
        ...oldValue,
        [tabName]: !hasData,
      })),
    []
  );

  const foundFavorite =
    clickedObject &&
    views?.[`view${activeView}`]?.favorites?.find(
      (fav) =>
        fav[clickedObject.address ? "store_id" : "demographic_id"] ===
        clickedObject.id
    );

  const foundNotes =
    (clickedObject &&
      views?.[`view${activeView}`]?.annotations?.filter?.(
        (note) =>
          note[clickedObject.address ? "store_id" : "demographic_id"] ===
          clickedObject.id
      )) ||
    [];

  return (
    <Stack
      direction={["column", null, "row"]}
      height={["40%", null, "100%"]}
      background="white"
      rounded="md"
      width={["100%", null, width]}
      textAlign="start"
      overflow="hidden"
      {...containerProps}
    >
      <Flex flexDir="column" w="100%" h="100%">
        <HStack p={2} bg="gray.100">
          {clickedObject?.id && (
            <IconButton
              icon={
                foundFavorite ? (
                  <IoStar color="yellow.400" />
                ) : (
                  <IoStarOutline color="black" />
                )
              }
              variant="link"
              isLoading={favoriteLoading}
              onClick={() => {
                setFavoriteLoading(true);
                crudFavorite({
                  action: foundFavorite ? "delete" : "create",
                  data: {
                    id: foundFavorite?.id,
                    [clickedObject.address ? "store_id" : "demographic_id"]:
                      clickedObject.id,
                  },
                }).then(() => setFavoriteLoading(false));
              }}
            />
          )}
          <Box position={"relative"}>
            <AddNoteModal RESOURCES={RESOURCES} userResources={userResources} />
            <Badge
              ml="-3"
              position={"absolute"}
              colorScheme={foundNotes?.length ? "green" : "gray"}
            >
              {foundNotes?.length ?? 0}
            </Badge>
          </Box>
          <Text fontSize="xl" fontWeight="bold" align="center" flex={1}>
            {!clickedObject?.address &&
              clickedObject?.zipcode &&
              `${pluralize(zipcodeLabel, 1)}: ${
                clickedObject?.zipcode || clickedObject?.["Zip Code"]
              }`}
            {clickedObject?.address &&
              `${storeLabel}: ${clickedObject?.address}`}
          </Text>
          <IconButton
            disabled
            color="black"
            variant="link"
            icon={<LinkIcon />}
          />
          <CloseButton
            onClick={() => {
              removeClickedObject(views?.[`view${activeView}`]?.id);
            }}
          />
        </HStack>
        <Divider />
        <Box flex={1} overflowY="hidden">
          {!extraData ? (
            <Center h={"100%"}>
              <Spinner />
            </Center>
          ) : (
            <Tabs
              isFitted
              variant="enclosed-colored"
              defaultIndex={0}
              index={activeTab}
              height="100%"
              display="flex"
              flexDir="column"
              onChange={(index) => {
                const name = tabNames[index];
                if (!noDataTabs[name]) {
                  setActiveTab(index);
                }
              }}
            >
              <TabPanels flex={1} overflow="auto">
                <TabPanel>
                  <ZipDemographics
                    accessToken={accessToken}
                    extraData={extraData}
                  />
                </TabPanel>
                <TabPanel>
                  <InfoTab extraData={extraData} />
                </TabPanel>
                <TabPanel>
                  <TopicsTab
                    onFinishLoading={onFinishLoading}
                    tabIsActive={activeTab === 2}
                  />
                </TabPanel>
                <TabPanel>
                  <ProductsTab
                    products={products}
                    onFinishLoading={onFinishLoading}
                    tabIsActive={activeTab === 3}
                  />
                </TabPanel>
                <TabPanel>
                  <SalesTab
                    products={products}
                    filteredProduct={filteredProduct}
                    setFilteredProduct={setFilteredProduct}
                    onFinishLoading={onFinishLoading}
                  />
                </TabPanel>
                <TabPanel>
                  <FacebookAdsTab
                    products={products}
                    zipcodeAds={zipcodeAds}
                    filteredProduct={filteredProduct}
                    setFilteredProduct={setFilteredProduct}
                  />
                </TabPanel>
              </TabPanels>
              <Divider />
              <TabList bg="gray.100">
                {tabNames.map((name) => (
                  <Tab
                    key={name}
                    bg={noDataTabs[name] ? "red.200" : undefined}
                    isDisabled={getTabIsDisabled(name)}
                  >
                    <Tooltip
                      label="No data for this tab"
                      aria-label="A tooltip"
                      isDisabled={!noDataTabs[name]}
                    >
                      <Text w="100%" h="100%">
                        {name}
                      </Text>
                    </Tooltip>
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          )}
        </Box>
      </Flex>
    </Stack>
  );
}

export default connect(
  (state) => {
    const clicked =
      selectVisState(state)?.clicked || selectVisState(state)?.customClicked;
    return {
      products: state.app.products,
      accessToken: state.app.accessToken,
      clickedObject: clicked?.object?.properties,
      views: state.mapViews.views,
      userResources: state.app.currentUser.resources,
      activeView: state.mapViews.activeView,
      orgConfig: state.app.orgProperties?.properties,
      audienceVariables:
        state.app.properties?.find(
          (properties) => properties.name === "audiences"
        )?.value || [],
      states: (
        state.app.properties?.find((properties) => properties.name === "states")
          ?.value || []
      )
        .map((val) => {
          try {
            return JSON.parse(val.replaceAll("'", '"'));
          } catch (error) {
            return undefined;
          }
        })
        .filter((val) => !!val),
      country: state.app.orgProperties?.properties?.country,
      zipSize: state.app.orgProperties?.properties?.zipSize,
    };
  },
  (dispatch) => ({
    removeClickedObject: (activeView) => {
      dispatch(wrapTo(`view${activeView}`, onLayerClick(null)));
    },
    crudFavorite: (payload) => dispatch(crudFavorite(payload)),
  })
)(MapItemPanel);
