import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  GridItem,
  HStack,
  Select,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import pluralize from "pluralize";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Form from "../../components/Form";
import { RESOURCES } from "../../constants/user-constants";
import { getProperties } from "../../services/api.service";
import { getInsights } from "../../services/insights.service";
import InsightsSelector from "../insights/InsightsSelector";
import {
  fetchStores,
  setIsSelectingOnMap,
} from "../map-selection/mapSelectionSlice";
import { selectLoadedZipcodes } from "../map/keplerReducer";

function StoresSearch({
  loadedDemographics,
  orgConfig,
  mapSelection,
  setIsSelecting,
  chains,
  categories,
  getStores,
  isLoadingStores,
  userResources,
  accessToken,
  selectedAudience,
  states,
  onClose,
}) {
  const zipcodeLabel = orgConfig?.zipcodeNameReplacement || "Zipcode";
  const storeLabel = orgConfig?.storeNameReplacement || "Store";
  const [formValues, setFormValues] = useState();
  const [categoryOptions, setCategoryOptions] = useState();
  const [shouldCreateLayer, setShouldCreateLayer] = useState(false);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [useMapSelection, setUseMapSelection] = useState(false);
  const [searchInOrg, setSearchInOrg] = useState(false);
  const [insightsToColorBy, setInsightsToColorBy] = useState();
  const [insightsToBorderBy, setInsightsToBorderBy] = useState();
  const [storeInsightOptions, setStoreInsightOptions] = useState();

  const storeFilterSchema = (chains, categories) => [
    {
      key: "name",
      label: "Name",
      type: "string",
    },
    {
      key: "chains",
      label: "Chain",
      type: "list",
      options: chains,
    },
    {
      key: "category",
      label: "Category",
      type: "list",
      options: categoryOptions || categories,
      getOptionValue: (suggestion) => {
        return typeof suggestion === "object"
          ? `${suggestion.category}: ${suggestion.count}`
          : suggestion;
      },
    },
    {
      key: "store_id",
      label: `Specific ${pluralize(storeLabel)}`,
      type: "list",
      helperText: `IDs of ${pluralize(storeLabel)} you want to add to the map`,
    },
    {
      key: "zipcode",
      label: pluralize(zipcodeLabel, 1),
      type: "list",
      options: loadedDemographics?.map((demo) => demo.zipcode) || [],
      gridItemProps: { colStart: 1, colSpan: 2 },
      helperText: `Only ${pluralize(storeLabel)} in these ${pluralize(
        zipcodeLabel,
        2
      )} will be included`,
    },
    {
      renderFormControl: (values, onChange) => (
        <GridItem colSpan={2}>
          <Accordion w="100%" allowToggle defaultIndex={0}>
            <AccordionItem>
              <AccordionButton fontSize={18} fontWeight="bold">
                Insights
                <AccordionIcon ml="auto" />
              </AccordionButton>
              <AccordionPanel>
                <VStack spacing={5}>
                  <FormControl>
                    <FormLabel>Add {storeLabel} insights</FormLabel>
                    <InsightsSelector
                      insightOptions={storeInsightOptions}
                      value={values?.insights}
                      onChange={(value) => onChange("insights", value)}
                    />
                  </FormControl>
                  <HStack>
                    <FormControl>
                      <FormLabel>Fill color by</FormLabel>
                      <FormHelperText>
                        The selected property will be used to fill the color of
                        the
                        {storeLabel}
                      </FormHelperText>
                      <Select
                        value={insightsToColorBy}
                        disabled={
                          Object.keys(values?.insights || {}).filter(
                            (k) => !!values?.insights?.[k]
                          ).length === 0
                        }
                        onChange={(event) =>
                          setInsightsToColorBy(event.target.value)
                        }
                      >
                        <option value="" selected>
                          Select a property
                        </option>
                        {storeInsightOptions
                          ?.filter((variable) =>
                            Object.keys(values?.insights || {})
                              .filter((key) => !!values.insights[key])
                              .includes(`${variable.id}`)
                          )
                          .map((variable) => (
                            <option key={variable.id} value={variable.id}>
                              {variable.name}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Border color by</FormLabel>
                      <FormHelperText>
                        The selected property will be used to color the border
                        of the {storeLabel}
                      </FormHelperText>
                      <Select
                        value={insightsToBorderBy}
                        disabled={
                          Object.keys(values?.insights || {}).filter(
                            (k) => !!values?.insights?.[k]
                          ).length === 0
                        }
                        onChange={(event) =>
                          setInsightsToBorderBy(event.target.value)
                        }
                      >
                        <option value="" selected>
                          Select a property
                        </option>
                        {storeInsightOptions
                          ?.filter((variable) =>
                            Object.keys(values?.insights || {})
                              .filter((key) => !!values.insights[key])
                              .includes(`${variable.id}`)
                          )
                          .map((variable) => (
                            <option key={variable.id} value={variable.id}>
                              {variable.name}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  </HStack>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton fontSize={18} fontWeight="bold">
                Advanced options
                <AccordionIcon ml="auto" />
              </AccordionButton>
              <AccordionPanel>
                <VStack spacing={5}>
                  <Flex justify="space-between" alignItems="center" w="100%">
                    <Box>
                      <Switch
                        isChecked={!!useMapSelection}
                        onChange={() =>
                          setUseMapSelection((oldValue) => !oldValue)
                        }
                      >
                        Use {pluralize(zipcodeLabel, 1)} map selection
                      </Switch>
                      <Text fontSize={12} color="darkgray">
                        {useMapSelection
                          ? `${pluralize(
                              storeLabel
                            )} will be searched in the ${pluralize(
                              zipcodeLabel,
                              2
                            )} you selected on the map`
                          : `${pluralize(
                              storeLabel
                            )} will be searched in all of the ${pluralize(
                              zipcodeLabel,
                              2
                            )} you have on the map`}
                      </Text>
                    </Box>
                    {!mapSelection?.zipcodes?.length && useMapSelection && (
                      <Button onClick={() => setIsSelecting()}>
                        Select on map
                      </Button>
                    )}
                  </Flex>
                  <Flex justify="space-between" alignItems="center" w="100%">
                    <Box>
                      <Switch
                        isChecked={!!searchInOrg}
                        onChange={() => setSearchInOrg((oldValue) => !oldValue)}
                      >
                        Search in organization
                      </Switch>
                      <Text fontSize={12} color="darkgray">
                        {searchInOrg
                          ? `${pluralize(
                              storeLabel
                            )} will be searched in you organization`
                          : `${pluralize(
                              storeLabel
                            )} will be searched outside of your organization`}
                      </Text>
                    </Box>
                  </Flex>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </GridItem>
      ),
    },
  ];

  const onSubmit = async () => {
    setShouldSubmit(false);
    const _formValues = { ...(formValues || {}) };
    _formValues.zipcode = _formValues.zipcode?.length
      ? _formValues.zipcode
      : useMapSelection
      ? mapSelection.zipcodes.map((item) => item.zipcode)
      : loadedDemographics?.map((demo) => demo.zipcode);
    const insightParams = storeInsightOptions
      ?.filter((op) =>
        Object.keys(_formValues?.insights || {})
          .filter((key) => !!_formValues.insights[key])
          .includes(`${op.id}`)
      )
      ?.reduce((acc, op) => {
        const queryParams = op.endpoint
          ?.split("?")?.[1]
          ?.split("&")
          .filter((param) => param.startsWith("q"))
          .map((param) => param.split("=")[1]);
        return [...new Set([...acc, ...(queryParams || [])])];
      }, []);
    await getStores({
      ..._formValues,
      insightParams,
      colorBy: storeInsightOptions
        ?.find((option) => option.id === +insightsToColorBy)
        ?.endpoint?.split("?")?.[1]
        ?.split("&")
        .filter((param) => param.startsWith("q"))
        .map((param) => param.split("=")[1])
        ?.join("_&_"),
      colorBySecondary: storeInsightOptions
        ?.find((option) => option.id === +insightsToBorderBy)
        ?.endpoint?.split("?")?.[1]
        ?.split("&")
        .filter((param) => param.startsWith("q"))
        .map((param) => param.split("=")[1])
        ?.join("_&_"),
      category: _formValues.category?.map((cat) =>
        typeof cat === "string" ? cat : cat.category
      ),
      orgOnly: searchInOrg || undefined,
      shouldCreateLayer,
    });
    if (onClose) onClose();
  };

  useEffect(() => {
    const { state, counties, cities } = selectedAudience?.selectedItems || {
      state: states?.map((state) => ({ id: state.abbr })),
    };
    const { chains } = formValues || {};
    getProperties(accessToken, "for_stores", {
      states: orgConfig?.limitedStates || state?.map((item) => item.id),
      counties,
      cities,
      chains,
    }).then((properties) => {
      const newCategoryOptions = (
        properties?.find((properties) => properties.name === "categories")
          ?.value || []
      ).map((str) => {
        try {
          return JSON.parse(str.replaceAll("'", '"'));
        } catch (error) {
          return undefined;
        }
      });
      setCategoryOptions(
        newCategoryOptions?.length ? newCategoryOptions : undefined
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, formValues, selectedAudience]);

  useEffect(() => {
    getInsights(accessToken, { level: "store" }, "spec").then(
      setStoreInsightOptions
    );
  }, [accessToken]);

  return (
    <Box h="100%">
      <Form
        schema={storeFilterSchema(chains, categories)}
        values={formValues}
        onFormValuesChange={setFormValues}
        submitFromOutside={{ shouldSubmit }}
        onSubmit={onSubmit}
      />
      <Flex
        direction="row"
        justifyContent="space-between"
        position="absolute"
        right={0}
        bottom={0}
        bg="white"
        p={5}
        w="100%"
        borderTop="1px solid gainsboro"
        zIndex={9}
      >
        <Box>
          <Switch
            isChecked={!!shouldCreateLayer}
            onChange={() => setShouldCreateLayer((oldValue) => !oldValue)}
          >
            Create new layer
          </Switch>
          <Text fontSize={12} color="darkgray">
            {shouldCreateLayer
              ? `Your current ${storeLabel} layers will stay the same`
              : `Your current ${storeLabel} layers will be replaced with this search`}
          </Text>
        </Box>
        <Flex direction="row">
          <Button
            isLoading={isLoadingStores}
            colorScheme="blue"
            disabled={
              isLoadingStores || !userResources?.includes(RESOURCES.STORES_GET)
            }
            ml={3}
            onClick={() => {
              setShouldSubmit(true);
            }}
          >
            Show results
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}

export default connect(
  (state) => ({
    isLoadingStores:
      !!state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
        ?.loading?.stores,
    selectedAudience:
      state.mapViews.views[`view${state.mapViews.activeView}`]?.audience
        ?.selectedAudience,
    loadingStoreRank:
      state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
        ?.loadingStoreRank,
    userResources: state.app.currentUser?.resources,
    chains:
      state.app.properties?.find((properties) => properties.name === "chain")
        ?.value || [],
    categories:
      state.app.properties?.find(
        (properties) => properties.name === "categories"
      )?.value || [],
    orgConfig: state.app.orgProperties?.properties,
    mapSelection:
      state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
        ?.selection,
    loadedDemographics: selectLoadedZipcodes(state),
    accessToken: state.app.accessToken,
    states: (
      state.app.properties?.find((properties) => properties.name === "states")
        ?.value || []
    )
      .map((val) => {
        try {
          return JSON.parse(val.replaceAll("'", '"'));
        } catch (error) {
          return undefined;
        }
      })
      .filter((val) => !!val),
  }),
  (dispatch) => ({
    setIsSelecting: () => dispatch(setIsSelectingOnMap(true)),
    getStores: (filter) => dispatch(fetchStores(filter)),
  })
)(StoresSearch);
