import { Box, Text } from "@chakra-ui/layout";
import { hierarchy, linkRadial, scaleLinear, select, tree as d3Tree } from "d3";
import React, { useRef } from "react";

export default function RadialTree({
  data = [],
  dimensions = {},
  h,
  w,
  ...other
}) {
  const svgRef = useRef();
  const { width } = dimensions;
  const radius = width / 2;

  const getFillOpacity = (d) => {
    if (!d.parent) {
      return 0;
    }
    let scale;
    if (typeof d.data.freq === "number") {
      const { min, max } = d.parent.children.reduce(
        (acc, n) => ({
          min: n.data.freq < acc.min ? n.data.freq : acc.min,
          max: n.data.freq > acc.max ? n.data.freq : acc.max,
        }),
        { min: +Infinity, max: -Infinity }
      );
      scale = scaleLinear().domain([min, max]).range([0.3, 1]);
      return scale(d.data.freq);
    }
    scale = scaleLinear().domain([0, d.parent.children.length]).range([1, 0.3]);
    return scale(
      d.parent.children.findIndex((item) => item.data.name === d.data.name)
    );
  };

  React.useEffect(() => {
    const _data = hierarchy(data);

    const tree = d3Tree()
      .size([Math.PI, radius])
      .separation((a, b) => (a.parent === b.parent ? 1 : 2) / a.depth);

    const root = tree(_data);

    // Create root container where we will append all other chart elements
    const svgEl = select(svgRef.current);
    svgEl.selectAll("*").remove(); // Clear svg content before adding new elements
    const parentG = svgEl.append("g").attr("transform", `rotate(90)`);
    parentG
      .append("g")
      .attr("fill", "none")
      .attr("stroke", "#012e5a")
      .attr("stroke-opacity", 0.2)
      .attr("stroke-width", 1.5)
      .selectAll("path")
      .data(root.links())
      .join("path")
      .attr(
        "d",
        linkRadial()
          .angle((d) => d.x)
          .radius((d) => d.y)
      );

    parentG
      .append("g")
      .selectAll("circle")
      .data(root.descendants())
      .join("circle")
      .attr(
        "transform",
        (d) => `
                    rotate(${(d.x * 180) / Math.PI - 90})
                    translate(${d.y},0)
                  `
      )
      .attr("fill", "#479AF0")
      .attr("fill-opacity", getFillOpacity)
      .attr("r", 5);

    parentG
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 14)
      .attr("stroke-linejoin", "round")
      .attr("stroke-width", 3)
      .selectAll("text")
      .data(root.descendants())
      .join("text")
      .style("font-size", (d) => {
        return d.depth === 1 ? 14 : 14;
      })
      .style("font-weight", (d) => {
        return d.depth === 1 ? "bold" : "";
      })
      .attr("transform", (d) => {
        return `
                    rotate(${(d.x * 180) / Math.PI - 90}) 
                    translate(${d.y},0)
                    rotate(${d.x >= Math.PI / 2 ? 180 : 0})
                  `;
      })
      .attr("dy", "0.31em")
      .attr("x", (d) => {
        const shouldBeInside = !d.children;
        const isFirstHalf = d.x < Math.PI / 2;
        return isFirstHalf
          ? shouldBeInside
            ? 8
            : -8
          : shouldBeInside
          ? -8
          : 8;
      })
      .attr("text-anchor", (d) => {
        const shouldBeInside = !d.children;
        const isFirstHalf = d.x < Math.PI / 2;
        return isFirstHalf
          ? shouldBeInside
            ? "start"
            : "end"
          : shouldBeInside
          ? "end"
          : "start";
      })
      .text((d) => d.data.name)
      .clone(true)
      .lower()
      .attr("stroke", "white");

    svgEl.attr("viewBox", [-300, 0, 600, 600]);
  }, [data]);

  return (
    <Box h={h} w={w} {...other}>
      {!data?.children?.length && <Text fontSize="lg">No topic data</Text>}
      {!!data?.children?.length && <svg ref={svgRef} height={h} width={w} />}
    </Box>
  );
}
