import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Heading } from "@chakra-ui/layout";
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
} from "@chakra-ui/react";
import { DataType } from "ka-table/enums";
import pluralize from "pluralize";
import { useState } from "react";
import { FaDownload, FaUpload, FaVideo } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import XLSX from "xlsx";
import {
  selectAccessToken,
  selectOrgProperties,
  selectUserResources,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { DathicFileDrop } from "../../components/DathicFileDrop";
import UploadBtn from "../../components/uploadBtn";
import { RESOURCES } from "../../constants/user-constants";
import { Product } from "../../domain/Product";
import { getProducts, postProduct } from "../../services/api.service";
import { trackClick } from "../../services/tracking.service";
import { refreshIframe } from "../store-locator/storeLocatorSlice";

const productSchema = [
  { key: "name", label: "name", type: DataType.String, isRequired: true },
  {
    key: "url",
    label: "image url",
    type: DataType.String,
  },
  { key: "category", label: "category", type: DataType.String },
  { key: "description", label: "description", type: DataType.String },
  { key: "sku", label: "sku", type: DataType.String },
  { key: "flavor", label: "flavor", type: DataType.String },
  { key: "nutrients", label: "nutrients", type: DataType.String },
  { key: "gtin_upc", label: "gtin_upc", type: DataType.String },
  { key: "manufacturer", label: "manufacturer", type: DataType.String },
  { key: "ingredients", label: "ingredients", type: DataType.String },
  { key: "status", label: "status", type: DataType.String },
];

type Props = {
  onFinish?: () => void;
};
export const ImportProductsSteps = ({ onFinish }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const accessToken = useAppSelector(selectAccessToken);
  const userResources = useAppSelector(selectUserResources);
  const orgProperties = useAppSelector(selectOrgProperties);
  const dispatch = useAppDispatch();
  const [generatingTemplate, setGeneratingTemplate] = useState(false);
  const [file, setFile] = useState<File>();
  const [fileForMapping, setFileForMapping] = useState<File>();

  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";

  const generateTemplate = async (isCsv: boolean) => {
    setGeneratingTemplate(true);
    let products: Product[] = [];

    try {
      products = (await getProducts(accessToken)) as Product[];
    } catch (e) {
      console.log(e);
    }

    if (!isCsv) {
      var wb = XLSX.utils.book_new();

      const productsSheet = XLSX.utils.json_to_sheet([
        {
          name: "",
          description: "",
          category: "",
          sku: "",
          status: "",
        },
      ]);
      XLSX.utils.book_append_sheet(wb, productsSheet, "Product");
      XLSX.writeFile(wb, "ProductTemplate.xlsx");
    } else {
      const header = ["name", "description", "category", "sku", "status"];
      const rows = [header].concat(
        products.map((row) => [
          ...header.map((h) => `${row[h as keyof Product] || ""}`),
        ])
      );

      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(";")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var element = document.createElement("a");
      element.setAttribute("href", encodedUri);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
    setGeneratingTemplate(false);
  };
  return (
    <VStack
      w="100%"
      textAlign="start"
      spacing={5}
      bg="white"
      p={3}
      rounded="lg"
    >
      {onFinish && (
        <Button
          variant="link"
          alignSelf="flex-start"
          leftIcon={<ChevronLeftIcon />}
          onClick={() => {
            if (onFinish) onFinish();
          }}
        >
          Back
        </Button>
      )}
      <HStack w="100%" justifyContent="space-between">
        <Heading size="lg">Import products with a spreadsheet</Heading>
        <Button variant="outline" leftIcon={<FaVideo />} isDisabled>
          Questions? Watch video
        </Button>
      </HStack>
      <Divider />
      <HStack w="100%">
        <VStack flex={1} alignItems="flex-start">
          <Heading size="md">Step 1: Download Template</Heading>
          <Text fontSize="sm" color="gray.800">
            This file shows exactly how Dathic organizes product data.
            <br />
            You will see all the products that are already in your organization.
          </Text>
        </VStack>
        <HStack w="40%" justifyContent="center">
          <Button
            whiteSpace="normal"
            leftIcon={<FaDownload />}
            onClick={() => generateTemplate(true)}
            isDisabled={generatingTemplate}
            isLoading={generatingTemplate}
            id="product_csv_template"
          >
            CSV
          </Button>
          <Button
            whiteSpace="normal"
            leftIcon={<FaDownload />}
            onClick={() => generateTemplate(false)}
            isDisabled={generatingTemplate}
            isLoading={generatingTemplate}
            id="product_excel_template"
          >
            Excel
          </Button>
        </HStack>
      </HStack>
      <Divider />
      <HStack w="100%">
        <VStack flex={1} alignItems="flex-start">
          <Heading size="md">Step 2: Fill out template</Heading>
          <Text fontSize="sm" color="gray.800">
            Add new products to the spreadsheet. Certain fields need to be
            entered in order for your import to be successful. Make sure they
            are complete before you start importing.
          </Text>
        </VStack>
        <HStack w="40%" justifyContent="center">
          <Popover trigger="hover">
            <PopoverTrigger>
              <Button>View mandatory fields</Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Mandatory fields for products</PopoverHeader>
              <PopoverBody>
                <List spacing={3}>
                  {productSchema
                    .filter((field) => !!field.isRequired)
                    .map((field) => (
                      <ListItem>
                        <FormControl isRequired>
                          <FormLabel>{field.label}</FormLabel>
                        </FormControl>
                      </ListItem>
                    ))}
                </List>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>
      </HStack>
      <Divider />
      <HStack w="100%">
        <VStack flex={1} alignItems="flex-start">
          <Heading size="md">Step 3: Import spreadsheet</Heading>
          <Text fontSize="sm" color="gray.800">
            Upload your file. Only Excel (.xls and .xlsx) and .csv file types
            are supported.
          </Text>
        </VStack>
        <HStack w="40%" justifyContent="center">
          <DathicFileDrop
            fileFromOutside={file}
            onChange={setFile}
            accept=".xls, .xlsx, .csv"
            message="Only Excel (.xls and .xlsx) and .csv file types are supported."
            shouldUpload={false}
          />
        </HStack>
      </HStack>
      <Divider />
      <HStack w="100%">
        <VStack flex={1} alignItems="flex-start">
          <Heading size="md">Step 4: Mapping</Heading>
          <Text fontSize="sm" color="gray.800">
            Map each column in your spreadsheet to the corresponding label in
            Dathic. Unmapped columns will not be imported into Dathic.
          </Text>
        </VStack>
        <HStack w="40%" justifyContent="center">
          <UploadBtn
            entityName={`Products`}
            CustomButton={() => (
              <Button
                onClick={() => {
                  setFileForMapping(undefined);
                  setFileForMapping(file);
                }}
                isDisabled={!file}
              >
                Map fields
              </Button>
            )}
            fileFromOutside={fileForMapping}
            postItem={(item: any, accessToken: string) =>
              postProduct(new Product(item).buildForPost(), accessToken)
            }
            Icon={FaUpload}
            isDisabled={!userResources?.includes(RESOURCES.PRODUCTS_CREATE)}
            schema={productSchema.map((item) => ({
              key: item.key,
              title: item.label,
              isRequired: item.isRequired,
              dataType: item.type,
            }))}
            defaultSheet="Products"
            finishMessage={{
              message: `Click ${pluralize(
                storeLabel
              )} to associate the product with the ${pluralize(
                storeLabel
              )} that handle it.`,
              actions: [
                {
                  label: `Associate with ${pluralize(storeLabel)}`,
                  callback: () => {
                    if (location.pathname.includes("storelocator")) {
                      navigate(`/storelocator/stores`, {
                        state: { tabIndex: 1 },
                      });
                      return;
                    }
                    navigate(`/StoresTabs/stores-manage`, {
                      state: { tabIndex: 1 },
                    });
                  },
                },
              ],
            }}
            onFinish={() => {
              trackClick("upload-products-confirm", "");
              dispatch(refreshIframe());
              setFile(undefined);
              setFileForMapping(undefined);
            }}
          />
        </HStack>
      </HStack>
      <Divider />
      <HStack w="100%">
        <VStack flex={1} alignItems="flex-start">
          <Heading size="md">Step 5: Confirm</Heading>
          <Text fontSize="sm" color="gray.800">
            Confirm the import. Dathic will check the data to detect duplicates
            and will upload the products.
            <br />
            After import a product. Click on "{pluralize(storeLabel)}" to
            associate your products with the {pluralize(storeLabel)} that handle
            them
          </Text>
        </VStack>
        <HStack w="40%" justifyContent="center">
          <Button
            onClick={() => {
              if (location.pathname.includes("storelocator")) {
                navigate(`/storelocator/stores`, { state: { tabIndex: 1 } });
                return;
              }
              navigate(`/StoresTabs/stores-manage`, { state: { tabIndex: 1 } });
            }}
          >
            Manage {pluralize(storeLabel)}
          </Button>
        </HStack>
      </HStack>
    </VStack>
  );
};
