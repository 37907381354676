//@flow
import { processCsvData } from "kepler.gl/dist/processors";
import { unparse } from "papaparse";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { wrapTo } from "kepler.gl/dist/actions";
import { toast } from "react-toastify";
import { createGeoLayer, deleteGeoLayer } from "../layers/layersSlice";
import { appendVisData } from "../map/keplerReducer";
import { getIndieInsights } from "../../services/insights.service";
const layerConfigFile = require("../../data/layer_config.json");

// INITIAL_STATE
export const initialState = {
  loading: {
    indieInsights: false,
  },
};

export function defaultInsightLayerConfig(
  info,
  currentUser,
  orgProperties,
  colorBy,
  colorBySecondary
) {
  const layerConfig = JSON.parse(JSON.stringify(layerConfigFile));
  const insightConfig = JSON.parse(
    JSON.stringify(layerConfig.indieInsightsConfig)
  );
  if (colorBy?.name) {
    insightConfig.config.visState.layers[0].visualChannels.colorField.name =
      colorBy.name;
    insightConfig.config.visState.layers[0].visualChannels.colorField.type =
      colorBy.type;
  }
  if (colorBySecondary?.name) {
    insightConfig.config.visState.layers[0].visualChannels.strokeColorField.name =
      colorBySecondary.name;
    insightConfig.config.visState.layers[0].visualChannels.strokeColorField.type =
      colorBySecondary.type;
  }
  insightConfig.config.visState.layers[0].config.label = info.label;
  insightConfig.config.visState.layers[0].config.dataId = info.id;
  insightConfig.config.visState.layers[0].config.id = info.id;
  insightConfig.config.visState.layers[0].id = info.id;
  insightConfig.config.visState.layers[0].config.visConfig.stroked =
    colorBySecondary?.name ? true : false;
  return insightConfig;
}

//$FlowFixMe
export const fetchIndieInsights = createAsyncThunk(
  "insights/fetchIndieInsights",
  async (filters, { getState, dispatch }) => {
    const state = getState();
    const {
      states,
      zipcode,
      insightSpecId,
      cities,
      counties,
      mapId,
      config,
      shouldCreateLayer,
      colorBy,
      colorBySecondary,
      layerName,
    } = filters || {};
    const { accessToken, currentUser, orgProperties } = state.app;
    const { activeView, views } = state.mapViews;
    const { visState } =
      state.keplerGl?.[`view${views?.[`view${activeView}`]?.id}`] || {};
    const selectedStates =
      states?.map((s) => s.id) ||
      Array.from(
        new Set(
          visState?.layers
            ?.filter(
              (layer) => !!layer.config?.dataId?.includes("selected_audience")
            )
            ?.flatMap((layer) => layer.dataToFeature)
            ?.map((f) => f.properties?.state)
            ?.filter((s) => !!s) || []
        )
      );
    const view = views[`view${activeView}`];
    if (selectedStates?.length > 0) {
      let insightJson = await getIndieInsights(
        accessToken,
        insightSpecId,
        zipcode,
        cities,
        counties,
        selectedStates
      );
      const data = (insightJson || []).map((insight, id) => {
        const item = { ...(insight?.properties || {}) };
        const geometry =
          insight.geometry &&
          (typeof insight.geometry === "string"
            ? JSON.parse(insight.geometry)
            : insight.geometry
          ).features[0].geometry;

        if (geometry) {
        }
        item._geojson = JSON.stringify({
          geometry: {
            coordinates: [insight.longitude, insight.latitude],
            type: "Point",
          },
          id,
          properties: insight.properties,
          type: "Feature",
        });
        delete item.lat;
        delete item.latitude;
        delete item.longitude;
        delete item.lng;
        delete item.geometry;
        delete item.geometry_mid;
        delete item.geometry_low;
        delete item.deleted_at;
        Object.keys(item).forEach((key) => {
          if (item[key] === null || item[key] === undefined) {
            delete item[key];
          }
        });
        if (colorBy && !item[colorBy]) {
          const sumAudience = colorBy
            .split("_&_")
            .reduce((acc, audience) => acc + (item[audience] || 0), 0);
          item[colorBy] = sumAudience;
        }
        return {
          ...insight,
          ...item,
        };
      });
      const insightsCsv = unparse(data);
      if (insightsCsv) {
        if (!shouldCreateLayer) {
          const insightLayers = getState().keplerGl[
            `view${views?.[`view${activeView}`]?.id}`
          ]?.visState?.layers?.filter((layer) =>
            layer.config.dataId.includes("independent_insights")
          );

          dispatch(
            deleteGeoLayer({
              layersToDelete: insightLayers.map((layer) => layer.config.dataId),
            })
          );
        }
        let newId = "independent_insights";

        newId = newId + new Date().getTime();
        const info = {
          label: layerName || "Insights",
          id: newId,
        };
        const processedGeojson = processCsvData(insightsCsv);
        let insightsConfig = defaultInsightLayerConfig(
          info,
          currentUser,
          orgProperties,
          {
            name: colorBy,
            type: processedGeojson.fields.find((f) => f.name === colorBy)?.type,
          },
          {
            name: colorBySecondary,
            type: processedGeojson.fields.find(
              (f) => f.name === colorBySecondary
            )?.type,
          }
        );
        dispatch(
          wrapTo(
            mapId || `view${view?.id}`,
            appendVisData({
              // datasets
              datasets: {
                info,
                data: processedGeojson,
              },
              // option
              options: {
                keepExistingConfig: true,
              },
              config: config || insightsConfig,
            })
          )
        );

        if (!mapId) {
          dispatch(
            createGeoLayer({
              name: info.label,
              geolayer_details: insightJson.map((item) => ({
                geolayer_detail_generic: {
                  geometry: JSON.stringify(
                    (item._geojson &&
                      (typeof item._geojson === "string"
                        ? JSON.parse(item._geojson)
                        : item._geojson
                      ).geometry) ||
                      item.geometry
                  ),
                  properties: JSON.stringify(item),
                },
              })),
              color_by: colorBy,
              config: {
                info,
              },
              view_id: view.id,
            })
          );
        }
      } else {
        toast.info("No insights found for your filter");
      }
      return insightJson;
    } else {
      toast.warning("Please select an audience");
    }
  }
);

// REDUCER
//$FlowFixMe
const insightSlice = createSlice({
  name: "insights",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      if (
        typeof action.payload?.key !== "string" ||
        typeof action.payload?.value !== "boolean"
      ) {
        throw new Error("Invalid payload for reducer setLoading");
      }
      state.loading[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: {
    [fetchIndieInsights.pending]: (state, action) => {
      state.loading.indieInsights = true;
    },
    [fetchIndieInsights.fulfilled]: (state, action) => {
      state.loading.indieInsights = false;
      toast(`Insights loaded`, {
        autoClose: true,
        type: toast.TYPE.SUCCESS,
      });
    },
    [fetchIndieInsights.rejected]: (state, action) => {
      state.loading.indieInsights = false;
      toast(`Insights failed to load`, {
        autoClose: true,
        type: toast.TYPE.ERROR,
      });
    },
  },
});

export const { setLoading } = insightSlice.actions;

export default insightSlice.reducer;
