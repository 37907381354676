import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";

const errorHandler = (error) => {
  toast.error(error.message);
  const response = error.response;
  if (response) {
    switch (response.data.msg) {
      case "Token has expired":
        const auth = getAuth();
        auth.signOut().then();
        break;

      default:
        break;
    }
  }
};

export async function getPredictionData(zipcodes) {
  try {
    return require("./test_preds.json");
  } catch (error) {
    errorHandler(error);
    return { data: { content: { data: [], type: "" } } };
  }
}
