import React, { useState } from "react";
import { Box, Button, Select } from "@chakra-ui/react";
import { DataType } from "ka-table/enums";
import { connect } from "react-redux";
import {
  DecorativeAxis,
  DiscreteColorLegend,
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
} from "react-vis";
import { RESOURCES } from "../../constants/user-constants";
import { predictSalesStore } from "../map-selection/mapSelectionSlice";
import {
  deleteSale,
  getPredictFBSales,
  getSales,
  postSale,
  putSale,
} from "../../services/api.service";
import { EntityTable } from "../../components/entity-table/EntityTable";
import { selectVisState } from "../map/keplerReducer";
import { DateTime } from "luxon";

const _SalesTab = (props) => {
  const {
    clickedObject,
    accessToken,
    products,
    userResources,
    filteredProduct,
    setFilteredProduct,
    onFinishLoading,
  } = props;
  const [salesData, setSalesData] = useState([]);
  const [maxValue, setMaxValue] = useState(-Infinity);
  const [predictingSales, setPredictingSales] = useState(false);
  const [predictionsNoAds, setPredictionNoAds] = useState();
  const [predictionsAds, setPredictionAds] = useState();

  const handleRankStores = () => {
    setPredictingSales(true);
    getPredictFBSales(clickedObject.id, accessToken)
      .then((predictions) => {
        setPredictingSales(false);
        const newMax = Math.max(
          [
            ...(predictions?.prediction_no_ad || []),
            ...(predictions?.prediction_ad || []),
          ]?.reduce(
            (acc, prediction) => (prediction > acc ? prediction : acc),
            -Infinity
          ) || -Infinity,
          maxValue
        );
        setPredictionNoAds(
          predictions?.prediction_no_ad?.map((units, index) => ({
            end_date: predictions.future[index],
            units,
          }))
        );
        setPredictionAds(
          predictions?.prediction_ad?.map((units, index) => ({
            end_date: predictions.future[index],
            units,
          }))
        );
        setMaxValue(newMax);
      })
      .catch(() => {
        setPredictingSales(false);
      });
  };

  const myProductsWithSales =
    products?.filter(
      (product) =>
        (!filteredProduct || filteredProduct === `${product.id}`) &&
        (salesData?.findIndex((sale) => sale.product_id === product.id) ||
          -1) !== -1
    ) || [];
  return (
    <Box>
      {!!salesData.length && (
        <Button
          onClick={handleRankStores}
          isLoading={predictingSales}
          disabled={!userResources?.includes(RESOURCES.SALES_PREDICT)}
        >
          Predict sales
        </Button>
      )}
      <Box mb={20}>
        <XYPlot width={624} height={250} xType="time">
          <HorizontalGridLines />
          <VerticalGridLines />
          <XAxis tickLabelAngle={30} />
          <YAxis />
          <YAxis orientation="right" />
          {(predictionsNoAds?.length || predictionsAds?.length) && (
            <DecorativeAxis
              axisStart={{ x: new Date().getTime(), y: 0 }}
              axisEnd={{ x: new Date().getTime(), y: maxValue }}
              axisDomain={[0, maxValue]}
            />
          )}
          {!myProductsWithSales.length && (
            <LineSeries
              className="first-series"
              curve={"curveMonotoneX"}
              data={[
                {
                  x: new Date().getTime(),
                  y: clickedObject?.predicted_avg_units ?? 0,
                },
                {
                  x: DateTime.now().plus({ week: 1 }).toMillis(),
                  y: clickedObject?.predicted_avg_units ?? 0,
                },
              ]}
            />
          )}
          {!!myProductsWithSales.length &&
            myProductsWithSales.map((product) => (
              <LineSeries
                className="first-series"
                curve={"curveMonotoneX"}
                data={salesData
                  .filter((sale) => sale.product_id === product.id)
                  .sort((a, b) =>
                    DateTime.fromISO(a.end_date) > DateTime.fromISO(b.end_date)
                      ? -1
                      : +DateTime.now() === +DateTime.fromISO(b.end_date)
                      ? 0
                      : 1
                  )
                  .map((sale) => ({
                    x: DateTime.fromISO(sale.end_date).toMillis(),
                    y: sale.units,
                  }))}
              />
            ))}
          {predictionsNoAds && (
            <LineSeries
              className="first-series"
              curve={"curveMonotoneX"}
              data={predictionsNoAds
                .sort((a, b) =>
                  DateTime.fromISO(a.end_date) > DateTime.fromISO(b.end_date)
                    ? -1
                    : +DateTime.fromISO(a.end_date) ===
                      +DateTime.fromISO(b.end_date)
                    ? 0
                    : 1
                )
                .map((sale) => ({
                  x: DateTime.fromISO(sale.end_date).toMillis(),
                  y: sale.units,
                }))}
            />
          )}
          {predictionsAds && (
            <LineSeries
              className="first-series"
              curve={"curveMonotoneX"}
              data={predictionsAds
                .sort((a, b) =>
                  DateTime.fromISO(a.end_date) > DateTime.fromISO(b.end_date)
                    ? -1
                    : +DateTime.fromISO(a.end_date) ===
                      +DateTime.fromISO(b.end_date)
                    ? 0
                    : 1
                )
                .map((sale) => ({
                  x: DateTime.fromISO(sale.end_date).toMillis(),
                  y: sale.units,
                }))}
            />
          )}
          <DiscreteColorLegend
            items={
              predictionsNoAds?.length || predictionsAds?.length
                ? [
                    "today",
                    ...(products
                      ?.filter(
                        (product) =>
                          (!filteredProduct ||
                            filteredProduct === `${product.id}`) &&
                          salesData.findIndex(
                            (sale) => sale.product_id === product.id
                          ) !== -1
                      )
                      .map((product) => product.name) || []),
                    "Prediction no ads",
                    "Prediction ads",
                  ]
                : [
                    ...(products
                      ?.filter(
                        (product) =>
                          (!filteredProduct ||
                            filteredProduct === `${product.id}`) &&
                          salesData.findIndex(
                            (sale) => sale.product_id === product.id
                          ) !== -1
                      )
                      .map((product) => product.name) || []),
                  ]
            }
            orientation="horizontal"
          />
        </XYPlot>
      </Box>
      <Select
        placeholder="All products"
        value={filteredProduct}
        onChange={(event) => setFilteredProduct(event.target.value)}
        isRequired
      >
        {products?.map((product) => (
          <option key={product.id} value={product.id}>
            {product.name}
          </option>
        ))}
      </Select>

      {products && clickedObject?.id && (
        <EntityTable
          initialTableProps={{
            columns: [
              {
                title: "Product ID",
                dataType: DataType.Number,
                key: "product_id",
                isRequired: true,
                filterOptions: products.map((product) => ({
                  value: product.id,
                  label: product.name,
                })),
              },
              {
                title: "Units",
                dataType: DataType.Number,
                key: "units",
                isRequired: true,
              },
              {
                title: "Start Date",
                dataType: DataType.Date,
                key: "start_date",
                isRequired: true,
              },
              {
                title: "End Date",
                dataType: DataType.Date,
                key: "end_date",
                isRequired: true,
              },
              {
                title: "Period",
                dataType: DataType.String,
                key: "period",
                isRequired: true,
              },
            ],
          }}
          fetch={async () => {
            try {
              const sales = await getSales(clickedObject.id, accessToken);
              setSalesData(sales || []);
              if (onFinishLoading) onFinishLoading(!!sales?.length, "Sales");
              setMaxValue(
                sales?.reduce(
                  (acc, sale) => (sale.units > acc ? sale.units : acc),
                  -Infinity
                )
              );
              return sales?.map((sale) => ({
                ...sale,
                start_date: DateTime.fromISO(sale.start_date).toJSDate(),
                end_date: DateTime.fromISO(sale.end_date).toJSDate(),
              }));
            } catch (e) {
              console.log(e);
            }
          }}
          deleteItem={
            userResources?.includes(RESOURCES.SALES_DELETE) &&
            (async (saleId) => {
              try {
                await deleteSale(saleId, accessToken);
              } catch (error) {
                console.log(error);
              }
            })
          }
          postItem={
            userResources?.includes(RESOURCES.SALES_UPLOAD) &&
            (async (newSale) => {
              const saleToPost = {
                ...newSale,
                store_id: clickedObject.id,
              };
              try {
                await postSale(saleToPost, accessToken);
              } catch (error) {
                console.log(error);
              }
            })
          }
          putItem={
            userResources?.includes(RESOURCES.SALES_UPDATE) &&
            (async (saleId, updatedSale) => {
              const saleObject = salesData.find((sale) => sale.id === saleId);
              const saleToPost = {
                id: saleId,
                ...saleObject,
                ...updatedSale,
              };
              try {
                await putSale(saleToPost, accessToken);
              } catch (error) {
                console.log(error);
              }
            })
          }
        />
      )}
    </Box>
  );
};

export default connect(
  (state) => ({
    clickedObject: (
      selectVisState(state)?.clicked || selectVisState(state)?.customClicked
    )?.object?.properties,
    accessToken: state.app.accessToken,
    userResources: state.app.currentUser?.resources,
  }),
  (dispatch) => ({
    predictSalesStore: (payload) => dispatch(predictSalesStore(payload)),
  })
)(_SalesTab);
