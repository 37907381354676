import {
  Box,
  Heading,
  HStack,
  Stack,
  StackProps,
  Text,
  VStack,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";

type Props = {
  title?: string;
  label?: string;
  icon?: React.ReactNode;
  contentDirection?: "column" | "row";
  contentProps?: StackProps;
  containerProps?: StackProps;
};

export function PageSection({
  icon,
  title,
  label,
  contentDirection = "row",
  children,
  containerProps = {},
  contentProps = {},
}: PropsWithChildren<Props>) {
  return (
    <VStack w="100%" alignItems={"flex-start"} {...containerProps}>
      {(icon || title) && (
        <HStack alignItems="center" w="100%" spacing={3}>
          {icon}
          <Heading fontSize="lg">{title}</Heading>
          <Box flex={1} h="2px" bg="gray.500" />
        </HStack>
      )}
      {!!label && <Text fontSize="sm">{label}</Text>}
      <Stack
        direction={contentDirection}
        p={3}
        bg="white"
        rounded="lg"
        w="100%"
        spacing={5}
        {...contentProps}
      >
        {children}
      </Stack>
    </VStack>
  );
}
