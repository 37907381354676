import { CloseIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  HStack,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Stack,
  Tag,
  TagLabel,
  Text,
  VStack,
} from "@chakra-ui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useContext, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { setCurrentUser } from "../../app/appSlice";
import AutocompleteInput from "../../components/AutocompleteInput";
import { ResponsiveModal } from "../../components/responsive-modal";
import MessageModalContext from "../../contexts/MessageModalContext";
import { camelCaseWords } from "../../utils/stringUtils";
import { fetchAudience } from "../audience/audienceSlice";
import { fetchStores, rankStores } from "../map-selection/mapSelectionSlice";
import { fetchView, setViews } from "../map-views/mapViewsSlice";
import DemoReportModal from "./DemoReportModal";
import "./styles.css";

const chains = [
  "Walgreens",
  "TARGET",
  "Walmart",
  "Macys",
  "Sams",
  "Dollar Tree",
  "Family Dollar",
  "Dollar General",
  "Costco",
  "Quick Stop",
];

function Demo({
  currentUser,
  getAudience,
  getStores,
  rankStores,
  activeView,
  onNewSearch,
  setUser,
  audienceVariables,
  states,
}) {
  const statesArray =
    states?.map((state, index) => ({
      title: state.name,
      id: state.abbr,
    })) || [];
  const audienceArray = audienceVariables.map((code) => ({
    title: camelCaseWords(code),
    id: code,
  }));

  let location = useLocation();
  const modalsContext = useContext(MessageModalContext);
  let params = new URLSearchParams(location.search);
  const selectedAudienceParam = params.get("selectedAudience");
  const selectedStateParam = params.get("selectedState");
  const [calculatingReport, setCalculatingReport] = useState(false);
  const [teaserFormValues, setTeaserFormValues] = useState({
    audience: audienceArray.find((item) => item.id === selectedAudienceParam)
      ?.title,
    states: statesArray
      .filter((item) => item.id === selectedStateParam)
      .map((item) => item.title),
  });
  const [report, setReport] = useState();
  const [viewingReport, setViewingReport] = useState(false);

  const handleTeaser = async (event) => {
    setCalculatingReport(true);

    try {
      const selectedAudience = audienceArray.find(
        (audience) => audience.title === teaserFormValues.audience
      );
      const selectedStates = teaserFormValues.states
        ?.map((state) => statesArray.find((item) => item.title === state))
        ?.filter((item) => item);
      const selectedChains = teaserFormValues.chains
        ?.map((chain) => chains.find((item) => item === chain))
        ?.filter((item) => item);

      const wrappedAudience = await getAudience({
        zoneLevel: "zcta",
        selectedItems: {
          nationality: [selectedAudience],
          state: selectedStates,
          variable: [],
          zipcodes: teaserFormValues.zipcodes?.map((zip) => ({ id: zip })),
        },
        activeView,
      });
      const unwrappedAudience = await unwrapResult(wrappedAudience);

      if (unwrappedAudience?.errors) {
        modalsContext.showModal({
          title: "Could not fetch your audience",
          message: unwrappedAudience.errors,
          actions: [
            {
              label: "Request full access",
              callback: (modalIndex) => {
                window.open(
                  "https://calendly.com/laura-rocha/30-mins-dathic--locator-support"
                );
              },
              props: { colorScheme: "blue", mt: 5 },
            },
          ],
        });
        throw new Error(unwrappedAudience.errors);
      }
      setUser({
        ...currentUser,
        num_requests: (currentUser.num_requests || 0) + 1,
      });

      let unwrappedRanking;
      let unwrappedStores;
      if (selectedChains?.length) {
        const wrappedStores = await getStores({
          chains: selectedChains,
          states: selectedStates,
        });
        unwrappedStores = await unwrapResult(wrappedStores);
        if (unwrappedStores?.length) {
          const wrappedRanking = await rankStores({
            stores: unwrappedStores,
            nationalities: [selectedAudience],
          });
          unwrappedRanking = await unwrapResult(wrappedRanking);
          unwrappedRanking = unwrappedRanking.reduce(
            (acc, current) =>
              acc.filter((i) => i.score === current.score).length === 0
                ? [...acc, current]
                : acc,
            []
          );
        }
      }
      setReport({
        demographics: unwrappedAudience,
        selectedAudience: audienceArray.find(
          (audience) => audience.title === teaserFormValues.audience
        ),
        stores: unwrappedRanking || unwrappedStores,
        selectedState: teaserFormValues.states?.[0],
      });
    } catch (error) {
      setReport();
    }

    setCalculatingReport(false);
  };

  return (
    <>
      <VStack
        borderRadius={10}
        p={5}
        boxShadow="0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
      >
        <Text fontWeight="bold">Currently in demo mode</Text>
        <Text>
          In demo mode, you are limited to only 10 free searches and can not
          access most features.
        </Text>
        <Text>
          Free searches left:{" "}
          <Badge colorScheme={currentUser?.num_requests >= 8 ? "red" : "green"}>
            {10 - (currentUser?.num_requests || 0)}
          </Badge>
        </Text>
        {Object.keys(report || {}).length && (
          <Button onClick={() => setViewingReport(true)}>
            See free report
          </Button>
        )}
        <Button colorScheme="blue" variant="link" onClick={() => setReport()}>
          New search
        </Button>
      </VStack>
      <ResponsiveModal isOpen={!report} isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton onClick={() => setReport({})} />
          <ModalBody>
            <Flex
              w="100%"
              flex={1}
              p={10}
              justifyContent="center"
              alignItems="center"
            >
              <HStack alignItems="center" spacing={10}>
                <VStack spacing={5}>
                  <Text fontSize="3.25rem" fontWeight="bold">
                    Dathic Growth free demo
                  </Text>
                  <Text fontWeight="bold">
                    Market geolocation reports that allow optimizing the
                    analysis of potential customers and distribution channels
                  </Text>
                  <Text>
                    The platform uses data analytics and artificial intelligence
                    models to identify the different Hispanic communities,
                    evaluate and predict the best points of sale for
                    distribution in the United States according to the
                    concentration of potential consumers.
                  </Text>
                  <Text>
                    you have{" "}
                    <Badge
                      colorScheme={
                        currentUser?.num_requests >= 8 ? "red" : "green"
                      }
                    >
                      {10 - (currentUser?.num_requests || 0)}
                    </Badge>{" "}
                    free searches left
                  </Text>
                </VStack>
                <Box>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setTimeout(() => {
                        handleTeaser(teaserFormValues);
                      }, 500);
                    }}
                  >
                    <Stack dir="column" spacing={5} minW={200}>
                      <FormControl isRequired>
                        <FormLabel>Select your target audience</FormLabel>
                        <Select
                          value={teaserFormValues.audience}
                          onChange={(event) =>
                            setTeaserFormValues((oldValues) => ({
                              ...oldValues,
                              audience: event.target.value,
                            }))
                          }
                        >
                          {["", ...audienceArray]
                            .map((item) => item.title)
                            .map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControl isRequired>
                        <FormLabel>
                          Select the states where you want to grow
                        </FormLabel>
                        <AutocompleteInput
                          options={statesArray}
                          getSuggestionValue={(s) => s.title}
                          disabled={teaserFormValues.states?.length >= 1}
                          handleSearchResultClick={(selection) =>
                            setTeaserFormValues((oldValues) => ({
                              ...oldValues,
                              states: [
                                ...(oldValues.states || []),
                                `${selection.suggestionValue}`,
                              ],
                            }))
                          }
                          exclude={teaserFormValues.states.map((s) => ({
                            title: s,
                          }))}
                        />
                        <FormHelperText>
                          {teaserFormValues.states?.length >= 1 &&
                            "Purchase plan to select more states"}
                        </FormHelperText>
                        <Flex overflowX="auto" mt={2}>
                          {teaserFormValues.states?.map((item, index) => (
                            <Tag key={index} mr={1} mb={2} minW={20}>
                              <TagLabel>{item}</TagLabel>
                              <CloseIcon
                                onClick={() => {
                                  let newList = teaserFormValues.states;
                                  newList.splice(
                                    newList.findIndex(
                                      (searchItem) => searchItem === item
                                    ),
                                    1
                                  );
                                  setTeaserFormValues((oldValues) => ({
                                    ...oldValues,
                                    states: newList,
                                  }));
                                }}
                              />
                            </Tag>
                          ))}
                        </Flex>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Zipcodes</FormLabel>
                        <Input
                          datatype="list"
                          type="number"
                          pattern="[0-9]{5}"
                          disabled={teaserFormValues.zipcodes?.length >= 10}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") e.preventDefault();
                          }}
                          onKeyUp={(e) => {
                            if (
                              e.key === "Enter" &&
                              e.target.value?.length === 5
                            ) {
                              setTeaserFormValues((oldValues) => ({
                                ...oldValues,
                                zipcodes: [
                                  ...(oldValues.zipcodes || []),
                                  `${e.target.value}`,
                                ],
                              }));
                              e.target.value = "";
                            } else if (e.key === "Enter") {
                              toast.warning("Zipcodes have 5 digits");
                            }
                          }}
                          onBlur={(e) => {
                            if (
                              e.target.value &&
                              e.target.value?.length === 5
                            ) {
                              setTeaserFormValues((oldValues) => ({
                                ...oldValues,
                                zipcodes: [
                                  ...(oldValues.zipcodes || []),
                                  `${e.target.value}`,
                                ],
                              }));
                              e.target.value = "";
                            } else {
                              toast.warning("Zipcodes have 5 digits");
                            }
                          }}
                        />
                        <FormHelperText>
                          {teaserFormValues.zipcodes?.length >= 10 &&
                            "Purchase plan to select more zipcodes"}
                        </FormHelperText>
                        <Grid gridTemplateColumns="repeat(2, 1fr)" mt={2}>
                          {teaserFormValues.zipcodes?.map((item, index) => (
                            <Tag key={index} mr={1} mb={2} minW={20}>
                              <TagLabel>{item}</TagLabel>
                              <CloseIcon
                                onClick={() => {
                                  let newList = teaserFormValues.zipcodes;
                                  newList.splice(
                                    newList.findIndex(
                                      (searchItem) => searchItem === item
                                    ),
                                    1
                                  );
                                  setTeaserFormValues((oldValues) => ({
                                    ...oldValues,
                                    zipcodes: newList,
                                  }));
                                }}
                              />
                            </Tag>
                          ))}
                        </Grid>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Market Chains</FormLabel>
                        <AutocompleteInput
                          options={chains}
                          disabled={teaserFormValues.chains?.length >= 1}
                          handleSearchResultClick={(selection) =>
                            setTeaserFormValues((oldValues) => ({
                              ...oldValues,
                              chains: [
                                ...(oldValues.chains || []),
                                `${selection.suggestionValue}`,
                              ],
                            }))
                          }
                          exclude={teaserFormValues.chains}
                        />
                        <FormHelperText>
                          {teaserFormValues.chains?.length >= 1 &&
                            "Purchase plan to select more chains"}
                        </FormHelperText>
                        <Flex overflowX="auto" mt={2}>
                          {teaserFormValues.chains?.map((item, index) => (
                            <Tag key={index} mr={1} mb={2} minW={20}>
                              <TagLabel>{item}</TagLabel>
                              <CloseIcon
                                onClick={() => {
                                  let newList = teaserFormValues.chains;
                                  newList.splice(
                                    newList.findIndex(
                                      (searchItem) => searchItem === item
                                    ),
                                    1
                                  );
                                  setTeaserFormValues((oldValues) => ({
                                    ...oldValues,
                                    chains: newList,
                                  }));
                                }}
                              />
                            </Tag>
                          ))}
                        </Flex>
                      </FormControl>
                    </Stack>
                    <Button
                      mt={5}
                      variant="solid"
                      width="100%"
                      colorScheme="blue"
                      type="submit"
                      isLoading={calculatingReport}
                    >
                      Get data
                    </Button>
                  </form>
                </Box>
              </HStack>
            </Flex>
          </ModalBody>
        </ModalContent>
      </ResponsiveModal>
      {(Object.keys(report || {}).length || undefined) && (
        <DemoReportModal
          isOpen={viewingReport}
          onClose={() => setViewingReport(false)}
          report={report}
        />
      )}
    </>
  );
}

export default connect(
  (state) => ({
    currentUser: state.app.currentUser,
    loginStatus: state.app.loginStatus,
    activeView: state.mapViews.activeView,
    audienceVariables:
      state.app.properties?.find(
        (properties) => properties.name === "audiences"
      )?.value || [],
    states: (
      state.app.properties?.find((properties) => properties.name === "states")
        ?.value || []
    )
      .map((val) => {
        try {
          return JSON.parse(val.replaceAll("'", '"'));
        } catch (error) {
          return undefined;
        }
      })
      .filter((val) => !!val),
  }),
  (dispatch) => ({
    setUser: (newUser) => dispatch(setCurrentUser(newUser)),
    changeViewsList: (newView) => dispatch(setViews(newView)),
    loadView: (viewKey) => dispatch(fetchView(viewKey)),
    getAudience: (payload) => dispatch(fetchAudience(payload)),
    getStores: (filter) => dispatch(fetchStores(filter)),
    rankStores: (payload) => dispatch(rankStores(payload)),
  })
)(Demo);
