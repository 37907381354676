import { createAsyncThunk, createSlice, unwrapResult } from "@reduxjs/toolkit";
import { processCsvData } from "kepler.gl/dist/processors";
import { toast } from "react-toastify";
import { wrapTo } from "kepler.gl/actions";
import { unparse } from "papaparse";
import { appendVisData, selectVisState } from "../map/keplerReducer";
import { RESOURCES } from "../../constants/user-constants";
import { getDemographics, getPrediction } from "../../services/api.service";
import { createGeoLayer, deleteGeoLayer } from "../layers/layersSlice";
import { removeDataset } from "kepler.gl/dist/actions/vis-state-actions";
const layerConfigFile = require("../../data/layer_config.json");

// INITIAL_STATE
export const initialState = {
  isLoading: false,
  selectedAudience: undefined,
};

export const rankZipcodes = createAsyncThunk(
  "audience/rankZipcodes",
  async (payload, { getState, dispatch }) => {
    const { zipcodes, mapId, layerDataId, config, colorBy, colorBySecondary } =
      payload || {};
    const state = getState();
    const { accessToken, currentUser, orgProperties } = state.app;
    const oldLayer = selectVisState(state)?.layers?.find(
      (layer) => layer.config.dataId === layerDataId
    );

    const zipsPrediction = await getPrediction(
      undefined,
      undefined,
      zipcodes?.map((zip) => zip.zipcode),
      undefined,
      undefined,
      accessToken,
      orgProperties?.properties?.zipcodePredictVersion
    );
    if (zipsPrediction?.length) {
      const zipcodesCsv = unparse(
        oldLayer?.dataToFeature
          ?.map((feature) => {
            const zipPrediction =
              zipsPrediction.find(
                (pred) => pred.zipcode === feature?.properties?.zipcode
              ) || {};
            const zipcode =
              zipcodes.find(
                (pred) => pred.zipcode === feature?.properties?.zipcode
              ) || {};
            delete zipPrediction.id;
            const zip = {
              ...zipPrediction,
              ...(feature?.properties || {}),
              ...zipcode,
              is_selected: !!zipPrediction.is_recommended,
            };
            const _geojson = JSON.stringify({
              ...feature,
              properties: zip,
            });
            delete zip.latitude;
            delete zip.longitude;
            delete zip.geometry;
            delete zip.geometry_mid;
            delete zip.geometry_low;
            delete zip.deleted_at;
            Object.keys(zip).forEach((key) => {
              if (
                key.search(/_(lat|lon)$/) > -1 ||
                zip[key] === null ||
                zip[key] === undefined
              ) {
                delete zip[key];
              }
            });
            return {
              ...zip,
              _geojson,
            };
          })
          .sort((a, b) => Object.values(b).length - Object.values(a).length) ||
          []
      );
      if (zipcodesCsv) {
        const processedGeojson = processCsvData(zipcodesCsv);
        const info = {
          label: oldLayer.config.label,
          id: oldLayer.config.dataId,
        };
        const layerConfig = defaultAudienceLayerConfig(
          info,
          currentUser.resources,
          {
            name: colorBy,
            type: processedGeojson.fields.find((f) => f.name === colorBy)?.type,
          },
          {
            name: colorBySecondary,
            type: processedGeojson.fields.find(
              (f) => f.name === colorBySecondary
            )?.type,
          }
        );

        dispatch(
          wrapTo(
            mapId ||
              `view${
                state?.mapViews?.views?.[`view${state?.mapViews?.activeView}`]
                  ?.id
              }`,
            removeDataset(info.id)
          )
        );
        dispatch(
          wrapTo(
            mapId ||
              `view${
                state?.mapViews?.views?.[`view${state?.mapViews?.activeView}`]
                  ?.id
              }`,
            appendVisData({
              // datasets
              datasets: {
                info,
                data: processedGeojson,
              },
              // option
              options: {
                keepExistingConfig: true,
              },
              config: config || layerConfig.audienceConfig,
            })
          )
        );
      }
      return zipcodes.map((zipcode) => {
        const zipPrediction =
          zipsPrediction.find(
            (pred) => pred.id === zipcode.id || pred.zipcode === zipcode.zipcode
          ) || {};
        delete zipPrediction.id;
        return {
          ...zipcode,
          ...zipPrediction,
          is_selected: !!zipPrediction.is_recommended,
        };
      });
    } else {
      toast.info("No zipcode predictions");
    }
  },
  {
    condition: (arg, { getState }) => {
      const { orgProperties } = getState().app;
      return !orgProperties?.properties?.preventZipcodeRanking;
    },
  }
);

export function defaultAudienceLayerConfig(
  info,
  userResources,
  colorBy,
  colorBySecondary
) {
  const _index = (info.label || "").split(" in ")[0];
  const nationalities = _index.split("_&_");
  const layerConfig = JSON.parse(JSON.stringify(layerConfigFile));
  if (colorBy?.name) {
    layerConfig.audienceConfig.config.visState.layers[0].visualChannels.colorField.name =
      colorBy.name;
    layerConfig.audienceConfig.config.visState.layers[0].visualChannels.colorField.type =
      colorBy.type;
  } else {
    layerConfig.audienceConfig.config.visState.layers[0].visualChannels.colorField.name =
      _index;
  }
  if (colorBySecondary?.name) {
    layerConfig.audienceConfig.config.visState.layers[0].visualChannels.strokeColorField =
      colorBySecondary;
    layerConfig.audienceConfig.config.visState.layers[0].config.visConfig.stroked = true;
  }
  let tooltipFields = ["zipcode", "major_city"];
  if (userResources?.includes(RESOURCES.VIEW_MAP_MODAL)) {
    tooltipFields = tooltipFields
      .concat(nationalities)
      .concat(["median_household_income", "housing_units"]);
  }
  layerConfig.audienceConfig.config.visState.interactionConfig.tooltip.fieldsToShow[
    info.id
  ] = tooltipFields.map((name) => ({ name, format: null }));
  layerConfig.audienceConfig.config.visState.layers[0].config.dataId = info.id;
  layerConfig.audienceConfig.config.visState.layers[0].config.label =
    info.label;
  return layerConfig.audienceConfig;
}

export const fetchAudience = createAsyncThunk(
  "audience/fetchAudienceStatus",
  async (payload, { dispatch, getState }) => {
    const { accessToken, currentUser, orgProperties } = getState().app;
    const { views } = getState().mapViews;
    const {
      indexType,
      zoneLevel,
      index,
      selectedItems,
      activeView,
      mapId,
      shouldCreateLayer,
      insightParams,
      colorBy,
      colorBySecondary,
    } = payload;
    const view = views[`view${activeView}`];
    const _selectedItems = selectedItems;
    if (
      !_selectedItems?.nationality ||
      _selectedItems.nationality.length === 0
    ) {
      toast.warning("Select at least one origin");
      return;
    }

    let _indexType = indexType;
    let _zoneLevel = zoneLevel;

    let _index = index || _selectedItems.nationality[0].id;
    if (_indexType === "aggregate") {
      _index = _selectedItems.nationality.map((item) => item.id).join("_&_");
    }

    let nationalities =
      _selectedItems?.nationality?.map((item) => item.id) || [];
    nationalities = [
      ...new Set([
        ...nationalities,
        ...["total", "hispanic_or_latino"],
        ...nationalities
          .filter(
            (nat) =>
              ![
                "total",
                "other_hispanic_or_latino",
                "other_south_american",
                "all_other_hispanic_or_latino",
              ].includes(nat)
          )
          .map((item) => `prob_${item}`),
        ...nationalities
          .filter(
            (nat) =>
              ![
                "total",
                "other_hispanic_or_latino",
                "other_south_american",
                "all_other_hispanic_or_latino",
              ].includes(nat)
          )
          .map((item) => `prob_non_${item}`),
      ]),
    ];

    let location = _selectedItems?.state?.map((item) => item.id);
    let counties = _selectedItems?.counties;
    let cities = _selectedItems?.cities;
    let zipcodes = _selectedItems?.zipcodes?.map((item) => item.id);
    let otherFilters = _selectedItems?.otherFilters;
    let dynamicFilters = _selectedItems?.dynamicFilters;

    try {
      if (!mapId) {
        dispatch(
          setAudience({
            selectedItems: _selectedItems,
            indexType: _indexType,
            index: _index,
          })
        );
      }

      let variables = [
        "zipcode",
        "state",
        `geometry${
          location.length > 20 ? "_low" : location.length > 10 ? "_mid" : ""
        }`,
        "centroide_lat",
        "centroide_lon",
        "median_household_income",
        "housing_units",
        "occupied_housing_units",
        "median_home_value",
        "major_city",
        "population_by_age",
        "educational_attainment_for_population_25_and_over",
        "means_of_transportation_to_work_for_workers_16_and_over",
        "travel_time_to_work_in_minutes",
        "weekly_tweets",
        "monthly_rent_including_utilities_1_b",
        "monthly_rent_including_utilities_2_b",
        "monthly_rent_including_utilities_studio_apt",
        "monthly_rent_including_utilities_3plus_b",
        "id",
        "median_age",
        "population_by_race",
        "county",
        "number_of_schools_by_levels",
        "schools_by_virtual_modalities",
        "num_students_with_school_lunch_support",
        "total_school_students_by_grades",
        "school_students_by_ethnicities",
        "total_schools_by_type",
        "total_school_students",
        "number_of_schools",
      ];

      let newId = [
        "selected_audience",
        ...(nationalities || []),
        ...(variables || []),
        ...(location || []),
      ].join("_");

      newId = newId + new Date().getTime();

      const info = {
        label: `${_index} in ${
          location?.length > 3
            ? `${location.length} states`
            : location.join(" & ")
        }`,
        id: newId,
      };

      let result = await getDemographics(
        _zoneLevel,
        nationalities,
        variables,
        location,
        counties,
        cities,
        zipcodes,
        otherFilters,
        accessToken,
        dynamicFilters,
        undefined,
        orgProperties?.properties?.country,
        insightParams
      );

      if (!result?.errors && result?.length) {
        let data = result
          .map((store, id) => {
            const item = { ...store };

            Object.keys(item.insights?.[0] || {}).forEach((key) => {
              if (
                item.insights[0][key] &&
                typeof item.insights[0][key] === "object"
              ) {
                Object.keys(item.insights[0][key]).forEach((subKey) => {
                  item[subKey] = item.insights[0][key][subKey];
                });
              } else {
                item[key] = item.insights[0][key];
              }
            });

            delete item.geometry;
            delete item.geometry_mid;
            delete item.geometry_low;
            delete item.deleted_at;
            Object.keys(item).forEach((key) => {
              if (item[key] === null || item[key] === undefined) {
                delete item[key];
              }
            });
            const sumAudience = (colorBy || _index)
              .split("_&_")
              .reduce((acc, audience) => acc + (item[audience] || 0), 0);
            item[colorBy || _index] = sumAudience;
            const storeGeo =
              store[
                `geometry${
                  location.length > 20
                    ? "_low"
                    : location.length > 10
                    ? "_mid"
                    : ""
                }`
              ];
            const geometry = (
              typeof storeGeo === "string" ? JSON.parse(storeGeo) : storeGeo
            ).features[0].geometry;
            const _geojson = JSON.stringify({
              geometry,
              id,
              properties: item,
              type: "Feature",
            });
            return {
              ...item,
              _geojson,
            };
          })
          .sort((a, b) => Object.values(b).length - Object.values(a).length);

        let unwrappedRanking;
        try {
          const wrappedRanking = await dispatch(
            rankZipcodes({
              zipcodes: data,
              layerDataId: info.id,
              colorBy,
              colorBySecondary,
            })
          );
          unwrappedRanking = await unwrapResult(wrappedRanking);
        } catch (error) {
          console.log(error.message);
        }
        result = unwrappedRanking || result;

        let newGeoLayer;
        if (!mapId) {
          const wrappedGeolayer = await dispatch(
            createGeoLayer({
              name: info.label,
              geolayer_details: result.map((item) => ({
                demographic_id: item.id,
                score_demographic: item.score,
              })),
              color_by: colorBy || _index,
              config: {
                info,
                columns: (variables || []).concat(
                  ...(nationalities || []),
                  ...(insightParams || []),
                  "insights"
                ),
              },
              view_id: view.id,
            })
          );
          newGeoLayer = await unwrapResult(wrappedGeolayer);
          data = data.map((item) => ({
            ...item,
            geolayer_detail_id: newGeoLayer.geolayer_details.find(
              (detail) => detail.demographic_id === item.id
            )?.id,
          }));
        }
        const newDataCsv = unparse(data);
        const processedData = processCsvData(newDataCsv);

        const layerConfig = defaultAudienceLayerConfig(
          info,
          currentUser.resources,
          {
            name: colorBy,
            type: processedData.fields.find((f) => f.name === colorBy)?.type,
          },
          {
            name: colorBySecondary,
            type: processedData.fields.find((f) => f.name === colorBySecondary)
              ?.type,
          }
        );
        if (shouldCreateLayer === false) {
          const audienceLayers = getState().keplerGl[
            `view${views?.[`view${activeView}`]?.id}`
          ]?.visState?.layers?.filter((layer) =>
            layer.config.dataId.includes("selected_audience")
          );

          dispatch(
            deleteGeoLayer({
              layersToDelete: audienceLayers.map(
                (layer) => layer.config.dataId
              ),
            })
          );
        }
        dispatch(
          wrapTo(
            mapId || `view${views?.[`view${activeView}`]?.id}`,
            appendVisData({
              // datasets
              datasets: {
                info,
                data: processedData,
              },
              // option
              options: {
                keepExistingConfig: true,
              },
              config: layerConfig,
            })
          )
        );
        return result;
      } else if (!result?.errors) {
        toast.info("Did not find anything with your search criteria");
      } else {
        return result;
      }
    } catch (error) {
      console.warn(error);
    }
  }
);

// REDUCER
const audienceSlice = createSlice({
  name: "audience",
  initialState,
  reducers: {
    setAudience: (state, action) => {
      state.selectedAudience = action.payload;
    },
  },
  extraReducers: {
    [fetchAudience.pending]: (state, action) => {
      state.isLoading = true;
    },
    [fetchAudience.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [fetchAudience.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [rankZipcodes.pending]: (state, action) => {
      state.loadingZipRank = true;
    },
    [rankZipcodes.fulfilled]: (state, action) => {
      state.loadingZipRank = false;
    },
    [rankZipcodes.rejected]: (state, action) => {
      toast.error("Could not rank demographics");
      state.loadingZipRank = false;
    },
  },
});

export const selectSelectedAudience = (state) =>
  state.mapViews?.views[`view${state.mapViews?.activeView}`]?.audience
    ?.selectedAudience;

export const { setAudience } = audienceSlice.actions;

export default audienceSlice.reducer;
