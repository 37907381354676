import { createSlice } from "@reduxjs/toolkit";

type StoreLocatorState = {
  configCompletedIsOpen: boolean;
  productsIsOpen: boolean;
  storesIsOpen: boolean;
  iframeRefreshes: number;
};

// INITIAL_STATE
const initialState: StoreLocatorState = {
  configCompletedIsOpen: true,
  productsIsOpen: false,
  storesIsOpen: false,
  iframeRefreshes: 0,
};

const storeLocatorSlice = createSlice({
  name: "storeLocator",
  initialState,
  reducers: {
    toggleProductsModal: (state, action) => {
      state.productsIsOpen = action.payload;
    },
    toggleStoresModal: (state, action) => {
      state.storesIsOpen = action.payload;
    },
    toggleConfigCompletedModal: (state, action) => {
      state.configCompletedIsOpen = action.payload;
    },
    refreshIframe: (state) => {
      state.iframeRefreshes = state.iframeRefreshes + 1;
    },
  },
});

export const selectConfigCompletedIsOpen = (state: {
  storeLocator: StoreLocatorState;
}) => state.storeLocator.configCompletedIsOpen;
export const selectProductsIsOpen = (state: {
  storeLocator: StoreLocatorState;
}) => state.storeLocator.productsIsOpen;
export const selectStoresIsOpen = (state: {
  storeLocator: StoreLocatorState;
}) => state.storeLocator.storesIsOpen;
export const selectIframeKey = (state: { storeLocator: StoreLocatorState }) =>
  state.storeLocator.iframeRefreshes;

export const {
  toggleProductsModal,
  toggleStoresModal,
  toggleConfigCompletedModal,
  refreshIframe,
} = storeLocatorSlice.actions;

export default storeLocatorSlice.reducer;
