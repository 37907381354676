import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  SimpleGrid,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updateEmail,
  updateProfile,
} from "firebase/auth";
import { cloneDeep, merge, startCase } from "lodash";
import { useContext, useState } from "react";
import { FaUndo } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  OrgProperties,
  selectAccessToken,
  selectCurrentUser,
  selectOrgProperties,
  selectUserResources,
  selectUserRoles,
  setCurrentUser,
  updateOrgProperties,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { DathicFileDrop } from "../../components/DathicFileDrop";
import EmailVerification from "../../components/EmailVerification";
import OrgSelect from "../../components/OrgSelect";
import { PageSection } from "../../components/PageSection";
import { RESOURCES } from "../../constants/user-constants";
import MessageModalContext from "../../contexts/MessageModalContext";
import { putUser } from "../../services/api.service";
import { PasswordResetMessage } from "../login/Login";

function MyAccount() {
  const currentUser = useAppSelector(selectCurrentUser);
  const orgProperties = useAppSelector(selectOrgProperties);
  const accessToken = useAppSelector(selectAccessToken);
  const userRoles = useAppSelector(selectUserRoles);
  const userResources = useAppSelector(selectUserResources);
  const messageModalContext = useContext(MessageModalContext);
  const photoUploadDisclosure = useDisclosure();
  const dispatch = useAppDispatch();
  const [profilePhotoHash, setProfilePhotoHash] = useState(Date.now());
  const [emailChange, setEmailChange] = useState(currentUser?.username);
  const auth = getAuth();

  const userName = currentUser?.name || currentUser?.email;

  const putEmail = () => {
    const catchError = (error: any) => {
      console.log(error.code);
      switch (error.code) {
        case "auth/invalid-email":
          toast.error("Please enter a valid email");
          break;
        case "auth/email-already-in-use":
          toast.error("This email is already in use");
          break;
        case "auth/wrong-password":
          toast.error("Wrong password");
          break;
        case "auth/requires-recent-login":
          messageModalContext.showModal({
            title: "Enter your password",
            message: (dialogState, setDialogState) => (
              <Input
                type={"password"}
                value={dialogState.password}
                onChange={(e) =>
                  setDialogState({
                    password: e.currentTarget.value,
                  })
                }
              />
            ),
            actions: [
              {
                label: "Log in",
                callback(_, dialogState) {
                  signInWithEmailAndPassword(
                    auth,
                    auth.currentUser?.email!,
                    dialogState.password
                  ).then(() => {
                    putEmail();
                  }, catchError);
                },
              },
            ],
            initialDialogState: { password: "" },
          });
          break;

        default:
          toast.error("Error updating email");
          break;
      }
    };
    updateEmail(auth.currentUser!, emailChange)
      .then(() => {
        return putUser(
          {
            id: currentUser.id,
            access_token: currentUser.access_token,
            token: currentUser.token,
            username: emailChange,
            user_id: currentUser.user_id,
            email: emailChange,
          },
          accessToken
        );
      })
      .then((newUser) => {
        dispatch(
          setCurrentUser((oldUser: any) => ({
            ...oldUser,
            username: newUser.username,
            email: newUser.email,
          }))
        );
      })
      .catch(catchError);
  };

  const putOrg = async (
    changes: any | ((oldProps: OrgProperties | undefined) => any)
  ) => {
    const newOrgConfig = cloneDeep(orgProperties);
    merge(
      newOrgConfig,
      typeof changes === "function" ? changes(newOrgConfig) : changes
    );
    // @ts-ignore
    const wrapped = await dispatch(updateOrgProperties(newOrgConfig));
    // @ts-ignore
    return unwrapResult(wrapped);
  };
  return (
    <VStack w="100%" spacing={10}>
      <PageSection title="Basic info">
        <SimpleGrid
          columns={3}
          w="100%"
          gridTemplateColumns="1fr auto 1fr"
          spacing={5}
        >
          <VStack alignItems="flex-start" spacing={3}>
            <FormLabel fontWeight="bold">Personal info</FormLabel>
            <HStack>
              <VStack minW={60} flex={0}>
                {!photoUploadDisclosure.isOpen && (
                  <Avatar
                    name={userName}
                    src={currentUser?.photoURL + "?" + profilePhotoHash}
                    mr={3}
                    colorScheme="blue"
                  />
                )}
                {photoUploadDisclosure.isOpen && (
                  <DathicFileDrop
                    maxImageWidth={400}
                    shouldUpload
                    s3Params={{
                      getFileName: (file) => `user_${currentUser.id}`,
                      isPublic: true,
                      folderName: "profile-photos",
                    }}
                    onUrl={(url) => {
                      updateProfile(auth.currentUser!, {
                        photoURL: url,
                      }).then(() => {
                        photoUploadDisclosure.onClose();
                        setProfilePhotoHash(Date.now());
                      });
                    }}
                    accept="image/*"
                  />
                )}
                <Button
                  variant={"link"}
                  onClick={photoUploadDisclosure.onToggle}
                >
                  {photoUploadDisclosure.isOpen ? "Cancel" : "Change photo"}
                </Button>
              </VStack>
              <VStack>
                <FormControl>
                  <FormLabel>Display name</FormLabel>
                  <Input
                    defaultValue={currentUser.name}
                    isDisabled={!auth.currentUser}
                    onBlur={(e) => {
                      const newName = e.currentTarget.value;
                      updateProfile(auth.currentUser!, {
                        displayName: newName,
                      })
                        .then(() => {
                          return putUser(
                            {
                              id: currentUser.id,
                              access_token: currentUser.access_token,
                              token: currentUser.token,
                              username: currentUser.username,
                              user_id: currentUser.user_id,
                              name: newName,
                            },
                            accessToken
                          );
                        })
                        .then((newUser) => {
                          dispatch(
                            setCurrentUser((oldUser: any) => ({
                              ...oldUser,
                              name: newUser.name,
                            }))
                          );
                        });
                    }}
                  />
                  <FormHelperText mt={0}>
                    {startCase(userRoles?.[0]?.role?.name)}
                  </FormHelperText>
                </FormControl>
              </VStack>
            </HStack>
          </VStack>
          <Divider orientation="vertical" />
          <VStack alignItems="flex-start" spacing={3}>
            <FormLabel fontWeight="bold" w={"100%"}>
              Business info
              {!!currentUser.is_super && (
                <Button
                  variant="link"
                  float={"right"}
                  colorScheme="blue"
                  onClick={() =>
                    messageModalContext.showModal({
                      title: "Change organization",
                      message(dialogState, setDialogState) {
                        return (
                          <Box w="100%" h={250}>
                            <OrgSelect
                              value={dialogState?.organization_id}
                              onChange={(e) =>
                                setDialogState({
                                  organization_id: e,
                                })
                              }
                            />
                          </Box>
                        );
                      },
                      actions: [
                        {
                          label: "Reload",
                          callback(_, dialogState) {
                            putUser(
                              {
                                id: currentUser.id,
                                access_token: currentUser.access_token,
                                token: currentUser.token,
                                username: emailChange,
                                user_id: currentUser.user_id,
                                organization_id: dialogState?.organization_id,
                              },
                              accessToken
                            ).then(() => window.location.reload());
                          },
                        },
                      ],
                      initialDialogState: {
                        organization_id: currentUser.organization_id,
                      },
                    })
                  }
                >
                  Change
                </Button>
              )}
            </FormLabel>
            <SimpleGrid autoFlow="row" spacing={5}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input value={orgProperties?.organization?.name} isDisabled />
              </FormControl>
              <FormControl>
                <FormLabel>Notification email</FormLabel>
                <Input
                  placeholder={currentUser.username}
                  isDisabled={!userResources.includes(RESOURCES.MANAGE_ORG)}
                  defaultValue={orgProperties?.store_locator?.notificationEmail}
                  onBlur={(e) => {
                    const newName = e.currentTarget.value;
                    putOrg({ store_locator: { notificationEmail: newName } });
                  }}
                />
              </FormControl>
            </SimpleGrid>
          </VStack>
        </SimpleGrid>
      </PageSection>
      <PageSection title="Account security" contentDirection="column">
        <FormControl>
          <FormLabel fontWeight="bold">Your email/username</FormLabel>
          <HStack justifyContent="space-between" w="100%">
            <InputGroup size="md">
              <Input
                value={emailChange || ""}
                onChange={(e) => setEmailChange(e.currentTarget.value)}
              />
              {currentUser.username !== emailChange && (
                <InputRightAddon>
                  <IconButton
                    aria-label="undo email change"
                    icon={<FaUndo />}
                    onClick={() => setEmailChange(currentUser.username)}
                  />
                </InputRightAddon>
              )}
            </InputGroup>
            {currentUser.username !== emailChange && (
              <Button
                onClick={() => {
                  putEmail();
                }}
              >
                Change email
              </Button>
            )}
            {currentUser.username === emailChange &&
              !auth.currentUser?.emailVerified && (
                <Button
                  onClick={() => {
                    messageModalContext.showModal({
                      title: "Please verify your email",
                      message: <EmailVerification />,
                      actions: [
                        {
                          label: "Cancel",
                        },
                      ],
                    });
                  }}
                  colorScheme="orange"
                >
                  Verify email
                </Button>
              )}
          </HStack>
        </FormControl>
        <FormControl>
          <FormLabel fontWeight="bold">Lost your password?</FormLabel>
          <HStack justifyContent="space-between" w="100%">
            <FormHelperText mt={0}>
              If you've lost or forgotten your password we'll send you an email
              to reset your password.
            </FormHelperText>
            <Button
              onClick={() => {
                const catchError = (error: any) => {
                  switch (error.code) {
                    case "auth/invalid-email":
                      toast.error("Please enter a valid email");
                      break;
                    case "auth/user-not-found":
                      toast.error("A user with this email was not found");
                      break;

                    default:
                      toast.error("Error sending password reset link");
                      break;
                  }
                };
                messageModalContext.showModal({
                  title: "Password Reset",
                  message: (
                    <PasswordResetMessage
                      email={currentUser?.username}
                      disableInput
                      setEmail={() => {}}
                    />
                  ),
                  actions: [
                    {
                      label: "Cancel",
                      isLeastDestructive: true,
                    },
                    {
                      label: "Reset password",
                      props: { colorScheme: "blue" },
                      callback: () => {
                        try {
                          sendPasswordResetEmail(auth, currentUser?.username)
                            .then(() => {
                              toast(
                                "Your password reset link has been sent to your email"
                              );
                            })
                            .catch(catchError);
                        } catch (error) {
                          catchError(error);
                        }
                      },
                    },
                  ],
                });
              }}
            >
              Reset your password
            </Button>
          </HStack>
        </FormControl>
      </PageSection>
    </VStack>
  );
}
export default MyAccount;
