import { assign, keys, pick } from "lodash";

export class StoreReview {
  id!: string;
  uid!: string;
  source!: string;
  comment?: string;
  title?: string;
  store_source_id!: string;
  rating?: number;
  language?: string;
  quality_rating?: number;
  value_rating?: number;
  feedback_cool?: number;
  feedback_funny?: number;
  feedback_useful?: number;
  feedback_helpful?: number;
  feedback_unhelpful?: number;
  feedback_inappropriate?: number;
  business_owner_reply_comment?: string;
  num_comment_photos?: number;
  date?: Date;
  location?: string;
  id_2?: string;
  user_id?: string;
  user_name?: string;
  num_user_friends?: number;
  num_user_photos?: number;
  user_image?: string;
  user_url?: string;
  is_rating_only?: boolean;
  photos_url?: string;
  user_photos?: string[];
  is_updated?: boolean;

  constructor(candidate: any) {
    assign(this, pick(candidate, keys(this)));
  }

  buildForPost() {
    const newReview = JSON.parse(JSON.stringify(this));
    delete newReview.id;
    delete newReview.uid;
    delete newReview.source;
    delete newReview.store_source_id;
    return new StoreReview(newReview);
  }
}
