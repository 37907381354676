import { AddIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Input,
  SimpleGrid,
  Tooltip,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import AutocompleteInput from "./AutocompleteInput";

function CheckboxForGrid({
  option,
  ref,
  name,
  value,
  onChange,
  isSingleSelection,
}) {
  const [autocompleteText, setAutocompleteText] = useState();
  return (
    <div>
      <Checkbox
        isChecked={!!value?.[option.value]}
        name={name}
        ref={ref}
        onChange={(e) => {
          onChange({
            ...(!isSingleSelection ? value || {} : {}),
            [option.value]: e.target.checked || "",
          });
        }}
      >
        {option.label}
        {(option.hasInput || !!option.options?.length) && ":"}
        {option.description && (
          <Tooltip hasArrow placement="top" label={option.description}>
            <InfoOutlineIcon ml={3} />
          </Tooltip>
        )}
      </Checkbox>
      {option.hasInput && (
        <Input
          value={
            typeof value?.[option.value] === "string"
              ? value?.[option.value]
              : ""
          }
          onChange={(e) => {
            onChange({
              ...(!isSingleSelection ? value || {} : {}),
              [option.value]: e.target.value,
            });
          }}
        />
      )}
      {!!option.options?.length && (
        <AutocompleteInput
          options={option.options}
          value={
            autocompleteText ??
            (typeof value?.[option.value] === "string"
              ? value?.[option.value]
              : "")
          }
          inputProps={{ onChange: (e) => setAutocompleteText(e.target.value) }}
          getSuggestionValue={(option) => option.label}
          handleSearchResultClick={(selection) => {
            setAutocompleteText();
            onChange({
              ...(!isSingleSelection ? value || {} : {}),
              [option.value]: selection.suggestion.key,
            });
          }}
        />
      )}
    </div>
  );
}

export default function CheckboxGridInput({
  options = [],
  value = {},
  onChange = (value) => {
    console.log(value);
  },
  numVisibleOptions = 5,
  addBtnLabel = "Add an option",
  refForCheckboxes,
  name,
  isSingleSelection = false,
  isDisabled = false,
}) {
  const [addingOption, setAddingOption] = useState(false);
  const inputFieldRef = useRef();
  return (
    <SimpleGrid columns={2} spacing={3}>
      <CheckboxGroup
        value={value}
        onChange={(v) => {
          onChange(v);
        }}
        isDisabled={isDisabled}
      >
        {[
          ...options.slice(0, numVisibleOptions),
          ...options.slice(5).filter((option) => value?.[option.value]),
        ].map((option) => (
          <CheckboxForGrid
            key={option.value}
            option={option}
            name={name}
            ref={refForCheckboxes}
            value={value}
            onChange={onChange}
            isSingleSelection={isSingleSelection}
          />
        ))}
      </CheckboxGroup>
      <Box>
        {!addingOption && options?.length > numVisibleOptions && (
          <Button
            variant="link"
            colorScheme="blue"
            leftIcon={<AddIcon />}
            textAlign="start"
            alignSelf="start"
            onClick={() => {
              setAddingOption(true);
              inputFieldRef.current.focus();
            }}
          >
            {addBtnLabel}
          </Button>
        )}

        <AutocompleteInput
          containerProps={{
            style: {
              visibility:
                addingOption &&
                !!options.filter((option) => !value?.[option.value]).length
                  ? "visible"
                  : "hidden",
              height:
                addingOption &&
                !!options.filter((option) => !value?.[option.value]).length
                  ? "unset"
                  : "0px",
            },
          }}
          options={options.map((option) => ({
            key: option.value,
            label: option.label,
          }))}
          exclude={[
            ...options.slice(0, numVisibleOptions),
            ...options.slice(5).filter((option) => value?.[option.value]),
          ].map((option) => ({
            key: option.value,
            label: option.label,
          }))}
          focusInputOnSuggestionClick
          getSuggestionValue={(option) => option.label}
          handleSearchResultClick={(selection) => {
            onChange({ ...(value || {}), [selection.suggestion.key]: true });
            setAddingOption(false);
          }}
          inputProps={{
            ref: inputFieldRef,
          }}
        />
      </Box>
    </SimpleGrid>
  );
}
