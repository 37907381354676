import React from "react";
import {
  Stack,
  Flex,
  Text,
  Divider,
  VStack,
  HStack,
  SimpleGrid,
} from "@chakra-ui/react";
import { camelCaseWords } from "../../../../../utils/stringUtils";
import { connect } from "react-redux";
import { PieForTable } from "../../../../../components/PieForTable";

function InsightsTab({
  setSelectedTab,
  selectedItems,
  twitterIndicators,
  loading,
  accessToken,
  orgProperties,
}) {
  const objects = [
    ...(selectedItems.demographic || []),
    ...(selectedItems.store || []),
  ];

  return (
    <Stack
      direction={["column", null, "row"]}
      height={["40%", null, "100%"]}
      background="white"
      rounded="md"
      width={["100%", null, 650]}
      textAlign="start"
      overflowY="auto"
    >
      <Flex flexDir="column" w="100%" h="100%">
        <HStack divider={<Divider orientation="vertical" />}>
          {objects?.[0] && !!objects?.[0].insights?.length && (
            <SimpleGrid columns={1} w="100%" spacing={3}>
              {Object.keys(objects?.[0].insights[0] || {})
                .filter((key) => !!objects?.[0].insights[0][key])
                .map((key) => (
                  <VStack key={key} shadow="base" rounded="md" padding={3}>
                    <Text fontWeight="bold">{camelCaseWords(key)}</Text>
                    {objects?.[0].insights[0][key] &&
                      typeof objects?.[0].insights[0][key] === "object" && (
                        <PieForTable
                          key={key}
                          height={150}
                          width={150}
                          value={Object.keys(objects?.[0].insights[0][key]).map(
                            (innerkey) => ({
                              x: innerkey,
                              y: objects?.[0].insights[0][key][innerkey],
                            })
                          )}
                        />
                      )}
                    {typeof objects?.[0].insights[0][key] !== "object" && (
                      <Text fontSize={60}>
                        {objects?.[0].insights[0][key] === true
                          ? "Yes"
                          : objects?.[0].insights[0][key] === false
                          ? "No"
                          : `${objects?.[0].insights[0][key]}`}
                      </Text>
                    )}
                  </VStack>
                ))}
            </SimpleGrid>
          )}
          {objects?.[1] && !!objects?.[1].insights?.length && (
            <SimpleGrid columns={1} w="100%" spacing={3}>
              {Object.keys(objects?.[1].insights[0] || {})
                .filter((key) => !!objects?.[1].insights[0][key])
                .map((key) => (
                  <VStack key={key} shadow="base" rounded="md" padding={3}>
                    <Text fontWeight="bold">{camelCaseWords(key)}</Text>
                    {objects?.[1].insights[0][key] &&
                      typeof objects?.[1].insights[0][key] === "object" && (
                        <PieForTable
                          key={key}
                          height={150}
                          width={150}
                          value={Object.keys(objects?.[1].insights[0][key]).map(
                            (innerkey) => ({
                              x: innerkey,
                              y: objects?.[1].insights[0][key][innerkey],
                            })
                          )}
                        />
                      )}
                    {typeof objects?.[1].insights[0][key] !== "object" && (
                      <Text fontSize={60}>
                        {objects?.[1].insights[0][key] === true
                          ? "Yes"
                          : objects?.[1].insights[0][key] === false
                          ? "No"
                          : `${objects?.[1].insights[0][key]}`}
                      </Text>
                    )}
                  </VStack>
                ))}
            </SimpleGrid>
          )}
        </HStack>
      </Flex>
    </Stack>
  );
}

export default connect((state) => ({
  selectedItems:
    state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
      ?.comparisonSelection,
  orgProperties: state.app.orgProperties?.properties,
}))(InsightsTab);
