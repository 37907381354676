import { CopyIcon } from "@chakra-ui/icons";
import { Heading } from "@chakra-ui/layout";
import {
  Button,
  Center,
  Divider,
  HStack,
  IconButton,
  Img,
  Input,
  InputGroup,
  InputRightAddon,
  ListItem,
  Spinner,
  Text,
  UnorderedList,
  useDisclosure,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  selectAccessToken,
  selectCurrentUser,
  setCurrentUser,
} from "../app/appSlice";
import { useAppDispatch, useAppSelector } from "../app/store";
import referralBanner from "../assets/images/referrer_banner.jpg";
import { ShortUrl } from "../domain/ShortUrl";
import { generateShortUrl } from "../services/shrtco.service";

export const ReferralLinks = () => {
  const theme = useTheme();
  const termsDisclosure = useDisclosure();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const accessToken = useAppSelector(selectAccessToken);
  const [isLoading, setIsLoading] = useState(false);
  const [referrals, setReferrals] = useState<ShortUrl[]>(
    currentUser?.referral_links || []
  );

  useEffect(() => {
    if (!currentUser?.referral_links?.length) {
      setIsLoading(true);
      let urlStr = "https://dathic.com/referred-home";
      const url = new URL(urlStr);
      url.searchParams.append("utm_source", "dathic_growth");
      url.searchParams.append("utm_medium", "referral_link");
      url.searchParams.append("utm_campaign", "referrals");
      generateShortUrl(url.toString(), accessToken, currentUser.id)
        .then((newReferral) => {
          if (newReferral) {
            setReferrals([newReferral]);
            dispatch(
              setCurrentUser((oldUser: any) => ({
                ...oldUser,
                referral_links: [newReferral],
              }))
            );
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [accessToken]);

  return (
    <VStack>
      <Img src={referralBanner} w="100%" maxW={80} />
      <Heading as="h1" fontWeight="bold" fontSize="xl">
        Refer a CPG business to Dathic's Product Locator
      </Heading>
      <Text fontWeight="bold" fontSize="xl">
        <Text as="span" fontSize="2xl" sx={{ color: theme.colors.blue[400] }}>
          Get $100
        </Text>{" "}
        when your friend signs up with Dathic.
      </Text>
      <Text>
        Loving your Dathic's Locator? Your friend's business might want a great
        locator too. Refer a friend to us and you'll both earn $100.
      </Text>
      <Divider />
      <Text fontWeight="bold">
        Use your unique link to refer your friends to Dathic.
      </Text>
      {isLoading && !referrals.length && (
        <Center>
          <Spinner />
        </Center>
      )}
      {!isLoading && !referrals.length && (
        <Text>There was an error loading referral links</Text>
      )}
      {referrals.map((referral) => (
        <HStack>
          <InputGroup>
            <Input value={`https://api-1.dathic.com/r/${referral.short_id}`} />
            <InputRightAddon>
              <IconButton
                icon={<CopyIcon />}
                variant="ghost"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://api-1.dathic.com/r/${referral.short_id}`
                  );
                  toast.success("Referral link copied");
                }}
                aria-label={"copy referral button"}
              />
            </InputRightAddon>
          </InputGroup>
        </HStack>
      ))}
      <Divider />
      <Text>
        <Button
          variant="link"
          sx={{ color: theme.colors.blue[400] }}
          onClick={() => termsDisclosure.onToggle()}
        >
          {termsDisclosure.isOpen ? "Close " : ""}Terms
        </Button>
        {termsDisclosure.isOpen ? "" : " apply"}
      </Text>
      {termsDisclosure.isOpen && (
        <UnorderedList textAlign="start">
          <ListItem>
            After your referral becomes a Dathic client, we’ll send you a $100
            gift card.
          </ListItem>
          <ListItem>
            We’ll offer your friends a month of free service (value $100+) and
            show them how up-to-date locator can help their business grow.
          </ListItem>
          <ListItem fontWeight="bold">
            There is no limit! Refer as many friends as you want, they’ll thank
            you later!
          </ListItem>
        </UnorderedList>
      )}
    </VStack>
  );
};
