import { DateTime } from "luxon";

export let GoogleAuth;
let _callback;

const SCOPE = "https://www.googleapis.com/auth/analytics.readonly";

function initClient() {
  // In practice, your app can retrieve one or more discovery documents.
  var reportingApiUrl =
    "https://analyticsreporting.googleapis.com/$discovery/rest?version=v4";
  var analyticsApiUrl =
    "https://www.googleapis.com/discovery/v1/apis/analytics/v3/rest";

  // Initialize the gapi.client object, which app uses to make API requests.
  // Get API key and client ID from API Console.
  // 'scope' field specifies space-delimited list of access scopes.
  window.gapi.client
    .init({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      discoveryDocs: [reportingApiUrl, analyticsApiUrl],
      scope: SCOPE,
    })
    .then(function () {
      GoogleAuth = window.gapi.auth2.getAuthInstance();

      if (_callback) {
        GoogleAuth.isSignedIn.listen(_callback);

        _callback(GoogleAuth.isSignedIn.get());
      }
    })
    .catch((e) => {
      console.log(e);
    });
}

export function loadGoogle(callback) {
  _callback = callback;
  // Load the API's client and auth2 modules.
  // Call the initClient function after the modules load.
  window.gapi?.load("client:auth2", initClient);
}

export async function authenticateGoogleApis() {
  GoogleAuth.signIn();
}

export function disconnectGoogle() {
  return GoogleAuth.disconnect();
}

export async function getGAViews(store_id, accessToken) {
  return new Promise((resolve, reject) => {
    var request = window.gapi.client.analytics.management.profiles.list({
      accountId: "~all",
      webPropertyId: "~all",
    });
    request.execute((results) => {
      resolve(results?.items);
    });
  });
}

export async function getGAReport(gaViewId, metrics, dateRange) {
  return new Promise((resolve, reject) => {
    var request = window.gapi.client.analyticsreporting.reports.batchGet({
      resource: {
        reportRequests: [
          {
            viewId: gaViewId,
            dateRanges: dateRange
              ? [
                  {
                    startDate: DateTime.fromJSDate(dateRange.start).toFormat(
                      "yyyy-MM-DD"
                    ),
                    endDate: DateTime.fromJSDate(dateRange.end).toFormat(
                      "yyyy-MM-DD"
                    ),
                  },
                ]
              : undefined,
            dimensions: [
              "ga:metro",
              "ga:latitude",
              "ga:longitude",
              "ga:productSku",
              "ga:productName",
            ].map((dim) => ({ name: dim })),
            metrics: ["ga:itemQuantity", "ga:itemRevenue"].map((metric) => ({
              expression: metric,
            })),
          },
        ],
      },
    });
    request.execute((results) => {
      resolve(results?.reports);
    });
  });
}
