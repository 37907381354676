// INITIAL_STATE
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
// @ts-ignore
import KeplerGlSchema from "kepler.gl/schemas";
import audienceReducer, {
  initialState as audienceInit,
} from "../audience/audienceSlice";
import mapSelectionReducer, {
  fetchSales,
  fetchStores,
  initialState as mapSelectionInit,
} from "../map-selection/mapSelectionSlice";
import deliveryReducer, {
  initialState as deliveryInit,
} from "../delivery/deliverySlice";
import {
  deleteAnnotation,
  deleteFavorite,
  deleteView,
  getSharedViews,
  getViews,
  postAnnotation,
  postFavorite,
  postView,
  putAnnotation,
  putView,
} from "../../services/api.service";
import { deepEqual } from "../../utils/compareUtils";
import { deleteGeoLayer, fetchLayerData } from "../layers/layersSlice";
import { RESOURCES } from "../../constants/user-constants";
import { unparse } from "papaparse";
// @ts-ignore
import { wrapTo } from "kepler.gl/actions";
import {
  appendVisData,
  selectSelectedAudiences,
  toggleGeocoder,
} from "../map/keplerReducer";
// @ts-ignore
import { processCsvData } from "kepler.gl/dist/processors";
import { View } from "../../domain/View";
import { AppDispatch, RootState } from "../../app/store";

const _ = require("lodash");

type ViewStatus = "idle" | "loading" | "loaded" | { error: string };

type ThunkApiConfig = { state: RootState; dispatch: AppDispatch };

export type ViewState = {
  activeView?: number;
  outdatedViews: { [viewID: string]: {} };
  views: {
    [viewID: string]: {
      id: number;
      name: string;
      description: string;
      audience: typeof audienceInit;
      mapSelection: typeof mapSelectionInit;
      delivery: typeof deliveryInit;
      config: { closed?: boolean };
      favorites?: any[];
      annotations?: any[];
      type: "shared";
      version?: number;
    };
  };
  viewStatus: { updating: ViewStatus; fetching: ViewStatus }[];
  sideView: { name?: string; props?: { [propName: string]: any } }[];
  viewAccess: ("owner" | "read")[];
  creatingViewStatus: ViewStatus;
  deletingView: boolean;
  updateViewTimeoutId?: string | number | NodeJS.Timeout;
};

const initialState: ViewState = {
  activeView: undefined,
  outdatedViews: {
    view0: {
      audience: undefined,
      mapSelection: undefined,
    },
  },
  views: {},
  viewStatus: [{ updating: "idle", fetching: "idle" }],
  sideView: [{}],
  viewAccess: ["owner"],
  creatingViewStatus: "idle",
  deletingView: false,
  updateViewTimeoutId: undefined,
};

export const updateView = createAsyncThunk<View, void, ThunkApiConfig>(
  "mapViews/updateView",
  async (updatedView, { getState, dispatch }) => {
    const state = getState();
    const accessToken = state.app.accessToken;
    const newView = state.mapViews.views[`view${state.mapViews.activeView}`];
    const selectedAudience = selectSelectedAudiences(state);

    const freshLoadingState = (initState: { [key: string]: any }) =>
      Object.keys(initState)
        .filter((key) => key.toLowerCase().includes("loading"))
        .reduce((acc, key) => ({ ...acc, [key]: initState[key] }), {});

    const { id, name, description, audience, mapSelection, config } = newView;
    const { closed } = config || {};
    const currentMap =
      state.keplerGl[
        `view${
          state?.mapViews?.views?.[`view${state?.mapViews?.activeView}`]?.id
        }`
      ];
    const _config = {
      closed,
      mapViewConfig: {
        audience: {
          ...audience,
          ...freshLoadingState(audienceInit),
          selectedAudience,
        },
        mapSelection: {
          ...mapSelection,
          ...freshLoadingState(mapSelectionInit),
        },
      },
      mapConfig: currentMap
        ? KeplerGlSchema.getConfigToSave(currentMap)
        : undefined,
    };
    const viewToUpdate = {
      id,
      name,
      description,
      config: _config,
    };

    let response;
    if (viewToUpdate?.name && viewToUpdate?.description) {
      response = await putView(viewToUpdate, accessToken);
      if (response && _config) {
        dispatch(
          setUpdateViewTimeoutId(
            setTimeout(() => {
              localStorage.setItem(
                `lastStableConfigView${id}`,
                JSON.stringify(_config)
              );
            }, 5000)
          )
        );
      }
    }
    return response;
  },
  {
    condition: (arg, { getState, extra }) => {
      const state = getState();
      const { outdatedViews, views, activeView, viewStatus, viewAccess } =
        state.mapViews;
      const { currentUser } = state.app;
      const oldView = outdatedViews[`view${activeView}`];
      const updatedView = views[`view${activeView}`];
      const newView = {
        ...oldView,
        ...updatedView,
      };

      const viewChanged =
        Object.keys(newView).length &&
        (oldView === undefined || !deepEqual(oldView, newView));
      return !!(
        activeView !== undefined &&
        currentUser.resources?.includes(RESOURCES.LAYER_EDIT_CONFIG) &&
        currentUser.resources?.includes(RESOURCES.VIEW_UPDATE) &&
        viewChanged &&
        viewStatus[activeView].fetching === "loaded" &&
        viewAccess[activeView] !== "read"
      );
    },
  }
);

export const createView = createAsyncThunk<View, View, ThunkApiConfig>(
  "mapViews/createView",
  async (newView, { getState, dispatch }) => {
    const { accessToken } = getState().app;
    const { views } = getState().mapViews;

    const response = await postView(newView, accessToken);

    if (response) {
      dispatch(
        // @ts-ignore
        setViews({
          ...views,
          [`view${Object.keys(views).length}`]: { ...newView, id: response.id },
        })
      );
      dispatch(
        setViewStatus({
          viewKey: Object.keys(views).length,
          viewStatus: { updating: "idle", fetching: "loaded" },
        })
      );
      dispatch(setSideView([{}]));
      dispatch(
        setViewAccess({
          viewKey: Object.keys(views).length,
          viewAccess: "owner",
        })
      );
      dispatch(changeActiveView(Object.keys(views).length));
    }
    return response;
  }
);

export const crudFavorite = createAsyncThunk<any, any, ThunkApiConfig>(
  "mapViews/crudFavorite",
  async (payload, { getState, dispatch }) => {
    const { accessToken } = getState().app;
    const { views, activeView } = getState().mapViews;
    const view = views[`view${activeView}`];

    let response;
    const newFavorites = [...(view.favorites || [])];
    switch (payload.action) {
      case "create":
        payload.data.view_id = view.id;
        response = await postFavorite(payload.data, accessToken);
        newFavorites.push(response);
        break;
      case "delete":
        response = await deleteFavorite(payload.data.id, accessToken);
        newFavorites.splice(
          newFavorites.findIndex((fav) => fav.id === payload.data.id),
          1
        );
        break;

      default:
        break;
    }
    dispatch(
      setViews({
        ...views,
        [`view${activeView}`]: { ...(view || {}), favorites: newFavorites },
      })
    );
    return response;
  }
);

const loadAnnotationsOnMap = createAsyncThunk<void, any[], ThunkApiConfig>(
  "mapViews/loadAnnotationsOnMap",
  async (payload, { getState, dispatch }) => {
    const { keplerGl } = getState();
    const { views, activeView } = getState().mapViews;
    const layers =
      keplerGl[`view${views?.[`view${activeView}`]?.id}`]?.visState?.layers;

    const annotationLayers = layers?.filter((layer: any) =>
      layer.config.dataId.includes("annotations")
    );
    if (annotationLayers?.length) {
      dispatch(
        // @ts-ignore
        deleteGeoLayer({
          layersToDelete: annotationLayers.map(
            (layer: any) => layer.config.dataId
          ),
        })
      );
    }
    const csvAnnotation = unparse(
      (payload || []).reduce<any[]>((acc, noteData) => {
        const point_lat =
          noteData.data.latitude ||
          noteData.data.lat ||
          noteData.data.centroide_lat;
        const point_lng =
          noteData.data.longitude ||
          noteData.data.lng ||
          noteData.data.centroide_lon;

        const duplicateObjects = payload.filter(
          (note) =>
            note.store_id === noteData.store_id ||
            note.demographic_id === noteData.demographic_id
        );
        const index = acc.findIndex(
          (note: any) =>
            note.store_id === noteData.store_id ||
            note.demographic_id === noteData.demographic_id
        );
        const newNote = {
          point_lat,
          point_lng,
          icon: "note",
          note:
            duplicateObjects.length > 1
              ? `${duplicateObjects.length} notes`
              : noteData.description,
          store_id: noteData.store_id,
          demographic_id: noteData.demographic_id,
          properties: JSON.stringify(
            noteData.store || noteData.demographic || {}
          ),
        };
        return index > -1 ? acc : [...acc, newNote];
      }, [])
    );
    const processedCsv = processCsvData(csvAnnotation);
    const layerConfig = require("../../data/layer_config.json");
    const info = {
      label: "Annotations",
      id: "annotations" + new Date().getTime(),
    };
    dispatch(
      wrapTo(
        `view${views?.[`view${activeView}`]?.id}`,
        // @ts-ignore
        appendVisData({
          // datasets
          datasets: {
            info,
            data: processedCsv,
          },
          // option
          options: {
            keepExistingConfig: true,
            centerMap: false,
          },
          config: layerConfig.annotationsConfig,
        })
      )
    );
  }
);

export const crudAnnotation = createAsyncThunk<any, any, ThunkApiConfig>(
  "mapViews/crudAnnotation",
  async (payload, { getState, dispatch }) => {
    const { accessToken } = getState().app;
    const { views, activeView } = getState().mapViews;
    const view = views[`view${activeView}`];

    let response;
    const newAnnotations = [...(view.annotations || [])];
    switch (payload.action) {
      case "create":
        payload.data.view_id = view.id;
        response = await postAnnotation(payload.data, accessToken);
        newAnnotations.push(response);
        break;
      case "update":
        response = await putAnnotation(payload.data, accessToken);
        newAnnotations[payload.index] = payload.data;
        break;
      case "delete":
        response = await deleteAnnotation(
          newAnnotations[payload.index].id,
          accessToken
        );
        newAnnotations.splice(payload.index, 1);
        break;

      default:
        break;
    }
    dispatch(
      setViews({
        ...views,
        [`view${activeView}`]: { ...(view || {}), annotations: newAnnotations },
      })
    );
    // @ts-ignore
    dispatch(loadAnnotationsOnMap(newAnnotations));
    return response;
  }
);

export const removeView = createAsyncThunk<boolean, void, ThunkApiConfig>(
  "mapViews/deleteView",
  async (viewKey, { dispatch, getState }) => {
    const { accessToken } = getState().app;
    const { views, activeView, viewStatus, viewAccess } = getState().mapViews;

    if (activeView === undefined) {
      return false;
    }

    const view = views[`view${activeView}`];

    await deleteView(view?.id, accessToken);

    dispatch(
      setViews((oldViews) => {
        let newList = { ...oldViews };
        delete newList[`view${activeView}`];
        newList = Object.values(newList).reduce(
          (acc, tempView, index) => ({ ...acc, [`view${index}`]: tempView }),
          {}
        );
        return newList;
      })
    );

    const updatedViewAccess = [...viewAccess];
    updatedViewAccess.splice(activeView, 1);
    dispatch(setViewAccess(updatedViewAccess));

    const updatedViewStatus = [...viewStatus];
    updatedViewStatus.splice(activeView, 1);
    dispatch(setViewStatus(updatedViewStatus));

    dispatch(
      changeActiveView(
        Object.values(views).findIndex((view, i) => !view.config?.closed)
      )
    );

    return true;
  }
);

export const fetchView = createAsyncThunk<void, number, ThunkApiConfig>(
  "mapViews/getViews",
  async (viewKey, { dispatch, getState }) => {
    const { accessToken, currentUser, orgProperties } = getState().app;
    const { views, activeView } = getState().mapViews;

    let getViewsResult: View | undefined;
    let viewAccess = "owner";
    if (views[`view${viewKey}`].type === "shared") {
      const userView = await getSharedViews(
        undefined,
        accessToken,
        views[`view${viewKey}`].id,
        currentUser.id
      );
      if (userView?.[0]) {
        viewAccess = userView[0].access;
        getViewsResult = await getViews(userView[0].view_id, accessToken);
      }
    } else {
      getViewsResult = await getViews(views[`view${viewKey}`].id, accessToken);
    }
    if (!getViewsResult) {
      throw new Error("No view results");
    }

    const mapConfig = getViewsResult.config?.mapConfig;
    const mapViewConfig = getViewsResult.config?.mapViewConfig;
    const fetchedView = { ...getViewsResult };
    delete fetchedView.config;

    dispatch(setViewAccess({ viewKey, viewAccess }));
    dispatch(
      setViews((oldViews) => {
        const newList = { ...oldViews };
        newList[`view${viewKey}`] = {
          ...newList[`view${viewKey}`],
          ...fetchedView,
          ...(mapViewConfig || {}),
        };
        return newList;
      })
    );
    const windowIsSmall = window.innerWidth < 620;
    if (
      mapConfig?.config &&
      !mapConfig?.config?.visState?.interactionConfig?.geocoder?.enabled &&
      !windowIsSmall
    ) {
      if (getViewsResult?.layers?.length) {
        mapConfig.config.visState.interactionConfig.geocoder.enabled = true;
      } else {
        dispatch(wrapTo(`view${activeView}`, toggleGeocoder()));
      }
    }

    let keplerLayers;
    const layerResults: any[] = await Promise.all(
      (getViewsResult?.layers || []).map((layerUri) => {
        const layerId = layerUri.substr(layerUri.lastIndexOf("/") + 1);
        return dispatch(
          // @ts-ignore
          fetchLayerData({
            layerId,
            mapConfig,
            mapViewConfig,
            viewKey: getViewsResult?.id,
          })
        );
      })
    );

    keplerLayers = layerResults.map((res) => ({
      ...res.meta.arg,
      data: res.payload,
    }));
    if (
      !keplerLayers.find((layerRes) =>
        layerRes.data?.info?.id?.includes("fetched_org_stores")
      )
    ) {
      let orgStoresConfig = mapConfig?.config?.visState?.layers?.find(
        (layer: any) => layer.config.dataId.includes("fetched_org_stores")
      );
      let _mapConfig;
      if (orgStoresConfig) {
        _mapConfig = _.cloneDeep(mapConfig);
        _mapConfig.config.visState.layers = [orgStoresConfig];
      }
      // @ts-ignore
      await dispatch(fetchStores({ orgLayer: true, config: _mapConfig }));
    }
    if (orgProperties?.properties?.autoUpdateSalesLayer) {
      let websiteSalesConfig = mapConfig?.config?.visState?.layers?.find(
        (layer: any) => layer.config.dataId.includes("website_sales")
      );
      let _mapConfig;
      if (websiteSalesConfig) {
        _mapConfig = _.cloneDeep(mapConfig);
        _mapConfig.config.visState.layers = [websiteSalesConfig];
      }
      // @ts-ignore
      await dispatch(fetchSales({ config: _mapConfig }));
    }
    // @ts-ignore
    await dispatch(loadAnnotationsOnMap(fetchedView.annotations));
  },
  {
    condition: (viewKey, { getState }) => {
      const { views } = getState().mapViews;
      return !!views[`view${viewKey}`];
    },
  }
);

// REDUCER
const mapViewsSlice = createSlice({
  name: "mapViews",
  initialState,
  reducers: {
    changeActiveView: (state, action) => ({
      ...state,
      activeView: action.payload,
    }),
    changeIsDeletingView: (state, action) => ({
      ...state,
      deletingView: action.payload,
    }),
    setViewStatus: (state, action) => {
      if (typeof action.payload?.viewKey === "number") {
        state.viewStatus[action.payload.viewKey] = action.payload?.viewStatus;
      } else {
        state.viewStatus = action.payload;
      }
    },
    setSideView: (state, action) => {
      if (typeof action.payload?.viewKey === "number") {
        state.sideView[action.payload.viewKey] = action.payload?.sideView;
      } else {
        state.sideView = action.payload;
      }
    },
    setViewAccess: (state, action) => {
      if (typeof action.payload?.viewKey === "number") {
        state.viewAccess[action.payload.viewKey] = action.payload?.viewAccess;
      } else {
        state.viewAccess = action.payload;
      }
    },
    setViews: (
      state,
      action: PayloadAction<
        ViewState["views"] | ((views: ViewState["views"]) => ViewState["views"])
      >
    ) => {
      const views =
        typeof action.payload === "function"
          ? action.payload(state.views) || {}
          : action.payload || {};
      const numNewViews =
        Object.keys(views).length - Object.keys(state.views).length;

      Object.keys(state.views).forEach((viewKey) => {
        state.outdatedViews[viewKey] = _.cloneDeep(state.views[viewKey]);
      });
      if (
        typeof state.activeView === "number" &&
        Object.values(views)[state.activeView]?.config?.closed
      ) {
        const openViews = Object.values(views).filter((v) => !v.config.closed);
        if (openViews.length) state.activeView = openViews.length - 1;
      }
      state.views = views;
      state.viewStatus = [
        ...(state.viewStatus || []),
        ...(numNewViews > 0
          ? Array(numNewViews).fill({
              updating: "idle",
              fetching: "idle",
            })
          : []),
      ];
      state.sideView = [
        ...(state.sideView || []),
        ...(numNewViews > 0 ? Array(numNewViews).fill({}) : []),
      ];
    },
    setUpdateViewTimeoutId: (state, action) => ({
      ...state,
      updateViewTimeoutId: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateView.pending, (state, action) => {
        if (!!state.activeView && state.viewStatus?.[state.activeView]) {
          state.viewStatus[state.activeView].updating = "loading";
        }
      })
      .addCase(updateView.fulfilled, (state, action) => {
        if (!!state.activeView && state.viewStatus?.[state.activeView]) {
          state.viewStatus[state.activeView].updating = "loaded";
        }
      })
      .addCase(updateView.rejected, (state, action) => {
        if (!!state.activeView && state.viewStatus?.[state.activeView]) {
          state.viewStatus[state.activeView].updating = {
            error: "could not update view",
          };
        }
      })
      .addCase(createView.pending, (state, action) => {
        state.creatingViewStatus = "loading";
      })
      .addCase(createView.fulfilled, (state, action) => {
        state.creatingViewStatus = "idle";
      })
      .addCase(createView.rejected, (state, action) => {
        state.creatingViewStatus = {
          error: "could not create view",
        };
      })
      .addCase(removeView.pending, (state, action) => {
        state.deletingView = true;
      })
      .addCase(removeView.fulfilled, (state, action) => {
        state.deletingView = false;
      })
      .addCase(removeView.rejected, (state, action) => {
        state.deletingView = false;
      })
      .addCase(fetchView.pending, (state, action) => {
        const viewKey = action.meta.arg;
        state.viewStatus[viewKey].fetching = "loading";
      })
      .addCase(fetchView.fulfilled, (state, action) => {
        const viewKey = action.meta.arg;
        state.viewStatus[viewKey].fetching = "loaded";
      })
      .addCase(fetchView.rejected, (state, action) => {
        const viewKey = action.meta.arg;
        state.viewStatus[viewKey].fetching = {
          error: "could fetch view",
        };
      })
      .addMatcher(
        (action) => {
          return (
            [
              "CONFIG_CHANGE",
              "FILTER",
              "LAYER",
              "GeoLayer/fulfilled",
              "APPEND_VIS_DATA",
            ].filter(
              (reducerType) =>
                action.type.includes(reducerType) &&
                !action.type.includes("LAYER_HOVER") &&
                !action.type.includes("LAYER_CLICK")
            ).length > 0
          );
        },
        (state, action) => {
          const viewkey = Object.keys(state.views).find((key) =>
            [
              +action.payload?.view_id,
              +action.payload?.meta?._id_?.match(/\d+/)?.[0],
            ].includes(state.views[key].id)
          );
          if (viewkey) {
            state.views[viewkey].version =
              (state.views[viewkey].version || 0) + 1;
          }
        }
      )
      .addMatcher(
        (action) =>
          ["audience", "mapSelection", "delivery"].filter((reducerType) =>
            action.type.startsWith(reducerType)
          ).length > 0,
        (state, action) => {
          state.outdatedViews[`view${state.activeView}`] = _.cloneDeep(
            state.views[`view${state.activeView}`]
          );
          state.views[`view${state.activeView}`] = {
            ...state.views[`view${state.activeView}`],
            audience: audienceReducer(
              state.views[`view${state.activeView}`]?.audience || audienceInit,
              action
            ),
            mapSelection: mapSelectionReducer(
              state.views[`view${state.activeView}`]?.mapSelection ||
                mapSelectionInit,
              action
            ),
            delivery: deliveryReducer(
              state.views[`view${state.activeView}`]?.delivery || deliveryInit,
              action
            ),
          };
        }
      );
  },
});

export const selectCurrentView = (state: RootState) =>
  state.mapViews.views[`view${state.mapViews.activeView}`];
export const selectViews = (state: RootState) => state.mapViews?.views;
export const selectUpdateViewTimeout = (state: RootState) =>
  state?.mapViews?.updateViewTimeoutId;
export const selectFavorites = (state: RootState) =>
  state.mapViews.views[`view${state.mapViews.activeView}`]?.favorites;

export const {
  changeActiveView,
  setViews,
  setViewStatus,
  setSideView,
  setViewAccess,
  changeIsDeletingView,
  setUpdateViewTimeoutId,
} = mapViewsSlice.actions;

export default mapViewsSlice.reducer;
