import { Button } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import { Box, Text } from "@chakra-ui/layout";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import { Img } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addOpenTour,
  selectOrgProperties,
  selectUserResources,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import menuIconSettings from "../../assets/images/menu-icon-settings.png";
import menuIconStoreLocator from "../../assets/images/menu-icon-storelocator.png";
import { RESOURCES } from "../../constants/user-constants";
import { trackClick } from "../../services/tracking.service";

function StoreLocatorBtn() {
  const noStoreLocatorAlertDisclosure = useDisclosure();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userResources = useAppSelector(selectUserResources);
  const orgProperties = useAppSelector(selectOrgProperties);

  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";

  return !location.pathname?.includes("storelocator") ? (
    <Box>
      <AlertDialog
        onClose={noStoreLocatorAlertDisclosure.onClose}
        isOpen={noStoreLocatorAlertDisclosure.isOpen}
        isCentered
        closeOnOverlayClick
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            Looks like you haven't set up Dathic {storeLabel} Locator
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Text>Do you want to setup a {storeLabel} locator?</Text>
            <Text>
              For information on the product{" "}
              <Button
                variant="link"
                onClick={() =>
                  window.open("https://www.dathic.com/product-locator-plus")
                }
              >
                click here
              </Button>
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              variant="solid"
              onClick={() => {
                noStoreLocatorAlertDisclosure.onClose();
              }}
            >
              Maybe later
            </Button>
            <Button
              ml={3}
              variant="solid"
              colorScheme="blue"
              onClick={() => {
                noStoreLocatorAlertDisclosure.onClose();
                window.location.href = "/storelocator";
                dispatch(addOpenTour("storelocatorTour"));
              }}
            >
              Set up
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Button
        id="store-locator-btn"
        className="store-locator-btn"
        variant="link"
        as="div"
        padding="12px 10px"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        isDisabled={!userResources?.includes(RESOURCES.STORE_LOCATOR_BTN)}
        leftIcon={
          <Img
            src={menuIconStoreLocator}
            height="1rem"
            width="1rem"
            id="store-locator-btn"
            className="store-locator-btn"
            filter={
              location.pathname.includes("storelocator")
                ? "invert(48%) sepia(45%) saturate(4075%) hue-rotate(177deg) brightness(97%) contrast(102%)"
                : undefined
            }
          />
        }
        iconSpacing={0}
        size="xs"
        _hover={{ textDecoration: "underline" }}
        onClick={(e) =>
          trackClick("store-locator-btn", "Settings", () => {
            if (!userResources?.includes(RESOURCES.STORE_LOCATOR_BTN)) {
              e.preventDefault();
              toast.warn("You don't have access to this");
              return;
            }
            if (!orgProperties?.store_locator) {
              e.preventDefault();
              noStoreLocatorAlertDisclosure.onOpen();
            }
            window.location.href = "/storelocator";
          })
        }
      >
        {storeLabel} Locator
      </Button>
    </Box>
  ) : (
    <Button
      id="store-locator-btn"
      className="store-locator-btn"
      variant="link"
      as="div"
      color="blue.900"
      padding="12px 10px"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      isDisabled={!userResources?.includes(RESOURCES.STORE_LOCATOR_BTN)}
      leftIcon={
        <Img
          src={menuIconSettings}
          height="1rem"
          width="1rem"
          id="store-locator-btn"
          className="store-locator-btn"
        />
      }
      iconSpacing={0}
      size="xs"
      onClick={() => navigate("/storelocator/config")}
    >
      Settings
    </Button>
  );
}
export default StoreLocatorBtn;
