import {
  Button,
  Divider,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  Spacer,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/hooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { primaryLight } from "../../theme/colors";
import { postBillingPortalSession } from "../../services/stripe.service";
import { selectDatasets } from "../map/keplerReducer";
import { RESOURCES } from "../../constants/user-constants";
import { useAppSelector } from "../../app/store";
import {
  selectAccessToken,
  selectIsShopifyApp,
  selectOrgProperties,
  selectShopifyDomain,
  selectStatusMessages,
  selectUserResources,
  StatusMessage,
  StatusMessageObject,
} from "../../app/appSlice";
import {
  selectFailedLayers,
  selectLoadedLayers,
  selectLoadingLayers,
} from "../layers/layersSlice";
import { selectLoadingStores } from "../map-selection/mapSelectionSlice";
import { PopoverTrigger } from "../org-screen/OrgOverview";
import { useLocation } from "react-router-dom";

var pluralize = require("pluralize");

export function StatusBarObjectsList({
  objects = [],
  loading = false,
}: {
  objects?: StatusMessageObject[];
  loading?: boolean;
}) {
  return (
    <VStack
      mt={5}
      spacing={3}
      maxHeight={60}
      overflowY="auto"
      overflowX="visible"
      divider={<Divider />}
    >
      {objects.map((object, i) => (
        <VStack key={i} w="100%" alignItems="flex-start">
          {object.title && <Text>{object.title}</Text>}
          {object.message && <Text fontSize={12}>{object.message}</Text>}
          <HStack alignSelf="flex-end" p={3}>
            {object.actions?.map(({ action, label, ...other }) => (
              <Button onClick={action} {...other}>
                {label}
              </Button>
            ))}
            {loading && <Spinner size="xs" />}
          </HStack>
        </VStack>
      ))}
    </VStack>
  );
}

function StatusSection({
  bg = "#B6D2F0",
  title,
  objects,
  loading = false,
}: StatusMessage) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <HStack
          paddingX={2}
          paddingY={1}
          bg={bg}
          onClick={objects?.length ? onOpen : undefined}
          fontSize={12}
          cursor={objects?.length ? "pointer" : undefined}
        >
          <Text>{title}</Text>
          {loading && <Spinner size="xs" />}
        </HStack>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <StatusBarObjectsList objects={objects} loading={loading} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

function AppStatusBar({ width = "100%" }) {
  const location = useLocation();
  const orgProperties = useAppSelector(selectOrgProperties);
  const datasets = useAppSelector(selectDatasets);
  const { store_locator, dathic_analysis } = orgProperties || {};
  const _datasets = useMemo(() => datasets, [datasets]);
  const [rankedStores, setRankedStores] = useState<any[]>();
  const [rankedZipcodes, setRankedZipcodes] = useState<any[]>();
  const zipcodeLabel =
    orgProperties?.properties?.zipcodeNameReplacement || "Zipcode";
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";

  const hasStripeBilling = Object.values(orgProperties?.properties || {}).find(
    (p: any) => !!p?.customer_id
  );
  const isShopifyApp = useAppSelector(selectIsShopifyApp);
  const shopifyDomain = useAppSelector(selectShopifyDomain);
  const accessToken = useAppSelector(selectAccessToken);
  const userResources = useAppSelector(selectUserResources);
  const loadingLayers = useAppSelector(selectLoadingLayers);
  const loadedLayers = useAppSelector(selectLoadedLayers);
  const failedLayers = useAppSelector(selectFailedLayers);
  const loadingStores = useAppSelector(selectLoadingStores);
  const statusMessages = useAppSelector(selectStatusMessages);

  const getScores = useCallback(
    (layerPredicate: (key: string) => boolean) =>
      Object.keys(_datasets || {})
        .filter(layerPredicate)
        .flatMap((key) => {
          const scoreFieldIndex = _datasets[key]?.fields?.findIndex(
            (field: any) => field.id === "score"
          );
          return _datasets[key]?.allData?.map(
            (item: any) => item[scoreFieldIndex]
          );
        })
        .filter((prop) => !!prop),
    [_datasets]
  );

  useEffect(() => {
    const scores = getScores(
      (key) =>
        key.includes("fetched_stores") || key.includes("fetched_org_stores")
    );
    if (scores) {
      setRankedStores(scores);
    }
  }, [getScores]);

  useEffect(() => {
    const scores = getScores((key) => key.includes("selected_audience"));
    if (scores) {
      setRankedZipcodes(scores);
    }
  }, [getScores]);

  const handleBillingPortal: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    postBillingPortalSession(accessToken).then((portalUrl) => {
      if (portalUrl) {
        window.open(portalUrl);
      }
    });
  };
  const paymentObjects: StatusMessageObject[] = [
    {
      message: "You can manage your billing or ask for help",
      actions: [
        {
          label: "Ask for help",
          action: () => window.open("mailto:info@dathic.com"),
          variant: "link",
        },
        ...(hasStripeBilling
          ? [
              {
                label: "Go to Stripe billing",
                action: handleBillingPortal,
                colorScheme: "blue",
                isDisabled: userResources.includes(RESOURCES.MANAGE_BILLING),
              },
            ]
          : []),
        ...(isShopifyApp
          ? [
              {
                label: "Go to Shopify billing",
                action: () =>
                  window.open(
                    `${window.location.protocol}//${shopifyDomain}/admin/settings/billing`
                  ),
                colorScheme: "blue",
                isDisabled: userResources.includes(RESOURCES.MANAGE_BILLING),
              },
            ]
          : []),
      ],
    },
  ];
  return !location.pathname.includes("storelocator") ? (
    <HStack
      bg={primaryLight}
      w={width}
      divider={<Divider orientation="vertical" />}
      zIndex={1300}
    >
      {statusMessages.map((statusMessage) => (
        <StatusSection {...statusMessage} />
      ))}
      {store_locator &&
        ["failedpayment", "pendingpayment"].includes(store_locator?.status) && (
          <StatusSection
            id={"store_locator"}
            bg={store_locator.status === "failedpayment" ? "tomato" : undefined}
            title={`${storeLabel} Locator payment ${
              store_locator.status === "failedpayment"
                ? "failed"
                : "is processing"
            }`}
            objects={paymentObjects}
          />
        )}
      {dathic_analysis &&
        ["failedpayment", "pendingpayment"].includes(
          dathic_analysis?.status
        ) && (
          <StatusSection
            id={"dathic_analysis"}
            bg={
              dathic_analysis.status === "failedpayment" ? "tomato" : undefined
            }
            title={`Dathic Analysis payment ${
              dathic_analysis.status === "failedpayment"
                ? "failed"
                : "is processing"
            }`}
            objects={paymentObjects}
          />
        )}
      <Spacer />
      {!!rankedZipcodes?.length && (
        <StatusSection
          id={"rankedZipcodes"}
          title={`${pluralize(
            zipcodeLabel,
            rankedZipcodes?.length,
            true
          )} ranked`}
        />
      )}
      {!!rankedStores?.length && (
        <StatusSection
          id={"rankedStores"}
          title={`${pluralize(storeLabel, rankedStores?.length, true)} ranked`}
        />
      )}
      {!!loadingStores && (
        <StatusSection
          id={"loadingStores"}
          title={`Loading ${pluralize(storeLabel)}`}
          loading
        />
      )}
      {!!failedLayers?.length && (
        <StatusSection
          id={"failedLayers"}
          bg="red.400"
          title={`${pluralize(
            "layer",
            failedLayers?.length,
            true
          )} failed to load`}
          objects={failedLayers.map((layerId: any) => ({
            message: `Layer ${layerId.id || layerId} failed to load`,
          }))}
        />
      )}
      {!!loadedLayers?.length && (
        <StatusSection
          id={"loadedLayers"}
          title={`${pluralize("layer", loadedLayers?.length, true)} loaded`}
          objects={loadedLayers.map((layerId: any) => ({
            message: `Layer ${layerId} loaded`,
          }))}
        />
      )}
      {!!loadingLayers?.length && (
        <StatusSection
          id={"loadingLayers"}
          title={`${pluralize("layer", loadingLayers?.length, true)} loading`}
          objects={loadingLayers.map((layerId: any) => ({
            message: `Layer ${layerId} loading`,
          }))}
          loading
        />
      )}
    </HStack>
  ) : (
    <></>
  );
}

export default AppStatusBar;
