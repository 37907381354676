import React, { useState } from "react";
import {
  Box,
  Flex,
  Slider,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { primary, secondaryDark } from "../../../theme/colors";

export default function _Slider({
  thumbToolTipLabel,
  width,
  label,
  CustomToolTip,
  sliderValue,
  min,
  max,
  leftSliderMark,
  rightSliderMark,
  bg,
  leftSliderMarkLabel,
  rightSliderMarkLabel,
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <Box bg={bg} mt="2" p={1} w={width}>
      <Flex direction="column" alignItems="center">
        <Flex align="center" justifyContent="center" w="100%">
          <Text w="full" m={2} textAlign="center" fontWeight="medium">
            {label}
          </Text>
          {CustomToolTip}
        </Flex>
        <Slider
          mb={5}
          w={width}
          isReadOnly
          min={min}
          max={max}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          color="white"
          aria-label="slider-ex-1"
          value={sliderValue}
        >
          <SliderMark
            color="black"
            mt="2"
            ml="-2"
            fontSize="sm"
            fontWeight="medium"
            value={leftSliderMark}
          >
            {leftSliderMarkLabel}
          </SliderMark>
          <SliderMark
            color="black"
            mt="2"
            ml="-8"
            fontSize="sm"
            fontWeight="medium"
            value={rightSliderMark}
          >
            {rightSliderMarkLabel}
          </SliderMark>
          <SliderTrack
            boxSize={3}
            borderRadius={6}
            bgGradient={`linear(to-r, ${secondaryDark}, ${primary})`}
          ></SliderTrack>
          <Tooltip
            hasArrow
            bg="white"
            color="black"
            placement="top"
            isOpen={showTooltip}
            label={thumbToolTipLabel}
          >
            <SliderThumb boxSize={5} bg="blue.900" />
          </Tooltip>
        </Slider>
      </Flex>
    </Box>
  );
}
