import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import pluralize from "pluralize";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Form from "../../components/Form";
import { RESOURCES } from "../../constants/user-constants";
import { getInsights } from "../../services/insights.service";
import { setIsSelectingOnMap } from "../map-selection/mapSelectionSlice";
import { selectLoadedZipcodes } from "../map/keplerReducer";
import { fetchIndieInsights } from "./insightSlice";
import InsightsSelector from "./InsightsSelector";

function InsightSearch({
  loadedDemographics,
  orgConfig,
  mapSelection,
  setIsSelecting,
  fetchInsights,
  isLoadingInsights,
  userResources,
  accessToken,
  onClose,
}) {
  const zipcodeLabel = orgConfig?.zipcodeNameReplacement || "Zipcode";
  const [formValues, setFormValues] = useState();
  const [shouldCreateLayer, setShouldCreateLayer] = useState(false);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [useMapSelection, setUseMapSelection] = useState(false);
  const [insightOptions, setInsightOptions] = useState();

  const colorByOptions = insightOptions
    ?.find(
      (variable) =>
        Object.keys(formValues?.insights || {}).filter(
          (key) => !!formValues.insights[key]
        )?.[0] === `${variable.id}`
    )
    ?.color_by_properties?.map((variable) => ({
      value: Object.keys(variable)?.[0],
      label: Object.values(variable)?.[0],
    }));

  const insightFilterSchema = () => [
    {
      renderFormControl: (values, onChange) => (
        <GridItem colSpan={2}>
          <FormControl>
            <FormLabel>Select insights</FormLabel>
            <InsightsSelector
              insightOptions={insightOptions}
              value={values?.insights}
              onChange={(value) => onChange("insights", value)}
            />
          </FormControl>
        </GridItem>
      ),
    },
    {
      key: "colorBy",
      label: "Fill color by",
      type: "string",
      placeholder: "Select a property",
      options: colorByOptions?.map((option) => option.label),
      disabled:
        Object.keys(formValues?.insights || {}).filter(
          (k) => !!formValues?.insights?.[k]
        ).length === 0,
      helperText: `The selected property will be used to fill the color of the insight`,
    },
    {
      key: "colorBySecondary",
      label: "Border color by",
      type: "string",
      placeholder: "Select a property",
      options: colorByOptions?.map((option) => option.label),
      disabled:
        Object.keys(formValues?.insights || {}).filter(
          (k) => !!formValues?.insights?.[k]
        ).length === 0,
      helperText: `The selected property will be used to color the border of the insight`,
    },
    {
      key: "zipcode",
      label: pluralize(zipcodeLabel, 1),
      type: "list",
      options: loadedDemographics?.map((demo) => demo.zipcode) || [],
      gridItemProps: { colStart: 1, colSpan: 2 },
      helperText: `Only insights in these ${pluralize(
        zipcodeLabel,
        2
      )} will be included`,
    },
    {
      renderFormControl: () => (
        <GridItem colSpan={2}>
          <Accordion w="100%" allowToggle defaultIndex={0}>
            <AccordionItem>
              <AccordionButton fontSize={18} fontWeight="bold">
                Advanced options
                <AccordionIcon ml="auto" />
              </AccordionButton>
              <AccordionPanel>
                <VStack spacing={5}>
                  <Flex justify="space-between" alignItems="center" w="100%">
                    <Box>
                      <Switch
                        isChecked={!!useMapSelection}
                        onChange={() =>
                          setUseMapSelection((oldValue) => !oldValue)
                        }
                      >
                        Use {pluralize(zipcodeLabel, 1)} map selection
                      </Switch>
                      <Text fontSize={12} color="darkgray">
                        {useMapSelection
                          ? `Insights will be searched in the ${pluralize(
                              zipcodeLabel,
                              2
                            )} you selected on the map`
                          : `Insights will be searched in all of the ${pluralize(
                              zipcodeLabel,
                              2
                            )} you have on the map`}
                      </Text>
                    </Box>
                    {!mapSelection?.zipcodes?.length && useMapSelection && (
                      <Button onClick={() => setIsSelecting()}>
                        Select on map
                      </Button>
                    )}
                  </Flex>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </GridItem>
      ),
    },
  ];

  const onSubmit = async () => {
    setShouldSubmit(false);
    const _formValues = { ...(formValues || {}) };
    _formValues.zipcode = _formValues.zipcode?.length
      ? _formValues.zipcode
      : useMapSelection
      ? mapSelection.zipcodes.map((item) => item.zipcode)
      : loadedDemographics?.map((demo) => demo.zipcode);
    const insightSpecId = +Object.keys(_formValues.insights || {})?.[0];
    await fetchInsights({
      ..._formValues,
      layerName: insightOptions.find(
        (variable) => variable.id === insightSpecId
      )?.name,
      insightSpecId,
      colorBy: colorByOptions?.find((op) => op.label === _formValues.colorBy)
        ?.value,
      colorBySecondary: colorByOptions?.find(
        (op) => op.label === _formValues.colorBySecondary
      )?.value,
      shouldCreateLayer,
    });
    if (onClose) onClose();
  };

  useEffect(() => {
    getInsights(accessToken, { level: "independent" }, "spec").then(
      setInsightOptions
    );
  }, [accessToken]);

  return (
    <Box>
      <Form
        schema={insightFilterSchema()}
        values={formValues}
        onFormValuesChange={setFormValues}
        submitFromOutside={{ shouldSubmit }}
        onSubmit={onSubmit}
      />
      <Flex
        direction="row"
        justifyContent="space-between"
        position="absolute"
        right={0}
        bottom={0}
        bg="white"
        p={5}
        w="100%"
        borderTop="1px solid gainsboro"
        zIndex={9}
      >
        <Box>
          <Switch
            isChecked={!!shouldCreateLayer}
            onChange={() => setShouldCreateLayer((oldValue) => !oldValue)}
          >
            Create new layer
          </Switch>
          <Text fontSize={12} color="darkgray">
            {shouldCreateLayer
              ? "Your current insight layers will stay the same"
              : "Your current insight layers will be replaced with this search"}
          </Text>
        </Box>
        <Flex direction="row">
          <Button
            isLoading={isLoadingInsights}
            colorScheme="blue"
            disabled={
              Object.keys(formValues?.insights || {}).filter(
                (k) => !!formValues?.insights?.[k]
              )?.length === 0 ||
              isLoadingInsights ||
              !userResources?.includes(RESOURCES.INDIE_INSIGHTS_GET)
            }
            ml={3}
            onClick={() => {
              setShouldSubmit(true);
            }}
          >
            Show results
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}

export default connect(
  (state) => ({
    isLoadingInsights:
      !!state.mapViews.views[`view${state.mapViews.activeView}`]?.insights
        ?.loading?.indieInsights,
    userResources: state.app.currentUser?.resources,
    orgConfig: state.app.orgProperties?.properties,
    mapSelection:
      state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
        ?.selection,
    loadedDemographics: selectLoadedZipcodes(state),
    accessToken: state.app.accessToken,
  }),
  (dispatch) => ({
    setIsSelecting: () => dispatch(setIsSelectingOnMap(true)),
    fetchInsights: (filter) => dispatch(fetchIndieInsights(filter)),
  })
)(InsightSearch);
