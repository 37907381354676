import React, { useEffect, useState } from "react";
import {
  Text,
  Flex,
  Button,
  Box,
  Center,
  Heading,
  SkeletonText,
  SkeletonCircle,
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import StoreInfo from "./StoreInfo";
import { connect } from "react-redux";
var pluralize = require("pluralize");

function ZipStoreCard({ clickedObject, zipStores, isLoading, orgConfig }) {
  const [sorted, setSorted] = useState([]);
  const storeLabel = orgConfig?.storeNameReplacement || "Store";

  useEffect(() => {
    const chain = zipStores.reduce((prev, curr) => {
      if (prev[curr.chain]) {
        prev[curr.chain]++;
      } else {
        prev[curr.chain] = 1;
      }
      return prev;
    }, {});

    setSorted(
      Object.entries(chain)
        .filter(([key, value]) => key !== "null")
        .sort((a, b) => b[1] - a[1])
        .slice(0, 3)
    );
  }, [zipStores]);

  const getStar = (rating) => {
    let star = [];
    const roundedRating = Math.floor(rating);
    for (let i = 0; i < 5; i++) {
      star.push(
        <StarIcon
          key={i}
          color={i < roundedRating ? "yellow.300" : "gray.500"}
        />
      );
    }
    return star;
  };
  return (
    <Flex direction="column" alignItems="center" w="100%">
      <Flex m={"3"} justify="center">
        <Button variant={"outline"} colorScheme={"blue"} w={300} isDisabled>
          <Text align="center">View Reports</Text>
        </Button>
      </Flex>
      <Box h={"45"} w={"80%"} bg={"gray.100"}>
        <Center h={"100%"}>
          <Heading fontSize={"2xl"}>Selected {storeLabel}</Heading>
        </Center>
      </Box>
      <Flex
        px="5"
        my="3"
        h="100%"
        direction={"column"}
        justifyContent="space-around"
        w="100%"
      >
        <Flex alignItems={"center"} justifyContent={"space-around"} w={"100%"}>
          <StoreInfo
            parameter={clickedObject.name?.replace(/['"]+/g, "")}
            label={"Name"}
          />
          <Button px={"6"} colorScheme={"blue"} variant={"outline"} isDisabled>
            Want to sell here?
          </Button>
        </Flex>
        <StoreInfo parameter={clickedObject.chain} label={"Chain"} />
        <StoreInfo parameter={clickedObject.phone} label={"Phone"} />
        <StoreInfo
          parameter={clickedObject.address.replace(/['"]+/g, "")}
          label={"Address"}
        />
        <StoreInfo parameter={clickedObject.city} label={"City"} />
        <StoreInfo parameter={clickedObject.state} label={"State"} />
        <StoreInfo parameter={clickedObject.county} label={"County"} />
        <Flex alignItems={"center"} w={"100%"}>
          <Text fontSize={"large"} fontWeight={"bold"}>
            Rating:
          </Text>
          <span style={{ width: "5px" }} />
          <Flex alignItems={"center"} fontSize={"large"}>
            {clickedObject.getAvgRating()
              ? getStar(clickedObject.getAvgRating())
              : "No rating found"}
          </Flex>
        </Flex>
        <StoreInfo
          parameter={clickedObject.getReviewCount()}
          label={"Review Count"}
        />
      </Flex>
      <Box w={"80%"} bg={"gray.100"}>
        <Center h={"100%"}>
          <Heading fontSize={"2xl"}>Top Chains</Heading>
        </Center>
        {isLoading ? (
          <>
            <Box mx="5" my="2">
              <Flex
                direction={"row"}
                m="2"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text>Chain</Text>
                <Text>Locations</Text>
                <Box></Box>
              </Flex>
            </Box>
            {[...Array(3)].map((_, index) => (
              <>
                <Box
                  bg="white"
                  border="1px"
                  borderColor="blackAlpha.400"
                  mx="5"
                  my="2"
                  key={index}
                  shadow="md"
                >
                  <Flex
                    direction={"row"}
                    m="2"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SkeletonText noOfLines={1} w={"25%"} />
                    <SkeletonText noOfLines={1} w={"10%"} />
                    <SkeletonCircle borderRadius={5} w={75} />
                  </Flex>
                </Box>
              </>
            ))}
          </>
        ) : sorted.length ? (
          <>
            <Box mx="5" my="2">
              <Flex
                direction={"row"}
                m="2"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text>Chain</Text>
                <Text>Locations</Text>
                <Box></Box>
              </Flex>
            </Box>
            {sorted.map(([key, value], i) => (
              <Box
                bg="white"
                border="1px"
                borderColor="blackAlpha.400"
                mx="5"
                my="2"
                key={i}
                shadow="md"
              >
                <Flex
                  direction={"row"}
                  m="2"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text textAlign={"left"} w={100}>
                    {i + 1}. {key}
                  </Text>
                  <Text>{value}</Text>
                  <Button colorScheme={"blue"} variant={"outline"} isDisabled>
                    View {pluralize(storeLabel)}
                  </Button>
                </Flex>
              </Box>
            ))}
          </>
        ) : (
          <Text textAlign="center" fontSize="xl">
            No chains found
          </Text>
        )}
      </Box>
    </Flex>
  );
}

export default connect((state) => ({
  orgConfig: state.app.orgProperties?.properties,
}))(ZipStoreCard);
