import React, { FunctionComponent } from "react";
import { Button, Flex, Image, Img } from "@chakra-ui/react";
import errorImage from "../assets/images/404-image.png";
import ringTopRight from "../assets/images/ring_topright.png";
import ringBottomLeft from "../assets/images/ring_bottomleft.png";
import { useNavigate } from "react-router";

interface OwnProps {}

type Props = OwnProps;

const ErrorPage: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();
  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      background="white"
      height="100%"
      width="100%"
      pos="absolute"
    >
      <Flex flexDir="column" zIndex={2}>
        <Image src={errorImage} alt="dathic" />
        <Button
          variant="solid"
          onClick={() => {
            navigate("/");
          }}
        >
          Go back home
        </Button>
      </Flex>
      <Img src={ringTopRight} top={0} right={0} pos="absolute" opacity={0.5} />
      <Img
        src={ringBottomLeft}
        bottom={0}
        left={0}
        pos="absolute"
        opacity={0.5}
      />
    </Flex>
  );
};

export default ErrorPage;
