import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  HStack,
  Text,
  Flex,
  CloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import DemographicsTab from "./Tabs/DemographicsTab";
import StoresTab from "./Tabs/StoresTab";
import SocialTab from "./Tabs/SocialTab";
import {
  getStores,
  getTwitterIndicators,
} from "../../../../services/api.service";
import { connect } from "react-redux";
import { setComparisonSelection } from "../../../map-selection/mapSelectionSlice";
import { toast } from "react-toastify";
import InsightsTab from "./Tabs/InsightsTab";
var pluralize = require("pluralize");

function ComparisonCard({
  selectedItems,
  accessToken,
  setSelectedItems,
  orgProperties,
}) {
  const [firstZipStores, setFirstZipStores] = useState([]);
  const [secondZipStores, setSecondZipStores] = useState([]);
  const [twitterIndicators, setTwitterIndicators] = useState([]);
  const [loading, setLoading] = useState(true);

  const zipSize = useMemo(() => orgProperties?.zipSize || 6, [orgProperties]);
  const storeLabel = orgProperties?.storeNameReplacement || "Store";

  const states = useMemo(
    () => [
      ...new Set(
        [
          ...(selectedItems.demographic || []),
          ...(selectedItems.store || []),
        ]?.map((d) => d.state)
      ),
    ],
    [selectedItems]
  );
  const zipcodes = useMemo(
    () => [
      ...new Set(
        [
          ...(selectedItems.demographic || []),
          ...(selectedItems.store || []),
        ].map((d) => d.zipcode.substring(0, zipSize))
      ),
    ],
    [selectedItems, zipSize]
  );

  const handleClose = useCallback(() => {
    setSelectedItems((oldValue) => ({
      ...(oldValue || {}),
      demographic: [],
      store: [],
    }));
  }, [setSelectedItems]);

  useEffect(() => {
    const fetchStores = async () => {
      setLoading(true);
      const stores = await getStores(
        states,
        [],
        zipcodes,
        [],
        [],
        undefined,
        false,
        accessToken,
        orgProperties?.country
      );

      const res = await getTwitterIndicators(
        undefined,
        zipcodes,
        [],
        accessToken
      );
      const newIndicator = res.map((zipcode) => {
        return {
          ...(zipcode || {}),
          ...[
            "most_active_users",
            "most_influential_users",
            "most_liked_users",
            "hashtags",
            "platforms_distribution",
            "hispanic_platforms_distribution",
          ].reduce(
            (acc, key) => ({
              ...acc,
              [key]: JSON.parse(zipcode?.[key]?.replaceAll("'", '"') || "{}")
                .values?.sort((a, b) => b.y - a.y)
                .splice(0, 10)
                .sort((a, b) => a.y - b.y)
                .map((val) => ({ x: val.y, y: val.x })),
            }),
            {}
          ),
        };
      });
      setTwitterIndicators(newIndicator);

      setFirstZipStores(
        stores.filter((store) => store.zipcode === zipcodes[0])
      );
      setSecondZipStores(
        stores.filter((store) => store.zipcode === zipcodes[1])
      );
      setLoading(false);
    };
    if (zipcodes?.length > 1) {
      fetchStores();
    } else {
      handleClose();
      toast.warn("You must select items with different zipcodes to compare.");
    }
  }, [accessToken, handleClose, orgProperties, states, zipcodes]);

  return (
    <Flex
      flexDir="column"
      background="white"
      rounded="md"
      h={["40%", null, "100%"]}
      pos="relative"
      overflowY="auto"
    >
      <HStack p={2} bg="gray.100" pos="sticky" top={0} zIndex={2}>
        <CloseButton
          pos="absolute"
          top={0}
          right={0}
          size="md"
          onClick={handleClose}
        />
        <Text fontSize="3xl" fontWeight="bold" align="center" flex={1}>
          {zipcodes[0]}
        </Text>
        <Text fontSize="3xl" fontWeight="bold" align="center" flex={1}>
          vs.
        </Text>
        <Text fontSize="3xl" fontWeight="bold" align="center" flex={1}>
          {zipcodes[1]}
        </Text>
      </HStack>
      {zipcodes.length > 1 && (
        <Tabs isFitted isLazy>
          <TabList pos="sticky" top="61px" zIndex={2} bg="white">
            <Tab>Demographics</Tab>
            <Tab>{pluralize(storeLabel)}</Tab>
            <Tab>Social</Tab>
            <Tab>Insights</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <DemographicsTab />
            </TabPanel>
            <TabPanel>
              <StoresTab
                firstZipStores={firstZipStores}
                secondZipStores={secondZipStores}
              />
            </TabPanel>
            <TabPanel>
              <SocialTab
                accessToken={accessToken}
                loading={loading}
                twitterIndicators={twitterIndicators}
              />
            </TabPanel>
            <TabPanel>
              <InsightsTab />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </Flex>
  );
}

export default connect(
  (state) => ({
    selectedItems:
      state.mapViews.views[`view${state.mapViews.activeView}`]?.mapSelection
        ?.comparisonSelection,
    orgProperties: state.app.orgProperties?.properties,
  }),
  (dispatch) => ({
    setSelectedItems: (newSelection) =>
      dispatch(setComparisonSelection(newSelection)),
  })
)(ComparisonCard);
