import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Colors,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  Text,
  useDisclosure,
  UseDisclosureProps,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import EngageBtn from "../components/EngageBtn";
import GrowthBtn from "../components/GrowthBtn";
import HomeBtn from "../components/HomeBtn";
import LocationBtn from "../components/LocationBtn";
import { LogoComponent } from "../components/logo-component";
import MapsBtn from "../components/MapsBtn";
import ProductsBtn from "../components/ProductsBtn";
import ReportsBtn from "../components/ReportsBtn";
import ShopperBtn from "../components/ShopperBtn";
import StoresBtn from "../components/StoresBtn";
import { SalesBtn } from "../components/uploadSalesBtn";
import UserMenu from "../components/UserMenu";
import Demo from "../features/demo/demo";
import StoreLocatorBtn from "../features/store-locator/StoreLocatorBtn";
import { trackClick } from "../services/tracking.service";
import dathic_chakra_theme from "../theme/dathic_chakra_theme";
import { selectCurrentUser, selectOrgProperties } from "./appSlice";
import { useAppSelector } from "./store";

function VerticalMenu() {
  const currentUser = useAppSelector(selectCurrentUser);
  const orgProperties = useAppSelector(selectOrgProperties);
  const [profilePhotoHash, setProfilePhotoHash] = useState(Date.now());
  const userName = currentUser?.displayName || currentUser?.email;
  const userIsInDemo = currentUser?.is_demo;
  const storeLocatorConfigured = ["payed", "free_trial"].includes(
    orgProperties?.store_locator?.status || ""
  );

  useEffect(() => {
    setProfilePhotoHash(Date.now());
  }, [currentUser]);

  return (
    <VStack
      bg="blue.900"
      borderRight="1px solid #f5f5f5"
      maxW={60}
      spacing={5}
      alignItems="center"
      h="100%"
      sx={{
        ".chakra-button": { color: "white" },
      }}
    >
      <Flex p="1rem 0" w="100%" justifyContent="center" alignContent="center">
        <LogoComponent />
      </Flex>
      <VStack spacing={0} mt={0} alignItems="center" flex={1} overflowY="auto">
        <MapsBtn />
        <HomeBtn />
        <GrowthBtn />
        <LocationBtn />
        <StoresBtn />
        <ShopperBtn />
        <SalesBtn />
        <ProductsBtn />
        <EngageBtn />
        <ReportsBtn />
        <Divider />
        <StoreLocatorBtn />
        {/* // @ts-ignore */}
        {userIsInDemo && <Demo />}
        {userIsInDemo && (
          <Box
            p={5}
            boxShadow="0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
          >
            <Button
              onClick={() =>
                window.open(
                  "https://calendly.com/laura-rocha/30-mins-dathic--locator-support"
                )
              }
              colorScheme="blue"
            >
              Request full access
            </Button>
          </Box>
        )}
      </VStack>
      <Divider />
      <Menu>
        <MenuButton
          id="avatar"
          className="avatar-btn"
          as={"div"}
          style={{
            alignSelf: "center",
            width: "100%",
            margin: 0,
            padding: "1rem 0.6rem",
            height: "110px",
          }}
          onClick={() => trackClick("user-menu-btn", "User Menu")}
        >
          <VStack>
            <Avatar
              name={userName}
              src={
                currentUser?.photoURL
                  ? currentUser?.photoURL + "?" + profilePhotoHash
                  : undefined
              }
              colorScheme="blue"
            >
              {!storeLocatorConfigured && (
                <AvatarBadge boxSize="1em" bg="blue.400" top={-1} />
              )}
            </Avatar>
            <Text
              color="white"
              maxW="7rem"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {userName}
            </Text>
          </VStack>
        </MenuButton>
        <UserMenu />
      </Menu>
    </VStack>
  );
}

export default function LateralAppBar({
  isOpen: drawerOpen,
  onClose,
}: UseDisclosureProps) {
  const { getButtonProps, getDisclosureProps, isOpen } = useDisclosure({
    defaultIsOpen: true,
  });
  return (
    <>
      <Drawer
        isOpen={!!drawerOpen}
        placement="left"
        onClose={onClose || (() => {})}
        closeOnOverlayClick
      >
        <Box display={["block", null, "none"]}>
          <DrawerOverlay display={["block", null, "none"]} />
          <DrawerContent maxW={40} display={["flex", null, "none"]}>
            <VerticalMenu />
          </DrawerContent>
        </Box>
      </Drawer>
      <Box
        display={["none", null, "flex"]}
        pos="relative"
        isolation="isolate"
        zIndex={2}
        flexDir="column"
        bg={
          ((dathic_chakra_theme.colors?.blue || {}) as Colors)["400"] as string
        }
      >
        <IconButton
          {...getButtonProps()}
          icon={isOpen ? <FaCaretLeft /> : <FaCaretRight />}
          iconSpacing={0}
          zIndex={2}
          right={0}
          color="white"
          height="40px"
          variant="link"
          pos="absolute"
          isActive={false}
        />
        <motion.div
          {...getDisclosureProps()}
          initial={false}
          hidden={false}
          animate={{ width: isOpen ? 130 : 30, opacity: isOpen ? 1 : -1 }}
          style={{
            width: 130,
            height: "100%",
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            overflowY: "auto",
          }}
        >
          <VerticalMenu />
        </motion.div>
      </Box>
    </>
  );
}
