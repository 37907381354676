import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  getDeliveryEntities,
  getDemographics,
  getStores,
} from "../../services/api.service";
import { getInsights } from "../../services/insights.service";
import { changeLayerData } from "../layers/layersSlice";
import { selectVisState } from "../map/keplerReducer";
import InsightsSelector from "./InsightsSelector";

function SelectMoreInsights({
  layerId,
  accessToken,
  keplerLayers,
  orgConfig,
  changeLayerData,
  datasets,
  onOptionsFetch,
}) {
  const [insightOptions, setInsightOptions] = useState();
  const [moreInsightsToFetch, setMoreInsightsToFetch] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const country = orgConfig.country || "US";

  const objectType = useMemo(() => {
    const layer = keplerLayers?.find((l) => l.id === layerId);
    return layer?.config?.dataId?.includes("selected_audience")
      ? "zipcode"
      : layer?.config?.dataId?.includes("_stores")
      ? "store"
      : layer?.config?.dataId?.includes("delivery_entities")
      ? "delivery_entity"
      : layer?.config?.dataId?.includes("insight")
      ? "independent"
      : "generic";
  }, [keplerLayers, layerId]);

  const { selectedInsights, states, zipcodes, objectIds } = useMemo(() => {
    const layer = keplerLayers?.find((l) => l.id === layerId);
    return (layer?.dataToFeature || []).reduce(
      (acc, feature) => {
        const featureInsights = Object.keys(
          feature?.properties?.insights?.[0] || {}
        );
        const selectedInsights = [
          ...new Set([...acc.selectedInsights, ...featureInsights]),
        ];
        const states = [
          ...new Set([...acc.states, feature?.properties?.state]),
        ];
        const zipcodes = [
          ...new Set([...acc.zipcodes, feature?.properties?.zipcode]),
        ];
        const objectIds = [
          ...new Set([
            ...acc.objectIds,
            objectType === "delivery_entity"
              ? feature?.properties?.uid
              : feature?.properties?.id,
          ]),
        ];
        return { selectedInsights, states, zipcodes, objectIds };
      },
      { selectedInsights: [], states: [], zipcodes: [], objectIds: [] }
    );
  }, [keplerLayers, layerId, objectType]);

  useEffect(() => {
    getInsights(accessToken, { level: objectType }, "spec").then(
      (newOptions) => {
        setInsightOptions(newOptions);
        if (onOptionsFetch) onOptionsFetch(newOptions);
      }
    );
  }, [accessToken, objectType, onOptionsFetch]);

  const getNewInsights = async (insightsToFetch) => {
    switch (objectType) {
      case "zipcode":
        return getDemographics(
          undefined,
          undefined,
          ["id"],
          states,
          undefined,
          undefined,
          zipcodes,
          undefined,
          accessToken,
          undefined,
          undefined,
          country,
          insightsToFetch
        );
      case "store":
        return getStores(
          states,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          accessToken,
          undefined,
          insightsToFetch,
          objectIds
        );
      case "delivery_entity":
        return getDeliveryEntities(
          accessToken,
          states,
          objectIds,
          country,
          undefined,
          undefined,
          zipcodes,
          undefined,
          undefined,
          insightsToFetch
        );
      // case "independent":
      //     return getIndieInsights(accessToken,)

      default:
        return [];
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const newInsights = insightOptions
      ?.filter((variable) =>
        Object.keys(moreInsightsToFetch).includes(`${variable.id}`)
      )
      ?.reduce((acc, op) => {
        const queryParams = op.endpoint
          ?.split("?")?.[1]
          ?.split("&")
          .filter((param) => param.startsWith("q"))
          .map((param) => param.split("=")[1]);
        return [...new Set([...acc, ...(queryParams || [])])];
      }, []);
    const insightsToFetch = [...new Set([...selectedInsights, ...newInsights])];

    setIsFetching(true);
    getNewInsights(insightsToFetch)
      .then((newInsights) => {
        const layer = keplerLayers?.find((l) => l.id === layerId);
        const { fields, allData } = datasets?.[layer?.config?.dataId] || {};
        const newObjects = allData.map((row) => {
          let rowData = fields.reduce(
            (acc, field) => ({ ...acc, [field.name]: row[field.fieldIdx] }),
            {}
          );
          const newItemData = newInsights.find(
            (d) =>
              d[objectType === "delivery_entity" ? "uid" : "id"] ===
              rowData[objectType === "delivery_entity" ? "uid" : "id"]
          );
          let _geojson;
          if (rowData._geojson) {
            try {
              _geojson = JSON.parse(rowData._geojson || 0);
              rowData = _geojson.properties;
            } catch (error) {}
          }
          rowData.insights = newItemData.insights?.map((insight) =>
            Object.keys(insight).reduce(
              (acc, key) =>
                insightsToFetch?.includes(key) && key !== "id"
                  ? { ...acc, [key]: insight[key] }
                  : acc,
              {}
            )
          );
          Object.keys(rowData.insights?.[0] || {}).forEach((key) => {
            rowData[key] = rowData.insights[0][key];
          });
          if (_geojson) {
            rowData.geometry = _geojson.geometry;
            _geojson.properties = rowData;
            _geojson.properties._geojson = JSON.stringify(_geojson);
            rowData._geojson = JSON.stringify(_geojson);
          }
          return rowData;
        });
        return changeLayerData({ layerId, newData: newObjects });
      })
      .finally(() => {
        setIsFetching(false);
      });
  };
  return (
    <Accordion w="100%" allowToggle mt={3}>
      <AccordionItem>
        <AccordionButton>
          Add more insights
          <AccordionIcon ml="auto" />
        </AccordionButton>
        <AccordionPanel>
          <form onSubmit={onSubmit}>
            <InsightsSelector
              insightOptions={insightOptions?.filter(
                (variable) =>
                  !selectedInsights?.find((varName) =>
                    variable.endpoint.includes(varName)
                  )
              )}
              value={moreInsightsToFetch}
              onChange={setMoreInsightsToFetch}
            />
            <Button
              type="submit"
              disabled={
                Object.values(moreInsightsToFetch || {})?.length === 0 ||
                isFetching
              }
              isLoading={isFetching}
            >
              Fetch
            </Button>
          </form>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
export default connect(
  (state) => ({
    accessToken: state.app.accessToken,
    orgConfig: state.app.orgProperties?.properties,
    keplerLayers: selectVisState(state)?.layers,
    datasets: selectVisState(state)?.datasets,
  }),
  (dispatch) => ({
    changeLayerData: (payload) => dispatch(changeLayerData(payload)),
  })
)(SelectMoreInsights);
