import { Button } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import { Img } from "@chakra-ui/react";
import { toLower } from "lodash";
import { useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import menuIconReports from "../assets/images/menu-icon-reports.png";
import { RESOURCES } from "../constants/user-constants";
import MarketingReportDrawer from "../features/dashboard/marketing-report-drawer";
import { trackClick } from "../services/tracking.service";
import { joinWords } from "../utils/stringUtils";
import EmbeddedReportDrawer from "./embed/embedded-report-drawer";

function ReportsBtn({ currentUser, embeddedReports }) {
  const location = useLocation();
  const [openEmbeddedReport, setOpenEmbeddedReport] = useState();
  const marketingReportDisclosure = useDisclosure();
  const organization_id = currentUser?.organization_id;
  const userResources = currentUser?.resources;
  const _embeddedReports =
    embeddedReports?.filter((r) =>
      location.pathname?.includes("storelocator")
        ? toLower(r.product).includes("store locator")
        : true
    ) || [];

  return (
    <div>
      <Menu>
        {(!![RESOURCES.REPORT_MARKETING].find(
          (resource) => !!userResources?.includes(resource)
        ) ||
          !!_embeddedReports?.length) && (
          <MenuButton
            as={"div"}
            _hover={{ textDecoration: "underline" }}
            onClick={(e) => trackClick("reports-btn", "Reports", null)}
          >
            <Button
              id="reports-btn"
              className="reports-btn"
              variant="link"
              as="div"
              padding="12px 10px"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              leftIcon={
                <Img
                  src={menuIconReports}
                  height="1rem"
                  width="1rem"
                  id="reports-btn"
                  className="reports-btn"
                />
              }
              iconSpacing={0}
              size="xs"
            >
              Reports
            </Button>
          </MenuButton>
        )}
        <MenuList>
          <MenuGroup>
            {organization_id === 1 && (
              <MenuItem
                id="report-marketing"
                className="report-item-btn"
                onClick={() =>
                  trackClick("report-marketing", "Marketing Report", () =>
                    marketingReportDisclosure.onOpen()
                  )
                }
                isDisabled={
                  !userResources?.includes(RESOURCES.REPORT_MARKETING)
                }
              >
                Marketing Report
              </MenuItem>
            )}
            {_embeddedReports?.map((embeddedReport) => (
              <MenuItem
                id={`report-${joinWords(embeddedReport.name)}`}
                className="report-item-btn"
                onClick={() =>
                  trackClick(
                    `report-${embeddedReport.url.match(/\/(\d+)\//)?.[1]}`,
                    embeddedReport.name,
                    () => setOpenEmbeddedReport(embeddedReport)
                  )
                }
              >
                {embeddedReport.name}
              </MenuItem>
            ))}
          </MenuGroup>
        </MenuList>
      </Menu>
      <MarketingReportDrawer
        isOpen={marketingReportDisclosure.isOpen}
        onClose={marketingReportDisclosure.onClose}
      />
      <EmbeddedReportDrawer
        isOpen={!!openEmbeddedReport}
        onClose={() => setOpenEmbeddedReport()}
        url={openEmbeddedReport?.url}
      />
    </div>
  );
}

export default connect((state) => ({
  currentUser: state.app.currentUser,
  embeddedReports: state?.app?.orgProperties?.properties?.reports,
}))(ReportsBtn);
