import {
  Button,
  Colors,
  Divider,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { cloneDeep, merge, union } from "lodash";
import { Dispatch, useEffect, useRef, useState } from "react";
import { IoStorefront } from "react-icons/io5";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import Tour, { ReactourStep, ReactourStepContentArgs } from "reactour";
import {
  addOpenTour,
  removeOpenTour,
  selectCurrentUser,
  selectIsShopifyApp,
  selectOpenTours,
  selectOrgProperties,
  selectShopifyDomain,
  updateOrgProperties,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import customize_icon from "../../assets/images/customize_icon.png";
import fireworks from "../../assets/images/fireworks.gif";
import products_icon from "../../assets/images/products_icon.png";
import storelocator_icon from "../../assets/images/storelocator_icon.png";
import EmailVerification from "../../components/EmailVerification";
import dathicChakraTheme from "../../theme/dathic_chakra_theme";
import {
  selectProductsIsOpen,
  selectStoresIsOpen,
} from "../store-locator/storeLocatorSlice";

var pluralize = require("pluralize");

function SimpleStep(props: { message: string; args: ReactourStepContentArgs }) {
  return (
    <VStack>
      <Text>{props.message}</Text>
      <Divider />

      <Button
        onClick={() => props.args.goTo(props.args.step)}
        alignSelf="flex-end"
      >
        Next
      </Button>
    </VStack>
  );
}

const homeTourSteps: (storeLabel: string) => ReactourStep[] = (
  storeLabel: string
) => [
  {
    selector: "#audience-select-btn",
    content: (args) => (
      <SimpleStep
        message="To get insights on your audience, you must first click this button to filter and choose the nationality and location of your audience."
        args={args}
      />
    ),
  },
  {
    selector: "#selection-btn",
    content: (args) => (
      <SimpleStep
        message="When you have some demographics on the map, you can select states and locations to use through the platform."
        args={args}
      />
    ),
  },
  {
    selector: "#location-btn",
    content: (args) => (
      <SimpleStep
        message="When having some locations selected, you can manage your selected locations and take some actions here."
        args={args}
      />
    ),
  },
  {
    selector: "#marketing-btn",
    content: (args) => (
      <SimpleStep
        message="One of the actions you can take on your locations is to create an ad campaign targeting this selection."
        args={args}
      />
    ),
  },
  {
    selector: "#stores-btn",
    content: (args) => (
      <SimpleStep
        message={`If you want to work with ${pluralize(
          storeLabel
        )}, after selecting your audience, you can search for the ${pluralize(
          storeLabel
        )} you want to analyze clicking this button.`}
        args={args}
      />
    ),
  },
  {
    selector: "#sales-btn",
    content: (args) => (
      <SimpleStep
        message={`To manage your ${storeLabel} sales and get better AI recommendations, you can upload your sales and view sales trends here.`}
        args={args}
      />
    ),
  },
  {
    selector: "#products-btn",
    content: (args) => (
      <SimpleStep
        message="Here you can manage the products your organization sells, compare trends with the competition and discover similar products"
        args={args}
      />
    ),
  },
  {
    selector: "#reports-btn",
    content: (args) => (
      <SimpleStep message="All of your reports are listed here." args={args} />
    ),
  },
  {
    selector: "#view-tabs",
    content: (args) => (
      <SimpleStep
        message="This is the view tab bar, a view is an independent map with it's own data and reports."
        args={args}
      />
    ),
  },
  {
    selector: "#view-settings-btn",
    content: (args) => (
      <SimpleStep
        message="Here you can configure your view and share it with others."
        args={args}
      />
    ),
  },
  {
    selector: "#add-view-btn",
    content: (args) => (
      <SimpleStep
        message="To create a new view click this button."
        args={args}
      />
    ),
  },
  {
    selector: "#tours-btn",
    content: (args) => (
      <SimpleStep
        message="If you ever get lost and want to view this or other tours again, you can get back to them here."
        args={args}
      />
    ),
  },
];
const storelocatorTourSteps: (
  dispatch: Dispatch<any>,
  step: number,
  storeLabel: string,
  shopifyDomain?: any,
  navigate?: NavigateFunction
) => ReactourStep[] = (dispatch, step, storeLabel, shopifyDomain, navigate) => {
  const shopifySteps: ReactourStep[] = [
    {
      content: (args) => {
        return (
          <VStack spacing={3}>
            <HStack w="100%">
              <Heading flex={1} as="h3" size="lg">
                Congrats on your new Shopify {storeLabel} Locator App!
              </Heading>
              <Image src={storelocator_icon} alt="storelocator_icon" h={50} />
            </HStack>
            <Text>
              We created a{" "}
              <Button
                variant="link"
                onClick={() =>
                  window.open(
                    "https://" + shopifyDomain + "/pages/store-locator"
                  )
                }
              >
                page
              </Button>{" "}
              on Shopify for your {storeLabel} Locator. You can add it to your
              menus in your store's admin panel.{" "}
              <Button
                variant="link"
                onClick={() =>
                  window.open(
                    "https://help.shopify.com/en/manual/online-store/menus-and-links"
                  )
                }
              >
                Find help
              </Button>
            </Text>
            <Text>
              You can also add an App Block anywhere on your store by{" "}
              <Button
                variant="link"
                onClick={() =>
                  window.open("https://" + shopifyDomain + "/admin/themes")
                }
              >
                customizing your theme
              </Button>{" "}
              and selecting the Dathic {storeLabel} Locator block under Apps .
            </Text>
            <Divider />
            <Button
              onClick={() => args.goTo(args.step)}
              colorScheme="blue"
              alignSelf="flex-end"
            >
              Set Up Now
            </Button>
          </VStack>
        );
      },
      style: {
        maxWidth: "30rem",
        padding: "4rem 3rem",
      },
    },
    {
      content: (args) => {
        return (
          <VStack spacing={3}>
            <HStack w="100%">
              <Heading flex={1} as="h3" size="lg">
                Please verify your email
              </Heading>
            </HStack>
            <EmailVerification />
            <Divider />
            <Button onClick={() => args.goTo(args.step)} alignSelf="flex-end">
              Next
            </Button>
          </VStack>
        );
      },
      style: {
        maxWidth: "30rem",
        padding: "4rem 3rem",
      },
    },
  ];
  const steps: ReactourStep[] = [
    ...(shopifyDomain ? shopifySteps : []),
    {
      content: (args) => {
        return (
          <VStack spacing={3}>
            <HStack w="100%">
              <Heading flex={1} as="h3" size="lg">
                Welcome to your product locator!
              </Heading>
              <Image src={storelocator_icon} alt="storelocator_icon" h={50} />
            </HStack>
            <Text>
              This tool allows you to clearly communicate to customers what you
              sell and where they can find and purchase your products.
            </Text>
            <Divider />
            <Button
              onClick={() => args.goTo(args.step)}
              colorScheme="blue"
              alignSelf="flex-end"
            >
              Set Up Now
            </Button>
          </VStack>
        );
      },
      style: {
        maxWidth: "30rem",
        padding: "4rem 3rem",
      },
    },
    {
      selector: "#store-locator-btn",
      content: (args) => {
        return (
          <VStack>
            <HStack w="100%">
              <Heading flex={1} as="h3" size="lg">
                Customize the look
              </Heading>
              <Image src={customize_icon} alt="customize_icon" h={50} />
            </HStack>
            <Text>
              Here in <strong>Settings</strong>, you can choose what information
              is displayed to customers when they search for your product and
              stores
            </Text>
            <Divider />
            <HStack justify="space-between" w="100%">
              <Button onClick={() => args.goTo(args.step)}>Next</Button>
            </HStack>
          </VStack>
        );
      },
      style: {
        maxWidth: "30rem",
        padding: "4rem 3rem",
      },
    },
    {
      selector: "#products-btn",
      content: (args) => {
        return (
          <VStack>
            <HStack w="100%">
              <Heading flex={1} as="h3" size="lg">
                Edit your collection
              </Heading>
              <Image src={products_icon} alt="products_icon" h={50} />
            </HStack>
            <Text>
              In <strong>Products</strong>, you have the ability to add new,
              update existing and manage your products
            </Text>
            <Divider />
            <HStack justify="space-between" w="100%">
              <Button onClick={() => args.goTo(args.step)}>Next</Button>
            </HStack>
          </VStack>
        );
      },
      style: {
        maxWidth: "30rem",
        padding: "4rem 3rem",
      },
    },
    {
      selector: "#stores-btn",
      content: (args) => {
        return (
          <VStack>
            <HStack w="100%">
              <Heading flex={1} as="h3" size="lg">
                Keep things in order
              </Heading>
              <IoStorefront size="2rem" />
            </HStack>
            <Text>
              You can manage your store's organization by creating a new
              location, update or edit existing ones
            </Text>
            <Divider />
            <HStack justify="space-between" w="100%">
              <Button onClick={() => args.goTo(args.step)}>Next</Button>
            </HStack>
          </VStack>
        );
      },
      style: {
        maxWidth: "30rem",
        padding: "4rem 3rem",
      },
    },
    {
      selector: '[data-tour="tours-btn-icon"]',
      stepInteraction: false,
      content: (args) => {
        return (
          <VStack pos="relative">
            <Image
              pos="absolute"
              src={fireworks}
              alt="fireworks"
              h={270}
              right={"-40px"}
              bottom={-50}
            />
            <Heading flex={1} as="h3" size="lg">
              And thats it!
            </Heading>
            <Text>
              Click the info button in the top right to review the walkthrough
              again, anytime!
            </Text>
            <Divider />
            <Button onClick={() => args.close()} alignSelf="flex-end">
              Close
            </Button>
          </VStack>
        );
      },
      style: {
        maxWidth: "30rem",
        padding: "4rem 3rem",
      },
    },
  ];
  return steps;
};

function AppTours() {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const tourStepParam = params.get("tourStep");

  const openTours = useAppSelector(selectOpenTours);
  const storelocatorProductsOpen = useAppSelector(selectProductsIsOpen);
  const storelocatorStoresOpen = useAppSelector(selectStoresIsOpen);
  const { properties: orgConfig } = useAppSelector(selectOrgProperties) || {};
  const isShopifyApp = useAppSelector(selectIsShopifyApp);
  const shopifyDomain = useAppSelector(selectShopifyDomain);
  const orgProperties = useAppSelector(selectOrgProperties);
  const currentUser = useAppSelector(selectCurrentUser);

  const [currentStep, setCurrentStep] = useState(
    tourStepParam ? Number(tourStepParam) : 0
  );

  const shouldGoToNextStep = useRef(false);

  const storeLabel = orgConfig?.storeNameReplacement || "Store";

  useEffect(() => {
    if (shouldGoToNextStep.current) {
      shouldGoToNextStep.current = false;
      setCurrentStep((oldValue) => oldValue + 1);
    }
    if (storelocatorProductsOpen || storelocatorStoresOpen) {
      shouldGoToNextStep.current = true;
    }
  }, [storelocatorProductsOpen, storelocatorStoresOpen]);

  useEffect(() => {
    const firstTour = (openTours || []).at(-1) || "";
    if (firstTour) {
      let params = new URLSearchParams(location.search);
      params.set("tourStep", `${currentStep}`);
      params.set("openTour", firstTour);
      navigate(location.pathname, {
        state: {
          search: params.toString(),
        },
      });
    }
  }, [currentStep, navigate, location.pathname, location.search, openTours]);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    const openTourParam = params.get("openTour");

    if (openTourParam) {
      dispatch(addOpenTour(openTourParam));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getSteps: (step: number) => ReactourStep[] = (step) => {
    const firstTour = (openTours || []).at(-1) || "";
    switch (firstTour) {
      case "homeTour":
        return homeTourSteps(storeLabel);
      case "storelocatorTour":
        return storelocatorTourSteps(
          dispatch,
          step,
          storeLabel,
          isShopifyApp ? shopifyDomain : undefined,
          navigate
        );

      default:
        return [];
    }
  };

  const setViewedTours = async (tourName: string) => {
    const newOrgConfig = cloneDeep(orgProperties);
    merge(newOrgConfig, {
      properties: {
        viewedGrowthTours: {
          [currentUser.id]: union(
            newOrgConfig?.properties?.viewedGrowthTours?.[currentUser.id] || [],
            [tourName]
          ),
        },
      },
    });
    // @ts-ignore
    const wrapped = await dispatch(updateOrgProperties(newOrgConfig));
    // @ts-ignore
    return unwrapResult(wrapped);
  };

  return getSteps(currentStep).length ? (
    <Tour
      className="tour-helper"
      disableKeyboardNavigation={["left", "right"]}
      accentColor={
        ((dathicChakraTheme.colors?.blue || {}) as Colors)["400"] as string
      }
      steps={getSteps(currentStep)}
      isOpen
      showNumber={false}
      rounded={25}
      showButtons={false}
      getCurrentStep={setCurrentStep}
      goToStep={currentStep}
      closeWithMask={false}
      onRequestClose={(props) => {
        const tourName = (openTours || []).at(-1);
        if (tourName) {
          let params = new URLSearchParams(location.search);
          params.delete("tourStep");
          params.delete("openTour");
          navigate(location.pathname, {
            state: {
              search: params.toString(),
            },
          });
          dispatch(removeOpenTour(tourName));
          setViewedTours(tourName);
        }
      }}
    />
  ) : (
    <></>
  );
}

export default AppTours;
