import {
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  selectAccessToken,
  selectOrgProperties,
  selectProducts,
  selectUserResources,
  updateOrgProperties,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { cloneDeep, merge, startCase } from "lodash";
import { unwrapResult } from "@reduxjs/toolkit";
import { MdMoreVert, MdVisibility, MdVisibilityOff } from "react-icons/md";
import AdditionalSourcesForm, {
  AdditionalOnlineSource,
} from "./AdditionalSourcesForm";
import { getStoreLocatorInventory } from "../../services/api.service";
import {
  BatchActionRenderProps,
  EntityTable,
} from "../../components/entity-table/EntityTable";
import { DataType } from "ka-table/enums";
import { ICellTextProps } from "ka-table/props";
import { refreshIframe } from "./storeLocatorSlice";
import { FaEdit } from "react-icons/fa";
import { RESOURCES } from "../../constants/user-constants";
import { sourceLogos } from "../../utils/stringUtils";

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((s, index) => ({ ...s, order: index + 1 }));
};

export function OnlineOptionCard({
  option,
}: {
  option: AdditionalOnlineSource;
}) {
  return (
    <HStack bg="white" rounded="lg" shadow="md" w={200} mx={3} pos="relative">
      <VStack p={2} alignItems="flex-start">
        <Text>{option.source}</Text>
        {option.buyUrl && <Button>BUY</Button>}
      </VStack>
      <Box w={16} h={16} pos="absolute" right={0}>
        {option.imgUrl && (
          <Image w="100%" h="100%" src={option.imgUrl} objectFit="contain" />
        )}
        {option.origin === "dathic" && (
          <Image
            w="100%"
            h="100%"
            src={sourceLogos(option.source)}
            objectFit="contain"
          />
        )}
      </Box>
    </HStack>
  );
}

const ToggleOptionsVisibility = ({
  selectedRows,
  sources,
  saveChanges,
}: {
  selectedRows: AdditionalOnlineSource[];
  sources: AdditionalOnlineSource[];
  saveChanges: (newSources: AdditionalOnlineSource[]) => Promise<any>;
}) => {
  const userResources = useAppSelector(selectUserResources);
  const [isLoading, setIsLoading] = useState(false);
  const shouldEnable = selectedRows.every((s) => s.isHidden);

  const toggleHiddenBatch = (
    shouldEnable: boolean,
    options: AdditionalOnlineSource[]
  ) => {
    const newSources = [
      ...sources.filter(
        (s) => !options.map((o) => o.source).includes(s.source)
      ),
      ...options.map((s) => ({ ...s, isHidden: !shouldEnable })),
    ];
    return saveChanges(newSources);
  };

  return (
    <Button
      leftIcon={
        !shouldEnable ? (
          <MdVisibilityOff size="1rem" />
        ) : (
          <MdVisibility size="1rem" />
        )
      }
      isLoading={isLoading}
      variant="link"
      isDisabled={
        !selectedRows.length ||
        !userResources?.includes(RESOURCES.STORES_UPDATE)
      }
      aria-label={"Batch option disable or enable button"}
      onClick={() => {
        setIsLoading(true);
        toggleHiddenBatch(shouldEnable, selectedRows)
          .then(() => {})
          .finally(() => setIsLoading(false));
      }}
    >
      {!shouldEnable ? "Disable" : "Enable"}
    </Button>
  );
};

const EditStoreButton = ({
  selectedRows,
  onClick,
}: {
  selectedRows: AdditionalOnlineSource[];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const userResources = useAppSelector(selectUserResources);
  return (
    <Button
      leftIcon={<FaEdit />}
      variant="link"
      isDisabled={
        !selectedRows.length ||
        selectedRows.length > 1 ||
        selectedRows?.[0]?.origin === "dathic" ||
        !userResources?.includes(RESOURCES.STORES_UPDATE)
      }
      aria-label={"Additional source edit button"}
      onClick={onClick}
    >
      Edit
    </Button>
  );
};

const AdditionalSources = () => {
  const [editing, setEditing] = useState<number>();
  const [deliveryInventory, setDeliveryInventory] = useState<any[]>([]);
  const products = useAppSelector(selectProducts);
  const accessToken = useAppSelector(selectAccessToken);
  const orgProperties = useAppSelector(selectOrgProperties);
  const dispatch = useAppDispatch();
  const storeLocatorConfig = orgProperties?.store_locator;
  const [sources, setSources] = useState<AdditionalOnlineSource[]>(
    storeLocatorConfig?.additionalSources || []
  );
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";

  useEffect(() => {
    const deliveryProductUids = Array.from(
      new Set(
        products
          ?.flatMap((p) => p.delivery_products)
          .map((dp) => dp?.delivery_product_uid) || []
      )
    ).filter((uid) => !!uid);
    if (deliveryProductUids?.length) {
      getStoreLocatorInventory(
        undefined,
        undefined,
        deliveryProductUids,
        accessToken
      ).then((inventory) => {
        setDeliveryInventory(
          [...(inventory || [])].reduce(
            (acc, i) =>
              acc.find(
                (item: any) =>
                  item.id_auto_delivery_inventory ===
                  i.id_auto_delivery_inventory
              )
                ? acc
                : [...acc, i],
            []
          )
        );
      });
    }
  }, [accessToken, products]);

  const deliveryEntities = deliveryInventory
    ?.reduce(
      (acc: any[], inv: any) =>
        acc.find((item) => item.delivery_entity_uid === inv.delivery_entity_uid)
          ? acc
          : [...acc, inv],
      []
    )
    .map((inv: any) => inv.delivery_entity)
    .filter((entity: any) => !!entity);

  const saveChanges = useCallback(
    async (newSources: AdditionalOnlineSource[]) => {
      const newOrgConfig = cloneDeep(orgProperties || {});
      merge(newOrgConfig, {
        store_locator: {
          additionalSources: [],
        },
      });
      const sortedSources = newSources.sort(
        (a, b) => +(a.order ?? 0) - +(b.order ?? 0)
      );
      // @ts-ignore
      newOrgConfig.store_locator = {
        // @ts-ignore
        ...newOrgConfig.store_locator,
        additionalSources: sortedSources,
      };
      dispatch(refreshIframe());
      setSources(sortedSources);
      // @ts-ignore
      const wrapped = await dispatch(updateOrgProperties(newOrgConfig));
      // @ts-ignore
      return unwrapResult(wrapped);
    },
    [dispatch, orgProperties]
  );

  const toggleHidden = (index: number, source: string) => {
    const newSources = [...sources];

    if (newSources.length < index + 1) {
      // grabbed outside ordered list
      const newSource = {
        source,
        origin: "dathic",
        isHidden: true,
      } as AdditionalOnlineSource;
      newSources.push(newSource);
    } else {
      const source = { ...newSources[index] };
      source.isHidden = !source.isHidden;
      newSources.splice(+index, 1, source);
    }
    return saveChanges(newSources);
  };
  const removeSource = (index: number) => {
    const newSources = [...sources];
    const sourceToRemove = newSources[index];
    if (sourceToRemove.origin === "dathic") {
      if (newSources.length < index + 1) {
        // grabbed outside ordered list
        const newSource = {
          source: sourceToRemove.source,
          origin: "dathic",
          isHidden: true,
        } as AdditionalOnlineSource;
        newSources.push(newSource);
      } else {
        const source = { ...newSources[index] };
        source.isHidden = true;
        newSources.splice(+index, 1, source);
      }
    } else {
      newSources.splice(+index, 1);
    }
    saveChanges(newSources).then();
  };

  const StatusSelect = (props: ICellTextProps) => {
    const { rowData, rowKeyValue } = props;
    const [isLoading, setIsLoading] = useState(false);
    return (
      <Select
        defaultValue={rowData.isHidden ? "disabled" : "enabled"}
        value={rowData.isHidden ? "disabled" : "enabled"}
        isDisabled={isLoading}
        onChange={() => {
          setIsLoading(true);
          toggleHidden(+rowKeyValue, rowData.source)
            .then(() => {})
            .finally(() => setIsLoading(false));
        }}
      >
        {["enabled", "disabled"].map((option) => (
          <option key={option} value={option}>
            {startCase(option)}
          </option>
        ))}
      </Select>
    );
  };

  const ProductImageField = ({ rowData, column }: ICellTextProps) => {
    return (
      <VStack>
        {rowData[column.key] ? (
          <Image src={rowData[column.key]} w={50} h={50} objectFit="contain" />
        ) : rowData.origin === "dathic" ? (
          <Image
            src={sourceLogos(rowData.source)}
            w={50}
            h={50}
            objectFit="contain"
          />
        ) : (
          <Box
            textAlign="center"
            p={3}
            rounded="lg"
            bg="gray.100"
            border="1px solid"
            lineHeight="1em"
            color="tomato"
            borderColor="tomato"
          >
            No Image
          </Box>
        )}
      </VStack>
    );
  };

  const batchActions = useMemo(() => {
    return [
      {
        Render: ({
          selectedRows,
        }: BatchActionRenderProps<AdditionalOnlineSource>) => (
          <ToggleOptionsVisibility
            selectedRows={selectedRows}
            sources={sources}
            saveChanges={saveChanges}
          />
        ),
      },
      {
        Render: ({
          selectedRows,
        }: BatchActionRenderProps<AdditionalOnlineSource>) => (
          <EditStoreButton
            selectedRows={selectedRows}
            onClick={() => setEditing(selectedRows?.[0]?.id)}
          />
        ),
      },
    ];
  }, [saveChanges, sources]);

  const OptionsButton = ({
    rowData,
  }: {
    rowData: ICellTextProps["rowData"];
  }) => {
    const userResources = useAppSelector(selectUserResources);
    const orgProperties = useAppSelector(selectOrgProperties);
    const storeLabel =
      orgProperties?.properties?.storeNameReplacement || "Store";
    return (
      <Box>
        <Menu>
          <MenuButton disabled={typeof rowData.id !== "number"}>
            <Box p={1} bg="white" rounded="lg">
              <IconButton
                variant="link"
                icon={<MdMoreVert />}
                colorScheme="blue.900"
                aria-label={""}
              />
            </Box>
          </MenuButton>
          <Portal>
            <MenuList maxHeight={200} overflowY="auto" zIndex={999}>
              <MenuItem
                icon={<FaEdit />}
                isDisabled={
                  !userResources?.includes(RESOURCES.STORES_UPDATE) ||
                  rowData.origin === "dathic"
                }
                onClick={() => setEditing(rowData.id)}
              >
                Edit {storeLabel}
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Box>
    );
  };

  const dataFromOutside = useMemo(
    () =>
      [
        ...sources,
        ...Array.from(
          new Set(deliveryEntities?.map((entity) => entity?.source) || [])
        )
          .filter((source) => !sources.find((s) => s.source === source))
          .map<AdditionalOnlineSource>(
            (source) =>
              ({
                source,
                origin: "dathic",
              } as AdditionalOnlineSource)
          ),
      ].map((source, index) => ({
        ...source,
        id: index,
        buyUrl: source.origin === "dathic" ? "Automatic" : source.buyUrl,
      })),
    [deliveryEntities, sources]
  );

  const handleReorder = (sourceIndex: number, destinationIndex: number) => {
    // dropped outside the list
    const list = [...sources];

    if (list.length < sourceIndex + 1) {
      // grabbed outside ordered list
      const grabbedSource = dataFromOutside[sourceIndex];
      list.push(grabbedSource);
      sourceIndex = list.length - 1;
    }

    if (list.length < destinationIndex + 1) {
      // grabbed outside ordered list
      destinationIndex = list.length - 1;
    }
    const items = reorder(list, sourceIndex, destinationIndex);

    saveChanges(items).then();
  };

  return (
    <VStack position="relative" alignItems="start" height="100%" w="100%">
      {typeof editing === "number" && (
        <Box
          pos="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          bg="white"
          rounded="lg"
          zIndex={99}
          p={3}
          overflowY="auto"
        >
          <AdditionalSourcesForm
            key={editing}
            selectedSource={editing}
            onFinish={(result) => {
              if (result) {
                setSources((oldStores) => {
                  const newStores = [...oldStores];
                  newStores[
                    oldStores.findIndex((item) => item.source === result.source)
                  ] = result;
                  return newStores;
                });
                dispatch(refreshIframe());
              }
              setEditing(undefined);
            }}
          />
        </Box>
      )}
      <Box w="100%" flex={1}>
        <Text textAlign="start">
          You can change the order by drag and dropping a row.
        </Text>
        <EntityTable
          initialTableProps={{
            columns: [
              {
                title: "Status",
                dataType: DataType.Boolean,
                key: "isHidden",
                isEditable: false,
                info: `Options that have the status (Enabled) will appear in your ${storeLabel} Locator`,
                Render: (p: any) => <StatusSelect {...p} dispatch={dispatch} />,
              },
              {
                title: "Display Order",
                key: "order",
                format: (value) => (!value ? "No order" : +value),
              },
              {
                title: "Name",
                key: "source",
                isEditable: false,
              },
              {
                title: "Buy URL",
                key: "buyUrl",
                isEditable: false,
              },
              {
                title: "Image",
                key: "imgUrl",
                isEditable: false,
                Render: ProductImageField,
              },
              {
                title: "Preview",
                key: "preview",
                isEditable: false,
                style: { width: 300 },
                Render: ({ rowData }) => <OnlineOptionCard option={rowData} />,
              },
              {
                title: "Delivery area",
                key: "deliveryArea",
                isEditable: false,
                Render: ({ rowData }) => (
                  <Text>
                    {rowData.origin === "dathic"
                      ? "Automatic"
                      : rowData.deliveryArea === "Nationwide"
                      ? "Nationwide"
                      : (rowData.deliveryStates || [])
                          .map((state: any) => state.key)
                          .join(", ")}
                  </Text>
                ),
              },
              {
                title: "Origin",
                key: "origin",
                isEditable: false,
                default: "User Submitted",
              },
            ],
            rowReordering: true,
          }}
          containerProps={{ height: "100%" }}
          LeftButton={OptionsButton}
          deleteItem={(id) => removeSource(+id)}
          dataFromOutside={dataFromOutside}
          allowBatchActions
          additionalBatchActions={batchActions}
          onReorder={handleReorder}
        />
      </Box>
    </VStack>
  );
};
export default AdditionalSources;
