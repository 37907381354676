import React from "react";
import {
  Button,
  Divider,
  HStack,
  Icon,
  Image,
  Tab,
  TabList,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import store_img_example from "../../assets/images/store_img_example.png";
import { replacePlaceholders } from "../../utils/stringUtils";
import { FaClock, FaDirections, FaGlobe } from "react-icons/fa";
import { MdRoomService } from "react-icons/md";
import { StoreLocatorConfiguration } from "./StoreLocatorConfig";
import { useAppSelector } from "../../app/store";
import { selectOrgProperties } from "../../app/appSlice";
import product_grid_preview_rating from "../../assets/images/product_grid_preview_rating.png";
import product_grid_preview from "../../assets/images/product_grid_preview.png";
import product_carousel_preview_rating from "../../assets/images/product_carousel_preview_rating.png";
import product_carousel_preview from "../../assets/images/product_carousel_preview.png";
import FeaturedContentList, { FeaturedContent } from "./FeaturedContentList";
import { Store } from "../../domain/Store";

type Props = {
  config?: StoreLocatorConfiguration;
  featuredContent?: FeaturedContent[];
  store?: Store;
};

const exampleStore = {
  address: "788 S Grand Ave, Los Angeles, CA 90017",
  short_address: "788 S Grand Ave",
  type: "Supercenter",
  name: "Whole Foods Market, Los Angeles",
  county: "Los Angeles",
  major_city: "Los Angeles",
  city: "Los Angeles",
  state: "CA",
  zipcode: "90017",
  chain: "Whole Foods Market",
  phone: "(213) 873-4745",
} as unknown as Store;

export const StoreCardPreview = ({
  config,
  featuredContent = [],
  store = exampleStore,
}: Props) => {
  const orgProperties = useAppSelector(selectOrgProperties);
  const storeLocatorConfig = config || orgProperties?.store_locator;

  const selectedSecondaryColor =
    storeLocatorConfig?.theme?.palette?.secondary?.main;

  const getProductPreview = (key: string) =>
    ({
      grid_rating: product_grid_preview_rating,
      grid_: product_grid_preview,
      carousel_rating: product_carousel_preview_rating,
      carousel_: product_carousel_preview,
    }[key]);

  return storeLocatorConfig ? (
    <VStack p={3} rounded="lg" shadow="md" w="100%">
      {storeLocatorConfig.storeDetails?.showStoreImage && (
        <Image src={store_img_example} alt="store_img_example" w="100%" />
      )}
      {!!storeLocatorConfig.storeDetails?.showReviews &&
        (storeLocatorConfig.storeDetails?.numberOfReviewsToShow > 1 ||
          isNaN(storeLocatorConfig.storeDetails?.numberOfReviewsToShow)) && (
          <Tabs w="100%" isFitted>
            <TabList
              sx={{
                '.chakra-tabs__tab[aria-selected="true"]': {
                  color: selectedSecondaryColor,
                },
              }}
            >
              <Tab>INFO</Tab>
              <Tab>REVIEWS</Tab>
            </TabList>
          </Tabs>
        )}
      <HStack w="100%" fontSize={12}>
        <VStack flex={1} alignItems="flex-start" spacing={0}>
          <Text fontWeight="bold" fontSize={14}>
            {replacePlaceholders(
              storeLocatorConfig.storeDetails.nameFormat,
              store
            )}
          </Text>
          {storeLocatorConfig.storeDetails?.showAddress && (
            <>
              <Text textAlign="start">{store?.address}</Text>
            </>
          )}
          {storeLocatorConfig.storeDetails?.showType && (
            <Text>{store?.type}</Text>
          )}
          {storeLocatorConfig.storeDetails?.contactInfo && (
            <Text>{store?.phone}</Text>
          )}
        </VStack>
        <VStack alignItems="flex-start">
          {storeLocatorConfig.storeDetails?.openInfo && (
            <Button leftIcon={<FaClock />} variant="link" colorScheme="green">
              Open
            </Button>
          )}
          {storeLocatorConfig.storeDetails?.directionsButton && (
            <Button
              leftIcon={<FaDirections />}
              variant="link"
              colorScheme="black"
            >
              Directions
            </Button>
          )}
          {storeLocatorConfig.storeDetails?.buyOnlineLink && (
            <Button leftIcon={<FaGlobe />} variant="link" colorScheme="blue">
              Website
            </Button>
          )}
        </VStack>
      </HStack>
      {storeLocatorConfig.storeDetails?.showDescription && (
        <Text>{store?.description}</Text>
      )}
      {!!featuredContent.length && (
        <>
          <Divider />
          <FeaturedContentList
            content={featuredContent}
            w="100%"
            direction="row"
            viewOnly
          />
        </>
      )}
      {storeLocatorConfig.storeDetails?.additionalServices?.length && (
        <>
          <Divider />
          <HStack
            spacing={5}
            w="100%"
            overflowX="auto"
            alignItems="flex-start"
            justifyContent="center"
          >
            {storeLocatorConfig.storeDetails.additionalServices.map((s) => (
              <VStack>
                {s.icon ? (
                  <Image
                    w={14}
                    h={14}
                    src={s.icon}
                    objectFit="cover"
                    rounded="md"
                  />
                ) : (
                  <Icon as={MdRoomService} w={14} h={14} />
                )}
                <Text textAlign="center" mt={0} m={0}>
                  {s.label}
                </Text>
              </VStack>
            ))}
          </HStack>
        </>
      )}
      <Divider />
      {storeLocatorConfig.storeDetails?.productsAvailable && (
        <Image
          src={getProductPreview(
            `${
              storeLocatorConfig.storeDetails?.productsVisualization ||
              "carousel"
            }_${
              storeLocatorConfig.storeDetails?.showProductReviews
                ? "rating"
                : ""
            }`
          )}
          alt="products_preview"
          w="100%"
        />
      )}
    </VStack>
  ) : (
    <></>
  );
};
